import utils from "../../../../utils";

import configBuilder from '../../../../components/ubold/ConfigBuilder';

import pageDefinitions from "../definitions";

const definitions = {...pageDefinitions,
    validators: {
        bank_name: (value) => {
            return utils.validator.validateRequired(value);
        },
        bank_number: (value) => {
            return utils.validator.validateRequired(value);
        },
        bank_logo: (value) => {
            return utils.validator.validateRequired(value);
        },
    },
}

export default configBuilder.buildAddEditPageConfig(definitions, ['bank_logo']);