import {withRouter} from "react-router-dom";

import TabPane from "../../../../../../components/ubold/organisms/TabPane";
import TabPaneSection from "../../../../../../components/ubold/organisms/TabPaneSection";

import CHTextField from "../../../../../../components/ubold/molecules/forms/CHTextField";
import CHTextArea from "../../../../../../components/ubold/molecules/forms/CHTextArea";
import CHCheckBox from "../../../../../../components/ubold/molecules/forms/CHCheckBox";

function Role(props) {
    const {id, definitions, tab, state, setState} = props;

    return (
        <TabPane
            id={id}
            definitions={definitions}
            tab={tab}
            state={state}
            setState={setState}>
            <TabPaneSection style={{padding: '25px 16px 0px'}}>
                <CHTextField
                    name='name'
                    title='Name'
                    isRequired
                    maxLength={50}
                />
                <CHTextArea
                    name='description'
                    title='Description'
                    maxLength={255}
                />
                <CHCheckBox
                    name='is_personal_role'
                    title='Is Personal Role'
                />
            </TabPaneSection>
        </TabPane>
    );
}

export default withRouter(Role);