import { useState } from "react";

import { useParams, withRouter } from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import Form from "../../../../components/ubold/organisms/Form";

import definitions from "./definitions";
import CVTextField from "../../../../components/ubold/molecules/forms/CVTextField";
import CVSelect from "../../../../components/ubold/molecules/forms/CVSelect";
import CVAsyncSelect from "../../../../components/ubold/molecules/forms/CVAsyncSelect";

function CAddEditPage() {
  let { id } = useParams<{ id: string }>();

  const initialState = {
    month: 1,
    escalating_amount: 0,
  };

  const [state, setState] = useState(initialState);

  const monthsGenerated = [];

  for (let i = 1; i <= 61; i++) {
    monthsGenerated.push({
      label: i + " month" + (i === 1 ? "" : "s"),
      value: i,
    });
  }

  return (
    <Page
      title={
        (id === undefined ? "Add New" : "Edit") +
        " " +
        definitions.title
      }
      restAccessCode={definitions.restAccessCode}
    >
      <Form
        id={id}
        definitions={definitions}
        state={state}
        setState={setState}
        readOnly
      >
        <CVAsyncSelect
          name="building_aggrement"
          title="Building Agreement"
          data="building/building__agreement?__type__=select_entries"
          isRequired
          readOnly
        />
        <CVSelect
          name="month"
          title="Month"
          data={monthsGenerated}
          isRequired
          readOnly
        />
        <CVTextField
          name="escalating_amount"
          title="Escalating Amount ( in month )"
          isRequired
          type="number"
          readOnly
        />
      </Form>
    </Page>
  );
}

export default withRouter(CAddEditPage);
