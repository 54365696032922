import configBuilder from "components/ubold/ConfigBuilder";

import pageDefinitons from "../definitions";
import utils from "utils";

const definitions = {
  ...pageDefinitons,
  validators: {
    title: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    messages: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    sanity_id: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    notif_type: (value: number) => {
      return utils.validator.validateRequired(value);
    },
    target: (value: number) => {
      return utils.validator.validateRequired(value);
    },
    users: (value: number[], state: any) => {
      const userValue = utils.validator.validateRequired(value);
      if (state["target"] === 4 && userValue !== true) {
        return "Users cannot be empty";
      } else {
        return true;
      }
    },
    building: (value: number, state: any) => {
      const buildingValue = utils.validator.validateRequired(value);
      if (state["target"] === 2 && buildingValue !== true) {
        return "Building cannot be empty";
      } else {
        return true;
      }
    },
  },
};

export default configBuilder.buildAddEditPageConfig(definitions);
