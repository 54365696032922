import { withRouter } from "react-router-dom";

import Page from "../../../../../components/ubold/organisms/Page";
// import TableView from "../../../../../components/ubold/organisms/TableView";

import TableViewColFreeze from "../../../../../components/ubold/organisms/TableViewColFreeze/TableViewColFreeze";
import PageRoomDC from "./Page.css";

import definitions from "./definitions";

function CPage() {
  return (
    <PageRoomDC>
      <Page title={definitions.title}>
        <TableViewColFreeze {...definitions.table} />
      </Page>
    </PageRoomDC>
  );
}

export default withRouter(CPage);
