import { isMobile } from "react-device-detect";

import utils from "../../../utils";

function SecondaryDataHandler(data) {
  return (
    <div
      className={
        isMobile
          ? "secondary-data-panel-mobile"
          : "secondary-data-panel"
      }
    >
      <div className="row mb-2">
        <div className="col mw-200">
          <div>
            <b>Room Variant</b>: <br />
            {data["room_variant_name"]}
          </div>
        </div>
        <div className="col mw-200">
          <div>
            <b>Room</b>: <br />
            {data["room_name"]}
          </div>
        </div>
        <div className="col mw-200">
          <div>
            <b>Plan</b>: <br />
            {data["payment_plan_name"]} Month(s)
          </div>
        </div>
        <div className="col mw-200">
          <div>
            <b>Full Paid:</b> <br />
            <span
              style={{
                color:
                  data["full_paid"] === "true"
                    ? "rgb(26, 188, 156);"
                    : "color: red;",
              }}
            >
              {data["full_paid"] === "true" ? "✔" : "✘"}
            </span>
          </div>
        </div>
        <div className="col mw-200">
          <div>
            <b>Deposit</b>: <br />
            {utils.formatter.currency(data["deposit"])}
          </div>
        </div>
        <div className="col mw-200">
          <div>
            <b>Deposit Notes</b>: <br />
            <div
              dangerouslySetInnerHTML={{
                __html: data["deposit_notes"]
                  ? data["deposit_notes"]
                  : "",
              }}
            />
          </div>
        </div>
        <div className="col mw-200">
          <div>
            <b>Status of Done:</b> <br />
            <span
              style={{
                color:
                  data["status_of_done"] === "true"
                    ? "rgb(26, 188, 156);"
                    : "color: red;",
              }}
            >
              {data["status_of_done"] === "true" ? "✔" : "✘"}
            </span>
          </div>
        </div>
        <div className="col mw-200">
          <div>
            <b>Transfer From</b>: <br />
            {data["transfer_from"] ? data["transfer_from"] : "-"}
          </div>
        </div>
        <div className="col mw-200">
          <div>
            <b>On Leave Paying:</b> <br />
            <span
              style={{
                color:
                  data["on_leave_paying"] === "true"
                    ? "rgb(26, 188, 156);"
                    : "color: red;",
              }}
            >
              {data["on_leave_paying"] === "true" ? "✔" : "✘"}
            </span>
          </div>
        </div>
        <div className="col mw-200">
          <div>
            <b>Duplicate Order:</b> <br />
            <span
              style={{
                color:
                  data["duplicated_order"] === "true"
                    ? "rgb(26, 188, 156);"
                    : "color: red;",
              }}
            >
              {data["duplicated_order"] === "true" ? "✔" : "✘"}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SecondaryDataHandler;
