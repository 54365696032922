import {useEffect, useState} from "react";

import {withRouter} from "react-router-dom";

import {isMobile} from "react-device-detect";

function CRadio(props) {
    const {name, state, setState, data, readOnly=false} = props;

    const [cData, setCData] = useState([])

    useEffect(() => {
        setCData(data);
    }, []);

    return (
        <div>
            <div className="align-items-center h-100 float-left" style={{...props.style, marginLeft: '3px'}}>
                {cData.map((value, index) => {
                    return <>
                        <div
                            key={name + index}
                            className={"d-inline-block radio radio-success" + (index > 0 && !isMobile ? ' pl-2' : '')}
                            onChange={e => {
                                setState(prevState => ({ ...prevState, [name]: e.target.value }));
                            }}>
                                <input
                                    id={name + index}
                                    type="radio"
                                    name={name}
                                    disabled={readOnly}
                                    readOnly={readOnly}
                                    checked={state[name] && state[name].toString() === value.value.toString()}
                                    value={value.value}
                                    />
                                <label htmlFor={name + index}>
                                    {value.label}
                                </label>
                        </div>
                        {isMobile ? <br/> : <></>}
                    </>
                })}
            </div>
            {state[name] !== null && state[name] !== undefined && !readOnly ? <span style={{
                display: 'inline',
                cursor: 'pointer',
                color: 'blue',
                textDecoration: 'underline',
                marginLeft: '8px',
                float: 'left'
            }} onClick={() => {
                setState(prevState => ({ ...prevState, [name]: null }));
            }}>Clear Selection</span> : <></>}
        </div>
    );
}

export default withRouter(CRadio);