import { useState } from "react";
import { useParams, withRouter } from "react-router";

import Page from "../../../../components/ubold/organisms/Page";
import Form from "../../../../components/ubold/organisms/Form";
import CVTextField from "../../../../components/ubold/molecules/forms/CVTextField";
import CVTextArea from "../../../../components/ubold/molecules/forms/CVTextArea";
import definitions from "./definitions";

function CViewPage() {
  let { id } = useParams<{ id: string }>();

  const initialState = {};

  const [state, setState] = useState(initialState);

  return (
    <Page
      title={"Detail of" + definitions.title}
      restAccessCode={definitions.restAccessCode}
    >
      <Form
        id={id}
        definitions={definitions}
        state={state}
        setState={setState}
        readOnly={true}
      >
        <CVTextField
          name="name"
          title="Name"
          maxLength={254}
          isRequired
          readOnly
        />
        <CVTextArea
          name="desc"
          title="Description"
          isRequired
          readOnly
        />
      </Form>
    </Page>
  );
}
export default withRouter(CViewPage);
