import { useParams, withRouter } from "react-router-dom";

import AccordionPane from "../../../../../../../components/ubold/organisms/AccordionPane";

import CHAsyncSelect from "../../../../../../../components/ubold/molecules/forms/CHAsyncSelect";
import CHDateTimePicker from "../../../../../../../components/ubold/molecules/forms/CHDateTimePicker";
import CHTextArea from "../../../../../../../components/ubold/molecules/forms/CHTextArea";

import utils from "../../../../../../../utils";
import { sectionProps } from "@types";
import CHTextField from "components/ubold/molecules/forms/CHTextField";
import CHTextView from "components/ubold/molecules/forms/CHTextView";
import CHSelect from "components/ubold/molecules/forms/CHSelect";

function Attributes(props: sectionProps) {
  const { baseLink, accordionId, onSubmit, state, setState } = props;

  let { id } = useParams<{ id: string }>();

  const doneStatus = [5, 6, 10];

  return (
    <AccordionPane
      id="otherInfo"
      name="Other Info"
      accordionId={accordionId}
      baseLink={baseLink}
      onSubmit={onSubmit}
      shown={
        !!(
          utils.form.isFieldError(state, "rumanage_remarks") ||
          utils.form.isFieldError(state, "referral_code") ||
          utils.form.isFieldError(state, "signed_date") ||
          utils.form.isFieldError(state, "signed_by_1") ||
          utils.form.isFieldError(state, "signed_by_2") ||
          utils.form.isFieldError(state, "signed_by_3")
        )
      }
      state={state}
      setState={setState}
    >
      <CHAsyncSelect
        name="signed_by_1"
        read_only_label="signed_by_1_name"
        title="Signed By 1"
        readOnly={
          (!id && state?.order_type === 2) ||
          (id &&
            doneStatus.includes(state["original___order_status"]))
        }
        isRequired
        data="crm/user?__type__=select_entries&limit=10"
        itemsExtractor={(value: { username: string; id: number }) => {
          return {
            label: value.username,
            value: value.id,
          };
        }}
      />
      <CHAsyncSelect
        name="signed_by_2"
        read_only_label="signed_by_2_name"
        title="Signed By 2"
        readOnly={
          (!id && state?.order_type === 2) ||
          (id &&
            doneStatus.includes(state["original___order_status"]))
        }
        data="crm/user?__type__=select_entries&limit=10"
        itemsExtractor={(value: { username: string; id: number }) => {
          return {
            label: value.username,
            value: value.id,
          };
        }}
      />
      <CHAsyncSelect
        name="signed_by_3"
        read_only_label="signed_by_3_name"
        title="Signed By 3"
        readOnly={
          (!id && state?.order_type === 2) ||
          (id &&
            doneStatus.includes(state["original___order_status"]))
        }
        data="crm/user?__type__=select_entries&limit=10"
        itemsExtractor={(value: { username: string; id: number }) => {
          return {
            label: value.username,
            value: value.id,
          };
        }}
      />
      <CHDateTimePicker
        name="signed_date"
        title="Signed Date"
        type="date"
        readOnly={
          (!id && state?.order_type === 2) ||
          (id &&
            doneStatus.includes(state["original___order_status"]))
        }
      />
      <CHTextField
        name="referral_code"
        title="Referral Code"
        maxLength={8}
        readOnly
      />
      <CHTextArea
        name="rumanage_remarks"
        title="RuManage Remarks"
        maxLength={150}
        withCharCounter
        readOnly={
          id && doneStatus.includes(state["original___order_status"])
        }
      />
      {state?.order_type !== 2 ? (
        <CHTextView name="transfer_from" title="Transfer From" />
      ) : (
        <CHTextView
          name="extend_from_order_number"
          title="Extend From"
          enableHyperlink
          dataId={state?.extend_from_order_id}
          locationPath="/sales/order_sop"
        />
      )}
      <CHSelect
        name="source"
        title="Source of Order"
        data={{
          optionField: "order_new_sources",
        }}
        readOnly
      />
      <CHTextView
        name="date_created"
        title="Date Created"
        renderer={(value: string) => {
          return utils.formatter.dateTime(value);
        }}
      />
      <CHTextView
        name="date_updated"
        title="Date Updated"
        renderer={(value: string) => {
          return utils.formatter.dateTime(value);
        }}
      />
      <CHTextView name="cancel_by" title="Cancel By" />
      <CHTextView name="created_by" title="Created By" />
    </AccordionPane>
  );
}

export default withRouter(Attributes);
