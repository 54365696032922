import {isMobile} from "react-device-detect";

function SecondaryDataHandler(data) {
    return <div className={isMobile ? 'secondary-data-panel-mobile' : 'secondary-data-panel'}>
        <div className="row mb-2">
            <div className="col mw-200">
                <div>
                    <b>Total Rooms</b>: <br/>
                    {data.total_room}
                </div>
            </div>
        </div>
    </div>;
};

export default SecondaryDataHandler;