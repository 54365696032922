import { isMobile } from "react-device-detect";

import utils from "../../../utils";

function SecondaryDataHandler(data) {
  return (
    <div
      className={
        isMobile
          ? "secondary-data-panel-mobile"
          : "secondary-data-panel"
      }
    >
      <div className="row mb-2">
        <div className="col mw-200">
          <div>
            <b>Addon</b>: <br />
            {utils.formatter.currency(data.addon)}
          </div>
        </div>
        <div className="col mw-200">
          <div>
            <b>Addons Finance Category</b>: <br />
            <div
              style={{ maxWidth: "200px" }}
              dangerouslySetInnerHTML={{
                __html:
                  data["addon_category"] &&
                  data["addon_category"].length > 0
                    ? data["addon_category"].join("<br />")
                    : "-",
              }}
            />
          </div>
        </div>
        <div className="col mw-200">
          <div>
            <b>Service Request</b>: <br />
            {utils.formatter.currency(data.service_request)}
          </div>
        </div>
        <div className="col mw-200">
          <div>
            <b>Additional Fee</b>: <br />
            {utils.formatter.currency(data.additional_fee)}
          </div>
        </div>
        <div className="col mw-200">
          <div>
            <b>Late Fine Fee</b>: <br />
            {utils.formatter.currency(data.late_fine_fee)}
          </div>
        </div>
        <div className="col mw-200">
          <div>
            <b>Actual Payment Amount</b>: <br />
            {utils.formatter.currency(data.actual_payment_amount)}
          </div>
        </div>
        <div className="col mw-200">
          <div>
            <b>Trx Fee</b>: <br />
            {utils.formatter.currency(data.trx_fee)}
          </div>
        </div>
        <div className="col mw-200">
          <div>
            <b>Discount Amount</b>: <br />
            {utils.formatter.currency(data.discount_amount)}
          </div>
        </div>
        <div className="col mw-200">
          <div>
            <b>Discount Building</b>: <br />
            {utils.formatter.currency(data.discount_building)}
          </div>
        </div>
        <div className="col mw-200">
          <div>
            <b>Building Price</b>: <br />
            {utils.formatter.currency(data.building_price)}
          </div>
        </div>
        <div className="col mw-200">
          <div>
            <b>Payment Method</b>: <br />
            {data.payment_method}
          </div>
        </div>
        <div className="col mw-200">
          <div>
            <b>Bank Name</b>: <br />
            {data.bank_name}
          </div>
        </div>
        <div className="col mw-200">
          <div>
            <b>Midtrans Number</b>: <br />
            {data.midtrans_number ? data.midtrans_number : "-"}
          </div>
        </div>
        <div className="col mw-200">
          <div>
            <b>Payment Date</b>: <br />
            {utils.formatter.dateTime(data.payment_date)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SecondaryDataHandler;
