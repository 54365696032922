import { useState, useEffect } from "react";

import { useParams, withRouter } from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import TabHeaders from "../../../../components/ubold/organisms/TabHeaders";
import TabContent from "../../../../components/ubold/organisms/TabContent";
import TabbedForm from "../../../../components/ubold/organisms/TabbedForm";

import Order from "./Tabs/Order";
import OrderAdditionalDiscounts from "./Tabs/OrderAdditionalDiscounts";
import OrderAddonBuildings from "./Tabs/OrderAddonBuildings";
import OrderServiceSchedules from "./Tabs/OrderServiceSchedules";
import definitions from "./definitions";

function OrderAddEditPage() {
  let { id } = useParams();

  const initialState = {
    payment_plan_discount: 0,
    price: 0,
    discount_amount: 0,
    deposit: 0,
    status_of_done: 0,
    tenant_category: 1,
    status: 1,
  };

  const [state, setState] = useState(initialState);
  const doneStatus = [4, 5, 6];

  useEffect(() => {
    if (
      parseInt(state["discount_amount"]) === 0 ||
      state["discount_amount"] === ""
    ) {
      setState((prevState) => ({
        ...prevState,
        discount_notes: "",
      }));
    }
    if (doneStatus.includes(state["status"])) {
      setState((prevState) => ({
        ...prevState,
        deposit_notes: "",
      }));
    }
  }, [state["discount_amount"], state["status"]]);

  return (
    <Page
      title={
        (id === undefined ? "Add New" : "Edit") +
        " " +
        definitions.title
      }
      restAccessCode={definitions.restAccessCode}
    >
      <TabbedForm
        id={id}
        state={state}
        setState={setState}
        definitions={definitions}
      >
        <TabHeaders />
        <TabContent>
          <Order />
          <OrderAdditionalDiscounts />
          <OrderAddonBuildings />
          <OrderServiceSchedules />
        </TabContent>
      </TabbedForm>
    </Page>
  );
}

export default withRouter(OrderAddEditPage);
