import React from "react";

import { Link } from "react-router-dom";

import { isMobile } from "react-device-detect";

import configs from "../../../../configs";
import utils from "../../../../utils";

import Tooltip from "../../atoms/tooltip/Tooltip";

import BaseTableResponsiveContainer from "./BaseTableResponsive.css";
import useGlobalState from "state";

const BaseTableResponsive = (props) => {
  const {
    actions = [],
    accesses,
    cellRenderer = null,
    columns = [],
    columnsHead,
    columnsContentStart = 0,
    columnsContentEnd = columns.length,
    data,
    editAsDetail = false,
    errorMessage,
    fieldAccesses,
    hasSecondaryData = false,
    hideColumnNumbering = false,
    hideEditButton = false,
    hideRemoveButton,
    isEmptyData,
    isTeamMenu = false,
    isFetching,
    location,
    ordering,
    path,
    removeIdentifier,
    restAccessCode,
    rowOffset,
    secondaryDataHandler = null,
    secondaryDataItem = 5,
    secondaryDataRow = 1,
    secondaryDataRowHeight = 50,
    selectedPage,
    selectedRowPerPage,
    setData,
    showDetailButton = false,
    showLoadingSpinner = true,
    style,
    sweetAlert,
    user,
    isFetched,
    fetch,
  } = props;

  const paramRestAccessCode = `rest_access_code=${restAccessCode}`;
  const [constants] = useGlobalState("constant_values");

  const fetchSecondaryData = (id, index) => {
    setData(() => {
      setData(
        [...data].map((object, i) => {
          if (i === index) {
            return {
              ...object,
              secondaryPanelVisible: true,
            };
          } else {
            return object;
          }
        })
      );
    });

    utils.httpClient.get(
      configs.apiUrl +
        utils.commons.stripMultipleSlashes(path + "/" + id) +
        "?__type__=secondary_data",
      (response) => {
        setData(() => {
          setData(
            [...data].map((object, i) => {
              if (i === index) {
                return {
                  ...object,
                  secondaryPanelVisible: true,
                  secondaryPanelData: response,
                  secondaryPanelError: null,
                };
              } else {
                return object;
              }
            })
          );
        });
      },
      (error, message) => {
        setData(() => {
          setData(
            [...data].map((object, i) => {
              if (i === index) {
                return {
                  ...object,
                  secondaryPanelVisible: true,
                  secondaryPanelData: null,
                  secondaryPanelError: message,
                };
              } else {
                return object;
              }
            })
          );
        });
      }
    );
  };

  const removeRecord = (id, identifier = null) => {
    sweetAlert
      .fire({
        title: "Confirmation",
        html:
          "Are you sure to delete <strong>" +
          (identifier === null ? id : identifier) +
          "</strong>?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      })
      .then((result) => {
        if (result.isConfirmed) {
          utils.httpClient.delete(
            configs.apiUrl +
              (path + "/" + id + "/").replace(/\/+/g, "/") +
              "?" +
              paramRestAccessCode +
              "&object_id=" +
              id,
            null,
            () => {
              sweetAlert.fire(
                "Deleted!",
                "Record has been deleted.",
                "success"
              );

              fetch(selectedPage, selectedRowPerPage);
            },
            (error, message) => {
              const errorCode = error?.response?.status;
              let messageError = "";

              if (!errorCode === 400) {
                messageError = error?.response?.data
                  ? error?.response?.data
                      .split("\n")[1]
                      .replace("['", "")
                      .replace("']", "")
                  : message;
              } else {
                messageError = error?.response?.data?.error_message;
              }

              sweetAlert.fire("Error", messageError, "error");
            }
          );
        }
      });
  };

  return (
    <BaseTableResponsiveContainer
      className="table-responsive"
      style={style}
    >
      <table className="table table-centered table-nowrap mb-0">
        <thead className="thead-light">
          <tr>
            {!hideEditButton ||
            !hideRemoveButton ||
            showDetailButton ||
            (actions && actions.length > 0) ? (
              <th
                className="border-0"
                style={{
                  width: "80px",
                  textAlign: "center",
                  paddingLeft: "18px",
                }}
              >
                Action
              </th>
            ) : (
              <></>
            )}
            {!hideColumnNumbering && (
              <th className="border-0" style={{ width: "20px" }}>
                No
              </th>
            )}
            {columnsHead.map((value, index) => {
              let orderingHeader = <></>;

              let canDoOrdering =
                value["canDoOrdering"] === undefined
                  ? true
                  : value["canDoOrdering"];

              let orderingParam =
                value["sortId"] !== undefined
                  ? value["sortId"]
                  : value["id"];

              if (canDoOrdering && ordering === orderingParam) {
                orderingHeader = (
                  <span>
                    <i className="fas fa-arrow-down" />
                  </span>
                );
              } else if (
                canDoOrdering &&
                ordering === "-" + orderingParam
              ) {
                orderingHeader = (
                  <span>
                    <i className="fas fa-arrow-up" />
                  </span>
                );
              }

              let isValueHidden = utils.access.isFieldHidden(
                fieldAccesses,
                restAccessCode + ".table." + value.id
              );

              return !isValueHidden ? (
                <th
                  style={{
                    cursor: canDoOrdering ? "pointer" : "unset",
                    textAlign: value.center ? "center" : "left",
                  }}
                  onClick={() => {
                    if (canDoOrdering && ordering !== orderingParam) {
                      fetch(
                        selectedPage,
                        selectedRowPerPage,
                        orderingParam
                      );
                    } else if (canDoOrdering) {
                      fetch(
                        selectedPage,
                        selectedRowPerPage,
                        "-" + orderingParam
                      );
                    }
                  }}
                  className="border-0"
                  key={"columns" + index}
                >
                  {orderingHeader} {value["name"]}
                </th>
              ) : (
                <></>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {isFetching && showLoadingSpinner ? (
            <tr>
              <td
                colSpan={columns.length + 2}
                style={{
                  padding: "5px 0px 0px 0px",
                }}
              >
                <div className="spinner-border m-2" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </td>
            </tr>
          ) : !isFetching &&
            (isEmptyData ||
              (errorMessage !== "" && errorMessage !== null)) ? (
            <td
              colSpan={columns.length + 2}
              style={{
                padding: "25px 0px 10px 15px",
              }}
            >
              {errorMessage !== "" && errorMessage !== null ? (
                <span style={{ textDecoration: "underline" }}>
                  {errorMessage}
                </span>
              ) : isEmptyData ? (
                <span style={{ padding: "0px" }}>
                  {isFetched
                    ? "There is no data"
                    : "Please select a filter first"}
                </span>
              ) : (
                ""
              )}
            </td>
          ) : (
            <></>
          )}
          {data &&
            data.map((value, index) => {
              const columnEntries = [];

              for (
                let i = columnsContentStart;
                i < columnsContentEnd;
                i++
              ) {
                let valueAltered = "???";

                if (value[columns[i].id] !== undefined) {
                  valueAltered =
                    value[columns[i].id] !== "" &&
                    value[columns[i].id] !== null
                      ? String(value[columns[i].id])
                      : "-";
                }

                if (
                  columns[i].renderer &&
                  utils.commons.isFunction(columns[i].renderer)
                ) {
                  valueAltered = columns[i].renderer(
                    valueAltered,
                    value,
                    constants?.[columns[i].referStateFieldName]
                  );
                }

                let isValueHidden = utils.access.isFieldHidden(
                  fieldAccesses,
                  restAccessCode + ".table." + columns[i].id
                );

                if (isValueHidden) {
                  valueAltered = "-hidden-";
                }

                const contentLength = utils.removeHtmlTags(
                  utils.commons.isFunction(cellRenderer)
                    ? cellRenderer(
                        index,
                        i,
                        valueAltered,
                        value,
                        props
                      )
                    : valueAltered
                ).length;

                if (!isValueHidden && columns[i].isSafeContent) {
                  if (
                    columns[i].tooltip &&
                    columns[i].tooltip.enabled &&
                    contentLength > columns[i].tooltip.limitCharacters
                  ) {
                    columnEntries[i] = (
                      <td
                        key={"cell" + index + "_" + i}
                        className="border-0"
                        style={{
                          cursor: "pointer",
                          textAlign: columns[i].center
                            ? "center"
                            : "left",
                          whiteSpace: columns[i].wrap
                            ? "pre-wrap"
                            : "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth:
                            columns[i].maxWidthColumn ?? "90px",
                        }}
                      >
                        <Tooltip
                          contentHover={"Click to see detail"}
                          contentMain={utils.removeHtmlTags(
                            utils.commons.isFunction(cellRenderer)
                              ? cellRenderer(
                                  index,
                                  i,
                                  valueAltered,
                                  value,
                                  props
                                )
                              : columns[i].renderer
                              ? value[columns[i].id]
                              : valueAltered // Ternary within ternary: Check if there is columns[i].renderer then use the text from value[columns[i].id]. Because if we want to use <Tooltip /> & ellipsis text with <a /> that pass with renderer prop, we only able to ellipsis the text directly on renderer prop.
                          )}
                          trigger={
                            columns[i].tooltip.trigger
                              ? columns[i].tooltip.trigger
                              : "click"
                          }
                          zIndex={
                            columns[i].tooltip.zIndex
                              ? columns[i].tooltip.zIndex
                              : 1
                          }
                        >
                          <span
                            dangerouslySetInnerHTML={{
                              __html: utils.commons.isFunction(
                                cellRenderer
                              )
                                ? cellRenderer(
                                    index,
                                    i,
                                    valueAltered,
                                    value,
                                    props
                                  )
                                : columns[i].renderer
                                ? valueAltered
                                : utils.formatter.truncateWithEllipsis(
                                    valueAltered,
                                    columns[i].tooltip.limitCharacters
                                  ), // Ternary within ternary: Check if there is columns[i].renderer then use the text from renderer prop (avoid truncateWithEllipsis() for HTML string that cause blank render) else if general string, we will truncateWithEllipsis()
                            }}
                          />
                        </Tooltip>
                      </td>
                    );
                  } else {
                    columnEntries[i] = (
                      <td
                        key={"cell" + index + "_" + i}
                        className="border-0"
                        style={{
                          textAlign: columns[i].center
                            ? "center"
                            : "left",
                          whiteSpace: columns[i].wrap
                            ? "pre-wrap"
                            : "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth:
                            columns[i].maxWidthColumn ?? "90px",
                        }}
                        dangerouslySetInnerHTML={{
                          __html: utils.commons.isFunction(
                            cellRenderer
                          )
                            ? cellRenderer(
                                index,
                                i,
                                valueAltered,
                                value,
                                props
                              )
                            : valueAltered,
                        }}
                      />
                    );
                  }
                } else if (!isValueHidden) {
                  if (
                    columns[i].tooltip &&
                    columns[i].tooltip.enabled &&
                    contentLength > columns[i].tooltip.limitCharacters
                  ) {
                    columnEntries[i] = (
                      <td
                        key={"cell" + index + "_" + i}
                        className="border-0"
                        style={{
                          cursor: "pointer",
                          textAlign: columns[i].center
                            ? "center"
                            : "left",
                          whiteSpace: columns[i].wrap
                            ? "pre-wrap"
                            : "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth:
                            columns[i].maxWidthColumn ?? "90px",
                        }}
                      >
                        <Tooltip
                          contentHover={"Click to see detail"}
                          contentMain={utils.removeHtmlTags(
                            utils.commons.isFunction(cellRenderer)
                              ? cellRenderer(
                                  index,
                                  i,
                                  valueAltered,
                                  value,
                                  props
                                )
                              : columns[i].renderer
                              ? value[columns[i].id]
                              : valueAltered // Ternary within ternary: Check if there is columns[i].renderer then use the text from value[columns[i].id]. Because if we want to use <Tooltip /> & ellipsis text with <a /> that pass with renderer prop, we only able to ellipsis the text directly on renderer prop.
                          )}
                          trigger={
                            columns[i].tooltip.trigger
                              ? columns[i].tooltip.trigger
                              : "click"
                          }
                          zIndex={
                            columns[i].tooltip.zIndex
                              ? columns[i].tooltip.zIndex
                              : 1
                          }
                        >
                          <span
                            dangerouslySetInnerHTML={{
                              __html: utils.commons.isFunction(
                                cellRenderer
                              )
                                ? cellRenderer(
                                    index,
                                    i,
                                    valueAltered,
                                    value,
                                    props
                                  )
                                : columns[i].renderer
                                ? valueAltered
                                : utils.formatter.truncateWithEllipsis(
                                    valueAltered,
                                    columns[i].tooltip.limitCharacters
                                  ), // Ternary within ternary: Check if there is columns[i].renderer then use the text from renderer prop (avoid truncateWithEllipsis() for HTML string that cause blank render) else if general string, we will truncateWithEllipsis()
                            }}
                          />
                        </Tooltip>
                      </td>
                    );
                  } else {
                    columnEntries[i] = (
                      <td
                        key={"cell" + index + "_" + i}
                        className="border-0"
                        style={{
                          textAlign: columns[i].center
                            ? "center"
                            : "left",
                          whiteSpace: columns[i].wrap
                            ? "pre-wrap"
                            : "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth:
                            columns[i].maxWidthColumn ?? "90px",
                        }}
                        dangerouslySetInnerHTML={{
                          __html: utils.commons.isFunction(
                            cellRenderer
                          )
                            ? cellRenderer(
                                index,
                                i,
                                valueAltered,
                                value,
                                props
                              )
                            : valueAltered,
                        }}
                      ></td>
                    );
                  }
                }
              }

              const actionEntries = [];

              for (let i = 0; i < actions.length; i++) {
                if (actions[i].type === "link") {
                  actionEntries[i] = (
                    <Link
                      key={"action" + index}
                      to={actions[i].path.replace("{ID}", value.id)}
                      className="dropdown-item"
                      style={{ cursor: "pointer" }}
                    >
                      {actions[i].icon && actions[i].icon !== "" ? (
                        <i
                          className={
                            actions[i].icon +
                            " mr-2 text-muted vertical-middle"
                          }
                        />
                      ) : (
                        ""
                      )}
                      {actions[i].name}
                    </Link>
                  );
                } else {
                  actionEntries[i] = (
                    <a
                      key={"action" + index}
                      onClick={() => actions[i].action(value, props)}
                      className="dropdown-item"
                      style={{ cursor: "pointer" }}
                    >
                      {actions[i].icon && actions[i].icon !== "" ? (
                        <i
                          className={
                            actions[i].icon +
                            " mr-2 text-muted vertical-middle"
                          }
                        />
                      ) : (
                        ""
                      )}
                      {actions[i].name}
                    </a>
                  );
                }
              }

              return (
                <>
                  <tr
                    title={
                      hasSecondaryData
                        ? "Click to " +
                          (hasSecondaryData &&
                          value["secondaryPanelVisible"]
                            ? "close "
                            : "open ") +
                          "detail data."
                        : "Row data."
                    }
                    style={{
                      cursor: hasSecondaryData ? "pointer" : "unset",
                    }}
                    onClick={(e) => {
                      if (
                        !e.target.id.startsWith("rowAction") &&
                        e.target.id !== "rowActionIcon" + index
                      ) {
                        if (value["secondaryPanelVisible"]) {
                          setData(() => {
                            setData(
                              [...data].map((object, i) => {
                                if (i === index) {
                                  return {
                                    ...object,
                                    secondaryPanelVisible: false,
                                  };
                                } else {
                                  return object;
                                }
                              })
                            );
                          });
                        } else {
                          if (value["secondaryPanelData"]) {
                            setData(
                              [...data].map((object, i) => {
                                if (i === index) {
                                  return {
                                    ...object,
                                    secondaryPanelVisible: true,
                                  };
                                } else {
                                  return object;
                                }
                              })
                            );
                          } else {
                            fetchSecondaryData(value.id, index);
                          }
                        }
                      }
                    }}
                  >
                    {!hideEditButton ||
                    !hideRemoveButton ||
                    showDetailButton ||
                    (actions && actions.length > 0) ? (
                      <td
                        className="border-0"
                        style={{ textAlign: "center" }}
                      >
                        <div className="btn-group dropright">
                          <a
                            data-toggle="dropdown"
                            aria-expanded="true"
                            id={"rowAction" + index}
                            className="table-action-btn dropdown-toggle arrow-none btn btn-light btn-xs dropdown-table"
                          >
                            <i
                              id={"rowActionIcon" + index}
                              className="mdi mdi-dots-horizontal"
                            />
                          </a>
                          <div className="dropdown-menu">
                            {!hideEditButton ? (
                              <Link
                                id={"rowActionEdit" + index}
                                onClick={(e) => {
                                  e.preventDefault();
                                  if (
                                    accesses.includes(
                                      restAccessCode + ".update"
                                    ) ||
                                    user["is_super_user"] ||
                                    (isTeamMenu &&
                                      user[
                                        "has_team_management_access"
                                      ])
                                  ) {
                                    props.history.push(
                                      utils.commons.stripMultipleSlashes(
                                        location.pathname + "/edit/"
                                      ) + value.id
                                    );
                                  } else {
                                    sweetAlert.fire(
                                      "Error!",
                                      "You have no access to do this action.",
                                      "error"
                                    );
                                  }
                                }}
                                className="dropdown-item"
                              >
                                <i
                                  className={
                                    "mdi mr-2 text-muted vertical-middle" +
                                    (editAsDetail
                                      ? " mdi-eye"
                                      : " mdi-pencil")
                                  }
                                />
                                {!editAsDetail ? "Edit" : "Detail"}
                              </Link>
                            ) : (
                              <></>
                            )}
                            {!hideRemoveButton ? (
                              <a
                                id={"rowActionRemove" + index}
                                onClick={() => {
                                  if (
                                    accesses.includes(
                                      restAccessCode + ".destroy"
                                    ) ||
                                    user["is_super_user"] ||
                                    (isTeamMenu &&
                                      user[
                                        "has_team_management_access"
                                      ])
                                  ) {
                                    removeRecord(
                                      value.id,
                                      utils.commons.isFunction(
                                        removeIdentifier
                                      )
                                        ? removeIdentifier(value)
                                        : removeIdentifier
                                        ? value[removeIdentifier]
                                        : value.name
                                        ? value.name
                                        : value.id
                                    );
                                  } else {
                                    sweetAlert.fire(
                                      "Error!",
                                      "You have no access to do this action.",
                                      "error"
                                    );
                                  }
                                }}
                                className="dropdown-item"
                                style={{
                                  cursor: "pointer",
                                }}
                              >
                                <i className="mdi mdi-delete mr-2 text-muted vertical-middle" />
                                Remove
                              </a>
                            ) : (
                              <></>
                            )}
                            {showDetailButton ? (
                              <Link
                                id={"rowActionDetail" + index}
                                to={
                                  location.pathname + "/" + value.id
                                }
                                className="dropdown-item"
                              >
                                <i className="mdi mdi-eye mr-2 text-muted vertical-middle" />
                                Detail
                              </Link>
                            ) : (
                              <></>
                            )}
                            {actionEntries}
                          </div>
                        </div>
                      </td>
                    ) : (
                      <></>
                    )}
                    {!hideColumnNumbering && (
                      <td
                        className="border-0"
                        style={{ textAlign: "center" }}
                      >
                        {rowOffset + index + 1}.{" "}
                      </td>
                    )}
                    {columnEntries}
                  </tr>
                  {hasSecondaryData &&
                  value["secondaryPanelVisible"] ? (
                    <tr>
                      <td
                        className="border-0"
                        style={{
                          padding: "10px 5px 10px 75px",
                          backgroundColor: "#efe",
                        }}
                        colSpan={columns.length + 2}
                      >
                        <div
                          style={{
                            padding: "0px 10px",
                            height: isMobile
                              ? value["secondaryPanelData"]
                                ? secondaryDataItem *
                                    secondaryDataRowHeight +
                                  10 +
                                  "px"
                                : "25px"
                              : value["secondaryPanelData"]
                              ? secondaryDataRow *
                                  secondaryDataRowHeight +
                                "px"
                              : "25px",
                          }}
                        >
                          {value["secondaryPanelError"] ||
                          value["secondaryPanelData"] ? (
                            <div>
                              {value["secondaryPanelError"]
                                ? value["secondaryPanelError"]
                                : utils.commons.isFunction(
                                    secondaryDataHandler
                                  )
                                ? secondaryDataHandler(
                                    utils.access.doAccessProtectionInSecondaryData(
                                      restAccessCode,
                                      fieldAccesses,
                                      value["secondaryPanelData"]
                                    )
                                  )
                                : "Handler not defined"}
                            </div>
                          ) : (
                            <div
                              className="spinner-border m-1"
                              style={{
                                width: "15px",
                                height: "15px",
                              }}
                              role="status"
                            >
                              <span className="sr-only">
                                Loading...
                              </span>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <></>
                  )}
                  <tr>
                    <td
                      className="border-0"
                      colSpan={100}
                      style={{ padding: "0px" }}
                    >
                      <div
                        style={{
                          width: "100%",
                          height: "2px",
                          backgroundColor: "#f5f6f8",
                        }}
                      >
                        {" "}
                      </div>
                    </td>
                  </tr>
                </>
              );
            })}
        </tbody>
      </table>
    </BaseTableResponsiveContainer>
  );
};

export default BaseTableResponsive;
