import configBuilder from "../../../../components/ubold/ConfigBuilder";

import pageDefinitions from "../definitions";

const definitions = {
  ...pageDefinitions,
  tabs: [
    {
      id: "invoice",
      name: "Invoice Info",
    },
    {
      id: "t_invoice_details",
      name: "Invoice Details",
    },
    {
      id: "t_payments",
      name: "Payments",
    },
  ],
};

export default configBuilder.buildAddEditPageConfig(
  definitions,
  [],
  [],
  "detail"
);
