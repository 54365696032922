import configBuilder from "../../../../components/ubold/ConfigBuilder";

import pageDefinitions from "../definitions";

import utils from "../../../../utils";

const definitions = {
  ...pageDefinitions,
  tabs: [
    {
      id: "service",
      name: "Service",
    },
    {
      id: "t_images",
      name: "Images",
    },
    {
      id: "t_request_logs",
      name: "RequestLog",
    },
  ],
  validators: {
    order: (value) => {
      return utils.validator.validateRequired(value);
    },
    tenant: (value) => {
      return utils.validator.validateRequired(value);
    },
    status: (value) => {
      return utils.validator.validateRequired(value);
    },
    building_service: (value) => {
      return utils.validator.validateRequired(value);
    },
  },
};

export default configBuilder.buildAddEditPageConfig(definitions);
