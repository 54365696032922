import {useEffect} from "react";

import {withRouter, Link, useLocation} from "react-router-dom";

import logoDark from '../../assets/logo-dark.png';

import utils from "../../utils";

function LogoutPage(props) {
    const location = useLocation();

    useEffect(() => {
        const t = utils.store.get('t');

        if(t) {
            props.history.push('/');
        }
    }, []);

    let urlSearch = new URLSearchParams(location.search)

    const next = urlSearch.get('next');

    return (
        <div className="auth-fluid">
            <div className="auth-fluid-form-box">
                <div className="align-items-center d-flex h-100">
                    <div className="card-body">
                        <div className="auth-brand text-center text-lg-left">
                            <div className="auth-logo">
                                <a href="#" className="logo logo-dark text-center">
                                    <span className="logo-lg">
                                        <img src={logoDark} alt="" height="33" />
                                    </span>
                                </a>
                            </div>
                        </div>
                        <div className="text-center">
                            <div className="mt-4">
                                <div className="logout-checkmark">
                                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                                        <circle className="path circle" fill="none" stroke="#4bd396" strokeWidth="6"
                                                strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
                                        <polyline className="path check" fill="none" stroke="#4bd396" strokeWidth="6"
                                                  strokeLinecap="round" strokeMiterlimit="10"
                                                  points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
                                    </svg>
                                </div>
                            </div>
                            <h3>See you again !</h3>
                            <p className="text-muted"> You are now successfully sign out. </p>
                        </div>
                        <footer className="footer footer-alt">
                            <p className="text-muted">Back to
                                <Link to={'/login' + (next ? '?next=' + next : '')} className="text-muted ml-1">
                                    <b>SignIn</b>
                                </Link>
                            </p>
                        </footer>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withRouter(LogoutPage);