import {withRouter} from "react-router-dom";

import TabPane from "../../../../../../components/ubold/organisms/TabPane";
import TabPaneSection from "../../../../../../components/ubold/organisms/TabPaneSection";

import CHSelect from "../../../../../../components/ubold/molecules/forms/CHSelect";
import CHAsyncSelect from "../../../../../../components/ubold/molecules/forms/CHAsyncSelect";
import CHTextArea from "../../../../../../components/ubold/molecules/forms/CHTextArea";
import CHTextField from "../../../../../../components/ubold/molecules/forms/CHTextField";

function Structure(props) {
    const {id, definitions, tab, state, setState} = props;

    return (
        <TabPane
            id={id}
            definitions={definitions}
            tab={tab}
            state={state}
            setState={setState}>
            <TabPaneSection style={{padding: '25px 16px 0px'}}>
                <CHTextField
                    title='Name'
                    name='name'
                    type='text'
                    maxLength={50}
                    isRequired
                />
                <CHSelect
                    title='Can Have Team?'
                    name='can_have_team'
                    data={[
                        {
                            label: 'Yes',
                            value: true
                        },
                        {
                            label: 'No',
                            value: false
                        },
                    ]}
                    isRequired
                />
                <CHAsyncSelect
                    title='Parent'
                    name='parent'
                    data='crm/structure?__type__=select_entries'
                />
                <CHTextArea
                    title='Description'
                    name='description'
                    maxLength={255}
                />
            </TabPaneSection>
        </TabPane>
    );
}

export default withRouter(Structure);