import { useParams, withRouter } from "react-router-dom";

import TabPaneSecondary from "../../../../../../components/ubold/organisms/TabPaneSecondary";

import COneOrManyToManyFieldV2 from "components/ubold/molecules/forms/COneOrManyToManyFieldV2";

function RukiKoin(props) {
  const { definitions, tab, state, setState } = props;

  let { id } = useParams();

  return (
    <TabPaneSecondary
      definitions={definitions}
      restAccessCodeNamespace="ruki_koin"
      tab={tab}
      state={state}
      setState={setState}
    >
      <COneOrManyToManyFieldV2
        id={id}
        name="history_koin"
        readOnly
        columns={[
          {
            name: "category",
            type: "text",
            title: "Category",
            data: {
              optionField: "ruki_koin_category",
            },
            readOnly: true,
          },
          {
            name: "koin",
            type: "number",
            title: "Koin",
            readOnly: true,
          },
          {
            name: "source",
            type: "text",
            title: "Source",
            readOnly: true,
          },
          {
            name: "reference",
            type: "text",
            title: "Reference",
            readOnly: true,
          },
        ]}
      />
    </TabPaneSecondary>
  );
}

export default withRouter(RukiKoin);
