import { useEffect, useState } from "react";

import { useParams, withRouter } from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import definitions from "./definitions";

import TabHeaders from "../../../../components/ubold/organisms/TabHeaders";
import TabContent from "../../../../components/ubold/organisms/TabContent";
import TabbedForm from "../../../../components/ubold/organisms/TabbedForm";

import Service from "./Tabs/Service";
import RequestLog from "./Tabs/RequestLog";

function CAddEditPage() {
  let { id } = useParams();

  const initialState = {
    status: 2,
    rate: null,
    service_images: [],
    building_service_request_type: 1,
  };

  const [state, setState] = useState(initialState);

  // when id is not available (create form) we set the value of status to 1 (Waiting)
  useEffect(() => {
    if (!id) {
      setState((prevState) => ({
        ...prevState,
        status: 1,
      }));
    }
  }, [id]);

  return (
    <Page
      title={
        (id === undefined ? "Add New" : "Edit") +
        " " +
        definitions.title
      }
      restAccessCode={definitions.restAccessCode}
    >
      <TabbedForm
        id={id}
        state={state}
        setState={setState}
        definitions={definitions}
      >
        <TabHeaders />
        <TabContent>
          <Service />
          <RequestLog />
        </TabContent>
      </TabbedForm>
    </Page>
  );
}

export default withRouter(CAddEditPage);
