import { useState } from "react";
import { useParams, withRouter } from "react-router";
import definitions from "./definitions";

import Page from "components/ubold/organisms/Page";
import Form from "components/ubold/organisms/Form";
import CVRichTextField from "components/ubold/molecules/forms/CVRichTextField";
import CVTextField from "components/ubold/molecules/forms/CVTextField";

function TermsAndConditionViewPage() {
  let { id }: { id: string } = useParams();

  const initialState: any = {};
  const [state, setState] = useState(initialState);

  return (
    <Page
      title={(!id ? "Add New" : "Edit") + " " + definitions.title}
      restAccessCode={definitions.restAccessCode}
    >
      <Form
        id={id}
        definitions={definitions}
        state={state}
        setState={setState}
      >
        <CVTextField
          name="cancellation_policy_title"
          title={
            state.cancellation_policy_title ?? "Kebijakan Pembatalan"
          }
          isRequired
          readOnly
        />
        <CVTextField
          name="refund_policy_title"
          title={
            state.refund_policy_title ?? "Kebijakan Pengembalian Dana"
          }
          isRequired
          readOnly
        />
        <CVRichTextField name="cancellation_policy" readOnly />
        <CVRichTextField name="refund_policy" readOnly />
        <CVTextField
          name="parking_policy_title"
          title={state.parking_policy_title ?? "Kebijakan Parkir"}
          isRequired
          readOnly
        />
        <CVTextField
          name="payment_policy_title"
          title={
            state.payment_policy_title ??
            "Kebijakan Pembayaran Terpisah"
          }
          isRequired
          readOnly
        />
        <CVRichTextField
          name="parking_policy"
          title="Kebijakan Parkir"
          isRequired
          readOnly
        />
        <CVRichTextField
          name="payment_policy"
          title="Kebijakan Pembayaran Deposit"
          isRequired
          readOnly
        />
      </Form>
    </Page>
  );
}

export default withRouter(TermsAndConditionViewPage);
