import { withRouter } from "react-router-dom";

import CImageUpload from "../../atoms/forms/CImageUpload";

import useGlobalState from "../../../../state";

import utils from "../../../../utils";

function CVImageUpload(props) {
  const {
    name,
    title,
    isRequired = false,
    restAccessCode,
    helperText,
    maxFile = 1,
    formColumn = 2,
    overrideLayout = null,
    state,
    setState,
    maxSize,
    alertAllowedExtension,
    alertFileSize,
    allowedImageExtension,
  } = props;

  let formColumnReal = 12 / formColumn;

  const [user] = useGlobalState("user");
  const isSuperUser = user ? user.is_super_user : false;
  const [fieldAccesses] = useGlobalState("field_accesses");
  const [fieldAccessReversePolicy] = useGlobalState(
    "field_access_reverse_policy"
  );

  return (
    <div
      className={
        "mb-2 col-lg-" +
        (overrideLayout ? overrideLayout : formColumnReal)
      }
      style={{
        display:
          !isRequired &&
          restAccessCode &&
          utils.access.isFieldHidden(
            fieldAccesses,
            restAccessCode + "." + name
          )
            ? "none"
            : "unset",
      }}
    >
      <div className="form-group mb-0">
        <label
          className={
            (isRequired ? " font-weight-bold" : "") +
            (state[name + "Error"] ? " text-danger" : "")
          }
        >
          {title}
          {isRequired ? "*" : ""}{" "}
          {utils.access.isFieldReadOnly(
            fieldAccesses,
            restAccessCode + "." + name,
            fieldAccessReversePolicy,
            isSuperUser
          )
            ? "(read only)"
            : ""}
        </label>
        &nbsp;
      </div>
      <CImageUpload
        name={name}
        maxFile={maxFile}
        maxSize={maxSize}
        readOnly={
          restAccessCode &&
          utils.access.isFieldReadOnly(
            fieldAccesses,
            restAccessCode + "." + name,
            fieldAccessReversePolicy,
            isSuperUser
          )
        }
        state={state}
        setState={setState}
        isHorizontalPreview
        alertFileSize={alertFileSize}
        alertAllowedExtension={alertAllowedExtension}
        allowedImageExtension={allowedImageExtension}
      />
      {helperText ? (
        <span
          className="text-muted font-13 pt-1 mb-0"
          dangerouslySetInnerHTML={{ __html: helperText }}
        />
      ) : (
        ""
      )}
      {state[name + "Error"] ? (
        <p className="text-danger" style={{ fontSize: "12px" }}>
          {state[name + "Error"]}
        </p>
      ) : (
        <></>
      )}
    </div>
  );
}

export default withRouter(CVImageUpload);
