import configBuilder from "../../../../components/ubold/ConfigBuilder";

import pageDefinitions from "../definitions";

const definitions = { ...pageDefinitions, validators: {} };

export default configBuilder.buildAddEditPageConfig(
  definitions,
  [],
  ["occupancy_types"]
);
