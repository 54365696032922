import {useState} from "react";

import {useParams, withRouter} from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import Form from "../../../../components/ubold/organisms/Form";

import CVTextField from "../../../../components/ubold/molecules/forms/CVTextField";
import CVImageUpload from "../../../../components/ubold/molecules/forms/CVImageUpload";

import definitions from "./definitions";

function BankAccountAddEditPage() {
    let { id } = useParams();

    const initialState = {};

    const [state, setState] = useState(initialState);

    return (
        <Page title={(id === undefined ? 'Add New' : 'Edit') + ' ' +  definitions.title}
              restAccessCode={definitions.restAccessCode}>
            <Form
                id={id}
                definitions={definitions}
                state={state}
                setState={setState}>
                <CVTextField
                    title='Bank Name'
                    name='bank_name'
                    isRequired
                    maxLength={36}
                />
                <CVTextField
                    title='Bank Number'
                    name='bank_number'
                    type='number'
                    isRequired
                />
                <CVImageUpload
                    title='Bank Logo'
                    name='bank_logo'
                    maxFile={1}
                    isRequired
                />
            </Form>
        </Page>
    );
}

export default withRouter(BankAccountAddEditPage);