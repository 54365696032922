import React from "react";
import {
  ButtonPrimary,
  ButtonOutlined,
  IconContainer,
} from "./CButtonV2.styled";
import { RukiUIButtonVariant } from "@types";

interface IButtonV2Props {
  disabled?: boolean;
  title?: string;
  onClick?: any;
  variant?: RukiUIButtonVariant;
  icon?: string;
}

const CButtonV2: React.FC<IButtonV2Props> = ({
  disabled,
  title,
  onClick,
  variant = "PRIMARY",
  icon,
}) => {
  const initBasedOnVariant = (
    variant: RukiUIButtonVariant
  ): React.ReactNode => {
    switch (variant) {
      case "OUTLINED":
        return renderButton(ButtonOutlined);
      default:
        return renderButton(ButtonPrimary);
    }
  };

  const renderButton = (
    ButtonVariant: React.FC<IButtonV2Props>
  ): React.ReactNode => {
    return (
      <ButtonVariant
        disabled={disabled}
        variant={variant}
        onClick={onClick}
      >
        {icon && (
          <IconContainer disabled={disabled} variant={variant}>
            <img
              alt="Icon"
              src={"/assets/icons/trash.svg"}
              height={16}
              width={16}
            />
          </IconContainer>
        )}{" "}
        {title && <span>{title}</span>}
      </ButtonVariant>
    );
  };

  return (
    <React.Fragment>{initBasedOnVariant(variant)}</React.Fragment>
  );
};

export default CButtonV2;
