import { useState } from "react";
import { useParams, withRouter } from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";
import Form from "../../../../components/ubold/organisms/Form";
import CVTextField from "../../../../components/ubold/molecules/forms/CVTextField";
import COneOrManyToManyFieldV2 from "../../../../components/ubold/molecules/forms/COneOrManyToManyFieldV2";
import definitions from "./definitions";
import Swal from "sweetalert2";

function CaseCategoryAddEditPage() {
  let { id }: { id: string } = useParams();
  const initialState = !id
    ? {
        sub_categories: [],
        eligible_to_delete: true,
      }
    : {};

  const [state, setState] = useState(initialState);

  const handleDeleteSubCaseCategory = (index: number) => {
    if (!state?.sub_categories?.length) return;
    const clonedSubCaseCategory = [...state?.sub_categories];
    if (
      state.eligible_to_delete &&
      clonedSubCaseCategory[index].flag !== "New"
    ) {
      Swal.fire({
        title: `Are you sure to delete this sub-case ${clonedSubCaseCategory[index].name}`,
        text: "This action will also delete the sub-case that appears on Tenant app",
        icon: "warning",
        showDenyButton: true,
        confirmButtonText: "Yes",
        denyButtonText: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          clonedSubCaseCategory[index].flag = "Deleted";
          setState((prevState: any) => ({
            ...prevState,
            sub_categories: clonedSubCaseCategory,
          }));
          return;
        }
      });
    } else if (
      !state.eligible_to_delete &&
      clonedSubCaseCategory[index].flag !== "New"
    ) {
      clonedSubCaseCategory[index].flag = "Deleted";
      setState((prevState: any) => ({
        ...prevState,
        sub_categories: clonedSubCaseCategory,
      }));
    } else if (clonedSubCaseCategory[index].flag === "New") {
      clonedSubCaseCategory.splice(index, 1);
      setState((prevState: any) => ({
        ...prevState,
        sub_categories: clonedSubCaseCategory,
      }));
    }
  };

  return (
    <Page
      title={
        (id === undefined ? "Add New" : "Edit") +
        " " +
        definitions.title
      }
      restAccessCode={definitions.restAccessCode}
    >
      <Form
        id={id}
        definitions={definitions}
        state={state}
        setState={setState}
      >
        <CVTextField
          name="name"
          title="Name ID"
          isRequired
          maxLength={45}
        />
        <CVTextField
          name="name_en"
          title="Name EN"
          isRequired
          maxLength={45}
        />
        <section
          style={{
            width: "100%",
            background: "#f2f2f2",
            height: 30,
            paddingRight: 24,
            paddingLeft: 29,
            alignItems: "center",
            fontSize: 16,
            fontWeight: 500,
            display: "flex",
          }}
        >
          Sub-Case Category
        </section>
        <COneOrManyToManyFieldV2
          id={id}
          name="sub_categories"
          saveOnlyFromMainForm
          mainTab={true}
          useNewClone
          isSequenceEnabled
          sequenceTitle="Priority"
          additionalStateInAddNewRow={{
            sequence: state?.sub_categories?.length + 1 ?? 1,
          }}
          useAlertBeforeDelete
          customHandleDeleteRow={handleDeleteSubCaseCategory}
          columns={[
            {
              name: "name",
              title: "Name ID",
              type: "text",
              isRequired: true,
            },
            {
              name: "name_en",
              type: "text",
              title: "Name EN",
              isRequired: true,
            },
          ]}
        />
      </Form>
    </Page>
  );
}

export default withRouter(CaseCategoryAddEditPage);
