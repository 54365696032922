import { useState } from "react";

import { useParams, withRouter } from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import Form from "../../../../components/ubold/organisms/Form";

import CVTextField from "../../../../components/ubold/molecules/forms/CVTextField";

import definitions from "./definitions";
import CVImageView from "components/ubold/molecules/forms/CVImageView";

function BankAccountViewPage() {
  let { id }: { id: string } = useParams();

  const initialState = {};

  const [state, setState] = useState(initialState);

  return (
    <Page
      title={"Detail of " + definitions.title}
      restAccessCode={definitions.restAccessCode}
    >
      <Form
        id={id}
        definitions={definitions}
        readOnly
        state={state}
        setState={setState}
      >
        <CVTextField
          title="Bank Name"
          name="bank_name"
          isRequired
          maxLength={36}
          readOnly
        />
        <CVTextField
          title="Bank Number"
          name="bank_number"
          type="number"
          isRequired
          readOnly
        />
        <CVImageView title="Bank Logo" name="bank_logo" />
      </Form>
    </Page>
  );
}

export default withRouter(BankAccountViewPage);
