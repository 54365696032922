import styled from "styled-components";

export const Container = styled.div`
  border-radius: 4px;
  color: #000;
  display: flex;
  flex-direction: column;
  font-family: "Rubik";
  font-weight: 400;
  height: 100%;
  width: 100%;
`;

export const TableStyled = styled.div`
  background-color: #fff;
  padding: 16px;
  border-radius: 4px;
`;

export const TableInformation = styled.div`
  max-height: 14px;
  display: flex;
  flex-direction: row;
  font-size: 10px;
  line-height: 14px;
  margin-bottom: 16px;
`;

export const RefreshBtn = styled.button`
  height: 14px;
  color: #00bbb4;
  background: transparent;
  border: 0;
  cursor: pointer;
  padding: 0;
`;

export const LabelSeparator = styled.div`
  display: flex;
  padding-bottom: 4px;
  align-items: center;
  margin-right: 4px;
`;
