import { useParams, withRouter } from "react-router-dom";

import TabPaneSecondary from "../../../../../../components/ubold/organisms/TabPaneSecondary";

import COneOrManyToManyField from "../../../../../../components/ubold/molecules/forms/COneOrManyToManyField";

function BuildingPhoto(props) {
  const { definitions, tab, state, setState } = props;

  let { id } = useParams();

  return (
    <TabPaneSecondary
      definitions={definitions}
      tab={tab}
      restAccessCodeNamespace="photo"
      state={state}
      setState={setState}
    >
      <div className="m-2 d-flex flex-row">
        <a
          href={
            "https://rukita.retool.com/p/insert-asset-360-marzipano?assetId=" +
            id
          }
          target="_blank"
          rel="noreferrer"
        >
          <div className="px-2 py-1 rounded border border-primary text-primary">
            Insert 360 Marzipano
          </div>
        </a>
        <a
          href={
            "https://rukita.retool.com/p/insert-asset-hls?assetId=" +
            id
          }
          target="_blank"
          rel="noreferrer"
          className="ml-2"
        >
          <div className="px-2 py-1 rounded border border-primary text-primary">
            Insert Video (HLS)
          </div>
        </a>
      </div>
      <COneOrManyToManyField
        id={id}
        name={tab.id}
        restPath="building/building_photo/"
        relationKey="building_id"
        photoFields={["image_orignal", "thumbnail"]}
        minRow={3}
        enableMinRowValidation
        dataTypeValidation="photos"
        columns={[
          {
            name: "image_orignal",
            type: "building_photo_upload",
            isRequired: true,
            title: "Photo (Max Size 2MB)",
          },
          {
            name: "image_description",
            type: "textarea",
            width: 400,
            title: "Description",
          },
          {
            name: "order_no",
            type: "number",
            width: 30,
            title: "Nomor Urut",
          },
        ]}
      />
    </TabPaneSecondary>
  );
}

export default withRouter(BuildingPhoto);
