import configBuilder from "../../../../components/ubold/ConfigBuilder";

import pageDefinitions from "../definitions";

const definitions = {
  ...pageDefinitions,
  linkHistory: (id: string) =>
    `${definitions.frontendPath}/history/${id}`,
  tabs: [
    {
      id: "tenant",
      name: "Tenant",
    },
    {
      id: "t_ruki_koins",
      name: "RukiKoin",
    },
  ],
};

export default configBuilder.buildAddEditPageConfig(
  definitions,
  [
    "photo_original",
    "id_card_photo_original",
    "payment_receipt_original",
  ],
  [],
  "edit",
  (data: { country: number }) => {
    if (data.country) {
      data.country = 84;
    }

    return data;
  }
);
