import configBuilder from "../../../../components/ubold/ConfigBuilder";

import pageDefinitions from "../definitions";

const definitions = {
  ...pageDefinitions,
  linkHistory: (id: any) =>
    `${definitions.frontendPath}/history/${id}`,
  tabs: [
    {
      id: "t_general",
      name: "General",
    },
  ],
};

export default configBuilder.buildAddEditPageConfig(definitions);
