import { useState } from "react";
import { useParams, withRouter } from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";
import Form from "../../../../components/ubold/organisms/Form";

import CVTextField from "components/ubold/molecules/forms/CVTextField";
import CVTextArea from "components/ubold/molecules/forms/CVTextArea";
import CVImageUpload from "components/ubold/molecules/forms/CVImageUpload";
import definitions from "./definitions";

function CUSPHighlightAddEditPage() {
  let { id }: { id: string } = useParams();

  const initialState = {};

  const [state, setState] = useState(initialState);

  return (
    <Page
      title={(!id ? "Add New" : "Edit") + " " + definitions.title}
      restAccessCode={definitions.restAccessCode}
    >
      <Form
        id={id}
        definitions={definitions}
        state={state}
        setState={setState}
      >
        <CVTextField
          isRequired
          maxLength={18}
          name="name"
          title="Title (ID)"
        />
        <CVTextField
          isRequired
          maxLength={18}
          name="name_en"
          title="Title (EN)"
        />
        <CVTextArea
          isRequired
          maxLength={75}
          name="description"
          title="Description (ID)"
        />
        <CVTextArea
          isRequired
          maxLength={75}
          name="description_en"
          title="Description (EN)"
        />
        <CVTextField
          isRequired
          min={1}
          name="order"
          title="Order"
          type="number"
        />
        <CVImageUpload
          alertAllowedExtension=".png"
          allowedImageExtension={["png"]}
          helperText="input file with these extension: .png with maximum size is 2MB"
          isRequired
          name="icon_original"
          title="Icon"
        />
      </Form>
    </Page>
  );
}

export default withRouter(CUSPHighlightAddEditPage);
