import { useCallback, useEffect, useState } from "react";
import { useParams, withRouter } from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import Form from "../../../../components/ubold/organisms/Form";
import Select from "react-select";

import CVAsyncSelect from "../../../../components/ubold/molecules/forms/CVAsyncSelect";
import CVTextField from "../../../../components/ubold/molecules/forms/CVTextField";

import definitions from "./definitions";
import CVTextView from "components/ubold/molecules/forms/CVTextView";
import CVCheckBox from "components/ubold/molecules/forms/CVCheckBox";
import CVDateTimePicker from "components/ubold/molecules/forms/CVDateTimePicker";
import useGlobalState from "state";
import { apiUrl } from "configs";
import utils from "utils";

function LandlordAddEditPage() {
  let { id } = useParams();

  const initialState = {};

  const [state, setState] = useState(initialState);
  const [globalState] = useGlobalState("constant_values");

  const [initialRole, setInitialRole] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);

  useEffect(() => {
    if (id) {
      utils.httpClient.get(
        `${apiUrl}landlord/user_role?landlord_user=${id}`,
        (res) => {
          if (res.data.rows.length !== 0) {
            setInitialRole(res.data.rows[0]);
            setSelectedRole(
              globalState?.landlord_user_role.find(
                (role) => role.value === res.data.rows[0].role
              ) ?? null
            );
          }
        }
      );
    }
  }, [id, globalState]);

  const onSubmitForm = useCallback(
    async (e, flag, history) => {
      // 1. If there's no role, then we need to create using POST method
      if (selectedRole && id) {
        if (initialRole === null) {
          createRole(e, flag, history);
        }
        // 2. If there's a role, then we need to update using PATCH method
        if (initialRole && selectedRole.value !== initialRole.role) {
          updateRole(e, flag, history);
        }
      } else {
        definitions.onSubmit(
          id,
          state,
          setState,
          e,
          { id, state, setState, definitions, history },
          flag
        );
      }
    },
    [
      id,
      initialRole,
      selectedRole,
      state,
      setState,
      definitions,
      history,
    ]
  );

  const createRole = useCallback(
    async (e, flag, history) => {
      return utils.httpClient.post(
        `${apiUrl}landlord/user_role/`,
        {
          landlord_user: id,
          role: selectedRole.value,
        },
        () => {
          definitions.onSubmit(
            id,
            state,
            setState,
            e,
            { id, state, setState, definitions, history },
            flag
          );
        }
      );
    },
    [id, selectedRole, state, setState, definitions, history]
  );

  const updateRole = useCallback(
    (e, flag, history) => {
      return utils.httpClient.patch(
        `${apiUrl}landlord/user_role/${initialRole.id}/`,
        {
          landlord_user: id,
          role: selectedRole.value,
        },
        () => {
          definitions.onSubmit(
            id,
            state,
            setState,
            e,
            { id, state, setState, definitions, history },
            flag
          );
        }
      );
    },
    [
      id,
      initialRole,
      selectedRole,
      state,
      setState,
      definitions,
      history,
    ]
  );

  return (
    <Page
      title={
        (id === undefined ? "Add New" : "Edit") +
        " " +
        definitions.title
      }
      restAccessCode={definitions.restAccessCode}
    >
      <Form
        id={id}
        definitions={definitions}
        state={state}
        setState={setState}
        onSubmit={onSubmitForm}
      >
        <CVAsyncSelect
          title="Landlord"
          name="landlord"
          overrideLayout="12"
          data="building/landlord?__type__=select_entries"
          isRequired
        />

        <CVTextField
          name="username"
          title="Username"
          password={100}
          overrideLayout="12"
        />
        <CVTextField
          name="email"
          title="Email"
          isRequired
          password={254}
          overrideLayout="12"
        />
        <CVTextField
          name="password"
          title="Password"
          type="password"
          autoComplete="new-password"
          isRequired
          password={50}
          overrideLayout="12"
          helperText="Your password can't be too similar to your other personal information.
                    <br/>Your password must contain at least 8 characters.
                    <br/>Your password can't be entirely numeric."
        />
        <CVTextField
          name="password_confirm"
          title="Confirm Password"
          type="password"
          autoComplete="new-password"
          overrideLayout="12"
          isRequired
          password={50}
          helperText="Enter the same password as before, for verification."
        />
        {/* CUSTOM SELECT because we are hitting different API for this */}
        <div
          className={"col-lg-12"}
          style={{
            display: id ? "unset" : "none",
          }}
        >
          <div className="form-group mb-3">
            <label>User Role</label>
            &nbsp;
            <Select
              // name={"Role"}
              isClearable={false}
              closeMenuOnSelect
              placeholder={"Select Role"}
              hideSelectedOptions={true}
              value={selectedRole}
              isSearchable={false}
              options={globalState?.landlord_user_role ?? []}
              onChange={(data) => {
                setSelectedRole(data);
              }}
            />
          </div>
        </div>
        {/* SELECT */}
        <CVCheckBox name="is_active" title="Is Active" />
        <CVDateTimePicker
          name="deactivate_date"
          title="Deletion Date"
          formColumn={1}
          readOnly
        />
        <CVTextView
          name="deactivate_reason"
          title="Deactive Reason"
          formColumn={1}
        />
      </Form>
    </Page>
  );
}

export default withRouter(LandlordAddEditPage);
