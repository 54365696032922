import configBuilder from "components/ubold/ConfigBuilder";
import pageDefinitions from "../definitions";

import utils from "utils";

const definitions = {
  ...pageDefinitions,
  tabs: [
    {
      id: "monthly_report",
      name: "Monthly Reports",
    },
    {
      id: "t_report_file",
      name: "Report Files",
    },
  ],
};

export default configBuilder.buildAddEditPageConfig(definitions, [
  "file",
]);
