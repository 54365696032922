import { useParams, withRouter } from "react-router-dom";

import AccordionPane from "../../../../../../../components/ubold/organisms/AccordionPane";

import CHSelect from "../../../../../../../components/ubold/molecules/forms/CHSelect";
import CHAsyncSelect from "../../../../../../../components/ubold/molecules/forms/CHAsyncSelect";
import CHDateTimePicker from "../../../../../../../components/ubold/molecules/forms/CHDateTimePicker";
import CHTextArea from "../../../../../../../components/ubold/molecules/forms/CHTextArea";
import CHCheckBox from "../../../../../../../components/ubold/molecules/forms/CHCheckBox";

import CHTextView from "../../../../../../../components/ubold/molecules/forms/CHTextView";

import utils from "../../../../../../../utils";
import CHTextField from "components/ubold/molecules/forms/CHTextField";

function Attributes(props) {
  const { baseLink, accordionId, onSubmit, state, setState } = props;

  let { id } = useParams();

  const doneStatus = [4, 5, 6];

  return (
    <AccordionPane
      id="rent"
      name="Rent"
      accordionId={accordionId}
      baseLink={baseLink}
      onSubmit={onSubmit}
      shown={
        !!(
          utils.form.isFieldError(state, "status") ||
          utils.form.isFieldError(state, "date_started") ||
          utils.form.isFieldError(state, "date_ended") ||
          utils.form.isFieldError(state, "status_of_done")
        )
      }
      state={state}
      setState={setState}
    >
      <CHSelect
        name="status"
        title="Status"
        isRequired
        readOnly={
          id &&
          doneStatus.includes(state["status"]) &&
          !state["statusEdited"]
        }
        data={{
          optionField: "order_order_status",
        }}
        afterChange={() => {
          setState((prevState) => ({
            ...prevState,
            statusEdited: true,
          }));
        }}
      />
      <CHDateTimePicker
        name="date_started"
        title="Date Started"
        readOnly={id || (id && doneStatus.includes(state["status"]))}
        isRequired
        type="date"
      />
      <CHTextView
        name="date_ended"
        title="Date Ended"
        readonly
        type="date"
      />
      <CHDateTimePicker
        name="check_in_to_landlord"
        title="Checkin to Landlord"
        type="date"
      />
      <CHDateTimePicker
        name="estimated_checkout_date"
        title="Estimated Check-Out Date"
        type="date"
      />
      <CHDateTimePicker
        name="last_payment_to_landlord"
        title="Last Payment to Landlord"
        type="date"
      />
      <CHDateTimePicker
        name="date_checkout"
        title="Date Checkout"
        readOnly={id && doneStatus.includes(state["status"])}
        type="date"
        helperText="<b>Note</b>: Date checkout should be greater than Date Ended."
      />
      <CHSelect
        name="status_of_done"
        title="Status of Done"
        readOnly={id && doneStatus.includes(state["status"])}
        isRequired
        data={{
          optionField: "order_order_status_of_done",
        }}
      />
      <CHTextArea
        name="reason_of_check_out"
        title="Reason of Checkout"
        readOnly={id && doneStatus.includes(state["status"])}
      />
      <CHAsyncSelect
        name="checkout_category"
        title="Checkout Category"
        readOnly={id && doneStatus.includes(state["status"])}
        data="order/checkout_category/?__type__=select_entries&limit=10"
      />
      <CHAsyncSelect
        name="checkout_reason"
        title="Checkout Reason"
        readOnly={id && doneStatus.includes(state["status"])}
        dependsOn="checkout_category"
        data="order/checkout_reason/?__type__=select_entries&limit=10"
      />
      <CHCheckBox
        name="on_leave_paying"
        title="On Leave Paying?"
        disabled={id && doneStatus.includes(state["status"])}
      />
      <CHDateTimePicker
        name="check_in_time"
        title="Check-In Time"
        type="time"
      />
      <CHDateTimePicker
        name="check_out_time"
        title="Check-Out Time"
        type="time"
      />
      <CHCheckBox
        name="confirm_check_out"
        title="Confirm Check-Out"
      />
      <CHCheckBox
        name="split_deposit_and_rent"
        title="Split Deposit and Rent"
      />
      <CHCheckBox name="no_show" title="No Show" />
      <CHTextField
        name="monthly_commitment"
        title="Monthly Commitment"
        type="number"
        min={0}
      />
      <CHTextField
        name="landlord_price"
        title="Landlord Price"
        type="number"
        min={0}
      />
    </AccordionPane>
  );
}

export default withRouter(Attributes);
