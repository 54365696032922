import config from "../configs"
import utils from "../utils"

const authService = {
    login: (payload, successCallback, errorCallback) => {
        utils.httpClient.post(config.apiUrl + "login/", payload, successCallback, errorCallback, true);
    },

    logout: (payload, successCallback, errorCallback) => {
        utils.httpClient.get(config.apiUrl + "logout", payload, successCallback, errorCallback, true);
    },

    init: (payload, successCallback, errorCallback) => {
        utils.httpClient.get(config.apiUrl + "init", payload, successCallback, errorCallback);
    },
}

export default authService;
