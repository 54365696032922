import { useParams, withRouter } from "react-router";

import TabPaneSecondary from "../../../../../../components/ubold/organisms/TabPaneSecondary";
import COneOrManyToManyField from "../../../../../../components/ubold/molecules/forms/COneOrManyToManyField";

function CategoryProducts(props) {
  const { definitions, tab, state, setState } = props;

  let { id } = useParams();
  return (
    <TabPaneSecondary
      definitions={definitions}
      restAccessCodeNamespace="category_product"
      tab={tab}
      state={state}
      setState={setState}
    >
      <COneOrManyToManyField
        id={id}
        name={tab.id}
        restPath="one_time_purchase/category_product/"
        relationKey="product_id"
        columns={[
          {
            name: "category",
            type: "async_select",
            title: "Category Products",
            isRequired: true,
            isUniqueOption: true,
            data: "one_time_purchase/category?__type__=select_entries",
          },
        ]}
      />
    </TabPaneSecondary>
  );
}

export default withRouter(CategoryProducts);
