import { useState } from "react";

import { useParams, withRouter } from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import Form from "../../../../components/ubold/organisms/Form";

import CVSwitch from "../../../../components/ubold/molecules/forms/CVSwitch";
import CVTextField from "../../../../components/ubold/molecules/forms/CVTextField";
import CVSimpleManyToMany from "../../../../components/ubold/molecules/forms/CVSimpleManyToMany";

import definitions from "./definitions";

function CAddEditPage() {
  let { id } = useParams<{ id: string }>();

  const initialState = {};

  const [state, setState] = useState(initialState);

  return (
    <Page
      title={
        (id === undefined ? "Add New" : "Edit") +
        " " +
        definitions.title
      }
      restAccessCode={definitions.restAccessCode}
    >
      <Form
        id={id}
        definitions={definitions}
        state={state}
        setState={setState}
        readOnly
      >
        <CVTextField
          name="name"
          title="Name"
          maxLength={255}
          isRequired
          readOnly
        />
        <CVSwitch name="published" title="Published" readOnly />
        <CVSimpleManyToMany
          name="collection"
          title="Collection"
          data="building/collection?__type__=select_entries"
          readOnly
        />
      </Form>
    </Page>
  );
}

export default withRouter(CAddEditPage);
