import { useParams, withRouter } from "react-router";
import { useEffect, useState } from "react";
import TabPaneSecondary from "components/ubold/organisms/TabPaneSecondary";

import COneOrManyToManyField from "components/ubold/molecules/forms/COneOrManyToManyField";
import moment from "moment";

function PriceUpdate(props) {
  const { definitions, tab, state, setState } = props;
  const [dataList, setDataList] = useState([]);

  let { id } = useParams();
  const doneStatus = [5, 6, 10];

  const getMinDate = () => {
    const date = new Date();
    const dateRule = new Date(
      `${date.getFullYear()}-${date.getMonth() + 1}-25`
    );
    let minDateRule = "";

    if (date < dateRule) {
      minDateRule = new Date(
        `${date.getFullYear()}-${date.getMonth() + 2}-01`
      );
    } else {
      minDateRule = new Date(
        `${date.getFullYear()}-${date.getMonth() + 3}-01`
      );
    }
    return minDateRule;
  };

  useEffect(() => {
    if (!state[tab.id]) return;
    setDataList(state[tab.id]);
  }, [state?.[tab.id]]);

  useEffect(() => {
    if (id) return;
    if (state?.subscription_type !== 1) {
      state[tab.id + "infoMessage"] =
        "Subscription Type Open is required before using this tab!";
      return;
    } else {
      delete state?.[tab.id + "infoMessage"];
      return;
    }
  }, [id, state?.subscription_type]);

  useEffect(() => {
    if (state[tab.id]?.length > 0) {
      if (state[tab.id].some((rowData) => !rowData.date_created)) {
        setState((prev) => {
          const todayDate = prev[tab.id].map((rowData) => {
            if (rowData.date_created) {
              return rowData;
            } else {
              return {
                ...rowData,
                date_created: moment().format("YYYY-MM-DD"),
              };
            }
          });
          return {
            ...prev,
            [tab.id]: todayDate,
          };
        });
      }
    }
  }, [state?.[tab.id]]);

  return (
    <TabPaneSecondary
      definitions={definitions}
      tab={tab}
      restAccessCodeNamespace="price_update"
      state={state}
      setState={setState}
    >
      <COneOrManyToManyField
        id={id}
        name={tab.id}
        readOnly={
          state?.subscription_type !== 1 ||
          (id && doneStatus.includes(state?.original___order_status))
        }
        restPath="order_new/price_update/"
        relationKey="order_id"
        saveOnlyFromMainForm={true}
        dependsTabName={tab.id}
        useNewClone={true}
        index={4}
        columns={[
          {
            name: "price",
            type: "number",
            title: "Price",
            dynamicReadOnly: true,
            dataList: dataList,
            isRequired: true,
          },
          {
            name: "period",
            type: "monthpicker",
            title: "Period",
            isRequired: true,
            dynamicReadOnly: true,
            dataList: dataList,
            minDate: getMinDate(),
          },
          {
            name: "date_created",
            type: "date",
            title: "Created Date",
            isReadOnly: true,
          },
          {
            name: "status",
            type: "select",
            title: "Status",
            readOnly: true,
            default: 1,
            isReadOnly: true,
            data: [
              {
                label: "Waiting",
                value: 1,
              },
              {
                label: "Applied",
                value: 2,
              },
              {
                label: "Cancel",
                value: 3,
              },
            ],
          },
          {
            name: "is_cancelled",
            type: "checkbox",
            dynamicReadOnly: true,
            title: "Cancel",
            default: false,
            dataList: dataList,
          },
        ]}
      />
    </TabPaneSecondary>
  );
}

export default withRouter(PriceUpdate);
