import configBuilder from "../../../components/ubold/ConfigBuilder";

import utils from "../../../utils";

let definitions = {
  title: "Events",
  frontendPath: "/community_event/event",
  baseLink: "/event/event",
  restAccessCode: "event.event",
  __table__: {
    filterColumn: 4,
    hideEditButton: true,
    removeIdentifier: "event_name",
    filters: [
      {
        id: "event_name",
        name: "Event Name",
        type: "text",
      },
      {
        id: "event_type",
        name: "Event Type",
        type: "select",
        data: {
          optionField: "event_event_mode",
        },
      },
      {
        id: "link_external",
        name: "Link External",
        type: "text",
      },
      {
        id: "date_started",
        name: "Date Started",
        type: "date",
      },
      {
        id: "date_created",
        name: "Date Created",
        type: "date",
      },
    ],
    columns: [
      {
        id: "event_name",
        name: "Event Name",
        enableHyperlink: true,
      },
      {
        id: "event_type",
        name: "Event Type",
        center: true,
        referStateFieldName: "event_event_mode",
        renderer: (value, _, constants) => {
          let label = "-";
          (constants ?? []).forEach((obj) => {
            if (obj.value === parseInt(value)) {
              label = obj.label;
            }
          });
          return label;
        },
      },
      {
        id: "link_external",
        name: "Link External",
        isSafeContent: true,
        center: true,
        renderer: (value) => {
          if (value && value !== "" && value !== "-") {
            return (
              "<a href='" + value + "' target='_blank'>Open link</a>"
            );
          } else {
            return value;
          }
        },
      },
      {
        id: "date_started",
        name: "Date Started",
        renderer: (value) => {
          return utils.formatter.date(value);
        },
      },
      {
        id: "date_created",
        name: "Date Created",
        renderer: (value) => {
          return utils.formatter.dateTime(value);
        },
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
