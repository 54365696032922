import date from "./Date";
import commons from "./Commons";
import crypt from "./Crypt";
import store from "./Store";
import httpClient from "./HttpClient";
import form from "./Form";
import renderer from "./Renderer";
import formatter from "./Formatter";
import validator from "./Validator";
import access from "./Access";
import removeHTMLTags from "./RemoveHTMLTags";

const utils = {};

utils.date = date;
utils.commons = commons;
utils.crypt = crypt;
utils.store = store;
utils.httpClient = httpClient;
utils.form = form;
utils.renderer = renderer;
utils.formatter = formatter;
utils.validator = validator;
utils.access = access;
utils.removeHtmlTags = removeHTMLTags;

export default utils;
