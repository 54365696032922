import { useParams, withRouter } from "react-router-dom";

import TabPaneSecondary from "../../../../../../components/ubold/organisms/TabPaneSecondary";

import COneOrManyToManyField from "../../../../../../components/ubold/molecules/forms/COneOrManyToManyField";

function BuildingServices(props) {
  const { definitions, tab, state, setState } = props;

  let { id } = useParams();

  return (
    <TabPaneSecondary
      definitions={definitions}
      tab={tab}
      restAccessCodeNamespace="service"
      state={state}
      setState={setState}
    >
      <COneOrManyToManyField
        id={id}
        name={tab.id}
        restPath="building/building__service/"
        relationKey="building_id"
        readOnly
        columns={[
          {
            name: "request_type",
            type: "select",
            title: "Request Type",
            disabledAtEdit: true,
            isUniqueOption: true,
            isRequired: true,
            data: [
              {
                value: "1",
                label: "Service Request",
              },
              {
                value: "2",
                label: "Help Request",
              },
            ],
            readOnly: true,
          },
          {
            name: "service",
            type: "async_select",
            title: "Help & Service",
            disabledAtEdit: true,
            isUniqueOption: true,
            isRequired: true,
            data: "building/service?__type__=select_entries",
            dependsOn: "request_type",
            dependsOnPrefix: "",
            requireField: "Request Type",
            readOnly: true,
          },
          {
            name: "active",
            type: "checkbox",
            center: true,
            default: true,
            width: 50,
            title: "Is Active",
            readOnly: true,
          },
        ]}
      />
    </TabPaneSecondary>
  );
}

export default withRouter(BuildingServices);
