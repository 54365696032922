const validator = {
  validateRequired: (value) => {
    if (
      value &&
      !value.toString().startsWith("http") &&
      value.toString().indexOf("<") !== -1
    ) {
      value = $(value).text();
    }

    return value !== null && value !== undefined && value !== ""
      ? true
      : "This field is required";
  },
  validateNopol: (value) => {
    const regexPattern =
      /^([A-Z]{1,2})(\s|-)*([0-9]{1,5})(\s|-)*([A-Za-z]{0,3})$/g;
    return regexPattern.test(value);
  },
  validateFilterRequired: (value) => {
    if (!value) {
      return "This field is required";
    } else {
      return true;
    }
  },
  validateRequired2: (value) => {
    return value !== null && value !== undefined && value !== ""
      ? true
      : "This field is required";
  },
};

export default validator;
