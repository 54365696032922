import configBuilder from "components/ubold/ConfigBuilder";
import utils from "utils";

let definitions = {
  title: "Profile Setting",
  frontendPath: "/profile_setting",
  restAccessCode: "administrator.user",
  sections: [
    {
      id: "edit_profile",
      icon: {
        active: "/assets/icons/person-active.svg",
        main: "/assets/icons/person.svg",
      },
      name: "Edit Profile",
    },
    {
      id: "change_password",
      icon: {
        main: "/assets/icons/unlock.svg",
        active: "/assets/icons/unlock-active.svg",
      },
      name: "Change Password",
    },
  ],
  validators: {
    first_name: (value: string) => {
      if (value && !/^[a-zA-Z'\-]+$/.test(value)) {
        return "Max. 150 characters. Letters, digits and @/./+/-/_ only";
      } else {
        return utils.validator.validateRequired(value);
      }
    },
    last_name: (value: string) => {
      if (value && !/^[a-zA-Z'\-]+$/.test(value)) {
        return "Max. 30 characters. Letters and -/' only";
      } else {
        return utils.validator.validateRequired(value);
      }
    },
    username: (value: string) => {
      if (value && !/^[a-zA-Z0-9.@+_-]+$/.test(value)) {
        return "Max. 30 characters. Letters and -/' only";
      } else {
        return utils.validator.validateRequired(value);
      }
    },
    email: (value: string) => {
      const validRukitaEmail = /^[A-Za-z0-9._%+-]+@rukita\.co$/.test(
        value
      );
      const validGmailEmail = /^[A-Za-z0-9._%+-]+@gmail\.com$/.test(
        value
      );
      const validYahooEmail =
        /^[A-Za-z0-9._%+-]+@yahoo\.co\.id$/.test(value);
      const validIcloudEmail = /^[A-Za-z0-9._%+-]+@icloud\.com$/.test(
        value
      );

      if (
        !validRukitaEmail &&
        !validGmailEmail &&
        !validYahooEmail &&
        !validIcloudEmail
      ) {
        return "Insert a valid allowed email. (@rukita.co, @gmail.com, @yahoo.co.id, @icloud.com)";
      } else {
        return utils.validator.validateRequired(value);
      }
    },
  },
};

export default configBuilder.buildAddEditPageConfig(definitions);
