import secondaryDataHandler from "./SecondaryDataHandler";

import utils from "../../../utils";

import configBuilder from "../../../components/ubold/ConfigBuilder";

import configs from "../../../configs";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

let definitions = {
  title: "Invoices",
  frontendPath: "/finance/tenant_invoice",
  baseLink: "/finance/invoice",
  restAccessCode: "finance.invoice",
  __table__: {
    addButton: false,
    filterColumn: 4,
    removeIdentifier: "number",
    hasSecondaryData: true,
    secondaryDataRow: 3,
    secondaryDataItem: 13,
    secondaryDataHandler: secondaryDataHandler,
    hideEditButton: true,
    filterButtonsRight: [
      {
        name: "Export",
        onClick: (e, state) => {
          const fieldMaps = {
            date: "date",
            invoice: "number",
            order: "order",
            tenant: "tenant",
            building: "building",
            payment_method: "selected_payment_method",
            building_type: "building_type",
            midtrans_number: "midtrans",
            invoice_status: "invoice_status",
            release_status: "release_status",
          };

          const params = [];

          for (let key of Object.keys(state)) {
            if (
              state[key] !== null &&
              state[key] !== undefined &&
              fieldMaps[key]
            ) {
              if (typeof state[key] === "string") {
                if (state[key].trim() !== "") {
                  params.push(fieldMaps[key] + "=" + state[key]);
                }
              } else if (typeof state[key] === "object") {
                params.push(fieldMaps[key] + "=" + state[key].value);
              } else {
                params.push(fieldMaps[key] + "=" + state[key]);
              }
            }
          }

          const sweetAlert = withReactContent(Swal);

          utils.httpClient.get(
            configs.apiUrl +
              "finance/invoice/export/?" +
              params.join("&"),
            (response) => {
              sweetAlert.fire("", response.message, "info");
            },
            (error, message) => {
              sweetAlert.fire("", message, "error");
            }
          );
        },
      },
    ],
    filters: [
      {
        id: "invoice",
        name: "Invoice Number",
        type: "async_select",
        data: "finance/invoice?__type__=select_entries",
        itemsExtractor: (item) => {
          return {
            label: item.number,
            value: item.id,
          };
        },
      },
      {
        id: "order",
        name: "Order ID",
        type: "async_select",
        data: "order/order?__type__=select_entries",
        itemsExtractor: (item) => {
          return {
            label: item.number,
            value: item.id,
          };
        },
      },
      {
        id: "tenant",
        name: "Tenant Name",
        type: "async_select",
        data: "user/tenant?__type__=select_entries",
        itemsExtractor: (item) => {
          return {
            label: item.name,
            value: item.id,
          };
        },
      },
      {
        id: "building",
        name: "Building Name",
        type: "async_select",
        data: "building/building?__type__=select_entries",
        itemsExtractor: (item) => {
          return {
            label: item.building_name,
            value: item.id,
          };
        },
      },
      {
        id: "payment_method",
        name: "Payment Method",
        type: "select",
        data: {
          optionField: "finance_invoice_payment_method",
        },
      },
      {
        id: "building_type",
        name: "Building Type",
        type: "select",
        data: {
          optionField: "buildings_building_type",
        },
      },
      {
        id: "midtrans_number",
        name: "Midtrans Number",
        type: "async_select",
        data: "payment_gateway/midtrans_transaction?__type__=select_entries",
        itemsExtractor: (item) => {
          return {
            label: item.midtrans_number,
            value: item.id,
          };
        },
      },
      {
        id: "date",
        name: "Created Date (Export Only)",
        type: "date",
        modeRange: true,
      },
      {
        id: "invoice_status",
        name: "Invoice Status",
        type: "select",
        data: {
          optionField: "finance_invoice_status",
        },
      },
      {
        id: "release_status",
        name: "Release Status",
        type: "select",
        data: {
          optionField: "finance_invoice_release_status",
        },
      },
    ],
    columns: [
      {
        id: "number",
        name: "Invoice Number",
        enableHyperlink: true,
      },
      {
        id: "order_number",
        name: "Order ID",
      },
      {
        id: "tenant_name",
        name: "Tenant Name",
      },
      {
        id: "building_name",
        name: "Building Name",
      },
      {
        id: "building_type",
        name: "Building Type",
        referStateFieldName: "buildings_building_type",
        renderer: (value, _, constants) => {
          let label = "Unknown";
          (constants ?? []).forEach((obj) => {
            if (obj.value === parseInt(value)) {
              label = obj.label;
            }
          });
          return label;
        },
      },
      {
        id: "room_name",
        name: "Room Name",
      },
      {
        id: "total_amount",
        name: "Invoice Amount",
        renderer: (value) => {
          return utils.formatter.currency(value);
        },
      },
      {
        id: "paid",
        name: "Is Paid?",
        isSafeContent: true,
        center: true,
        renderer: (value) => {
          if (value === "true") {
            return '<span style="color: rgb(26, 188, 156);"> ✔ </span>';
          } else {
            return '<span style="color: red;">✘</span>';
          }
        },
      },
      {
        id: "join_invoice",
        name: "Is Join Invoice?",
        isSafeContent: true,
        center: true,
        renderer: (value) => {
          if (value === "true") {
            return '<span style="color: rgb(26, 188, 156);"> ✔ </span>';
          } else {
            return '<span style="color: red;">✘</span>';
          }
        },
      },
      {
        id: "selected_payment_method",
        name: "Selected Payment Method",
        canDoOrdering: false,
        referStateFieldName: "finance_invoice_payment_method",
        renderer: (value, _, constants) => {
          let label = "-";
          (constants ?? []).forEach((obj) => {
            if (obj.value === parseInt(value)) {
              label = obj.label;
            }
          });
          return label;
        },
      },
      {
        id: "invoice_pdf_url",
        name: "PDF",
        isSafeContent: true,
        renderer: (value) => {
          if (value !== "-") {
            return (
              "<a href='" +
              value +
              "' target='_blank' rel='noreferrer'>Download</a>"
            );
          }

          return value;
        },
      },
      {
        id: "release_status",
        name: "Release Status",
        canDoOrdering: false,
        referStateFieldName: "finance_invoice_release_status",
        renderer: (value, _, constants) => {
          let label = "-";
          (constants ?? []).forEach((obj) => {
            if (obj.value === parseInt(value)) {
              label = obj.label;
            }
          });
          return label;
        },
      },
      {
        id: "status",
        name: "Invoice Status",
        canDoOrdering: false,
        referStateFieldName: "finance_invoice_status",
        renderer: (value, _, constants) => {
          let label = "-";
          (constants ?? []).forEach((obj) => {
            if (obj.value === parseInt(value)) {
              label = obj.label;
            }
          });
          return label;
        },
      },
      {
        id: "remarks",
        name: "Remark",
      },
      {
        id: "due_date",
        name: "Due Date",
        renderer: (value) => {
          return utils.formatter.date(value);
        },
      },
      {
        id: "date_created",
        name: "Created Date",
        renderer: (value) => {
          return utils.formatter.dateTime(value);
        },
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
