import configBuilder from "../../../components/ubold/ConfigBuilder";

import utils from "../../../utils";

let definitions = {
  title: "Corporate Leads",
  frontendPath: "/lead/corporate_lead",
  baseLink: "/lead/corporate_lead",
  restAccessCode: "lead.corporate_lead",
  __table__: {
    filterColumn: 4,
    addButton: false,
    hideEditButton: true,
    filters: [
      {
        id: "name",
        name: "Name",
        type: "text",
      },
      {
        id: "email",
        name: "Email",
        type: "text",
      },
      {
        id: "role",
        name: "Role",
        type: "text",
      },
      {
        id: "company_name",
        name: "Company Name",
        type: "text",
      },
      {
        id: "comment",
        name: "Comment",
        type: "text",
      },
      {
        id: "phone",
        name: "Phone",
        type: "text",
      },
      {
        id: "remarks",
        name: "Remarks",
        type: "select",
        data: {
          optionField: "lead_corporate_lead_remark_choice",
        },
      },
      {
        id: "date_created",
        name: "Date Created",
        type: "date",
      },
      {
        id: "date_updated",
        name: "Date Updated",
        type: "date",
      },
    ],
    columns: [
      {
        id: "name",
        name: "Name",
        center: true,
        enableHyperlink: true,
      },
      {
        id: "email",
        name: "Email",
        center: true,
      },
      {
        id: "role",
        name: "Role",
        center: true,
      },
      {
        id: "company_name",
        name: "Company Name",
        center: true,
      },
      {
        id: "comment",
        name: "Comment",
        center: true,
      },
      {
        id: "phone",
        name: "Phone",
        center: true,
      },
      {
        id: "remarks",
        name: "Remarks",
        center: true,
        renderer: (value) => {
          if (parseInt(value) === 1) {
            return "Contacted";
          } else {
            return "-";
          }
        },
      },
      {
        id: "date_created",
        name: "Date Created",
        center: true,
        renderer: (value) => {
          return utils.formatter.dateTime(value);
        },
      },
      {
        id: "date_updated",
        name: "Date Updated",
        center: true,
        renderer: (value) => {
          return utils.formatter.dateTime(value);
        },
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
