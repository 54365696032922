import {useParams, withRouter} from "react-router-dom";

import TabPaneSecondary from "../../../../../../components/ubold/organisms/TabPaneSecondary";

import COneOrManyToManyField from "../../../../../../components/ubold/molecules/forms/COneOrManyToManyField";

function SecondTab(props) {
    const {definitions, tab, state, setState} = props;

    let {id} = useParams();

    return (
        <TabPaneSecondary
            definitions={definitions}
            restAccessCodeNamespace='target'
            tab={tab}
            state={state}
            setState={setState}>
            <COneOrManyToManyField
                id={id}
                name={tab.id}
                restPath='promotion/benefit_target/'
                relationKey='benefit_id'
                columns={[
                    {
                        name: 'eligible_to_all_building',
                        title: 'Eligible to All Building',
                        type: 'checkbox'
                    },
                    {
                        name: 'building',
                        title: 'Building',
                        type: 'async_select',
                        data: 'building/building?__type__=select_entries',
                        itemsExtractor: value => {
                            return {
                                label: value.building_name,
                                value: value.id
                            }
                        }
                    },
                    {
                        name: 'eligible_to_all_room_type',
                        title: 'Eligible to All Room Type',
                        type: 'checkbox'
                    },
                    {
                        name: 'room_type',
                        title: 'Room Type',
                        type: 'dual_list',
                        width: 400,
                        data: 'building/room_type?__type__=select_entries'
                    },
                ]}
            />
        </TabPaneSecondary>
    );
}

export default withRouter(SecondTab);