import { useState } from "react";

import { useParams, withRouter } from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import Form from "../../../../components/ubold/organisms/Form";

import CVTextField from "../../../../components/ubold/molecules/forms/CVTextField";

import definitions from "./definitions";
import CVTextView from "../../../../components/ubold/molecules/forms/CVTextView";
import utils from "../../../../utils";

function CAddEditPage() {
  let { id } = useParams<{ id: string }>();

  const initialState = {};

  const [state, setState] = useState(initialState);

  return (
    <Page
      title={
        (id === undefined ? "Add New" : "Edit") +
        " " +
        definitions.title
      }
      restAccessCode={definitions.restAccessCode}
    >
      <Form
        id={id}
        definitions={definitions}
        state={state}
        setState={setState}
        readOnly
      >
        <CVTextField
          name="name"
          title="Name"
          maxLength={64}
          isRequired
          readOnly
        />
        <CVTextField
          name="name_en"
          title="Name EN"
          maxLength={64}
          isRequired
          readOnly
        />
        <CVTextField
          name="building_priority"
          title="Building Priority"
          maxLength={3}
          type="number"
          readOnly
          isRequired
        />
        <CVTextField
          name="room_priority"
          title="Room Priority"
          maxLength={3}
          type="number"
          isRequired
          readOnly
        />
        <CVTextView
          name="date_created"
          title="Date Created"
          renderer={(value: string) => {
            return utils.formatter.dateTime(value);
          }}
        />
        <CVTextView
          name="date_updated"
          title="Date Updated"
          renderer={(value: string) => {
            return utils.formatter.dateTime(value);
          }}
        />
      </Form>
    </Page>
  );
}

export default withRouter(CAddEditPage);
