import secondaryDataHandler from "./SecondaryDataHandler";

import configBuilder from "../../../components/ubold/ConfigBuilder";
import configs from "configs";
import utils from "utils";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

let definitions = {
  title: "Buildings",
  frontendPath: "/building/property_list",
  baseLink: "/building/building",
  restAccessCode: "building.building",
  __table__: {
    removeIdentifier: "building_name",
    filterColumn: 3,
    hasSecondaryData: true,
    secondaryDataItem: 2,
    hideEditButton: true,
    secondaryDataHandler: secondaryDataHandler,
    filterButtonsRight: [
      {
        name: "Export",
        useAccessList: true,
        onClick: (e, state) => {
          const fieldMaps = {
            building: "building",
            code: "code",
            building_type: "building_type",
            building_floors: "building_floors",
            place_group: "place_group",
            building_status: "building_status",
            property_type: "property_type",
            status_of_internet: "status_of_internet",
            date_created: "date_created",
            date_updated: "date_updated",
            publish_status: "publish_status",
          };

          const params = [];

          for (let key of Object.keys(state)) {
            if (
              state[key] !== null &&
              state[key] !== undefined &&
              fieldMaps[key]
            ) {
              if (typeof state[key] === "string") {
                if (state[key].trim() !== "") {
                  params.push(fieldMaps[key] + "=" + state[key]);
                }
              } else if (typeof state[key] === "object") {
                params.push(fieldMaps[key] + "=" + state[key].value);
              } else {
                params.push(fieldMaps[key] + "=" + state[key]);
              }
            }
          }

          const sweetAlert = withReactContent(Swal);

          utils.httpClient.get(
            configs.apiUrl +
              "building/building/export/?" +
              params.join("&") +
              "&ordering=-id",
            (response) => {
              sweetAlert.fire("", response.message, "info");
            },
            (error, message) => {
              sweetAlert.fire("", message, "error");
            }
          );
        },
      },
    ],
    filters: [
      {
        id: "building",
        name: "Building Name",
        type: "async_select",
        data: "building/building?__type__=select_entries",
        itemsExtractor: (item) => {
          return {
            label: item.building_name,
            value: item.id,
          };
        },
      },
      {
        id: "type",
        name: "Type",
        type: "select",
        data: {
          optionField: "buildings_building_type",
        },
      },
      {
        id: "publish_status",
        name: "Publish Status",
        type: "select",
        data: {
          optionField: "buildings_building_published_status",
        },
      },
      {
        id: "status_of_internet",
        name: "Status of Internet",
        type: "select",
        data: {
          optionField: "buildings_building_internet_status",
        },
      },
      {
        id: "landlord",
        name: "Landlord",
        type: "async_select",
        data: "building/landlord/?__type__=select_entries",
        itemsExtractor: (item) => {
          return {
            label: item.name,
            value: item.id,
          };
        },
      },
      {
        id: "place_group",
        name: "Place Groups",
        type: "async_select",
        data: "building/place_group/?__type__=select_entries",
        itemsExtractor: (item) => {
          return {
            label: item.name,
            value: item.id,
          };
        },
      },
    ],
    columns: [
      {
        id: "building_name",
        name: "Name",
        enableHyperlink: true,
      },
      {
        id: "code",
        name: "Code",
      },
      {
        id: "building_type",
        name: "Type",
        referStateFieldName: "buildings_building_type",
        renderer: (value, _, constants) => {
          let label = "-";
          (constants ?? []).forEach((obj) => {
            if (obj.value === parseInt(value)) {
              label = obj.label;
            }
          });
          return label;
        },
      },
      {
        id: "publish_status",
        name: "Publish Status",
        referStateFieldName: "buildings_building_published_status",
        renderer: (value, _, constants) => {
          let label = "-";
          (constants ?? []).forEach((obj) => {
            if (obj.value === parseInt(value)) {
              label = obj.label;
            }
          });
          return label;
        },
        canDoOrdering: false,
      },
      {
        id: "building_status",
        name: "Building Status",
        referStateFieldName: "buildings_building_status",
        renderer: (value, _, constants) => {
          let label = "-";
          (constants ?? []).forEach((obj) => {
            if (obj.value === parseInt(value)) {
              label = obj.label;
            }
          });
          return label;
        },
        canDoOrdering: false,
      },
      {
        id: "property_type",
        name: "Type of Property",
        referStateFieldName: "buildings_building_property_type",
        renderer: (value, _, constants) => {
          let label = "-";
          (constants ?? []).forEach((obj) => {
            if (obj.value === parseInt(value)) {
              label = obj.label;
            }
          });
          return label;
        },
        canDoOrdering: false,
      },
      {
        id: "building_floors",
        name: "Total Floor",
        center: true,
        renderer: (value) => {
          if (value !== "-") {
            return (
              value + " floor" + (parseInt(value) > 1 ? "s" : "")
            );
          } else {
            return (
              "0 floor" +
              (parseInt(value) > 1 ? "s" : "") +
              " (unset)"
            );
          }
        },
      },
      {
        id: "internet_status",
        name: "Status of Internet",
        referStateFieldName: "buildings_building_internet_status",
        renderer: (value, _, constants) => {
          let label = "-";
          (constants ?? []).forEach((obj) => {
            if (obj.value === parseInt(value)) {
              label = obj.label;
            }
          });
          return label;
        },
        canDoOrdering: false,
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
