import { useParams, withRouter } from "react-router-dom";
import { useState, useEffect } from "react";

import TabPaneSecondary from "../../../../../../components/ubold/organisms/TabPaneSecondary";

import COneOrManyToManyField from "../../../../../../components/ubold/molecules/forms/COneOrManyToManyField";
import useGlobalState from "state";
import utils from "utils";
import config from "configs";

function RoomVariantRoom(props) {
  const { definitions, tab, state, setState } = props;

  let { id } = useParams();

  const [constant] = useGlobalState("constant_values");
  const [dataList, setDataList] = useState([]);

  const [isRuOpt, setIsRuOpt] = useState(false);

  useEffect(() => {
    if (!state?.["building"]) return;
    utils.httpClient.get(
      `${config.apiUrl}building/building/?__type__=select_entries&building=${state["building"]}`,
      (data) => {
        setIsRuOpt(data?.data?.rows[0]?.rukita_option);
      }
    );
  }, [state?.["building"]]);

  useEffect(() => {
    if (!state[tab.id]) return;
    setDataList(state[tab.id]);
  }, [state[tab.id]]);

  useEffect(() => {
    // this effect is used to fill initial value of new row :)
    if (state[tab.id]) {
      let shouldUpdate = false;
      const addInitialStateStateTabId = state[tab.id].map((item) => {
        const newItem = { ...item };
        if (
          item?.flag === "New" &&
          !("room_status" in item) &&
          !("maintenance_status" in item)
        ) {
          newItem.room_status = 6;
          newItem.maintenance_status = 0;
          shouldUpdate = true;
        }
        return newItem;
      });
      if (shouldUpdate) {
        setState((prev) => ({
          ...prev,
          [tab.id]: addInitialStateStateTabId,
        }));
      }
    }
  }, [state[tab.id]]);

  return (
    <TabPaneSecondary
      definitions={definitions}
      restAccessCodeNamespace="room"
      tab={tab}
      state={state}
      setState={setState}
    >
      <COneOrManyToManyField
        id={id}
        name={tab.id}
        restAccessCode={definitions.restAccessCode}
        restPath="building/room/"
        relationKey="room_variant_id"
        columns={[
          {
            name: "name",
            type: "text",
            title: "Name",
            maxLength: 100,
            isRequired: true,
          },
          {
            name: "room_type",
            type: "async_select",
            title: "Room Type",
            data: "building/room_type?__type__=select_entries",
          },
          {
            name: "price",
            type: "number",
            default: 0,
            title: "Price",
          },
          {
            name: "room_status",
            type: "select",
            title: "Room Status",
            isRequired: true,
            data: constant?.["buildings_room_status"],
            useStatusTransition: true,
            buildingRuOpt: isRuOpt,
            dataList: dataList,
          },
          {
            name: "maintenance_status",
            type: "select",
            title: "Maintenance Status",
            data: constant?.["buildings_room_maintenance_status"],
            dataList: dataList,
            useStatusTransition: true,
            dependsOn: "room_status",
          },
          {
            name: "room_status_notes",
            type: "textarea",
            title: "Room Status Notes",
          },
        ]}
      />
    </TabPaneSecondary>
  );
}

export default withRouter(RoomVariantRoom);
