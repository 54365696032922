import configBuilder from "../../../components/ubold/ConfigBuilder";

import utils from "../../../utils";

let definitions = {
  title: "Voucher Managements",
  frontendPath: "/marketing/promotion/voucher/voucher",
  baseLink: "/promotion/voucher_management",
  restAccessCode: "promotion.voucher_management",
  __table__: {
    hideEditButton: true,
    filterColumn: 4,
    filters: [
      {
        id: "name",
        name: "Voucher Headline",
        type: "text",
      },
      {
        id: "expiry_time",
        name: "Expiry Time (in days)",
        type: "number",
      },
      {
        id: "quota",
        name: "Quota",
        type: "number",
      },
      {
        id: "remaining_quota",
        name: "Remaining Quota",
        type: "number",
      },
      {
        id: "date_started",
        name: "Date Started",
        type: "date",
      },
      {
        id: "date_ended",
        name: "Date Ended",
        type: "date",
      },
    ],
    columns: [
      {
        id: "name",
        name: "Voucher Headline",
        enableHyperlink: true,
      },
      {
        id: "date_started",
        name: "Date Started",
        renderer: (value) => {
          return utils.formatter.dateTime(value);
        },
      },
      {
        id: "date_ended",
        name: "Date Ended",
        renderer: (value) => {
          return utils.formatter.dateTime(value);
        },
      },
      {
        id: "expiry_time",
        name: "Expiry Time Voucher Rukita and E-Wallet (In Days)",
        center: true,
      },
      {
        id: "quota",
        name: "Quota",
        center: true,
      },
      {
        id: "remaining_quota",
        name: "Remaining Quota",
        center: true,
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
