import React, { useEffect, useMemo, useState } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import RukiModal from "../../../../../../components/ubold/molecules/modal/RukiModal";
import Alert from "../../../../../../components/ubold/molecules/modal/Alert";
import ModalPreviewOrderSubmit from "../../../../../../components/ubold/molecules/modal/ModalPreviewOrderSubmit/ModalPreviewOrderSubmit";
import {
  ButtonModal,
  ModalPreviewOrderSubmitCSSDefault,
  ModalPreviewOrderSubmitCSSError,
} from "../../../../../../components/ubold/molecules/modal/ModalPreviewOrderSubmit/ModalPreviewOrderSubmit.css";

import TabPane from "../../../../../../components/ubold/organisms/TabPane";
import TabPaneSection from "../../../../../../components/ubold/organisms/TabPaneSection";

import CHTextField from "../../../../../../components/ubold/molecules/forms/CHTextField";
import CHSelect from "../../../../../../components/ubold/molecules/forms/CHSelect";
import CHAsyncSelect from "../../../../../../components/ubold/molecules/forms/CHAsyncSelect";
import CHRichTextField from "../../../../../../components/ubold/molecules/forms/CHRichTextField";
import CHCheckBox from "../../../../../../components/ubold/molecules/forms/CHCheckBox";
import CHTextView from "../../../../../../components/ubold/molecules/forms/CHTextView";

import LegacyTenantInfo from "./Sections/LegacyTenantInfo";
import Rent from "./Sections/Rent";

import utils from "../../../../../../utils";
import config from "configs";
import OtherInfo from "./Sections/OtherInfo";

import {
  GenerateBackdateInvoiceResponse,
  genericTabProps,
  IPreviewOrder_DataObject,
  IPreviewOrder_Response,
  PreviewOrderPayload,
  FieldOrderToBeRemoved,
  FieldOrderToBeModified,
} from "@types";
import CButton from "components/ubold/atoms/buttons/CButton";
import styled from "styled-components";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import useGlobalState from "state";
import ServiceEligibility from "./Sections/ServiceEligibility";
import useSearchableDropdown from "utils/hooks/useSearchableDropdown";
import axios from "axios";
import { InputActionMeta } from "react-select";
import CQRCodeAndURL from "components/ubold/atoms/buttons/CQRCodeAndURL";

const Building = (props: genericTabProps<any>) => {
  const { id, definitions, tab, state, setState } = props;
  const [modalPreviewOrderSubmit, setModalPreviewOrderSubmit] =
    useState(false);
  const [
    modalPreviewOrderSubmitData,
    setModalPreviewOrderSubmitData,
  ] = useState<IPreviewOrder_DataObject>(null);
  const [isHttpRequestError, setIsHttpRequestError] =
    useState<boolean>(false);
  const [accesses] = useGlobalState("accesses");
  const [fieldAccesses] = useGlobalState("field_accesses");
  const [fieldAccessReversePolicy] = useGlobalState(
    "field_access_reverse_policy"
  );
  const [user] = useGlobalState("user");
  const isSuperUser = user ? user.is_super_user : false;
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [disableDepositAmount /* setDisableDepositAmount */] =
    useState<boolean>(true);

  const [
    isBackdateModalGenerateInvoiceOpen,
    setIsBackdateModalGenerateInvoiceOpen,
  ] = useState<boolean>(false);

  const [modalGenerateInvoiceData, setModalGenerateInvoiceData] =
    useState<any>([]);

  const doneStatus = [5, 6, 10];
  const sweetAlert = withReactContent(Swal);
  const {
    tenantOptions,
    buildingOptions,
    roomVariantOptions,
    roomOptions,
    tenantKeyword,
    buildingKeyword,
    isTenantLoading,
    isBuildingLoading,
    isRoomVariantLoading,
    isRoomLoading,
    handleOnChange,
    handleOnInputChange,
    dropdownValues,
  } = useSearchableDropdown(state, setState);

  // this useEffect is to get initial data for room variant and room dropdown option
  useEffect(() => {
    const editOrDuplicate = id || state.isDuplicate;
    if (editOrDuplicate) {
      // setSelectedBuilding
      setState((prevState: Object) => {
        return {
          ...prevState,
          selectedBuilding: {
            value: state.building,
            label: state.building_name,
          },
          selectedRoomVariant: {
            value: state.room_variant,
            label: state.room_variant_name,
          },
          selectedRoom: {
            value: state.room,
            label: state.room_name,
          },
        };
      });
    }
  }, [id]);

  useEffect(() => {
    if (state["tenant_category"] === 2 || !id) {
      setState((prevState: Object) => {
        return { ...prevState, deposit_rules: 1 };
      });
    }
  }, [state["tenant_category"]]);

  const isBuildingReadOnly = () => {
    if (!id) {
      return (
        (state["t_discount_orders"]?.length ||
          state?.exclude_deposit ||
          state?.order_type ===
            2) /** New Condition for Order Type Extend Order */ &&
        !id
      );
    } else {
      return id || (id && doneStatus.includes(state["status"]));
    }
  };

  const getBuildingHelperText = () => {
    if (state?.exclude_deposit && !id) {
      return "Please uncheck exclude deposit first if you want to update this field";
    }

    if (
      state["t_discount_orders"]?.length ||
      state["t_addon_buildings"]?.length
    ) {
      return "Please delete all of the additional discounts and add on buildings to enable building options";
    }

    return "";
  };

  const getDepositDisableStatus = () => {
    if (state?.exclude_deposit) {
      return true;
    }
    return false;
    // if (!id && state?.order_type === 2) return true;
    // // please comment section below to enable exclude_deposit bugfix
    // if (state?.exclude_deposit) {
    //   return false;
    // }
    // // please comment section above to enable exclude_deposit bugfix

    // if (id || state?.is_migration_data) {
    //   return false;
    // }

    // return disableDepositAmount;
  };

  const postPreviewOrder = () => {
    type T_Addon_Orders_Object = {
      addon_building: number;
      end_date?: string;
      flag: string;
      start_date: string;
      status: number;
    };

    type AddonsPayloadObject = {
      addon_building: number;
      start_date: string;
      end_date?: string;
      status?: number;
    };

    // TODO: tambahin jagaan untuk mengecek semua parameter yang mandatory sebelum request post
    const previewOrderPayload: PreviewOrderPayload = {
      ...state, // in the end we spread all the state to make it same with form payload lol
      tenant: state["tenant"],
      tenant_category: state["tenant_category"],
      building: state["building"],
      room_variant: state["room_variant"],
      room: state["room"],
      deposit_rules: state["deposit_rules"],
      subscription_type: state["subscription_type"],
      checkin_date: state["checkin_date"],
      checkout_date: state["checkout_date"],
      monthly_commitment: state["monthly_commitment"],
      order_status: state["order_status"],
      order_type: state["order_type"],
      source: state["source"],
      referral_code: state["referral_code"],
      addons: state?.["t_addon_orders"]?.map(
        (addOnOrder: T_Addon_Orders_Object) => {
          let result: AddonsPayloadObject = {
            addon_building: addOnOrder?.addon_building,
            end_date: addOnOrder?.end_date
              ? addOnOrder?.end_date
              : null, // It's can't be nulled from BE
            start_date: addOnOrder?.start_date,
          };

          if (addOnOrder?.status) {
            // Because within the endpoint 'status: null;' will be passed (but if the payload doesn't contain 'status' property, it will be fail), so I decided to make validation with this way (only create property of object when the value is truthy)
            result.status = addOnOrder?.status;
          }

          return result;
        }
      ),
      discounts:
        state?.["t_discount_orders"] && // if discount order tab is undefined
        Object.values<{
          label: string;
          category: number;
          discount: number;
          start_date: string;
          end_date: string;
          minimum_monthly_commitment: number;
          one_time: boolean;
        }>(state?.["t_discount_orders"])?.map((discount) => {
          return {
            category: discount?.category,
            discount: discount?.discount,
            end_date: discount?.end_date ? discount?.end_date : null,
            one_time: discount?.one_time,
            start_date: discount?.start_date,
          };
        }),
    };

    if (state["tenant_category"] === 2) {
      // Legacy Tenant
      previewOrderPayload.checkin_to_landlord =
        state?.["checkin_to_landlord"];
      previewOrderPayload.last_payment_to_landlord =
        state?.["last_payment_to_landlord"];
      previewOrderPayload.price_from_landlord =
        state?.["price_from_landlord"];
    }

    utils.httpClient.post(
      config.apiUrl + "order_new/order/preview/",
      previewOrderPayload,
      (res: IPreviewOrder_Response) => {
        setModalPreviewOrderSubmitData(res.data);
        setIsLoading(false);
      },
      (error: any, message: string) => {
        setIsHttpRequestError(true);
        setIsLoading(false);
      }
    );
  };

  const getBuildingType = async (): Promise<number> => {
    try {
      const { data } = await axios.get(
        `${config.apiUrl}building/building/${state?.building}/?__type__=edit`,
        utils.httpClient._getConfig()
      );
      setState((prevState: any) => ({
        ...prevState,
        building_type: data.building_type,
      }));
      return data.building_type;
    } catch (error) {
      console.error(error);
    }
  };

  const getRoomVariantObject = async (): Promise<number> => {
    try {
      const { data } = await axios.get(
        `${config.apiUrl}building/room_variant/${state?.room_variant}/?__type__=edit`,
        utils.httpClient._getConfig()
      );
      setState((prevState: any) => ({
        ...prevState,
        room_variant_object: {
          label: data.name,
          price: data.price,
          value: data.id,
        },
      }));
      return data.price;
    } catch (error) {
      console.error(error);
    }
  };

  const getRoomObject = async (): Promise<number> => {
    try {
      const { data } = await axios.get(
        `${config.apiUrl}building/room/${state?.room}/?__type__=edit`,
        utils.httpClient._getConfig()
      );
      setState((prevState: any) => ({
        ...prevState,
        room_object: {
          label: data.name,
          price: data.price,
          value: data.id,
          available_on: undefined,
        },
      }));
      return data.price;
    } catch (error) {
      console.error(error);
    }
  };

  const getVariantOrRoomPrice = async (): Promise<number> => {
    const buildingType = state?.building_type
      ? state?.building_type
      : await getBuildingType();
    const roomVariantPrice = state?.room_variant_object
      ? state?.room_variant_object?.price
      : await getRoomVariantObject();
    const roomPrice = state?.room_object
      ? state?.room_object?.price
      : await getRoomObject();

    if (buildingType === 2) {
      return roomPrice;
    }
    return roomVariantPrice;
  };

  const isDepositForfeitedReadOnly = () => {
    if (!id && state?.order_type !== 2) return true;
    if (state?.original___deposit_forfeited) return true;
    if (state?.split_deposit_and_rent) {
      return state?.original___order_status !== 5;
    }
    return doneStatus.includes(state?.original___order_status);
  };

  const renderDepositAmount = (
    depositRules: number,
    price: number
  ): number => {
    if (!price) return 0;
    const depositRulesValues: any = {
      1: 0,
      2: 500000,
      3: Math.ceil((50 / 100) * price),
      4: price,
      5: 1000000,
      default: 200000,
    };
    return (
      depositRulesValues[depositRules] ??
      depositRulesValues["default"]
    );
  };

  const generateDepositValue = async () => {
    const variantOrRoomPrice = await getVariantOrRoomPrice();
    const paramDepositRules =
      state?.order_type === 2
        ? state?.deposit_rules
        : state?.deposit_rules_object?.value;
    const _deposit = renderDepositAmount(
      paramDepositRules,
      variantOrRoomPrice
    );

    setState((prevState: any) => {
      const isCreateOrderForExtendOrder =
        localStorage.getItem("extendOrder");

      const _depositRules = isCreateOrderForExtendOrder
        ? {
            deposit_rules: 1,
            deposit_rules_object: {
              label: "No Deposit",
              value: 1,
            },
            deposit_rule_name: "No Deposit",
          }
        : {};

      return {
        ...prevState,
        // if the order is extend order form, we dont want to change the form value
        deposit: isCreateOrderForExtendOrder
          ? JSON.parse(isCreateOrderForExtendOrder)?.deposit
          : _deposit,
        ..._depositRules,
      };
    });
  };

  const generateModifyValueForExtendOrder = () => {
    const { original___checkout_date, id, number } = state;

    // if tenant category is legacy tenant, we will modify the value of last_payment_to_landlord to be same with order checkout_date
    const lastPaymentToLandlordUpdate =
      state["tenant_category"] === 2 &&
      state["last_payment_to_landlord"]
        ? {
            name: FieldOrderToBeModified.lastPaymentToLandlord,
            value: moment(original___checkout_date).format(
              "YYYY-MM-DD"
            ),
          }
        : {};

    const modifiedState: Array<Object> = [
      {
        name: FieldOrderToBeModified.orderType,
        value: 2,
      },
      {
        name: FieldOrderToBeModified.orderStatus,
        value: 9,
      },
      {
        name: FieldOrderToBeModified.extendFromOrderId,
        value: id,
      },
      {
        name: FieldOrderToBeModified.extendFromOrderNumber,
        value: number,
      },
      {
        name: FieldOrderToBeModified.checkinDate,
        value: moment(original___checkout_date)
          .add(1, "day")
          .format("YYYY-MM-DD"),
      },
      {
        name: FieldOrderToBeModified.statusOfDone,
        value: 0,
      },
      {
        name: FieldOrderToBeModified.sourceOfOrder,
        value: 1,
      },
      {
        name: FieldOrderToBeModified.subscriptionType,
        value: 1,
      },
      {
        name: FieldOrderToBeModified.canExtendOrder,
        value: false,
      },
      {
        name: FieldOrderToBeModified.excludeDeposit,
        value: true,
      },
      {
        name: FieldOrderToBeModified.dateCreated,
        value: moment().format("YYYY-MM-DD HH:mm"),
      },
      {
        name: FieldOrderToBeModified.dateUpdated,
        value: moment().format("YYYY-MM-DD HH:mm"),
      },
      {
        name: FieldOrderToBeModified.createdBy,
        value: user?.username,
      },
      Object.keys(lastPaymentToLandlordUpdate).length > 0
        ? lastPaymentToLandlordUpdate
        : undefined,
    ];
    return modifiedState;
  };

  const generateExtendOrderEligibility = () => {
    /** can do extend order with these following status
     * superadmin or ordinary user with access list for extend order
     * and can extend order is true
     * must read from original state object because any changes before save
     * is not considered as valid value
     */

    const { original___can_extend_order } = state;

    let isExtendOrderEligible = false;

    if (
      ((!isSuperUser &&
        utils.access.isUserHasAcess(
          fieldAccesses,
          `${definitions.restAccessCode}.extend_order`
        )) ||
        isSuperUser) &&
      original___can_extend_order
    ) {
      isExtendOrderEligible = true;
    }

    return isExtendOrderEligible;
  };

  const handleOnClickGenerateNewInvoice = () => {
    utils.httpClient.post(
      `${config.apiUrl}order_new/order/generate_invoice_backdate/?order_id=${id}`,
      null,
      ({ data }: { data: GenerateBackdateInvoiceResponse }) => {
        let accessType = "view";
        if (
          accesses.includes("finance_new.invoice.create") ||
          accesses.includes("finance_new.invoice.update")
        ) {
          accessType = "edit";
        }
        sweetAlert.close();
        sweetAlert
          .fire({
            title: "Invoice Generated",
            html: `Invoice has been successfully generated.<br>New Invoice ID: <a href="/finance/invoice_outstanding/${accessType}/${data.invoice_id}" target="_blank">${data.invoice_number}</a>`,
            icon: "success",
          })
          .then(() => window.location.reload());
      },
      (error: any, message: any) => {
        console.error(error, message);
        sweetAlert.close();
        sweetAlert.fire(
          "Failed To Generate Invoice",
          "Generate invoice is failed. Please try again",
          "error"
        );
      }
    );
    setIsBackdateModalGenerateInvoiceOpen(false);
  };

  const isDepositHolderEditable = useMemo(() => {
    // on order creation, it is editable
    if (!id) return true;

    // when viewing existing order, only some order status that is editable
    if (id) {
      if (!state?.deposit_holder) {
        return true;
      }

      switch (state?.order_status) {
        // waiting for payment
        case 9:
          return true;
        // deposit received
        case 7:
          return true;
        // deposit and payment received
        case 8:
          return true;
        // ongoing
        case 3:
          return true;
        default:
          return false;
      }
    }
  }, [state?.order_status, id]);

  useEffect(() => {
    if (
      id ||
      !state?.building ||
      !state?.room_variant ||
      !state?.room
    )
      return;
    generateDepositValue();
  }, [state?.deposit_rules]);

  useEffect(() => {
    if (modalPreviewOrderSubmit) {
      postPreviewOrder();
    }
  }, [modalPreviewOrderSubmit]);

  useEffect(() => {
    if (id) return;
    if (
      state?.checkin_date &&
      moment(state?.checkin_date).isAfter(moment().add(1, "days"))
    ) {
      return;
    }
    setState((prevState: any) => ({
      ...prevState,
      extend_and_hold_invoice: false,
    }));
  }, [state?.checkin_date]);

  // effect for changing the field deposit ammount to 0 and deposit rules to no deposit when exclude deposit is true
  // only happened on order creation, identified when !id -> no id on the url
  useEffect(() => {
    const isCreateOrderForExtendOrder =
      localStorage.getItem("extendOrder");
    if (state?.exclude_deposit && !id) {
      setState((prevState: any) => {
        // when it is extend order, we dont want to change the deposit amount, but keep the deposit rules to no deposit
        if (isCreateOrderForExtendOrder) {
          return {
            ...prevState,
            deposit_rules: 1,
            deposit_rules_object: {
              label: "No Deposit",
              value: 1,
            },
            deposit_rule_name: "No Deposit",
          };
        } else {
          return {
            ...prevState,
            deposit_rules: 1,
            deposit_rules_object: {
              label: "No Deposit",
              value: 1,
            },
            deposit_rule_name: "No Deposit",
            deposit: 0,
          };
        }
      });
    }
  }, [state?.exclude_deposit]);
  // please uncomment section above to enable exclude_deposit bugfix

  return (
    <TabPane
      id={id}
      additionalButtonsBeforeMainButtons={[
        id &&
        (state?.original___subscription_type === 1 ||
          (state?.original___subscription_type === 2 &&
            state?.original___monthly_commitment > 1)) &&
        state?.original___order_status === 6 &&
        state?.original___backdate_checkout &&
        state?.original___show_generate_backdate_invoice_button &&
        !state?.original___has_generated_backdate_invoice ? (
          /**
           * Generate Invoice Preview for Backdate will be rendered if:
           * - in edit order
           * - is not an upfront order ((closed subscription || subs type === 2) && monthly commitment 0)
           * - order status is done
           * - previous order save already use backdate checkout feature
           * - field show_generate_backdate_invoice_button is true. this field is given by BE since RF cant decide whether an order have a first invoice without recurring invoice or not
           * - the generate invoice for backdate checkout never been used before
           */
          <ButtonModal
            className="btn btn-outline-success mr-1"
            key="generate-invoice-backdate"
            onClick={() => {
              utils.httpClient.post(
                `${config.apiUrl}order_new/order/preview_backdate/?order_id=${id}`,
                null,
                (res: any) => {
                  setModalGenerateInvoiceData(res.data);
                },
                (error: any, message: any) => {
                  console.error(error, message);
                }
              );
              setIsBackdateModalGenerateInvoiceOpen(true);
            }}
            disabled={
              definitions?.restAccessCode &&
              utils.access.isFieldReadOnly(
                fieldAccesses,
                `${definitions?.restAccessCode}.generate_invoice_backdate_checkout`,
                fieldAccessReversePolicy,
                isSuperUser
              )
            }
          >
            Generate Invoice
          </ButtonModal>
        ) : (
          <></>
        ),
        // renderExtendOrderButton(),
      ]}
      beforeSubmit={() => {
        // show popup alert when signed date is empty, on add form only
        if (!id && !state["signed_date"]) {
          return sweetAlert
            .fire({
              title: "Signed date still empty",
              text: "Are you sure you want to create the order without signed date?",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Yes, do it!",
            })
            .then((result) => {
              if (result.isConfirmed) {
                return Promise.resolve();
              } else {
                return Promise.reject();
              }
            });
        }
      }}
      btnPreviewSubmit={
        !id ? (
          <ButtonModal
            onClick={() => {
              const mainFormInvalid = !utils.form.validateFormOnly(
                state,
                setState,
                definitions.validators
              );
              const invalidTabs = [
                "t_discount_orders",
                "t_addon_orders",
                "t_price_updates",
              ].map(
                (tabName) =>
                  !utils.form.validateTabForm(
                    tabName,
                    state,
                    setState
                  )
              );
              if (mainFormInvalid || invalidTabs.some((item) => item))
                return;

              if (isHttpRequestError) {
                setIsHttpRequestError(false);
              }

              setIsLoading(true);
              setModalPreviewOrderSubmit(true);
            }}
            className="btn btn-outline-success mr-1"
          >
            Preview
          </ButtonModal>
        ) : (
          <React.Fragment />
        )
      }
      definitions={definitions}
      isAbleToDuplicate
      isAbleToExtendOrder={generateExtendOrderEligibility()}
      removedUnusedFields={Object.values(FieldOrderToBeRemoved)}
      modifyValueWhenDuplicate={generateModifyValueForExtendOrder()}
      removedDuplicateFields={[
        "number",
        "t_discount_orders",
        "t_addon_orders",
        "t_service_schedules",
        "t_price_updates",
        "t_discount_ordersInfoMessage",
        "t_addon_ordersInfoMessage",
        "backdate_checkout",
        "checkout_date",
        "reason_of_check_out",
        "checkout_category",
        "checkout_reason",
        "confirm_checkout",
        "split_deposit_and_rent",
        "extend_and_hold_invoice",
        "deposit_forfeited",
        "is_migration_data",
      ]}
      replaceValueWhenDuplicate={[
        {
          name: "order_status",
          value: 9,
        },
        {
          name: "transfer_from",
          value: "",
        },
        {
          name: "is_migration_data",
          value: false,
        },
      ]}
      tab={tab}
      state={state}
      setState={setState}
    >
      <TabPaneSection style={{ padding: "25px 16px 0px" }}>
        <CHTextView name="number" title="Order ID" />
        {/* Start of QR Code and URL Copy Button Section */}
        <CQRCodeAndURL
          label="Order URL"
          url={`/order/${state.number}`}
          id={state.number}
          isShowQRButton={false}
        />
        {/* End of QR Code and URL Copy Button Section */}
        <CHAsyncSelect
          // start of v3 props
          isUsingV3
          options={tenantOptions}
          handleOnChange={(val: any) => handleOnChange(val, "tenant")}
          handleOnInputChange={(
            val: string,
            meta: InputActionMeta
          ) => {
            handleOnInputChange(val, meta, "tenant");
          }}
          isSearchable
          inputValue={tenantKeyword}
          value={dropdownValues.tenant}
          isLoading={isTenantLoading}
          // end of v3 props
          name="tenant"
          title="Tenant"
          read_only_label="tenant_name"
          isRequired
          readOnly={
            (!id && state?.order_type === 2) ||
            id ||
            state["t_discount_orders"]?.length
          }
          helperText={
            !id && state["t_discount_orders"]?.length
              ? "Please delete all of the additional discounts if you want to change the Tenant"
              : null
          }
        />
        <CHSelect
          afterChange={(option: { label: string; value: number }) => {
            if (option.value === 2) {
              setState((prevState: any) => {
                return {
                  ...prevState,
                  deposit: 0,
                  deposit_rules: 1,
                };
              });
            }
          }}
          name="tenant_category"
          title="Tenant Category"
          read_only_label="tenant_category_name"
          readOnly={
            state?.t_discount_orders?.length ||
            Number(state["monthly_commitment"]) > 1 ||
            id ||
            (id && doneStatus.includes(state["status"]))
          }
          isRequired
          data={{ optionField: "order_new_tenant_categories" }}
          helperText={
            !id &&
            (state?.t_discount_orders?.length ||
              Number(state["monthly_commitment"]) > 1)
              ? "Please delete all of the additional discounts and/or change monthly commitment to 0 to enable tenant category options"
              : null
          }
        />
        <CHAsyncSelect
          // V3 Props
          isUsingV3
          value={dropdownValues.building}
          handleOnChange={(val: any) =>
            handleOnChange(val, "building")
          }
          isSearchable
          handleOnInputChange={(
            val: string,
            meta: InputActionMeta
          ) => {
            handleOnInputChange(val, meta, "building");
          }}
          inputValue={buildingKeyword}
          options={buildingOptions}
          isLoading={isBuildingLoading}
          // end of v3 props

          name="building"
          read_only_label="building_name"
          title="Building"
          isRequired
          readOnly={isBuildingReadOnly()}
          itemsExtractor={(row: {
            building_name: string;
            building_type: number;
            id: number;
            live_date: string;
            rukita_option: boolean;
            minimum_deposit: number;
            eligible_for_discount: boolean;
            allow_for_more_than_30_days: boolean;
          }) => {
            // this block case is used for editing existing order to extract required field data
            if (row.id === state["building"] && id) {
              setState((prevState: any) => ({
                ...prevState,
                live_date: row.live_date,
                building_type: row.building_type,
                eligible_for_discount: row.eligible_for_discount,
                allow_for_more_than_30_days:
                  row.allow_for_more_than_30_days,
              }));
            }
            return {
              label:
                row.building_name +
                " - " +
                (row.building_type === 1
                  ? "Centralized"
                  : "Decentralized"),
              value: row.id,
              live_date: row.live_date,
              building_type: row.building_type,
              rukita_option: row.rukita_option,
              minimum_deposit: row.minimum_deposit,
              eligible_for_discount: row.eligible_for_discount,
              allow_for_more_than_30_days:
                row.allow_for_more_than_30_days,
            };
          }}
          helperText={getBuildingHelperText()}
          isClearable={false}
        />
        {/* Start of Deposit Holder Button Section */}
        <CHSelect
          name="deposit_holder"
          title="Deposit Holder"
          isRequired
          data={{
            optionField: "order_deposit_holder",
          }}
          // we will make it required in the future, dont forget the validation in definitions.js as well
          // isRequired
          readOnly={!isDepositHolderEditable}
        />
        {/* End of Deposit Holder Button Section */}
        <CHAsyncSelect
          // V3 Props
          isUsingV3
          value={dropdownValues.roomVariant}
          handleOnChange={(val: any) =>
            handleOnChange(val, "room_variant")
          }
          options={roomVariantOptions}
          isLoading={isRoomVariantLoading}
          // end of v3 props
          name="room_variant"
          read_only_label="room_variant_name"
          title="Room Variant"
          dependsOn="building"
          isRequired
          readOnly={
            (!id && state?.order_type === 2) ||
            id ||
            (id && doneStatus.includes(state["status"])) ||
            state?.exclude_deposit
          }
          helperText={
            state?.exclude_deposit && !id
              ? "Please uncheck exclude deposit first if you want to update this field"
              : ""
          }
          data="building/room_variant?__type__=select_entries&is_sop_filter=1&limit=10"
          itemsExtractor={(row: {
            name: string;
            id: number;
            price: number;
          }) => {
            return {
              label: `${row.name}${
                !id ? `- ${utils.formatter.currency(row.price)}` : ""
              }`,
              price: row.price,
              value: row.id,
            };
          }}
          afterChange={(value: {
            label: string;
            price: number;
            value: number;
          }) => {
            setState((prevState: any) => {
              return {
                ...prevState,
                selected_room_variant_price: value.price,
                deposit: renderDepositAmount(
                  prevState?.deposit_rules,
                  value.price
                ),
              };
            });
          }}
          isClearable={false}
        />
        <CHAsyncSelect
          // V3 Props
          isUsingV3
          value={dropdownValues.room}
          handleOnChange={(val: any) => handleOnChange(val, "room")}
          options={roomOptions}
          isLoading={isRoomLoading}
          // end of v3 props
          name="room"
          read_only_label="room_name"
          title="Room"
          dependsOn="room_variant"
          isRequired={state?.building_type === 1}
          // helperText={
          //   state["available_on"]
          //     ? `Minimum Check-In Date is ${moment(
          //         state["available_on"]
          //       ).format("MMM DD YYYY")}`
          //     : ""
          // sementara dihide dulu karena banyak hiccup di logic BE }
          helperText={
            state?.exclude_deposit && !id
              ? "Please uncheck exclude deposit first if you want to update this field"
              : ""
          }
          data={`building/room?__type__=select_entries&limit=10&selected_date=${moment().format(
            "YYYY-MM-DD"
          )}`}
          // data={`building/room?__type__=select_entries&is_sop_filter=1&limit=1000`} for now selected_date is hardcoded to today, not reading the selected date because the room option will be lost if we change the selected_date
          itemsExtractor={(row: {
            price: number;
            name: string;
            id: number;
            available_on: string;
          }) => {
            return {
              label: `${row.name}${
                state?.building_type === 2
                  ? `- ${utils.formatter.currency(row.price)}`
                  : ""
              }`,
              value: row.id,
              available_on: row.available_on,
              price: row.price,
            };
          }}
          afterChange={(value: {
            price: number;
            name: string;
            id: number;
            available_on: string;
          }) => {
            setState((prev: any) => ({
              ...prev,
              selected_room_price: value.price,
              available_on: value.available_on,
              deposit:
                state?.building_type === 2
                  ? renderDepositAmount(
                      prev?.deposit_rules,
                      value.price
                    )
                  : prev.deposit,
            }));
          }}
          isClearable={false}
          readOnly={
            (!id && state?.order_type === 2) ||
            id ||
            state?.exclude_deposit
          }
        />
        {id ? (
          <CHTextView
            name="deposit"
            title="Deposit Amount"
            renderer={(value: number) =>
              utils.formatter.currency(value)
            }
          />
        ) : (
          <CHTextField
            name="deposit"
            title="Deposit Amount"
            type="number"
            readOnly={getDepositDisableStatus()}
            useCurrencyFormatHelper={!(state?.exclude_deposit && !id)}
            helperText={
              state?.exclude_deposit && !id
                ? "Please uncheck exclude deposit first if you want to update this field"
                : ""
            }
          />
        )}
        <CHSelect
          name="deposit_rules"
          read_only_label="deposit_rule_name"
          title="Deposit Rules"
          data={{
            optionField: "order_new_deposit_rules",
          }}
          filterItems={(
            options: {
              value: number;
              label: string;
            }[]
          ) => {
            // This function filters the options array based on certain conditions
            // If state.building_object is falsy, it will return all options
            // If state.building_object.rukita_option is truthy and option.value !== 6, it will filter out any option that matches the condition
            // If both conditions are truthy, it will return all options
            const newOptions = options?.filter((option) => {
              if (!state.building_object) {
                return true;
              }

              if (!state.building_object.rukita_option) {
                return option?.value !== 6;
              }

              return true;
            });

            return newOptions;
          }}
          readOnly={
            (!id && state?.order_type === 2) ||
            id ||
            state?.exclude_deposit ||
            state["tenant_category"] === 2 ||
            (state.building_type === 1 && !state.room_object) ||
            (state.building_type === 2 && !state.room_variant_object)
          }
          helperText={
            state?.exclude_deposit && !id
              ? "Please uncheck exclude deposit first if you want to update this field"
              : ""
          }
          isClearable={false}
        />

        {!doneStatus.includes(state["original___order_status"]) ? (
          <CHRichTextField
            name="deposit_notes"
            title="Deposit Notes"
          />
        ) : (
          <CHTextView
            name="deposit_notes"
            title="Deposit Notes"
            isSafeContent
          />
        )}
        <CHCheckBox
          name="deposit_forfeited"
          title="Deposit Forfeited"
          readOnly={isDepositForfeitedReadOnly()}
        />
        {/* There is a special case for extend order for this field
          if order creation is coming from extend order button
          then it needs to read from field actual_price
        */}
        <CHTextView
          name={
            !id && state?.order_type === 2 ? "actual_price" : "price"
          }
          title="Price"
          readOnly={id}
          renderer={(value: Object) => {
            return utils.formatter.currency(value);
          }}
        />
        {id ||
        (moment(state?.checkin_date).isAfter(
          moment().add(1, "days")
        ) &&
          state?.order_type !== 2) ? (
          <CHCheckBox
            name="extend_and_hold_invoice"
            title="Extend & Hold Invoice"
            readOnly={id || state?.split_deposit_and_rent}
          />
        ) : (
          <></>
        )}
        <CHCheckBox
          name="exclude_deposit"
          title="Exclude Deposit"
          disabled={
            (!id && state?.order_type === 2) ||
            id ||
            state?.split_deposit_and_rent
          }
        />
      </TabPaneSection>
      <TabPaneSection accordionId="formAccordion">
        <LegacyTenantInfo />
        <Rent />
        <ServiceEligibility />
        <OtherInfo />
      </TabPaneSection>
      <RukiModal
        isOpen={isBackdateModalGenerateInvoiceOpen}
        onClose={() => setIsBackdateModalGenerateInvoiceOpen(false)}
        headerTitle="Preview Invoice"
        headerType="close"
        modalSize={isHttpRequestError ? 387 : 1032}
        css={
          isHttpRequestError
            ? ModalPreviewOrderSubmitCSSError
            : ModalPreviewOrderSubmitCSSDefault
        }
        modalFooter={
          <GenerateInvoiceFooter>
            <CButton
              title="Cancel"
              key="cancel-button-generate-invoice"
              onClick={() =>
                setIsBackdateModalGenerateInvoiceOpen(false)
              }
              margin="0 10px"
            />
            <CButton
              title="Generate Invoice"
              key="generate-button-generate-invoice"
              onClick={handleOnClickGenerateNewInvoice}
            />
          </GenerateInvoiceFooter>
        }
      >
        <React.Fragment>
          <ModalPreviewOrderSubmit
            data={modalGenerateInvoiceData}
            onClose={() =>
              setIsBackdateModalGenerateInvoiceOpen(false)
            }
            customCheckInDateTitle="Start Date"
          />
        </React.Fragment>
      </RukiModal>
      <RukiModal
        isOpen={modalPreviewOrderSubmit}
        onClose={() => setModalPreviewOrderSubmit(false)}
        headerTitle="Detail"
        headerType="close"
        css={
          isHttpRequestError
            ? ModalPreviewOrderSubmitCSSError
            : ModalPreviewOrderSubmitCSSDefault
        }
        modalSize={isHttpRequestError ? 387 : 1032}
      >
        {!isHttpRequestError ? (
          <React.Fragment>
            {!isLoading && (
              <ModalPreviewOrderSubmit
                data={modalPreviewOrderSubmitData}
                onClose={() => setModalPreviewOrderSubmit(false)}
              />
            )}
          </React.Fragment>
        ) : (
          <React.Fragment>
            {!isLoading && (
              <Alert
                textHeading="Request Failed"
                textMessages="Something wrong, please check your order input again."
                onClose={() => setModalPreviewOrderSubmit(false)}
              />
            )}
          </React.Fragment>
        )}
      </RukiModal>
    </TabPane>
  );
};

const GenerateInvoiceFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 10px;
`;

export default withRouter(Building);
