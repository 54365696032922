import React, { Fragment } from "react";
import styled from "styled-components";
import { parseThousands } from "utils/generalUtils";
import CFilterContainer from "../display/CFilterContainer";

type filterAppliedProps = {
  isLoading: boolean;
  totalRows: number;
  reset: Function;
  search: Function;
  filterKeys: string[];
  setState: React.Dispatch<
    React.SetStateAction<
      Record<
        string,
        | string
        | string[]
        | number[]
        | {
            value: string;
            label: string;
          }[]
      >
    >
  >;
  lastAppliedFilters: Record<
    string,
    string | string[] | number[] | { value: string; label: string }[]
  >;
};

const AppliedFilters: React.FC<filterAppliedProps> = ({
  isLoading,
  totalRows,
  reset,
  search,
  filterKeys,
  lastAppliedFilters,
  setState,
}) => {
  const handleRemoveItem = (name: string, param: number | string) => {
    const selectedKeyFilter = lastAppliedFilters[name] as {
      value: string;
      label: string;
    }[];
    if (selectedKeyFilter?.length) {
      const newSelectedFilterRemove = selectedKeyFilter.filter(
        (item) => item.value !== param
      );
      const newFilters = {
        ...lastAppliedFilters,
        [name]: newSelectedFilterRemove,
      };
      setState(newFilters);
      search(newFilters);
    }
  };

  return (
    <AppliedFiltersStyled className="applied-filter">
      {!isLoading && (
        <>
          <div className="filter-caption mb-1">
            <b className="text-dark">
              Filter result{" "}
              {isLoading
                ? ""
                : `(Show ${parseThousands(totalRows)} data)`}
            </b>{" "}
            <button className="reset-link position-relative">
              Reset
              <span
                onClick={() => reset()}
                className="overlay-navigation"
              ></span>
            </button>
          </div>
          <div className="applied-filter-container">
            {filterKeys.map((filterName, idx) => {
              const labelList = lastAppliedFilters[filterName] as {
                value: string | number;
                label: string;
              }[];
              if (labelList === null || labelList?.length === 0) {
                return <Fragment key={filterName + idx}></Fragment>;
              }
              return (
                <CFilterContainer
                  key={filterName + idx}
                  filterName={filterName}
                  labelList={labelList}
                  handleRemoveItem={handleRemoveItem}
                />
              );
            })}
          </div>
        </>
      )}
    </AppliedFiltersStyled>
  );
};

const AppliedFiltersStyled = styled.div`
  .reset-link {
    background: none;
    border: none;
    color: #35b0a7;
    font-weight: 300;
  }

  /* .filter-item {
    margin-bottom: 16px;
    & .name {
      text-transform: capitalize;
      width: 60px;
      margin-right: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      align-self: start;
      flex-shrink: 0;
    }
  }

  .label-container {
    column-gap: 8px;
    row-gap: 8px;
    flex-wrap: wrap;
  } */
`;

export default AppliedFilters;
