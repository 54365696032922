import { useState } from "react";

import { useParams, withRouter } from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import Form from "../../../../components/ubold/organisms/Form";

import CVTextField from "../../../../components/ubold/molecules/forms/CVTextField";
import CVSwitch from "../../../../components/ubold/molecules/forms/CVSwitch";

import definitions from "./definitions";
import useGlobalState from "state";
import CVSelectV2 from "components/ubold/molecules/forms/CVSelectV2";

function CAddEditPage() {
  let { id } = useParams();

  const initialState = {};

  const [state, setState] = useState(initialState);
  const [constants] = useGlobalState("constant_values");

  return (
    <Page
      title={
        (id === undefined ? "Add New" : "Edit") +
        " " +
        definitions.title
      }
      restAccessCode={definitions.restAccessCode}
    >
      <Form
        id={id}
        definitions={definitions}
        state={state}
        setState={setState}
      >
        <CVTextField
          name="name"
          title="Name"
          maxLength={50}
          isRequired
        />
        <CVTextField
          name="phone"
          title="Phone"
          maxLength={128}
          helperText="Example: +62 812 3456 7890"
          isRequired
        />
        <CVTextField name="email" title="Email" maxLength={254} />
        <CVSwitch name="status" title="Status" />
        <CVSelectV2
          name="hear_about_us"
          title="Hear About Us"
          data={constants?.user_landlord_hear_about_us_choice}
          isCheckbox
          isMulti
          readOnly
          disableMultiValue
          removeCloseIcon
        />
      </Form>
    </Page>
  );
}

export default withRouter(CAddEditPage);
