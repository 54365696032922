import configBuilder from "../../../components/ubold/ConfigBuilder";

import utils from "../../../utils";

let definitions = {
  title: "Voucher Redemptions",
  frontendPath: "/marketing/promotion/voucher/voucher_redemption",
  baseLink: "/promotion/voucher_redemption",
  restAccessCode: "promotion.voucher_management",
  __table__: {
    filterColumn: 4,
    addButton: false,
    hideEditButton: true,
    removeIdentifier: (row) => {
      return row["tenant_name"] + " - " + row["voucher_name"];
    },
    filters: [
      {
        id: "tenant",
        name: "Tenant",
        type: "async_select",
        data: "user/tenant?__type__=select_entries",
      },
      {
        id: "phone_number",
        name: "Phone Number",
        type: "number",
      },
      {
        id: "voucher",
        name: "Voucher",
        type: "async_select",
        data: "user/user_voucher?__type__=select_entries",
      },
      {
        id: "voucher_provider",
        name: "Provider",
        type: "select",
        data: {
          optionField: "promotion_voucher_management_provider",
        },
      },
      {
        id: "status",
        name: "Status",
        type: "select",
        data: {
          optionField: "promotion_voucher_redemption_status",
        },
      },
      {
        id: "date_requested",
        name: "Date Requested",
        type: "date",
      },
      {
        id: "date_transferred",
        name: "Date Transferred",
        type: "date",
      },
    ],
    columns: [
      {
        id: "tenant_name",
        enableHyperlink: true,
        name: "Tenant",
        renderer: (value, row) => {
          return value + " - " + row["tenant_email"];
        },
      },
      {
        id: "phone_number",
        name: "Phone Number",
      },
      {
        id: "voucher_name",
        name: "Voucher",
      },
      {
        id: "status",
        name: "Status",
        referStateFieldName: "promotion_voucher_redemption_status",
        renderer: (value, _, constants) => {
          let label = "-";
          (constants ?? []).forEach((obj) => {
            if (obj.value === parseInt(value)) {
              label = obj.label;
            }
          });
          return label;
        },
      },
      {
        id: "voucher_provider",
        name: "Provider",
        referStateFieldName: "promotion_voucher_management_provider",
        renderer: (value, _, constants) => {
          let label = "-";
          (constants ?? []).forEach((obj) => {
            if (obj.value === parseInt(value)) {
              label = obj.label;
            }
          });
          return label;
        },
      },
      {
        id: "date_requested",
        name: "Date Requested",
        renderer: (value) => {
          return utils.formatter.dateTime(value);
        },
      },
      {
        id: "date_transferred",
        name: "Date Transferred",
        renderer: (value) => {
          return utils.formatter.dateTime(value);
        },
      },
      {
        id: "prove_transferred_original",
        name: "Prove Transferred",
        isSafeContent: true,
        canDoOrdering: false,
        center: true,
        renderer: (value) => {
          if (value && value !== "-" && value !== "") {
            return (
              "<img alt='image' src='" + value + "' width='50px' />"
            );
          } else {
            return value;
          }
        },
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
