import { withRouter } from "react-router-dom";

import CTextField from "../../atoms/forms/CTextField";

import useGlobalState from "../../../../state";

import utils from "../../../../utils";
import CButton from "components/ubold/atoms/buttons/CButton";
import { useState } from "react";
import { useToast } from "utils/customHooks";

const CHHiddenTextView = (props: any) => {
  const {
    name,
    title,
    type = "text",
    isRequired = false,
    autoComplete = "off",
    restAccessCode,
    maxLength = null,
    state,
    setState,
    helperText = "",
    validate,
    inputGroups = null,
    min,
    defaultValue = null,
    handleOnClick = () => {},
    toastMessage = "",
    buttonTitle = "Click to show",
    children = null,
    allowedToShowHiddenText = false,
  } = props;

  const [fieldAccesses] = useGlobalState("field_accesses");

  const [isHidden, setIsHidden] = useState<boolean>(true);

  const toast = useToast();

  const input = isHidden ? (
    <CTextField
      type={type}
      readOnly
      state={state}
      autoComplete={autoComplete}
      maxLength={maxLength}
      setState={setState}
      validate={validate}
      min={min}
      defaultValue={new Array(12)
        .fill(String.fromCharCode(9679))
        .join(" ")}
    />
  ) : (
    <CTextField
      name={name}
      type={type}
      readOnly
      state={state}
      autoComplete={autoComplete}
      maxLength={maxLength}
      setState={setState}
      validate={validate}
      min={min}
      defaultValue={defaultValue}
    />
  );

  return (
    <div
      className="form-group row mb-3"
      style={{
        display:
          !isRequired &&
          restAccessCode &&
          utils.access.isFieldHidden(
            fieldAccesses,
            restAccessCode + "." + name
          )
            ? "none"
            : "flex",
      }}
    >
      <label
        htmlFor="name"
        className={
          "col-md-3 col-form-label font-weight-bold" +
          (state[name + "Error"] ? " text-danger" : "")
        }
      >
        {title}
      </label>
      <div className="col-md-3">
        {inputGroups !== null ? (
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">{inputGroups}</span>
            </div>
            {input}
          </div>
        ) : (
          input
        )}
        {helperText !== "" ? (
          <span
            className="text-muted font-13 pt-1 mb-0"
            dangerouslySetInnerHTML={{ __html: helperText }}
          />
        ) : (
          ""
        )}
        {helperText !== "" && state[name + "Error"] ? <br /> : <></>}
        {state[name + "Error"] ? (
          <span className="text-danger" style={{ fontSize: "12px" }}>
            {state[name + "Error"]}
          </span>
        ) : (
          <></>
        )}
      </div>
      <CButton
        title={buttonTitle}
        onClick={() => {
          allowedToShowHiddenText && setIsHidden(false);
          handleOnClick();
          if (toastMessage) {
            toast({
              description: toastMessage,
            });
          }
        }}
      />
      {children}
    </div>
  );
};

export default withRouter(CHHiddenTextView);
