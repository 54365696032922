import { useParams, withRouter } from "react-router-dom";

import TabPaneSecondary from "../../../../../../components/ubold/organisms/TabPaneSecondary";

import COneOrManyToManyField from "../../../../../../components/ubold/molecules/forms/COneOrManyToManyField";

function Image(props: any) {
  const { definitions, tab, state, setState } = props;

  let { id }: any = useParams();

  return (
    <TabPaneSecondary
      definitions={definitions}
      restAccessCodeNamespace="image"
      tab={tab}
      state={state}
      setState={setState}
      readOnly={true}
    >
      <COneOrManyToManyField
        id={id}
        name={tab.id}
        photoFields={["photo_original"]}
        restPath="order/service_image/"
        relationKey="service_id"
        columns={[
          {
            name: "photo_original",
            title: "Photo",
            type: "photo_upload",
            isRequired: true,
            readOnly: true,
          },
        ]}
      />
    </TabPaneSecondary>
  );
}

export default withRouter(Image);
