import React from "react";
import styled from "styled-components";

type CButtonProps = {
  title: string;
  icon?: string;
  badgeNumber?: number;
  onClick?: Function;
  isDisabled?: boolean;
  backgroundColor?: string;
  color?: string;
  fontWeight?: string;
  fontSize?: string;
  border?: string;
  margin?: string;
};

const CButton: React.FC<CButtonProps> = ({
  icon,
  title,
  onClick,
  isDisabled = false,
  backgroundColor,
  color,
  fontWeight,
  fontSize,
  border,
  margin,
}) => {
  return (
    <CButtonStyled
      onClick={() => onClick()}
      disabled={isDisabled}
      isDisabled={isDisabled}
      backgroundColor={backgroundColor}
      color={color}
      fontWeight={fontWeight}
      fontSize={fontSize}
      border={border}
      margin={margin}
    >
      {icon && (
        <img
          src={process.env.PUBLIC_URL + icon}
          alt={"icon-" + title}
          width="18px"
          height="18px"
        />
      )}
      {title}
    </CButtonStyled>
  );
};

const CButtonStyled = styled.button<{
  isDisabled?: boolean;
  backgroundColor?: string;
  color?: string;
  fontWeight?: string;
  fontSize?: string;
  border?: string;
  margin?: string;
}>`
  background: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : "#ffffff"};
  border: ${({ border }) => (border ? border : "1px solid #35b0a7")};
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "14px")};
  color: ${({ color }) => (color ? color : "#35b0a7")};
  font-weight: ${({ fontWeight }) =>
    fontWeight ? fontWeight : "500"};
  margin: ${({ margin }) => (margin ? margin : "0px")};
  &:active {
    background: ${({ backgroundColor }) =>
      backgroundColor ? backgroundColor : "#35b0a7"};
    color: ${({ color }) => (color ? color : "#ffffff")};
  }
  cursor: ${({ isDisabled }) =>
    isDisabled ? "not-allowed" : "pointer"};
  & img {
    margin-right: 10px;
  }
`;

export default CButton;
