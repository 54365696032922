import React from "react";
const EmptyStateIllustration =
  require("../../../../assets/Empty_State.svg") as string;

import styled from "styled-components/macro";
import { IEmptyStateProps } from "@types";

const EmptyState: React.FC<IEmptyStateProps> = ({
  textDescription,
  textTitle,
  imageSource,
  isDashboard,
  iconHeight,
  iconWidth,
}) => {
  return (
    <EmptyStateBase isDashboard={isDashboard}>
      <img
        alt="No data's illustration"
        src={imageSource ?? EmptyStateIllustration}
        height={iconHeight ?? 175}
        width={iconWidth ?? 250}
      />
      {(textDescription || textTitle) && (
        <Caption isDashboard={isDashboard}>
          {textTitle && <Title>{textTitle}</Title>}
          {textDescription && (
            <Description>{textDescription}</Description>
          )}
        </Caption>
      )}
    </EmptyStateBase>
  );
};

const EmptyStateBase = styled.div<{
  isDashboard?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: ${({ isDashboard }) =>
    isDashboard ? "column" : "unset"};
  font-family: "Rubik";
  img {
    margin-right: ${({ isDashboard }) => (isDashboard ? 0 : "24px")};
  }

  h6 {
    font-size: 14px;
    font-weight: 500;
  }

  p {
    font-size: ${({ isDashboard }) =>
      isDashboard ? "12px" : "14px"};
    font-weight: ${({ isDashboard }) => (isDashboard ? 400 : 300)};
    color: ${({ isDashboard }) => (isDashboard ? "#646464" : "#222")};
  }
  padding: 16px 0 16px 0;
`;

const Caption = styled.div<{
  isDashboard?: boolean;
}>`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: ${({ isDashboard }) => (isDashboard ? "32px" : 0)};
`;

const Title = styled.label`
  font-weight: 500;
  font-size: 14px;
  margin: 0;
  padding-bottom: 8px;
`;

const Description = styled.p`
  font-weight: 400;
  font-size: 12px;
  margin: 0;
`;

export default EmptyState;
