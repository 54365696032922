import { useState } from "react";

import { useParams, withRouter } from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import Form from "../../../../components/ubold/organisms/Form";

import definitions from "./definitions";
import CVTextField from "../../../../components/ubold/molecules/forms/CVTextField";
import CVDateTimePicker from "../../../../components/ubold/molecules/forms/CVDateTimePicker";
import CVSelect from "../../../../components/ubold/molecules/forms/CVSelect";
import CVTextArea from "../../../../components/ubold/molecules/forms/CVTextArea";
import CVSimpleManyToMany from "../../../../components/ubold/molecules/forms/CVSimpleManyToMany";
import CVImageView from "components/ubold/molecules/forms/CVImageView";

function CAddEditPage() {
  let { id } = useParams();

  const initialState = {
    roles: 1,
  };

  const [state, setState] = useState(initialState);

  return (
    <Page
      title={
        (id === undefined ? "Add New" : "Edit") +
        " " +
        definitions.title
      }
      restAccessCode={definitions.restAccessCode}
    >
      <Form
        id={id}
        definitions={definitions}
        state={state}
        setState={setState}
      >
        <CVTextField
          name="name"
          title="Name"
          maxLength={80}
          isRequired
        />
        <CVTextField
          name="country_code"
          title="Country Code"
          maxLength={2}
          type="number"
        />
        <CVTextField
          name="display_name"
          title="Display Name (shown on RuManage, Tenant App & Website)"
          maxLength={80}
          isRequired
        />
        <CVImageView
          name="photo_url"
          title="Display Photo (shown on RuManage, Tenant App & Website)"
        />
        <CVTextField
          name="phone_number"
          title="Phone Number"
          maxLength={20}
          type="number"
        />
        <CVTextField name="email" title="Email" maxLength={254} />
        <CVTextField name="address" title="Address" maxLength={100} />
        <CVTextField
          name="id_number"
          title="ID Number"
          maxLength={20}
          type="number"
        />
        <CVDateTimePicker name="dob" title="DoB" type="date" />
        <CVSelect
          name="roles"
          title="Role"
          isRequired
          data={[
            {
              label: "Agent",
              value: 1,
            },
            {
              label: "Owner",
              value: 2,
            },
            {
              label: "Manager",
              value: 3,
            },
          ]}
        />
        <CVTextField
          name="organisation"
          title="Organisation"
          maxLength={50}
        />
        <CVTextField
          name="bank"
          title="Bank"
          maxLength={30}
          isRequired
        />
        <CVTextField
          name="bank_account_name"
          title="Bank Account Name"
          maxLength={80}
          isRequired
        />
        <CVTextField
          name="bank_account_number"
          title="Bank Account Number"
          maxLength={20}
          isRequired
        />
        <CVTextArea name="notes" title="Notes" />
        <CVSimpleManyToMany
          name="units"
          title="Units"
          data="building/room_variant?__type__=select_entries"
        />
        <CVTextField name="npwp" title="NPWP" maxLength={16} />
      </Form>
    </Page>
  );
}

export default withRouter(CAddEditPage);
