import {withRouter} from "react-router-dom";

import AccordionPane from "../../../../../../../components/ubold/organisms/AccordionPane";

import CHCheckBox from "../../../../../../../components/ubold/molecules/forms/CHCheckBox";
import CHTextField from "../../../../../../../components/ubold/molecules/forms/CHTextField";
import CHSelect from "../../../../../../../components/ubold/molecules/forms/CHSelect";

function Services(props) {
    const {baseLink, accordionId, onSubmit, state, setState} = props;

    return (
        <AccordionPane
            id='attributes'
            name='Building Attributes'
            accordionId={accordionId}
            baseLink={baseLink}
            onSubmit={onSubmit}
            state={state}
            setState={setState}>
            <CHCheckBox
                name='laundry'
                title='Laundry'
            />
            <CHTextField
                name='quota_laundry'
                title='Quota Laundry'
                type='number'
            />
            <CHSelect
                name='denomination'
                title='Denomination'
                data={[
                    {
                        label: 'kgs',
                        value: 1
                    },
                    {
                        label: 'pcs',
                        value: 2
                    },
                ]}
            />
            <CHSelect
                name='duration_laundry'
                title='(Laundry) Quota per'
                data={[
                    {
                        label: 'week',
                        value: 2
                    },
                    {
                        label: 'month',
                        value: 3
                    },
                ]}
            />

            <CHCheckBox
                name='room_cleaning'
                title='Room Cleaning'
            />
            <CHTextField
                name='quota_rc'
                title='Quota RC'
                type='number'
            />
            <CHSelect
                name='duration_rc'
                title='(Room Cleaning) Quota per'
                data={[
                    {
                        label: 'week',
                        value: 2
                    },
                    {
                        label: 'month',
                        value: 3
                    },
                ]}
            />

            <CHCheckBox
                name='internet'
                title='Internet'
            />
            <CHTextField
                name='internet_speed'
                title='Internet Speed'
                type='number'
            />

            <CHCheckBox
                name='repair_maintenance'
                title='Repair Maintenance'
            />
            <CHTextField
                name='sla'
                title='SLA'
                type='number'
            />
        </AccordionPane>
    );
}

export default withRouter(Services);