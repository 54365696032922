import styled, { CSSProp } from "styled-components";
import React, { useRef, useEffect, useState } from "react";
import {
  disableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";
import type { BodyScrollOptions } from "body-scroll-lock";

import { fadeTypeProp, ModalProps } from "../../../../@types";
import { useEventListener } from "../../../../utils/customHooks";
import closeBtnIcon from "assets/icons/close.svg";

const options: BodyScrollOptions = {
  reserveScrollBarGap: true,
};

const ModalTemplate: React.FC<ModalProps> = ({
  children,
  isOpen,
  onClose,
  modalSize,
  modalClass = "",
  headerTitle = "",
  headerClass = "",
  changeType,
  css,
  headerType,
  modalFooter = null,
}) => {
  const [fadeType, setFadeType] = useState<fadeTypeProp>(null);
  const modalRef = useRef<HTMLDivElement>(null);

  const handleClick = (e: any) => {
    e.preventDefault();
    setFadeType("out");
  };

  const onEscKeyDown = (e: Event) => {
    const event = e as KeyboardEvent;
    if (event.key !== "Escape") return;
    setFadeType("out");
  };

  const transitionEnd = (e: any) => {
    const event = e as TransitionEvent;
    if (event.propertyName !== "opacity" || fadeType === "in") return;

    if (fadeType === "out") {
      clearAllBodyScrollLocks();
      onClose();
    }
  };

  useEffect(() => {
    if (modalRef.current) {
      disableBodyScroll(modalRef.current, options);
    }
    return () => clearAllBodyScrollLocks();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setFadeType("in");
    }, 0);
  }, []);

  useEffect(() => {
    setFadeType((prevIsOpen) => {
      if (!isOpen && prevIsOpen) {
        return "out";
      } else {
        return prevIsOpen;
      }
    });
  }, [isOpen]);

  useEventListener("keydown", onEscKeyDown);

  return (
    <ModalStyled
      className={`fade-${fadeType} ${modalClass}`}
      size={modalSize}
      onTransitionEnd={transitionEnd}
      ref={modalRef}
      changeType={changeType}
      css={css}
    >
      <div className={` box-wrapper relative flex`}>
        <div className="box-dialog">
          {headerType === "close" && (
            <div className={`box-header ${headerClass}`}>
              <h4 className="box-title text-xl font-bold">
                {headerTitle}
              </h4>
              <button
                type="button"
                onClick={handleClick}
                className="x-close right-4 flex items-center justify-center w-10 h-10 rounded-full"
              >
                <img
                  src={closeBtnIcon}
                  width="16px"
                  height="16px"
                  alt="close"
                  className="close-icn"
                />
              </button>
            </div>
          )}
          <div className="box-content">{children}</div>
          {modalFooter}
        </div>
        <div className={`background`} onMouseDown={handleClick} />
      </div>
    </ModalStyled>
  );
};

type ModalStyledProps = {
  size: number;
  changeType?: number;
  css: CSSProp;
};

const ModalStyled = styled.div<ModalStyledProps>`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: start;
  justify-content: center;
  z-index: 2000;
  width: 100%;
  height: 100%;
  opacity: 0;
  overflow-x: hidden;
  overflow-y: auto;
  & .box-wrapper {
    margin: 64px auto 0;
    bottom: 32px;
  }
  &.fade-in {
    opacity: 1;
    transition: all linear 0.15s;
    bottom: 32px;
    & .box-wrapper {
      transition: all linear 0.15s;
      bottom: 0;
    }
  }
  &.fade-out {
    opacity: 0;
    transition: all linear 0.15s;
    bottom: 0;
    & .box-wrapper {
      transition: all linear 0.15s;
      bottom: 32px;
    }
  }
  .background {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    z-index: 1040;
    display: block;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    outline: 0;
  }
  .box-dialog {
    width: ${({ size }) => size}px;
    z-index: 1050;
    background-color: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    position: relative;
    top: 0;
    border-radius: 4px;
    .box-content {
      width: 100%;
      padding: 0px 24px 24px;
    }
    .box-header {
      padding: 24px 24px 16px;
      display: flex;
      justify-content: start;
      align-items: center;
      position: relative;
      background: #fff;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      .box-title {
        color: #012156;
        margin: 0;
      }
      .x-close {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        background: #eaeff5;
        right: 24px;
        font-size: 35px;
        line-height: 35px;
        font-weight: 400;
        text-shadow: none;
        color: black;
        cursor: pointer;
        background: none;
        border: none;
        padding: 0;
      }
    }
    .box-body {
      font-size: 14px;
      padding: 0px;
      width: auto;
      height: auto;
    }
    .box-footer {
      height: 48px;
      padding: 0px 24px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      border-top: 1px solid #c7c7c7;
    }
  }

  ${({ css }) => (css ? css : "")};

  /* & .request-detail {
    height: 72px;
    background: #fff;
    & .row-title {
      color: #6e8c9b;
      & .col-1 {
        flex: 0 0 150px;
      }
      & .col-2 {
        flex: 1 1 auto;
      }
    }

    & .row-content {
      color: #012156;
      & .col-1 {
        flex: 0 0 150px;
      }
      & .col-2 {
        flex: 1 1 auto;
      }
    }
  }

  & .account-info,
  & .bank-account {
    background: #fff;
    & .header {
      border-bottom: 2px solid #e1e6e8;
      background: #fff;
    }
    & .next-btn {
      position: absolute;
      right: 16px;
    }
    & .bank-name {
      border-bottom: 2px solid #e1e6e8;
      background: #fff;
      & .row-name {
        color: #6e8c9b;
      }
      & span {
        color: #012156;
      }
    }
  }

  & .bank-account {
    background: #f6f9fa;
  }

  & .work-change {
    background: #fff;
    & .header {
      border-bottom: 2px solid #e1e6e8;
    }
    & .field-name {
      border-bottom: 2px solid #e1e6e8;
      background: #fff;
      & span {
        color: #012156;
      }
    }
    & .row-name {
      color: #6e8c9b;
    }
  }

  & .balance-wrapper {
    border: 2px solid #e1e6e8;
    color: #012156;
    background: #fff;

    & .amount {
      border-bottom: 1px solid #e1e6e8;
    }

    & .idr-wrapper {
      & > span {
        font-size: 32px;
        font-weight: 400;
      }
    }
  }

  & .troubled-work {
    background: #fff0d6;
  }

  & .button-container {
    & button {
      height: 56px;
    }

    & .reject {
      border: 2px solid #cf3e33;
      background: #fff;
      color: #cf3e33;
    }

    & .accept {
      color: #fff;
      background: #0f6fde;
    }
  }

  & .reasons-container {
    border-top: 2px solid #e1e6e8;
    background: #fff;
    & textarea {
      resize: none;
      background: none;
      color: #012156;
      &::placeholder {
        color: #aabcc5;
      }
    }
  }

  & .decline-request,
  & .accept-request {
    background: #cf3e33;
    height: 56px;
    width: 100%;
    color: #fff;
  }

  & .accept-request {
    background: #0f6fde;
  }

  .border-top-content {
    border-top: 2px solid #e1e6e8;
  }

  .attachment-box {
    border: 2px dotted #e1e6e8;
    height: 240px;
    cursor: pointer;
  }

  .attachment-uploaded {
    background: #f6f9fa;
    max-height: 448px;
    & > img {
      object-fit: contain;
      width: 100%;
      max-height: 448px;
    }
  }

  .attachment-error {
    border-color: #cf3e33;
  }

  .box-error {
    height: 40px;
    color: #cf3e33;
    background: #f5d8d6;
    &:before {
      position: absolute;
      top: -20px;
      width: 12px;
      height: 12px;
      border-style: solid;
      border-width: 12px;
      border-color: transparent transparent #f5d8d6;
      content: "";
      display: block;
      left: 20px;
    }
  }

  & .work-info {
    width: calc(100% - 80px);
  }

  & .timelines {
    background: #f6f9fa;
    position: relative;
    & .top-ball {
      border: 4px solid #e1e6e8;
      top: 46px;
    }
    & .bottom-ball {
      border: 4px solid #e1e6e8;
      top: ${({ changeType }) =>
    changeType === 1 ? "178px" : "202px"};
    }
    &::before {
      content: "";
      position: absolute;
      width: 4px;
      background: #e1e6e8;
      height: ${({ changeType }) =>
    changeType === 1 ? "135px" : "160px"};
      left: 22px;
      top: 52px;
    }
  }

  & .timeline-item {
    background: #fff;
    border: 2px solid #e1e6e8;
    &::before {
      position: absolute;
      top: 16px;
      width: 12px;
      height: 12px;
      border-style: solid;
      border-width: 12px;
      border-color: transparent #e1e6e8 transparent transparent;
      content: "";
      display: block;
      left: -24px;
    }

    &::after {
      position: absolute;
      top: 16px;
      width: 12px;
      height: 12px;
      border-style: solid;
      border-width: 12px;
      border-color: transparent #fff transparent transparent;
      content: "";
      display: block;
      left: -22px;
    }
  }

  & .attachment-item {
    max-width: 50%;
    background: #f6f9fa;
  }

  & .timeline-item-selected {
    background: #fff;
    border: 2px solid #012156;
    &::before {
      position: absolute;
      top: 16px;
      width: 12px;
      height: 12px;
      border-style: solid;
      border-width: 12px;
      border-color: transparent #012156 transparent transparent;
      content: "";
      display: block;
      left: -24px;
    }

    &::after {
      position: absolute;
      top: 16px;
      width: 12px;
      height: 12px;
      border-style: solid;
      border-width: 12px;
      border-color: transparent #fff transparent transparent;
      content: "";
      display: block;
      left: -22px;
    }
  } */
`;

export default ModalTemplate;
