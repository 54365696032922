import { useState } from "react";

import { useParams, withRouter } from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import Form from "../../../../components/ubold/organisms/Form";

import CVTextView from "../../../../components/ubold/molecules/forms/CVTextView";

import utils from "../../../../utils";

import definitions from "./definitions";

function UserVoucherViewPage() {
  let { id }: { id: string } = useParams();

  const initialState = {};

  const [state, setState] = useState(initialState);

  return (
    <Page
      title={"Detail of " + definitions.title}
      restAccessCode={definitions.restAccessCode}
    >
      <Form
        id={id}
        definitions={definitions}
        readOnly
        state={state}
        setState={setState}
      >
        <CVTextView
          name="tenant_name"
          title="Tenant"
          renderer={(value: string, row: any) => {
            return value + " - " + row["tenant_email"];
          }}
          readOnly
        />
        <CVTextView
          name="voucher_name"
          title="Voucher"
          renderer={(value: string, row: any) => {
            return (
              value +
              " - " +
              utils.formatter.currency(row["voucher_amount"])
            );
          }}
          readOnly
        />
        <CVTextView
          name="amount"
          title="Amount"
          renderer={(value: string) => {
            return utils.formatter.currency(value);
          }}
          readOnly
        />
        <CVTextView
          name="date_expired"
          title="Date Expired"
          renderer={(value: string) => {
            return utils.formatter.dateTime(value);
          }}
          readOnly
        />
        <CVTextView
          name="voucher_type"
          title="Voucher Type"
          renderer={(value: string) => {
            if (parseInt(value) === 1) {
              return "Internal Voucher";
            } else if (parseInt(value) === 2) {
              return "External Voucher";
            }
          }}
          readOnly
        />
        <CVTextView
          name="status"
          title="Status"
          renderer={(value: string) => {
            if (parseInt(value) === 1) {
              return "Active";
            } else if (parseInt(value) === 2) {
              return "Process";
            } else if (parseInt(value) === 3) {
              return "Expired";
            } else if (parseInt(value) === 4) {
              return "Redeemed";
            }
          }}
          readOnly
        />
        <CVTextView
          name="date_used"
          title="Date Used"
          renderer={(value: string) => {
            return utils.formatter.dateTime(value);
          }}
          readOnly
        />
      </Form>
    </Page>
  );
}

export default withRouter(UserVoucherViewPage);
