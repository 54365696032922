import { useState } from "react";
import { useParams, withRouter } from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";
import definitions from "./definitions";

import TabHeaders from "components/ubold/organisms/TabHeaders";
import TabContent from "components/ubold/organisms/TabContent";
import TabbedForm from "components/ubold/organisms/TabbedForm";

import HomepageCollectionTab from "./Tabs/HomepageCollection";
import BuildingCollectionTab from "./Tabs/BuildingCollection";

function CHomepageCollectionAddEditPage() {
  let { id }: { id: string } = useParams();
  const initialState = {
    published: true,
  };

  const [state, setState] = useState(initialState);

  return (
    <Page
      title={(!id ? "Add New" : "Edit") + " " + definitions.title}
      restAccessCode={definitions.restAccessCode}
    >
      <TabbedForm
        id={id}
        state={state}
        setState={setState}
        definitions={definitions}
      >
        <TabHeaders />
        <TabContent>
          <HomepageCollectionTab />
          <BuildingCollectionTab />
        </TabContent>
      </TabbedForm>
    </Page>
  );
}

export default withRouter(CHomepageCollectionAddEditPage);
