import { useParams, withRouter } from "react-router-dom";
import { useEffect } from "react";

import COneOrManyToManyFieldV2 from "components/ubold/molecules/forms/COneOrManyToManyFieldV2";
import AccordionPane from "components/ubold/organisms/AccordionPane";
import CHSingleRow from "components/ubold/molecules/forms/CHSingleRow";

function InvoiceDetails(props: any) {
  const {
    state,
    setState,
    generalKey,
    accordionId,
    baseLink,
    onSubmit,
    accordionIndex,
  } = props;

  let { id }: any = useParams();

  useEffect(() => {
    if (
      !state?.[generalKey]?.length &&
      state?.[`${generalKey}-default`]?.length
    ) {
      setState((prevState: any) => ({
        ...prevState,
        [generalKey]: state?.[`${generalKey}-default`],
      }));
    }
  }, [state?.[generalKey]]);

  return (
    <AccordionPane
      id={generalKey}
      name={generalKey}
      accordionId={accordionId}
      baseLink={baseLink}
      onSubmit={onSubmit}
      state={state}
      setState={setState}
      shown={accordionIndex === 0}
    >
      <COneOrManyToManyFieldV2
        id={id}
        name={generalKey}
        saveOnlyFromMainForm
        disableActionBtn
        dependsTabName={generalKey}
        relationKey="invoice_id"
        columns={[
          {
            name: "category",
            type: "select",
            title: "Category",
            readOnly: true,
            defaultVal: 2,
            data: {
              optionField: "finance_new_invoice_detail_category",
            },
            dataList: state[generalKey],
          },
          {
            name: "sub_category",
            type: "select",
            title: "Sub Category",
            readOnly: true,
            defaultVal: 5,
            data: {
              optionField: "finance_new_invoice_detail_sub_category",
            },
            dataList: state[generalKey],
          },

          {
            name: "item",
            title: "Name",
            type: "text",
            readOnly: true,
            dataList: state[generalKey],
            isRequired: true,
          },
          {
            name: "price",
            type: "number",
            title: "Amount",
            readOnly: true,
            dataList: state[generalKey],
            releaseStatus: "original___release_status",
            invoiceStatus: "original___invoice_status",
            isRequired: true,
            dynamicTypeField: true,
          },
          {
            name: "description",
            type: "textarea",
            title: "Descriptions",
            readOnly: true,
          },
          {
            name: "start_date",
            type: "date",
            title: "Start Date",
            dynamicValue: true,
            readOnly: true,
            dataList: state[generalKey],
          },
          {
            name: "end_date",
            type: "date",
            title: "End Date",
            readOnly: true,
            dataList: state[generalKey],
            dynamicMinDate: true,
            dynamicReadOnly: true,
            dynamicValue: true,
            plusOne: false,
            requireField: "Start Date",
          },
        ]}
      />
      {state?.[`${generalKey}-subtotal`] ? (
        <CHSingleRow
          title="Total"
          data={state?.[`${generalKey}-subtotal`]}
          titleWidth="57%"
        />
      ) : (
        <></>
      )}
    </AccordionPane>
  );
}

export default withRouter(InvoiceDetails);
