import React from "react";

import {withRouter} from "react-router-dom";

import utils from "../../../utils";

function TabContent(props) {
    const {id, children, definitions, state, setState} = props;

    return (
        <div className="tab-content" style={{padding: '0px'}}>
            {utils.commons.isArray(children) ? children.map((child, i) => {
                return React.cloneElement(child, {
                    key: i,
                    state: state,
                    setState: setState,
                    definitions: definitions,
                    id: id,
                    tab: definitions.tabs[i]
                })
            }) : children ? React.cloneElement(children, {
                key: '0',
                state: state,
                setState: setState,
                definitions: definitions,
                id: id,
                tab: definitions.tabs[0]
            }) : <></>}
        </div>
    );
}

export default withRouter(TabContent);