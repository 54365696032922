import configBuilder from "../../../components/ubold/ConfigBuilder";
import utils from "../../../utils";

let definitions = {
  title: "User Vouchers",
  frontendPath: "/admin/logs/voucher_log",
  baseLink: "/user/user_voucher",
  restAccessCode: "user.user_voucher",
  __table__: {
    filterColumn: 4,
    addButton: false,
    hideEditButton: true,
    hideRemoveButton: true,
    editAsDetail: true,
    filters: [
      {
        id: "tenant",
        name: "Tenant",
        type: "async_select",
        data: "user/tenant?__type__=select_entries",
      },
      {
        id: "voucher",
        name: "Voucher",
        type: "async_select",
        data: "promotion/voucher_management?__type__=select_entries",
      },
      {
        id: "voucher_type",
        name: "Voucher Type",
        type: "select",
        data: {
          optionField: "user_voucher_type",
        },
      },
      {
        id: "status",
        name: "Status",
        type: "select",
        data: {
          optionField: "user_voucher_status",
        },
      },
      {
        id: "amount",
        name: "Amount",
        type: "number",
      },
      {
        id: "date_expired",
        name: "Date Expired",
        type: "date",
      },
      {
        id: "date_created",
        name: "Date Created",
        type: "date",
      },
      {
        id: "date_updated",
        name: "Date Updated",
        type: "date",
      },
    ],
    columns: [
      {
        id: "tenant_name",
        name: "Tenant",
        renderer: (value, row) => {
          return value + " - " + row["tenant_email"];
        },
        enableHyperlink: true,
      },
      {
        id: "voucher_name",
        name: "Voucher",
        renderer: (value, row) => {
          return (
            value +
            " - " +
            utils.formatter.currency(row["voucher_amount"])
          );
        },
      },
      {
        id: "voucher_type",
        name: "Voucher Type",
        referStateFieldName: "user_voucher_type",
        renderer: (value, _, constants) => {
          let label = "-";
          (constants ?? []).forEach((obj) => {
            if (obj.value === parseInt(value)) {
              label = obj.label;
            }
          });
          return label;
        },
      },
      {
        id: "status",
        name: "Status",
        referStateFieldName: "user_voucher_status",
        renderer: (value, _, constants) => {
          let label = "-";
          (constants ?? []).forEach((obj) => {
            if (obj.value === parseInt(value)) {
              label = obj.label;
            }
          });
          return label;
        },
      },
      {
        id: "amount",
        name: "Amount",
        renderer: (value) => {
          return utils.formatter.currency(value);
        },
      },
      {
        id: "date_expired",
        name: "Date Expired",
        renderer: (value) => {
          return utils.formatter.dateTime(value);
        },
      },
      {
        id: "date_created",
        name: "Date Created",
        renderer: (value) => {
          return utils.formatter.dateTime(value);
        },
      },
      {
        id: "date_updated",
        name: "Date Updated",
        renderer: (value) => {
          return utils.formatter.dateTime(value);
        },
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
