import utils from "../../../utils";

import configBuilder from '../../../components/ubold/ConfigBuilder';

let definitions = {
    title: 'Users',
    baseLink: '/crm/user',
    restAccessCode: 'administrator.user',
    __table__: {
        removeIdentifier: 'username',
        filters: [
            {
                id: 'username',
                name: 'Username',
                type: 'text'
            },
            {
                id: 'email',
                name: 'Email',
                type: 'email'
            },
            {
                id: 'is_superuser',
                name: 'Is Super User',
                type: 'select',
                data: [
                    {
                        label: 'Yes',
                        value: true
                    },
                    {
                        label: 'No',
                        value: false
                    }
                ]
            },
        ],
        columns: [
            {
                id: 'first_name',
                name: 'Name',
                renderer: (value, row) => {
                    return (value ? value : '') + ' ' + (row['last_name'] ? row['last_name'] : '');
                }
            },
            {
                id: 'username',
                name: 'Username'
            },
            {
                id: 'email',
                name: 'Email'
            },
            {
                id: 'is_superuser',
                name: 'Is Super User?',
                isSafeContent: true,
                center: true,
                renderer: (value) => {
                    if(value === 'true') {
                        return '<span style="color: rgb(26, 188, 156);"> ✔ </span>';
                    } else {
                        return '<span style="color: red;">✘</span>';
                    }
                }
            },
            {
                id: 'date_joined',
                name: 'Join At',
                renderer: (value) => {
                    return utils.formatter.dateTime(value);
                }
            }
        ]
    }
}

export default configBuilder.buildTablePageConfig(definitions);