import { withRouter } from "react-router-dom";

import AccordionPane from "../../../../../../../components/ubold/organisms/AccordionPane";

import CHAsyncSelect from "../../../../../../../components/ubold/molecules/forms/CHAsyncSelect";
import CHDateTimePicker from "../../../../../../../components/ubold/molecules/forms/CHDateTimePicker";
import CHTextArea from "../../../../../../../components/ubold/molecules/forms/CHTextArea";

import utils from "../../../../../../../utils";
import { sectionProps } from "@types";
import CHTextField from "components/ubold/molecules/forms/CHTextField";
import CHTextView from "components/ubold/molecules/forms/CHTextView";

function Attributes(props: sectionProps) {
  const { baseLink, accordionId, onSubmit, state, setState } = props;

  // let { id } = useParams<{ id: string }>();

  // const doneStatus = [4, 5, 6];

  return (
    <AccordionPane
      id="otherInfo"
      name="Other Info"
      accordionId={accordionId}
      baseLink={baseLink}
      onSubmit={onSubmit}
      shown={
        !!(
          utils.form.isFieldError(state, "rumanage_remarks") ||
          utils.form.isFieldError(state, "referral_code") ||
          utils.form.isFieldError(state, "signed_date") ||
          utils.form.isFieldError(state, "signed_by_1") ||
          utils.form.isFieldError(state, "signed_by_2") ||
          utils.form.isFieldError(state, "signed_by_3")
        )
      }
      state={state}
      setState={setState}
    >
      <CHAsyncSelect
        name="signed_by_1"
        title="Signed By 1"
        readOnly
        data="crm/user?__type__=select_entries&limit=10"
        itemsExtractor={(value: { username: string; id: number }) => {
          return {
            label: value.username,
            value: value.id,
          };
        }}
      />
      <CHAsyncSelect
        name="signed_by_2"
        title="Signed By 2"
        readOnly
        data="crm/user?__type__=select_entries&limit=10"
        itemsExtractor={(value: { username: string; id: number }) => {
          return {
            label: value.username,
            value: value.id,
          };
        }}
      />
      <CHAsyncSelect
        name="signed_by_3"
        title="Signed By 3"
        readOnly
        data="crm/user?__type__=select_entries&limit=10"
        itemsExtractor={(value: { username: string; id: number }) => {
          return {
            label: value.username,
            value: value.id,
          };
        }}
      />
      <CHDateTimePicker
        name="signed_date"
        title="Signed Date"
        type="date"
        readOnly
      />
      <CHTextField
        name="referral_code"
        title="Referral Code"
        maxLength={8}
        readOnly
      />
      <CHTextArea
        name="rumanage_remarks"
        title="RuManage Remarks"
        maxLength={150}
        withCharCounter
        readOnly
      />
      <CHTextView
        name="date_created"
        title="Date Created"
        renderer={(value: string) => {
          return utils.formatter.dateTime(value);
        }}
        readOnly
      />
      <CHTextView
        name="date_updated"
        title="Date Updated"
        renderer={(value: string) => {
          return utils.formatter.dateTime(value);
        }}
        readOnly
      />
      <CHTextView name="cancel_by" title="Cancel By" />
      <CHTextView name="created_by" title="Created By" />
    </AccordionPane>
  );
}

export default withRouter(Attributes);
