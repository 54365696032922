// check token for the first render and refresh it if's expired

import config from "configs";
import { VerifyTokenContext } from "context";
import React, { useEffect, useState } from "react";
import utils from "utils";
import { handleRefreshToken } from "utils/HttpClient";

const WithRefreshToken: React.FC = ({ children }) => {
  const [isTokenChecked, setTokenChecked] = useState(false);

  useEffect(() => {
    const t = utils.store.get("t");

    if (t) {
      utils.httpClient.post(
        config.apiUrl + "verify_token/",
        {
          token: utils.store.get("t"),
        },
        () => {
          handleRefreshToken(utils.store)
            .then(() => {
              setTokenChecked(true);
            })
            .catch((err) => {
              // ------------------------------------------------
              // Debug for bad redirect behavior
              console.error("err", JSON.stringify(err.response));
              const message = err.response.status
                ? `Message: ${err.response.data.detail} \nError Code: ${err.response.status}`
                : "Unidentified Error";
              alert(message);
              // end
              // ------------------------------------------------
              setTimeout(() => {
                setTokenChecked(true);
              }, 1000);
            });
        },
        () => {
          setTimeout(() => {
            setTokenChecked(true);
          }, 1000);
        },
        true
      );
    } else {
      setTokenChecked(true);
    }
  }, []);

  return (
    <VerifyTokenContext.Provider value={false}>
      {isTokenChecked ? (
        children
      ) : (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
          }}
        >
          <div
            className="spinner-border m-1"
            style={{
              width: "15px",
              height: "15px",
              left: "40px",
              position: "relative",
            }}
            role="status"
          >
            <span className="sr-only">Verifying token...</span>
          </div>
          <div className="text">Verifying token...</div>
        </div>
      )}
    </VerifyTokenContext.Provider>
  );
};

export default WithRefreshToken;
