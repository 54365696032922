import Tenant from "pages/User/Tenant";
import TenantAddEdit from "pages/User/Tenant/AddEdit";
import TenantViewOnly from "pages/User/Tenant/ViewOnly";
import TenantHistory from "pages/User/Tenant/History/TenantHistory";

const TenantRoutes = [
  {
    path: "/tenant/tenant",
    component: Tenant,
  },
  {
    path: "/tenant/tenant/(add|edit)/:id([0-9]+)?",
    component: TenantAddEdit,
  },
  {
    path: "/tenant/tenant/view/:id([0-9]+)?",
    component: TenantViewOnly,
  },
  {
    path: "/tenant/tenant/history/:id([0-9]+)?",
    component: TenantHistory,
  },
];

export default TenantRoutes;
