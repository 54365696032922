import configBuilder from "../../../components/ubold/ConfigBuilder";

let definitions = {
  title: "One Time Purchase Orders",
  frontendPath: "/sales/onetimepurchase",
  baseLink: "/one_time_purchase/order",
  restAccessCode: "one_time_purchase.order",
  __table__: {
    filterColumn: 3,
    removeIdentifier: "number",
    selectedInitialRowsPerPage: 4,
    isAddButtonDisabled: true,
    isHideRemoveButton: true,
    hideEditButton: true,
    filters: [
      {
        id: "order",
        name: "Order",
        type: "async_select",
        data: "one_time_purchase/order/?__type__=select_entries",
        itemsExtractor: (item) => {
          return {
            label: item.number,
            value: item.id,
          };
        },
      },
      {
        id: "otp_status",
        name: "Order Status",
        type: "select",
        data: {
          optionField: "otp_order_status",
        },
      },
      {
        id: "courier",
        name: "Courier",
        type: "select",
        data: {
          optionField: "buildings_building_shipping_method",
        },
      },
    ],
    columns: [
      {
        id: "number",
        name: "Order Number",
        enableHyperlink: true,
      },
      {
        id: "tenant_text",
        name: "Tenant",
      },
      {
        id: "otp_status",
        name: "Status",
        referStateFieldName: "otp_order_status",
        renderer: (value, _, constants) => {
          let label = "-";
          (constants ?? []).forEach((obj) => {
            if (obj.value === parseInt(value)) {
              label = obj.label;
            }
          });
          return label;
        },
      },
      {
        id: "courier",
        name: "Courier",
        referStateFieldName: "buildings_building_shipping_method",
        renderer: (value, _, constants) => {
          let label = "-";
          (constants ?? []).forEach((obj) => {
            if (obj.value === parseInt(value)) {
              label = obj.label;
            }
          });
          return label;
        },
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
