import {useState} from "react";

import {useParams, withRouter} from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import Form from "../../../../components/ubold/organisms/Form";

import definitions from "./definitions";
import CVTextField from "../../../../components/ubold/molecules/forms/CVTextField";

function CAddEditPage() {
    let {id} = useParams();

    const initialState = {};

    const [state, setState] = useState(initialState);

    return (
        <Page title={(id === undefined ? 'Add New' : 'Edit') + ' ' +  definitions.title}
              restAccessCode={definitions.restAccessCode}>
            <Form
                id={id}
                definitions={definitions}
                state={state}
                setState={setState}>
                <CVTextField
                    name='name'
                    title='Name'
                    maxLength={250}
                    isRequired
                    overrideLayout='12'
                />
            </Form>
        </Page>
    );
}

export default withRouter(CAddEditPage);