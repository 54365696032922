import { useState } from "react";
import { useParams, withRouter } from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import Form from "../../../../components/ubold/organisms/Form";

import CVTextField from "../../../../components/ubold/molecules/forms/CVTextField";
import CVSwitch from "../../../../components/ubold/molecules/forms/CVSwitch";
// import CVImageUpload from "../../../../components/ubold/molecules/forms/CVImageUpload";

import definitions from "./definitions";
import CVTextArea from "components/ubold/molecules/forms/CVTextArea";
import CVRichTextField from "components/ubold/molecules/forms/CVRichTextField";
import CVDateTimePicker from "components/ubold/molecules/forms/CVDateTimePicker";
import CVImageView from "components/ubold/molecules/forms/CVImageView";

function PromotionViewPage() {
  let { id }: { id: string } = useParams();

  const initialState = {};

  const [state, setState] = useState(initialState);

  return (
    <Page
      title={"Detail of " + definitions.title}
      restAccessCode={definitions.restAccessCode}
    >
      <Form
        id={id}
        definitions={definitions}
        readOnly
        state={state}
        setState={setState}
      >
        <CVImageView
          title="Promo Listing Mobile-Desktop Screen (450x253px, 150dpi, max. size 500kb)"
          name="banner_picture_original"
          isRequired
          readOnly
        />
        <CVImageView
          title="Promo Details Mobile-Tablet Screen (450x253px, 150dpi, max size 500kb):"
          name="banner_picture_mobile_tablet_screen"
          isRequired
          readOnly
        />
        <CVImageView
          title="Promo Details Desktop Screen (850x283px, 150dpi, max size 500kb):"
          name="banner_picture_desktop_screen"
          isRequired
          readOnly
        />
        <CVTextField
          title="Title"
          name="title"
          maxLength={75}
          isRequired
          readOnly
        />
        <CVTextField
          title="Title en"
          name="title_en"
          maxLength={75}
          isRequired
          readOnly
        />
        <CVTextField
          title="Slug"
          name="slug"
          maxLength={50}
          isRequired
          readOnly
        />
        <CVTextField
          title="Meta title"
          name="meta_title"
          maxLength={255}
          readOnly
        />
        <CVTextArea
          title="Meta description"
          name="meta_description"
          readOnly
        />
        <CVRichTextField
          title="Description"
          name="description"
          isRequired
          readOnly
        />
        <CVRichTextField
          title="Description En"
          name="description_en"
          isRequired
          readOnly
        />
        <CVRichTextField
          title="Term and Condition"
          name="term_and_condition"
          isRequired
          readOnly
        />
        <CVRichTextField
          title="Term and Condition En"
          name="term_and_condition_en"
          isRequired
          readOnly
        />
        <CVRichTextField
          title="How to use"
          name="how_to_use"
          isRequired
          readOnly
        />
        <CVRichTextField
          title="How to use En"
          name="how_to_use_en"
          isRequired
          readOnly
        />
        <CVRichTextField
          title="Disclaimer"
          name="disclaimer"
          readOnly
        />
        <CVRichTextField
          title="Disclaimer En"
          name="disclaimer_en"
          readOnly
        />
        <CVDateTimePicker
          name="start_date"
          title="Start Date"
          isRequired
          type="date"
          readOnly
        />
        <CVDateTimePicker
          name="end_date"
          title="End Date"
          isRequired
          type="date"
          readOnly
        />
        <CVTextField
          title="Code"
          name="code"
          maxLength={255}
          readOnly
        />
        <CVTextField
          name="sequence"
          title="Sequence"
          type="number"
          maxLength={3}
          isRequired
          readOnly
        />
        <CVSwitch title="Is published" name="is_published" readOnly />
      </Form>
    </Page>
  );
}

export default withRouter(PromotionViewPage);
