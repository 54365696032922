import configBuilder from "../../../../components/ubold/ConfigBuilder";

import pageDefinitions from "../definitions";

import utils from "../../../../utils";

const definitions = {
  ...pageDefinitions,
  validators: {
    landlord_lead: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    building_name: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    address: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    total_room: (value: string) => {
      return utils.validator.validateRequired(value);
    },
  },
};

export default configBuilder.buildAddEditPageConfig(definitions);
