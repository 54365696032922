import { useState } from "react";

import { useParams, withRouter } from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import Form from "../../../../components/ubold/organisms/Form";

import CVTextField from "../../../../components/ubold/molecules/forms/CVTextField";
import CVSelect from "../../../../components/ubold/molecules/forms/CVSelect";
import CVAsyncSelect from "../../../../components/ubold/molecules/forms/CVAsyncSelect";

import definitions from "./definitions";
import CVRichTextField from "components/ubold/molecules/forms/CVRichTextField";
import CVCheckBox from "components/ubold/molecules/forms/CVCheckBox";
import CVImageUpload from "components/ubold/molecules/forms/CVImageUpload";

function CAddEditPage() {
  let { id } = useParams();

  const initialState = {};

  const [state, setState] = useState(initialState);

  return (
    <Page
      title={
        (id === undefined ? "Add New" : "Edit") +
        " " +
        definitions.title
      }
      restAccessCode={definitions.restAccessCode}
    >
      <Form
        id={id}
        definitions={definitions}
        state={state}
        setState={setState}
      >
        <CVTextField
          name="name"
          title="Name"
          maxLength={50}
          overrideLayout="12"
          isRequired
        />
        <CVAsyncSelect
          name="country"
          title="Country"
          data="place/country?__type__=select_entries"
          isRequired
        />
        <CVSelect
          name="province"
          title="Province"
          dependsOn="country"
          data="place/province?__type__=select_entries"
          isRequired
        />
        <CVSelect
          name="city"
          title="City"
          dependsOn="province"
          data="place/city?__type__=select_entries"
          isRequired
        />
        <CVSelect
          name="district"
          title="District"
          dependsOn="city"
          data="place/district?__type__=select_entries"
          isRequired
        />
        <CVTextField
          name="meta_title"
          title="Meta Title"
          maxLength={255}
        />
        <CVTextField
          name="meta_title_en"
          title="Meta Title EN"
          maxLength={255}
        />
        <CVRichTextField
          name="meta_description"
          title="Meta Description"
        />
        <CVRichTextField
          name="meta_description_en"
          title="Meta Description EN"
        />
        <CVRichTextField name="description" title="Description" />
        <CVRichTextField
          name="description_en"
          title="Description EN"
        />
        <CVCheckBox name="published" title="Published" />
        <CVTextField name="sequence_number" title="Sequence" />
        <CVImageUpload
          name="photo_header_original"
          title="Photo Header"
          isRequired
        />
      </Form>
    </Page>
  );
}

export default withRouter(CAddEditPage);
