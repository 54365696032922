import utils from "utils";
import moment from "moment";

export const parseThousands = (
  num: number | null,
  type: number = 1
) => {
  if (typeof num === "number") {
    return `${num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  }
  return num;
};

export const removeProps = (obj: any, removeKey: string[]) => {
  for (let i = 0; i < removeKey.length; i++) {
    delete obj?.[removeKey[i]];
  }
  return obj;
};

export const compareObj = (obj1: any, obj2: any) => {
  if (!obj1) return;
  for (const [key] of Object?.entries(obj1)) {
    if (key.indexOf("Error") !== -1) {
      delete obj1[key];
    }
  }
  const compareResult = JSON.stringify(obj1) === JSON.stringify(obj2);

  return compareResult;
};

export const parseReturnMessage = (
  message: string,
  setState: any
) => {
  const messageObject = JSON.parse(message);
  for (const key of Object.keys(messageObject)) {
    let message: any = [];
    if (utils.commons.isArray(messageObject[key])) {
      for (let keyItem in messageObject[key]) {
        message.push(messageObject[key][keyItem]);
      }
    } else {
      message.push(messageObject[key]);
    }

    setState((prevState: any) => ({
      ...prevState,
      [key + "Error"]: message.join(", "),
      hasErrors: true,
    }));

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }
};

export const getFlagTheme = (roomStatus: number) => {
  /**
   * Its coming from BE
   * 6  : Vacant - Dirty
   * 10 : Vacant - Ready
   * 11 : Occupied
   * 12 : Occupied - Need Repair
   * 13 : Out of Service
   * 9  : Occupied by RuOptions Legacy Tenant
   */

  switch (roomStatus) {
    case 6:
      return "GREEN_DARK";

    case 10:
      return "GREEN";

    case 11:
      return "BLUE";

    case 12:
      return "PURPLE";

    case 13:
      return "RED";

    case 9:
      return "GOLD";

    default:
      return "BLACK";
  }
};

export const strAbbreviation = (
  strVal: string,
  abbrevTarget: number[]
) => {
  /** this function originally build for case
   * Occupied by RuOptions Legacy Tenant
   * abbrevTarget is which index of words should be shorten
   */

  let splitStr = strVal.trim().split(" ");
  if (splitStr.length > 1) {
    return `${splitStr[0]} ${splitStr[1]} ${splitStr[2]} ${splitStr[
      abbrevTarget[0]
    ].charAt(0)} ${splitStr[abbrevTarget[1]].charAt(0)}`;
  }
};

export function getArrayDifference(
  arr1: Array<any>,
  arr2: Array<any>
) {
  return arr1
    .filter((x) => !arr2.includes(x))
    .concat(arr2.filter((x) => !arr1.includes(x)));
}

export const getTransitionRoomStatus = (roomStatus: number) => {
  /** default status when creation
   *  and Occupied by RuOption Legacy Tenant (9)
   */
  let arrTransition: number[] = [6, 10, 13];

  switch (roomStatus) {
    case 6 /** Vacant Dirty */:
      return (arrTransition = [10, 13]);

    case 10 /** Vacant Ready */:
      return (arrTransition = [13]);

    case 11 /** Occupied */:
      return (arrTransition = [12]);

    case 12 /** Occupied Need Repair */:
      return (arrTransition = [13]);

    case 13 /** Out of Service */:
      return (arrTransition = [10]);

    default:
      return arrTransition;
  }
};

export const getStatusByRule = (
  rules: number[],
  data: Array<{ label: string; value: number; disabled?: boolean }>
) => {
  data?.forEach((obj) => {
    if (!rules?.includes(obj.value)) {
      obj.disabled = true;
    }
  });
  return data;
};

export const getTransitionMaintenanceStatus = (
  roomStatus: number
) => {
  let arrTransition: number[] = [0];

  switch (roomStatus) {
    case 13:
      return (arrTransition = [1, 2, 3, 4, 5, 6]);
    case 12:
      return (arrTransition = [1, 2, 5, 6]);
    default:
      return arrTransition;
  }
};

export const getMinMaxDate = (type: string, defaultDate: string) => {
  const today = moment().format("YYYY-MM-DD");
  let date = today;

  if (type === "min") {
    if (defaultDate > today) {
      date = defaultDate;
    }
  } else {
    if (defaultDate > today) {
      date = moment(defaultDate).add(29, "days").format("YYYY-MM-DD");
    } else {
      date = moment().add(29, "days").format("YYYY-MM-DD");
    }
  }
  return date;
};

export const isDecimalNum = (num: number) => {
  const result = num - Math.floor(num) !== 0;
  if (result) return true;
  return false;
};

export const firstLetterUppercase = (text: string) => {
  if (!text) return "";
  const finalText = text.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
    letter.toUpperCase()
  );
  return finalText;
};

// this function is created when searching in asyncselect component that start with unique character like [] or so on, it cause crash on the app side
export const escapeRegExp = (input: string) => {
  if (typeof input !== "string") {
    return "";
  }
  return input.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
};
