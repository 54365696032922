import {useParams, withRouter} from "react-router-dom";

import TabPaneSecondary from "../../../../../../components/ubold/organisms/TabPaneSecondary";

import COneOrManyToManyField from "../../../../../../components/ubold/molecules/forms/COneOrManyToManyField";

function StructureRoles(props) {
    const {definitions, tab, state, setState} = props;

    let {id} = useParams();

    return (
        <TabPaneSecondary
            definitions={definitions}
            tab={tab}
            restAccessCodeNamespace='role'
            state={state}
            setState={setState}>
            <COneOrManyToManyField
                id={id}
                name={tab.id}
                restPath='crm/structure__role/'
                relationKey='structure_id'
                columns={[
                    {
                        name: 'role',
                        type: 'async_select',
                        title: 'Role',
                        disabledAtEdit: true,
                        isRequired: true,
                        isUniqueOption: true,
                        data: 'crm/role/?__type__=select_entries'
                    },
                    {
                        name: 'note',
                        type: 'text',
                        title: 'Note',
                        maxLength: 255
                    },
                ]}
            />
        </TabPaneSecondary>
    );
}

export default withRouter(StructureRoles);