import utils from "../../../utils";

import configBuilder from "../../../components/ubold/ConfigBuilder";

let definitions = {
  title: "Accesses",
  baseLink: "/crm/access",
  restAccessCode: "administrator.access_list",
  __table__: {
    filterColumn: 3,
    removeIdentifier: "code",
    filters: [
      {
        id: "code",
        name: "Access Code",
        type: "text",
      },
      {
        id: "name",
        name: "Access Name",
        type: "text",
      },
      {
        id: "type",
        name: "Access Type",
        type: "select",
        data: {
          optionField: "crm_access_type",
        },
      },
      {
        id: "menu",
        name: "Menu",
        type: "async_select",
        data: "crm/menu?__type__=select_entries",
      },
      {
        id: "descriptive_name",
        name: "Descriptive Name",
        type: "text",
      },
    ],
    columns: [
      {
        id: "code",
        name: "Code",
      },
      {
        id: "type",
        name: "Type",
        referStateFieldName: "crm_access_type",
        renderer: (value, _, constants) => {
          let label = "-";
          (constants ?? []).forEach((obj) => {
            if (obj.value === parseInt(value)) {
              label = obj.label;
            }
          });
          return label;
        },
      },
      {
        id: "menu_name",
        name: "Menu Name",
      },
      {
        id: "name",
        name: "Name",
      },
      {
        id: "descriptive_name",
        name: "Descriptive Name",
      },
      {
        id: "created_at",
        name: "Created At",
        renderer: (value) => {
          return utils.formatter.dateTime(value);
        },
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
