import styled from "styled-components/macro";
import { withRouter } from "react-router";

import utils from "utils";

function CHRowView(props) {
  const { data, title, multi, isDeduction } = props;

  const renderComponent = (data, i) => {
    return (
      <Container key={i}>
        <TitleContainer>{title}</TitleContainer>
        <DataContainer>
          {multi ? data.voucher_name : data.referal}
        </DataContainer>
        <DataContainer>
          {isDeduction ? "(-) " : ""}
          {utils.formatter.currency(data.price)}
        </DataContainer>
      </Container>
    );
  };
  return multi ? (
    <Parent multi={multi}>
      {data.map((val, i) => renderComponent(val, i))}
    </Parent>
  ) : (
    <Parent>{renderComponent(data, 0)}</Parent>
  );
}

export default withRouter(CHRowView);

const Parent = styled.div`
  width: 100%;
  margin: 0 12px 0 12px;
  & > :first-child {
    border-top: 4px solid #edeff1;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  padding: 5px 0 5px 0;
  border-bottom: 2px solid #edeff1;
`;

const TitleContainer = styled.div`
  padding-left: 10px;
  width: 38%;
  font-size: 16px;
  font-weight: 900;
`;

const DataContainer = styled.div`
  padding-top: 7px;
  width: 300px;
`;
