// SEO
import SeoCMS from "pages/Website/SeoCms";
import SeoCMSAddEdit from "pages/Website/SeoCms/AddEdit";
import SeoCMSView from "pages/Website/SeoCms/ViewOnly";
// BUILDING PRIORITY
import BuildingPriority from "pages/Building/PriorityBuilding";
import BuildingPriorityAddEdit from "pages/Building/PriorityBuilding/AddEdit";
import BuildingPriorityViewOnly from "pages/Building/PriorityBuilding/ViewOnly";
// POPULAR LANDMARKS
import PopularLandmark from "pages/Building/PopularLandmark";
import PopularLandmarkAddEdit from "pages/Building/PopularLandmark/AddEdit";
import PopularLandmarkViewOnly from "pages/Building/PopularLandmark/ViewOnly";
// POPULAR LOCATIONS
import PopularLocation from "pages/Building/PopularLocation";
import PopularLocationAddEdit from "pages/Building/PopularLocation/AddEdit";
import PopularLocationViewOnly from "pages/Building/PopularLocation/ViewOnly";
//
// BUILDING COLLECTIONS
//
// COLLECTIONS
import Collection from "pages/Building/Collection";
import CollectionAddEdit from "pages/Building/Collection/AddEdit";
import CollectionViewOnly from "pages/Building/Collection/ViewOnly";
// COLLECTION GROUPS
import CollectionGroup from "pages/Building/CollectionGroup";
import CollectionGroupAddEdit from "pages/Building/CollectionGroup/AddEdit";
import CollecitonGroupViewOnly from "pages/Building/CollectionGroup/ViewOnly";
//
// RECOMMENDATION CITIES
import RecommendationCity from "pages/Building/RecommendationCity";
import RecommendationCityAddEdit from "pages/Building/RecommendationCity/AddEdit";
import RecommendationCityViewOnly from "pages/Building/RecommendationCity/ViewOnly";
//
// PLACE
//
// PLACE GROUPS
import PlaceGroup from "pages/Building/PlaceGroup";
import PlaceGroupAddEdit from "pages/Building/PlaceGroup/AddEdit";
import PlaceGroupViewOnly from "pages/Building/PlaceGroup/ViewOnly";
//
// LANDLORD PAGE
import MakeOverGallery from "pages/Building/MakeOverGallery";
import MakeOverGalleryAddEdit from "pages/Building/MakeOverGallery/AddEdit";
import MakeOverGalleryViewOnly from "pages/Building/MakeOverGallery/ViewOnly";
// HOMEPAGE COLLECTION
import HomepageCollection from "pages/Website/HomepageCollection";
import HomepageCollectionAddEdit from "pages/Website/HomepageCollection/AddEdit";
import HomepageCollectionView from "pages/Website/HomepageCollection/ViewOnly";

const WebsiteRoutes = [
  // SEO
  {
    path: "/website/seo_cms",
    component: SeoCMS,
  },
  {
    path: "/website/seo_cms/(add|edit)/:id([0-9]+)?",
    component: SeoCMSAddEdit,
  },
  {
    path: "/website/seo_cms/view/:id([0-9]+)?",
    component: SeoCMSView,
  },
  // BUILDING PRIORITY
  {
    path: "/website/building_priority",
    component: BuildingPriority,
  },
  {
    path: "/website/building_priority/(add|edit)/:id([0-9]+)?",
    component: BuildingPriorityAddEdit,
  },
  {
    path: "/website/building_priority/view/:id([0-9]+)?",
    component: BuildingPriorityViewOnly,
  },
  // POPULAR LANDMARKS
  {
    path: "/website/popular_landmark",
    component: PopularLandmark,
  },
  {
    path: "/website/popular_landmark/(add|edit)/:id([0-9]+)?",
    component: PopularLandmarkAddEdit,
  },
  {
    path: "/website/popular_landmark/view/:id([0-9]+)?",
    component: PopularLandmarkViewOnly,
  },
  // POPULAR LOCATIONS
  {
    path: "/website/popular_location",
    component: PopularLocation,
  },
  {
    path: "/website/popular_location/(add|edit)/:id([0-9]+)?",
    component: PopularLocationAddEdit,
  },
  {
    path: "/website/popular_location/view/:id([0-9]+)?",
    component: PopularLocationViewOnly,
  },
  //
  // BUILDING COLLECTIONS
  //
  // COLLECTIONS
  {
    path: "/website/building_collection/collection",
    component: Collection,
  },
  {
    path: "/website/building_collection/collection/(add|edit)/:id([0-9]+)?",
    component: CollectionAddEdit,
  },
  {
    path: "/website/building_collection/collection/view/:id([0-9]+)?",
    component: CollectionViewOnly,
  },
  // COLLECTION GROUPS
  {
    path: "/website/building_collection/collection_group",
    component: CollectionGroup,
  },
  {
    path: "/website/building_collection/collection_group/(add|edit)/:id([0-9]+)?",
    component: CollectionGroupAddEdit,
  },
  {
    path: "/website/building_collection/collection_group/view/:id([0-9]+)?",
    component: CollecitonGroupViewOnly,
  },
  //
  // RECOMMENDATION CITIES
  {
    path: "/website/recommendation_city",
    component: RecommendationCity,
  },
  {
    path: "/website/recommendation_city/(add|edit)/:id([0-9]+)?",
    component: RecommendationCityAddEdit,
  },
  {
    path: "/website/recommendation_city/view/:id([0-9]+)?",
    component: RecommendationCityViewOnly,
  },
  //
  // PLACE
  //
  // PLACE GROUPS
  {
    path: "/website/place/place_group",
    component: PlaceGroup,
  },

  {
    path: "/website/place/place_group/(add|edit)/:id([0-9]+)?",
    component: PlaceGroupAddEdit,
  },
  {
    path: "/website/place/place_group/view/:id([0-9]+)?",
    component: PlaceGroupViewOnly,
  },
  //
  // LANDLORD PAGE
  {
    path: "/website/landlord_page",
    component: MakeOverGallery,
  },
  {
    path: "/website/landlord_page/(add|edit)/:id([0-9]+)?",
    component: MakeOverGalleryAddEdit,
  },
  {
    path: "/website/landlord_page/view/:id([0-9]+)?",
    component: MakeOverGalleryViewOnly,
  },
  // HOMEPAGE COLLECTION
  {
    path: "/website/homepage_collection",
    component: HomepageCollection,
  },
  {
    path: "/website/homepage_collection/(add|edit)/:id([0-9]+)?",
    component: HomepageCollectionAddEdit,
  },
  {
    path: "/website/homepage_collection/view/:id([0-9]+)?",
    component: HomepageCollectionView,
  },
];

export default WebsiteRoutes;
