import ProfileSettingAddEdit from "pages/ProfileSettings/index";

const AppRoutes = [
  {
    path: "/profile_settings",
    component: ProfileSettingAddEdit,
  },
];

export default AppRoutes;
