import { fadeTypeProp, ToastType, ToastShape } from "@types";
import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { useInterval } from "utils/customHooks";

const listTypeOfWhiteCloseBtn: ToastType[] = [
  "success",
  "warning",
  "danger",
  "info",
];

const Toast: React.FC<{
  data: ToastShape;
  deleteToast: (num: number) => void;
}> = ({ data, deleteToast }) => {
  const toastRef = useRef<HTMLDivElement>(null);
  const [fadeType, setFadeType] = useState<fadeTypeProp>(null);

  const transitionEnd = (e: any) => {
    const event = e as TransitionEvent;
    if (event.propertyName !== "top" || fadeType === "in") return;

    if (fadeType === "out") {
      deleteToast(data.id);
    }
  };

  const isCloseWhiteBtn = listTypeOfWhiteCloseBtn.some(
    (type) => type === data.type && data.type !== "warning"
  );

  useEffect(() => {
    setTimeout(() => {
      setFadeType("in");
    }, 0);
  }, []);

  useInterval(
    () => {
      setFadeType("out");
    },
    data ? data.dismissTime : null
  );

  return (
    <ToastStyled
      onTransitionEnd={transitionEnd}
      fadeType={fadeType}
      ref={toastRef}
      className={`fade-${fadeType}`}
      type={data.type}
    >
      <IconContainer>
        <img
          src={`/assets/icons/${
            data.type === "warning"
              ? "warning"
              : data.type === "info"
              ? "info"
              : data.type === "danger"
              ? "danger"
              : "success"
          }.svg`}
          width="24px"
          height="24px"
          alt="icn-notif"
        />
      </IconContainer>
      <Content>
        <span className="d-inline">{data.description}</span>
        <button
          onClick={() => setFadeType("out")}
          type="button"
          className="close-btn ml-6"
          style={{ width: "14px", height: "14px" }}
        >
          <img
            src={`/assets/icons/close${
              isCloseWhiteBtn ? "-white" : "-black"
            }.svg`}
            width="11px"
            height="11px"
            alt="close-btn"
            className="close-btn"
          />
        </button>
      </Content>
    </ToastStyled>
  );
};

export default Toast;

const ToastStyled = styled.div<{
  fadeType: fadeTypeProp;
  type: ToastType;
}>`
  background: #3db165;
  width: 100%;
  color: #ffffff;
  font-size: 16px;
  transition: all ease-in-out 0.3s;
  position: relative;
  border-radius: 8px;
  display: flex;
  align-items: center;
  flex: 1;
  min-height: 56px;
  font-weight: 400;
  padding: 16px 24px;
  font-family: Rubik;

  ${({ type }) =>
    type === "danger" &&
    css`
      background: #d00c1a;
      color: #ffffff;
    `}

  ${({ type }) =>
    type === "warning" &&
    css`
      background: #fec42f;
      color: #222222;
    `}

  ${({ type }) =>
    type === "info" &&
    css`
      background: #2a7de1;
      color: #ffffff;
    `}

    top: ${({ fadeType }) => {
    if (fadeType === "in") {
      return "calc(53px + 16px)";
    } else {
      return "-68px";
    }
  }};

  .close-btn {
    background: none;
    border: none;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const IconContainer = styled.div`
  padding-right: 16px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;
