import configBuilder from "../../../../components/ubold/ConfigBuilder";

import pageDefinitions from "../definitions";

import utils from "../../../../utils";

const definitions = {
  ...pageDefinitions,
  validators: {
    order: (value) => {
      return utils.validator.validateRequired(value);
    },
    addon_building: (value) => {
      return utils.validator.validateRequired(value);
    },
    status: (value) => {
      return utils.validator.validateRequired(value);
    },
    start_date: (value) => {
      return utils.validator.validateRequired(value);
    },
  },
};

export default configBuilder.buildAddEditPageConfig(definitions);
