import {useParams, withRouter} from "react-router-dom";

import TabPaneSecondary from "../../../../../../components/ubold/organisms/TabPaneSecondary";

import COneOrManyToManyField from "../../../../../../components/ubold/molecules/forms/COneOrManyToManyField";

function Building(props) {
    const {definitions, tab, state, setState} = props;

    let {id} = useParams();

    return (
        <TabPaneSecondary
            definitions={definitions}
            restAccessCodeNamespace='building'
            tab={tab}
            state={state}
            setState={setState}>
            <COneOrManyToManyField
                id={id}
                name={tab.id}
                restPath={'building/recommendation__building/'}
                relationKey='city_id'
                columns={[
                    {
                        name: 'building',
                        title: 'Building',
                        type: 'async_select',
                        isUniqueOption: true,
                        disabledAtEdit: true,
                        isRequired: true,
                        data: 'building/building?__type__=select_entries',
                        itemsExtractor: row => {
                            return {
                                label: row.building_name,
                                value: row.id
                            }
                        }
                    },
                    {
                        name: 'priority',
                        title: 'Priority',
                        isRequired: true,
                        type: 'number'
                    }
                ]}
            />
        </TabPaneSecondary>
    );
}

export default withRouter(Building);