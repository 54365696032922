import React from "react";

import {withRouter} from "react-router-dom";

import useGlobalState from "../../../state";

import utils from "../../../utils";

function TabPaneSecondary(props) {
    const {children, definitions, restAccessCodeNamespace, tab, state, setState} = props;

    const [user,] = useGlobalState('user');
    const [accesses,] = useGlobalState('accesses')

    return (
        <div className="tab-pane" id={tab.id}>
            {(user && user['is_super_user']) || (user && user['has_team_management_access'] && definitions.isTeamMenu) || !restAccessCodeNamespace || (accesses && accesses.includes(definitions.restAccessCode + '.' + restAccessCodeNamespace + '.list')) ?
                (utils.commons.isArray(children) ? children.map((child) => {
                    return React.cloneElement(child, {
                        onSubmit: definitions.onSubmit,
                        definitions: definitions,
                        state: state,
                        setState: setState,
                        restAccessCode: definitions.restAccessCode,
                        restAccessCodeNamespace: restAccessCodeNamespace,
                        frontendPath: definitions.frontendPath,
                        baseLink: definitions.baseLink,
                        tab: tab
                    })
                }) : children ? React.cloneElement(children, {
                    onSubmit: definitions.onSubmit,
                    definitions: definitions,
                    state: state,
                    setState: setState,
                    restAccessCode: definitions.restAccessCode,
                    restAccessCodeNamespace: restAccessCodeNamespace,
                    frontendPath: definitions.frontendPath,
                    baseLink: definitions.baseLink,
                    tab: tab
                }) : <></>) : <div style={{padding: '16px', textAlign: 'center'}}>{accesses === null ? 'Loading...' : '-no access-'}</div>}
        </div>
    );
}

export default withRouter(TabPaneSecondary);