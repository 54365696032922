import { useParams, withRouter } from "react-router-dom";
import React, { useEffect, useState } from "react";

import AccordionPane from "../../../../../../../components/ubold/organisms/AccordionPane";

import CHSelect from "../../../../../../../components/ubold/molecules/forms/CHSelect";
import CHAsyncSelect from "../../../../../../../components/ubold/molecules/forms/CHAsyncSelect";
import CHDateTimePicker from "../../../../../../../components/ubold/molecules/forms/CHDateTimePicker";
import CHTextArea from "../../../../../../../components/ubold/molecules/forms/CHTextArea";
import CHCheckBox from "../../../../../../../components/ubold/molecules/forms/CHCheckBox";

import utils from "../../../../../../../utils";
import CHTextField from "components/ubold/molecules/forms/CHTextField";
import { getMinMaxDate } from "utils/generalUtils";
import moment from "moment";
import useGlobalState from "state";
import CButton from "components/ubold/atoms/buttons/CButton";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import styled from "styled-components";

const Rent = (props) => {
  const {
    baseLink,
    accordionId,
    onSubmit,
    state,
    setState,
    restAccessCode,
  } = props;

  let { id } = useParams();
  const sweetAlert = withReactContent(Swal);

  const [user] = useGlobalState("user");
  const isSuperUser = user ? user.is_super_user : false;
  const [isShortStay, setIsShortStay] = useState(false);
  const [orderStatusState, setOrderStatus] = useState([]);
  const [earlyCheckoutIsDisabled, setEarlyCheckoutIsDisabled] =
    useState(true);
  const [backdateCheckoutIsDisabled, setBackdateCheckoutIsDisabled] =
    useState(true);
  const [constant] = useGlobalState("constant_values");
  const [fieldAccesses] = useGlobalState("field_accesses");
  const [fieldAccessReversePolicy] = useGlobalState(
    "field_access_reverse_policy"
  );
  const defaultOrderStatus = constant?.["order_new_statuses"];
  const [previousCheckoutDate, setPreviousCheckoutDate] =
    useState(null);

  const doneStatus = [5, 6, 10];
  const today = moment().format("YYYY-MM-DD");

  const dynamicDisabled = () => {
    return (
      (state["t_addon_orders"] ?? []).length ||
      (state["t_discount_orders"] ?? []).length ||
      (state["t_price_updates"] ?? []).length
    );
  };

  const generateCheckoutDateReadOnly = () => {
    if (!id) {
      return (
        state?.subscription_type === 1 ||
        state?.t_discount_orders?.length
      );
    }
    if (!earlyCheckoutIsDisabled || !backdateCheckoutIsDisabled) {
      return false;
    }
    return (
      doneStatus.includes(state?.original___order_status) ||
      moment(today).isAfter(
        moment(state?.original___checkout_date)
      ) ||
      state?.original___checkout_date
    );
  };

  const isSplitDepositAndRentReadOnly = () => {
    if (!id && state?.order_type === 2) return true;
    if (
      id ||
      state?.extend_and_hold_invoice ||
      state?.exclude_deposit
    )
      return true;

    if (state?.checkin_date)
      return moment(state?.checkin_date).isSame(moment(), "day");

    return true;
  };

  const generateHelperText = () => {
    if (!dynamicDisabled()) return "";
    return `Remove existing data inside ${
      state["t_addon_orders"].length > 0 ? "(Addon Orders)" : ""
    } ${
      state["t_price_updates"].length > 0 ? "(Price Update)" : ""
    } ${
      state["t_discount_orders"].length > 0
        ? "(Additional Discounts)"
        : ""
    } before changing this field!`;
  };

  const generateReadOnlyStatusBasedOnOrderStatus = () => {
    if (id && doneStatus.includes(state["original___order_status"])) {
      return true;
    }
    return false;
  };

  const generateHelperTextCheckoutDate = () => {
    return `
      ${
        (state["t_addon_orders"]?.length > 0 &&
          state["t_addon_orders"].some((obj) =>
            moment(obj.end_date).isAfter(state["checkout_date"])
          )) ||
        (state["t_discount_orders"]?.length > 0 &&
          state["t_discount_orders"].some((obj) =>
            moment(obj.end_date).isAfter(state["checkout_date"])
          ))
          ? "(Addon/ Additional Discount) has end date greater than Checkout Date, thus it will be override using Checkout Date selected Value"
          : ""
      }
    `;
  };

  const handleOnClickEarlyCheckoutButton = () => {
    setEarlyCheckoutIsDisabled(false);
    setBackdateCheckoutIsDisabled(true);
    if (previousCheckoutDate) {
      setState((prevState) => ({
        ...prevState,
        checkout_date: previousCheckoutDate,
      }));
    }
  };

  const handleOnClickBackdateCheckoutButton = () => {
    if (!backdateCheckoutIsDisabled) {
      setBackdateCheckoutIsDisabled(!backdateCheckoutIsDisabled);
      setState((prevState) => ({
        ...prevState,
        checkout_date: previousCheckoutDate,
      }));
      return;
    }
    sweetAlert.close();
    sweetAlert
      .fire({
        title: "Procceed with Backdate Checkout?",
        html: (
          <BackdateCheckoutModalWrapper>
            <span>You will activate Backdate Checkout.</span>
            <span>
              {" "}
              {state?.original___checkout_date
                ? `It seems you have checkout date saved, this will clear the current checkout date. And it will change the checkout date range you can select.`
                : "It will change the checkout date range you can select."}
            </span>
            <span>Do you wish to proceed?</span>
          </BackdateCheckoutModalWrapper>
        ),
        footer:
          "Notes: You can revert the checkout date into its original by clicking the Backdate Checkout Button again",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirm",
      })
      .then((result) => {
        if (result.isConfirmed) {
          setBackdateCheckoutIsDisabled(!backdateCheckoutIsDisabled);
          setEarlyCheckoutIsDisabled(true);
          if (!state?.original___checkout_date) return;
          setPreviousCheckoutDate(state?.original___checkout_date);
          setState((prevState) => ({
            ...prevState,
            checkout_date: null,
          }));
        }
      });
  };

  const isCheckoutButtonDisabled = () => {
    return (
      doneStatus.includes(state?.original___order_status) ||
      (restAccessCode &&
        utils.access.isFieldReadOnly(
          fieldAccesses,
          `${restAccessCode}.early_checkout`,
          fieldAccessReversePolicy,
          isSuperUser
        )) ||
      !state?.original___checkout_date
    );
  };

  const isBackdateButtonDisabled = () => {
    if (
      state?.original___backdate_checkout ||
      moment(today).isSame(state?.original___checkin_date)
    )
      return true;
    return (
      doneStatus.includes(state?.original___order_status) ||
      (restAccessCode &&
        utils.access.isFieldReadOnly(
          fieldAccesses,
          `${restAccessCode}.apply_back_date_checkout`,
          fieldAccessReversePolicy,
          isSuperUser
        ))
    );
  };

  const generateCheckOutMinDate = () => {
    if (
      !id &&
      state?.subscription_type === 2 &&
      state?.monthly_commitment > 1
    ) {
      // monthly commitment case in order creation
      return moment(state?.checkin_date)
        .add(30 * state.monthly_commitment - 1, "day")
        .format("YYYY-MM-DD");
    }

    // case upfront (monthly commit = 0)
    if (
      !id &&
      state?.subscription_type === 2 &&
      state?.monthly_commitment <= 1
    ) {
      return state?.checkin_date;
    }

    if (!backdateCheckoutIsDisabled) {
      const momentCheckinDate = moment(
        state?.original___checkin_date
      );
      const differenceBetweenCheckinDateAndToday = moment(today).diff(
        momentCheckinDate,
        "days"
      );
      const dateEightDaysBeforeToday = moment()
        .subtract(
          differenceBetweenCheckinDateAndToday < 8
            ? differenceBetweenCheckinDateAndToday
            : 8,
          "day"
        )
        .format("YYYY-MM-DD");
      return moment(state?.original___checkin_date).isAfter(
        dateEightDaysBeforeToday
      )
        ? state?.original___checkin_date
        : dateEightDaysBeforeToday;
    }
    if (
      moment(today).isAfter(moment(state["original___checkout_date"]))
    ) {
      return state?.original___checkout_date;
    }
    return state?.checkin_date ?? today;
  };

  const generateCheckoutMaxDate = () => {
    if (!backdateCheckoutIsDisabled)
      return moment(today).subtract(1, "day").format("Y-M-D");
    return state?.original___checkout_date;
  };

  const getMax120Days = () => {
    const maxDate = moment().add(119, "days").format("YYYY-MM-DD");
    return maxDate;
  };

  const getMax180Days = () => {
    const maxDate = moment().add(179, "days").format("YYYY-MM-DD");
    return maxDate;
  };

  useEffect(() => {
    // set initial state
    setState((prev) => ({
      ...prev,
      monthly_commitment: 0,
    }));
  }, []);

  useEffect(() => {
    if (state.subscription_type === 1 && !id) {
      setState((prevState) => ({
        ...prevState,
        checkout_date: null,
      }));
    }
  }, [state.subscription_type]);

  useEffect(() => {
    if (!defaultOrderStatus || !state["order_status"]) return;

    const orderStatus = [...defaultOrderStatus];

    let possibleTransition = [5];

    if (id) {
      switch (state?.order_status) {
        case 3:
          possibleTransition = [];
          break;
        case 6:
          possibleTransition = [];
          break;
        case 10:
          possibleTransition = [];
          break;
        default:
          possibleTransition.push(state?.original___order_status);
      }
    } else {
      possibleTransition.push(9);
    }

    const newOrderStatus = orderStatus?.map((obj) => {
      const tempObj = {
        label: obj.label,
        value: obj.value,
        disabled: false,
      };
      if (!possibleTransition?.includes(obj.value)) {
        tempObj.disabled = true;
      }
      return tempObj;
    });

    setOrderStatus(newOrderStatus);
  }, [defaultOrderStatus, state?.order_status, id]);

  useEffect(() => {
    if (!id) {
      // make it to only run this effect on order creation
      const dateCheckIn = moment(state?.checkin_date, "YYYY-MM-DD");
      const dateCheckOut = moment(state?.checkout_date, "YYYY-MM-DD");
      if (
        dateCheckIn.format("YYYY-MM-DD") !== "Invalid date" &&
        dateCheckOut.format("YYYY-MM-DD") !== "Invalid date" &&
        state?.subscription_type === 2 &&
        state?.order_type !== 2
      ) {
        const diffDays = dateCheckOut.diff(dateCheckIn, "days");
        const shortStayApplied =
          diffDays >= 0 &&
          diffDays <= 21 &&
          state.monthly_commitment === 0;

        setIsShortStay(shortStayApplied);
        setState((prev) => ({
          ...prev,
          short_stay: shortStayApplied,
        }));
      } else {
        setIsShortStay(false);
        setState((prev) => ({
          ...prev,
          short_stay: false,
        }));
      }
    }
  }, [
    id,
    state?.checkin_date,
    state?.checkout_date,
    state?.subscription_type,
    state?.monthly_commitment,
  ]);

  useEffect(() => {
    if (!state?.checkout_date) return;
    const today = moment().format("YYYY-MM-DD");
    const isCheckoutDateIsBackdate = moment(today).isAfter(
      state?.checkout_date
    );
    setState((prevState) => ({
      ...prevState,
      backdate_checkout: isCheckoutDateIsBackdate,
    }));
  }, [state?.checkout_date]);

  useEffect(() => {
    if (id) return;
    if (
      state?.checkin_date &&
      moment(state?.checkin_date).isSameOrAfter(
        moment().add(7, "days")
      )
    ) {
      return;
    }
    setState((prevState) => ({
      ...prevState,
      split_deposit_and_rent: false,
    }));
  }, [state?.checkin_date]);

  return (
    <AccordionPane
      id="rent"
      name="Rent"
      accordionId={accordionId}
      baseLink={baseLink}
      onSubmit={onSubmit}
      shown={
        !!(
          utils.form.isFieldError(state, "order_status") ||
          utils.form.isFieldError(state, "date_started") ||
          utils.form.isFieldError(state, "date_ended") ||
          utils.form.isFieldError(state, "status_of_done")
        )
      }
      state={state}
      setState={setState}
    >
      <CHSelect
        name="subscription_type"
        title="Subscription Type"
        isRequired
        data={{
          optionField: "order_new_subscription_type",
        }}
        readOnly={id || dynamicDisabled()}
        helperText={generateHelperText()}
      />
      {state["subscription_type"] === 2 ? (
        <CHTextField
          name="monthly_commitment"
          title="Monthly Commitment"
          type="number"
          readOnly={
            state["t_discount_orders"]?.length ||
            state?.tenant_category === 2 ||
            id
          }
          min={0}
          helperText={
            state["t_discount_orders"]?.length ||
            state["t_addon_buildings"]?.length
              ? "Please delete all of the additional discounts and add on buildings to enable Monthly Commitment"
              : !id && state?.tenant_category === 2
              ? "Monthly commitment is disabled due to the legacy tenant have been selected"
              : null
          }
        />
      ) : (
        <React.Fragment />
      )}
      <CHDateTimePicker
        name="checkin_date"
        title="Check-In Date"
        readOnly={
          (!id && state?.order_type === 2) || id || dynamicDisabled()
        }
        type="date"
        helperText={generateHelperText()}
        isRequired
        minDate={!id && getMinMaxDate("min", state["live_date"])}
        maxDate={
          state?.order_type !== 2 && // not extend order
          (state?.original___checkin_date
            ? state?.original___checkin_date
            : state?.allow_for_more_than_30_days
            ? // on 02-04-2024 there is product requirement update that change the getMax120Days to getMax180Days as a maxDate for checkin date
              getMax180Days()
            : getMinMaxDate("max", state["live_date"]))
        }
      />
      {state?.deposit_rules !== 1 ? (
        <CHCheckBox
          name="split_deposit_and_rent"
          title="Split Deposit and Rent"
          readOnly={isSplitDepositAndRentReadOnly()}
        />
      ) : (
        <></>
      )}
      <CHDateTimePicker
        name="checkout_date"
        title="Check-Out Date"
        readOnly={generateCheckoutDateReadOnly()}
        helperText={
          state["t_discount_orders"]?.length &&
          !id &&
          "Please delete all of the additional discounts to enable checkout date"
        }
        helperTextAdditional={id && generateHelperTextCheckoutDate()}
        type="date"
        minDate={generateCheckOutMinDate()}
        maxDate={generateCheckoutMaxDate()}
        isRequired={state?.subscription_type === 2}
        isClearable={false}
        modifiedCol={
          id && state?.original___order_status === 3 ? 5 : 7
        }
      >
        {id ? (
          <div className="row">
            <CButton
              title="Early Checkout"
              name="early_checkout"
              onClick={handleOnClickEarlyCheckoutButton}
              isDisabled={isCheckoutButtonDisabled()}
              backgroundColor={
                isCheckoutButtonDisabled() && "hsl(0, 0%, 95%)"
              }
              color={
                isCheckoutButtonDisabled() && "rgb(153, 153, 153)"
              }
              fontWeight="300"
              fontSize="13px"
              border={
                isCheckoutButtonDisabled() &&
                "1px solid hsl(0, 0%, 90%)"
              }
              margin={
                state?.original___order_status === 3
                  ? "0px 20px 0px 10px"
                  : "0px"
              }
            />
            {state?.original___order_status === 3 ? (
              <CButton
                title="Backdate Checkout"
                name="backdate_checkout"
                onClick={handleOnClickBackdateCheckoutButton}
                isDisabled={isBackdateButtonDisabled()}
                backgroundColor={
                  isBackdateButtonDisabled() && "hsl(0, 0%, 95%)"
                }
                color={
                  isBackdateButtonDisabled() && "rgb(153, 153, 153)"
                }
                fontWeight="300"
                fontSize="13px"
                border={
                  isBackdateButtonDisabled() &&
                  "1px solid hsl(0, 0%, 90%)"
                }
              />
            ) : null}
          </div>
        ) : null}
      </CHDateTimePicker>
      {id || (isShortStay && state?.monthly_commitment === 0) ? (
        <CHCheckBox
          name="short_stay"
          title="Short Stay"
          readOnly={(!id && state?.order_type === 2) || id}
          restAccessCode={restAccessCode}
        />
      ) : (
        <React.Fragment />
      )}
      <CHSelect
        name="status_of_done"
        title="Status of Done"
        readOnly={
          id && doneStatus.includes(state["original___order_status"])
        }
        isRequired
        data={{
          optionField: "order_new_status_of_done",
        }}
      />
      <CHTextArea
        name="reason_of_check_out"
        title="Reason of Checkout"
        readOnly={generateReadOnlyStatusBasedOnOrderStatus()}
        isRequired={state["checkout_date"]}
      />
      <CHAsyncSelect
        name="checkout_category"
        read_only_label="checkout_category_name"
        title="Checkout Category"
        readOnly={generateReadOnlyStatusBasedOnOrderStatus()}
        data="order/checkout_category/?__type__=select_entries&limit=10"
        isRequired={state["checkout_date"]}
      />
      <CHAsyncSelect
        name="checkout_reason"
        read_only_label="checkout_reason_name"
        title="Checkout Reason"
        readOnly={generateReadOnlyStatusBasedOnOrderStatus()}
        dependsOn="checkout_category"
        data="order/checkout_reason/?__type__=select_entries&limit=10"
        isRequired={state["checkout_date"]}
      />
      <CHCheckBox
        name="on_leave_paying"
        title="On Leave Paying?"
        disabled={
          id && doneStatus.includes(state["original___order_status"])
        }
      />
      <CHSelect
        name="order_status"
        title="Status"
        isRequired
        data={orderStatusState}
        readOnly={
          !id ||
          (id &&
            doneStatus.includes(state["original___order_status"]))
        }
      />
      <CHTextArea
        name="cancellation_remarks"
        title="Cancellation Remarks"
        readOnly={state?.order_status !== 5}
      />
      <CHDateTimePicker
        name="estimated_checkout_date"
        title="Estimated Check-Out Date"
        type="date"
        readOnly={
          id && doneStatus.includes(state["original___order_status"])
        }
      />
      <CHDateTimePicker
        name="checkin_time"
        title="Check-In Time"
        type="time"
        readOnly={id}
      />
      <CHDateTimePicker
        name="checkout_time"
        title="Check-Out Time"
        type="time"
        readOnly={
          id && doneStatus.includes(state["original___order_status"])
        }
      />
      <CHCheckBox
        name="confirm_checkout"
        title="Confirm Check-Out"
        disabled={state["original___confirm_check_out"]}
        readOnly={
          id &&
          (state["original___confirm_checkout"] ||
            doneStatus.includes(state["original___order_status"]))
        }
      />
      <CHCheckBox
        name="no_show"
        title="No Show"
        readOnly={
          id && doneStatus.includes(state["original___order_status"])
        }
      />
    </AccordionPane>
  );
};

const BackdateCheckoutModalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  span:nth-child(even) {
    margin: 10px 0;
  }
`;

export default withRouter(Rent);
