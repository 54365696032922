import React from "react";
import utils from "../../../../utils";
import configs from "../../../../configs";

import Swal, { SweetAlertIcon, SweetAlertInput } from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

type ParamObjectType = { [key: string]: string | number };
interface CButtonWithAlertProps {
  baseLink: string;
  confirmBtnText?: string;
  dialogText?: string;
  dialogTitle?: string;
  failedMessage: string;
  key?: string;
  paramObject?: ParamObjectType;
  successMessage: string;
  title: string;
  withDialog?: boolean;
  inputAttributes?: string;
  inputLabel?: string;
  inputType?: SweetAlertInput;
  inputPlaceholder?: string;
  icon?: SweetAlertIcon;
  inputFieldName?: string;
  readOnly?: boolean;
}

const CButtonWithAlert: React.FC<CButtonWithAlertProps> = ({
  baseLink,
  confirmBtnText,
  dialogText,
  dialogTitle,
  failedMessage,
  key,
  paramObject,
  successMessage,
  title,
  withDialog,
  inputAttributes,
  inputLabel,
  inputType,
  inputPlaceholder,
  icon,
  inputFieldName,
  readOnly,
}) => {
  const sweetAlert = withReactContent(Swal);
  const sweeetAlertSuccess = () => {
    sweetAlert.close();
    sweetAlert.fire("Success", successMessage, "success").then(() => {
      window.location.reload();
    });
  };
  const sweetAlertFailed = (errorMessage: string) => {
    sweetAlert.close();
    sweetAlert.fire("Failed", errorMessage, "error");
  };
  const hitAPIWithoutDialog = () => {
    utils.httpClient.post(
      configs.apiUrl + baseLink,
      paramObject,
      sweeetAlertSuccess,
      (e: any, message: any) => {
        let errMsg =
          failedMessage ??
          "Request is unsuccessful. Please Try Again";

        if (utils.commons.isJSON(message)) {
          errMsg = JSON.parse(message)?.msg;
          sweetAlertFailed(errMsg);
        } else {
          sweetAlertFailed(errMsg);
        }
      }
    );
  };
  const hitAPIWithDialog = () => {
    sweetAlert
      .fire({
        title: dialogTitle ?? "Dialog Title",
        text: dialogText ?? "Dialog Text",
        input: inputType ?? "text",
        inputLabel: inputLabel ?? "Message",
        inputPlaceholder:
          inputPlaceholder ?? "Type your message here...",
        inputAttributes: {
          "aria-label": inputAttributes ?? "Type your message here",
        },
        icon: icon ?? "warning",
        showCancelButton: true,
        confirmButtonText: confirmBtnText ?? "Text Confirm Button",
        didOpen: () => {
          Swal.getConfirmButton().disabled = true;
          const getInputValue =
            document.getElementById("swal2-input");

          getInputValue.addEventListener(
            "input",
            function textAreaFunc(event) {
              const element = event.currentTarget as HTMLInputElement;
              if (!element.value) {
                Swal.getConfirmButton().disabled = true;
              } else {
                Swal.getConfirmButton().disabled = false;
              }
            }
          );
        },
      })
      .then(
        (result: {
          isConfirmed: boolean;
          value?: string | number;
        }) => {
          if (result.isConfirmed) {
            paramObject[inputFieldName] = result.value;
            hitAPIWithoutDialog();
          }
        }
      );
  };
  return (
    <a
      key={key}
      className="btn btn-outline-success mr-1"
      onClick={() => {
        if (readOnly) return;
        !withDialog ? hitAPIWithoutDialog() : hitAPIWithDialog();
      }}
    >
      {title ?? "Button"}
    </a>
  );
};

export default CButtonWithAlert;
