import React, { ForwardRefExoticComponent, useState } from "react";

import styled from "styled-components";
import Tippy, { TippyProps } from "@tippyjs/react";

import "tippy.js/dist/tippy.css";

const Tooltip: React.FC<{
  children: React.ReactElement;
  contentMain: string;
  contentHover?: string;
  customCSS?: {
    fontSize?: number;
    fontWeight?: number;
    textAlign?: string;
    width?: string;
  };
  hideOnClick?: boolean;
  trigger?: "click" | "hover";
  zIndex?: number;
}> = ({
  children,
  contentMain,
  contentHover,
  customCSS,
  hideOnClick = false,
  trigger = "click",
  zIndex = 1,
}) => {
  const [tooltipMainOpened, setTooltipMainOpened] =
    useState<boolean>();
  const [tooltipHoverOpened, setTooltipHoverOpened] =
    useState<boolean>(false);

  const TooltipClick: React.ReactElement = (
    <TippyStyled
      content={contentMain}
      customCSS={customCSS}
      hideOnClick={hideOnClick}
      onClickOutside={() => setTooltipMainOpened(false)}
      trigger="click"
      visible={tooltipMainOpened}
      zIndex={zIndex}
    >
      <TippyHover
        trigger="mouseenter"
        content={contentHover}
        onClickOutside={() => setTooltipHoverOpened(false)}
        visible={tooltipHoverOpened}
        zIndex={zIndex}
      >
        <ButtonContainer
          onMouseEnter={() => {
            if (!tooltipMainOpened && contentHover) {
              setTooltipHoverOpened(true);
            }
          }}
          onMouseLeave={() => setTooltipHoverOpened(false)}
          onClick={() => setTooltipMainOpened(true)}
          type="button"
        >
          {children}
        </ButtonContainer>
      </TippyHover>
    </TippyStyled>
  );

  const TooltipHover: React.ReactElement = (
    <TippyStyled
      hideOnClick={hideOnClick}
      trigger="mouseenter"
      content={contentMain}
      customCSS={customCSS}
      zIndex={zIndex}
    >
      {children}
    </TippyStyled>
  );

  return (
    <React.Fragment>
      {trigger === "hover" ? TooltipHover : TooltipClick}
    </React.Fragment>
  );
};

export default Tooltip;

const ButtonContainer = styled.button`
  background-color: unset;
  border: unset;
  color: inherit;
  display: block;
  font-weight: 300;
  padding: unset;

  &:active,
  &:focus {
    outline: unset;
  }
`;

const TippyStyled = styled<
  ForwardRefExoticComponent<
    TippyProps & {
      customCSS?: {
        fontSize?: number;
        fontWeight?: number;
        textAlign?: string;
        width?: string;
      };
    }
  >
>(Tippy)`
  width: ${({ customCSS }) =>
    customCSS?.width ? customCSS?.width : "calc(170px - 21px)"};

  font-size: ${({ customCSS }) =>
    customCSS?.fontSize ? customCSS?.fontSize + "px" : "14px"};
  font-weight: ${({ customCSS }) =>
    customCSS?.fontWeight ? customCSS?.fontWeight : 300};
  text-align: ${({ customCSS }) =>
    customCSS?.textAlign ? customCSS?.textAlign : "center"};
`;

const TippyHover = styled(Tippy)`
  width: 125px;

  font-size: 12px;
  font-weight: 500;
  text-align: center;
`;
