import utils from "../../../utils";

import configBuilder from "../../../components/ubold/ConfigBuilder";

let definitions = {
  title: "Menus",
  baseLink: "/crm/menu",
  restAccessCode: "administrator.menu",
  __table__: {
    filterColumn: 3,
    filters: [
      {
        id: "name",
        name: "Name",
        type: "text",
      },
      {
        id: "path",
        name: "Path",
        type: "text",
      },
      {
        id: "menu_position",
        name: "Menu Position",
        type: "async_select",
        data: "crm/menu_position?__type__=select_entries",
      },
      {
        id: "parent",
        name: "Parent Menu",
        type: "async_select",
        data: "crm/menu?__type__=select_entries&__menu_list__=with_parent",
      },
      {
        id: "type",
        name: "Menu Type",
        type: "select",
        data: {
          optionField: "crm_menu_type",
        },
      },
    ],
    columns: [
      {
        id: "name",
        name: "Name",
      },
      {
        id: "menu_position_name",
        name: "Menu Position",
        center: true,
      },
      {
        id: "type",
        name: "Menu Type",
        center: true,
        referStateFieldName: "crm_menu_type",
        renderer: (value, _, constants) => {
          let label = "-";
          (constants ?? []).forEach((obj) => {
            if (obj.value === parseInt(value)) {
              label = obj.label;
            }
          });
          return label;
        },
      },
      {
        id: "parent_name",
        name: "Parent Menu",
        center: true,
      },
      {
        id: "order_position",
        name: "Order Position",
        center: true,
      },
      {
        id: "link",
        name: "Path",
      },
      {
        id: "icon",
        name: "Icon",
        center: true,
      },
      {
        id: "is_exact_link",
        name: "Is Exact Link?",
        isSafeContent: true,
        center: true,
        renderer: (value) => {
          if (value === "true") {
            return '<span style="color: rgb(26, 188, 156);"> ✔ </span>';
          } else {
            return '<span style="color: red;">✘</span>';
          }
        },
      },
      {
        id: "is_administrator_menu",
        name: "Is Administrator Menu?",
        isSafeContent: true,
        center: true,
        renderer: (value) => {
          if (value === "true") {
            return '<span style="color: rgb(26, 188, 156);"> ✔ </span>';
          } else {
            return '<span style="color: red;">✘</span>';
          }
        },
      },
      {
        id: "short_description",
        name: "Short Description",
        center: true,
      },
      {
        id: "is_active",
        name: "Is Active?",
        isSafeContent: true,
        center: true,
        renderer: (value) => {
          if (value === "true") {
            return '<span style="color: rgb(26, 188, 156);"> ✔ </span>';
          } else {
            return '<span style="color: red;">✘</span>';
          }
        },
      },
      {
        id: "is_audited",
        name: "Is Audited?",
        isSafeContent: true,
        center: true,
        renderer: (value) => {
          if (value === "true") {
            return '<span style="color: rgb(26, 188, 156);"> ✔ </span>';
          } else {
            return '<span style="color: red;">✘</span>';
          }
        },
      },
      {
        id: "created_at",
        name: "Created At",
        renderer: (value) => {
          return utils.formatter.dateTime(value);
        },
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
