import utils from "../../../../utils";

import configBuilder from "../../../../components/ubold/ConfigBuilder";

import pageDefinitions from "../definitions";

const definitions = {
  ...pageDefinitions,
  linkHistory: (id: string) =>
    `${definitions.frontendPath}/history/${id}`,
  tabs: [
    {
      id: "room",
      name: "Room",
    },
    {
      id: "t_photos",
      name: "Photos",
    },
    {
      id: "t_facilities",
      name: "Facilities",
    },
    {
      id: "t_features",
      name: "Features",
    },
    {
      id: "t_room_service_schedule",
      name: "Service Schedule",
    },
  ],
  validators: {
    name: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    room_variant: (value: string) => {
      return utils.validator.validateRequired(value);
    },
  },
};

export default configBuilder.buildAddEditPageConfig(definitions);
