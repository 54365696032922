import configBuilder from "../../../../components/ubold/ConfigBuilder";

import pageDefinitions from "../definitions";

const definitions = {
  ...pageDefinitions,
  linkHistory: (id: string) =>
    `${definitions.frontendPath}/history/${id}`,
  tabs: [
    {
      id: "order",
      name: "Order",
    },
    {
      id: "t_discount_orders",
      name: "Additional Discounts",
      alwaysEditable: true,
    },
    {
      id: "t_addon_buildings",
      name: "Addon Buildings",
      alwaysEditable: true,
    },
    /** Hide this as requested on RTD-4553 */
    // {
    //   id: "t_service_schedules",
    //   name: "Service Schedules",
    //   alwaysEditable: true,
    // },
    {
      id: "t_order_update_price",
      name: "Price Update",
      alwaysEditable: true,
    },
  ],
};

export default configBuilder.buildAddEditPageConfig(
  definitions,
  [],
  ["created_by", "date_created", "date_updated"]
);
