import { isMobile } from "react-device-detect";
import moment from "moment";

import utils from "../../../utils";

function SecondaryDataHandler(data) {
  const dateCreated = moment(data.date_created).format(
    "DD MMM YYYY HH:mm:ss"
  );
  const dateUpdated = moment(data.date_updated).format(
    "DD MMM YYYY HH:mm:ss"
  );

  return (
    <div
      className={
        isMobile
          ? "secondary-data-panel-mobile"
          : "secondary-data-panel"
      }
    >
      <div className="row mb-2">
        <div className="col mw-200">
          {data.additional_number && (
            <div>
              <b>Additional Number</b>: <br />
              {data.additional_number.toUpperCase()}
            </div>
          )}
        </div>

        <div className="col mw-200">
          <div>
            <b>Service Fee</b>: <br />
            {utils.formatter.currency(data.service_fee)}
          </div>
        </div>

        <div className="col mw-200">
          <div>
            <b>Delivery Fee</b>: <br />
            {utils.formatter.currency(data.delivery_fee)}
          </div>
        </div>

        <div className="col mw-200">
          {data.date_created && (
            <div>
              <b>Date Created</b>: <br />
              {dateCreated}
            </div>
          )}
        </div>

        <div className="col mw-200">
          {data.date_updated && (
            <div>
              <b>Date Updated</b>: <br />
              {dateUpdated}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SecondaryDataHandler;
