import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import moment from "moment";

import {
  ButtonLoadMore,
  LoadingContainer,
  TableHistoryBase,
} from "./TableHistory.css";

import {
  ICRMAuditTrailResponse,
  IDataAuditTrailObject,
} from "../../../../@types/";

import configs from "../../../../configs";
import utils from "../../../../utils";
import EmptyState from "../../molecules/EmptyState/EmptyState";

enum HistoryActionType {
  GET = "GET",
  DELETE = "DELETE",
  PATCH = "PATCH",
  POST = "POST",
}

interface ITableHistoryProps {
  endpointHistory?: string;
  restAccessCode?: string;
  table: {
    columns: Array<{ id: string; name: string }>;
    pageLimit?: number;
    pageStart?: number;
    path?: string;
  };
}

const isBothArray = (inputOne: unknown, inputTwo: unknown) => {
  if (
    utils.commons.isArray(inputOne) &&
    utils.commons.isArray(inputTwo)
  ) {
    return true;
  } else {
    return false;
  }
};

const TableHistory: React.FC<ITableHistoryProps> = (props) => {
  const [data, setData] = useState<ICRMAuditTrailResponse["data"]>(
    []
  );
  const {
    endpointHistory,
    restAccessCode,
    table: { columns, pageLimit = 20, pageStart = 1 },
  } = props;
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(pageStart);
  const [lastPage, setLastPage] = useState<boolean>(false);
  const [limitPage] = useState<number>(pageLimit);

  const { id }: { id: string } = useParams();

  const fetch = () => {
    setIsFetching(true);

    const initEndpointPath = (): string => {
      if (endpointHistory) return endpointHistory;

      if (restAccessCode) {
        return `crm/audit_trail_by_menu/?rac=${restAccessCode}&object=${id}&page=${currentPage}&limit=${limitPage}`;
      }

      return `crm/audit_trail/?page=${currentPage}&limit=${limitPage}`;
    };

    const endpoint = initEndpointPath();

    utils.httpClient.get(
      configs.apiUrl + endpoint,
      (response: ICRMAuditTrailResponse) => {
        // The API doesnt return information on how many total items it retrieved, so we will always try fetch next page until the response gives 0 item
        // So it is gonna be expected the user will press load more one time at the end and it will gives nothing
        if (response.data?.length > 0) {
          setData((prevState) => prevState.concat(response.data));
          if (response.data.length < limitPage) {
            setLastPage(true);
          }
        } else {
          setLastPage(true);
        }

        setIsFetching(false);
      },
      (error: any, message: string) => {}
    );
  };

  useEffect(() => {
    fetch();
  }, [currentPage, limitPage]);

  const renderString = (
    inputToCheck: string | number | boolean | unknown[]
  ) => {
    if (typeof inputToCheck === "boolean") {
      // Avoid print blank string if data type are boolean
      return <strong>{inputToCheck.toString()}</strong>;
    }

    if (inputToCheck === null || inputToCheck === "")
      return <strong>{'""'}</strong>;

    if (
      typeof inputToCheck === "string" &&
      utils.commons.isHTML(inputToCheck)
    ) {
      // If the value are HTML string. For example: data that generated from Rich Text Format Editor
      return (
        <React.Fragment>
          <strong>&quot;</strong>
          <strong
            className="d-inline-block"
            dangerouslySetInnerHTML={{
              __html: inputToCheck,
            }}
          />
          <strong>&quot;</strong>
        </React.Fragment>
      );
    }

    if (utils.commons.isArray(inputToCheck)) {
      const arrayInput = inputToCheck as Array<unknown>;
      return <strong>{arrayInput.join(", ")}</strong>;
    }

    return <strong>{inputToCheck}</strong>;
  };

  const renderDataDiff = (
    source: IDataAuditTrailObject["_source"]
  ): React.ReactNode => {
    let dataDiff: string[] = [];
    if (source.data_diff?.added?.length) {
      dataDiff = [...dataDiff, ...source.data_diff.added];
    }
    if (source.data_diff?.changed?.length) {
      dataDiff = [...dataDiff, ...source.data_diff.changed];
    }
    if (source.data_diff?.deleted?.length) {
      dataDiff = [...dataDiff, ...source.data_diff.deleted];
    }
    if (!dataDiff?.length) return;
    return dataDiff?.map((item: string) => {
      if (typeof JSON.parse(item) !== "object") return;
      const obj: {
        field: string;
        old_value: string | number | boolean | unknown[] | null;
        new_value: string | number | boolean | unknown[] | null;
      } = JSON.parse(item);
      const field = columns?.find(
        (column) => column.id === obj?.field
      )
        ? columns?.find((column) => column.id === obj?.field)?.name
        : obj?.field;

      switch (source.action) {
        case HistoryActionType.GET:
          if (field === "id") return null;

          return (
            <li key={obj.field}>
              Display in <strong>{source?.model_name}</strong> field{" "}
              <strong>{field}</strong> {renderString(obj?.new_value)}
            </li>
          );
        case HistoryActionType.POST:
          if (field === "id") return null;
          let oldValue = obj?.old_value;
          let newValue = obj?.new_value;
          if (
            source.rest_access_code ===
              "finance_new.invoice_outstanding" &&
            field === "source"
          ) {
            oldValue =
              obj?.old_value === 1 ? "System" : obj?.old_value;
            newValue =
              obj?.new_value === 2 ? "Manual" : obj?.new_value;
          }

          if (source.url.includes("waive")) {
            return (
              <li key={obj.field}>
                Execute {renderString(newValue)}
              </li>
            );
          }

          return (
            <li key={obj.field}>
              Create in <strong>{source?.model_name}</strong> field{" "}
              <strong>{field}</strong> from {renderString(oldValue)}{" "}
              to {renderString(newValue)}
            </li>
          );

        case HistoryActionType.PATCH:
          if (field === "id") return null;

          if (!obj.new_value) {
            return (
              <li key={obj.field}>
                Remove {renderString(obj?.old_value)} from{" "}
                <strong>{field}</strong>
              </li>
            );
          }

          if (!obj.old_value && obj.new_value) {
            return (
              <li key={obj.field}>
                Add {renderString(obj?.new_value)} to field{" "}
                <strong>{field}</strong> in{" "}
                <strong>{source?.model_name}</strong>{" "}
              </li>
            );
          }

          if (isBothArray(obj.old_value, obj.new_value)) {
            const arrayOldValue = obj.old_value as unknown[];
            const arrayNewValue = obj.new_value as unknown[];
            const removedOldValue = arrayOldValue.filter(
              (value) => !arrayNewValue.includes(value)
            );
            const addedNewValue = arrayNewValue.filter(
              (value) => !arrayOldValue.includes(value)
            );
            const renderRemovedOldValue = removedOldValue?.length ? (
              <li key={obj.field}>
                Remove {renderString(removedOldValue)} from field{" "}
                <strong>{field}</strong>
              </li>
            ) : null;
            const renderAddedNewValue = addedNewValue?.length ? (
              <li key={obj.field}>
                Add {renderString(addedNewValue)} into field{" "}
                <strong>{field}</strong>
              </li>
            ) : null;
            return (
              <>
                {renderRemovedOldValue}
                {renderAddedNewValue}
              </>
            );
          }

          return (
            <li key={obj.field}>
              Change in <strong>{source?.model_name}</strong> field{" "}
              <strong>{field}</strong> from{" "}
              {renderString(obj?.old_value)} to{" "}
              {renderString(obj?.new_value)}
            </li>
          );

        case HistoryActionType.DELETE:
          if (field === "id") return null;

          return (
            <li key={obj.field}>
              Delete <strong>{source.model_name}</strong> field{" "}
              <strong>{field}</strong> with value{" "}
              {renderString(obj?.new_value)} & PK:{" "}
            </li>
          );

        default:
          return null;
      }
    });
  };

  return (
    <React.Fragment>
      <TableHistoryBase>
        <thead>
          <tr>
            <th>Day & Date</th>
            <th>Time</th>
            <th>User</th>
            <th>Activity</th>
          </tr>
        </thead>

        <tbody>
          {data?.length
            ? data?.map((column) => {
                return (
                  <tr key={column._id}>
                    <td>
                      {moment(column._source?.date_created).format(
                        "ddd, DD MMM YYYY"
                      )}
                    </td>
                    <td>
                      {moment(column._source?.date_created).format(
                        "HH:mm:ss"
                      )}
                    </td>
                    <td>{column._source?.username}</td>
                    <td>
                      <ul>{renderDataDiff(column._source)}</ul>
                    </td>
                  </tr>
                );
              })
            : null}
        </tbody>
      </TableHistoryBase>

      {isFetching ? (
        <LoadingContainer>
          <div className="spinner-border m-2" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </LoadingContainer>
      ) : null}

      {!lastPage && !isFetching ? (
        <ButtonLoadMore
          onClick={() => setCurrentPage((prevState) => prevState + 1)}
          type="button"
        >
          Load More History
        </ButtonLoadMore>
      ) : null}

      {lastPage && data.length ? (
        <ButtonLoadMore disabled>
          All history has been displayed
        </ButtonLoadMore>
      ) : null}

      {!data.length ? (
        <EmptyState
          textDescription="There is no activity history here"
          textTitle="Empty History"
        />
      ) : null}
    </React.Fragment>
  );
};

export default TableHistory;
