import utils from "../../../../utils";

import configBuilder from '../../../../components/ubold/ConfigBuilder';

import pageDefinitions from "../definitions";

const definitions = {...pageDefinitions,
    validators: {
        landlord: (value) => {
            return utils.validator.validateRequired(value);
        },
        email: (value) => {
            return utils.validator.validateRequired(value);
        },
        password: (value, state) => {
            if(!state.id && !value) {
                return 'Password should be mandatory when adding new tenant.';
            } else if(value && value.length < 8) {
                return 'Min password length is 8 characters';
            } else if(value && /^\d+$/.test(value)) {
                return "Password can't be all digit characters";
            }

            return true;
        },
        password_confirm: (value, state) => {
            if(state.password && state.password !== '' && state.password !== value) {
                return 'Confirm password should be the same with password. Value is not same.'
            }

            return true;
        }
    },
}

export default configBuilder.buildAddEditPageConfig(definitions);