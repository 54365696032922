import { useState } from "react";
import { useParams, withRouter } from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import Form from "../../../../components/ubold/organisms/Form";

import CVTextField from "../../../../components/ubold/molecules/forms/CVTextField";
import CVSelect from "../../../../components/ubold/molecules/forms/CVSelect";
import CVAsyncSelect from "../../../../components/ubold/molecules/forms/CVAsyncSelect";

import definitions from "./definitions";
import CVImageView from "components/ubold/molecules/forms/CVImageView";

function EnabledPaymentViewPage() {
  let { id }: { id: string } = useParams();

  const initialState = {
    status: 0,
  };

  const [state, setState] = useState(initialState);

  return (
    <Page
      title={"Detail of " + definitions.title}
      restAccessCode={definitions.restAccessCode}
    >
      <Form
        id={id}
        definitions={definitions}
        readOnly
        state={state}
        setState={setState}
      >
        <CVTextField
          title="Name"
          readOnly
          name="name"
          maxLength={100}
          isRequired
        />
        <CVTextField
          title="Code"
          readOnly
          name="code"
          maxLength={100}
          isRequired
        />
        <CVSelect
          title="Status"
          readOnly
          name="status"
          data={[
            {
              label: "PUBLISH",
              value: 1,
            },
            {
              label: "UNPUBLISH",
              value: 0,
            },
          ]}
        />
        <CVTextField
          title="Sequence"
          readOnly
          name="sequence"
          type="number"
          min={1}
        />
        <CVAsyncSelect
          readOnly
          name="category_payment"
          title="Category Payment"
          data="payment_gateway/category_payment?__type__=select_entries"
          itemExtractor={(row: { id: string; name: string }) => {
            return {
              label: row.name,
              value: row.id,
            };
          }}
        />
        <CVImageView name="image_original" title="Image" />
      </Form>
    </Page>
  );
}

export default withRouter(EnabledPaymentViewPage);
