import { useState } from "react";

import { withRouter } from "react-router-dom";

import utils from "../../../../utils";

function Pagination(props) {
  const {
    fetch,
    totalRows,
    rowsPerPage,
    selectedInitialRowsPerPage,
    selectedPage,
    setSelectedPage,
  } = props;
  const findFiftyRows = rowsPerPage.findIndex(
    (limit) => limit === 50
  );

  const [jumpToSelectedPage, setJumpToSelectedPage] = useState(1);
  const [selectedRowPerPage, setSelectedRowPerPage] = useState(
    selectedInitialRowsPerPage
      ? rowsPerPage[selectedInitialRowsPerPage]
      : rowsPerPage[findFiftyRows > -1 ? findFiftyRows : 0]
  );
  const [isMoreThanTotalPage, setIsMoreThanTotalPage] =
    useState(false);

  const pageCount =
    totalRows === 0 ? 1 : Math.ceil(totalRows / selectedRowPerPage);

  let pages = [];

  pages.push("&laquo;");
  pages.push("Prev");

  const pagesPagination = utils.commons.createPagination(
    selectedPage,
    pageCount
  );
  pages = pages.concat(pagesPagination);

  pages.push("Next");
  pages.push("&raquo;");

  const pagination = [];
  for (let i = 0; i < pages.length; i++) {
    let pageNumber = pages[i];

    pagination[i] = (
      <li
        key={"pagination" + i}
        className={
          "page-item " + (pageNumber === selectedPage ? "active" : "")
        }
        style={{
          marginRight: "1px",
        }}
      >
        <a
          style={{
            cursor: "pointer",
            backgroundColor:
              pageNumber === selectedPage ? "#D9EFE5" : "unset",
            border:
              pageNumber === selectedPage
                ? "1px solid #35B0A7"
                : "1px solid #dee2e6",
            color:
              pageNumber === selectedPage ? "#35B0A7" : "#323a46",
          }}
          onClick={() => {
            if (pageNumber !== "...") {
              if (pageNumber === "&raquo;") {
                pageNumber = pageCount;
              } else if (pageNumber === "&laquo;") {
                pageNumber = 1;
              } else if (pageNumber === "Next") {
                pageNumber =
                  selectedPage < pageCount
                    ? selectedPage + 1
                    : pageCount;
              } else if (pageNumber === "Prev") {
                pageNumber = selectedPage > 1 ? selectedPage - 1 : 1;
              }

              setSelectedPage(pageNumber);
              setJumpToSelectedPage(pageNumber);

              fetch(pageNumber, selectedRowPerPage);
            }

            setIsMoreThanTotalPage(false);
          }}
          className="page-link"
          dangerouslySetInnerHTML={{ __html: pageNumber }}
        />
      </li>
    );
  }

  return (
    <div className="row p-2">
      <div className="col-12 col-md-9">
        <div className="grid-container">
          <div className="form-row align-items-center">
            <div className="col-auto">
              <label htmlFor="inlineFormInputGroup">Limit</label>
              <select
                id="inlineFormInput"
                className="form-control"
                value={selectedRowPerPage}
                onChange={(e) => {
                  const newSelectedRowPerPage = parseInt(
                    e.target.value
                  );

                  setSelectedRowPerPage(newSelectedRowPerPage);

                  const resetPageNumber = 1;

                  setSelectedPage(resetPageNumber);
                  setJumpToSelectedPage(resetPageNumber);

                  setIsMoreThanTotalPage(false);

                  fetch(resetPageNumber, newSelectedRowPerPage);
                }}
              >
                {rowsPerPage.map((value, index) => {
                  return <option key={index}>{value}</option>;
                })}
              </select>
            </div>
            <div className="col-auto pt-2 pt-md-0">
              <label htmlFor="inlineFormInputGroup">
                Jump to:{" "}
                {isMoreThanTotalPage ? (
                  <span style={{ fontSize: "12px", color: "red" }}>
                    Maximum page number {pageCount}
                  </span>
                ) : (
                  <></>
                )}
              </label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <div className="input-group-text">Page:</div>
                </div>
                <input
                  type="number"
                  size="3"
                  placeholder="1"
                  aria-label="page"
                  className="form-control"
                  value={jumpToSelectedPage}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      const page = jumpToSelectedPage
                        ? jumpToSelectedPage
                        : 1;

                      setJumpToSelectedPage(page);

                      setSelectedPage(page);

                      setIsMoreThanTotalPage(false);

                      fetch(page, selectedRowPerPage);
                    }
                  }}
                  onChange={(e) => {
                    const newPage = parseInt(e.target.value);

                    if (newPage <= pageCount && newPage >= 1) {
                      setIsMoreThanTotalPage(false);
                      setJumpToSelectedPage(newPage);
                    } else if (newPage > pageCount) {
                      setIsMoreThanTotalPage(true);
                      setJumpToSelectedPage(pageCount);
                    } else if (e.target.value === "") {
                      setJumpToSelectedPage(null);
                    }
                  }}
                />
                <div className="input-group-append">
                  <button
                    type="button"
                    className="btn btn-success waves-effect waves-light"
                    onClick={() => {
                      const page = jumpToSelectedPage
                        ? jumpToSelectedPage
                        : 1;

                      setJumpToSelectedPage(page);

                      setSelectedPage(page);

                      setIsMoreThanTotalPage(false);

                      fetch(page, selectedRowPerPage);
                    }}
                  >
                    Go
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-3">
        <div className="grid-container h-100 d-flex align-items-end justify-content-start justify-content-md-end">
          <div className="form-row pt-2 pt-md-0">
            <div className="col-auto">
              <span>
                {selectedPage} from {pageCount} ({totalRows} records)
              </span>
              <div className="input-group flex-column mt-1">
                <ul className="pagination pagination-m m-0 justify-content-end">
                  {pagination}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(Pagination);
