import React, { CSSProperties } from "react";
const CheckedList =
  require("../../../../assets/icons/checkedList.svg") as string;

interface CheckboxStyleProps {
  container?: CSSProperties;
  icon?: CSSProperties;
}

interface CheckboxProps {
  isChecked: boolean;
  checkboxCustomStyle?: CheckboxStyleProps;
}

const Checkbox: React.FC<CheckboxProps> = ({
  isChecked = false,
  checkboxCustomStyle = {
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "12px",
      width: "12px",
      minWidth: "12px",
      borderRadius: "2px",
      borderStyle: "solid",
      borderWidth: "1px",
      marginRight: "8px",
      borderColor: isChecked ? "#FFFFFF" : "#CCCCCC",
      backgroundColor: isChecked ? "#00BBB4" : "#FFFFFF",
    },
    icon: {
      width: "6.67px",
      height: "4.58px",
      objectFit: "cover",
      display: isChecked ? "flex" : "none",
    },
  },
}) => {
  return (
    <div style={checkboxCustomStyle.container}>
      <img style={checkboxCustomStyle.icon} src={CheckedList} />
    </div>
  );
};

export default Checkbox;
