import { useParams, withRouter } from "react-router-dom";

import TabPaneSecondary from "../../../../../../components/ubold/organisms/TabPaneSecondary";

import COneOrManyToManyField from "../../../../../../components/ubold/molecules/forms/COneOrManyToManyField";

function InvoicePayments(props) {
  const { definitions, tab, state, setState } = props;

  let { id } = useParams();

  return (
    <TabPaneSecondary
      definitions={definitions}
      tab={tab}
      restAccessCodeNamespace="payment"
      state={state}
      setState={setState}
    >
      <COneOrManyToManyField
        id={id}
        name={tab.id}
        restPath="finance/payment/"
        relationKey="invoice_id"
        photoFields={["image"]}
        excludedFieldsOnSubmit={["tenant", "invoice"]}
        columns={[
          {
            name: "tenant_name",
            type: "read_only",
            title: "Tenant",
          },
          {
            name: "amount",
            type: "read_only",
            title: "number",
          },
          {
            name: "discount_amount",
            type: "number",
            title: "Discount Amount",
            isRequired: true,
          },
          {
            name: "voucher",
            type: "text",
            maxLength: 12,
            title: "Voucher",
          },
          {
            name: "comment",
            type: "read_only",
            title: "Comment",
          },
          {
            name: "remark",
            type: "read_only",
            title: "Remark",
          },
          {
            name: "image",
            type: "photo_upload",
            title: "Image",
            isRequired: true,
          },
          {
            name: "midtrans_number",
            type: "read_only",
            title: "Midtrans number",
          },
          {
            name: "bank_account_number",
            type: "text",
            maxLength: 50,
            title: "Bank account number",
          },
          {
            name: "bank_account_name",
            type: "text",
            maxLength: 50,
            title: "Bank account name",
          },
          {
            name: "bank_name",
            type: "read_only",
            maxLength: 50,
            title: "Bank name",
          },
          {
            name: "date_transfer",
            type: "datetime",
            title: "Date transfer",
          },
          {
            name: "payment_status",
            type: "select",
            title: "Payment status",
            data: {
              optionField: "finance_payment_status",
            },
          },
          {
            name: "payment_method",
            type: "select",
            title: "Payment method",
            data: {
              optionField: "finance_invoice_payment_method",
            },
          },
          {
            name: "remaining_amount",
            type: "read_only",
            title: "Remaining amount",
          },
        ]}
      />
    </TabPaneSecondary>
  );
}

export default withRouter(InvoicePayments);
