import { withRouter } from "react-router-dom";

import useGlobalState from "../../../../state";

import utils from "../../../../utils";

function CHTextView(props) {
  const {
    name,
    title,
    content = null,
    state,
    isSafeContent = false,
    renderer = null,
    restAccessCode,
    helperText = "",
    classLabel = null,
    classData = null,
    paddingLeft = null,
    enableHyperlink = false,
    dataId = null,
    locationPath = null,
  } = props;

  const [fieldAccesses] = useGlobalState("field_accesses");
  const [accesses] = useGlobalState("accesses");
  const [user] = useGlobalState("user");

  const generateLinkBasedOnAccess = () => {
    let generatedPath = `${locationPath}/view/${dataId}`;
    if (
      accesses?.includes(`${restAccessCode}.update`) ||
      user?.["is_super_user"]
    ) {
      generatedPath = `${locationPath}/edit/${dataId}`;
    }
    return generatedPath;
  };

  const renderValueWithHyperLink = () => {
    /** Feature v.1 RenderTextWithHyperlink
     * to enable this feature you need to sent this following parameter
     * enableHyperlink - boolean - mandatory
     * dataId - number - mandatory
     * locationPath - string - mandatory
     * format locationPath example: /sales/order_sop
     */
    return (
      <div
        className={`${classData ?? "col-md-9"}`}
        style={{ paddingTop: "7px", paddingLeft: paddingLeft }}
      >
        <a
          href={generateLinkBasedOnAccess()}
          target="_blank"
          rel="noreferrer"
        >
          {state[name]}
        </a>
      </div>
    );
  };

  return (
    <div
      className="form-group row mb-3"
      style={{
        display:
          restAccessCode &&
          utils.access.isFieldHidden(
            fieldAccesses,
            restAccessCode + "." + name
          )
            ? "none"
            : "flex",
      }}
    >
      <label
        htmlFor="name"
        className={`${classLabel ?? "col-md-3"} col-form-label`}
      >
        {title}
      </label>
      {enableHyperlink && dataId && locationPath ? (
        renderValueWithHyperLink()
      ) : (
        <div
          className={`${classData ?? "col-md-9"}`}
          style={{ paddingTop: "7px", paddingLeft: paddingLeft }}
        >
          {isSafeContent ? (
            <span
              dangerouslySetInnerHTML={{
                __html:
                  content === null
                    ? utils.commons.isFunction(renderer)
                      ? renderer(state[name])
                      : state[name]
                      ? state[name]
                      : "-"
                    : utils.commons.isFunction(renderer)
                    ? renderer(
                        content.replace("{VALUE}", state[name])
                      )
                    : content.replace("{VALUE}", state[name]),
              }}
            />
          ) : content === null ? (
            utils.commons.isFunction(renderer) ? (
              renderer(state[name])
            ) : state[name] ? (
              state[name]
            ) : (
              "-"
            )
          ) : utils.commons.isFunction(renderer) ? (
            renderer(content.replace("{VALUE}", state[name]))
          ) : (
            content.replace("{VALUE}", state[name])
          )}
          {helperText !== "" ? (
            <p className="text-muted font-13 pt-1 mb-0">
              {helperText}
            </p>
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
}

export default withRouter(CHTextView);
