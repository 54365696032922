import configBuilder from "../../../components/ubold/ConfigBuilder";

import utils from "../../../utils";

let definitions = {
  title: "Point of Interests",
  frontendPath: "/building/point_of_interest",
  baseLink: "/building/point_of_interest",
  restAccessCode: "building.point_of_interest",
  __table__: {
    filterColumn: 4,
    hideEditButton: true,
    filters: [
      {
        id: "name",
        name: "Name",
        type: "text",
      },
      {
        id: "name_en",
        name: "Name EN",
        type: "text",
      },
      {
        id: "date_updated",
        name: "Date Updated",
        type: "date",
      },
      {
        id: "date_created",
        name: "Date Created",
        type: "date",
      },
    ],
    columns: [
      {
        id: "name",
        name: "Name",
        enableHyperlink: true,
      },
      {
        id: "name_en",
        name: "Name EN",
      },
      {
        id: "slug",
        name: "Slug",
      },
      {
        id: "booster",
        name: "Boost POI",
        renderer: (value) => {
          if (value === "true") {
            return '<span style="color: rgb(26, 188, 156);"> ✔ </span>';
          } else {
            return '<span style="color: red;">✘</span>';
          }
        },
      },
      {
        id: "date_updated",
        name: "Date Updated",
        renderer: (value) => {
          return utils.formatter.dateTime(value);
        },
      },
      {
        id: "date_created",
        name: "Date Created",
        renderer: (value) => {
          return utils.formatter.dateTime(value);
        },
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
