import { withRouter } from "react-router-dom";

import TabPane from "../../../../../../components/ubold/organisms/TabPane";
import TabPaneSection from "../../../../../../components/ubold/organisms/TabPaneSection";

import CHTextField from "../../../../../../components/ubold/molecules/forms/CHTextField";
import CHCheckBox from "components/ubold/molecules/forms/CHCheckBox";

function User(props) {
  const { id, definitions, tab, state, setState } = props;

  return (
    <TabPane
      id={id}
      definitions={definitions}
      tab={tab}
      state={state}
      setState={setState}
    >
      <TabPaneSection style={{ padding: "25px 16px 0px" }}>
        <CHTextField
          name="username"
          title="Username"
          isRequired
          maxLength={150}
          helperText="Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only."
        />
        <CHTextField
          name="first_name"
          title="First Name"
          isRequired
          maxLength={30}
          helperText="Required. 30 characters or fewer. Letters and -/' only."
        />
        <CHTextField
          name="last_name"
          title="Last Name"
          isRequired
          maxLength={150}
          helperText="Required. 150 characters or fewer. Letters and -/' only."
        />
        <CHTextField
          name="email"
          title="Email"
          type="email"
          maxLength={254}
          isRequired
        />
        <CHTextField
          name="password"
          title="Password"
          type="password"
          autoComplete="new-password"
          password={50}
          helperText="Your password can't be too similar to your other personal information.
                    <br/>Your password must contain at least 8 characters.
                    <br/>Your password can't be entirely numeric."
        />
        <CHTextField
          name="password_confirm"
          title="Confirm Password"
          type="password"
          autoComplete="new-password"
          password={50}
          helperText="Enter the same password as before, for verification."
        />

        <CHCheckBox name="is_staff" title="Staff Status" />
        <CHCheckBox name="is_active" title="Active Status" />
      </TabPaneSection>
    </TabPane>
  );
}

export default withRouter(User);
