import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import CPasswordField from "components/ubold/atoms/forms/CPasswordField";
import styled from "styled-components/macro";
import { useToast } from "utils/customHooks";

import {
  BorderBar,
  ButtonPrimary,
  LabelFormSRegular,
  LabelLMedium,
  LabelSRegular,
  VFormGroup,
} from "components/ubold/atoms/General.css";

import loading from "../../../assets/loading.gif";

import withReactContent from "sweetalert2-react-content";
import utils from "utils";
import configs from "configs";
import Swal from "sweetalert2";
import useGlobalState from "state";

import { parseReturnMessage } from "utils/generalUtils";
import { useHistory } from "react-router-dom";

interface ChangePasswordSectionProps {
  setIsFormPasswordDirty?: Dispatch<SetStateAction<boolean>>;
}

type OnTypingValidationFormChangePasswordObjects = {
  newPassword: Array<{
    error: boolean;
    text: string;
    visible: boolean;
  }>;
  confirmPassword: Array<{
    error: boolean;
    text: string;
    visible: boolean;
  }>;
};

type FormChangePasswordStateObject = {
  password: string;
  passwordError: string | string[];
  newPassword: string;
  newPasswordError: string | string[];
  confirmPassword: string;
  confirmPasswordError: string | string[];
};

const initialFormValue: FormChangePasswordStateObject = {
  password: null,
  passwordError: null,
  newPassword: null,
  newPasswordError: null,
  confirmPassword: null,
  confirmPasswordError: null,
};

const ChangePasswordSection: React.FC<ChangePasswordSectionProps> = ({
  setIsFormPasswordDirty,
}) => {
  const history = useHistory();
  const [user] = useGlobalState("user");
  const [form, setForm] =
    useState<FormChangePasswordStateObject>(initialFormValue);

  const [onTypingRules, setOnTypingRules] =
    useState<OnTypingValidationFormChangePasswordObjects>({
      newPassword: [
        {
          error: false,
          text: "Your password must contain at least 8 characters.",
          visible: true,
        },
        {
          error: false,
          text: "Your password can't be entirely numeric.",
          visible: true,
        },
        {
          error: false,
          text: "This field is required",
          visible: false,
        },
      ],
      confirmPassword: [
        {
          error: false,
          text: "Enter the same password as before, for verification.",
          visible: true,
        },
        {
          error: false,
          text: "This field is required",
          visible: false,
        },
      ],
    });

  const toast = useToast();

  const isButtonSubmitDisabled =
    !form?.password && !form?.newPassword && !form?.confirmPassword;

  useEffect(() => {
    setIsFormPasswordDirty(
      !(
        !form?.password &&
        !form?.newPassword &&
        !form?.confirmPassword
      )
    );
    history.push({
      pathname: "/profile_settings",
      state: {
        target: "change_password",
        formPasswordDirty: !(
          !form?.password &&
          !form?.newPassword &&
          !form?.confirmPassword
        ),
      },
    });
  }, [form]);

  const runOnTypingValidation = {
    newPassword: (value: string) => {
      const currentState = { ...onTypingRules };
      const inputValue = value === "" ? null : value;

      const regExOnlyDigit = new RegExp(/^\d+$/);
      const isNumericOnly = regExOnlyDigit.test(value);

      if (!value) {
        currentState.newPassword[0].visible = false;
        currentState.newPassword[1].visible = false;
        currentState.newPassword[2].error = true;
        currentState.newPassword[2].visible = true;
      } else {
        currentState.newPassword[0].visible = true;
        currentState.newPassword[1].visible = true;
        currentState.newPassword[2].error = false;
        currentState.newPassword[2].visible = false;
      }

      if (value) {
        if (value?.length > 0 && value?.length < 8) {
          currentState.newPassword[0].error = true;
        } else {
          currentState.newPassword[0].error = false;
        }

        if (isNumericOnly) {
          currentState.newPassword[1].error = true;
        } else {
          currentState.newPassword[1].error = false;
        }

        if (form.confirmPassword) {
          if (
            value?.length > 0 &&
            inputValue === form.confirmPassword
          ) {
            currentState.confirmPassword[0].error = false;
          } else {
            currentState.confirmPassword[0].error = true;
          }
        }
      }

      setOnTypingRules(currentState);
    },
    confirmPassword: (value: string) => {
      const currentState = { ...onTypingRules };

      const inputValue = value === "" ? null : value;

      if (!value) {
        currentState.confirmPassword[0].visible = false;
        currentState.confirmPassword[1].error = true;
        currentState.confirmPassword[1].visible = true;
      } else {
        currentState.confirmPassword[0].visible = true;
        currentState.confirmPassword[1].error = false;
        currentState.confirmPassword[1].visible = false;
      }

      if (value?.length > 0 && inputValue !== form?.newPassword) {
        currentState.confirmPassword[0].error = true;
      } else {
        currentState.confirmPassword[0].error = false;
      }

      setOnTypingRules(currentState);
    },
  };

  /* const debugFormStateAndTypingValidation = () => {
    const payload = form;

    console.log("User's id: ", user?.id);
    console.log("Form's State: ", payload);
    console.log("onTypingValidation", onTypingRules);
  }; */

  const clearErrorServerValidation = (): void => {
    setForm((prevState) => {
      return {
        ...prevState,
        passwordError: null,
      };
    });
  };

  const verifyInlineValidation = (
    rules: OnTypingValidationFormChangePasswordObjects
  ): {
    isValidationPassed: boolean;
    state: Array<{
      id: string;
      count: number;
      data: Array<{
        error: boolean;
        text: string;
        visible: boolean;
      }>;
    }>;
  } => {
    if (Object.keys(rules)?.length === 0) return undefined;

    const errors: Array<{
      id: string;
      count: number;
      data: Array<{
        error: boolean;
        text: string;
        visible: boolean;
      }>;
    }> = [];

    Object.entries(rules).map((input, inputIndex) => {
      const inputName = input[0];
      const inputRules = input[1];

      const isErrorFound = inputRules.filter(
        (rule) => rule.error === true
      );

      if (isErrorFound.length > 0) {
        errors.push({
          id: inputName,
          count: isErrorFound.length,
          data: isErrorFound,
        });
      }
    });

    return { isValidationPassed: errors.length === 0, state: errors };
  };

  const sendData = () => {
    const payload = {
      password: form.password,
      new_password: form.newPassword,
      confirm_password: form.confirmPassword,
    };

    clearErrorServerValidation();

    const isValidationPassed =
      verifyInlineValidation(onTypingRules).isValidationPassed;

    if (isValidationPassed) {
      const sweetAlert = withReactContent(Swal);

      sweetAlert.fire({
        title: "Saving data",
        html: (
          <img
            style={{ width: "125px", height: "125px" }}
            alt="loading..."
            src={loading}
          />
        ),
        showConfirmButton: false,
        allowOutsideClick: false,
      });

      utils.httpClient.patch(
        configs.apiUrl + "change-password/" + user?.id + "/",
        payload,
        () => {
          setForm({ ...initialFormValue });
          sweetAlert.close();
          toast({
            description: "Data has been saved successfully ",
          });
        },
        (error: number, message: string) => {
          sweetAlert.close();
          if (utils.commons.isJSON(message)) {
            parseReturnMessage(message, setForm);
            toast({
              description: "Failed",
              type: "danger",
              dTime: 1000,
            });
          } else {
            let errorMessage = utils.httpClient
              .getErrorMessage(error)
              .split("\n")[0];

            toast({
              description: errorMessage,
              type: "danger",
              dTime: 1000,
            });
          }
          sweetAlert.close();
          toast({
            description: "Save is failed",
            type: "danger",
          });
        }
      );
    }
  };

  const handleSaveChanges = (): void => {
    if (!form.newPassword || !form.confirmPassword) {
      // Show validation error if newPassword & confirmPassword was blank
      if (!form.newPassword) {
        const rules = [...onTypingRules.newPassword];

        rules[0] = {
          ...rules[0],
          visible: false,
        };
        rules[1] = {
          ...rules[1],
          visible: false,
        };
        rules[2] = {
          ...rules[2],
          error: true,
          visible: true,
        };

        setOnTypingRules((prevState) => {
          return {
            ...prevState,
            newPassword: rules,
          };
        });
      }

      if (!form.confirmPassword) {
        const rules = [...onTypingRules.confirmPassword];

        rules[0] = {
          ...rules[0],
          visible: false,
        };
        rules[1] = {
          ...rules[1],
          error: true,
          visible: true,
        };

        setOnTypingRules((prevState) => {
          return {
            ...prevState,
            confirmPassword: rules,
          };
        });
      }
    } else {
      sendData();
    }
  };
  return (
    <Container>
      <p>
        <LabelLMedium>Change Password Section</LabelLMedium>
      </p>

      <BorderBar margin="0 0 24px 0" />
      <FormChangePasssword>
        <VFormGroup spacing={{ bottom: 4 }}>
          <LabelFormSRegular>
            <LabelSRegular
              color={form.passwordError ? "#D00C1A" : "#222222"}
            >
              Old Password
            </LabelSRegular>
            <LabelSRegular color="#D00C1A">*</LabelSRegular>
          </LabelFormSRegular>
          <CPasswordField
            name="password"
            placeholder="Old Password"
            setState={setForm}
            state={form}
          />
        </VFormGroup>

        <VFormGroup spacing={{ bottom: 4 }}>
          <LabelFormSRegular>
            <LabelSRegular
              color={
                onTypingRules.newPassword.find((rule) => rule.error)
                  ? "#D00C1A"
                  : "#222222"
              }
            >
              New Password
            </LabelSRegular>
            <LabelSRegular color="#D00C1A">*</LabelSRegular>
          </LabelFormSRegular>
          <CPasswordField
            name="newPassword"
            onChange={(e) => {
              const value = e.target.value;

              setForm((prevState) => ({
                ...prevState,
                newPassword: value === "" ? null : value,
              }));

              runOnTypingValidation.newPassword(value);
            }}
            placeholder="New Password"
            rules={onTypingRules.newPassword}
            setState={setForm}
            state={form}
          />
        </VFormGroup>

        <VFormGroup spacing={{ bottom: 4 }}>
          <LabelFormSRegular>
            <LabelSRegular
              color={
                onTypingRules.confirmPassword.find(
                  (rule) => rule.error && rule.visible
                )
                  ? "#D00C1A"
                  : "#222222"
              }
            >
              Confirm New Password
            </LabelSRegular>
            <LabelSRegular color="#D00C1A">*</LabelSRegular>
          </LabelFormSRegular>
          <CPasswordField
            name="confirmPassword"
            onKeyDown={(e) => {
              if (!isButtonSubmitDisabled) {
                if (e?.key === "Enter") {
                  handleSaveChanges();
                }
              }
            }}
            onChange={(e) => {
              const value = e.target.value;

              setForm((prevState) => ({
                ...prevState,
                confirmPassword: value === "" ? null : value,
              }));

              runOnTypingValidation.confirmPassword(value);
            }}
            placeholder="Confirm Password"
            rules={onTypingRules.confirmPassword}
            setState={setForm}
            state={form}
          />
        </VFormGroup>
      </FormChangePasssword>

      <div className="d-flex justify-content-end">
        <ButtonPrimary
          className="mr-1"
          disabled={isButtonSubmitDisabled}
          onClick={() => {
            // debugFormStateAndTypingValidation();
            handleSaveChanges();
          }}
          type="button"
        >
          Save Changes
        </ButtonPrimary>
      </div>
    </Container>
  );
};

export default ChangePasswordSection;

const Container = styled.div`
  background-color: #fff;
  padding: 24px;
`;

const FormChangePasssword = styled.form``;
