import { useParams, withRouter } from "react-router-dom";

import TabPaneSecondary from "../../../../../../components/ubold/organisms/TabPaneSecondary";

import COneOrManyToManyField from "../../../../../../components/ubold/molecules/forms/COneOrManyToManyField";

function BuildingAddons(props) {
  const { definitions, tab, state, setState } = props;

  let { id } = useParams();

  return (
    <TabPaneSecondary
      definitions={definitions}
      tab={tab}
      restAccessCodeNamespace="addon"
      state={state}
      setState={setState}
    >
      <COneOrManyToManyField
        id={id}
        name={tab.id}
        restPath="building/addon__building/"
        relationKey="building_id"
        columns={[
          {
            name: "addon",
            type: "async_select",
            title: "Addon",
            width: 300,
            disabledAtEdit: true,
            isUniqueOption: true,
            isRequired: true,
            data: "building/addon?__type__=select_entries",
          },
          {
            name: "payment_plan",
            type: "number",
            title: "Payment Plan",
          },
          {
            name: "price",
            type: "number",
            width: 250,
            default: 0,
            isRequired: true,
            title: "Price",
          },
          {
            name: "work_estimation",
            type: "number",
            width: 50,
            default: 0,
            isRequired: true,
            title: "Work Estimation",
          },
          {
            name: "visible",
            type: "checkbox",
            width: 50,
            center: true,
            default: true,
            title: "Is Visible",
          },
          {
            name: "is_publish",
            type: "checkbox",
            width: 50,
            center: true,
            title: "Is Publish",
          },
        ]}
      />
    </TabPaneSecondary>
  );
}

export default withRouter(BuildingAddons);
