import { useState } from "react";
import { useParams, withRouter } from "react-router";

import Page from "components/ubold/organisms/Page";
import Form from "components/ubold/organisms/Form";
import definitions from "./definitions";
import CVTextArea from "components/ubold/molecules/forms/CVTextArea";
import CVSelect from "components/ubold/molecules/forms/CVSelect";
import CVAsyncSelect from "components/ubold/molecules/forms/CVAsyncSelect";
import CVTextField from "components/ubold/molecules/forms/CVTextField";
import CVCheckBox from "components/ubold/molecules/forms/CVCheckBox";
import CVSimpleManyToMany from "components/ubold/molecules/forms/CVSimpleManyToMany";

function NotificationInfoViewOnlyPage() {
  let { id }: { id: any } = useParams();

  const initialState: any = {};

  const [state, setState] = useState(initialState);

  return (
    <Page
      title={
        (id === undefined ? "Add New" : "Edit") +
        " " +
        definitions.title
      }
      restAccessCode={definitions.restAccessCode}
    >
      <Form
        id={id}
        definitions={definitions}
        state={state}
        setState={setState}
        readOnly={true}
      >
        <CVTextField
          name="title"
          title="Title"
          maxLength={254}
          isRequired
          readOnly={true}
        />
        <CVTextArea
          name="messages"
          title="Messages"
          isRequired
          readOnly={true}
        />
        <CVTextField
          name="sanity_id"
          title="Sanity id"
          isRequired
          readOnly={true}
        />
        <CVCheckBox name="publish" title="Publish" readOnly={true} />
        <CVSelect
          name="notif_type"
          title="Notif Type"
          data={[
            {
              label: "Info",
              value: 1,
            },
          ]}
          isRequired
          readOnly={true}
        />
        <CVSelect
          name="target"
          title="Target"
          data={[
            {
              label: "All User",
              value: 1,
            },
            {
              label: "Specific Building",
              value: 2,
            },
            {
              label: "All Tenant",
              value: 3,
            },
            {
              label: "Specific User",
              value: 4,
            },
          ]}
          isRequired
          readOnly={true}
        />
        <CVSimpleManyToMany
          name="users"
          title="Users"
          data="user/tenant?__type__=select_entries"
          readOnly={true}
          itemsExtractor={(row: {
            name: string;
            email: string;
            id: number;
            username: string;
            country_code: number;
            phone_number: number;
          }) => {
            return {
              label:
                row.name +
                " - " +
                row.email +
                " - " +
                row.country_code +
                row.phone_number,
              value: row.id,
            };
          }}
        />
        <CVAsyncSelect
          name="building"
          title="Building"
          data="building/building?__type__=select_entries"
          itemsExtractor={(row: any) => {
            return {
              label: row.building_name,

              value: row.id,
            };
          }}
          readOnly={true}
        />
      </Form>
    </Page>
  );
}

export default withRouter(NotificationInfoViewOnlyPage);
