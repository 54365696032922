import configBuilder from "../../../../components/ubold/ConfigBuilder";

import pageDefinitions from "../definitions";

import utils from "../../../../utils";

const definitions = {
  ...pageDefinitions,
  tabs: [
    {
      id: "agreement",
      name: "Agreement",
    },
    {
      id: "t_tiring_escalations",
      name: "Tiring Escalations",
    },
  ],
  validators: {
    building: (value) => {
      return utils.validator.validateRequired(value);
    },
    building_effective_date: (value) => {
      return utils.validator.validateRequired(value);
    },
    building_live_date: (value, state) => {
      const inputValue = utils.validator.validateRequired(value);
      const confirmLiveDate = state["live_date_confirm"];
      if (inputValue !== true && confirmLiveDate) {
        return "Building Live Date is required";
      }
      if (
        state?.building_live_date < state?.soft_live_date ||
        state?.building_live_date < state?.original___soft_live_date
      ) {
        return "Building Live Date cannot less than Soft Live Date";
      }
      return true;
    },
    soft_live_date: (value, state) => {
      const inputValue = utils.validator.validateRequired(value);
      const confirmSoftDate = state["soft_live_confirm"];
      if (inputValue !== true && confirmSoftDate) {
        return "Building Soft Live Date is required";
      }
      return true;
    },
  },
};

export default configBuilder.buildAddEditPageConfig(definitions);
