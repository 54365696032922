import {useState} from "react";

import {useParams, withRouter} from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import definitions from "./definitions";

import TabHeaders from "../../../../components/ubold/organisms/TabHeaders";
import TabContent from "../../../../components/ubold/organisms/TabContent";
import TabbedForm from "../../../../components/ubold/organisms/TabbedForm";

import Comment from "./Tabs/Comment";
import CommentLike from "./Tabs/CommentLike";

function CAddEditPage() {
    let {id} = useParams();

    const initialState = {
        total_likes: 0
    };

    const [state, setState] = useState(initialState);

    return (
        <Page title={(id === undefined ? 'Add New' : 'Edit') + ' ' +  definitions.title}
              restAccessCode={definitions.restAccessCode}>
            <TabbedForm
                id={id}
                state={state}
                setState={setState}
                definitions={definitions}>
                <TabHeaders />
                <TabContent>
                    <Comment />
                    <CommentLike />
                </TabContent>
            </TabbedForm>
        </Page>
    );
}

export default withRouter(CAddEditPage);