import configBuilder from '../../../components/ubold/ConfigBuilder';

let definitions = {
    title: 'Roles',
    baseLink: '/crm/role',
    restAccessCode: 'administrator.role',
    __table__: {
        filters: [
            {
                id: 'name',
                name: 'Role Name',
                type: 'text'
            },
            {
                id: 'is_personal_role',
                name: 'Personal Role',
                type: 'select',
                data: [
                    {
                        label: 'Yes',
                        value: true
                    },
                    {
                        label: 'No',
                        value: false
                    }
                ]
            },
        ],
        columns: [
            {
                id: 'name',
                name: 'Name'
            },
            {
                id: 'is_personal_role',
                name: 'Personal Role',
                isSafeContent: true,
                renderer: (value) => {
                    if(value === 'true') {
                        return '<span style="color: rgb(26, 188, 156);"> ✔ </span>';
                    } else {
                        return '<span style="color: red;">✘</span>';
                    }
                }
            },
            {
                id: 'description',
                name: 'Description'
            }
        ]
    }
}

export default configBuilder.buildTablePageConfig(definitions);