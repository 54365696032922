import {useState} from "react";

import {useParams, withRouter} from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import TabHeaders from "../../../../components/ubold/organisms/TabHeaders";
import TabContent from "../../../../components/ubold/organisms/TabContent";
import TabbedForm from "../../../../components/ubold/organisms/TabbedForm";

import Structure from "./Tabs/Structure";
import StructureRole from "./Tabs/StructureRole";
import StructureUsers from "./Tabs/StructureUsers";

import definitions from "./definitions";

function RoleAddEditPage() {
    let { id } = useParams();

    const initialState = {
        is_personal_role: false
    };

    const [state, setState] = useState(initialState);

    return (
        <Page title={(id === undefined ? 'Add New' : 'Edit') + ' ' +  definitions.title}
              restAccessCode={definitions.restAccessCode}>
            <TabbedForm
                id={id}
                state={state}
                setState={setState}
                definitions={definitions}>
                <TabHeaders />
                <TabContent>
                    <Structure />
                    <StructureRole />
                    <StructureUsers />
                </TabContent>
            </TabbedForm>
        </Page>
    );
}

export default withRouter(RoleAddEditPage);