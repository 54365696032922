import { useParams, withRouter } from "react-router";

import TabPaneSecondary from "../../../../../../components/ubold/organisms/TabPaneSecondary";
import COneOrManyToManyField from "../../../../../../components/ubold/molecules/forms/COneOrManyToManyField";
import { genericTabProps } from "@types";

function PhotoProducts(props: genericTabProps<Object>) {
  const { definitions, tab, state, setState } = props;

  let { id } = useParams<{ id: string }>();

  return (
    <TabPaneSecondary
      definitions={definitions}
      tab={tab}
      restAccessCodeNamespace="photo_product"
      state={state}
      setState={setState}
    >
      <COneOrManyToManyField
        id={id}
        name={tab.id}
        restPath="one_time_purchase/photo_product/"
        relationKey="product_id"
        photoFields={["photo_original"]}
        readOnly={true}
        columns={[
          {
            name: "photo_original",
            type: "photo_upload",
            isRequired: true,
            title: "Photo (Max Size 2MB)",
            readOnly: true,
            disabledAtEdit: true,
          },
        ]}
      />
    </TabPaneSecondary>
  );
}

export default withRouter(PhotoProducts);
