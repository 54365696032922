import { withRouter } from "react-router-dom";

import Page from "../../../../../components/ubold/organisms/Page";
import TableDashboardRoomAllotment from "../../../../../components/ubold/organisms/TableDashboardRoomAllotment/TableDashboardRoomAllotment";

import definitions from "./definitions";
import PageRoomAllotmentBase from "./Page.css";

function CPage() {
  return (
    <PageRoomAllotmentBase>
      <Page
        breadcrumb={definitions.breadcrumb}
        title={definitions.title}
      >
        <TableDashboardRoomAllotment {...definitions.table} />
      </Page>
    </PageRoomAllotmentBase>
  );
}

export default withRouter(CPage);
