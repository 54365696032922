import { useParams, withRouter } from "react-router-dom";

import { useEffect, useState } from "react";

import CTextField from "../../atoms/forms/CTextField";
import CSelect from "../../atoms/forms/CSelect";
import CAsyncSelect from "../../atoms/forms/CAsyncSelect";
import CCheckBox from "../../atoms/forms/CCheckBox";
import CTextArea from "../../atoms/forms/CTextArea";
import CImageUpload from "../../atoms/forms/CImageUpload";
import CDualListBox from "../../atoms/forms/CDualListBox";
import CDateTimePicker from "../../atoms/forms/CDateTimePicker";
import CDateTimePickerV3 from "../../atoms/forms/CDateTimePickerV3";
import CMultiCheckBox from "../../atoms/forms/CMultiCheckBox";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

import { isMobile } from "react-device-detect";

import loading from "../../../../assets/loading.gif";

import moment from "moment";

import useGlobalState from "../../../../state";

import utils from "../../../../utils";
import configs from "../../../../configs";
import {
  getTransitionRoomStatus,
  getStatusByRule,
  getTransitionMaintenanceStatus,
} from "utils/generalUtils";
import { MediaType } from "@types";

function COneOrManyToManyField(props) {
  const {
    id,
    state,
    setState,
    saveOnlyFromMainForm,
    index = 1,
    restAccessCode,
    definitions,
    maxRow,
    minRow,
    dataTypeValidation,
    enableMinRowValidation,
    restAccessCodeNamespace,
    name,
    restPath,
    photoFields = [],
    excludedFieldsOnSubmit = [],
    readOnly,
    additionalDataFilter = "",
    relationKey,
    columns,
    disableActionBtn = false,
    enableListener = false,
    setCheckResult,
    dependsTabName = "",
    rowIdentifier = "",
    useNewClone = false,
    additionalStateInAddNewRow = null,
    afterDeleteRow,
    infoMessageStandAlone = false,
    isEligibleForDiscount = true,
    isHittingEndPoint = true,
    sourceField = null,
  } = props;

  const [user] = useGlobalState("user");
  const isSuperUser = user ? user.is_super_user : false;

  const [accesses] = useGlobalState("accesses");
  const [fieldAccesses] = useGlobalState("field_accesses");
  const [fieldAccessReversePolicy] = useGlobalState(
    "field_access_reverse_policy"
  );
  const [constant] = useGlobalState("constant_values");
  const [isRequestCompleted, setIsRequestCompleted] = useState(false);
  const [isRequestError, setIsRequestError] = useState(false);
  const params = useParams();
  const defaultRoomStatus = constant?.["buildings_room_status"];
  const defaultMaintenanceStatus =
    constant?.["buildings_room_maintenance_status"];

  const paramRestAccessCode = `rest_access_code=${restAccessCode}`;
  const paramObjectId = `object_id=${id}`;
  const dateNow = moment().format("YYYY-MM-DD");

  const [selectedAsync, setSelectedAsync] = useState([]);

  const getAddonCatg = (col, index) => {
    const flag = col?.addonList[index]?.flag;
    const result =
      flag !== "New"
        ? col?.addonList[index]?.addon_category_name
        : selectedAsync[index];
    return result;
  };

  const getDataIndex = (col, index, name) => {
    const result = col?.dataList?.[index]?.[name];
    return result;
  };

  const getFieldValue = (col, index, name) => {
    const flag = col?.dataList[index]?.flag;
    const result =
      flag !== "New" ? col?.dataList[index]?.[name] : null;
    return result;
  };

  const filteredMaintenanceStatus = (data, idx, dependsOn) => {
    if (data.length === 0) return;
    const maintenanceStatusCloned = JSON.parse(
      JSON.stringify(defaultMaintenanceStatus)
    );

    const statusTransition = getTransitionMaintenanceStatus(
      data[idx]?.[dependsOn]
    );
    const newStatus = getStatusByRule(
      statusTransition,
      maintenanceStatusCloned
    );
    return newStatus;
  };

  const filteredRoomStatus = (data, idx, buildingRuOpt, name) => {
    if (data.length === 0) return;

    if (name === "room_status" && defaultRoomStatus) {
      const roomStatus = JSON.parse(
        JSON.stringify(defaultRoomStatus)
      );
      const roomTransition = getTransitionRoomStatus(
        data[idx]?.[name]
      );
      if (buildingRuOpt) roomTransition?.push(9);
      /** explanation
       * at the end of array
       * we need to put its origin status
       * because transition allowed before saving are both
       * its origin status and possible transition by rules
       */
      roomTransition?.push(data[idx]?.[name]);
      const newStatus = getStatusByRule(roomTransition, roomStatus);

      return newStatus;
    }
  };

  const load = () => {
    if (!isHittingEndPoint) return;

    setState((prevState) => ({
      ...prevState,
      [name + "ErrorMessage"]: null,
    }));

    setIsRequestCompleted(false);
    setIsRequestError(false);
    utils.httpClient.get(
      configs.apiUrl +
        restPath +
        "?ordering=id&" +
        relationKey +
        "=" +
        +id +
        "&" +
        additionalDataFilter,
      (response) => {
        for (let i = 0; i < response.data.rows.length; i++) {
          response.data.rows[i].flag = "Editing";
        }

        setTimeout(() => {
          setIsRequestCompleted(true);
          setState((prevState) => ({
            ...prevState,
            [name]: response.data.rows,
            [name + "Columns"]: columns,
            [name + "InfoMessage"]:
              "Data saved successfully at <b>" +
              moment().format("DD MMM YYYY HH:mm:ss") +
              "</b>",
          }));

          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        }, 500 * index);
      },
      (error, message) => {
        setState((prevState) => ({
          ...prevState,
          [name]: [],
          [name + "Columns"]: columns,
          [name + "ErrorMessage"]:
            "Error in fetching data (" + message + ")",
          [name + "InfoMessage"]: null,
        }));

        setIsRequestCompleted(true);
        setIsRequestError(true);

        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }
    );
  };

  const handleOnChange = (event, keyName) => {
    if (!state[name]) return;

    setCheckResult((prevState) => ({
      ...prevState,
      [keyName]: event.target.checked,
    }));

    setState((prevState) => ({
      ...prevState,
      [name]: state[name].map((data) => {
        return {
          ...data,
          [keyName]: event.target.checked,
        };
      }),
    }));
  };

  useEffect(() => {
    if (id) {
      load();
    } else {
      if (!state[name]) {
        setState((prevState) => ({
          ...prevState,
          [name]: [],
          [name + "Columns"]: columns,
        }));
      }
    }
  }, [id]);

  useEffect(() => {
    if (!sourceField?.length || isHittingEndPoint) return;
    const tempSourceField = sourceField.map((item) => {
      return {
        ...item,
        flag: "Editing",
      };
    });
    const timeout = setTimeout(() => {
      setIsRequestCompleted(true);
      setState((prevState) => ({
        ...prevState,
        [name]: [...tempSourceField],
        [name + "Columns"]: columns,
        [name + "InfoMessage"]:
          "Data saved successfully at <b>" +
          moment().format("DD MMM YYYY HH:mm:ss") +
          "</b>",
      }));
    }, 500 * index);
    return () => {
      clearTimeout(timeout);
    };
  }, [sourceField]);

  const renderInfoMessage = () => (
    <div
      className="alert alert-info alert-dismissible fade show mb-0"
      role="alert"
      style={{ borderRadius: "0px" }}
    >
      <button
        onClick={() =>
          setState((prevState) => ({
            ...prevState,
            [name + "InfoMessage"]: null,
          }))
        }
        type="button"
        className="close"
        data-dismiss="alert"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
      <span
        dangerouslySetInnerHTML={{
          __html: state[name + "InfoMessage"],
        }}
      />
    </div>
  );

  return (
    <div
      className="row"
      style={{
        marginTop: "1px",
        marginLeft: isMobile ? "0px" : "-12px",
        marginRight: isMobile ? "0px" : "-12px",
      }}
    >
      <div className="col-12 px-0 px-md-2">
        <div className="card" style={{ marginBottom: "0px" }}>
          {state[name + "ErrorMessage"] ? (
            <div
              className="alert alert-danger alert-dismissible fade show mb-0"
              style={{ borderRadius: "0px" }}
              role="alert"
            >
              <button
                onClick={() =>
                  setState((prevState) => ({
                    ...prevState,
                    [name + "ErrorMessage"]: null,
                  }))
                }
                type="button"
                className="close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div
                dangerouslySetInnerHTML={{
                  __html: state[name + "ErrorMessage"],
                }}
              />
            </div>
          ) : (
            <></>
          )}
          {infoMessageStandAlone && state[name + "InfoMessage"] ? (
            renderInfoMessage()
          ) : (
            <></>
          )}
          {state[name + "HasBeenSaved"] &&
          state[name + "InfoMessage"] ? (
            renderInfoMessage()
          ) : (
            <></>
          )}
          <div
            className="card-body p-0"
            style={{ overflowX: "auto" }}
          >
            <div
              className="table-responsive"
              style={{
                height: "max-content",
                width: "max-content",
                minWidth: "100%",
              }}
            >
              <table
                className="table table-nowrap mb-0"
                style={{ minWidth: "100%" }}
              >
                <thead className="thead-light">
                  <tr>
                    {!readOnly ? (
                      <th
                        className="border-0"
                        style={{
                          width: "1%",
                          padding: "10px",
                          textAlign: "center",
                        }}
                      >
                        Action
                      </th>
                    ) : (
                      <></>
                    )}
                    {columns.map((value, index) => {
                      return !value.isRequired &&
                        restAccessCode &&
                        utils.access.isFieldHidden(
                          fieldAccesses,
                          restAccessCode +
                            "." +
                            restAccessCodeNamespace +
                            "." +
                            value.name
                        ) ? (
                        <></>
                      ) : (
                        <th
                          className="border-0"
                          key={"columns" + index}
                          style={{
                            padding: "10px",
                            width: value.width
                              ? value.width + "px"
                              : "250px",
                            textAlign: value.center
                              ? "center"
                              : "left",
                            display: value.hideColumnByCSS
                              ? "none"
                              : undefined,
                          }}
                        >
                          <span
                            title={
                              value.isRequired
                                ? "This field is required"
                                : "Optional field"
                            }
                            style={{
                              borderBottom: value.isRequired
                                ? "1px dashed #666"
                                : "none",
                            }}
                          >
                            {value["title"]}
                          </span>
                          {value.isRequired ? "*" : ""}
                          {/* Enable Check All */}
                          {value.type === "checkbox" &&
                            value.enableCheckAll && (
                              <span style={{ paddingLeft: 8 }}>
                                <input
                                  type="checkbox"
                                  name={value.name}
                                  onChange={(event) =>
                                    handleOnChange(event, value.name)
                                  }
                                  checked={value.isCheckAll}
                                />
                              </span>
                            )}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {id && !isRequestCompleted && !isRequestError ? (
                    <div className="spinner-border m-2">
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <></>
                  )}
                  {state[name] &&
                    state[name].map((row, index) => {
                      const hasDependByFlag = state[dependsTabName];
                      let isDisableAction = false;
                      if (hasDependByFlag) {
                        /** usage: Enable and disable Action button dynamicly
                         *  pre condition: define dependsTabName inside component declaration on respective page
                         */
                        const flagStatus =
                          hasDependByFlag &&
                          hasDependByFlag[index]?.flag;

                        isDisableAction =
                          flagStatus === "New" || params[0] === "add"
                            ? false
                            : true;
                      }
                      if (maxRow) {
                        isDisableAction =
                          state[name].length === maxRow;
                      }

                      const isNewRow = row.flag === "New";

                      return (
                        <tr
                          key={"row" + index}
                          style={{
                            backgroundColor:
                              row.flag === "Deleted"
                                ? "#e30517"
                                : "#ffffff",
                          }}
                        >
                          {!readOnly ? (
                            <td
                              className="border-0"
                              style={{
                                padding: "10px",
                                textAlign: "center",
                              }}
                            >
                              {row.flag !== "Deleted" &&
                              ((user && user["is_super_user"]) ||
                                (user &&
                                  user[
                                    "has_team_management_access"
                                  ] &&
                                  definitions &&
                                  definitions.isTeamMenu) ||
                                !restAccessCodeNamespace ||
                                (accesses &&
                                  accesses.includes(
                                    restAccessCode +
                                      "." +
                                      restAccessCodeNamespace +
                                      ".destroy"
                                  ))) ? (
                                <button
                                  title="Delete row"
                                  disabled={
                                    disableActionBtn ||
                                    isDisableAction
                                  }
                                  style={{ marginRight: "5px" }}
                                  type="button"
                                  className="btn btn-danger waves-effect waves-light btn-xs"
                                  onClick={() => {
                                    let dataCloned = JSON.parse(
                                      JSON.stringify(state[name])
                                    );

                                    if (row.flag === "New") {
                                      delete dataCloned.splice(
                                        index,
                                        1
                                      );
                                    } else {
                                      dataCloned[index].flag =
                                        "Deleted";
                                    }

                                    setState((prevState) => ({
                                      ...prevState,
                                      [name]: dataCloned,
                                    }));
                                    if (
                                      utils.commons.isFunction(
                                        afterDeleteRow
                                      )
                                    ) {
                                      afterDeleteRow({ index });
                                    }
                                  }}
                                >
                                  <i className="mdi mdi-close" />
                                </button>
                              ) : (user && user["is_super_user"]) ||
                                !restAccessCodeNamespace ||
                                (accesses &&
                                  accesses.includes(
                                    restAccessCode +
                                      "." +
                                      restAccessCodeNamespace +
                                      ".destroy"
                                  )) ? (
                                <button
                                  title="Undo deletion"
                                  style={{ marginRight: "5px" }}
                                  type="button"
                                  className="btn btn-warning waves-effect waves-light btn-xs"
                                  onClick={() => {
                                    let dataCloned = JSON.parse(
                                      JSON.stringify(state[name])
                                    );

                                    dataCloned[index].flag =
                                      "Editing";

                                    setState((prevState) => ({
                                      ...prevState,
                                      [name]: dataCloned,
                                    }));
                                  }}
                                >
                                  <i className="fas fa-undo" />
                                </button>
                              ) : (
                                <></>
                              )}
                              <br />
                              <span
                                style={{
                                  fontSize: "10px",
                                  color:
                                    row.flag === "Deleted"
                                      ? "#ffffff"
                                      : "inherit",
                                }}
                              >
                                Status: <b>{row.flag}</b>
                              </span>
                            </td>
                          ) : (
                            <></>
                          )}
                          {columns.map((column, j) => {
                            let isEditing =
                              state[name][index]["flag"] ===
                              "Editing";

                            let isReadOnly =
                              readOnly ??
                              ((isEditing ||
                                (!isEditing && !column.isRequired)) &&
                                restAccessCode &&
                                utils.access.isFieldReadOnly(
                                  fieldAccesses,
                                  `${restAccessCode}.${restAccessCodeNamespace}.${column.name}`,
                                  fieldAccessReversePolicy,
                                  isSuperUser
                                ));

                            if (
                              accesses &&
                              !accesses.includes(
                                restAccessCode +
                                  "." +
                                  restAccessCodeNamespace +
                                  ".update"
                              )
                            ) {
                              isReadOnly =
                                isEditing && !user["is_super_user"];
                            }

                            if (
                              user &&
                              user["has_team_management_access"] &&
                              definitions &&
                              definitions.isTeamMenu
                            ) {
                              isReadOnly = "";
                            }

                            if (column?.isReadOnly) isReadOnly = true;

                            if (
                              column.type === "text" ||
                              column.type === "number" ||
                              column.type === "email"
                            ) {
                              let customReadOnly;

                              if (column.dynamicReadOnly) {
                                const price = getFieldValue(
                                  column,
                                  index,
                                  "price"
                                );
                                customReadOnly = price ? true : false;
                              }

                              const stateTabId = state[column?.tabId];
                              const dependKeyHasValue = stateTabId
                                ? stateTabId[index][column?.dependKey]
                                : null;

                              if (dependKeyHasValue)
                                isReadOnly = true;

                              return !column.isRequired &&
                                restAccessCode &&
                                utils.access.isFieldHidden(
                                  fieldAccesses,
                                  restAccessCode +
                                    "." +
                                    restAccessCodeNamespace +
                                    "." +
                                    column.name
                                ) ? (
                                <></>
                              ) : (
                                <td
                                  key={"cell_" + j}
                                  className="border-0"
                                  style={{
                                    padding: "10px",
                                    width: column.width
                                      ? column.width + "px"
                                      : "250px",
                                    textAlign: column.center
                                      ? "center"
                                      : "left",
                                    display: column.hideColumnByCSS
                                      ? "none"
                                      : undefined,
                                  }}
                                >
                                  <CTextField
                                    type={column.type}
                                    name={column.name}
                                    state={state[name][index]}
                                    defaultValue={column.default}
                                    readOnly={
                                      isReadOnly ||
                                      customReadOnly ||
                                      column.isDisabled ||
                                      (column.disabledAtEdit &&
                                      (state[name][index]["flag"] ===
                                        "Editing" ||
                                        state[name][index]["flag"] ===
                                          "Deleted")
                                        ? true
                                        : false)
                                    }
                                    isParkingAddon={
                                      column?.isParkingAddon
                                    }
                                    maxLength={
                                      column.maxLength
                                        ? column.maxLength
                                        : 255
                                    }
                                    onChange={(e) => {
                                      const value = e.target.value;

                                      setState((prevState) => ({
                                        ...prevState,
                                        [name]: [...state[name]].map(
                                          (object, i) => {
                                            if (i === index) {
                                              return {
                                                ...object,
                                                [column.name]:
                                                  column.type ===
                                                  "number"
                                                    ? value
                                                    : value,
                                              };
                                            } else {
                                              return object;
                                            }
                                          }
                                        ),
                                      }));
                                    }}
                                  />
                                  {isReadOnly ? (
                                    <div
                                      style={{
                                        marginTop: "5px",
                                        fontSize: "12px",
                                        color: "#aaa",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontStyle: "italic",
                                        }}
                                      >
                                        Read Only
                                      </span>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                  {state[name][index][
                                    column.name + "Error"
                                  ] ? (
                                    <span style={{ color: "red" }}>
                                      {
                                        state[name][index][
                                          column.name + "Error"
                                        ]
                                      }
                                    </span>
                                  ) : (
                                    <></>
                                  )}
                                </td>
                              );
                            } else if (column.type === "select") {
                              let customReadOnly;
                              if (column.dynamicReadOnly) {
                                const effectiveDate = getFieldValue(
                                  column,
                                  index,
                                  "effective_start_date"
                                );

                                const flagStatus =
                                  column?.dataList[index]?.flag;

                                const statusCancel = getFieldValue(
                                  column,
                                  index,
                                  "cancel"
                                );

                                customReadOnly =
                                  flagStatus !== "New" &&
                                  (effectiveDate <= dateNow ||
                                    statusCancel === 2 ||
                                    statusCancel === 3);
                              }
                              return !column.isRequired &&
                                restAccessCode &&
                                utils.access.isFieldHidden(
                                  fieldAccesses,
                                  restAccessCode +
                                    "." +
                                    restAccessCodeNamespace +
                                    "." +
                                    column.name
                                ) ? (
                                <></>
                              ) : (
                                <td
                                  key={"cell_" + j}
                                  className="border-0"
                                  style={{
                                    padding: "10px",
                                    width: column.width
                                      ? column.width + "px"
                                      : "250px",
                                    textAlign: column.center
                                      ? "center"
                                      : "left",
                                    display: column.hideColumnByCSS
                                      ? "none"
                                      : undefined,
                                  }}
                                >
                                  <CSelect
                                    name={column.name}
                                    state={state[name][index]}
                                    stateComponent={state[name]}
                                    defaultValue={column.default}
                                    inclusionKey={column.inclusionKey}
                                    isClearable={column.isClearable}
                                    uniqueIdentifier={
                                      column.uniqueIdentifier
                                    }
                                    isUniqueOption={
                                      column.isUniqueOption
                                        ? column.isUniqueOption
                                        : false
                                    }
                                    data={
                                      column.useStatusTransition &&
                                      column.name === "room_status"
                                        ? filteredRoomStatus(
                                            column.dataList,
                                            index,
                                            column.buildingRuOpt,
                                            column.name
                                          )
                                        : column.useStatusTransition &&
                                          column.name ===
                                            "maintenance_status"
                                        ? filteredMaintenanceStatus(
                                            column.dataList,
                                            index,
                                            column?.dependsOn
                                          )
                                        : column.data
                                    }
                                    readOnly={
                                      isReadOnly || customReadOnly
                                    }
                                    isDisabled={
                                      column.disabledAtEdit &&
                                      (state[name][index]["flag"] ===
                                        "Editing" ||
                                        state[name][index]["flag"] ===
                                          "Deleted")
                                        ? true
                                        : column.isDisabled
                                        ? column.isDisabled
                                        : false
                                    }
                                    itemsExtractor={
                                      column.itemsExtractor
                                    }
                                    onChange={(dataValue) => {
                                      const tempState = [
                                        ...state[name],
                                      ].map((object, i) => {
                                        if (i === index) {
                                          return {
                                            ...object,
                                            [column.name]: dataValue
                                              ? dataValue.value
                                              : null,
                                          };
                                        } else {
                                          return object;
                                        }
                                      });
                                      if (
                                        column?.unallowManualSelection?.includes(
                                          dataValue.value
                                        )
                                      ) {
                                        const sweetAlert =
                                          withReactContent(Swal);
                                        sweetAlert.fire(
                                          "Error",
                                          "Manual Selection is not allowed",
                                          "error"
                                        );
                                        return;
                                      }
                                      setState((prevState) => ({
                                        ...prevState,
                                        [name]: tempState,
                                      }));
                                      if (
                                        utils.commons.isFunction(
                                          column.afterChange
                                        )
                                      ) {
                                        column.afterChange({
                                          dataValue,
                                          index,
                                          tempState,
                                        });
                                      }
                                    }}
                                  />
                                  {isReadOnly ? (
                                    <div
                                      style={{
                                        marginTop: "5px",
                                        fontSize: "12px",
                                        color: "#aaa",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontStyle: "italic",
                                        }}
                                      >
                                        Read Only
                                      </span>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                  {state[name][index][
                                    column.name + "Error"
                                  ] ? (
                                    <span style={{ color: "red" }}>
                                      {
                                        state[name][index][
                                          column.name + "Error"
                                        ]
                                      }
                                    </span>
                                  ) : (
                                    <></>
                                  )}
                                </td>
                              );
                            } else if (
                              column.type === "async_select"
                            ) {
                              return !column.isRequired &&
                                restAccessCode &&
                                utils.access.isFieldHidden(
                                  fieldAccesses,
                                  restAccessCode +
                                    "." +
                                    restAccessCodeNamespace +
                                    "." +
                                    column.name
                                ) ? (
                                <></>
                              ) : (
                                <td
                                  key={"cell_" + j}
                                  className="border-0"
                                  style={{
                                    padding: "10px",
                                    width: column.width
                                      ? column.width + "px"
                                      : "250px",
                                    textAlign: column.center
                                      ? "center"
                                      : "left",
                                    display: column.hideColumnByCSS
                                      ? "none"
                                      : undefined,
                                  }}
                                >
                                  <CAsyncSelect
                                    name={column.name}
                                    state={state[name][index]}
                                    stateComponent={state[name]}
                                    defaultValue={column.default}
                                    inclusionKey={column.inclusionKey}
                                    uniqueIdentifier={
                                      column.uniqueIdentifier
                                    }
                                    dependsOn={column.dependsOn}
                                    dependsOnPrefix={
                                      column.dependsOnPrefix
                                    }
                                    isUniqueOption={
                                      column.isUniqueOption
                                        ? column.isUniqueOption
                                        : false
                                    }
                                    row={index}
                                    data={`${column.data}${
                                      isNewRow &&
                                      column
                                        ?.additionalParamsWhenFlagIsAdd
                                        ?.length
                                        ? `&${(
                                            column.additionalParamsWhenFlagIsAdd ??
                                            []
                                          ).join("&")}`
                                        : ""
                                    }`}
                                    isClearable={column.isClearable}
                                    readOnly={isReadOnly}
                                    isDisabled={
                                      column.disabledAtEdit &&
                                      (state[name][index]["flag"] ===
                                        "Editing" ||
                                        state[name][index]["flag"] ===
                                          "Deleted")
                                        ? true
                                        : column.isDisabled
                                        ? column.isDisabled
                                        : false
                                    }
                                    itemsExtractor={
                                      column.itemsExtractor
                                    }
                                    setState={setState}
                                    onChange={(dataValue) => {
                                      if (enableListener) {
                                        setSelectedAsync({
                                          ...selectedAsync,
                                          [index]: dataValue?.label,
                                        });
                                      }
                                      const tempState = [
                                        ...state[name],
                                      ].map((object, i) => {
                                        if (i === index) {
                                          return {
                                            ...object,
                                            [column.name]: dataValue
                                              ? dataValue.value
                                              : null,
                                          };
                                        } else {
                                          return object;
                                        }
                                      });
                                      setState((prevState) => ({
                                        ...prevState,
                                        [name]: tempState,
                                      }));
                                      if (
                                        utils.commons.isFunction(
                                          column.afterChange
                                        )
                                      ) {
                                        column.afterChange({
                                          dataValue,
                                          index,
                                          tempState,
                                        });
                                      }
                                    }}
                                  />
                                  {state[column.requireField] && (
                                    <div
                                      style={{
                                        marginTop: "5px",
                                        fontSize: "12px",
                                        color: "#aaa",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontStyle: "italic",
                                        }}
                                      >
                                        {column.requireField} required
                                      </span>
                                    </div>
                                  )}
                                  {isReadOnly ? (
                                    <div
                                      style={{
                                        marginTop: "5px",
                                        fontSize: "12px",
                                        color: "#aaa",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontStyle: "italic",
                                        }}
                                      >
                                        Read Only
                                      </span>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                  {state[name][index][
                                    column.name + "Error"
                                  ] ? (
                                    <span style={{ color: "red" }}>
                                      {
                                        state[name][index][
                                          column.name + "Error"
                                        ]
                                      }
                                    </span>
                                  ) : (
                                    <></>
                                  )}
                                </td>
                              );
                            } else if (
                              column.type === "datetime" ||
                              column.type === "date" ||
                              column.type === "time"
                            ) {
                              let customMinDate = "";
                              let customReadOnly;

                              if (column.dynamicMinDate) {
                                const startDate = getDataIndex(
                                  column,
                                  index,
                                  "start_date"
                                );
                                if (
                                  state[name][index]["flag"] === "New"
                                ) {
                                  customMinDate = moment(startDate)
                                    .add(1, "day")
                                    .format("YYYY-MM-DD");
                                } else {
                                  customMinDate = moment(
                                    state[name][index]["end_date"]
                                  ).format("YYYY-MM-DD");
                                }
                              }

                              if (column.dynamicReadOnly) {
                                const startDate = getDataIndex(
                                  column,
                                  index,
                                  "start_date"
                                );
                                customReadOnly = !startDate && true;
                              }

                              let adjustedMinDate = "";
                              if (column.adjustMinDate) {
                                const flag =
                                  state[name][index]["flag"];
                                if (flag !== "New") {
                                  adjustedMinDate = moment(
                                    state[name][index][column.name]
                                  ).format("YYYY-MM-DD");
                                } else {
                                  adjustedMinDate = column.minDate;
                                }
                              }

                              return !column.isRequired &&
                                restAccessCode &&
                                utils.access.isFieldHidden(
                                  fieldAccesses,
                                  restAccessCode +
                                    "." +
                                    restAccessCodeNamespace +
                                    "." +
                                    column.name
                                ) ? (
                                <></>
                              ) : (
                                <td
                                  key={"cell_" + j}
                                  className="border-0"
                                  style={{
                                    padding: "10px",
                                    width: column.width
                                      ? column.width + "px"
                                      : "250px",
                                    textAlign: column.center
                                      ? "center"
                                      : "left",
                                    display: column.hideColumnByCSS
                                      ? "none"
                                      : undefined,
                                  }}
                                >
                                  <CDateTimePicker
                                    name={column.name}
                                    state={state[name][index]}
                                    data={column.data}
                                    isClearable={column.isClearable}
                                    readOnly={
                                      isReadOnly ||
                                      customReadOnly ||
                                      column.isReadOnly ||
                                      (utils.commons.isFunction(
                                        column.generateReadOnly
                                      ) &&
                                        column.generateReadOnly({
                                          index,
                                        }))
                                    }
                                    type={column.type}
                                    monthSelect={column.monthSelect}
                                    minDate={
                                      column.dynamicMinDate
                                        ? customMinDate
                                        : utils.commons.isFunction(
                                            column.getDisabledDate
                                          )
                                        ? column.getDisabledDate({
                                            index,
                                            type: "minDate",
                                            column,
                                          })
                                        : column.dateRanges
                                        ? column.dateRanges
                                            ?.availableRange?.[index]
                                            ?.start_date
                                        : column.adjustMinDate
                                        ? adjustedMinDate
                                        : column.minDate
                                    }
                                    maxDate={
                                      utils.commons.isFunction(
                                        column.getDisabledDate
                                      )
                                        ? column.getDisabledDate({
                                            index,
                                            type: "maxDate",
                                            column,
                                          })
                                        : column.dateRanges
                                        ? column.dateRanges
                                            ?.availableRange?.[index]
                                            ?.end_date
                                        : column.maxDate
                                    }
                                    isDisabled={
                                      column.disabledAtEdit &&
                                      (state[name][index]["flag"] ===
                                        "Editing" ||
                                        state[name][index]["flag"] ===
                                          "Deleted")
                                        ? true
                                        : column.isDisabled
                                        ? column.isDisabled
                                        : false
                                    }
                                    disabledDate={
                                      utils.commons.isFunction(
                                        column.getDisabledDate
                                      )
                                        ? column.getDisabledDate({
                                            index,
                                          })
                                        : column.dateRanges
                                            ?.selectedDate
                                    }
                                    onClear={() => {
                                      const tempCarryOverState = [
                                        ...state[name],
                                      ].map((object, i) => {
                                        if (i === index) {
                                          return {
                                            ...object,
                                            [column.name]: null,
                                          };
                                        } else {
                                          return object;
                                        }
                                      });
                                      setState((prevState) => ({
                                        ...prevState,
                                        [name]: tempCarryOverState,
                                      }));
                                      if (
                                        utils.commons.isFunction(
                                          column.afterClearDate
                                        )
                                      ) {
                                        column.afterClearDate({
                                          index,
                                          tempCarryOverState,
                                          column,
                                        });
                                      }
                                    }}
                                    onChange={(dataValue) => {
                                      let result = moment(
                                        dataValue[0].toISOString()
                                      ).format("YYYY-MM-DD HH:mm");

                                      if (column.type === "date") {
                                        result = moment(
                                          dataValue[0].toISOString()
                                        ).format("YYYY-MM-DD");
                                      } else if (
                                        column.type === "time"
                                      ) {
                                        result = moment(
                                          dataValue[0].toISOString()
                                        ).format("HH:mm:ss");
                                      }

                                      const tempState = [
                                        ...state[name],
                                      ].map((object, i) => {
                                        if (i === index) {
                                          return {
                                            ...object,
                                            [column.name]: result
                                              ? result
                                              : null,
                                          };
                                        } else {
                                          return object;
                                        }
                                      });

                                      setState((prevState) => ({
                                        ...prevState,
                                        [name]: [...tempState],
                                      }));

                                      if (
                                        utils.commons.isFunction(
                                          column.afterChange
                                        )
                                      ) {
                                        column.afterChange({
                                          dataValue,
                                          carryOverState: tempState,
                                          index,
                                        });
                                      }
                                    }}
                                  />
                                  {column.requireField &&
                                  customReadOnly ? (
                                    <div
                                      style={{
                                        marginTop: "5px",
                                        fontSize: "12px",
                                        color: "#aaa",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontStyle: "italic",
                                        }}
                                      >
                                        {column.requireField} required
                                      </span>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                  {isReadOnly ? (
                                    <div
                                      style={{
                                        marginTop: "5px",
                                        fontSize: "12px",
                                        color: "#aaa",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontStyle: "italic",
                                        }}
                                      >
                                        Read Only
                                      </span>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                  {state[name][index][
                                    column.name + "Error"
                                  ] ? (
                                    <span style={{ color: "red" }}>
                                      {
                                        state[name][index][
                                          column.name + "Error"
                                        ]
                                      }
                                    </span>
                                  ) : (
                                    <></>
                                  )}
                                </td>
                              );
                            } else if (
                              column.type === "monthpicker"
                            ) {
                              let customReadOnly;

                              if (column.dynamicReadOnly) {
                                const period = getFieldValue(
                                  column,
                                  index,
                                  "period"
                                );
                                customReadOnly = period
                                  ? true
                                  : false;
                              }
                              return !column.isRequired &&
                                restAccessCode &&
                                utils.access.isFieldHidden(
                                  fieldAccesses,
                                  restAccessCode +
                                    "." +
                                    restAccessCodeNamespace +
                                    "." +
                                    column.name
                                ) ? (
                                <></>
                              ) : (
                                <td
                                  key={`cell_${j}`}
                                  className="border-0"
                                  style={{
                                    padding: "10px",
                                    width: column.width
                                      ? column.width + "px"
                                      : "250px",
                                    textAlign: column.center
                                      ? "center"
                                      : "left",
                                    display: column.hideColumnByCSS
                                      ? "none"
                                      : undefined,
                                  }}
                                >
                                  <CDateTimePickerV3
                                    id={index}
                                    name={column.name}
                                    state={state[name][index]}
                                    disabled={
                                      isReadOnly || customReadOnly
                                    }
                                    type={column.type}
                                    minDate={column.minDate}
                                    maxDate={column.maxDate}
                                    onChange={(dataValue) => {
                                      let result = dataValue;
                                      setState((prevState) => ({
                                        ...prevState,
                                        [name]: [...state[name]].map(
                                          (object, i) => {
                                            if (i === index) {
                                              return {
                                                ...object,
                                                [column.name]:
                                                  result ?? null,
                                              };
                                            } else {
                                              return object;
                                            }
                                          }
                                        ),
                                      }));
                                    }}
                                  />
                                  {isReadOnly ? (
                                    <div
                                      style={{
                                        marginTop: "5px",
                                        fontSize: "12px",
                                        color: "#aaa",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontStyle: "italic",
                                        }}
                                      >
                                        Read Only
                                      </span>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                  {state[name][index][
                                    column.name + "Error"
                                  ] ? (
                                    <span style={{ color: "red" }}>
                                      {
                                        state[name][index][
                                          column.name + "Error"
                                        ]
                                      }
                                    </span>
                                  ) : (
                                    <></>
                                  )}
                                </td>
                              );
                            } else if (column.type === "dual_list") {
                              return !column.isRequired &&
                                restAccessCode &&
                                utils.access.isFieldHidden(
                                  fieldAccesses,
                                  restAccessCode +
                                    "." +
                                    restAccessCodeNamespace +
                                    "." +
                                    column.name
                                ) ? (
                                <></>
                              ) : (
                                <td
                                  key={"cell_" + j}
                                  className="border-0"
                                  style={{
                                    padding: "10px",
                                    width: column.width
                                      ? column.width + "px"
                                      : "250px",
                                    textAlign: column.center
                                      ? "center"
                                      : "left",
                                    display: column.hideColumnByCSS
                                      ? "none"
                                      : undefined,
                                  }}
                                >
                                  <CDualListBox
                                    name={column.name}
                                    state={state[name][index]}
                                    data={column.data}
                                    dependsOn={column.dependsOn}
                                    dependsOnRelationKey={
                                      column.dependsOnRelationKey
                                    }
                                    readOnly={
                                      (column.disabledAtEdit &&
                                        (state[name][index][
                                          "flag"
                                        ] === "Editing" ||
                                          state[name][index][
                                            "flag"
                                          ] === "Deleted")) ||
                                      isReadOnly
                                        ? true
                                        : column.isDisabled
                                        ? column.isDisabled
                                        : false
                                    }
                                    onChange={(dataValue) => {
                                      setState((prevState) => ({
                                        ...prevState,
                                        [name]: [...state[name]].map(
                                          (object, i) => {
                                            if (i === index) {
                                              return {
                                                ...object,
                                                [column.name]:
                                                  dataValue
                                                    ? dataValue
                                                    : [],
                                              };
                                            } else {
                                              return object;
                                            }
                                          }
                                        ),
                                      }));
                                    }}
                                  />
                                  {isReadOnly ? (
                                    <div
                                      style={{
                                        marginTop: "5px",
                                        fontSize: "12px",
                                        color: "#aaa",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontStyle: "italic",
                                        }}
                                      >
                                        Read Only
                                      </span>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                  {state[name][index][
                                    column.name + "Error"
                                  ] ? (
                                    <span style={{ color: "red" }}>
                                      {
                                        state[name][index][
                                          column.name + "Error"
                                        ]
                                      }
                                    </span>
                                  ) : (
                                    <></>
                                  )}
                                </td>
                              );
                            } else if (column.type === "checkbox") {
                              /** add custom read only to disable component conditionally */
                              let customReadOnly;

                              if (column.dynamicReadOnly) {
                                const priceStatus = getDataIndex(
                                  column,
                                  index,
                                  "status"
                                );

                                customReadOnly = priceStatus === 3;
                              }
                              if (column.dynamicValue) {
                                const flag =
                                  column?.dataList?.[index]?.flag;
                                if (id && flag === "New") {
                                  state[name][index][
                                    column.name
                                  ] = true;
                                }
                              }
                              return !column.isRequired &&
                                restAccessCode &&
                                utils.access.isFieldHidden(
                                  fieldAccesses,
                                  restAccessCode +
                                    "." +
                                    restAccessCodeNamespace +
                                    "." +
                                    column.name
                                ) ? (
                                <></>
                              ) : (
                                <td
                                  key={"cell_" + j}
                                  className="border-0"
                                  style={{
                                    padding: "10px",
                                    width: column.width
                                      ? column.width + "px"
                                      : "250px",
                                    textAlign: column.center
                                      ? "center"
                                      : "left",
                                    display: column.hideColumnByCSS
                                      ? "none"
                                      : undefined,
                                  }}
                                >
                                  <CCheckBox
                                    name={column.name}
                                    readOnly={
                                      isReadOnly ||
                                      column.isDisabled ||
                                      column.readOnly
                                    }
                                    disabled={
                                      isReadOnly ||
                                      column.readOnly ||
                                      customReadOnly ||
                                      column.isDisabled
                                    }
                                    state={state[name][index]}
                                    onChange={() => {
                                      setState((prevState) => ({
                                        ...prevState,
                                        [name]: [...state[name]].map(
                                          (object, i) => {
                                            if (i === index) {
                                              return {
                                                ...object,
                                                [column.name]:
                                                  !state[name][index][
                                                    column.name
                                                  ],
                                              };
                                            } else {
                                              return object;
                                            }
                                          }
                                        ),
                                      }));
                                    }}
                                  />
                                  {column.helperText &&
                                  !isReadOnly ? (
                                    <div
                                      style={{
                                        marginTop: "5px",
                                        fontSize: "12px",
                                        color: "#aaa",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontStyle: "italic",
                                        }}
                                      >
                                        {column.helperText}
                                      </span>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                  {isReadOnly ? (
                                    <div
                                      style={{
                                        marginTop: "5px",
                                        fontSize: "12px",
                                        color: "#aaa",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontStyle: "italic",
                                        }}
                                      >
                                        Read Only
                                      </span>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                  {state[name][index][
                                    column.name + "Error"
                                  ] ? (
                                    <span style={{ color: "red" }}>
                                      {
                                        state[name][index][
                                          column.name + "Error"
                                        ]
                                      }
                                    </span>
                                  ) : (
                                    <></>
                                  )}
                                </td>
                              );
                            } else if (column.type === "textarea") {
                              return !column.isRequired &&
                                restAccessCode &&
                                utils.access.isFieldHidden(
                                  fieldAccesses,
                                  restAccessCode +
                                    "." +
                                    restAccessCodeNamespace +
                                    "." +
                                    column.name
                                ) ? (
                                <></>
                              ) : (
                                <td
                                  key={"cell_" + j}
                                  className="border-0"
                                  style={{
                                    padding: "10px",
                                    width: column.width
                                      ? column.width + "px"
                                      : "250px",
                                    textAlign: column.center
                                      ? "center"
                                      : "left",
                                    display: column.hideColumnByCSS
                                      ? "none"
                                      : undefined,
                                  }}
                                >
                                  <CTextArea
                                    name={column.name}
                                    state={state[name][index]}
                                    readOnly={
                                      isReadOnly ||
                                      (column.disabledAtEdit &&
                                        (state[name][index][
                                          "flag"
                                        ] === "Editing" ||
                                          state[name][index][
                                            "flag"
                                          ] === "Deleted"))
                                    }
                                    maxLength={
                                      column.maxLength
                                        ? column.maxLength
                                        : 255
                                    }
                                    onChange={(e) => {
                                      const value = e.target.value;

                                      setState((prevState) => ({
                                        ...prevState,
                                        [name]: [...state[name]].map(
                                          (object, i) => {
                                            if (i === index) {
                                              return {
                                                ...object,
                                                [column.name]: value,
                                              };
                                            } else {
                                              return object;
                                            }
                                          }
                                        ),
                                      }));
                                    }}
                                  />
                                  {isReadOnly ? (
                                    <div
                                      style={{
                                        marginTop: "5px",
                                        fontSize: "12px",
                                        color: "#aaa",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontStyle: "italic",
                                        }}
                                      >
                                        Read Only
                                      </span>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                  {state[name][index][
                                    column.name + "Error"
                                  ] ? (
                                    <span style={{ color: "red" }}>
                                      {
                                        state[name][index][
                                          column.name + "Error"
                                        ]
                                      }
                                    </span>
                                  ) : (
                                    <></>
                                  )}
                                </td>
                              );
                            } else if (
                              column.type === "photo_upload" ||
                              column.type === "building_photo_upload"
                            ) {
                              /**
                               * If building.type === 'building_photo_upload'
                               * We need to check if the row.media_type:
                               * - MediaType.IMAGE => render CImageUpload
                               * - MediaType.Marzipano360 or MediaType.HLS => render link preview label
                               */
                              if (
                                column.type ===
                                "building_photo_upload"
                              ) {
                                // check if row.media_type is not MediaType.IMAGE
                                if (
                                  state[name][index].media_type &&
                                  state[name][index].media_type !==
                                    "IMAGE"
                                ) {
                                  // render link preview label
                                  return (
                                    <td
                                      key={"cell_" + j}
                                      className="border-0"
                                      style={{
                                        padding: "10px",
                                        width: column.width
                                          ? column.width + "px"
                                          : "250px",
                                        textAlign: column.center
                                          ? "center"
                                          : "left",
                                        display:
                                          column.hideColumnByCSS
                                            ? "none"
                                            : undefined,
                                      }}
                                    >
                                      <a
                                        href={
                                          state[name][index][
                                            column.name
                                          ]
                                        }
                                        target="_blank"
                                        rel="noreferrer"
                                        style={{
                                          cursor: "pointer",
                                        }}
                                      >
                                        <div>
                                          <img
                                            src={
                                              state[name][index]
                                                .thumbnail
                                            }
                                            alt="preview"
                                            style={{
                                              width: "100%",
                                              height: "auto",
                                            }}
                                          />
                                        </div>
                                      </a>
                                    </td>
                                  );
                                }
                              }
                              const stateTabId = state[column?.tabId];
                              const dependKeyHasValue = stateTabId
                                ? stateTabId[index][column?.dependKey]
                                : null;
                              if (dependKeyHasValue)
                                isReadOnly = true;

                              return !column.isRequired &&
                                restAccessCode &&
                                utils.access.isFieldHidden(
                                  fieldAccesses,
                                  restAccessCode +
                                    "." +
                                    restAccessCodeNamespace +
                                    "." +
                                    column.name
                                ) ? (
                                <></>
                              ) : (
                                <td
                                  valign="top"
                                  key={"cell_" + j}
                                  className="border-0"
                                  style={{
                                    padding: "10px",
                                    width: column.width
                                      ? column.width + "px"
                                      : "250px",
                                    textAlign: column.center
                                      ? "center"
                                      : "left",
                                    display: column.hideColumnByCSS
                                      ? "none"
                                      : undefined,
                                  }}
                                >
                                  <CImageUpload
                                    name={column.name}
                                    state={state[name][index]}
                                    readOnly={
                                      isReadOnly ||
                                      (column.disabledAtEdit &&
                                        (state[name][index][
                                          "flag"
                                        ] === "Editing" ||
                                          state[name][index][
                                            "flag"
                                          ] === "Deleted"))
                                    }
                                    isPdf={column.isPdf}
                                    alertFileSize={
                                      column.alertFileSize
                                    }
                                    alertAllowedExtension={
                                      column.alertAllowedExtension
                                    }
                                    maxSize={column.maxSize}
                                    onChange={(acceptedFiles) => {
                                      if (!column.useURL) {
                                        utils.form.getBase64(
                                          acceptedFiles,
                                          1,
                                          column.name,
                                          state[name],
                                          setState,
                                          (base64) => {
                                            setState((prevState) => ({
                                              ...prevState,
                                              [name]: [
                                                ...state[name],
                                              ].map((object, i) => {
                                                if (i === index) {
                                                  return {
                                                    ...object,
                                                    [column.name]:
                                                      base64,
                                                  };
                                                } else {
                                                  return object;
                                                }
                                              }),
                                            }));
                                          },
                                          name
                                        );
                                      } else {
                                        const formData =
                                          new FormData();
                                        formData.append(
                                          "file",
                                          acceptedFiles?.[0]
                                        );
                                        formData.append(
                                          "upload_path",
                                          column.uploadPath
                                        );

                                        utils.httpClient.postMultiPart(
                                          configs.apiUrl +
                                            column.restPath +
                                            "/",
                                          formData,
                                          column.contentType,
                                          (result) => {
                                            setState((prevState) => ({
                                              ...prevState,
                                              [name]: [
                                                ...state[name],
                                              ].map((object, i) => {
                                                if (i === index) {
                                                  return {
                                                    ...object,
                                                    [column.name]:
                                                      result.url,
                                                  };
                                                } else {
                                                  return object;
                                                }
                                              }),
                                            }));
                                          },
                                          (error, message) => {
                                            alert(error);
                                          }
                                        );
                                      }
                                    }}
                                  />
                                  {isReadOnly ? (
                                    <div
                                      style={{
                                        marginTop: "5px",
                                        fontSize: "12px",
                                        color: "#aaa",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontStyle: "italic",
                                        }}
                                      >
                                        Read Only
                                      </span>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                  {state[name][index][
                                    column.name + "Error"
                                  ] ? (
                                    <span style={{ color: "red" }}>
                                      {
                                        state[name][index][
                                          column.name + "Error"
                                        ]
                                      }
                                    </span>
                                  ) : (
                                    <></>
                                  )}
                                </td>
                              );
                            } else if (column.type === "read_only") {
                              return !column.isRequired &&
                                restAccessCode &&
                                utils.access.isFieldHidden(
                                  fieldAccesses,
                                  restAccessCode +
                                    "." +
                                    restAccessCodeNamespace +
                                    "." +
                                    column.name
                                ) ? (
                                <></>
                              ) : (
                                <td
                                  key={"cell_" + j}
                                  className="border-0"
                                  style={{
                                    padding: "10px",
                                    width: column.width
                                      ? column.width + "px"
                                      : "250px",
                                    textAlign: column.center
                                      ? "center"
                                      : "left",
                                    display: column.hideColumnByCSS
                                      ? "none"
                                      : undefined,
                                  }}
                                >
                                  {state[name][index][column.name] !==
                                  undefined
                                    ? utils.commons.isFunction(
                                        column.renderer
                                      )
                                      ? column.renderer(
                                          state[name][index][
                                            column.name
                                          ],
                                          {
                                            rowData:
                                              state[name][index],
                                          }
                                        )
                                      : state[name][index][
                                          column.name
                                        ]
                                    : "---"}
                                </td>
                              );
                            } else if (
                              column.type === "read_only_image"
                            ) {
                              return !column.isRequired &&
                                restAccessCode &&
                                utils.access.isFieldHidden(
                                  fieldAccesses,
                                  restAccessCode +
                                    "." +
                                    restAccessCodeNamespace +
                                    "." +
                                    column.name
                                ) ? (
                                <></>
                              ) : (
                                <td
                                  key={"cell_" + j}
                                  className="border-0"
                                  style={{
                                    padding: "10px",
                                    width: column.width
                                      ? column.width + "px"
                                      : "250px",
                                    textAlign: column.center
                                      ? "center"
                                      : "left",
                                    display: column.hideColumnByCSS
                                      ? "none"
                                      : undefined,
                                  }}
                                >
                                  <img
                                    style={{ width: "150px" }}
                                    src={
                                      state[name][index][column.name]
                                    }
                                    alt="image"
                                  />
                                </td>
                              );
                            } else if (
                              column.type === "multi_checkbox"
                            ) {
                              return !column.isRequired &&
                                restAccessCode &&
                                utils.access.isFieldHidden(
                                  fieldAccesses,
                                  restAccessCode +
                                    "." +
                                    restAccessCodeNamespace +
                                    "." +
                                    column.name
                                ) ? (
                                <></>
                              ) : (
                                <td
                                  key={"cell_" + j}
                                  className="border-0"
                                  style={{
                                    padding: "10px",
                                    width: column.width
                                      ? column.width + "px"
                                      : "250px",
                                    textAlign: column.center
                                      ? "center"
                                      : "left",
                                    display: column.hideColumnByCSS
                                      ? "none"
                                      : undefined,
                                  }}
                                >
                                  <CMultiCheckBox
                                    name={column.name}
                                    state={state[name][index]}
                                    disabled={column.disabled}
                                    indexData={index}
                                    data={column.data}
                                    onChange={(values) => {
                                      setState((prevState) => ({
                                        ...prevState,
                                        [name]: [...state[name]].map(
                                          (object, i) => {
                                            if (i === index) {
                                              return {
                                                ...object,
                                                [column.name]: values,
                                              };
                                            } else {
                                              return object;
                                            }
                                          }
                                        ),
                                      }));
                                    }}
                                  />
                                  {state[name][index][
                                    column.name + "Error"
                                  ] ? (
                                    <span style={{ color: "red" }}>
                                      {
                                        state[name][index][
                                          column.name + "Error"
                                        ]
                                      }
                                    </span>
                                  ) : (
                                    <></>
                                  )}
                                </td>
                              );
                            } else if (column.type === "custom") {
                              /** Check field by addon category */
                              const addonCatg = getAddonCatg(
                                column,
                                index
                              );

                              return !column.isRequired &&
                                restAccessCode &&
                                utils.access.isFieldHidden(
                                  fieldAccesses,
                                  restAccessCode +
                                    "." +
                                    restAccessCodeNamespace +
                                    "." +
                                    column.name
                                ) ? (
                                <></>
                              ) : addonCatg
                                  ?.toLowerCase()
                                  ?.includes("parking") &&
                                !selectedAsync[index]
                                  ?.toLowerCase()
                                  .includes("bicycle") ? (
                                <td
                                  key={"cell_" + j}
                                  className="border-0"
                                  style={{
                                    padding: "10px",
                                    width: column.width
                                      ? column.width + "px"
                                      : "250px",
                                    textAlign: column.center
                                      ? "center"
                                      : "left",
                                    display: column.hideColumnByCSS
                                      ? "none"
                                      : undefined,
                                  }}
                                >
                                  <CTextField
                                    name={column.name}
                                    state={state[name][index]}
                                    readOnly={isReadOnly}
                                    isParkingAddon={true}
                                    maxLength={15}
                                    defaultValue={"cth: B 1234 ABC"}
                                    onChange={(e) => {
                                      const value = e.target.value;

                                      setState((prevState) => ({
                                        ...prevState,
                                        [name]: [...state[name]].map(
                                          (object, i) => {
                                            if (i === index) {
                                              return {
                                                ...object,
                                                [column.name]: value,
                                              };
                                            } else {
                                              return object;
                                            }
                                          }
                                        ),
                                      }));
                                    }}
                                  />
                                </td>
                              ) : (
                                <td
                                  key={"cell_" + j}
                                  className="border-0"
                                  style={{
                                    padding: "10px",
                                    width: column.width
                                      ? column.width + "px"
                                      : "250px",
                                    textAlign: column.center
                                      ? "center"
                                      : "left",
                                    display: column.hideColumnByCSS
                                      ? "none"
                                      : undefined,
                                  }}
                                >
                                  <CTextArea
                                    name={column.name}
                                    state={state[name][index]}
                                    readOnly={isReadOnly}
                                    maxLength={
                                      column.maxLength
                                        ? column.maxLength
                                        : 255
                                    }
                                    onChange={(e) => {
                                      const value = e.target.value;

                                      setState((prevState) => ({
                                        ...prevState,
                                        [name]: [...state[name]].map(
                                          (object, i) => {
                                            if (i === index) {
                                              return {
                                                ...object,
                                                [column.name]: value,
                                              };
                                            } else {
                                              return object;
                                            }
                                          }
                                        ),
                                      }));
                                    }}
                                  />
                                </td>
                              );
                            } else if (
                              column.type === "dynamicDateMonthPicker"
                            ) {
                              const isRenderMonthPicker =
                                column.isRenderMonthPicker({ index });
                              let customMinDate = "";

                              if (column.dynamicMinDate) {
                                const startDate = getDataIndex(
                                  column,
                                  index,
                                  "start_date"
                                );
                                if (
                                  state[name][index]["flag"] === "New"
                                ) {
                                  customMinDate = moment(startDate)
                                    .add(1, "day")
                                    .format("YYYY-MM-DD");
                                } else {
                                  customMinDate = moment(
                                    state[name][index]["end_date"]
                                  ).format("YYYY-MM-DD");
                                }
                              }
                              let customReadOnly;

                              if (column.dynamicReadOnly) {
                                const startDate = getDataIndex(
                                  column,
                                  index,
                                  "start_date"
                                );
                                customReadOnly = !startDate && true;
                              }

                              let adjustedMinDate = "";
                              if (column.adjustMinDate) {
                                const flag =
                                  state[name][index]["flag"];
                                if (flag !== "New") {
                                  adjustedMinDate = moment(
                                    state[name][index][column.name]
                                  ).format("YYYY-MM-DD");
                                } else {
                                  adjustedMinDate = column.minDate;
                                }
                              }

                              return !column.isRequired &&
                                restAccessCode &&
                                utils.access.isFieldHidden(
                                  fieldAccesses,
                                  restAccessCode +
                                    "." +
                                    restAccessCodeNamespace +
                                    "." +
                                    column.name
                                ) ? (
                                <></>
                              ) : (
                                <td
                                  key={"cell_" + j}
                                  className="border-0"
                                  style={{
                                    padding: "10px",
                                    width: column.width
                                      ? column.width + "px"
                                      : "250px",
                                    textAlign: column.center
                                      ? "center"
                                      : "left",
                                    display: column.hideColumnByCSS
                                      ? "none"
                                      : undefined,
                                  }}
                                >
                                  {isRenderMonthPicker ? (
                                    //component below will render monthpicker
                                    <CDateTimePickerV3
                                      id={index}
                                      name={column.name}
                                      state={state[name][index]}
                                      disabled={
                                        isReadOnly ||
                                        customReadOnly ||
                                        (utils.commons.isFunction(
                                          column.generateReadOnly
                                        ) &&
                                          column.generateReadOnly({
                                            index,
                                            column,
                                          }))
                                      }
                                      excludedDateRange={
                                        utils.commons.isFunction(
                                          column.excludedDateRange
                                        )
                                          ? column.excludedDateRange({
                                              index,
                                            })
                                          : column.excludedDateRange
                                      }
                                      type={column.type}
                                      minDate={
                                        utils.commons.isFunction(
                                          column.getDisabledDate
                                        )
                                          ? column.getDisabledDate({
                                              index,
                                              type: "minDate",
                                              column,
                                            })
                                          : column.minDate
                                      }
                                      maxDate={
                                        utils.commons.isFunction(
                                          column.getDisabledDate
                                        )
                                          ? column.getDisabledDate({
                                              index,
                                              type: "maxDate",
                                              column,
                                            })
                                          : column.maxDate
                                      }
                                      onChange={
                                        utils.commons.isFunction(
                                          column.handleOnChangeMonthPicker
                                        )
                                          ? (dataValue) =>
                                              column.handleOnChangeMonthPicker(
                                                {
                                                  dataValue,
                                                  column,
                                                  index,
                                                }
                                              )
                                          : (dataValue) => {
                                              let result = dataValue;
                                              setState(
                                                (prevState) => ({
                                                  ...prevState,
                                                  [name]: [
                                                    ...state[name],
                                                  ].map(
                                                    (object, i) => {
                                                      if (
                                                        i === index
                                                      ) {
                                                        return {
                                                          ...object,
                                                          [column.name]:
                                                            result ??
                                                            null,
                                                        };
                                                      } else {
                                                        return object;
                                                      }
                                                    }
                                                  ),
                                                })
                                              );
                                            }
                                      }
                                    />
                                  ) : (
                                    <CDateTimePicker
                                      name={column.name}
                                      state={state[name][index]}
                                      data={column.data}
                                      isClearable={column.isClearable}
                                      dateFormat={column.dateFormat}
                                      readOnly={
                                        isReadOnly ||
                                        customReadOnly ||
                                        column.isReadOnly ||
                                        (utils.commons.isFunction(
                                          column.generateReadOnly
                                        ) &&
                                          column.generateReadOnly({
                                            index,
                                            column,
                                          }))
                                      }
                                      type={column.type}
                                      enableTime={column.enableTime}
                                      minDate={
                                        column.dynamicMinDate
                                          ? customMinDate
                                          : utils.commons.isFunction(
                                              column.getDisabledDate
                                            )
                                          ? column.getDisabledDate({
                                              index,
                                              type: "minDate",
                                              column,
                                            })
                                          : column.dateRanges
                                          ? column.dateRanges
                                              ?.availableRange?.[
                                              index
                                            ]?.start_date
                                          : column.adjustMinDate
                                          ? adjustedMinDate
                                          : column.minDate
                                      }
                                      maxDate={
                                        utils.commons.isFunction(
                                          column.getDisabledDate
                                        )
                                          ? column.getDisabledDate({
                                              index,
                                              type: "maxDate",
                                              column,
                                            })
                                          : column.dateRanges
                                          ? column.dateRanges
                                              ?.availableRange?.[
                                              index
                                            ]?.end_date
                                          : column.maxDate
                                      }
                                      isDisabled={
                                        column.disabledAtEdit &&
                                        (state[name][index][
                                          "flag"
                                        ] === "Editing" ||
                                          state[name][index][
                                            "flag"
                                          ] === "Deleted")
                                          ? true
                                          : column.isDisabled
                                          ? column.isDisabled
                                          : false
                                      }
                                      disabledDate={
                                        utils.commons.isFunction(
                                          column.getDisabledDate
                                        )
                                          ? column.getDisabledDate({
                                              index,
                                            })
                                          : column.dateRanges
                                              ?.selectedDate
                                      }
                                      onClear={() => {
                                        const tempCarryOverState = [
                                          ...state[name],
                                        ].map((object, i) => {
                                          if (i === index) {
                                            return {
                                              ...object,
                                              [column.name]: null,
                                            };
                                          } else {
                                            return object;
                                          }
                                        });
                                        setState((prevState) => ({
                                          ...prevState,
                                          [name]: tempCarryOverState,
                                        }));
                                        if (
                                          utils.commons.isFunction(
                                            column.afterClearDate
                                          )
                                        ) {
                                          column.afterClearDate({
                                            index,
                                            tempCarryOverState,
                                            column,
                                          });
                                        }
                                      }}
                                      onChange={(dataValue) => {
                                        let result = moment(
                                          dataValue[0].toISOString()
                                        ).format("YYYY-MM-DD");

                                        const tempState = [
                                          ...state[name],
                                        ].map((object, i) => {
                                          if (i === index) {
                                            return {
                                              ...object,
                                              [column.name]: result
                                                ? result
                                                : null,
                                            };
                                          } else {
                                            return object;
                                          }
                                        });

                                        setState((prevState) => ({
                                          ...prevState,
                                          [name]: [...tempState],
                                        }));

                                        if (
                                          utils.commons.isFunction(
                                            column.afterChange
                                          )
                                        ) {
                                          column.afterChange({
                                            dataValue,
                                            carryOverState: tempState,
                                            index,
                                          });
                                        }
                                      }}
                                    />
                                  )}
                                  {isReadOnly ? (
                                    <div
                                      style={{
                                        marginTop: "5px",
                                        fontSize: "12px",
                                        color: "#aaa",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontStyle: "italic",
                                        }}
                                      >
                                        Read Only
                                      </span>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                  {state[name][index][
                                    column.name + "Error"
                                  ] ? (
                                    <span style={{ color: "red" }}>
                                      {
                                        state[name][index][
                                          column.name + "Error"
                                        ]
                                      }
                                    </span>
                                  ) : (
                                    <></>
                                  )}
                                </td>
                              );
                            }
                          })}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
          {!readOnly ? (
            <div style={{ padding: "10px" }}>
              {!(
                maxRow &&
                state[name] &&
                state[name].length >= maxRow
              ) &&
              ((user && user["is_super_user"]) ||
                (user &&
                  user["has_team_management_access"] &&
                  definitions &&
                  definitions.isTeamMenu) ||
                !restAccessCodeNamespace ||
                (accesses &&
                  accesses.includes(
                    restAccessCode +
                      "." +
                      restAccessCodeNamespace +
                      ".create"
                  ))) &&
              isEligibleForDiscount ? (
                <button
                  type="button"
                  disabled={disableActionBtn}
                  className="btn btn-primary waves-effect waves-light"
                  onClick={() => {
                    let dataCloned = [];
                    if (!useNewClone) {
                      dataCloned = JSON.parse(
                        JSON.stringify(state[name])
                      );
                    } else {
                      dataCloned = [...state[name]];
                    }

                    let newRow = { flag: "New" };
                    if (
                      utils.commons.isObject(
                        additionalStateInAddNewRow
                      )
                    ) {
                      newRow = {
                        ...newRow,
                        ...additionalStateInAddNewRow,
                      };
                    }

                    for (let i = 0; i < columns.length; i++) {
                      if (columns[i].default) {
                        newRow[columns[i].name] = columns[i].default;
                      }
                    }

                    dataCloned.push(newRow);

                    setState((prevState) => ({
                      ...prevState,
                      [name]: dataCloned,
                    }));
                  }}
                >
                  <i className="fas fa-plus" /> Add New
                </button>
              ) : (
                <></>
              )}
              {id !== undefined &&
              ((user && user["is_super_user"]) ||
                !restAccessCodeNamespace ||
                (accesses &&
                  accesses.includes(
                    restAccessCode +
                      "." +
                      restAccessCodeNamespace +
                      ".update"
                  )) ||
                (user && user["is_super_user"]) ||
                !restAccessCodeNamespace ||
                (accesses &&
                  accesses.includes(
                    restAccessCode +
                      "." +
                      restAccessCodeNamespace +
                      ".create"
                  )) ||
                (user && user["is_super_user"]) ||
                !restAccessCodeNamespace ||
                (accesses &&
                  accesses.includes(
                    restAccessCode +
                      "." +
                      restAccessCodeNamespace +
                      ".destroy"
                  )) ||
                (user &&
                  user["has_team_management_access"] &&
                  definitions &&
                  definitions.isTeamMenu)) &&
              !saveOnlyFromMainForm ? (
                <button
                  type="button"
                  className="btn btn-success waves-effect waves-light float-right"
                  onClick={() => {
                    if (
                      state[name] &&
                      state[name].length <= 0 &&
                      !maxRow
                    ) {
                      setState((prevState) => ({
                        ...prevState,
                        [name + "ErrorMessage"]:
                          "Please make sure at least one record exists before saving.",
                      }));
                    } else if (
                      maxRow &&
                      state[name]?.length !== maxRow
                    ) {
                      setState((prevState) => ({
                        ...prevState,
                        [name +
                        "ErrorMessage"]: `Please select ${maxRow} ${rowIdentifier}s.`,
                      }));
                    } else {
                      const deleted = [];
                      const editing = [];
                      const additions = [];

                      let dataCloned = JSON.parse(
                        JSON.stringify(state[name])
                      );

                      for (let i = 0; i < state[name].length; i++) {
                        for (let j = 0; j < columns.length; j++) {
                          if (
                            columns[j].isRequired &&
                            utils.form.isEmpty(
                              state[name][i][columns[j].name]
                            )
                          ) {
                            setState((prevState) => ({
                              ...prevState,
                              [name + "ErrorMessage"]:
                                "There are error(s) in form, please fix them first!",
                            }));

                            dataCloned[i][columns[j].name + "Error"] =
                              "This field is required";
                          } else {
                            delete dataCloned[i][
                              columns[j].name + "Error"
                            ];
                          }
                        }
                      }

                      if (
                        enableMinRowValidation &&
                        dataTypeValidation
                      ) {
                        const dataWithoutDeletion = dataCloned.filter(
                          (item) => {
                            return item.flag !== "Deleted";
                          }
                        );

                        if (dataWithoutDeletion.length < minRow) {
                          setState((prevState) => ({
                            ...prevState,
                            [name +
                            "ErrorMessage"]: `Please make sure at least ${minRow} ${dataTypeValidation} are exists before saving`,
                          }));
                          return;
                        }
                      }

                      let hasError = false;
                      for (let i = 0; i < state[name].length; i++) {
                        for (let j = 0; j < columns.length; j++) {
                          if (
                            dataCloned[i][columns[j].name + "Error"]
                          ) {
                            hasError = true;

                            break;
                          }
                        }
                      }

                      if (hasError) {
                        setState((prevState) => ({
                          ...prevState,
                          [name]: dataCloned,
                        }));

                        window.scrollTo({
                          top: 0,
                          left: 0,
                          behavior: "smooth",
                        });
                      } else {
                        if (!hasError) {
                          for (
                            let i = 0;
                            i < state[name].length;
                            i++
                          ) {
                            if (state[name][i].flag === "Deleted") {
                              deleted.push(state[name][i].id);
                            } else if (
                              state[name][i].flag === "Editing"
                            ) {
                              for (
                                let j = 0;
                                j < photoFields.length;
                                j++
                              ) {
                                if (
                                  state[name][i][photoFields[j]] &&
                                  state[name][i][
                                    photoFields[j]
                                  ].startsWith("http")
                                ) {
                                  delete state[name][i][
                                    photoFields[j]
                                  ];
                                }
                              }

                              editing.push(state[name][i]);
                            } else if (
                              state[name][i].flag === "New"
                            ) {
                              state[name][i][
                                relationKey.replace("_id", "")
                              ] = id;
                              additions.push(state[name][i]);
                            }
                          }

                          const sweetAlert = withReactContent(Swal);

                          sweetAlert.fire({
                            title: "Saving data",
                            html: (
                              <img
                                style={{
                                  width: "125px",
                                  height: "125px",
                                }}
                                alt="loading..."
                                src={loading}
                              />
                            ),
                            showConfirmButton: false,
                            allowOutsideClick: false,
                          });

                          for (let i = 0; i < editing.length; i++) {
                            for (
                              let j = 0;
                              j < excludedFieldsOnSubmit.length;
                              j++
                            ) {
                              if (
                                typeof editing[i][
                                  excludedFieldsOnSubmit[j]
                                ] === "object"
                              ) {
                                delete editing[i][
                                  excludedFieldsOnSubmit[j]
                                ];
                              }
                            }
                          }

                          for (let i = 0; i < additions.length; i++) {
                            for (
                              let j = 0;
                              j < excludedFieldsOnSubmit.length;
                              j++
                            ) {
                              if (
                                typeof additions[i][
                                  excludedFieldsOnSubmit[j]
                                ] === "object"
                              ) {
                                delete additions[i][
                                  excludedFieldsOnSubmit[j]
                                ];
                              }
                            }
                          }

                          let responseCount = 0;
                          let updateResponseSuccess = false;
                          let createResponseSuccess = false;
                          let destroyResponseSuccess = false;
                          let errorMessages = [];
                          let nonFieldErrors = [];

                          let dataCloned = JSON.parse(
                            JSON.stringify(state[name])
                          );

                          if (
                            ((user && user["is_super_user"]) ||
                              !restAccessCodeNamespace ||
                              (accesses &&
                                accesses.includes(
                                  restAccessCode +
                                    "." +
                                    restAccessCodeNamespace +
                                    ".update"
                                )) ||
                              (user &&
                                user["has_team_management_access"] &&
                                definitions &&
                                definitions.isTeamMenu)) &&
                            editing.length > 0
                          ) {
                            utils.httpClient.patch(
                              configs.apiUrl +
                                restPath +
                                "*/" +
                                "?" +
                                paramRestAccessCode +
                                "&" +
                                paramObjectId,
                              editing,
                              () => {
                                responseCount++;

                                updateResponseSuccess = true;
                              },
                              (e) => {
                                let errors = utils.httpClient
                                  .getErrorMessage(e)
                                  .split("\n")[0];

                                if (utils.commons.isJSON(errors)) {
                                  let errorsJSON = JSON.parse(
                                    errors.replaceAll("'", '"')
                                  );

                                  let itemRow = 0;

                                  if (
                                    utils.commons.isArray(errorsJSON)
                                  ) {
                                    for (
                                      let i = 0;
                                      i < errorsJSON.length;
                                      i++
                                    ) {
                                      for (let keyItem in errorsJSON[
                                        i
                                      ]) {
                                        if (
                                          keyItem ===
                                          "non_field_errors"
                                        ) {
                                          nonFieldErrors.push(
                                            errorsJSON[i][keyItem]
                                          );
                                        }
                                      }
                                    }
                                  }

                                  for (
                                    let i = 0;
                                    i < dataCloned.length;
                                    i++
                                  ) {
                                    if (
                                      dataCloned[i].flag === "Editing"
                                    ) {
                                      for (let keyItem in dataCloned[
                                        i
                                      ]) {
                                        delete dataCloned[i][
                                          keyItem + "Error"
                                        ];
                                      }

                                      if (
                                        typeof errorsJSON[itemRow] !==
                                          "undefined" &&
                                        errorsJSON[itemRow] !== null
                                      ) {
                                        for (let keyItem in errorsJSON[
                                          itemRow
                                        ]) {
                                          dataCloned[i][
                                            keyItem + "Error"
                                          ] =
                                            errorsJSON[itemRow][
                                              keyItem
                                            ];
                                        }
                                      }

                                      itemRow++;
                                    }
                                  }

                                  window.scrollTo({
                                    top: 0,
                                    left: 0,
                                    behavior: "smooth",
                                  });
                                } else {
                                  errorMessages.push(errors);
                                }

                                responseCount++;
                              }
                            );
                          } else {
                            updateResponseSuccess = true;
                            responseCount++;
                          }

                          if (
                            ((user && user["is_super_user"]) ||
                              !restAccessCodeNamespace ||
                              (accesses &&
                                accesses.includes(
                                  restAccessCode +
                                    "." +
                                    restAccessCodeNamespace +
                                    ".create"
                                )) ||
                              (user &&
                                user["has_team_management_access"] &&
                                definitions &&
                                definitions.isTeamMenu)) &&
                            additions.length > 0
                          ) {
                            utils.httpClient.post(
                              configs.apiUrl +
                                restPath +
                                "?" +
                                paramRestAccessCode +
                                "&" +
                                paramObjectId,
                              additions,
                              () => {
                                responseCount++;

                                createResponseSuccess = true;
                              },
                              (e) => {
                                let errors = utils.httpClient
                                  .getErrorMessage(e)
                                  .split("\n")[0];

                                if (utils.commons.isJSON(errors)) {
                                  let errorsJSON = JSON.parse(errors);

                                  if (
                                    utils.commons.isArray(errorsJSON)
                                  ) {
                                    for (
                                      let i = 0;
                                      i < errorsJSON.length;
                                      i++
                                    ) {
                                      for (let keyItem in errorsJSON[
                                        i
                                      ]) {
                                        if (
                                          keyItem ===
                                          "non_field_errors"
                                        ) {
                                          nonFieldErrors.push(
                                            errorsJSON[i][keyItem]
                                          );
                                        }
                                      }
                                    }
                                  }

                                  let itemRow = 0;
                                  for (
                                    let i = 0;
                                    i < dataCloned.length;
                                    i++
                                  ) {
                                    if (
                                      dataCloned[i].flag === "New"
                                    ) {
                                      for (let keyItem in dataCloned[
                                        i
                                      ]) {
                                        delete dataCloned[i][
                                          keyItem + "Error"
                                        ];
                                      }

                                      if (
                                        typeof errorsJSON[itemRow] !==
                                          "undefined" &&
                                        errorsJSON[itemRow] !== null
                                      ) {
                                        for (let keyItem in errorsJSON[
                                          itemRow
                                        ]) {
                                          dataCloned[i][
                                            keyItem + "Error"
                                          ] =
                                            errorsJSON[itemRow][
                                              keyItem
                                            ];
                                        }
                                      }

                                      itemRow++;
                                    }
                                  }

                                  window.scrollTo({
                                    top: 0,
                                    left: 0,
                                    behavior: "smooth",
                                  });
                                } else {
                                  errorMessages.push(errors);
                                }

                                responseCount++;
                              }
                            );
                          } else {
                            createResponseSuccess = true;
                            responseCount++;
                          }

                          if (
                            ((user && user["is_super_user"]) ||
                              !restAccessCodeNamespace ||
                              (accesses &&
                                accesses.includes(
                                  restAccessCode +
                                    "." +
                                    restAccessCodeNamespace +
                                    ".destroy"
                                )) ||
                              (user &&
                                user["has_team_management_access"] &&
                                definitions &&
                                definitions.isTeamMenu)) &&
                            deleted.length > 0
                          ) {
                            utils.httpClient.delete(
                              configs.apiUrl +
                                restPath +
                                "*/" +
                                "?" +
                                paramRestAccessCode +
                                "&" +
                                paramObjectId,
                              deleted,
                              () => {
                                responseCount++;

                                destroyResponseSuccess = true;
                              },
                              (e) => {
                                errorMessages.push(
                                  utils.httpClient
                                    .getErrorMessage(e)
                                    .split("\n")[0]
                                );

                                responseCount++;
                              }
                            );
                          } else {
                            destroyResponseSuccess = true;
                            responseCount++;
                          }

                          let intervalID = setInterval(() => {
                            if (responseCount === 3) {
                              clearInterval(intervalID);

                              sweetAlert.close();

                              if (
                                updateResponseSuccess &&
                                createResponseSuccess &&
                                destroyResponseSuccess
                              ) {
                                sweetAlert
                                  .fire(
                                    "Saved",
                                    "Record has been updated successfully.",
                                    "success"
                                  )
                                  .then(() => {
                                    setState((prevState) => ({
                                      ...prevState,
                                      [name + "HasBeenSaved"]: true,
                                    }));

                                    load();
                                  });
                              } else {
                                setState((prevState) => ({
                                  ...prevState,
                                  [name]: dataCloned,
                                  [name + "ErrorMessage"]:
                                    nonFieldErrors.length === 0
                                      ? "There are error(s) in form, please fix them first!<br/>"
                                      : "<b>Error(s)</b>: " +
                                        (nonFieldErrors.length > 1
                                          ? "<br/>"
                                          : "") +
                                        nonFieldErrors.join("<br/>"),
                                  [name + "HasBeenSaved"]: false,
                                }));

                                if (errorMessages.length > 0) {
                                  let message = "Error in: ";

                                  const errorList = [];
                                  if (!updateResponseSuccess) {
                                    errorList.push("UPDATE");
                                  }

                                  if (!createResponseSuccess) {
                                    errorList.push("CREATE");
                                  }

                                  if (!destroyResponseSuccess) {
                                    errorList.push("DELETE");
                                  }

                                  sweetAlert.fire(
                                    "Error",
                                    message +
                                      errorList.join(", ") +
                                      "<br/><br/>" +
                                      errorMessages.join(", "),
                                    "error"
                                  );
                                }
                              }
                            }
                          }, 1000);
                        }
                      }
                    }
                  }}
                >
                  <i className="fas fa-save" /> Save
                </button>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}

export default withRouter(COneOrManyToManyField);
