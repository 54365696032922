import { useState } from "react";

import { useParams, withRouter } from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import Form from "../../../../components/ubold/organisms/Form";

import CVSimpleManyToMany from "../../../../components/ubold/molecules/forms/CVSimpleManyToMany";

import definitions from "./definitions";
import CVTextView from "../../../../components/ubold/molecules/forms/CVTextView";

import utils from "../../../../utils";

function CAddEditPage() {
  let { id } = useParams<{ id: string }>();

  const initialState = {};

  const [state, setState] = useState(initialState);

  return (
    <Page
      title={
        (id === undefined ? "Add New" : "Edit") +
        " " +
        definitions.title
      }
      restAccessCode={definitions.restAccessCode}
    >
      <Form
        id={id}
        definitions={definitions}
        state={state}
        setState={setState}
        readOnly
      >
        <CVSimpleManyToMany
          readOnly
          name="priority_building"
          title="Priority Building"
          overrideLayout="12"
          data="building/building?__type__=select_entries"
          itemsExtractor={(row: {
            id: string;
            building_name: string;
          }) => {
            return {
              label: row.building_name,
              value: row.id,
            };
          }}
        />
        <CVSimpleManyToMany
          readOnly
          name="featured_building"
          title="Featured Building"
          overrideLayout="12"
          data="building/building?__type__=select_entries"
          itemsExtractor={(row: {
            id: string;
            building_name: string;
          }) => {
            return {
              label: row.building_name,
              value: row.id,
            };
          }}
        />
        <CVTextView
          name="date_created"
          title="Date Created"
          renderer={(value: string) => {
            return utils.formatter.dateTime(value);
          }}
        />
        <CVTextView
          name="date_updated"
          title="Date Updated"
          renderer={(value: string) => {
            return utils.formatter.dateTime(value);
          }}
        />
      </Form>
    </Page>
  );
}

export default withRouter(CAddEditPage);
