import { useState, useEffect } from "react";

import { useParams, withRouter } from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import TabHeaders from "../../../../components/ubold/organisms/TabHeaders";
import TabContent from "../../../../components/ubold/organisms/TabContent";
import TabbedForm from "../../../../components/ubold/organisms/TabbedForm";

import RoomPhotos from "./Tabs/RoomPhotos";
import RoomFacilities from "./Tabs/RoomFacilities";
import RoomFeatures from "./Tabs/RoomFeatures";
import Room from "./Tabs/Room";

import definitions from "./definitions";
import RoomServiceSchedule from "./Tabs/RoomServiceSchedule";
import utils from "utils";
import configs from "../../../../configs";

function RoomAddEditPage() {
  let { id } = useParams();

  const initialState = {
    is_available: true,
    price: 0,
    electricity: 1,
    bathroom: 1,
    gender: 0,
    room_status: 10,
    t_room_service_schedule: [
      {
        laundry_schedule: [],
        cleaning_schedule: [],
      },
    ],
  };

  const [state, setState] = useState(initialState);
  const expectedRoomStatusWithMT = [12, 13];

  // update logic ngeclear date_started cukup ngebaca return room status kalau vacant ready
  useEffect(() => {
    if (state?.original___room_status === 10) {
      setState((prev) => ({
        ...prev,
        date_started_maintenance: null,
      }));
    }
  }, [state?.original___room_status]);

  useEffect(() => {
    if (!expectedRoomStatusWithMT.includes(state["room_status"])) {
      setState((prevState) => ({
        ...prevState,
        maintenance_status: 0,
      }));
    }
  }, [state["room_status"]]);

  // Get building data
  useEffect(() => {
    if (state.building) {
      utils.httpClient.get(
        configs.apiUrl +
          `building/building/${state.building}/?__type__=edit
`,
        (data) => {
          console.log("building data", data);
          setState((prevState) => ({
            ...prevState,
            publish_status: data.publish_status,
          }));
        }
      );
    }
  }, [state.building]);

  return (
    <Page
      linkHistory={
        id === undefined ? null : definitions.linkHistory(id)
      }
      title={
        (id === undefined ? "Add New" : "Edit") +
        " " +
        definitions.title
      }
      restAccessCode={definitions.restAccessCode}
    >
      <TabbedForm
        id={id}
        state={state}
        setState={setState}
        definitions={definitions}
      >
        <TabHeaders />
        <TabContent>
          <Room />
          <RoomPhotos />
          <RoomFacilities />
          <RoomFeatures />
          <RoomServiceSchedule />
        </TabContent>
      </TabbedForm>
    </Page>
  );
}

export default withRouter(RoomAddEditPage);
