import configBuilder from "../../../components/ubold/ConfigBuilder";

import secondaryDataHandler from "./SecondaryDataHandler";

export const otpPaymentMethod = [
  {
    label: "Cash",
    value: 1,
  },
  {
    label: "Virtual Account",
    value: 3,
  },
];

let definitions = {
  title: "Payments",
  frontendPath: "/admin/logs/onetimepurchase/payment",
  baseLink: "/one_time_purchase/payment",
  restAccessCode: "one_time_purchase.payment",

  __table__: {
    addButton: false,
    hideEditButton: true,
    hideRemoveButton: true,
    showDetailButton: true,
    filterColumn: 3,
    hasSecondaryData: true,
    isAddButtonDisabled: true,
    isHideRemoveButton: true,
    secondaryDataItem: 2,
    secondaryDataHandler: secondaryDataHandler,
    filters: [
      {
        id: "tenant_name",
        name: "Tenant",
        type: "text",
      },
      {
        id: "invoice_number",
        name: "Invoice",
        type: "text",
      },
      {
        id: "status",
        name: "Status",
        type: "select",
        data: [
          {
            label: "Proceed",
            value: 1,
          },
          {
            label: "Approved",
            value: 3,
          },
          {
            label: "Failed",
            value: 6,
          },
        ],
      },
      {
        id: "otp_payment_method",
        name: "Payment Method",
        type: "select",
        data: otpPaymentMethod,
      },
    ],

    columns: [
      {
        id: "tenant_text",
        name: "Tenant",
        enableHyperlink: true,
      },
      {
        id: "invoice_number",
        name: "Invoice",
      },
      {
        id: "status_text",
        name: "Status",
      },
      {
        id: "transaction_date",
        name: "Transaction Date",
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
