import React from "react";
import styled from "styled-components";

interface CBadgesProps {
  counter: number;
  name?: string;
  isTotal?: boolean;
}

const CBadge = (props: CBadgesProps) => {
  const { counter, name, isTotal } = props;
  const dataLength = counter as unknown;

  return dataLength && name ? (
    <Container>{dataLength}</Container>
  ) : isTotal && counter ? (
    <Container>{counter}</Container>
  ) : (
    <></>
  );
};

export default CBadge;

const Container = styled.div`
  height: 12px;
  width: 12px;
  background-color: #d00c1a;
  font-size: 8px;
  font-family: Rubik;
  font-weight: 800;
  text-align: center;
  color: #ffffff;
  border-radius: 50%;
`;
