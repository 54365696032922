import { BuildingRoom } from "@types";
import axios from "axios";
import config from "configs";
import utils from "utils";

export type GetRoomListParams = {
  building: number;
  building_name: string;
  page: number;
  limit: number;
};

export type GetRoomListResponse = {
  code: number;
  data: {
    count: number;
    rows: BuildingRoom[];
  };
  message: string;
};

/**
 *
 * @description a function to call API to get room list filtered by building id
 */
const getRoomList = async (params: GetRoomListParams) => {
  return axios.get<GetRoomListResponse>(
    `${config.apiUrl}building/room`,
    {
      ...utils.httpClient._getConfig(),
      params: {
        building: params.building ?? 0,
        building_object: params.building ?? 0,
        building_label: params.building_name ?? "",
        page: params.page ?? 1,
        limit: params.limit ?? 50,
        ordering: "-id",
      },
    }
  );
};

export default getRoomList;
