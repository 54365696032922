import { OptionProps } from "@types";
import React from "react";
import styled from "styled-components";

type CCheckBoxProps = {
  name: string;
  data: OptionProps[];
  state: any;
  setState: React.Dispatch<React.SetStateAction<Record<string, any>>>;
};

const CCheckBoxMultiple: React.FC<CCheckBoxProps> = ({
  name,
  data,
  state,
  setState,
}) => {
  return (
    <CCheckBoxMultipleStyled
      style={{
        maxHeight: "calc(37px * 5)",
        overflow: "auto",
      }}
    >
      {data.map((option, idx) => (
        <div
          className="checkbox checkbox-success d-flex align-items-center px-1"
          key={option.value}
        >
          <input
            id={"multi" + name + idx + option.value}
            checked={(state[name] ?? []).find(
              (v: { value: number; label: string }) =>
                v.value === option.value
            )}
            type="checkbox"
            className="opt mr-2"
            value={option.value}
            onChange={(e) => {
              const selectedState = state[name] as
                | { value: number; label: string }[]
                | null;
              setState((prev) => {
                const mapped = new Set<{
                  value: number;
                  label: string;
                }>(selectedState);
                if (e.target.checked) {
                  mapped.add({
                    value: Number(e.target.value),
                    label: option.label,
                  });
                } else {
                  mapped.forEach((item) => {
                    if (item.value === Number(e.target.value)) {
                      mapped.delete(item);
                    }
                  });
                }
                return {
                  ...prev,
                  [name]: Array.from(mapped),
                };
              });
            }}
          />
          <label
            htmlFor={"multi" + name + idx + option.value}
            className="col-form-label font-weight-normal pl-0 d-flex align-items-center"
          >
            {option.label}
          </label>
        </div>
      ))}
    </CCheckBoxMultipleStyled>
  );
};

const CCheckBoxMultipleStyled = styled.div`
  & label {
    &:after {
      top: 14px !important;
      left: -7px !important;
    }
    &:before {
      top: 10px !important;
      left: -12px !important;
    }
  }
`;

export default CCheckBoxMultiple;
