import { useParams, withRouter } from "react-router-dom";

import TabPaneSecondary from "../../../../../../components/ubold/organisms/TabPaneSecondary";

import COneOrManyToManyField from "../../../../../../components/ubold/molecules/forms/COneOrManyToManyField";
import { genericTabProps } from "@types";

function RukiKoin(props: genericTabProps<Object>) {
  const { definitions, tab, state, setState } = props;

  let { id } = useParams<{ id: string }>();

  return (
    <TabPaneSecondary
      definitions={definitions}
      restAccessCodeNamespace="ruki_koin"
      tab={tab}
      state={state}
      setState={setState}
    >
      <COneOrManyToManyField
        id={id}
        name={tab.id}
        restPath="user/ruki_koin/"
        relationKey="tenant_id"
        columns={[
          {
            name: "order",
            type: "async_select",
            title: "Order",
            isRequired: true,
            isUniqueOption: true,
            disabledAtEdit: true,
            data: "order/order?__type__=select_entries",
            itemsExtractor: (value: {
              number: number;
              id: string;
            }) => {
              return {
                label: value.number,
                value: value.id,
              };
            },
          },
          {
            name: "category",
            type: "select",
            title: "Category",
            isRequired: true,
            data: [
              {
                label: "IN",
                value: 1,
              },
              {
                label: "OUT",
                value: 2,
              },
            ],
          },
          {
            name: "koin",
            type: "number",
            title: "Koin",
            isRequired: true,
          },
          {
            name: "source",
            type: "select",
            title: "Source",
            isRequired: true,
            data: [
              {
                label: "Referral",
                value: 1,
              },
              {
                label: "Voucher",
                value: 2,
              },
              {
                label: "Event",
                value: 3,
              },
              {
                label: "Expired",
                value: 5,
              },
            ],
          },
          {
            name: "reference_id",
            type: "number",
            title: "Reference ID",
          },
        ]}
      />
    </TabPaneSecondary>
  );
}

export default withRouter(RukiKoin);
