import styled, { css } from "styled-components/macro";
export const StyledDatePickerWrapper = styled.div<{
  disabled?: boolean;
  hasError?: string;
  isFocused?: boolean;
}>`
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none !important;
    `}

  .SingleDatePickerInput {
    border-radius: 4px;
    ${({ hasError }) =>
      hasError
        ? css`
            border: 1px solid #d00c1a;
          `
        : css`
            border: 1px solid #ccc;
          `}

    height: 38px;
    :hover {
      border: 1px solid #009c96;
    }

    ${({ isFocused }) =>
      isFocused &&
      css`
        border: 1px solid #009c96;
        box-shadow: 0 0 0 4px rgba(0, 187, 180, 0.2);
      `}
  }
  .SingleDatePickerInput_clearDate {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    padding: 10px;
    margin: 0 10px 0 5px;
    transform: translateY(-50%);
    display: contents;
  }
  .SingleDatePickerInput_clearDate_svg {
    fill: #999999;
    height: 9px;
    width: 9px;
    vertical-align: middle;
    margin: 0 2px 0 4px;
  }
  .SingleDatePickerInput__showClearDate {
    padding: 0;
  }
  .SingleDatePickerInput_calendarIcon {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    padding: 9px 12px 9px 8px;
    margin: 0;
  }
  .SingleDatePicker_picker__directionLeft {
    left: 0;
    top: 48px !important;
  }
  .CalendarDay__default {
    &:hover {
      background: #e7e7e7;
      border: 1px solid #e4e7e7;
      color: inherit;
    }
  }
  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background: #00bbb4;
    border: 1px double #00bbb4;
    color: #fff;
  }
  .CalendarDay__blocked_out_of_range,
  .CalendarDay__blocked_out_of_range:active,
  .CalendarDay__blocked_out_of_range:hover {
    background: #fafafa;
    border: 1px solid #e4e7e7;
    color: #cacccd;
  }

  .DateInput {
    margin: 0;
    padding: 0;
    background: #fff;
    position: relative;
    display: inline-block;
    width: 85px;
    vertical-align: middle;
  }

  .DateInput_input {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #000;
    background-color: #fff;
    width: 84px;
    padding: 7px 0 4px 12px;
    border: 0;
    border-top: 0;
    border-right: 0;
    border-bottom: 2px solid transparent;
    border-left: 0;
    border-radius: 0;
    &:disabled {
      background-color: inherit;
    }
  }
  .DateInput_input__focused {
    outline: 0;
    background: #fff;
    border: 0;
    border-top: 0;
    border-right: 0;
    border-bottom: 0;
    border-left: 0;
  }
  .DateInput_fang {
    position: absolute;
    width: 20px;
    height: 10px;
    left: 22px;
    top: 34px !important;
    z-index: 2;
  }
`;
