import utils from "../../../utils";

import configBuilder from "../../../components/ubold/ConfigBuilder";

let definitions = {
  title: "Landlord Invoices",
  frontendPath: "/finance/landlord_invoice",
  baseLink: "/finance/invoice_landlord",
  restAccessCode: "finance.landlord_invoice",
  __table__: {
    addButton: false,
    hideEditButton: true,
    hideRemoveButton: true,
    showDetailButton: true,
    filterColumn: 3,
    filters: [
      {
        id: "landlord",
        name: "Landlord",
        type: "async_select",
        data: "building/landlord?__type__=select_entries",
        itemsExtractor: (item) => {
          return {
            label: item.name,
            value: item.id,
          };
        },
      },
      {
        id: "building",
        name: "Building",
        type: "async_select",
        data: "building/building?__type__=select_entries",
        itemsExtractor: (item) => {
          return {
            label: item.building_name,
            value: item.id,
          };
        },
      },
      {
        id: "building_type",
        name: "Building Type",
        type: "select",
        data: {
          optionField: "buildings_building_type",
        },
      },
    ],
    columns: [
      {
        id: "landlord_name",
        name: "Landlord",
        enableHyperlink: true,
        forceView: true,
      },
      {
        id: "building_name",
        name: "Building",
      },
      {
        id: "building_type",
        name: "Building Type",
      },
      {
        id: "generated_at",
        name: "Generated Time",
      },
      {
        id: "total_amount",
        name: "Total Amount",
        renderer: (value) => {
          return utils.formatter.currency(value);
        },
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
