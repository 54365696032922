import configBuilder from "../../../../components/ubold/ConfigBuilder";

import pageDefinitions from "../definitions";

import utils from "../../../../utils";

const definitions = {
  ...pageDefinitions,
  tabs: [
    {
      id: "grade",
      name: "Grade",
    },
    {
      id: "t_buildings",
      name: "Buildings",
    },
  ],
  validators: {
    name: (value: string) => {
      return utils.validator.validateRequired(value);
    },
  },
};

export default configBuilder.buildAddEditPageConfig(definitions);
