import configBuilder from "../../../../components/ubold/ConfigBuilder";

import pageDefinitions from "../definitions";

import utils from "../../../../utils";

const definitions = {
  ...pageDefinitions,
  tabs: [
    {
      id: "event",
      name: "Event",
    },
    {
      id: "t_attendances",
      name: "Attendances",
    },
  ],
  validators: {
    event_name: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    event_type: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    host: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    photo_original: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    date_started: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    date_ended: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    time_started: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    latest_cancellation_date: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    latest_registration_date: (value: string) => {
      return utils.validator.validateRequired(value);
    },
  },
};

export default configBuilder.buildAddEditPageConfig(definitions, [
  "photo_original",
  "host_pic_original",
]);
