import styled from "styled-components";
import { RukiUIButtonVariant } from "@types";

const generateSVGIconColor = (
  disabled: boolean,
  variant: RukiUIButtonVariant
): string => {
  switch (variant) {
    case "OUTLINED":
      if (disabled) {
        return "opacity(20%);";
      }

      return "brightness(0) saturate(100%) invert(62%) sepia(41%) saturate(4767%) hue-rotate(138deg) brightness(94%) contrast(101%);";

    default:
      if (disabled) {
        return "brightness(0) saturate(100%) invert(89%) sepia(0%) saturate(0%) hue-rotate(38deg) brightness(92%) contrast(94%)";
      }

      return "brightness(0) saturate(100%) invert(100%) sepia(97%) saturate(0%) hue-rotate(58deg) brightness(105%) contrast(104%)";
  }
};

export const ButtonPrimary = styled.button`
  font-family: "Rubik";
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  width: 54px;

  background-color: #00bbb4;
  border: unset;
  border-radius: 4px;
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  padding: 8px 12px 8px 12px;

  &:disabled {
    background: rgba(0, 187, 180, 0.2);
    color: #fff;
  }

  &:not(:disabled) {
    &:focus {
      box-shadow: 0 0 0 4px rgba(0, 187, 180, 0.2);
    }

    &:hover {
      background-color: #009c96;
    }

    &:active {
      background-color: rgba(0, 60, 57, 1);
      box-shadow: unset;
    }
  }

  span {
    display: inline-block;
  }
`;

export const IconContainer = styled.div<{
  disabled?: boolean;
  variant?: RukiUIButtonVariant;
}>`
  display: flex;
  align-items: center;

  margin-right: 10px;

  img {
    display: inline-block;
    filter: ${({ disabled, variant }) =>
      generateSVGIconColor(disabled, variant)};
  }
`;

export const ButtonOutlined = styled.button`
  font-family: "Rubik";
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  width: 82px;

  background-color: #ffffff;
  border: 0;
  color: #00bbb4;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  padding: 9px 12px 10px 14px;

  &:disabled {
    background: #fff;
    border: 0;
    color: rgba(0, 187, 180, 0.2);
  }

  &:not(:disabled) {
    &:focus {
      border: 0px solid #fff;
      border-radius: 4px;
      box-shadow: 0 0 0 4px rgba(0, 187, 180, 0.2);
      color: #009c96;

      & > div {
        & > img {
          filter: brightness(0) saturate(100%) invert(34%) sepia(69%)
            saturate(1906%) hue-rotate(154deg) brightness(96%)
            contrast(101%);
        }
      }
    }

    &:hover {
      border: 0;
      color: #009c96;

      & > div {
        & > img {
          filter: brightness(0) saturate(100%) invert(34%) sepia(69%)
            saturate(1906%) hue-rotate(154deg) brightness(96%)
            contrast(101%);
        }
      }
    }

    &:active {
      background-color: #ffffff;
      border: 0;
      color: #063735;
      box-shadow: unset;

      & > div {
        & > img {
          filter: brightness(0) saturate(100%) invert(19%) sepia(15%)
            saturate(3186%) hue-rotate(133deg) brightness(95%)
            contrast(105%);
        }
      }
    }
  }

  span {
    display: inline-block;
  }
`;
