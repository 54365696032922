import configBuilder from "components/ubold/ConfigBuilder";

import pageDefinitions from "../definitions";
import utils from "utils";

const definitions = {
  ...pageDefinitions,
  validators: {
    name: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    name_en: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    description: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    description_en: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    order: (value: number) => {
      return utils.validator.validateRequired(value);
    },
    icon_original: (value: string) => {
      return utils.validator.validateRequired(value);
    },
  },
};

export default configBuilder.buildAddEditPageConfig(definitions, [
  "icon_original",
]);
