import React from "react";

import { Schedule } from "components/ubold/organisms/TableDashboardRoomAllotment/Types";

import CHTextFieldV2 from "../../forms/CHTextFieldV2";
import TextLegend, {
  ThemeTextLegendType,
} from "../../TextLegend/TextLegend";

import {
  CustomFormGroup,
  FormGroup,
  LinkText,
  ModalDetailStyled,
} from "./ModalDetail.css";

export type DataModalDetailObjectType = {
  date: string;
  hrefId: {
    order: number;
    room: number;
    roomVariant: number;
    tenant: number;
  };
  render: {
    subscriptionType: Schedule["order_subscription_type"];
    orderStatus:
      | "Waiting confirm"
      | "Processing"
      | "Waiting for Payment"
      | "Deposit Received"
      | "Deposit and Payment Received"
      | "Rejected"
      | "Ongoing"
      | "Cancel"
      | "Done";
    orderDate: string;
    orderId: string;
    salesName: string;
    confirmCheckout: boolean;
    roomVariant: string;
    room: string;
    tenantName: string;
  };
};

const generateLegendTheme = (
  orderStatus: DataModalDetailObjectType["render"]["orderStatus"]
): ThemeTextLegendType => {
  switch (orderStatus) {
    case "Ongoing":
      return "BLUE";

    case "Waiting for Payment":
      return "RED";

    case "Deposit Received":
      return "YELLOW";

    case "Deposit and Payment Received":
      return "PURPLE";

    default:
      return null;
  }
};

const ModalDetail: React.FC<{
  data: DataModalDetailObjectType;
  onClose: () => void;
}> = ({ data }) => {
  const subscriptionType: Schedule["order_subscription_type"] =
    data.render.subscriptionType;

  return (
    <ModalDetailStyled>
      <FormGroup>
        <label>Order Status</label>
        <div className="d-flex">
          <TextLegend
            className="mr-1"
            stripes={
              subscriptionType === "Close Subscription"
                ? generateLegendTheme(data?.render.orderStatus)
                : null
            }
            theme={generateLegendTheme(data?.render?.orderStatus)}
          />
          <p className="mb-0">
            {data?.render?.orderStatus ===
            "Deposit and Payment Received"
              ? "Complete Payment"
              : data?.render?.orderStatus}{" "}
            {`(${data?.render?.subscriptionType})`}
          </p>
        </div>
      </FormGroup>

      <CustomFormGroup>
        <CHTextFieldV2
          readOnly
          title="Order Date"
          value={data?.render?.orderDate}
        />
      </CustomFormGroup>

      <FormGroup>
        <label>Order ID</label>
        <LinkText
          href={`/sales/order_sop/edit/${data.hrefId.order}`}
          target="_blank"
          title="Click to see detail"
        >
          {data?.render?.orderId}
        </LinkText>
      </FormGroup>

      {data?.render?.salesName && (
        <CustomFormGroup>
          <CHTextFieldV2
            readOnly
            title="Sales Name"
            value={data?.render?.salesName}
          />
        </CustomFormGroup>
      )}

      <CustomFormGroup className="mb-2">
        <CHTextFieldV2
          readOnly
          title="Confirm Checkout"
          value={data?.render?.confirmCheckout ? "Yes" : "No"}
        />
      </CustomFormGroup>

      <h5 className="mb-2">Room Detail</h5>

      <FormGroup>
        <label>Room Variant</label>
        <LinkText
          href={`/building/room/room_variant/edit/${data.hrefId.roomVariant}`}
          target="_blank"
          title="Click to see detail"
        >
          {data?.render?.roomVariant}
        </LinkText>
      </FormGroup>

      <FormGroup>
        <label>Room</label>
        <LinkText
          href={`/building/room/room/edit/${data.hrefId.room}`}
          target="_blank"
          title="Click to see detail"
        >
          {data?.render?.room}
        </LinkText>
      </FormGroup>

      <FormGroup className="mb-0">
        <label>Tenant Name</label>
        <LinkText
          href={`/tenant/tenant/edit/${data.hrefId.tenant}`}
          target="_blank"
          title="Click to see detail"
        >
          {data?.render?.tenantName}
        </LinkText>
      </FormGroup>
    </ModalDetailStyled>
  );
};

export default ModalDetail;
