import { withRouter } from "react-router-dom";

import TabPane from "../../../../../../components/ubold/organisms/TabPane";
import TabPaneSection from "../../../../../../components/ubold/organisms/TabPaneSection";

import CHTextField from "../../../../../../components/ubold/molecules/forms/CHTextField";
import CHSelect from "../../../../../../components/ubold/molecules/forms/CHSelect";
import CHAsyncSelect from "../../../../../../components/ubold/molecules/forms/CHAsyncSelect";
import CHRichTextField from "../../../../../../components/ubold/molecules/forms/CHRichTextField";
import CHCheckBox from "../../../../../../components/ubold/molecules/forms/CHCheckBox";
import CHDateTimePicker from "../../../../../../components/ubold/molecules/forms/CHDateTimePicker";
import CHTextView from "../../../../../../components/ubold/molecules/forms/CHTextView";

import Information from "./Sections/Information";
import Rent from "./Sections/Rent";

import utils from "../../../../../../utils";
import CHTextArea from "../../../../../../components/ubold/molecules/forms/CHTextArea";

function Building(props) {
  const { id, definitions, tab, state, setState } = props;

  const doneStatus = [4, 5, 6];

  return (
    <TabPane
      id={id}
      definitions={definitions}
      isAbleToDuplicate
      removedDuplicateFields={["number"]}
      tab={tab}
      state={state}
      setState={setState}
    >
      <TabPaneSection style={{ padding: "25px 16px 0px" }}>
        <CHTextView name="number" title="Order ID" />
        <CHAsyncSelect
          name="tenant"
          title="Tenant"
          isRequired
          readOnly={
            id || (id && doneStatus.includes(state["status"]))
          }
          data="user/tenant?__type__=select_entries&limit=10"
          itemsExtractor={(row) => {
            return {
              label:
                row.name +
                " - " +
                row.email +
                " - " +
                row.country_code +
                row.phone_number,
              value: row.id,
            };
          }}
        />
        <CHAsyncSelect
          name="building"
          title="Building"
          isRequired
          readOnly={
            id || (id && doneStatus.includes(state["status"]))
          }
          data="building/building?__type__=select_entries&limit=10"
          itemsExtractor={(row) => {
            return {
              label:
                row.building_name +
                " - " +
                (row.building_type === 1
                  ? "Centralized"
                  : "Decentralized"),
              value: row.id,
            };
          }}
        />
        <CHAsyncSelect
          name="room_variant"
          title="Room Variant"
          dependsOn="building"
          isRequired
          readOnly={
            id || (id && doneStatus.includes(state["status"]))
          }
          data="building/room_variant?__type__=select_entries&limit=10"
          itemsExtractor={(row) => {
            return {
              label:
                row.name +
                " - " +
                utils.formatter.currency(row.price),
              value: row.id,
            };
          }}
        />
        <CHAsyncSelect
          name="room"
          title="Room"
          dependsOn="room_variant"
          readOnly={
            id || (id && doneStatus.includes(state["status"]))
          }
          data="building/room?__type__=select_entries&limit=10"
          itemsExtractor={(row) => {
            return {
              label:
                row.name +
                " - " +
                utils.formatter.currency(row.price),
              value: row.id,
            };
          }}
        />
        <CHAsyncSelect
          name="payment_plan"
          title="Payment Plan"
          isRequired
          readOnly={
            id || (id && doneStatus.includes(state["status"]))
          }
          data="finance/payment_plan?__type__=select_entries&limit=10"
          itemsExtractor={(row) => {
            return {
              label:
                row.plan + " Month(s) - " + row.discount_amount + "%",
              value: row.id,
            };
          }}
        />
        <CHTextView
          name="payment_plan_discount"
          title="Payment Plan Discount"
          renderer={(value) => {
            return value + "%";
          }}
        />
        <CHCheckBox
          name="full_paid"
          title="Is Full Paid?"
          disabled={
            id || (id && doneStatus.includes(state["status"]))
          }
        />
        <CHTextField
          name="deposit"
          title="Deposit"
          type="number"
          readOnly={id && doneStatus.includes(state["status"])}
        />
        {!doneStatus.includes(state["status"]) ? (
          <CHRichTextField
            name="deposit_notes"
            title="Deposit Notes"
          />
        ) : (
          <CHTextView
            name="deposit_notes"
            title="Deposit Notes"
            isSafeContent
          />
        )}
        <CHTextView
          name="price"
          title="Price"
          readOnly={
            id || (id && doneStatus.includes(state["status"]))
          }
          renderer={(value) => {
            return utils.formatter.currency(value);
          }}
        />
        <CHSelect
          name="discount_type"
          title="Discount Type"
          readOnly={
            id || (id && doneStatus.includes(state["status"]))
          }
          data={[
            {
              label: "Discount Existing Tenant",
              value: 1,
            },
            {
              label: "Discount Negotiation",
              value: 2,
            },
          ]}
        />
        <CHTextField
          name="discount_amount"
          title="Discount Amount"
          readOnly={
            id || (id && doneStatus.includes(state["status"]))
          }
          type="number"
          min={0}
        />
        {state["discount_amount"] > 0 ? (
          <CHRichTextField
            name="discount_notes"
            title="Discount Notes"
          />
        ) : (
          <CHTextView
            name="discount_notes"
            title="Discount Notes"
            isSafeContent
          />
        )}
        <CHSelect
          name="tenant_category"
          title="Tenant Category"
          readOnly={
            id || (id && doneStatus.includes(state["status"]))
          }
          isRequired
          data={[
            {
              label: "Rukita Tenant",
              value: 1,
            },
            {
              label: "Legacy Tenant",
              value: 2,
            },
            {
              label: "Rukita Staff - Pay",
              value: 3,
            },
            {
              label: "Rukita Staff - Free of Charge",
              value: 4,
            },
          ]}
        />
        <CHTextView name="transfer_from" title="Transfer From" />
        <CHCheckBox
          name="duplicated_order"
          title="Is Duplicate Order?"
          disabled={
            id || (id && doneStatus.includes(state["status"]))
          }
        />
        <CHAsyncSelect
          name="signed_by_1"
          title="Signed By 1"
          readOnly={id && doneStatus.includes(state["status"])}
          data="crm/user?__type__=select_entries&limit=10"
          itemsExtractor={(value) => {
            return {
              label: value.username,
              value: value.id,
            };
          }}
        />
        <CHAsyncSelect
          name="signed_by_2"
          title="Signed By 2"
          readOnly={id && doneStatus.includes(state["status"])}
          data="crm/user?__type__=select_entries&limit=10"
          itemsExtractor={(value) => {
            return {
              label: value.username,
              value: value.id,
            };
          }}
        />
        <CHAsyncSelect
          name="signed_by_3"
          title="Signed By 3"
          readOnly={id && doneStatus.includes(state["status"])}
          data="crm/user?__type__=select_entries&limit=10"
          itemsExtractor={(value) => {
            return {
              label: value.username,
              value: value.id,
            };
          }}
        />
        <CHDateTimePicker
          name="signed_date"
          title="Signed Date"
          type="date"
          readOnly={id && doneStatus.includes(state["status"])}
        />
        <CHTextField
          name="referral_code"
          title="Referral Code"
          maxLength={8}
          readOnly={id && doneStatus.includes(state["status"])}
        />
        <CHTextArea
          name="rumanage_remarks"
          title="RuManage Remarks"
          maxLength={150}
          withCharCounter
        />
      </TabPaneSection>
      <TabPaneSection accordionId="formAccordion">
        <Rent />
        <Information />
      </TabPaneSection>
    </TabPane>
  );
}

export default withRouter(Building);
