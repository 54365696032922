import { useEffect, useState } from "react";

import { withRouter } from "react-router-dom";

import DualListBox from "react-dual-listbox";

import "react-dual-listbox/lib/react-dual-listbox.css";

import utils from "../../../../utils";
import configs from "../../../../configs";

function CSelect(props) {
  const {
    name,
    state,
    setState,
    data,
    onChange,
    readOnly = false,
    ignoreDependsOnParam,
    dependsOn,
    dependsOnRelationKey,
    dependsOnHandler,
    itemsExtractor,
  } = props;

  const [options, setOptions] = useState([]);

  const load = (dependsOn, callback) => {
    if (utils.commons.isArray(data)) {
      setOptions(data);
    } else if (utils.commons.isString(data)) {
      utils.httpClient.get(
        configs.apiUrl +
          data +
          (dependsOn === null || ignoreDependsOnParam
            ? ""
            : "&" +
              (dependsOnRelationKey
                ? dependsOnRelationKey
                : dependsOn) +
              "_id=" +
              state[dependsOn]),
        (data) => {
          const options = [];

          for (let i = 0; i < data.data.rows.length; i++) {
            if (itemsExtractor === undefined) {
              options[i] = {
                label: data.data.rows[i]["name"],
                value: data.data.rows[i]["id"],
              };
            } else {
              options[i] = itemsExtractor(data.data.rows[i], i);
            }
          }

          setOptions(options);

          if (utils.commons.isFunction(callback)) {
            callback(options);
          }
        },
        (error, message) => {
          console.log(message);
        }
      );
    }
  };

  useEffect(() => {
    if (dependsOn === null || dependsOn === undefined) {
      load(null);
    }
  }, []);

  useEffect(() => {
    if (dependsOn && utils.commons.isString(data)) {
      if (
        state[dependsOn] !== undefined &&
        state[dependsOn] !== null
      ) {
        if (utils.commons.isFunction(dependsOnHandler)) {
          dependsOnHandler(data);
        } else {
          load(dependsOn);
        }
      } else {
        if (state[dependsOn] === null) {
          if (onChange) {
            onChange(null);
          } else {
            if (!state[name]) {
              setState((prevState) => ({ ...prevState, [name]: [] }));
            }
          }
        }
      }
    }
  }, [state[dependsOn]]);

  const selected = [];

  if (utils.commons.isArray(state[name])) {
    for (let i = 0; i < state[name].length; i++) {
      selected.push(state[name][i]);
    }
  }

  return (
    <DualListBox
      name={name}
      canFilter
      selected={selected}
      options={options}
      disabled={readOnly}
      onChange={
        onChange
          ? onChange
          : (selected) => {
              if (selected) {
                setState((prevState) => ({
                  ...prevState,
                  [name]: selected,
                }));
              } else {
                setState((prevState) => ({
                  ...prevState,
                  [name]: state[name] ? state[name] : [],
                }));
              }
            }
      }
    />
  );
}

export default withRouter(CSelect);
