import { useState } from "react";

import { useParams, withRouter } from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import Form from "../../../../components/ubold/organisms/Form";

import CVTextField from "../../../../components/ubold/molecules/forms/CVTextField";
import CVSwitch from "../../../../components/ubold/molecules/forms/CVSwitch";
import CVTextArea from "../../../../components/ubold/molecules/forms/CVTextArea";
import CVAsyncSelect from "../../../../components/ubold/molecules/forms/CVAsyncSelect";

import definitions from "./definitions";

function CAddEditPage() {
  let { id } = useParams();

  const initialState = {};

  const [state, setState] = useState(initialState);

  return (
    <Page
      title={
        (id === undefined ? "Add New" : "Edit") +
        " " +
        definitions.title
      }
      restAccessCode={definitions.restAccessCode}
    >
      <Form
        id={id}
        definitions={definitions}
        state={state}
        setState={setState}
      >
        <CVAsyncSelect
          name="landlord_lead"
          title="Landlord Lead"
          data="user/landlord_lead?__type__=select_entries"
          isRequired
        />
        <CVTextField
          name="building_name"
          title="Building Name"
          maxLength={50}
          isRequired
        />
        <CVTextField
          name="total_room"
          title="Total Room"
          type="number"
          isRequired
        />
        <CVTextField
          name="building_type"
          title="Building type"
          maxLength={254}
        />
        <CVTextArea name="location" title="Location" />
        <CVTextArea name="address" title="Address" />
        <CVTextField
          name="utm_source"
          title="UTM Source"
          maxLength={254}
        />
        <CVTextField
          name="utm_medium"
          title="UTM Medium"
          maxLength={254}
        />
        <CVTextField
          name="utm_campaign"
          title="UTM Campaign"
          maxLength={254}
        />
        <CVTextField
          name="utm_content"
          title="UTM Content"
          maxLength={254}
        />
        <CVSwitch name="need_response" title="Need Response" />
        <CVSwitch name="qualified" title="Qualified" />
      </Form>
    </Page>
  );
}

export default withRouter(CAddEditPage);
