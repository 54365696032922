import { withRouter } from "react-router-dom";

import AccordionPane from "../../../../../../../components/ubold/organisms/AccordionPane";

import CHCheckBox from "../../../../../../../components/ubold/molecules/forms/CHCheckBox";
import CHTextField from "../../../../../../../components/ubold/molecules/forms/CHTextField";
import CHSelect from "../../../../../../../components/ubold/molecules/forms/CHSelect";
import CHDateTimeSplitPicker from "../../../../../../../components/ubold/molecules/forms/CHDateTimeSplitPicker";
import CHParkingCapacity from "../../../../../../../components/ubold/molecules/forms/CHParkingCapacity";

import utils from "../../../../../../../utils";
import CHDateTimePicker from "../../../../../../../components/ubold/molecules/forms/CHDateTimePicker";
import CHMultiCheckbox from "../../../../../../../components/ubold/molecules/forms/CHMultiCheckbox";

function OtherInfos(props) {
  const {
    baseLink,
    accordionId,
    onSubmit,
    state,
    setState,
    restAccessCode,
  } = props;

  return (
    <AccordionPane
      id="otherInfos"
      name="Other Infos"
      accordionId={accordionId}
      baseLink={baseLink}
      onSubmit={onSubmit}
      shown={!!utils.form.isFieldError(state, "property_type")}
      state={state}
      setState={setState}
    >
      <CHTextField
        name="building_floors"
        title="Total Floor"
        type="number"
      />
      <CHSelect
        name="building_status"
        title="Progress Status"
        data={{ optionField: "buildings_building_status" }}
      />
      <CHSelect
        name="property_type"
        title="Type of Property"
        isRequired
        data={{ optionField: "buildings_building_property_type" }}
      />
      <CHTextField
        name="total_buildup"
        title="Total Build Up"
        type="number"
      />
      <CHTextField
        name="rented_buildup"
        title="Rented Build Up"
        type="number"
      />
      <CHDateTimeSplitPicker
        name="last_maintenance"
        title="Last Maintenance"
      />
      <CHDateTimeSplitPicker
        name="next_maintenance"
        title="Next Maintenance"
      />
      <CHDateTimePicker
        name="year_build"
        title="Year Build"
        type="date"
      />
      <CHParkingCapacity
        carName="car_parking_capacity"
        motorBikeName="motorbike_parking_capacity"
        // rukitaOptionName="rukita_option"
        enableOnlinePayment="enable_online_payment"
        title="Parking Capacity"
      />

      <CHCheckBox
        name="allow_for_more_than_30_days"
        title="Allow 180 Days"
        restAccessCode={restAccessCode}
      />

      <CHMultiCheckbox
        name="shipping_method"
        title="Shipping Method"
        data={[
          {
            label: "Diantar ke Kamar",
            value: "1",
          },
          {
            label: "Diambil di Dropbox",
            value: "2",
          },
        ]}
        onChange={(values) => {
          setState((prevState) => ({
            ...prevState,
            shipping_method: values,
          }));
        }}
      />

      <CHSelect
        name="minimum_deposit"
        title="Minimum Deposit"
        isRequired
        data={{ optionField: "buildings_building_minimum_deposit" }}
        restAccessCode={restAccessCode}
        filterItems={(options) => {
          const newOptions = options?.filter((option) =>
            !state?.rukita_option ? option?.value !== 6 : true
          );
          return newOptions;
        }}
        filterItemsDependencies={[state?.rukita_option]}
      />
    </AccordionPane>
  );
}

export default withRouter(OtherInfos);
