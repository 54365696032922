import { useState } from "react";

import { useParams, withRouter } from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import TabHeaders from "../../../../components/ubold/organisms/TabHeaders";
import TabContent from "../../../../components/ubold/organisms/TabContent";
import TabbedForm from "../../../../components/ubold/organisms/TabbedForm";

import BuildingRoomVariant from "./Tabs/BuildingRoomVariants";
import BuildingServices from "./Tabs/BuildingServices";
import BuildingFacilities from "./Tabs/BuildingFacilities";
import BuildingAddons from "./Tabs/BuildingAddons";
import BuildingPhoto from "./Tabs/BuildingPhoto";
import BuildingPointOfInterest from "./Tabs/BuildingPointOfInterest";
import Building from "./Tabs/Building";
import BuildingDiscountPromoTab from "./Tabs/BuildingDiscountPromo/BuildingDiscountPromoTab";

import definitions from "./definitions";

function BuildingAddEditPage() {
  let { id } = useParams();

  const initialState = {
    building_type: 2,
    active: true,
    release_status: 2,
    publish_status: 0,
    internet_status: 2,
    laundry: 1,
    room_cleaning: 1,
    parking_area: 1,
    security: 1,
    building_status: 5,
    property_type: 2,
    enable_online_payment: true,
    country: 84,
    eligible_for_discount: true,
    minimum_deposit: 4,
    laundry_deduction_to_landlord: "NO_DEDUCTION",
    commercial_type: "RUKITA",
  };

  const [state, setState] = useState(initialState);

  return (
    <Page
      linkHistory={
        id === undefined ? null : definitions.linkHistory(id)
      }
      title={
        (id === undefined ? "Add New" : "Edit") +
        " " +
        definitions.title
      }
      restAccessCode={definitions.restAccessCode}
    >
      <TabbedForm
        id={id}
        state={state}
        setState={setState}
        definitions={definitions}
      >
        <TabHeaders />
        <TabContent>
          <Building />
          <BuildingPhoto />
          <BuildingServices />
          <BuildingFacilities />
          <BuildingAddons />
          <BuildingRoomVariant />
          <BuildingPointOfInterest />
          <BuildingDiscountPromoTab />
        </TabContent>
      </TabbedForm>
    </Page>
  );
}

export default withRouter(BuildingAddEditPage);
