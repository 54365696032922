import { useEffect } from "react";
import { withRouter } from "react-router-dom";

import AccordionPane from "../../../../../../../components/ubold/organisms/AccordionPane";

import CHRadio from "../../../../../../../components/ubold/molecules/forms/CHRadio";
import CHSelect from "../../../../../../../components/ubold/molecules/forms/CHSelect";
import CHRichTextField from "../../../../../../../components/ubold/molecules/forms/CHRichTextField";
import CHTextView from "../../../../../../../components/ubold/molecules/forms/CHTextView";
import CHMultiCheckbox from "../../../../../../../components/ubold/molecules/forms/CHMultiCheckbox";
import CHTextField from "components/ubold/molecules/forms/CHTextField";
import CHCheckBox from "components/ubold/molecules/forms/CHCheckBox";

function GeneralServices(props) {
  const { baseLink, accordionId, onSubmit, state, setState } = props;

  const generalServiceOptions = [
    {
      value: 1,
      label: "None",
    },
    {
      value: 2,
      label: "In House",
    },
    {
      value: 3,
      label: "Vendor",
    },
  ];

  const laundryRuOptionDenominationOptions = [
    {
      label: "None",
      value: null,
    },
    {
      label: "Kgs",
      value: 1,
    },
    {
      label: "Pcs",
      value: 2,
    },
  ];

  const RuOptionQuotaPerOptions = [
    {
      label: "None",
      value: null,
    },
    {
      label: "Month",
      value: 3,
    },
    {
      label: "Week",
      value: 2,
    },
  ];

  const isRuOption = state["rukita_option"];

  useEffect(() => {
    if (!state?.laundry_schedule?.length) {
      setState((prevState) => ({
        ...prevState,
        apply_laundry_to_all_room: false,
      }));
    }
  }, [state?.laundry_schedule]);

  useEffect(() => {
    if (!state?.cleaning_schedule?.length) {
      setState((prevState) => ({
        ...prevState,
        apply_cleaning_to_all_room: false,
      }));
    }
  }, [state?.cleaning_schedule]);

  return (
    <AccordionPane
      id="generalServices"
      name="General Services"
      accordionId={accordionId}
      baseLink={baseLink}
      onSubmit={onSubmit}
      state={state}
      setState={setState}
    >
      <CHRadio
        name="internet_status"
        title="Status of Internet"
        data={[
          {
            label: "Connected",
            value: 1,
          },
          {
            label: "Pending Application",
            value: 2,
          },
          {
            label: "Pending Installation",
            value: 3,
          },
        ]}
      />
      <CHTextView
        name="internet_speed"
        title="Internet Speed"
        renderer={(value) => {
          return (value ? value : "None") + " MB/s";
        }}
      />
      <CHSelect
        name="laundry"
        title="Laundry"
        data={generalServiceOptions}
      />
      {isRuOption ? (
        <CHTextField
          name="quota_laundry"
          title="Laundry Quota"
          min={0}
          type="number"
        />
      ) : (
        <CHTextView
          name="grade_laundry_quota"
          title="Laundry Quota"
        />
      )}
      {isRuOption ? (
        <CHSelect
          name="laundry_ruoptions_denomination"
          title="Laundry Denomination"
          data={laundryRuOptionDenominationOptions}
        />
      ) : (
        <CHTextView
          name="laundry_ruoptions_denomination"
          title="Laundry Denomination"
          renderer={(value) => {
            if (parseInt(value) === 1) {
              return "Kgs";
            } else {
              return "Pcs";
            }
          }}
        />
      )}
      {isRuOption ? (
        <CHSelect
          name="laundry_ruoptions_quota_per"
          title="Laundry Quota Per"
          data={RuOptionQuotaPerOptions}
        />
      ) : (
        <CHTextView
          name="laundry_ruoptions_quota_per"
          title="Laundry Quota Per"
          renderer={(value) => {
            if (parseInt(value) === 2) {
              return "Week";
            } else {
              return "Month";
            }
          }}
        />
      )}
      <CHMultiCheckbox
        name="laundry_schedule"
        title="Laundry Schedule"
        data={[
          {
            label: "Senin",
            value: "1",
          },
          {
            label: "Selasa",
            value: "2",
          },
          {
            label: "Rabu",
            value: "3",
          },
          {
            label: "Kamis",
            value: "4",
          },
          {
            label: "Jumat",
            value: "5",
          },
          {
            label: "Sabtu",
            value: "6",
          },
          {
            label: "Minggu",
            value: "7",
          },
        ]}
        onChange={(values) => {
          setState((prevState) => ({
            ...prevState,
            laundry_schedule: values,
          }));
        }}
      />
      <CHCheckBox
        name="apply_laundry_to_all_room"
        title="Apply Laundry Schedule to All Room"
        readOnly={!state?.laundry_schedule?.length}
      />
      <CHRichTextField name="laundry_remark" title="Laundry Remark" />
      <CHSelect
        name="room_cleaning"
        title="Room Cleaning"
        data={generalServiceOptions}
      />
      {isRuOption ? (
        <CHTextField
          name="room_cleaning_quota"
          title="Room Cleaning Quota"
          min={0}
          type="number"
        />
      ) : (
        <CHTextView
          name="grade_room_cleaning_quota"
          title="Room Cleaning Quota"
        />
      )}
      {isRuOption ? (
        <CHSelect
          name="room_cleaning_per_ruoption"
          title="Room Cleaning Per"
          data={RuOptionQuotaPerOptions}
        />
      ) : (
        <CHTextView
          name="room_cleaning_per_ruoption"
          title="Room Cleaning Per"
          renderer={(value) => {
            if (parseInt(value) === 2) {
              return "Week";
            } else {
              return "Month";
            }
          }}
        />
      )}
      <CHMultiCheckbox
        name="cleaning_schedule"
        title="Cleaning Schedule"
        data={[
          {
            label: "Senin",
            value: "1",
          },
          {
            label: "Selasa",
            value: "2",
          },
          {
            label: "Rabu",
            value: "3",
          },
          {
            label: "Kamis",
            value: "4",
          },
          {
            label: "Jumat",
            value: "5",
          },
          {
            label: "Sabtu",
            value: "6",
          },
          {
            label: "Minggu",
            value: "7",
          },
        ]}
        onChange={(values) => {
          setState((prevState) => ({
            ...prevState,
            cleaning_schedule: values,
          }));
        }}
      />
      <CHCheckBox
        name="apply_cleaning_to_all_room"
        title="Apply Cleaning Schedule to All Room"
        readOnly={!state?.cleaning_schedule?.length}
      />
      <CHRichTextField
        name="room_cleaning_remark"
        title="Room Cleaning Remark"
      />
      <CHSelect
        name="parking_area"
        title="Parking Area"
        data={generalServiceOptions}
      />
      <CHRichTextField
        name="parking_area_remark"
        title="Parking Area Remark"
      />
      <CHSelect
        name="security"
        title="Security"
        data={generalServiceOptions}
      />
      <CHRichTextField
        name="security_remark"
        title="Security Remark"
      />
      <CHTextView
        name="sla"
        title="SLA Room Maintenance"
        renderer={(value) => {
          return (value ? value : "0") + " days";
        }}
      />
    </AccordionPane>
  );
}

export default withRouter(GeneralServices);
