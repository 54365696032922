import CHCheckBox from "components/ubold/molecules/forms/CHCheckBox";
import AccordionPane from "components/ubold/organisms/AccordionPane";
import { useParams, withRouter } from "react-router-dom";

const ServiceEligibility = (props) => {
  const { accordionId, baseLink, onSubmit, state, setState } = props;
  const { id } = useParams();

  if (state?.building_type !== 2) return null;

  return (
    <AccordionPane
      id="service_eligibility"
      name="Service Eligibility"
      accordionId={accordionId}
      baseLink={baseLink}
      onSubmit={onSubmit}
      state={state}
      setState={setState}
    >
      <CHCheckBox
        name="eligible_for_room_cleaning"
        title="Eligible for Room Cleaning"
        readOnly={id}
      />
      <CHCheckBox
        name="eligible_for_laundry"
        title="Eligible for Laundry"
        readOnly={id}
      />
    </AccordionPane>
  );
};

export default withRouter(ServiceEligibility);
