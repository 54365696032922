import configBuilder from "components/ubold/ConfigBuilder";
import pageDefinitions from "../definitions";

import utils from "utils";

const definitions = {
  ...pageDefinitions,
  tabs: [
    {
      id: "homepage_collection",
      name: "Homepage Collection",
    },
    {
      id: "t_building_collection",
      name: "Building Collection",
    },
  ],
  validators: {
    title_id: (value: string) => {
      return utils.validator.validateRequired2(value);
    },
    title_en: (value: string) => {
      return utils.validator.validateRequired2(value);
    },
    icon: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    url: (value: string) => {
      const allowedPath = [
        "/location",
        "/search",
        "/collections",
        "/tipe",
      ];
      const pathAllowed = allowedPath.some((path) =>
        value?.includes(path)
      );

      if (
        value !== "" &&
        (!value?.startsWith("http") || !pathAllowed)
      ) {
        return "URL must start with http:// or https:// (case sensitive) and contain one of these: rukita.co/collections, rukita.co/location, rukita.co/search, rukita.co/tipe";
      } else {
        return utils.validator.validateRequired(value);
      }
    },
    order: (value: number) => {
      return utils.validator.validateRequired(value);
    },
  },
};

export default configBuilder.buildAddEditPageConfig(definitions, [
  "icon",
]);
