import configBuilder from "../../../components/ubold/ConfigBuilder";

import utils from "../../../utils";

let definitions = {
  title: "Recommendation City",
  frontendPath: "/website/recommendation_city",
  baseLink: "/building/recommendation__city",
  restAccessCode: "building.recommendation_city",
  __table__: {
    filterColumn: 4,
    removeIdentifier: "city_name",
    hideEditButton: true,
    filters: [
      {
        id: "city",
        name: "City",
        type: "async_select",
        data: "place/city?__type__=select_entries",
      },
      {
        id: "priority",
        name: "Priority",
        type: "number",
      },
      {
        id: "date_created",
        name: "Date Created",
        type: "date",
      },
      {
        id: "date_updated",
        name: "Date Updated",
        type: "date",
      },
    ],
    columns: [
      {
        id: "city_name",
        name: "City",
        enableHyperlink: true,
      },
      {
        id: "priority",
        name: "Priority",
      },
      {
        id: "date_created",
        name: "Date Created",
        renderer: (value) => {
          return utils.formatter.dateTime(value);
        },
      },
      {
        id: "date_updated",
        name: "Date Updated",
        renderer: (value) => {
          return utils.formatter.dateTime(value);
        },
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
