const checkValue = (state: any, fieldName: string) => {
  const list: boolean[] = [];

  if (state.length === 0) return false;

  for (let idx = 0; idx < state.length; idx++) {
    const Obj = state[idx];
    Object.keys(Obj).forEach((key) => {
      if (key === fieldName) {
        list.push(Obj[fieldName]);
      }
    });
  }
  if (list.length === 0) return false;
  const allTrue = !list.some((val: boolean) => val === false);

  return allTrue;
};

export default checkValue;
