import configBuilder from '../../../../components/ubold/ConfigBuilder';

import pageDefinitions from "../definitions";

import utils from "../../../../utils";

const definitions = {...pageDefinitions,
    validators: {
        email: value => {
            return utils.validator.validateRequired(value);
        },
        name: value => {
            return utils.validator.validateRequired(value);
        },
        vendor: value => {
            return utils.validator.validateRequired(value);
        }
    },
}

export default configBuilder.buildAddEditPageConfig(definitions);