import { useState } from "react";

import { useParams, withRouter } from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import Form from "../../../../components/ubold/organisms/Form";

import CVTextField from "../../../../components/ubold/molecules/forms/CVTextField";
import CVAsyncSelect from "../../../../components/ubold/molecules/forms/CVAsyncSelect";

import definitions from "./definitions";

function CUserVendorViewPage() {
  let { id }: any = useParams();

  const initialState = {};

  const [state, setState] = useState(initialState);

  return (
    <Page
      title={`Detail of ${definitions.title}`}
      restAccessCode={definitions.restAccessCode}
    >
      <Form
        id={id}
        definitions={definitions}
        state={state}
        setState={setState}
        readOnly={true}
      >
        <CVTextField
          name="username"
          title="Username"
          maxLength={100}
          readOnly
        />
        <CVTextField
          name="email"
          title="email"
          maxLength={254}
          isRequired
          readOnly
        />
        <CVTextField
          name="name"
          title="Name"
          maxLength={80}
          isRequired
          readOnly
        />
        <CVAsyncSelect
          name="vendor"
          title="Vendor"
          data="vendor/vendor?__type__=select_entries"
          isRequired
          readOnly
        />
      </Form>
    </Page>
  );
}

export default withRouter(CUserVendorViewPage);
