import configBuilder from "../../../../components/ubold/ConfigBuilder";

import pageDefinitions from "../definitions";

const definitions = {
  ...pageDefinitions,
  tabs: [
    {
      id: "cart_view",
      name: "View Cart",
    },
  ],
};

export default configBuilder.buildAddEditPageConfig(
  definitions,
  [],
  [],
  "detail"
);
