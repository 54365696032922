import { useState } from "react";
import { useParams, withRouter } from "react-router-dom";

import Page from "components/ubold/organisms/Page";
import Form from "components/ubold/organisms/Form";

import CVSelect from "components/ubold/molecules/forms/CVSelect";
import CVAsyncSelect from "components/ubold/molecules/forms/CVAsyncSelect";
import CVTextField from "components/ubold/molecules/forms/CVTextField";
import CVTextView from "components/ubold/molecules/forms/CVTextView";
import CVRichTextField from "components/ubold/molecules/forms/CVRichTextField";
import useGlobalState from "state";

import definitions from "./definitions";

function SeoCmsAddEditPage() {
  let { id }: { id: string } = useParams();
  const initialState = {};
  const [state, setState] = useState(initialState);
  const [constants] = useGlobalState("constant_values");

  return (
    <Page
      title={
        (id === undefined ? "Add New" : "Edit") +
        " " +
        definitions.title
      }
      restAccessCode={definitions.restAccessCode}
    >
      <Form
        id={id}
        definitions={definitions}
        state={state}
        setState={setState}
        readOnly
      >
        <CVTextField
          name="title"
          title="Title ID"
          isRequired
          readOnly
        />
        <CVTextField
          name="title_en"
          title="Title EN"
          isRequired
          readOnly
        />
        <CVRichTextField name="desc" title="Desc ID" readOnly />
        <CVRichTextField name="desc_en" title="Desc EN" readOnly />
        <CVSelect
          name="seo_page"
          title="Seo page"
          data={constants?.["website_cms_seo_page_choice"]}
          readOnly
          isRequired
        />
        <CVSelect
          name="publish_status"
          title="Publish Status"
          data={[
            {
              label: "Publish",
              value: 1,
            },
            {
              label: "Unpublish",
              value: 2,
            },
          ]}
          readOnly
          isRequired
        />
        <CVAsyncSelect
          name="country"
          title="Country"
          data="place/country?__type__=select_entries&limit=10"
          readOnly
        />
        <CVAsyncSelect
          name="province"
          title="Province"
          dependsOn="country"
          data="place/province?__type__=select_entries&limit=10"
          readOnly
        />
        <CVAsyncSelect
          name="city"
          title="City"
          data="place/city?__type__=select_entries&limit=10"
          dependsOn="province"
          readOnly
        />
        <CVTextView name="date_created" title="Date Created" />
        <CVTextView name="date_updated" title="Date Updated" />
      </Form>
    </Page>
  );
}
export default withRouter(SeoCmsAddEditPage);
