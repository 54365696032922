import configBuilder from "components/ubold/ConfigBuilder";
import utils from "utils";

let definitions = {
  title: "Homepage Collection",
  frontendPath: "/website/homepage_collection",
  baseLink: "/homepage-collection",
  restAccessCode: "homepage.homepage_collections",
  __table__: {
    filterColumn: 4,
    hideEditButton: true,
    removeIdentifierNo: true,
    filters: [
      {
        id: "title_id",
        name: "Title ID",
        type: "text",
      },
      {
        id: "url",
        name: "URL",
        type: "text",
      },
      {
        id: "published",
        name: "Published Flag",
        type: "select",
        data: [
          {
            label: "Yes",
            value: true,
          },
          {
            label: "No",
            value: false,
          },
        ],
      },
      {
        id: "created",
        name: "Date Created",
        type: "date",
      },
      {
        id: "updated",
        name: "Date Updated",
        type: "date",
      },
    ],
    columns: [
      {
        id: "title_id",
        name: "Homepage Collection Title ID",
        enableHyperlink: true,
      },
      {
        id: "order",
        name: "Order",
      },
      {
        id: "url",
        name: "URL",
      },
      {
        id: "published",
        name: "Published Flag",
        center: true,
        renderer: (value: string) => {
          if (value === "true") {
            return '<span style="color: rgb(26, 188, 156);"> ✔ </span>';
          } else {
            return '<span style="color: red;">✘</span>';
          }
        },
      },
      {
        id: "date_created",
        name: "Date Created",
        renderer: (value: string) => {
          return utils.formatter.dateTime(value);
        },
      },
      {
        id: "date_updated",
        name: "Date Updated",
        renderer: (value: string) => {
          return utils.formatter.dateTime(value);
        },
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
