import { useParams, withRouter } from "react-router-dom";

import TabPaneSecondary from "../../../../../../components/ubold/organisms/TabPaneSecondary";

import COneOrManyToManyField from "../../../../../../components/ubold/molecules/forms/COneOrManyToManyField";

import utils from "../../../../../../utils";

function InvoiceDetails(props: any) {
  const { definitions, tab, state, setState } = props;

  let { id }: any = useParams();

  return (
    <TabPaneSecondary
      definitions={definitions}
      tab={tab}
      restAccessCodeNamespace="detail"
      state={state}
      setState={setState}
      readOnly={true}
    >
      <COneOrManyToManyField
        id={id}
        name={tab.id}
        restPath="finance/invoice_detail/"
        relationKey="invoice_id"
        columns={[
          {
            name: "tenant_name",
            type: "read_only",
            title: "Tenant",
          },
          {
            name: "category",
            type: "select",
            title: "Category",
            isRequired: true,
            data: [
              {
                label: "Building",
                value: 1,
              },
              {
                label: "Add-On",
                value: 2,
              },
              {
                label: "Service Request",
                value: 3,
              },
              {
                label: "Additional Cost",
                value: 4,
              },
              {
                label: "Discount",
                value: 5,
              },
              {
                label: "Fine Fee",
                value: 6,
              },
              {
                label: "Voucher",
                value: 7,
              },
              {
                label: "Excess Laundry",
                value: 8,
              },
              {
                label: "Discount Prorated",
                value: 9,
              },
              {
                label: "Discount Building",
                value: 10,
              },
            ],
            readOnly: true,
          },
          {
            name: "amount",
            type: "number",
            title: "Amount",
            isRequired: true,
            readOnly: true,
          },
          {
            name: "discount_amount",
            type: "number",
            title: "Discount Amount",
            isRequired: true,
            readOnly: true,
          },
          {
            name: "descriptions",
            type: "textarea",
            title: "Descriptions",
            isRequired: true,
            readOnly: true,
          },
          {
            name: "grand_total",
            type: "read_only",
            title: "Grand Total",
            renderer: (value: number) => {
              return utils.formatter.currency(value);
            },
            readOnly: true,
          },
          {
            name: "transaction_at",
            type: "datetime",
            title: "Transaction At",
            isRequired: true,
            readOnly: true,
          },
        ]}
      />
    </TabPaneSecondary>
  );
}

export default withRouter(InvoiceDetails);
