import Home from "pages/Home";
// DASHBOARD
import Building from "pages/Home/Dashboard/Building";
import Finance from "pages/Home/Dashboard/Finance";
import SalesCC from "pages/Home/Dashboard/Sales/RoomCentralized";
import SalesDC from "pages/Home/Dashboard/Sales/RoomDecentralized";
import SalesRoomAllotmentPage from "pages/Home/Dashboard/Sales/RoomAllotment";

const HomeRoutes = [
  {
    path: "/",
    component: Home,
  },
  // DASHBOARD
  {
    path: "/u/home",
    component: Home,
  },
  {
    path: "/u/dashboard/building",
    component: Building,
  },
  {
    path: "/u/dashboard/finance",
    component: Finance,
  },
  {
    path: "/u/dashboard/sales/all_rooms_cc",
    component: SalesCC,
  },
  {
    path: "/u/dashboard/sales/all_rooms_dc",
    component: SalesDC,
  },
  {
    path: "/u/dashboard/sales/room_allotment",
    component: SalesRoomAllotmentPage,
  },
];

export default HomeRoutes;
