import { withRouter } from "react-router-dom";

import TabPane from "../../../../../../components/ubold/organisms/TabPane";
import TabPaneSection from "../../../../../../components/ubold/organisms/TabPaneSection";

import CHTextField from "../../../../../../components/ubold/molecules/forms/CHTextField";
import CHRadio from "../../../../../../components/ubold/molecules/forms/CHRadio";
import CHSelect from "../../../../../../components/ubold/molecules/forms/CHSelect";
import CHAsyncSelect from "../../../../../../components/ubold/molecules/forms/CHAsyncSelect";
import CHTextArea from "../../../../../../components/ubold/molecules/forms/CHTextArea";
import CHRichTextField from "../../../../../../components/ubold/molecules/forms/CHRichTextField";
import CHCheckBox from "../../../../../../components/ubold/molecules/forms/CHCheckBox";
import CHImageUpload from "../../../../../../components/ubold/molecules/forms/CHImageUpload";
import CHDateTimePicker from "../../../../../../components/ubold/molecules/forms/CHDateTimePicker";
import CHTextView from "../../../../../../components/ubold/molecules/forms/CHTextView";
import CHImageView from "../../../../../../components/ubold/molecules/forms/CHImageView";
import CHSimpleManyToMany from "../../../../../../components/ubold/molecules/forms/CHSimpleManyToMany";

import OtherInfos from "./Sections/OtherInfos";
import Location from "./Sections/Location";
import GeneralServices from "./Sections/GeneralServices";
import Attributes from "./Sections/Attributes";
import CQRCodeAndURL from "components/ubold/atoms/buttons/CQRCodeAndURL";

function Building(props) {
  const { id, definitions, tab, state, setState } = props;
  return (
    <TabPane
      id={id}
      definitions={definitions}
      tab={tab}
      state={state}
      setState={setState}
    >
      <TabPaneSection style={{ padding: "25px 16px 0px" }}>
        <CHTextView name="code" title="Building Code" />
        {/* Start of QR Code and URL Copy Button Section */}
        <CQRCodeAndURL
          label="Building URL and QR Code"
          url={`/place/${state.slug}?utm_campaign=offline&utm_source=offline&utm_medium=offline&utm_content=offline`}
          id={id}
        />
        <CHAsyncSelect
          name="private_partner_company_id"
          title="Private Partner Company"
          data="user/partner_company?__type__=select_entries&limit=1000"
        />
        {/* End of QR Code and URL Copy Button Section */}
        {/* Start of Deposit Holder Button Section */}
        <CHSelect
          name="deposit_holder"
          title="Deposit Holder"
          isRequired
          data={{
            optionField: "building_deposit_holder",
          }}
        />
        {/* End of Deposit Holder Button Section */}
        <CHRadio
          name="building_type"
          title="Type"
          isRequired
          readOnly={id}
          data={[
            {
              label: "Centralized",
              value: 1,
            },
            {
              label: "Decentralized",
              value: 2,
            },
          ]}
        />
        <CHAsyncSelect
          name="building_grade"
          title="Building Grade"
          data="building/grade?__type__=select_entries&limit=1000"
        />
        <CHAsyncSelect
          name="landlord"
          title="Building Landlord"
          isRequired
          data="building/landlord?__type__=select_entries&limit=1000"
        />
        <CHTextField
          name="email_fmr_1"
          title="Email for monthly report (1)"
          maxLength={254}
        />
        <CHTextField
          name="email_fmr_2"
          title="Email for monthly report (2)"
          maxLength={254}
        />
        <CHTextField
          name="building_name"
          title="Name"
          maxLength={64}
          isRequired
        />
        <CHTextField
          isRequired
          name="slug"
          title="Slug"
          maxLength={100}
        />

        <CHTextField
          name="title_tag"
          title="Title Tag"
          maxLength={160}
        />
        <CHTextField
          name="title_tag_en"
          title="Title Tag EN"
          maxLength={160}
        />
        <CHTextArea
          name="meta_description"
          title="Meta Description"
        />
        <CHTextArea
          name="meta_description_en"
          title="Meta Description EN"
        />
        <CHRichTextField name="desc" title="Description" />
        <CHRichTextField name="desc_en" title="Description EN" />
        {/* <CHTextField
          name="start_from"
          title="Start From"
          type="number"
          inputGroups="Number"
        /> */}
        <CHSimpleManyToMany
          name="usp_highlight"
          title="USP Highlight"
          data="building/usp_highlight/?__type__=select_entries&limit=1000&ordering=-date_updated"
        />
        <CHRichTextField name="notes" title="Notes" />
        <CHRichTextField name="rules" title="Rules" />
        <CHCheckBox
          name="active"
          title="Active Status"
          checkboxTitle="Active"
        />
        <CHImageUpload
          name="photo_thumbnail_original"
          title="Building Thumbnail"
        />
        <CHImageView
          name="photo_thumbnail_original"
          title="Building Thumbnail Preview"
        />
        <CHTextField
          name="handyman_number"
          title="Handyman Number"
          maxLength={20}
        />
        <CHTextField
          name="it_support_number"
          title="IT Support Number"
          maxLength={20}
        />
        <CHTextField
          name="housekeeper_number"
          title="House Keeper Number"
          maxLength={20}
        />
        <CHSimpleManyToMany
          name="community_associate"
          title="Community Associates"
          data="crm/user?__type__=select_entries&limit=1000&role=Community Associate"
          itemsExtractor={(row) => {
            return {
              label: row.username,
              value: row.id,
            };
          }}
        />
        <CHSimpleManyToMany
          name="customer_service"
          title="Customer Services"
          data="crm/user?__type__=select_entries&limit=1000&group_name=Customer Service"
          itemsExtractor={(row) => {
            return {
              label: row.username,
              value: row.id,
            };
          }}
        />
        <CHSimpleManyToMany
          name="housekeeper"
          title="Operations Supervisor"
          data="crm/user?__type__=select_entries&limit=1000&role=Operations Supervisor"
          itemsExtractor={(row) => {
            return {
              label: row.username,
              value: row.id,
            };
          }}
        />
        <CHSelect
          name="release_status"
          title="Release Status"
          isRequired
          data={[
            {
              value: 1,
              label: "Hold",
            },
            {
              value: 2,
              label: "Release",
            },
          ]}
        />
        <CHSelect
          name="publish_status"
          title="Publish Status"
          isRequired
          readOnly={!id}
          data={{
            optionField: "buildings_building_published_status",
          }}
        />
        <CHDateTimePicker
          name="terminated_date"
          title="Terminated Date"
          type="date"
        />
        <CHRichTextField
          name="terminated_reason"
          title="Terminated Reason"
        />

        <CHCheckBox
          name="eligible_for_discount"
          title="Eligible for Discount"
        />

        <CHAsyncSelect
          name="discount_banner"
          title="Discount Banner"
          data="building/discount_banner?__type__=select_entries&limit=1000"
          itemsExtractor={(row) => {
            return {
              label: row.title,
              value: row.id,
            };
          }}
        />
        <CHTextField
          name="video_url"
          title="Video URL"
          maxLength={50}
        />
        <CHSelect
          name="commercial_type"
          title="Commercial Type"
          data={{
            optionField: "buildings_commercial_type",
          }}
        />
        <CHSelect
          name="laundry_deduction_to_landlord"
          title="Laundry Deduction to Landlord"
          data={{
            optionField: "buildings_laundry_deduction_to_landlord",
          }}
          isClearable={false}
        />
      </TabPaneSection>
      <TabPaneSection accordionId="formAccordion">
        <GeneralServices />
        <Attributes />
        <Location />
        <OtherInfos />
      </TabPaneSection>
    </TabPane>
  );
}

export default withRouter(Building);
