import { withRouter } from "react-router-dom";

import Flatpickr from "react-flatpickr";

import moment from "moment";

import useGlobalState from "../../../../state";

import utils from "../../../../utils";

function CHDateTimeSplitPicker(props) {
  const {
    name,
    title,
    isRequired = false,
    restAccessCode,
    state,
    setState,
    readOnly,
  } = props;

  const [user] = useGlobalState("user");
  const isSuperUser = user ? user.is_super_user : false;
  const [fieldAccesses] = useGlobalState("field_accesses");
  const [fieldAccessReversePolicy] = useGlobalState(
    "field_access_reverse_policy"
  );

  const flatPickers = [];

  return (
    <div
      className="form-group row mb-3"
      style={{
        display:
          !isRequired &&
          restAccessCode &&
          utils.access.isFieldHidden(
            fieldAccesses,
            restAccessCode + "." + name
          )
            ? "none"
            : "flex",
      }}
    >
      <label
        className={
          "col-md-3 col-form-label" +
          (isRequired ? " font-weight-bold" : "") +
          (state[name + "Error"] ? " text-danger" : "")
        }
      >
        {title}
        {isRequired ? "*" : ""}{" "}
        {readOnly ??
        utils.access.isFieldReadOnly(
          fieldAccesses,
          restAccessCode + "." + name,
          fieldAccessReversePolicy,
          isSuperUser
        )
          ? "(read only)"
          : ""}
      </label>
      <div className="col-md-9">
        <div className="d-flex">
          <div className="flex-fill">
            <label className="col-form-label">Date</label>
            <div className="input-group flatpickr">
              <Flatpickr
                id={name + "Date"}
                name={name + "Date"}
                value={state[name + "Date"]}
                disabled={
                  readOnly ??
                  (restAccessCode &&
                    utils.access.isFieldReadOnly(
                      fieldAccesses,
                      restAccessCode + "." + name,
                      fieldAccessReversePolicy,
                      isSuperUser
                    ))
                }
                placeholder="Select Date.."
                ref={(fp) => {
                  flatPickers["date"] = fp;
                }}
                style={{
                  border: "1px solid #ced4da",
                  width: "100%",
                  padding: "8px 8px 7px 8px",
                }}
                options={{
                  dateFormat: "Y-m-d",
                  enableTime: false,
                }}
                onChange={(date) => {
                  const result = moment(date[0].toISOString()).format(
                    "YYYY-MM-DD"
                  );

                  const timeValue = state[name + "Time"]
                    ? state[name + "Time"]
                    : "00:00:00";

                  setState((prevState) => ({
                    ...prevState,
                    [name + "Date"]: result,
                    [name + "Time"]: timeValue,
                    [name]: result + " " + timeValue,
                  }));
                }}
              />
              <div
                className="input-group-append"
                style={{ position: "absolute", right: "0px" }}
                title="toggle"
              >
                {state[name] !== null && state[name] !== undefined ? (
                  <button
                    className="btn waves-light"
                    type="button"
                    onClick={() => {
                      if (flatPickers) {
                        setState((prevState) => ({
                          ...prevState,
                          [name]: null,
                          [name + "Date"]: null,
                          [name + "Time"]: null,
                        }));
                      }
                    }}
                  >
                    <i className="fas fa-times" />
                  </button>
                ) : (
                  <></>
                )}
                <button
                  className="btn btn-dark waves-effect waves-light"
                  type="button"
                  onClick={() => {
                    if (flatPickers["date"]) {
                      flatPickers["date"].flatpickr.open();
                    }
                  }}
                >
                  <i className="fas fa-calendar-alt" />
                </button>
              </div>
            </div>
          </div>
          <div className="flex-fill pl-4">
            <label className="col-form-label">Time</label>
            <div className="input-group flatpickr">
              <Flatpickr
                id={name + "Time"}
                name={name + "Time"}
                value={state[name + "Time"]}
                disabled={
                  readOnly ??
                  (restAccessCode &&
                    utils.access.isFieldReadOnly(
                      fieldAccesses,
                      restAccessCode + "." + name,
                      fieldAccessReversePolicy,
                      isSuperUser
                    ))
                }
                placeholder={"Select time..."}
                ref={(fp) => {
                  flatPickers["time"] = fp;
                }}
                style={{
                  border: "1px solid #ced4da",
                  width: "100%",
                  padding: "8px 8px 7px 8px",
                }}
                options={{
                  dateFormat: "H:i",
                  enableTime: true,
                  noCalendar: true,
                  placeholder: "Pick a Time",
                }}
                onChange={(date) => {
                  const result = moment(date[0].toISOString()).format(
                    "HH:mm:ss"
                  );

                  const dateValue = state[name + "Date"]
                    ? state[name + "Date"]
                    : moment().format("YYYY-MM-DD");

                  setState((prevState) => ({
                    ...prevState,
                    [name + "Date"]: dateValue,
                    [name + "Time"]: result,
                    [name]: dateValue + " " + result,
                  }));
                }}
              />
              <div
                className="input-group-append"
                style={{ position: "absolute", right: "0px" }}
              >
                {state[name] !== null && state[name] !== undefined ? (
                  <button
                    className="btn waves-light"
                    type="button"
                    onClick={() => {
                      if (flatPickers) {
                        setState((prevState) => ({
                          ...prevState,
                          [name]: null,
                          [name + "Date"]: null,
                          [name + "Time"]: null,
                        }));
                      }
                    }}
                  >
                    <i className="fas fa-times" />
                  </button>
                ) : (
                  <></>
                )}
                <button
                  className="btn btn-dark waves-effect waves-light"
                  type="button"
                  onClick={() => {
                    if (flatPickers["time"]) {
                      flatPickers["time"].flatpickr.open();
                    }
                  }}
                >
                  <i className="fas fa-clock" />
                </button>
              </div>
            </div>
          </div>
        </div>
        {state[name + "Error"] ? (
          <span className="text-danger" style={{ fontSize: "12px" }}>
            {state[name + "Error"]}
          </span>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default withRouter(CHDateTimeSplitPicker);
