import { useParams, withRouter } from "react-router-dom";

import TabPaneSecondary from "../../../../../../components/ubold/organisms/TabPaneSecondary";

import COneOrManyToManyField from "../../../../../../components/ubold/molecules/forms/COneOrManyToManyField";

function SecondTab(props) {
  const { definitions, tab, state, setState } = props;

  let { id } = useParams();

  return (
    <TabPaneSecondary
      definitions={definitions}
      restAccessCodeNamespace="attendance"
      tab={tab}
      state={state}
      setState={setState}
    >
      <COneOrManyToManyField
        id={id}
        name={tab.id}
        restPath="event/event_attendance/"
        relationKey="event_id"
        disableActionBtn
        columns={[
          {
            name: "tenant",
            title: "Tenant",
            type: "async_select",
            isUniqueOption: true,
            disabledAtEdit: true,
            data: "user/tenant?__type__=select_entries",
          },
          {
            name: "building",
            title: "Building Name",
            type: "async_select",
            disabledAtEdit: true,
            data: "building/building?__type__=select_entries",
            itemsExtractor: (row) => {
              return {
                label: row.building_name,
                value: row.id,
              };
            },
          },
          {
            name: "signature_id",
            title: "Signature ID",
            maxLength: 254,
            type: "text",
          },
          {
            name: "status",
            title: "Status",
            type: "select",
            data: {
              optionField: "event_event_attendance_status",
            },
          },
        ]}
      />
    </TabPaneSecondary>
  );
}

export default withRouter(SecondTab);
