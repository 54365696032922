import configBuilder from '../../../../components/ubold/ConfigBuilder';

import pageDefinitions from "../definitions";

import utils from "../../../../utils";

const definitions = {...pageDefinitions,
    tabs: [
        {
            id: 'benefit',
            name: 'Benefit'
        },
        {
            id: 't_benefit_target',
            name: 'Targets'
        },
    ],
    validators: {
        banner_picture_original: value => {
            return utils.validator.validateRequired(value);
        },
        brand_name: value => {
            return utils.validator.validateRequired(value);
        },
        brand_information: value => {
            return utils.validator.validateRequired(value);
        },
        brand_logo_original: value => {
            return utils.validator.validateRequired(value);
        },
        headline_of_benefit: value => {
            return utils.validator.validateRequired(value);
        },
        detail_of_benefits: value => {
            return utils.validator.validateRequired(value);
        },
        how_to_redeem: value => {
            return utils.validator.validateRequired(value);
        },
        tnc: value => {
            return utils.validator.validateRequired(value);
        },
        date_started: value => {
            return utils.validator.validateRequired(value);
        },
        date_ended: value => {
            return utils.validator.validateRequired(value);
        },
        benefit_category: value => {
            return utils.validator.validateRequired(value);
        },
        title_pn: value => {
            return utils.validator.validateRequired(value);
        },
        content_pn: value => {
            return utils.validator.validateRequired(value);
        },
    },
}

export default configBuilder.buildAddEditPageConfig(definitions, [
    'banner_picture_original',
    'brand_logo_original',
    'pn_picture_original'
]);