import {isMobile} from "react-device-detect";

import utils from "../../../utils";

function SecondaryDataHandler(data) {
    let scheduledDay = '-';

    let scheduledDayValue = parseInt(data['selected_days']);

    if(scheduledDayValue === 1) {
        scheduledDay = 'Senin';
    } else if(scheduledDayValue === 2) {
        scheduledDay = 'Selasa';
    } else if(scheduledDayValue === 3) {
        scheduledDay = 'Rabu';
    } else if(scheduledDayValue === 4) {
        scheduledDay = 'Kamis';
    } else if(scheduledDayValue === 5) {
        scheduledDay = 'Jumat';
    } else if(scheduledDayValue === 6) {
        scheduledDay = 'Sabtu';
    } else if(scheduledDayValue === 7) {
        scheduledDay = 'Minggu';
    }

    return <div className={isMobile ? 'secondary-data-panel-mobile' : 'secondary-data-panel'}>
        <div className="row mb-2">
            <div className="col mw-200">
                <div>
                    <b>Building Service</b>: <br/>
                    {data['building_service_name'] ? data['building_service_name'] : '-'}
                </div>
            </div>
            <div className="col mw-200">
                <div>
                    <b>Scheduled Service</b>: <br/>
                    {data['service_name'] ? data['service_name'] : '-'}
                </div>
            </div>
            <div className="col mw-200">
                <div>
                    <b>Scheduled Day</b>: <br/>
                    {scheduledDay}
                </div>
            </div>
            <div className="col mw-200">
                <div>
                    <b>Date Created:</b> <br/>
                    {utils.formatter.date(data['date_created'])}
                </div>
            </div>
            <div className="col mw-200">
                <div>
                    <b>Estimated Date:</b> <br/>
                    {utils.formatter.date(data['estimation_date'])}
                </div>
            </div>
        </div>
    </div>;
};

export default SecondaryDataHandler;