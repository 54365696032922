import { useState } from "react";
import { useParams, withRouter } from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";
import Form from "../../../../components/ubold/organisms/Form";
import CVTextView from "../../../../components/ubold/molecules/forms/CVTextView";
import COneOrManyToManyFieldV2 from "../../../../components/ubold/molecules/forms/COneOrManyToManyFieldV2";
import definitions from "./definitions";

function CaseCategoryViewPage() {
  let { id }: { id: string } = useParams();
  const initialState = !id
    ? {
        sub_categories: [],
      }
    : {};

  const [state, setState] = useState(initialState);

  return (
    <Page
      title={
        (id === undefined ? "Add New" : "Edit") +
        " " +
        definitions.title
      }
      restAccessCode={definitions.restAccessCode}
    >
      <Form
        id={id}
        definitions={definitions}
        state={state}
        setState={setState}
        readOnly={true}
      >
        <CVTextView name="name" title="Name ID" isRequired />
        <CVTextView name="name_en" title="Name EN" isRequired />
        <section
          style={{
            width: "100%",
            background: "#f2f2f2",
            height: 30,
            paddingRight: 24,
            paddingLeft: 29,
            alignItems: "center",
            fontSize: 16,
            fontWeight: 500,
            display: "flex",
          }}
        >
          Sub-Case Category
        </section>
        <COneOrManyToManyFieldV2
          id={id}
          name="sub_categories"
          saveOnlyFromMainForm
          mainTab={true}
          useNewClone
          columns={[
            {
              name: "name",
              title: "Name ID",
              type: "text",
              isRequired: true,
              readOnly: true,
            },
            {
              name: "name_en",
              type: "text",
              title: "Name EN",
              isRequired: true,
              readOnly: true,
            },
          ]}
        />
      </Form>
    </Page>
  );
}

export default withRouter(CaseCategoryViewPage);
