import { withRouter } from "react-router-dom";

import TabPane from "../../../../../../components/ubold/organisms/TabPane";
import TabPaneSection from "../../../../../../components/ubold/organisms/TabPaneSection";

import CHTextView from "../../../../../../components/ubold/molecules/forms/CHTextView";
import CHTextField from "../../../../../../components/ubold/molecules/forms/CHTextField";
import CHSelect from "../../../../../../components/ubold/molecules/forms/CHSelect";
import CHAsyncSelect from "../../../../../../components/ubold/molecules/forms/CHAsyncSelect";
import CHCheckBox from "../../../../../../components/ubold/molecules/forms/CHCheckBox";
import CHRichTextField from "../../../../../../components/ubold/molecules/forms/CHRichTextField";
import CHTextArea from "../../../../../../components/ubold/molecules/forms/CHTextArea";

import utils from "../../../../../../utils";
import { genericTabProps } from "@types";
import CHDateTimePicker from "components/ubold/molecules/forms/CHDateTimePicker";
import CQRCodeAndURL from "components/ubold/atoms/buttons/CQRCodeAndURL";

function Room(
  props: genericTabProps<{
    building: string;
    room_variant: string;
    external_id: string;
  }>
) {
  const { id, definitions, tab, state, setState } = props;

  const buildingId = state.building;

  return (
    <TabPane
      id={id}
      definitions={definitions}
      tab={tab}
      state={state}
      setState={setState}
      readOnly
    >
      <TabPaneSection style={{ padding: "25px 16px 0px" }}>
        <CHTextField
          name="name"
          title="Name"
          isRequired
          maxLength={100}
          helperText="Room identity eg. `100` or `QR1`"
          readOnly
        />
        {/* Start of QR Code and URL Copy Button Section */}
        <CQRCodeAndURL
          label="Building URL and QR Code"
          url={`/book/verify?assetId=${state.building}&variantId=${state.room_variant}&externalRoomId=${state.external_id}&qr=1&utm_campaign=offline&utm_source=offline&utm_medium=offline&utm_content=offline`}
          id={id}
        />
        {/* End of QR Code and URL Copy Button Section */}
        <CHTextView name="building_name" title="Building Name" />
        <CHAsyncSelect
          name="room_variant"
          title="Room Variant"
          isRequired
          readOnly
          data={`building/room_variant/?__type__=select_entries&building_id=${buildingId}`}
        />
        <CHTextField
          name="total_bed"
          title="Total Bed"
          type="number"
          readOnly
        />
        <CHAsyncSelect
          name="room_type"
          title="Room Type"
          data="building/room_type/?__type__=select_entries"
          readOnly
        />
        <CHTextField
          name="area_size"
          title="Area Size"
          type="number"
          readOnly
        />
        <CHTextField
          name="price"
          title="Price"
          type="number"
          helperText="Price should be greater than Rp. 0"
          readOnly
        />
        <CHSelect
          name="electricity"
          title="Electricity"
          data={[
            {
              label: "Centralized",
              value: 1,
            },
            {
              label: "Token",
              value: 2,
            },
          ]}
          readOnly
        />
        <CHRichTextField name="remarks" title="Remarks" readOnly />
        <CHSelect
          name="bathroom"
          title="Bathroom"
          data={[
            {
              label: "Inside Room",
              value: 1,
            },
            {
              label: "Outside Room",
              value: 2,
            },
            {
              label: "Shared Bathroom",
              value: 3,
            },
          ]}
          readOnly
        />
        <CHSelect
          name="gender"
          title="Gender"
          data={[
            {
              label: "All Gender",
              value: 0,
            },
            {
              label: "Male",
              value: 1,
            },
            {
              label: "Female",
              value: 2,
            },
          ]}
          readOnly
        />
        <CHAsyncSelect
          name="discount"
          title="Discount"
          data="finance/discount/?__type__=select_entries"
          readOnly
        />
        <CHTextView
          name="date_created"
          title="Date Created"
          renderer={(value: string) => {
            return value
              ? utils.formatter.dateTime(
                  value,
                  "MMMM DD, YYYY, HH:mm"
                )
              : "-";
          }}
          readOnly
        />
        <CHTextView
          name="date_updated"
          title="Date Updated"
          renderer={(value: string) => {
            return value
              ? utils.formatter.dateTime(
                  value,
                  "MMMM DD, YYYY, HH:mm"
                )
              : "-";
          }}
          readOnly
        />
        <CHSelect
          name="room_status"
          title="Room Status"
          data={{ optionField: "buildings_room_status" }}
          readOnly
        />
        <CHSelect
          name="maintenance_status"
          title="Maintenance Status"
          data={{
            optionField: "buildings_room_maintenance_status",
          }}
          readOnly
        />
        <CHDateTimePicker
          name="date_started_maintenance"
          title="Date Started Maintenance"
          readOnly
          disableMobile
          type="date"
        />
        <CHDateTimePicker
          name="maintenance_date_ended"
          title="Date Ended Maintenance"
          readOnly
          disableMobile
          type="date"
        />
        <CHTextArea
          name="booked_remark"
          title="Booked Remark"
          readOnly
        />
        <CHTextArea
          name="room_status_notes"
          title="Room Status Notes"
          readOnly
        />
        <CHTextField
          name="floor"
          title="Floor"
          maxLength={10}
          readOnly
        />
        <CHCheckBox
          name="booked_by_sales"
          title="Booked by Sales"
          readOnly
        />
        <CHDateTimePicker
          name="live_date"
          title="Room Live Date"
          type="date"
          readOnly
        />
      </TabPaneSection>
    </TabPane>
  );
}

export default withRouter(Room);
