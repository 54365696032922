import { withRouter } from "react-router-dom";

import TabPane from "../../../../../../components/ubold/organisms/TabPane";
import TabPaneSection from "../../../../../../components/ubold/organisms/TabPaneSection";
import CHTextField from "../../../../../../components/ubold/molecules/forms/CHTextField";
import CHSelect from "../../../../../../components/ubold/molecules/forms/CHSelect";
import CHImageUpload from "../../../../../../components/ubold/molecules/forms/CHImageUpload";
import CHTextArea from "../../../../../../components/ubold/molecules/forms/CHTextArea";
import CHRichTextField from "../../../../../../components/ubold/molecules/forms/CHRichTextField";
import CHDateTimePicker from "../../../../../../components/ubold/molecules/forms/CHDateTimePicker";
import CHCheckBox from "../../../../../../components/ubold/molecules/forms/CHCheckBox";
import CHTextView from "../../../../../../components/ubold/molecules/forms/CHTextView";

import utils from "../../../../../../utils";
import CHAsyncSelect from "components/ubold/molecules/forms/CHAsyncSelect";

function FirstTab(props) {
  const { id, definitions, tab, state, setState } = props;

  return (
    <TabPane
      id={id}
      definitions={definitions}
      tab={tab}
      state={state}
      setState={setState}
    >
      <TabPaneSection style={{ padding: "25px 16px 0px" }}>
        <CHTextField
          name="event_name"
          title="Name"
          maxLength={80}
          isRequired
        />
        <CHSelect
          name="event_type"
          title="Event Type"
          isRequired
          data={[
            {
              label: "Internal",
              value: 1,
            },
            {
              label: "External",
              value: 2,
            },
          ]}
        />
        <CHTextField
          name="link_external"
          title="Link External"
          maxLength={300}
        />
        <CHTextField
          name="host"
          title="Host"
          maxLength={160}
          isRequired
        />
        <CHImageUpload name="host_pic_original" title="Host Pic" />
        <CHTextField name="slug" title="Slug" maxLength={100} />
        <CHTextField
          name="title_tag"
          title="Title Tag"
          maxLength={160}
        />
        <CHTextArea
          name="meta_description"
          title="Meta Description"
        />
        <CHRichTextField name="desc" title="Description" />
        <CHImageUpload
          name="photo_original"
          title="Photo"
          isRequired
        />
        <CHTextField name="address" title="Address" maxLength={120} />
        <CHTextField
          name="location"
          title="Location"
          maxLength={120}
        />
        <CHTextField name="lon" title="Longitude" type="number" />
        <CHTextField name="lat" title="Latitude" type="number" />
        <CHDateTimePicker
          name="date_started"
          title="Date Started"
          type="date"
          isRequired
        />
        <CHDateTimePicker
          name="date_ended"
          title="Date Ended"
          type="date"
          isRequired
        />
        <CHDateTimePicker
          name="time_started"
          title="Time Started"
          type="time"
          isRequired
        />
        <CHDateTimePicker
          name="time_ended"
          title="Time Ended"
          type="time"
        />
        <CHCheckBox name="published" title="Published" />
        <CHCheckBox name="cancelled" title="Cancelled" />
        <CHTextField
          name="url"
          title="Registration link"
          maxLength={250}
        />
        <CHDateTimePicker
          name="latest_cancellation_date"
          title="Latest Cancellation Date"
          isRequired
        />
        <CHDateTimePicker
          name="latest_registration_date"
          title="Latest Registration Date"
          isRequired
        />
        <CHTextField name="quota" title="Quota" type="number" />
        <CHSelect
          name="event_mode"
          title="Event Mode"
          data={[
            {
              label: "Offline",
              value: 0,
            },
            {
              label: "Online",
              value: 1,
            },
          ]}
        />
        <CHTextField name="link" title="Event Link" maxLength={250} />
        <CHDateTimePicker
          name="link_publish_date"
          title="link Publish Date"
        />
        <CHSelect
          isRequired
          name="type_notification"
          title="Target Event"
          data={{ optionField: "event_event_target_notification" }}
        />
        <CHAsyncSelect
          isRequired={state["type_notification"] === 3}
          name="building"
          title="Building"
          data="building/building?__type__=select_entries&limit=10"
          itemsExtractor={(row) => {
            return {
              label:
                row.building_name +
                " - " +
                (row.building_type === 1
                  ? "Centralized"
                  : "Decentralized"),
              value: row.id,
            };
          }}
        />
        <CHTextView
          name="date_created"
          title="Date Created"
          renderer={(value) => {
            return utils.formatter.dateTime(value);
          }}
        />
        <CHTextView
          name="date_updated"
          title="Date Updated"
          renderer={(value) => {
            return utils.formatter.dateTime(value);
          }}
        />
      </TabPaneSection>
    </TabPane>
  );
}

export default withRouter(FirstTab);
