import styled, { css } from "styled-components";

export const GridTableHead = styled.div`
  display: grid;
  grid-template-columns: 150px 250px 250px 150px 100px 100px;
  place-items: center;

  border: 1px solid #e7e7e7;

  & > div {
    display: flex;
    justify-content: center;

    width: 100%;

    &:not(:last-child) {
      border-right: 1px solid #e7e7e7;
    }
  }
`;

export const GridItemSingleRow = styled.div<{
  border?: {
    top?: boolean;
    right?: boolean;
    bottom?: boolean;
    left?: boolean;
  };
}>`
  display: grid;
  grid-template-columns: 150px 250px 250px 150px 100px 100px;

  ${({ border }) => {
    const borderTop: boolean = border?.top ? border?.top : false;
    const borderRight: boolean = border?.right ? border?.right : true;
    const borderBottom: boolean = border?.bottom
      ? border?.bottom
      : true;
    const borderLeft: boolean = border?.left ? border?.left : true;

    return css`
      border-top: ${borderTop ? "1px solid #e7e7e7" : "unset"};
      border-right: ${borderRight ? "1px solid #e7e7e7" : "unset"};
      border-bottom: ${borderBottom ? "1px solid #e7e7e7" : "unset"};
      border-left: ${borderLeft ? "1px solid #e7e7e7" : "unset"};
    `;
  }}

  & > div {
    display: flex;
    justify-content: center;

    width: 100%;

    padding: 4px;

    &:not(:last-child) {
      border-right: 1px solid #e7e7e7;
    }

    &:nth-of-type(4) {
      justify-content: space-between;
    }

    &.table {
      &-border-r {
        border-right: 1px solid #e7e7e7;

        &-unset {
          border-right: unset;
        }
      }

      &-border-b {
        border-bottom: 1px solid #e7e7e7;
      }
    }
  }
`;

export const MultipleList = styled.div<{
  grid?: { templateColumns: string };
}>`
  display: grid;
  grid-template-columns: ${({ grid }) =>
    grid?.templateColumns ? grid?.templateColumns : "250px"};

  & > * {
    padding: 4px;

    &:not(:last-child) {
      border-bottom: 1px solid #e7e7e7;
    }
  }
`;

export const PriceLabel = styled.div`
  display: flex;
  justify-content: space-between; ;
`;

const AccordionItemBase = styled.div`
  .card-body {
    padding: unset !important;
  }
`;

export default AccordionItemBase;
