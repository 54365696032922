import configBuilder from "../../../components/ubold/ConfigBuilder";

let definitions = {
  title: "Provinces",
  frontendPath: "/admin/location/province",
  baseLink: "/place/province",
  restAccessCode: "place.province",
  __table__: {
    hideEditButton: true,
    filterColumn: 2,
    filters: [
      {
        id: "name",
        name: "Name",
        type: "text",
      },
      {
        id: "country",
        name: "Country",
        type: "async_select",
        data: "place/country?__type__=select_entries",
      },
    ],
    columns: [
      {
        id: "name",
        name: "Name",
        enableHyperlink: true,
      },
      {
        id: "country_name",
        name: "Country",
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
