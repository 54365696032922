import { useState } from "react";

import { useParams, withRouter } from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import Form from "../../../../components/ubold/organisms/Form";

import CVTextField from "../../../../components/ubold/molecules/forms/CVTextField";
import CVSwitch from "../../../../components/ubold/molecules/forms/CVSwitch";

import definitions from "./definitions";

function PaymentPlanViewPage() {
  let { id }: { id: string } = useParams();

  const initialState = {
    full_paid: false,
    discount_amount: 0,
  };

  const [state, setState] = useState(initialState);

  return (
    <Page
      title={"Detail of " + definitions.title}
      restAccessCode={definitions.restAccessCode}
    >
      <Form
        id={id}
        definitions={definitions}
        readOnly
        state={state}
        setState={setState}
      >
        <CVTextField
          title="Plan"
          name="plan"
          type="number"
          isRequired
          readOnly
        />
        <CVTextField
          title="Discount in %"
          name="discount_amount"
          type="number"
          isRequired
          readOnly
        />
        <CVSwitch title="Full Paid" name="full_paid" />
      </Form>
    </Page>
  );
}

export default withRouter(PaymentPlanViewPage);
