import configBuilder from "../../../../components/ubold/ConfigBuilder";

import pageDefinitions from "../definitions";

import utils from "../../../../utils";

const definitions = {
  ...pageDefinitions,
  validators: {
    name: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    display_name: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    bank: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    bank_account_name: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    bank_account_number: (value: number) => {
      return utils.validator.validateRequired(value);
    },
    roles: (value: number) => {
      return utils.validator.validateRequired(value);
    },
  },
};

export default configBuilder.buildAddEditPageConfig(definitions);
