// PROPERTY LIST
import BuildingProperty from "pages/Building/Building";
import BuildingPropertyAddEdit from "pages/Building/Building/AddEdit";
import BuildingPropertyViewOnly from "pages/Building/Building/ViewOnly";
import BuildingPropertyHistory from "pages/Building/Building/History";
// GRADE
import BuildingGrade from "pages/Building/Grade";
import BuildingGradeAddEdit from "pages/Building/Grade/AddEdit";
import BuildingGradeViewOnly from "pages/Building/Grade/ViewOnly";
//
// ROOM
//
// ROOM TYPE
import BuildingRoomType from "pages/Building/RoomType";
import BuildingRoomTypeAddEdit from "pages/Building/RoomType/AddEdit";
import BuildingRoomTypeViewOnly from "pages/Building/RoomType/ViewOnly";
import BuildingRoomTypeHistory from "pages/Building/RoomType/History";
// ROOMS
import BuildingRoom from "pages/Building/Room";
import BuildingRoomAddEdit from "pages/Building/Room/AddEdit";
import BuildingRoomViewOnly from "pages/Building/Room/ViewOnly";
import BuildingRoomHistory from "pages/Building/Room/History";
import RoomPrintQR from "pages/Building/Room/RoomPrintQR";

// ROOM VARIANTS
import BuildingRoomVariant from "pages/Building/RoomVariant";
import BuildingRoomVariantAddEdit from "pages/Building/RoomVariant/AddEdit";
import BuildingRoomVariantViewOnly from "pages/Building/RoomVariant/ViewOnly";
import BuildingRoomVariantHistory from "pages/Building/RoomVariant/History";
//
// FACILITY
//
// FACILITIES
import BuildingFacility from "pages/Building/Facility";
import BuildingFacilityAddEdit from "pages/Building/Facility/AddEdit";
import BuildingFacilityViewOnly from "pages/Building/Facility/ViewOnly";
// PROPERTIES
import BuildingFacilityProperty from "pages/Building/Property";
import BuildingFacilityPropertyAddEdit from "pages/Building/Property/AddEdit";
import BuildingFacilityPropertyViewOnly from "pages/Building/Property/ViewOnly";
//
// ADDONS
import BuildingAddon from "pages/Building/Addon";
import BuildingAddonAddEdit from "pages/Building/Addon/AddEdit";
import BuildingAddonViewOnly from "pages/Building/Addon/ViewOnly";
import BuildingAddonHistory from "pages/Building/Addon/History";
// ADDON CATEGORY
import BuildingAddonCategory from "pages/Building/AddonCategory";
import BuildingAddonCategoryAddEdit from "pages/Building/AddonCategory/AddEdit";
import BuildingAddonCategoryViewOnly from "pages/Building/AddonCategory/ViewOnly";
//
// AGREEMENT
//
// AGREEMENTS
import BuildingAgreement from "pages/Building/Agreement";
import BuildingAgreementAddEdit from "pages/Building/Agreement/AddEdit";
import BuildingAgreementViewOnly from "pages/Building/Agreement/ViewOnly";
// TIRING ESCALATION
import BuildingTiringEscalation from "pages/Building/TiringEscalation";
import BuildingTiringEscalationAddEdit from "pages/Building/TiringEscalation/AddEdit";
import BuildingTiringEscalationViewOnly from "pages/Building/TiringEscalation/ViewOnly";
//
// STAFFS
import BuildingStaff from "pages/Building/Staff";
import BuildingStaffAddEdit from "pages/Building/Staff/AddEdit";
import BuildingStaffViewOnly from "pages/Building/Staff/ViewOnly";
//
// ONE TIME PURCHASE
//
// PRODUCTS
import BuildingOTPProduct from "pages/Building/OTPProduct";
import BuildingOTPProductAddEdit from "pages/Building/OTPProduct/AddEdit";
import BuildingOTPProductViewOnly from "pages/Building/OTPProduct/ViewOnly";
// CATEGORY
import BuildingOTPCategory from "pages/Building/OTPCategory";
import BuildingOTPCategoryAddEdit from "pages/Building/OTPCategory/AddEdit";
import BuildingOTPCategoryViewOnly from "pages/Building/OTPCategory/ViewOnly";
// USP HIGHLIGHT
import USPHighlight from "pages/Building/USPHighlight";
import USPHighlightAddEdit from "pages/Building/USPHighlight/AddEdit";
import USPHighlightViewOnly from "pages/Building/USPHighlight/ViewOnly";
// POI SUB MODULES
import CategoryPoi from "pages/Building/POI/MasterCategory";
import CategoryPoiAddEdit from "pages/Building/POI/MasterCategory/AddEdit";
import CategoryPoiViewOnly from "pages/Building/POI/MasterCategory/ViewOnly";
import BuildingPointOfInterest from "pages/Building/PointOfInterest";
import BuildingPointOfInterestAddEdit from "pages/Building/PointOfInterest/AddEdit";
import BuildingPointOfInterestViewOnly from "pages/Building/PointOfInterest/ViewOnly";
import BuildingPrintQR from "pages/Building/Building/BuildingPrintQR";

const BuildingRoutes = [
  // PROPERTY LIST
  {
    path: "/building/property_list",
    component: BuildingProperty,
  },
  {
    path: "/building/property_list/(add|edit)/:id([0-9]+)?",
    component: BuildingPropertyAddEdit,
  },
  {
    path: "/building/property_list/view/:id([0-9]+)?",
    component: BuildingPropertyViewOnly,
  },
  {
    path: "/building/property_list/history/:id([0-9]+)?",
    component: BuildingPropertyHistory,
  },
  {
    path: "/building/property_list/(add|edit|view)/:id([0-9]+)/print_qr",
    component: BuildingPrintQR,
  },
  // GRADE
  {
    path: "/building/grade",
    component: BuildingGrade,
  },
  {
    path: "/building/grade/(add|edit)/:id([0-9]+)?",
    component: BuildingGradeAddEdit,
  },
  {
    path: "/building/grade/view/:id([0-9]+)?",
    component: BuildingGradeViewOnly,
  },
  //
  // ROOM
  //
  // ROOM TYPE
  {
    path: "/building/room/room_type",
    component: BuildingRoomType,
  },
  {
    path: "/building/room/room_type/(add|edit)/:id([0-9]+)?",
    component: BuildingRoomTypeAddEdit,
  },
  {
    path: "/building/room/room_type/view/:id([0-9]+)?",
    component: BuildingRoomTypeViewOnly,
  },
  {
    path: "/building/room/room_type/history/:id([0-9]+)?",
    component: BuildingRoomTypeHistory,
  },
  // ROOMS
  {
    path: "/building/room/room",
    component: BuildingRoom,
  },
  {
    path: "/building/room/room/(add|edit)/:id([0-9]+)?",
    component: BuildingRoomAddEdit,
  },
  {
    path: "/building/room/room/view/:id([0-9]+)?",
    component: BuildingRoomViewOnly,
  },
  {
    path: "/building/room/room/history/:id([0-9]+)?",
    component: BuildingRoomHistory,
  },
  {
    path: "/building/room/room/(add|edit|view)/:id([0-9]+)/print_qr",
    component: RoomPrintQR,
  },
  // ROOM VARIANTS
  {
    path: "/building/room/room_variant",
    component: BuildingRoomVariant,
  },
  {
    path: "/building/room/room_variant/(add|edit)/:id([0-9]+)?",
    component: BuildingRoomVariantAddEdit,
  },
  {
    path: "/building/room/room_variant/view/:id([0-9]+)?",
    component: BuildingRoomVariantViewOnly,
  },
  {
    path: "/building/room/room_variant/history/:id([0-9]+)?",
    component: BuildingRoomVariantHistory,
  },

  //
  // FACILITY
  //
  // FACILITIES
  {
    path: "/building/facility/facility",
    component: BuildingFacility,
  },
  {
    path: "/building/facility/facility/(add|edit)/:id([0-9]+)?",
    component: BuildingFacilityAddEdit,
  },
  {
    path: "/building/facility/facility/view/:id([0-9]+)?",
    component: BuildingFacilityViewOnly,
  },
  // PROPERTIES
  {
    path: "/building/facility/property",
    component: BuildingFacilityProperty,
  },
  {
    path: "/building/facility/property/(add|edit)/:id([0-9]+)?",
    component: BuildingFacilityPropertyAddEdit,
  },
  {
    path: "/building/facility/property/view/:id([0-9]+)?",
    component: BuildingFacilityPropertyViewOnly,
  },
  //
  // ADDONS
  {
    path: "/building/addon",
    component: BuildingAddon,
  },
  {
    path: "/building/addon/(add|edit)/:id([0-9]+)?",
    component: BuildingAddonAddEdit,
  },
  {
    path: "/building/addon/view/:id([0-9]+)?",
    component: BuildingAddonViewOnly,
  },
  {
    path: "/building/addon/history/:id([0-9]+)?",
    component: BuildingAddonHistory,
  },
  // ADDON CATEGORY
  {
    path: "/building/addon_category",
    component: BuildingAddonCategory,
  },
  {
    path: "/building/addon_category/(add|edit)/:id([0-9]+)?",
    component: BuildingAddonCategoryAddEdit,
  },
  {
    path: "/building/addon_category/view/:id([0-9]+)?",
    component: BuildingAddonCategoryViewOnly,
  },
  //
  // AGREEMENT
  //
  // AGREEMENTS
  {
    path: "/building/agreement/building_agreement",
    component: BuildingAgreement,
  },
  {
    path: "/building/agreement/building_agreement/(add|edit)/:id([0-9]+)?",
    component: BuildingAgreementAddEdit,
  },
  {
    path: "/building/agreement/building_agreement/view/:id([0-9]+)?",
    component: BuildingAgreementViewOnly,
  },
  // TIRING ESCALATION
  {
    path: "/building/agreement/tiring_escalation",
    component: BuildingTiringEscalation,
  },
  {
    path: "/building/agreement/tiring_escalation/(add|edit)/:id([0-9]+)?",
    component: BuildingTiringEscalationAddEdit,
  },
  {
    path: "/building/agreement/tiring_escalation/view/:id([0-9]+)?",
    component: BuildingTiringEscalationViewOnly,
  },
  //
  // STAFFS
  {
    path: "/building/staff",
    component: BuildingStaff,
  },
  {
    path: "/building/staff/(add|edit)/:id([0-9]+)?",
    component: BuildingStaffAddEdit,
  },
  {
    path: "/building/staff/view/:id([0-9]+)?",
    component: BuildingStaffViewOnly,
  },
  //
  // ONE TIME PURCHASE
  //
  // PRODUCTS
  {
    path: "/building/onetimepurchase/product",
    component: BuildingOTPProduct,
  },
  {
    path: "/building/onetimepurchase/product/(add|edit)/:id([0-9]+)?",
    component: BuildingOTPProductAddEdit,
  },
  {
    path: "/building/onetimepurchase/product/view/:id([0-9]+)?",
    component: BuildingOTPProductViewOnly,
  },
  // CATEGORY
  {
    path: "/building/onetimepurchase/category",
    component: BuildingOTPCategory,
  },
  {
    path: "/building/onetimepurchase/category/(add|edit)/:id([0-9]+)?",
    component: BuildingOTPCategoryAddEdit,
  },
  {
    path: "/building/onetimepurchase/category/view/:id([0-9]+)?",
    component: BuildingOTPCategoryViewOnly,
  },
  // USP HIGHLIGHT
  {
    path: "/building/usp_highlight",
    component: USPHighlight,
  },
  {
    path: "/building/usp_highlight/(add|edit)/:id([0-9]+)?",
    component: USPHighlightAddEdit,
  },
  {
    path: "/building/usp_highlight/view/:id([0-9]+)?",
    component: USPHighlightViewOnly,
  },
  // POI
  {
    path: "/building/poi/category_point_of_interest",
    component: CategoryPoi,
  },
  {
    path: "/building/poi/category_point_of_interest/(add|edit)/:id([0-9]+)?",
    component: CategoryPoiAddEdit,
  },
  {
    path: "/building/poi/category_point_of_interest/view/:id([0-9]+)?",
    component: CategoryPoiViewOnly,
  },
  {
    path: "/building/point_of_interest",
    component: BuildingPointOfInterest,
  },
  {
    path: "/building/point_of_interest/(add|edit)/:id([0-9]+)?",
    component: BuildingPointOfInterestAddEdit,
  },
  {
    path: "/building/point_of_interest/view/:id([0-9]+)?",
    component: BuildingPointOfInterestViewOnly,
  },
];

export default BuildingRoutes;
