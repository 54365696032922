import React from "react";
import Page from "components/ubold/organisms/Page";
import TableHistory from "components/ubold/organisms/TableHistory/TableHistory";

import definitions from "../definitions";

const DiscountMasterHistoryPage: React.FC = () => {
  return (
    <Page title={definitions.title + " History"}>
      <TableHistory {...definitions} />
    </Page>
  );
};

export default DiscountMasterHistoryPage;
