import configBuilder from "../../../../components/ubold/ConfigBuilder";

import pageDefinitions from "../definitions";

import utils from "../../../../utils";

// 14/07/2023: update sales needs to ease tenant creation validation
const definitions = {
  ...pageDefinitions,
  linkHistory: (id) => `${definitions.frontendPath}/history/${id}`,
  tabs: [
    {
      id: "tenant",
      name: "Tenant",
    },
    {
      id: "t_ruki_koins",
      name: "RukiKoin",
    },
  ],
  validators: {
    /** General Section */
    name: (value) => {
      if (value && !/^[a-zA-Z0-9\s]+$/.test(value)) {
        return "This is not valid name format.";
      } else {
        return utils.validator.validateRequired(value);
      }
    },
    gender: (value) => {
      // 14/07/2023
      // return utils.validator.validateRequired(value);
      return true;
    },
    dob: (value) => {
      // 14/07/2023
      // return utils.validator.validateRequired(value);
      return true;
    },
    phone_number: (value) => {
      const valToString = value?.toString();
      if (valToString?.length > 0 && valToString?.length < 8) {
        return "Min phone number length is 8 characters";
      } else {
        return utils.validator.validateRequired(value);
      }
    },
    email: (value) => {
      if (
        value &&
        !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          value
        )
      ) {
        return "This is not valid email.";
      } else {
        return utils.validator.validateRequired(value);
      }
    },
    password: (value, state) => {
      if (!state.id && !value) {
        return "Password should be mandatory when adding new tenant.";
      }
      return true;
    },
    password_confirm: (value, state) => {
      if (state.id) return true;
      if (
        state.password &&
        state.password !== "" &&
        state.password !== value
      ) {
        return "Confirm password should be the same with password. Value is not same.";
      }
      return true;
    },
    country: (value) => {
      // 14/07/2023
      // return utils.validator.validateRequired(value);
      return true;
    },
    province: (value) => {
      // 14/07/2023
      // return utils.validator.validateRequired(value);
      return true;
    },
    city: (value) => {
      // 14/07/2023
      // return utils.validator.validateRequired(value);
      return true;
    },
    district: (value) => {
      // 14/07/2023
      // return utils.validator.validateRequired(value);
      return true;
    },
    subdistrict: (value) => {
      // 14/07/2023
      // return utils.validator.validateRequired(value);
      return true;
    },
    address: (value) => {
      // 14/07/2023
      // return utils.validator.validateRequired(value);
      return true;
    },
    identity_type: (value) => {
      // 14/07/2023
      // return utils.validator.validateRequired(value);
      return true;
    },
    identity_id: (value) => {
      // 14/07/2023
      // return utils.validator.validateRequired(value);
      return true;
    },
    /** Emergency Contact Section */
    emergency_phone_number: (value) => {
      // 14/07/2023
      // const valToString = value?.toString();
      // if (valToString?.length > 0 && valToString?.length < 8) {
      //   return "Min phone number length is 8 characters";
      // } else {
      //   return utils.validator.validateRequired(value);
      // }
      return true;
    },
    emergency_relation: (value) => {
      // 14/07/2023
      // return utils.validator.validateRequired(value);
      return true;
    },
    emergency_name: (value) => {
      // 14/07/2023
      // if (value && !/^[a-zA-Z0-9\s]+$/.test(value)) {
      //   return "This is not valid name format.";
      // } else {
      //   return utils.validator.validateRequired(value);
      // }
      return true;
    },
    id_card_photo_original: (value) => {
      // 14/07/2023
      // return utils.validator.validateRequired(value);
      return true;
    },
  },
};

export default configBuilder.buildAddEditPageConfig(
  definitions,
  [
    "photo_original",
    "id_card_photo_original",
    "payment_receipt_original",
  ],
  [],
  "edit",
  (data) => {
    if (data.country) {
      data.country = 84;
    }

    return data;
  }
);
