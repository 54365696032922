import {useState} from "react";
import {useParams, withRouter} from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import Form from "../../../../components/ubold/organisms/Form";

import CVTextView from "../../../../components/ubold/molecules/forms/CVTextView";

import definitions from "./definitions";

function DiscountAddEditPage() {
    let {id} = useParams();

    const initialState = {};

    const [state, setState] = useState(initialState);

    return (
        <Page title={'Detail ' +  definitions.title}
              restAccessCode={definitions.restAccessCode}>
            <Form
                id={id}
                definitions={definitions}
                readOnly
                state={state}
                setState={setState}>
                <CVTextView
                    title='Tenant'
                    name='tenant_name'
                    renderer={(value, state) => {
                        return value + ' - ' + state['tenant_email'];
                    }}
                />
                <CVTextView
                    title='Order'
                    name='order_number'
                    renderer={value => {
                        return value;
                    }}
                />
                <CVTextView
                    title='Category'
                    name='category'
                    renderer={value => {
                        if(parseInt(value) === 1) {
                            return 'IN';
                        } else if(parseInt(value) === 2) {
                            return 'OUT';
                        } else {
                            return '-';
                        }
                    }}
                />
                <CVTextView
                    title='Koin'
                    name='koin'
                />
                <CVTextView
                    title='Source'
                    name='source'
                    renderer={value => {
                        if(parseInt(value) === 1) {
                            return 'Referral';
                        } else if(parseInt(value) === 2) {
                            return 'Voucher';
                        } else if(parseInt(value) === 3) {
                            return 'Event';
                        } else if(parseInt(value) === 5) {
                            return 'Expired';
                        } else {
                            return '-';
                        }
                    }}
                />
                <CVTextView
                    title='Provider'
                    name='provider'
                />
            </Form>
        </Page>
    );
}

export default withRouter(DiscountAddEditPage);