import { useParams, withRouter } from "react-router-dom";

import TabPaneSecondary from "../../../../../../components/ubold/organisms/TabPaneSecondary";

import COneOrManyToManyField from "../../../../../../components/ubold/molecules/forms/COneOrManyToManyField";

function HistoryStatusOrders(props: any) {
  const { definitions, tab, state, setState } = props;

  let { id }: any = useParams();

  return (
    <TabPaneSecondary
      definitions={definitions}
      tab={tab}
      restAccessCodeNamespace="history_status_order"
      state={state}
      setState={setState}
      readOnly={true}
    >
      <COneOrManyToManyField
        id={id}
        name={tab.id}
        readOnly={true}
        restPath="one_time_purchase/history_status_order/"
        relationKey="order_id"
        saveOnlyFromMainForm={true}
        columns={[
          {
            name: "otp_status_text",
            type: "text",
            title: "STATUS",
            isReadOnly: true,
          },
          {
            name: "change_time",
            type: "datetime",
            title: "CHANGE TIME",
            isReadOnly: true,
          },
        ]}
      />
    </TabPaneSecondary>
  );
}

export default withRouter(HistoryStatusOrders);
