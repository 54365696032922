import { withRouter } from "react-router-dom";

import CCheckBox from "../../atoms/forms/CCheckBox";

import useGlobalState from "../../../../state";

import utils from "../../../../utils";

function CHCheckBox(props) {
  const {
    name,
    title,
    checkboxTitle,
    restAccessCode,
    isRequired = false,
    helperText = null,
    state,
    setState,
    readOnly,
    disabled = false,
    onChange,
  } = props;

  const [user] = useGlobalState("user");
  const isSuperUser = user ? user.is_super_user : false;
  const [fieldAccesses] = useGlobalState("field_accesses");
  const [fieldAccessReversePolicy] = useGlobalState(
    "field_access_reverse_policy"
  );

  return (
    <div
      className="form-group row mb-3"
      style={{
        display:
          !isRequired &&
          restAccessCode &&
          utils.access.isFieldHidden(
            fieldAccesses,
            restAccessCode + "." + name
          )
            ? "none"
            : "flex",
      }}
    >
      <label
        className={
          "col-md-3 col-form-label" +
          (isRequired ? " font-weight-bold" : "") +
          (state[name + "Error"] ? " text-danger" : "")
        }
      >
        {title}
        {isRequired ? "*" : ""}{" "}
        {readOnly ??
        utils.access.isFieldReadOnly(
          fieldAccesses,
          restAccessCode + "." + name,
          fieldAccessReversePolicy,
          isSuperUser
        )
          ? "(read only)"
          : ""}
      </label>
      <div className="col-md-9">
        <div className="checkbox checkbox-success d-flex align-items-center px-1">
          <CCheckBox
            name={name}
            disabled={
              disabled ||
              readOnly ||
              (restAccessCode &&
                utils.access.isFieldReadOnly(
                  fieldAccesses,
                  restAccessCode + "." + name,
                  fieldAccessReversePolicy,
                  isSuperUser
                ))
            }
            state={state}
            setState={setState}
            onChange={onChange}
          />
          <label htmlFor={name} style={{ fontWeight: "normal" }}>
            {checkboxTitle}
          </label>
        </div>
        {helperText ? (
          <>
            <br />
            <span
              className="text-muted font-13"
              dangerouslySetInnerHTML={{ __html: helperText }}
            />
          </>
        ) : (
          ""
        )}
        {state[name + "Error"] ? (
          <>
            <br />
            <span
              className="text-danger"
              style={{ fontSize: "12px" }}
            >
              {state[name + "Error"]}
            </span>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default withRouter(CHCheckBox);
