import { Link, useLocation } from "react-router-dom";
import logoWhiteSmall from "../../assets/logo-white-all.png";

import useGlobalState from "../../state";
import { Tooltip, withStyles } from "@material-ui/core";

function Sidebar() {
  const location = useLocation();

  const params = new URLSearchParams(window.location.search);

  const pageType = params.get("__page_type__");

  const [menus] = useGlobalState("menus");

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      fontSize: theme.typography.pxToRem(15),
    },
  }))(Tooltip);

  return (
    <div
      className="left-side-menu"
      style={{
        display:
          pageType && pageType === "no_frame" ? "none" : "block",
      }}
    >
      <div className="h-100">
        <div className="sidebar-content">
          <div className="sidebar-icon-menu h-100" data-simplebar>
            <a
              href="/u/home"
              className="logo"
              style={{
                position: "fixed",
                backgroundColor: "#35B0A7",
              }}
            >
              <span>
                <img src={logoWhiteSmall} alt="" height="27" />
              </span>
              {!menus ? (
                <div className="d-flex justify-content-center">
                  <div
                    className="spinner-border spinner-border-sm m-2"
                    style={{ color: "#ffffff" }}
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </a>
            <nav
              className="nav flex-column"
              style={{ margin: "0 auto", marginTop: "70px" }}
              id="two-col-sidenav-main"
            >
              {menus &&
                menus.entries.map((value) => {
                  const menus = [];

                  if (value.entries) {
                    for (let i = 0; i < value.entries.length; i++) {
                      let isActive = false;

                      if (
                        value.entries[i].exact &&
                        value.entries[i].link === location.pathname
                      ) {
                        isActive = true;
                      } else if (
                        !value.entries[i].exact &&
                        location.pathname.startsWith(
                          value.entries[i].link
                        )
                      ) {
                        isActive = true;
                      }

                      menus.push(
                        <HtmlTooltip
                          title={
                            value.entries[i].name === "Com Event"
                              ? "Community Event"
                              : value.entries[i].name
                          }
                          arrow
                          placement="right"
                        >
                          <a
                            className={
                              "nav-link " + (isActive ? "active" : "")
                            }
                            href={
                              "#menu_" +
                              value.entries[i].name
                                .replace(/ /g, "")
                                .toLowerCase() +
                              "_1"
                            }
                          >
                            <i
                              className={
                                value.entries[i].icon +
                                " icon-sidebar"
                              }
                            />
                            <div
                              style={{
                                marginTop: "-18px",
                                color: "#ffffff",
                                fontSize: "12px",
                              }}
                            >
                              {value.entries[i].name}
                            </div>
                          </a>
                        </HtmlTooltip>
                      );
                    }
                  }

                  return menus;
                })}
            </nav>
          </div>
          <div
            className="sidebar-main-menu"
            style={{ overflow: "auto" }}
          >
            <div id="two-col-menu" className="h-100">
              {!menus ? (
                <div className="d-flex justify-content-center">
                  <div
                    className="spinner-border spinner-border-sm m-2"
                    style={{ color: "#35B0A7" }}
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                <></>
              )}
              {menus &&
                menus.entries.map((value) => {
                  const menus = [];

                  if (value.entries) {
                    for (let i = 0; i < value.entries.length; i++) {
                      const menuItems = [];
                      let isActiveMenuBlock = false;

                      for (
                        let j = 0;
                        j < value.entries[i].entries.length;
                        j++
                      ) {
                        const entry = value.entries[i].entries[j];

                        let isActive = false;

                        const regexExpression =
                          "(?!\\_)(\\?|\\/|\\#|)(.*?)$";

                        const regex = new RegExp(
                          value.entries[i].entries[j].link +
                            regexExpression,
                          "g"
                        );

                        if (
                          value.entries[i].entries[j].exact &&
                          value.entries[i].entries[j].link ===
                            location.pathname
                        ) {
                          isActive = true;
                          isActiveMenuBlock = true;
                        } else if (
                          regex.test(
                            location.pathname + location.search
                          )
                        ) {
                          isActive = true;
                          isActiveMenuBlock = true;
                        }

                        if (entry.entries) {
                          let thirdLevelMenus = [];

                          for (
                            let k = 0;
                            k < entry.entries.length;
                            k++
                          ) {
                            if (entry.entries[k].entries) {
                              let fourthLevelMenus = [];
                              for (
                                let l = 0;
                                l < entry.entries[k].entries.length;
                                l++
                              ) {
                                const fourthLevelIsActiveRegex =
                                  new RegExp(
                                    entry.entries[k].entries[l].link +
                                      regexExpression,
                                    "g"
                                  );

                                let fourthLevelIsActive = false;

                                if (
                                  entry.entries[k].entries[l].exact &&
                                  entry.entries[k].entries[l] ===
                                    location.pathname
                                ) {
                                  fourthLevelIsActive = true;
                                } else if (
                                  fourthLevelIsActiveRegex.test(
                                    location.pathname +
                                      location.search
                                  )
                                ) {
                                  fourthLevelIsActive = true;
                                }

                                fourthLevelMenus.push(
                                  <li>
                                    <Link
                                      className={
                                        "nav-link " +
                                        (fourthLevelIsActive
                                          ? "active"
                                          : "")
                                      }
                                      to={
                                        entry.entries[k].entries[l]
                                          .link
                                          ? entry.entries[k].entries[
                                              l
                                            ].link
                                          : "/"
                                      }
                                    >
                                      {
                                        entry.entries[k].entries[l]
                                          .name
                                      }
                                    </Link>
                                  </li>
                                );
                              }

                              const thirdLevelIsActiveRegex =
                                new RegExp(
                                  entry.entries[k].link +
                                    regexExpression,
                                  "g"
                                );

                              let thirdLevelIsActive = false;

                              if (
                                entry.entries[k].exact &&
                                entry.entries[k] === location.pathname
                              ) {
                                thirdLevelIsActive = true;
                              } else if (
                                thirdLevelIsActiveRegex.test(
                                  location.pathname + location.search
                                )
                              ) {
                                thirdLevelIsActive = true;
                              }

                              thirdLevelMenus.push(
                                <li>
                                  <a
                                    href={
                                      "#menu_" +
                                      entry.entries[k].name
                                        .replace(/ /g, "")
                                        .toLowerCase()
                                    }
                                    data-toggle="collapse"
                                    className="nav-link"
                                    aria-expanded={thirdLevelIsActive}
                                  >
                                    <span>
                                      {entry.entries[k].name}
                                    </span>
                                    <span className="menu-arrow" />
                                  </a>
                                  <div
                                    className={
                                      "collapse " +
                                      (thirdLevelIsActive
                                        ? "show"
                                        : "")
                                    }
                                    id={
                                      "menu_" +
                                      entry.entries[k].name
                                        .replace(/ /g, "")
                                        .toLowerCase()
                                    }
                                  >
                                    <ul className="nav-second-level">
                                      {fourthLevelMenus}
                                    </ul>
                                  </div>
                                </li>
                              );
                            } else {
                              const thirdLevelIsActiveRegex =
                                new RegExp(
                                  entry.entries[k].link +
                                    regexExpression,
                                  "g"
                                );

                              let thirdLevelIsActive = false;

                              if (
                                entry.entries[k].exact &&
                                entry.entries[k] === location.pathname
                              ) {
                                thirdLevelIsActive = true;
                              } else if (
                                thirdLevelIsActiveRegex.test(
                                  location.pathname + location.search
                                )
                              ) {
                                thirdLevelIsActive = true;
                              }

                              thirdLevelMenus.push(
                                <li>
                                  <Link
                                    className={
                                      "nav-link " +
                                      (thirdLevelIsActive
                                        ? "active"
                                        : "")
                                    }
                                    to={
                                      entry.entries[k].link
                                        ? entry.entries[k].link
                                        : "/"
                                    }
                                  >
                                    {entry.entries[k].name}
                                  </Link>
                                </li>
                              );
                            }
                          }

                          menuItems.push(
                            <li className="nav-item">
                              <a
                                href={
                                  "#menu_" +
                                  entry.name
                                    .replace(/ |&/g, "")
                                    .toLowerCase()
                                }
                                data-toggle="collapse"
                                className="nav-link"
                                aria-expanded={isActive}
                              >
                                <span>{entry.name}</span>
                                <span className="menu-arrow" />
                              </a>
                              <div
                                className={
                                  "collapse " +
                                  (isActive ? "show" : "")
                                }
                                id={
                                  "menu_" +
                                  entry.name
                                    .replace(/ |&/g, "")
                                    .toLowerCase()
                                }
                              >
                                <ul className="nav-second-level">
                                  {thirdLevelMenus}
                                </ul>
                              </div>
                            </li>
                          );
                        } else {
                          menuItems.push(
                            <li className={"nav-item "}>
                              <Link
                                className={
                                  "nav-link " +
                                  (isActive ? "active" : "")
                                }
                                to={entry.link ? entry.link : "/"}
                              >
                                {entry.name}
                              </Link>
                            </li>
                          );
                        }
                      }

                      menus.push(
                        <div
                          className={
                            "twocolumn-menu-item " +
                            (isActiveMenuBlock
                              ? "menuitem-active d-block"
                              : "")
                          }
                          id={
                            "menu_" +
                            value.entries[i].name
                              .replace(/ /g, "")
                              .toLowerCase() +
                            "_1"
                          }
                        >
                          <div className="title-box">
                            <h5
                              className="menu-title"
                              style={{
                                fontSize: "13px",
                                color: "#555555 !important",
                              }}
                            >
                              {value.entries[i].name}
                            </h5>
                            <ul className="nav flex-column">
                              {menuItems}
                            </ul>
                          </div>
                        </div>
                      );
                    }
                  } else {
                    let isActive = false;

                    if (
                      value.exact &&
                      value.link === location.pathname
                    ) {
                      isActive = true;
                    } else if (
                      !value.exact &&
                      location.pathname.startsWith(value.link)
                    ) {
                      isActive = true;
                    }

                    menus.push(
                      <div
                        className={
                          "twocolumn-menu-item " +
                          (isActive ? "menuitem-active d-block" : "")
                        }
                        id={
                          "menu_" +
                          value.name.replace(/ /g, "").toLowerCase() +
                          "_1"
                        }
                      >
                        <div className="title-box">
                          <h5
                            className="menu-title"
                            style={{
                              fontSize: "13px",
                              color: "#555555 !important",
                            }}
                          >
                            {value.name}
                          </h5>
                          <ul className="nav flex-column">
                            <li
                              className={
                                "nav-item " +
                                (isActive ? "menuitem-active" : "")
                              }
                            >
                              <Link
                                className={
                                  "nav-link " +
                                  (isActive ? "active" : "")
                                }
                                to={value.link}
                              >
                                {value.name}
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    );
                  }

                  return menus;
                })}
            </div>
          </div>
          <div className="clearfix" />
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
