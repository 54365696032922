import {withRouter} from "react-router-dom";

import TabPane from "../../../../../../components/ubold/organisms/TabPane";
import TabPaneSection from "../../../../../../components/ubold/organisms/TabPaneSection";

import CHTextField from "../../../../../../components/ubold/molecules/forms/CHTextField";
import CHAsyncSelect from "../../../../../../components/ubold/molecules/forms/CHAsyncSelect";

function RecommendationCity(props) {
    const {id, definitions, tab, state, setState} = props;

    return (
        <TabPane
            id={id}
            definitions={definitions}
            tab={tab}
            state={state}
            setState={setState}>
            <TabPaneSection style={{padding: '25px 16px 0px'}}>
                <CHAsyncSelect
                    name='city'
                    title='City'
                    isRequired
                    data='place/city?__type__=select_entries'
                />
                <CHTextField
                    name='priority'
                    title='Priority'
                    isRequired
                    type='number'
                />
            </TabPaneSection>
        </TabPane>
    );
}

export default withRouter(RecommendationCity);