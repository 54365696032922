// BUILDING SERVICES
import Service from "pages/Building/Service";
import ServiceAddEdit from "pages/Building/Service/AddEdit";
import ServiceView from "pages/Building/Service/ViewOnly";
import ServiceHistory from "pages/Building/Service/History";
//
// SERVICE ORDERS
//
// SERVICE REQUEST
import ServiceOrder from "pages/Order/Service";
import ServiceOrderAddEdit from "pages/Order/Service/AddEdit";
import ServiceOrderView from "pages/Order/Service/ViewOnly";
// SERVICE ORDER SOP
import ServiceOrderSOP from "pages/Order/ServiceSOP";
import ServiceOrderSOPAddEdit from "pages/Order/ServiceSOP/AddEdit";
import ServiceOrderSOPView from "pages/Order/ServiceSOP/ViewOnly";
//
// VENDORS
//
// USER VENDORS
import UserVendor from "pages/Vendor/UserVendor";
import UserVendorAddEdit from "pages/Vendor/UserVendor/AddEdit";
import UserVendorView from "pages/Vendor/UserVendor/ViewOnly";
// VENDORS
import Vendor from "pages/Vendor/Vendor";
import VendorAddEdit from "pages/Vendor/Vendor/AddEdit";
import VendorView from "pages/Vendor/Vendor/ViewOnly";

// COMPLAINT & CASE CATEGORY
import CaseCategory from "pages/Operation/CaseCategory";
import CaseCategoryAddEdit from "pages/Operation/CaseCategory/AddEdit";
import CaseCategoryView from "pages/Operation/CaseCategory/ViewOnly";
import CaseCategoryHistory from "pages/Operation/CaseCategory/History";

const OpsRoutes = [
  // BUILDING SERVICES
  {
    path: "/operation/building_service",
    component: Service,
  },
  {
    path: "/operation/building_service/(add|edit)/:id([0-9]+)?",
    component: ServiceAddEdit,
  },
  {
    path: "/operation/building_service/view/:id([0-9]+)?",
    component: ServiceView,
  },
  {
    path: "/operation/building_service/history/:id([0-9]+)?",
    component: ServiceHistory,
  },
  //
  // SERVICE ORDERS
  //
  // SERVICE REQUEST
  {
    path: "/operation/service_order/service_request",
    component: ServiceOrder,
  },
  {
    path: "/operation/service_order/service_request/(add|edit)/:id([0-9]+)?",
    component: ServiceOrderAddEdit,
  },
  {
    path: "/operation/service_order/service_request/view/:id([0-9]+)?",
    component: ServiceOrderView,
  },
  // SERVICE ORDER SOP
  {
    path: "/operation/service_order_sop",
    component: ServiceOrderSOP,
  },
  {
    path: "/operation/service_order_sop/(add|edit)/:id([0-9]+)?",
    component: ServiceOrderSOPAddEdit,
  },
  {
    path: "/operation/service_order_sop/view/:id([0-9]+)?",
    component: ServiceOrderSOPView,
  },
  //
  // VENDORS
  //
  // USER VENDORS
  {
    path: "/operation/vendor/user_vendor",
    component: UserVendor,
  },
  {
    path: "/operation/vendor/user_vendor/(add|edit)/:id([0-9]+)?",
    component: UserVendorAddEdit,
  },
  {
    path: "/operation/vendor/user_vendor/view/:id([0-9]+)?",
    component: UserVendorView,
  },
  // VENDORS
  {
    path: "/operation/vendor/vendor",
    component: Vendor,
  },
  {
    path: "/operation/vendor/vendor/(add|edit)/:id([0-9]+)?",
    component: VendorAddEdit,
  },
  {
    path: "/operation/vendor/vendor/view/:id([0-9]+)?",
    component: VendorView,
  },
  // COMPLAINT & CASE CATEGORY
  {
    path: "/operation/case_category",
    component: CaseCategory,
  },
  {
    path: "/operation/case_category/(add|edit)/:id([0-9]+)?",
    component: CaseCategoryAddEdit,
  },
  {
    path: "/operation/case_category/view/:id([0-9]+)?",
    component: CaseCategoryView,
  },
  {
    path: "/operation/case_category/history/:id([0-9]+)?",
    component: CaseCategoryHistory,
  },
];

export default OpsRoutes;
