import { withRouter } from "react-router-dom";

import AccordionPane from "../../../../../../../components/ubold/organisms/AccordionPane";

import CHSelect from "../../../../../../../components/ubold/molecules/forms/CHSelect";
import CHAsyncSelect from "../../../../../../../components/ubold/molecules/forms/CHAsyncSelect";
import CHLatitudeLongitude from "../../../../../../../components/ubold/molecules/forms/CHLatitudeLongitude";
import CHTextField from "../../../../../../../components/ubold/molecules/forms/CHTextField";

import utils from "../../../../../../../utils";
import { sectionProps } from "@types";

function Location(props: sectionProps) {
  const { baseLink, accordionId, onSubmit, state, setState } = props;

  return (
    <AccordionPane
      id="location"
      name="Location"
      accordionId={accordionId}
      baseLink={baseLink}
      onSubmit={onSubmit}
      shown={
        !!(
          utils.form.isFieldError(state, "country") ||
          utils.form.isFieldError(state, "province") ||
          utils.form.isFieldError(state, "city") ||
          utils.form.isFieldError(state, "district") ||
          utils.form.isFieldError(state, "subdistrict") ||
          utils.form.isFieldError(state, "lat") ||
          utils.form.isFieldError(state, "lon") ||
          utils.form.isFieldError(state, "address") ||
          utils.form.isFieldError(state, "postal_code") ||
          utils.form.isFieldError(state, "neighborhood")
        )
      }
      state={state}
      setState={setState}
    >
      <CHSelect
        name="country"
        title="Country"
        isRequired
        data="place/country?__type__=select_entries&limit=1000"
        readOnly
      />
      <CHAsyncSelect
        name="province"
        title="Province"
        isRequired
        data="place/province?__type__=select_entries&limit=10"
        dependsOn="country"
        dependsOnStartFirst={true}
        readOnly
      />
      <CHAsyncSelect
        name="city"
        title="City"
        isRequired
        data="place/city?__type__=select_entries&limit=10"
        dependsOn="province"
        readOnly
      />
      <CHAsyncSelect
        name="district"
        title="District"
        isRequired
        data="place/district?__type__=select_entries&limit=10"
        dependsOn="city"
        readOnly
      />
      <CHAsyncSelect
        name="subdistrict"
        title="Sub District"
        isRequired
        data="place/subdistrict?__type__=select_entries&limit=10"
        dependsOn="district"
        readOnly
      />
      <CHLatitudeLongitude
        nameLatitude="lat"
        nameLongitude="lon"
        isRequired
        title="Coordinate"
        readOnly
      />
      <CHTextField
        name="address"
        isRequired
        title="Address"
        maxLength={100}
        readOnly
      />
      <CHTextField
        name="postal_code"
        title="Postal Code"
        isRequired
        type="number"
        maxLength={5}
        readOnly
      />
      <CHTextField
        name="neighborhood"
        title="Neighborhood"
        isRequired
        maxLength={120}
        helperText="Comma separated eg: `cawang, tebet`"
        readOnly
      />
    </AccordionPane>
  );
}

export default withRouter(Location);
