import { withRouter } from "react-router-dom";

import AccordionPane from "../../../../../../../components/ubold/organisms/AccordionPane";

import CHSelect from "../../../../../../../components/ubold/molecules/forms/CHSelect";
import CHAsyncSelect from "../../../../../../../components/ubold/molecules/forms/CHAsyncSelect";
import CHDateTimePicker from "../../../../../../../components/ubold/molecules/forms/CHDateTimePicker";
import CHTextArea from "../../../../../../../components/ubold/molecules/forms/CHTextArea";
import CHCheckBox from "../../../../../../../components/ubold/molecules/forms/CHCheckBox";

import CHTextView from "../../../../../../../components/ubold/molecules/forms/CHTextView";

import utils from "../../../../../../../utils";

function Attributes(props: any) {
  const { baseLink, accordionId, onSubmit, state, setState } = props;

  return (
    <AccordionPane
      id="rent"
      name="Rent"
      accordionId={accordionId}
      baseLink={baseLink}
      onSubmit={onSubmit}
      shown={
        !!(
          utils.form.isFieldError(state, "status") ||
          utils.form.isFieldError(state, "date_started") ||
          utils.form.isFieldError(state, "date_ended") ||
          utils.form.isFieldError(state, "status_of_done")
        )
      }
      state={state}
      setState={setState}
    >
      <CHSelect
        name="status"
        title="Status"
        isRequired
        readOnly
        data={[
          {
            value: 1,
            label: "Waiting confirm",
          },
          {
            value: 2,
            label: "Processing",
          },
          {
            value: 7,
            label: "Deposit received",
          },
          {
            value: 8,
            label: "Deposit and payment received",
          },
          {
            value: 4,
            label: "Rejected",
          },
          {
            value: 3,
            label: "Ongoing",
            disabled: true,
          },
          {
            value: 5,
            label: "Cancel",
          },
          {
            value: 6,
            label: "Done",
          },
        ]}
        afterChange={() => {
          setState((prevState: any) => ({
            ...prevState,
            statusEdited: true,
          }));
        }}
      />
      <CHDateTimePicker
        name="date_started"
        title="Date Started"
        readOnly
        isRequired
        type="date"
      />
      <CHTextView
        name="date_ended"
        title="Date Ended"
        readonly
        type="date"
      />
      <CHDateTimePicker
        name="check_in_to_landlord"
        title="Checkin to Landlord"
        type="date"
        readOnly
      />
      <CHDateTimePicker
        name="estimated_checkout_date"
        title="Estimated Check-Out Date"
        type="date"
        readOnly
      />
      <CHDateTimePicker
        name="last_payment_to_landlord"
        title="Last Payment to Landlord"
        type="date"
        readOnly
      />
      <CHDateTimePicker
        name="date_checkout"
        title="Date Checkout"
        readOnly
        type="date"
        helperText="<b>Note</b>: Date checkout should be greater than Date Ended."
      />
      <CHSelect
        name="status_of_done"
        title="Status of Done"
        readOnly
        isRequired
        data={[
          {
            label: "None",
            value: 0,
          },
          {
            label: "Transfer Room",
            value: 1,
          },
          {
            label: "Transfer Building",
            value: 2,
          },
          {
            label: "Finish/Moving Out",
            value: 5,
          },
          {
            label: "Building Terminated",
            value: 6,
          },
          {
            label: "Change Payment Plan",
            value: 7,
          },
          {
            label: "Extend Stay",
            value: 8,
          },
        ]}
      />
      <CHTextArea
        name="reason_of_check_out"
        title="Reason of Checkout"
        readOnly
      />
      <CHAsyncSelect
        name="checkout_category"
        title="Checkout Category"
        readOnly
        data="order/checkout_category/?__type__=select_entries&limit=10"
      />
      <CHAsyncSelect
        name="checkout_reason"
        title="Checkout Reason"
        readOnly
        dependsOn="checkout_category"
        data="order/checkout_reason/?__type__=select_entries&limit=10"
      />
      <CHCheckBox
        name="on_leave_paying"
        title="On Leave Paying?"
        disabled
      />
      <CHDateTimePicker
        name="check_in_time"
        title="Check-In Time"
        type="time"
        readOnly
      />
      <CHDateTimePicker
        name="check_out_time"
        title="Check-Out Time"
        type="time"
        readOnly
      />
      <CHCheckBox
        name="confirm_check_out"
        title="Confirm Check-Out"
        disabled
      />
      <CHCheckBox
        name="split_deposit_and_rent"
        title="Split Deposit and Rent"
        disabled
      />
      <CHCheckBox name="no_show" title="No Show" disabled />
      <CHTextView
        name="monthly_commitment"
        title="Monthly Commitment"
      />
      <CHTextView name="landlord_price" title="Landlord Price" />
    </AccordionPane>
  );
}

export default withRouter(Attributes);
