import configBuilder from "../../../components/ubold/ConfigBuilder";

import utils from "../../../utils";

import secondaryDataHandler from "./SecondaryDataHandler";

let definitions = {
  title: "Order Services",
  frontendPath: "/operation/service_order_sop",
  baseLink: "/order/service",
  restAccessCode: "order.service",
  __table__: {
    filterColumn: 4,
    removeIdentifier: "number",
    hasSecondaryData: true,
    secondaryDataRow: 2,
    secondaryDataItem: 13,
    secondaryDataHandler: secondaryDataHandler,
    hideEditButton: true,
    filters: [
      {
        id: "number",
        name: "Service ID",
        type: "text",
      },
      {
        id: "building",
        name: "Building",
        type: "async_select",
        data: "building/building?__type__=select_entries",
        itemsExtractor: (value) => {
          return {
            label: value.building_name,
            value: value.id,
          };
        },
      },
      {
        id: "building_service",
        name: "Building Service",
        type: "async_select",
        dependsOn: "building",
        data: "building/building__service?__type__=select_entries",
      },
      {
        id: "service",
        name: "Scheduled Service",
        type: "async_select",
        data: "building/service?__type__=select_entries&scheduled=1",
      },
      {
        id: "status",
        name: "Status",
        type: "select",
        data: {
          optionField: "order_service_status",
        },
      },
      {
        id: "estimation_date",
        name: "Estimation Date",
        type: "date",
      },
      {
        id: "selected_date",
        name: "Scheduled Date",
        type: "date",
      },
    ],
    columns: [
      {
        id: "number",
        name: "Service ID",
        enableHyperlink: true,
      },
      {
        id: "tenant_name",
        name: "Tenant",
      },
      {
        id: "building_name",
        name: "Building",
      },
      {
        id: "room_name",
        name: "Room",
      },
      {
        id: "status",
        name: "Status",
        referStateFieldName: "order_service_status",
        renderer: (value, _, constants) => {
          let label = "-";
          (constants ?? []).forEach((obj) => {
            if (obj.value === parseInt(value)) {
              label = obj.label;
            }
          });
          return label;
        },
      },
      {
        id: "selected_date",
        name: "Scheduled Date",
        renderer: (value) => {
          return utils.formatter.date(value);
        },
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
