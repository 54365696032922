import {useState} from "react";

import {useParams, withRouter} from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import Form from "../../../../components/ubold/organisms/Form";

import definitions from "./definitions";
import CVTextView from "../../../../components/ubold/molecules/forms/CVTextView";

function CAddEditPage() {
    let {id} = useParams();

    const initialState = {};

    const [state, setState] = useState(initialState);

    return (
        <Page title={'Detail ' +  definitions.title}
              restAccessCode={definitions.restAccessCode}>
            <Form
                id={id}
                definitions={definitions}
                state={state}
                readOnly
                setState={setState}>
                <CVTextView
                    name='tenant_name'
                    title='Tenant'
                    renderer={(value, row) => {
                        return value + ' - ' + row['tenant_email'];
                    }}
                />
                <CVTextView
                    name='title'
                    title='Title'
                />
                <CVTextView
                    name='messages'
                    title='Messages'
                />
                <CVTextView
                    name='status'
                    title='Status'
                    renderer={value => {
                        if(parseInt(value) === 1) {
                            return 'Danger';
                        } else if(parseInt(value) === 2) {
                            return 'Warning';
                        } else if(parseInt(value) === 3) {
                            return 'Success';
                        } else if(parseInt(value) === 4) {
                            return 'Alert';
                        } else if(parseInt(value) === 5) {
                            return 'Info';
                        } else {
                            return '-';
                        }
                    }}
                />
                <CVTextView
                    name='is_read'
                    title='Is Read'
                    isSafeContent
                    renderer={value => {
                        if(value === 'true') {
                            return '<span style="color: rgb(26, 188, 156);"> ✔ </span>';
                        } else {
                            return '<span style="color: red;">✘</span>';
                        }
                    }}
                />
                <CVTextView
                    name='is_popup'
                    title='Is Popup'
                    isSafeContent
                    renderer={value => {
                        if(value === 'true') {
                            return '<span style="color: rgb(26, 188, 156);"> ✔ </span>';
                        } else {
                            return '<span style="color: red;">✘</span>';
                        }
                    }}
                />
                <CVTextView
                    name='is_closable'
                    title='Is Closable'
                    isSafeContent
                    renderer={value => {
                        if(value === 'true') {
                            return '<span style="color: rgb(26, 188, 156);"> ✔ </span>';
                        } else {
                            return '<span style="color: red;">✘</span>';
                        }
                    }}
                />
                <CVTextView
                    name='relation_type'
                    title='Relation Type'
                    renderer={value => {
                        if(parseInt(value) === 1) {
                            return 'Invoice';
                        } else if(parseInt(value) === 2) {
                            return 'Payment';
                        } else if(parseInt(value) === 3) {
                            return 'Event';
                        } else if(parseInt(value) === 4) {
                            return 'Promotion';
                        } else if(parseInt(value) === 5) {
                            return 'Service Request';
                        } else {
                            return '-';
                        }
                    }}
                />
                <CVTextView
                    name='additional_data'
                    title='Additional Data'
                />
                <CVTextView
                    name='action_label'
                    title='Action Label'
                />
            </Form>
        </Page>
    );
}

export default withRouter(CAddEditPage);