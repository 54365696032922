// PROMOTION
//
// DISCOUNT MASTER
import DiscountMaster from "pages/Marketing/DiscountMaster";
import DiscountMasterAddEdit from "pages/Marketing/DiscountMaster/AddEdit";
import DiscountMasterHistory from "pages/Marketing/DiscountMaster/History";
import DiscountMasterView from "pages/Marketing/DiscountMaster//ViewOnly";
//
// DISCOUNT
//
// DISCOUNTS
import Discount from "pages/Finance/Discount";
import DiscountView from "pages/Finance/Discount/ViewOnly";
import DiscountAddEdit from "pages/Finance/Discount/AddEdit";
// DISCOUNT BANNERS
import DiscountBanner from "pages/Building/DiscountBanner";
import DiscountBannerView from "pages/Building/DiscountBanner/ViewOnly";
import DiscountBannerAddEdit from "pages/Building/DiscountBanner/AddEdit";
// DISCOUNT CATEGORIES
import DiscountCategory from "pages/Finance/DiscountCategory";
import DiscountCategoryView from "pages/Finance/DiscountCategory/ViewOnly";
import DiscountCategoryAddEdit from "pages/Finance/DiscountCategory/AddEdit";
//
// VOUCHER
//
// VOUCHERS
import VoucherManagement from "pages/Promotion/VoucherManagement";
import VoucherManagementView from "pages/Promotion/VoucherManagement/ViewOnly";
import VoucherManagementAddEdit from "pages/Promotion/VoucherManagement/AddEdit/index";
// VOUCHER REDEMPTIONS
import VoucherRedemption from "pages/Promotion/VoucherRedemption";
import VoucherRedemptionAddEdit from "pages/Promotion/VoucherRedemption/AddEdit";
import VoucherRedemptionView from "pages/Promotion/VoucherRedemption/ViewOnly";
//
// BENEFITS
import Benefit from "pages/Promotion/Benefit";
import BenefitAddEdit from "pages/Promotion/Benefit/AddEdit";
import BenefitView from "pages/Promotion/Benefit/ViewOnly";
// PROMOTION HIGHLIGHTS
import PartnershipOnWebsite from "pages/Promotion/PartnershipOnWebsite";
import PartnershipOnWebsiteView from "pages/Promotion/PartnershipOnWebsite/ViewOnly";
import PartnershipOnWebsiteAddEdit from "pages/Promotion/PartnershipOnWebsite/AddEdit";
// PROMOTION PAGE
import Promotion from "pages/Promotion/PromotionPage";
import PromotionPageAddEdit from "pages/Promotion/PromotionPage/AddEdit";
import PromotionPageView from "pages/Promotion/PromotionPage/ViewOnly";
//
// HIGHLIGHTS
import Highlight from "pages/User/Highlight";
import HighlightAddEdit from "pages/User/Highlight/AddEdit";
import HighlightView from "pages/User/Highlight/ViewOnly";
// HOMEPAGE
import HomepageHeader from "pages/Marketing/HomepageHeader";
import HomepageHeaderAddEdit from "pages/Marketing/HomepageHeader/AddEdit";
import HomepageHeaderView from "pages/Marketing/HomepageHeader/ViewOnly";

const MarketingRoutes = [
  // PROMOTION
  //
  // DISCOUNT MASTER
  {
    path: "/marketing/promotion/discount_master",
    component: DiscountMaster,
  },
  {
    path: "/marketing/promotion/discount_master/(add|edit)/:id([0-9]+)?",
    component: DiscountMasterAddEdit,
  },
  {
    path: "/marketing/promotion/discount_master/view/:id([0-9]+)?",
    component: DiscountMasterView,
  },
  {
    path: "/marketing/promotion/discount_master/history/:id([0-9]+)?",
    component: DiscountMasterHistory,
  },
  //
  // DISCOUNT
  //
  // DISCOUNTS
  {
    path: "/marketing/promotion/discount/discount",
    component: Discount,
  },
  {
    path: "/marketing/promotion/discount/discount/(add|edit)/:id([0-9]+)?",
    component: DiscountAddEdit,
  },
  {
    path: "/marketing/promotion/discount/discount/view/:id([0-9]+)?",
    component: DiscountView,
  },
  // DISCOUNT BANNERS
  {
    path: "/marketing/promotion/discount/discount_banner",
    component: DiscountBanner,
  },
  {
    path: "/marketing/promotion/discount/discount_banner/(add|edit)/:id([0-9]+)?",
    component: DiscountBannerAddEdit,
  },
  {
    path: "/marketing/promotion/discount/discount_banner/view/:id([0-9]+)?",
    component: DiscountBannerView,
  },
  // DISCOUNT CATEGORIES
  {
    path: "/marketing/promotion/discount/discount_category",
    component: DiscountCategory,
  },
  {
    path: "/marketing/promotion/discount/discount_category/(add|edit)/:id([0-9]+)?",
    component: DiscountCategoryAddEdit,
  },
  {
    path: "/marketing/promotion/discount/discount_category/view/:id([0-9]+)?",
    component: DiscountCategoryView,
  },
  //
  // VOUCHER
  //
  // VOUCHERS
  {
    path: "/marketing/promotion/voucher/voucher",
    component: VoucherManagement,
  },
  {
    path: "/marketing/promotion/voucher/voucher/(add|edit)/:id([0-9]+)?",
    component: VoucherManagementAddEdit,
  },
  {
    path: "/marketing/promotion/voucher/voucher/view/:id([0-9]+)?",
    component: VoucherManagementView,
  },
  // VOUCHER REDEMPTIONS
  {
    path: "/marketing/promotion/voucher/voucher_redemption",
    component: VoucherRedemption,
  },
  {
    path: "/marketing/promotion/voucher/voucher_redemption/(add|edit)/:id([0-9]+)?",
    component: VoucherRedemptionAddEdit,
  },
  {
    path: "/marketing/promotion/voucher/voucher_redemption/view/:id([0-9]+)?",
    component: VoucherRedemptionView,
  },
  //
  // BENEFITS
  {
    path: "/marketing/promotion/benefit",
    component: Benefit,
  },
  {
    path: "/marketing/promotion/benefit/(add|edit)/:id([0-9]+)?",
    component: BenefitAddEdit,
  },
  {
    path: "/marketing/promotion/benefit/view/:id([0-9]+)?",
    component: BenefitView,
  },
  // PROMOTION HIGHLIGHTS
  {
    path: "/marketing/promotion/partnership_on_website",
    component: PartnershipOnWebsite,
  },
  {
    path: "/marketing/promotion/partnership_on_website/(add|edit)/:id([0-9]+)?",
    component: PartnershipOnWebsiteAddEdit,
  },
  {
    path: "/marketing/promotion/partnership_on_website/view/:id([0-9]+)?",
    component: PartnershipOnWebsiteView,
  },
  // PROMOTION PAGE
  {
    path: "/marketing/promotion/promotion",
    component: Promotion,
  },
  {
    path: "/marketing/promotion/promotion/(add|edit)/:id([0-9]+)?",
    component: PromotionPageAddEdit,
  },
  {
    path: "/marketing/promotion/promotion/view/:id([0-9]+)?",
    component: PromotionPageView,
  },
  //
  // HIGHLIGHTS
  {
    path: "/marketing/highlight",
    component: Highlight,
  },
  {
    path: "/marketing/highlight/(add|edit)/:id([0-9]+)?",
    component: HighlightAddEdit,
  },
  {
    path: "/marketing/highlight/view/:id([0-9]+)?",
    component: HighlightView,
  },
  // HOMEPAGE
  {
    path: "/marketing/homepage/homepage_header",
    component: HomepageHeader,
  },
  {
    path: "/marketing/homepage/homepage_header/(add|edit)/:id([0-9]+)?",
    component: HomepageHeaderAddEdit,
  },
  {
    path: "/marketing/homepage/homepage_header/view/:id([0-9]+)?",
    component: HomepageHeaderView,
  },
];

export default MarketingRoutes;
