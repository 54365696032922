import configBuilder from "../../../components/ubold/ConfigBuilder";

import utils from "../../../utils";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import configs from "../../../configs";

let definitions = {
  title: "Tenants",
  frontendPath: "/tenant/tenant",
  baseLink: "/user/tenant",
  restAccessCode: "user.tenant",
  __table__: {
    filterColumn: 4,
    defaultOrdering: "-date_created",
    hideEditButton: true,
    actions: [
      {
        name: "Sync to Netsuite",
        icon: "mdi-axis-lock",
        action: (row) => {
          const sweetAlert = withReactContent(Swal);

          utils.httpClient.get(
            configs.apiUrl +
              "users/tenant/sync_to_netsuite/?tenant_id=" +
              row.id,
            (response) => {
              sweetAlert.fire("", response.message, "info");
            },
            (error, message) => {
              sweetAlert.fire("", message, "error");
            }
          );
        },
      },
    ],
    filters: [
      {
        id: "name",
        name: "Name",
        type: "text",
      },
      {
        id: "phone_number",
        name: "Phone Number",
        type: "text",
      },
      {
        id: "email",
        name: "Email",
        type: "text",
      },
      {
        id: "is_active",
        name: "Is Active?",
        type: "select",
        data: [
          {
            label: "Yes",
            value: true,
          },
          {
            label: "No",
            value: false,
          },
        ],
      },
    ],
    columns: [
      {
        id: "name",
        name: "Name",
        enableHyperlink: true,
      },
      {
        id: "code",
        name: "Tenant Code",
      },
      {
        id: "phone_number",
        name: "Phone Number",
        center: true,
      },
      {
        id: "email",
        name: "Email",
        center: true,
      },
      {
        id: "date_created",
        name: "Date Created",
        renderer: (value) => {
          return utils.formatter.dateTime(value);
        },
      },
      {
        id: "is_active",
        name: "Is Active",
        isSafeContent: true,
        renderer: (value) => {
          if (value === "true") {
            return '<span style="color: rgb(26, 188, 156);"> ✔ </span>';
          } else {
            return '<span style="color: red;">✘</span>';
          }
        },
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
