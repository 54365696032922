import { useParams, withRouter } from "react-router-dom";
import TabPaneSecondary from "components/ubold/organisms/TabPaneSecondary";

import COneOrManyToManyField from "components/ubold/molecules/forms/COneOrManyToManyField";

function BuildingCollectionTab(props: any) {
  const { definitions, tab, state, setState } = props;

  let { id }: { id: string } = useParams();

  return (
    <TabPaneSecondary
      definitions={definitions}
      restAccessCodeNamespace="building_collections"
      tab={tab}
      state={state}
      setState={setState}
    >
      <COneOrManyToManyField
        id={id}
        name={tab.id}
        restPath={"homepage-collection-building/"}
        relationKey={"collection_id"}
        maxRow={5} /** limit numbers of data to be sent */
        rowIdentifier="Building"
        columns={[
          {
            name: "building",
            title: "Building",
            type: "async_select",
            isUniqueOption: true,
            isRequired: true,
            data: "building/building?__type__=select_entries",
            itemsExtractor: (row: any) => {
              return {
                label: row.building_name,
                value: row.id,
              };
            },
          },
          {
            name: "order",
            title: "Order",
            isRequired: true,
            type: "number",
          },
        ]}
      />
    </TabPaneSecondary>
  );
}

export default withRouter(BuildingCollectionTab);
