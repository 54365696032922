import React, {
  Dispatch,
  HTMLAttributes,
  SetStateAction,
} from "react";
import moment from "moment";
import styled from "styled-components";

import { Schedule } from "./Types";

import { BgRoomStatus } from "./TableDashboardRoomAllotment.css";
import { DataModalDetailObjectType } from "components/ubold/molecules/modal/RoomAllotment/ModalDetail";
import { BgStripes } from "./TableDashboardRoomAllotment";

interface IDayOrderSchedule
  extends HTMLAttributes<HTMLButtonElement> {
  data: {
    currentOrderStatus: Schedule;
    roomDetail: {
      room: { id: number; name: string };
      roomVariant: { id: number; name: string };
    };
    scheduleNotConfirmedCheckout: Schedule;
  };
  setModal: Dispatch<SetStateAction<boolean>>;
  setModalData: Dispatch<SetStateAction<DataModalDetailObjectType>>;
}

const generateOrderStatus = (
  order: {
    date: Schedule["date"];
    orderNumber: Schedule["order_number"];
    status: Schedule["status"];
    subscriptionType: Schedule["order_subscription_type"];
  },
  radiusLeft: boolean,
  radiusRight: boolean,
  scheduleNotConfirmedCheckout: Schedule
): React.ReactElement => {
  if (
    scheduleNotConfirmedCheckout &&
    order.date > scheduleNotConfirmedCheckout.date &&
    !order.orderNumber
  ) {
    return <BgRoomStatus size="full" theme="GREY" />;
  }

  switch (order.status) {
    case "Waiting for Payment":
      if (order.subscriptionType === "Close Subscription") {
        return (
          <BgStripes
            backgroundColor="#FFC1BD"
            bar="#FF4438"
            radiusLeft={radiusLeft}
            radiusRight={radiusRight}
          />
        );
      }

      return (
        <BgRoomStatus
          radiusLeft={radiusLeft}
          radiusRight={radiusRight}
          size="center"
          theme="RED"
        />
      );

    case "Ongoing":
      if (order.subscriptionType === "Close Subscription") {
        return (
          <BgStripes
            backgroundColor="#B8D4F5"
            bar="#2A7DE1"
            radiusLeft={radiusLeft}
            radiusRight={radiusRight}
          />
        );
      }

      return (
        <BgRoomStatus
          radiusLeft={radiusLeft}
          radiusRight={radiusRight}
          size="center"
          theme="BLUE"
        />
      );

    case "Deposit Received":
      if (order.subscriptionType === "Close Subscription") {
        return (
          <BgStripes
            backgroundColor="#FFE7B3"
            bar="#FFB71B"
            radiusLeft={radiusLeft}
            radiusRight={radiusRight}
          />
        );
      }

      return (
        <BgRoomStatus
          radiusLeft={radiusLeft}
          radiusRight={radiusRight}
          size="center"
          theme="YELLOW"
        />
      );

    case "Deposit and Payment Received":
      if (order.subscriptionType === "Close Subscription") {
        return (
          <BgStripes
            backgroundColor="#D4C7EC"
            bar="#7E57C5"
            radiusLeft={radiusLeft}
            radiusRight={radiusRight}
          />
        );
      }

      return (
        <BgRoomStatus
          radiusLeft={radiusLeft}
          radiusRight={radiusRight}
          size="center"
          theme="PURPLE"
        />
      );

    default:
      return null;
  }
};

const DayOrderSchedule: React.FC<IDayOrderSchedule> = ({
  data: {
    currentOrderStatus,
    roomDetail,
    scheduleNotConfirmedCheckout,
  },
  setModal,
  setModalData,
}) => {
  const handleClick = (): void => {
    const orderDateStart = currentOrderStatus?.order_start_date;
    const orderDateEnd = currentOrderStatus?.order_end_date;

    setModalData({
      date: currentOrderStatus.date,
      hrefId: {
        room: roomDetail.room.id,
        roomVariant: roomDetail.roomVariant.id,
        order: currentOrderStatus.order,
        tenant: currentOrderStatus.tenant,
      },
      render: {
        subscriptionType: currentOrderStatus.order_subscription_type,
        orderStatus: currentOrderStatus?.status,
        orderDate: `${moment(orderDateStart, "YYYY-MM-DD").format(
          "DD MMM YYYY"
        )} - ${
          orderDateEnd
            ? moment(orderDateEnd, "YYYY-MM-DD").format("DD MMM YYYY")
            : ""
        }`,
        orderId: currentOrderStatus?.order_number,
        salesName: currentOrderStatus?.order_sales_name,
        confirmCheckout: currentOrderStatus?.order_confirm_checkout,
        roomVariant: roomDetail.roomVariant.name,
        room: roomDetail.room.name,
        tenantName: currentOrderStatus?.tenant_name,
      },
    });

    setModal(true);
  };

  return (
    <DayOrderScheduleBase
      disabled={
        scheduleNotConfirmedCheckout &&
        currentOrderStatus.date > scheduleNotConfirmedCheckout.date &&
        !currentOrderStatus.order_number
      }
      onClick={handleClick}
    >
      {generateOrderStatus(
        {
          date: currentOrderStatus.date,
          orderNumber: currentOrderStatus.order_number,
          status: currentOrderStatus.status,
          subscriptionType:
            currentOrderStatus.order_subscription_type,
        },
        currentOrderStatus?.order_start_date ===
          currentOrderStatus.date,
        currentOrderStatus?.order_end_date ===
          currentOrderStatus.date,
        scheduleNotConfirmedCheckout
      )}
    </DayOrderScheduleBase>
  );
};

export default DayOrderSchedule;

const DayOrderScheduleBase = styled.button`
  display: contents;

  background-color: unset;
  border: unset;
  padding: unset;
`;
