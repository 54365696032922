import styled from "styled-components";

const BaseTableResponsiveContainer = styled.div`
  overflow-x: unset;
  width: auto;
  display: flex;
  flex-grow: 1;

  &:first-child {
    position: sticky;
    left: 0;
  }

  .table {
    table-layout: fixed;
    white-space: nowrap;
    width: auto;
    flex-grow: 1;

    &:first-child {
      background-color: #fff;
      box-shadow: 0px 0px 8px rgb(0 0 0 / 10%);
    }

    td {
      padding: 12px 8px;

      a {
        &.link-rukita {
          font-weight: 500;
        }
      }
    }

    .thead-light {
      filter: drop-shadow(0px 0px 8px rgb(0 0 0 / 10%));

      th {
        position: sticky;
        top: 0;

        background-color: #d9efe5;
        color: #222222;
        font-weight: 500;
        padding: 12px 8px;
      }
    }
  }
`;

export const TableContainer = styled.div`
  max-height: 85vh;
  overflow: auto;
  width: 100%;
`;

export default BaseTableResponsiveContainer;
