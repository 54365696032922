import QRCode from "react-qr-code";

type Props = {
  slug: string;
  type: "PRINT" | "DOWNLOAD" | "PREVIEW";
  name?: string;
  id?: string;
};

function BuildingQR({ slug, type, name, id }: Props) {
  const displayStyle = {
    container: {
      width: "300px",
      height: "500px",
      display: "flex",
      flexDirection: "column" as const,
      justifyContent: "space-between",
    },
    qrCode: {
      height: "auto",
      maxWidth: "100%",
      width: "100%",
      viewBox: "0 0 1200 1200",
    },
  };

  const downloadStyle = {
    container: {
      width: "1200px",
      height: "1200px",
      position:
        type === "DOWNLOAD"
          ? ("absolute" as const)
          : ("relative" as const),
      left: type === "DOWNLOAD" ? "-2000px" : "0",
      top: type === "DOWNLOAD" ? "-2000px" : "0",
    },
    qrCode: {
      ...displayStyle.qrCode,
      viewBox: "0 0 1200 1200",
    },
  };

  const styles = type === "DOWNLOAD" ? downloadStyle : displayStyle;
  const env = process.env.REACT_APP_ENV_NAME;
  const urlBasedOnEnv =
    env === "production"
      ? "https://www.rukita.co/place/" +
        slug +
        "?utm_campaign=offline&utm_source=offline&utm_medium=offline&utm_content=offline"
      : "https://harbor.imarukita.ninja/place/" +
        slug +
        "?utm_campaign=offline&utm_source=offline&utm_medium=offline&utm_content=offline";

  return (
    <div id={id} style={styles.container}>
      {name && (
        <p
          style={{
            color: "black",
            fontSize: 32,
            textAlign: "center",
            width: styles.container.width,
            fontWeight: 700,
            marginBottom: 40,
          }}
        >
          {name}
        </p>
      )}
      <QRCode value={urlBasedOnEnv} style={styles.qrCode} />
    </div>
  );
}

export default BuildingQR;
