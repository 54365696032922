import {useState} from "react";

import {useParams, withRouter} from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import Form from "../../../../components/ubold/organisms/Form";

import CVTextView from "../../../../components/ubold/molecules/forms/CVTextView";

import utils from "../../../../utils";

import definitions from "./definitions";

function CAddEditPage() {
    let {id} = useParams();

    const initialState = {};

    const [state, setState] = useState(initialState);

    return (
        <Page title={'Detail ' +  definitions.title}
              restAccessCode={definitions.restAccessCode}>
            <Form
                id={id}
                definitions={definitions}
                readOnly
                state={state}
                setState={setState}>
                <CVTextView
                    name='tenant_name'
                    title='Tenant'
                    renderer={(value, row) => {
                        return value + ' - ' + row['tenant_email']
                    }}
                />
                <CVTextView
                    name='voucher_name'
                    title='Voucher'
                    renderer={(value, row) => {
                        return value + ' - ' + utils.formatter.currency(row['voucher_amount']);
                    }}
                />
                <CVTextView
                    name='amount'
                    title='Amount'
                    renderer={value => {
                        return utils.formatter.currency(value);
                    }}
                />
                <CVTextView
                    name='date_expired'
                    title='Date Expired'
                    renderer={value => {
                        return utils.formatter.dateTime(value);
                    }}
                />
                <CVTextView
                    name='voucher_type'
                    title='Voucher Type'
                    renderer={value => {
                        if(parseInt(value) === 1) {
                            return 'Internal Voucher';
                        } else if(parseInt(value) === 2) {
                            return 'External Voucher';
                        }
                    }}
                />
                <CVTextView
                    name='status'
                    title='Status'
                    renderer={value => {
                        if(parseInt(value) === 1) {
                            return 'Active';
                        } else if(parseInt(value) === 2) {
                            return 'Process';
                        } else if(parseInt(value) === 3) {
                            return 'Expired';
                        } else if(parseInt(value) === 4) {
                            return 'Redeemed';
                        }
                    }}
                />
                <CVTextView
                    name='date_used'
                    title='Date Used'
                    renderer={value => {
                        return utils.formatter.dateTime(value);
                    }}
                />
            </Form>
        </Page>
    );
}

export default withRouter(CAddEditPage);