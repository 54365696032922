import configBuilder from "../../../../components/ubold/ConfigBuilder";

import pageDefinitions from "../definitions";

import utils from "../../../../utils";

const definitions = {
  ...pageDefinitions,
  tabs: [
    {
      id: "benefit",
      name: "Benefit",
    },
    {
      id: "t_benefit_target",
      name: "Targets",
    },
  ],
  validators: {
    banner_picture_original: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    brand_name: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    brand_information: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    brand_logo_original: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    headline_of_benefit: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    detail_of_benefits: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    how_to_redeem: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    tnc: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    date_started: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    date_ended: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    benefit_category: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    title_pn: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    content_pn: (value: string) => {
      return utils.validator.validateRequired(value);
    },
  },
};

export default configBuilder.buildAddEditPageConfig(definitions, [
  "banner_picture_original",
  "brand_logo_original",
  "pn_picture_original",
]);
