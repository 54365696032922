import { useParams, withRouter } from "react-router-dom";

import TabPaneSecondary from "../../../../../../components/ubold/organisms/TabPaneSecondary";

import COneOrManyToManyField from "../../../../../../components/ubold/molecules/forms/COneOrManyToManyField";

function RoleUsers(props: any) {
  const { definitions, tab, state, setState } = props;

  let { id }: { id: string } = useParams();

  return (
    <TabPaneSecondary
      definitions={definitions}
      tab={tab}
      restAccessCodeNamespace="user"
      state={state}
      setState={setState}
    >
      <COneOrManyToManyField
        id={id}
        name={tab.id}
        restPath="crm/user__role/"
        relationKey="role_id"
        readOnly
        columns={[
          {
            name: "name",
            type: "read_only",
            title: "Name",
            renderer: (value: string) => {
              if (value.trim() === "") {
                return "-";
              } else {
                return value;
              }
            },
            isReadOnly: true,
          },
          {
            name: "username",
            type: "read_only",
            title: "Username",
            isReadOnly: true,
          },
          {
            name: "email",
            type: "read_only",
            title: "Email",
            isReadOnly: true,
          },
        ]}
      />
    </TabPaneSecondary>
  );
}

export default withRouter(RoleUsers);
