import React from "react";

import {withRouter} from "react-router-dom";

import utils from "../../../utils";

function TabbedForm(props) {
    const {id, children, definitions, state, setState} = props;

    return (
        <div className='row'>
            <div className='col-12'>
                <div className='card'>
                    <div className='card-body' style={{padding: '0px'}}>
                        {utils.commons.isArray(children) ? children.map((child, i) => {
                            return React.cloneElement(child, {
                                key: i,
                                state: state,
                                setState: setState,
                                id: id,
                                definitions: definitions,
                            })
                        }) : children ? React.cloneElement(children, {
                            key: '0',
                            state: state,
                            setState: setState,
                            id: id,
                            definitions: definitions,
                        }) : <></>}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withRouter(TabbedForm);