import {useState} from "react";

import {useParams, withRouter} from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import Form from "../../../../components/ubold/organisms/Form";

import CVTextField from "../../../../components/ubold/molecules/forms/CVTextField";
import CVTextArea from "../../../../components/ubold/molecules/forms/CVTextArea";
import CVAsyncSelect from "../../../../components/ubold/molecules/forms/CVAsyncSelect";

import definitions from "./definitions";

function CAddEditPage() {
    let {id} = useParams();

    const initialState = {};

    const [state, setState] = useState(initialState);

    return (
        <Page title={(id === undefined ? 'Add New' : 'Edit') + ' ' +  definitions.title}
              restAccessCode={definitions.restAccessCode}>
            <Form
                id={id}
                definitions={definitions}
                state={state}
                setState={setState}>
                <CVTextField
                    name='name'
                    title='Name'
                    maxLength={50}
                    isRequired
                />
                <CVAsyncSelect
                    name='country'
                    title='Country'
                    data='place/country?__type__=select_entries'
                    isRequired
                />
                <CVTextField
                    name='meta_title'
                    title='Meta Title'
                    maxLength={255}
                />
                <CVTextField
                    name='meta_title_en'
                    title='Meta Title EN'
                    maxLength={255}
                />
                <CVTextArea
                    name='meta_description'
                    title='Meta Description'
                />
                <CVTextArea
                    name='meta_description_en'
                    title='Meta Description EN'
                />
            </Form>
        </Page>
    );
}

export default withRouter(CAddEditPage);