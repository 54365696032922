import { withRouter } from "react-router-dom";

import utils from "../../../../utils";

function CTextArea(props) {
  const {
    name,
    rows = 5,
    onChange,
    maxLength = null,
    readOnly = false,
    state,
    setState,
    setErrorMessage,
    validate,
    withCharCounter,
  } = props;

  return (
    <div>
      <textarea
        name={name}
        value={state[name] ?? ""}
        rows={rows}
        readOnly={readOnly}
        style={{ backgroundColor: !readOnly ? "#fff" : "#f8f8f8" }}
        maxLength={maxLength ? maxLength : 65535}
        className="form-control"
        onChange={
          onChange
            ? onChange
            : (e) => {
                const value = e.target.value;

                setState((prevState) => ({
                  ...prevState,
                  [name]: value,
                }));

                if (
                  value !== "" &&
                  utils.commons.isFunction(validate)
                ) {
                  validate(value, setErrorMessage);
                } else {
                  if (
                    setErrorMessage &&
                    utils.commons.isFunction(setErrorMessage)
                  ) {
                    setErrorMessage(null);
                  }
                }
              }
        }
      />
      {!withCharCounter &&
      maxLength &&
      state[name] &&
      maxLength === state[name].length ? (
        <span style={{ fontSize: "12px", color: "#bbbbbb" }}>
          Maximum characters {maxLength}
        </span>
      ) : (
        <></>
      )}
      {withCharCounter && (
        <span style={{ fontSize: "12px", color: "#bbbbbb" }}>
          {maxLength - (state[name] ?? "").length} characters left.
        </span>
      )}
    </div>
  );
}

export default withRouter(CTextArea);
