import {useState} from "react";

import {useParams, withRouter} from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import Form from "../../../../components/ubold/organisms/Form";

import definitions from "./definitions";
import CVTextField from "../../../../components/ubold/molecules/forms/CVTextField";
import CVSimpleManyToMany from "../../../../components/ubold/molecules/forms/CVSimpleManyToMany";

function CAddEditPage() {
    let {id} = useParams();

    const initialState = {};

    const [state, setState] = useState(initialState);

    return (
        <Page title={(id === undefined ? 'Add New' : 'Edit') + ' ' +  definitions.title}
              restAccessCode={definitions.restAccessCode}>
            <Form
                id={id}
                definitions={definitions}
                state={state}
                setState={setState}>
                <CVTextField
                    name='name'
                    title='Name'
                    isRequired
                    maxLength={80}
                />
                <CVTextField
                    name='phone_number'
                    title='Phone Number'
                    type='number'
                    maxLength={20}
                />
                <CVTextField
                    name='address'
                    title='Address'
                    maxLength={255}
                />
                <CVSimpleManyToMany
                    name='buildings'
                    title='Buildings'
                    data='building/building?__type__=select_entries'
                    itemsExtractor={row => {
                        return {
                            label: row.building_name,
                            value: row.id,
                        }
                    }}
                />
            </Form>
        </Page>
    );
}

export default withRouter(CAddEditPage);