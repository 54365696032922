import configBuilder from "../../../components/ubold/ConfigBuilder";

import utils from "../../../utils";

let definitions = {
  title: "Midtrans Transactions",
  frontendPath: "/admin/logs/midtrans_transaction",
  baseLink: "/payment_gateway/midtrans_transaction",
  restAccessCode: "payment_gateway.midtrans_transaction",
  __table__: {
    hideEditButton: true,
    filterColumn: 4,
    removeIdentifier: "invoice_number",
    filters: [
      {
        id: "invoice",
        name: "Invoice",
        type: "async_select",
        data: "finance/invoice?__type__=select_entries",
        itemsExtractor: (row) => {
          return {
            label: row.number,
            value: row.id,
          };
        },
      },
      {
        id: "midtrans_number",
        name: "Midtrans Number",
        type: "text",
      },
      {
        id: "amount",
        name: "Amount",
        type: "number",
      },
      {
        id: "status",
        name: "Status",
        type: "select",
        data: {
          optionField: "payment_gateway_midtrans_status",
        },
      },
      {
        id: "status_timestamp",
        name: "Status Timestamp",
        type: "date",
      },
      {
        id: "token",
        name: "Token",
        type: "text",
      },
      {
        id: "payment_url",
        name: "Payment URL",
        type: "text",
      },
    ],
    columns: [
      {
        id: "invoice_number",
        name: "Invoice",
        enableHyperlink: true,
      },
      {
        id: "midtrans_number",
        name: "Midtrans Number",
      },
      {
        id: "amount",
        name: "Amount",
        renderer: (value) => {
          return utils.formatter.currency(value);
        },
      },
      {
        id: "status",
        name: "Status",
        referStateFieldName: "user_tenant_identity_type",
        renderer: (value, _, constants) => {
          let label = "-";
          (constants ?? []).forEach((obj) => {
            if (obj.value === parseInt(value)) {
              label = obj.label;
            }
          });
          return label;
        },
      },
      {
        id: "status_timestamp",
        name: "Status Timestamp",
        renderer: (value) => {
          return utils.formatter.dateTime(value);
        },
      },
      {
        id: "token",
        name: "Token",
        wrap: true,
      },
      {
        id: "payment_url",
        name: "Payment URL",
        wrap: true,
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
