import {useState} from "react";

import {useParams, withRouter} from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import Form from "../../../../components/ubold/organisms/Form";

import CVTextField from "../../../../components/ubold/molecules/forms/CVTextField";
import CVRichTextField from "../../../../components/ubold/molecules/forms/CVRichTextField";

import definitions from "./definitions";

function CAddEditPage() {
    let {id} = useParams();

    const initialState = {
        small_title: '-',
        small_title_en: '-',
        small_description: '-',
        small_description_en: '-',
    };

    const [state, setState] = useState(initialState);

    return (
        <Page title={(id === undefined ? 'Add New' : 'Edit') + ' ' +  definitions.title}
              restAccessCode={definitions.restAccessCode}>
            <Form
                id={id}
                definitions={definitions}
                state={state}
                setState={setState}>
                <CVTextField
                    name='title'
                    title='Title'
                    maxLength={25}
                    isRequired
                />
                <CVTextField
                    name='title_en'
                    title='Title EN'
                    maxLength={25}
                    isRequired
                />
                <CVRichTextField
                    name='description'
                    title='Description (Max 250 Character)'
                    maxLength='150'
                    isRequired
                />
                <CVRichTextField
                    name='description_en'
                    title='Description En (Max 250 Character)'
                    maxLength='150'
                    isRequired
                />
                <CVTextField
                    name='url'
                    title='URL'
                    maxLength={250}
                />
            </Form>
        </Page>
    );
}

export default withRouter(CAddEditPage);