import utils from "../../../utils";

import configBuilder from '../../../components/ubold/ConfigBuilder';

let definitions = {
    title: 'Field Accesses',
    baseLink: '/crm/field_access',
    restAccessCode: 'administrator.field_access_list',
    __table__: {
        filterColumn: 3,
        removeIdentifier: 'code',
        filters: [
            {
                id: 'code',
                name: 'Code',
                type: 'text'
            },
            {
                id: 'menu',
                name: 'Menu',
                type: 'async_select',
                data: 'crm/menu?__type__=select_entries'
            },
            {
                id: 'description',
                name: 'Description',
                type: 'text'
            }
        ],
        columns: [
            {
                id: 'code',
                name: 'Code'
            },
            {
                id: 'menu_name',
                name: 'Menu'
            },
            {
                id: 'description',
                name: 'Description'
            },
            {
                id: 'created_at',
                name: 'Created At',
                renderer: value => {
                    return utils.formatter.dateTime(value);
                }
            },
        ]
    }
}

export default configBuilder.buildTablePageConfig(definitions);