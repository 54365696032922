import configBuilder from "../../../components/ubold/ConfigBuilder";
import utils from "utils";

let definitions = {
  title: "SEO CMS",
  frontendPath: "/website/seo_cms",
  baseLink: "/seo_cms/cms",
  restAccessCode: "seo_cms.cms",
  __table__: {
    filterColumn: 4,
    hideEditButton: true,
    hideFilterButton: true,
    columns: [
      {
        id: "title",
        name: "Title ID",
        enableHyperlink: true,
      },
      {
        id: "title_en",
        name: "Title EN",
      },
      {
        id: "publish_status",
        name: "Publish Status",
        renderer: (value: string) => {
          if (value === "2") {
            return "Unpublish";
          } else {
            return "Publish";
          }
        },
      },
      {
        id: "date_created",
        name: "Data Created",
        renderer: (value: string) => {
          return utils.formatter.dateTime(value);
        },
      },
      {
        id: "date_updated",
        name: "Data Updated",
        renderer: (value: string) => {
          return utils.formatter.dateTime(value);
        },
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
