import { withRouter } from "react-router-dom";

import AccordionPane from "../../../../../../../components/ubold/organisms/AccordionPane";
import CHSelect from "../../../../../../../components/ubold/molecules/forms/CHSelect";
import CHAsyncSelect from "../../../../../../../components/ubold/molecules/forms/CHAsyncSelect";
import { sectionProps } from "@types";
import useGlobalState from "state";

function Detail(props: sectionProps) {
  const { baseLink, accordionId, onSubmit, state, setState } = props;
  const [constants] = useGlobalState("constant_values");

  return (
    <AccordionPane
      id="detail"
      name="Detail"
      accordionId={accordionId}
      baseLink={baseLink}
      onSubmit={onSubmit}
      state={state}
      setState={setState}
    >
      <CHAsyncSelect
        name="job_group"
        title="Job/Employement Category"
        data="user/job_group?__type__=select_entries"
        readOnly
      />
      <CHAsyncSelect
        name="organization"
        title="Organization"
        data="user/organization?__type__=select_entries"
        readOnly
      />
      <CHSelect
        name="marital_status"
        title="Marital Status"
        data={constants?.["user_tenant_marital_status"]}
        readOnly
      />
      <CHSelect
        name="religion"
        title="Religion"
        data={constants?.["user_tenant_religion"]}
        readOnly
      />
    </AccordionPane>
  );
}

export default withRouter(Detail);
