import configBuilder from "components/ubold/ConfigBuilder";
import utils from "utils";

let definitions = {
  title: "Building Dashboard",
  frontendPath: "/u/dashboard/building",
  baseLink: "u/dashboard/building/building",
  restAccessCode: "home.dashboard.building.building",
  __table__: {
    isAddButtonDisabled: true,
    isBuildingDashboard: true,
    hideColumnNumbering: true,
    isHideRemoveButton: true,
    hideEditButton: true,
    dateWithDefaultValue: "date_started",
    useOldResetFunc: true,
    filterValidators: {
      building_id: (value, state) => {
        if (state["filter_by"] !== "by_building") {
          return true;
        } else {
          return utils.validator.validateFilterRequired(value);
        }
      },
      date_started: (value) => {
        return utils.validator.validateFilterRequired(value);
      },
      place_group_id: (value, state) => {
        if (state["filter_by"] !== "by_placegroup") {
          return true;
        } else {
          return utils.validator.validateFilterRequired(value);
        }
      },
    },
    filters: [
      {
        id: "filter_by",
        name: "Filter by",
        type: "select",
        data: [
          {
            label: "Building",
            value: "by_building",
          },
          {
            label: "Place Group",
            value: "by_placegroup",
          },
        ],
        hideSelectedOptions: false,
      },
      {
        id: "building_id",
        name: "Building Name",
        type: "async_select",
        data: "building/building?__type__=select_entries",
        itemsExtractor: (item) => {
          return {
            label: item.building_name,
            value: item.id,
          };
        },
        isRequired: true,
        conditionalRenderIdentifier: "by_building",
        placeholder: "Search Building",
        isInputRequired: true,
        customNoOptionMessage: "Please input building name first",
      },
      {
        id: "place_group_id",
        name: "Placegroup",
        type: "async_select",
        data: "building/place_group/?__type__=select_entries",
        itemsExtractor: (item) => {
          return {
            label: item.name,
            value: item.id,
          };
        },
        isRequired: true,
        conditionalRenderIdentifier: "by_placegroup",
        placeholder: "Search Placegroup",
        isInputRequired: true,
        customNoOptionMessage: "Please input placegroup name first",
      },
      {
        id: "date_started",
        name: "Start Date",
        type: "date",
        isRequired: true,
      },
      {
        id: "date_ended",
        name: "End Date",
        type: "date",
        showClearDate: true,
      },
    ],
    columns: [
      {
        id: "building_name",
        name: "Building",
        maxWidthColumn: "unset",
        customPath: "/building/property_list",
        customPathId: "building",
        useTextWithIconRenderer: true,
        customPath2: "/u/dashboard/sales/room_allotment",
        normal: true,
      },
      {
        id: "room_variant_name",
        name: "Room Variant",
        enableHyperlink: true,
        maxWidthColumn: "unset",
        customPath: "/building/room/room_variant",
        customPathId: "room_variant",
        normal: true,
      },
      {
        id: "room_name",
        name: "Room",
        enableHyperlink: true,
        maxWidthColumn: "unset",
        customPath: "/building/room/room",
        customPathId: "id",
        normal: true,
      },
      {
        id: "room_status",
        name: "Current Room Status",
        maxWidthColumn: "165px",
        referStateFieldName: "buildings_room_status",
        useFlagRenderer: true,
        renderer: (value, _, constants) => {
          let roomStatus = "-";
          (constants ?? []).forEach((obj) => {
            if (obj.value === parseInt(value)) {
              roomStatus = obj.label + "|" + obj.value;
            }
          });
          return roomStatus;
        },
      },
      {
        id: "sellable",
        name: "Sellable",
        maxWidthColumn: "56px",
        iconWithText: true,
        renderer: (value) => {
          if (value === "true") {
            return "Yes";
          } else {
            return "No";
          }
        },
        normal: true,
      },
      {
        id: "booked_by_sales",
        name: "Booked by Sales",
        maxWidthColumn: "50px",
        iconWithText: true,
        splitLabel: true,
        disableText: true,
        useTooltip: true,
        renderer: (value, state) => {
          if (value === "true") {
            return `Yes|${state.booked_by}`;
          } else {
            return "No";
          }
        },
        tooltip: {
          limitCharacters: 30,
          trigger: "hover",
          zIndex: 2,
        },
        normal: true,
        getUsername: true,
      },
      {
        id: "checkout_remarks",
        name: "Checkout Remarks",
        maxWidthColumn: "120px",
        normal: true,
      },
      {
        id: "room_remarks",
        name: "Room Remarks",
        maxWidthColumn: "120px",
        normal: true,
      },
      {
        id: "room_variant_remarks",
        name: "Variant Remarks",
        maxWidthColumn: "120px",
        normal: true,
      },
      {
        id: "retail_price",
        name: "Retail Price (Rp)",
        maxWidthColumn: "80px",
        renderer: (value) => {
          return utils.formatter.currency(value, true);
        },
        right: true,
        normal: true,
      },
      {
        id: "discount_price",
        name: "Discount Price (Rp)",
        maxWidthColumn: "80px",
        renderer: (value) => {
          return utils.formatter.currency(value, true);
        },
        right: true,
        normal: true,
        canDoOrdering: false,
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
