import React, { MouseEventHandler } from "react";

import styled, { css } from "styled-components";

const Alert: React.FC<{
  className?: string;

  textMessages?: React.ReactElement | string;
  textHeading?: string;
  onClose: MouseEventHandler<HTMLButtonElement>;
}> = ({ className, textHeading, textMessages, onClose }) => {
  return (
    <AlertStyled className={className}>
      <img
        height={80}
        width={80}
        alt="Alert Icon"
        src={process.env.PUBLIC_URL + "/assets/icons/alert-red.svg"}
      />

      {textHeading && <h5>{textHeading}</h5>}

      {typeof textMessages === "string" ? (
        <p>{textMessages}</p>
      ) : (
        { textMessages }
      )}

      <button onClick={onClose}>OK</button>
    </AlertStyled>
  );
};

export const AlertModalDefaultLayout = css`
  .box-dialog {
    .box-content {
      width: 100%;
      padding: 24px;
    }
  }

  .box-wrapper {
    margin: auto;
  }
`;

const AlertStyled = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  button {
    height: 36px;
    width: 76px;

    background-color: #35b0a7;
    border: unset;
    border-radius: 2px;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    padding: 8px 28.5px;
  }

  h5,
  p {
    text-align: center;
  }

  h5 {
    font-size: 16px;
    font-weight: 500;
    margin-top: unset;
    margin-bottom: 16px;
  }

  img {
    margin-bottom: 30px;
  }

  p {
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 30px;
  }

  strong {
    color: #000;
    font-weight: 500;
  }
`;

export default Alert;
