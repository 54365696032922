import configBuilder from "../../../../components/ubold/ConfigBuilder";

import pageDefinitions from "../definitions";

const definitions = { ...pageDefinitions, validators: {} };

export default configBuilder.buildAddEditPageConfig(
  definitions,
  [],
  [
    "id",
    "airtable_id",
    "name",
    "email",
    "phone",
    "contact_preference",
    "contact_time",
    "visit_time",
    "enquiry_type",
    "enquiry",
    "preferred_location",
    "preferred_building",
    "source",
    "collection_id",
    "room_variant",
    "budget",
    "gender",
    "marital_status",
    "moviein_date",
    "province",
    "city",
    "district",
    "sub_district",
    "remarks",
    "lead_type",
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "utm_content",
    "playing_video",
    "tenant_name",
    "Room",
    "room_variant",
    "room_type",
    "building",
    "date_created",
    "date_udpated",
    "is_deleted",
    "date_deleted",
    "deleted_by",
  ]
);
