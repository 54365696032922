import {withRouter} from "react-router-dom";

import TabPane from "../../../../../../components/ubold/organisms/TabPane";
import TabPaneSection from "../../../../../../components/ubold/organisms/TabPaneSection";

import CVTextView from "../../../../../../components/ubold/molecules/forms/CVTextView";
import CVTextField from "../../../../../../components/ubold/molecules/forms/CVTextField";
import CVSelect from "../../../../../../components/ubold/molecules/forms/CVSelect";
import CVSwitch from "../../../../../../components/ubold/molecules/forms/CVSwitch";
import CVRichTextField from "../../../../../../components/ubold/molecules/forms/CVRichTextField";

import utils from "../../../../../../utils";

function Invoice(props) {
    const {id, definitions, tab, state, setState} = props;

    return (
        <TabPane
            id={id}
            definitions={definitions}
            tab={tab}
            hideAddAnother
            state={state}
            setState={setState}>
            <TabPaneSection multipleColumn style={{padding: '25px'}}>
                <CVTextView
                    title='Invoice Number'
                    name='number'
                />
                <CVTextView
                    title='Grand Total'
                    name='grand_total'
                    renderer={value => {
                        return utils.formatter.currency(value);
                    }}
                />
                <CVTextView
                    title='Order Number'
                    name='order_number'
                />
                <CVTextView
                    title='Additional Number'
                    name='additional_number'
                />
                <CVTextView
                    title='Amount'
                    name='total_amount'
                    renderer={value => {
                        return utils.formatter.currency(value);
                    }}
                />
                <CVTextView
                    title='Status'
                    name='status'
                    renderer={value => {
                        if(value === 1) {
                            return "Waiting for Payment";
                        } else if(value === 2) {
                            return "Proceed";
                        } else if(value === 3) {
                            return "Done";
                        }
                    }}
                />
                <CVTextView
                    title='Started at'
                    name='started_at'
                    renderer={value => {
                        return utils.formatter.date(value);
                    }}
                />
                <CVTextView
                    title='End at'
                    name='ended_at'
                    renderer={value => {
                        return utils.formatter.date(value);
                    }}
                />
                <CVTextView
                    title='Invoice Type'
                    name='invoice_type'
                    renderer={(value) => {
                        if(value === 1) {
                            return 'Monthly';
                        } else if(value === 2) {
                            return 'One Time';
                        }
                    }}
                />
                <CVTextView
                    title='Building Name'
                    name='building_name'
                />
                <CVTextField
                    title='Discount Amount'
                    name='discount_amount'
                    type='number'
                />
                <CVSelect
                    title='Release Status'
                    name='release_status'
                    data={[
                        {
                            label: 'Hold',
                            value: 1
                        },
                        {
                            label: 'Ready to Release',
                            value: 2
                        },
                        {
                            label: 'Release',
                            value: 3
                        }
                    ]}
                />
                <CVSwitch
                    title='Paid with Extra Digit'
                    name='paid_with_extra_digit'
                />
                <CVSwitch
                    title='Laundry Status'
                    name='laundry_status'
                />
                <CVRichTextField
                    title='Discount Notes'
                    name='discount_notes'
                />
                <CVRichTextField
                    title='Remarks'
                    name='remarks'
                />
            </TabPaneSection>
        </TabPane>
    );
}

export default withRouter(Invoice);