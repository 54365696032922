import { withRouter } from "react-router-dom";

import TabPane from "../../../../../../components/ubold/organisms/TabPane";
import TabPaneSection from "../../../../../../components/ubold/organisms/TabPaneSection";
import CHTextField from "../../../../../../components/ubold/molecules/forms/CHTextField";
import CHTextView from "../../../../../../components/ubold/molecules/forms/CHTextView";

import utils from "../../../../../../utils";
import CHSelect from "components/ubold/molecules/forms/CHSelect";
import { useEffect, useState } from "react";
import configs from "configs";
import COneOrManyToManyFieldV2 from "components/ubold/molecules/forms/COneOrManyToManyFieldV2";

function ServiceTab(props: any) {
  const { id, definitions, tab, state, setState } = props;
  const [caseCategories, setCaseCategories] = useState([]);

  useEffect(() => {
    utils.httpClient.get(
      `${configs.apiUrl}building/case_category/?__type__=select_entries&ordering=-id&page=1&limit=50`,
      ({ data }: any) => {
        const tempCaseCategories = data?.rows?.map(
          (item: { id: string; name: string }) => ({
            value: item.id,
            label: item.name,
          })
        );
        setCaseCategories(tempCaseCategories);
      },
      (error: any, message: any) => {
        console.error(message);
      }
    );
  }, []);

  useEffect(() => {
    if (!id) return;
    utils.httpClient.get(
      `${configs.apiUrl}building/service__case_category/?service_id=${id}&ordering=-id`,
      ({ data }: any) => {
        const tempCategories = data.rows
          .sort((a: any, b: any) => a.sequence - b.sequence)
          .map((item: any) => ({
            id: item.id,
            case_category: item.case_category,
            sub_case_category: item.sub_case_category,
            sequence: item.sequence,
            is_active: item.is_active,
            flag: "Editing",
          }));
        setState((prevState: any) => ({
          ...prevState,
          t_categories: tempCategories,
        }));
      },
      (error: any, message: any) => {
        console.error(message);
      }
    );
  }, [id]);

  return (
    <TabPane
      id={id}
      definitions={definitions}
      tab={tab}
      state={state}
      setState={setState}
      readOnly={true}
    >
      <TabPaneSection style={{ padding: "25px 16px 0px" }}>
        <CHSelect
          name="rquest_type"
          title="Request Type"
          data={{
            optionField: "buildings_service_request_type",
          }}
          isRequired
          readOnly
        />
        <CHTextField
          name="name"
          title="Name"
          type="text"
          maxLength={80}
          isRequired
          readOnly
        />
        <CHTextField
          name="name_en"
          title="Name EN"
          maxLength={80}
          type="text"
          isRequired
          readOnly
        />
        <CHTextField name="sla" title="SLA" type="number" readOnly />
        <CHTextView
          name="date_created"
          title="Date Created"
          renderer={(value: string) => {
            return utils.formatter.dateTime(value);
          }}
          readOnly
        />
        <CHTextView
          name="date_updated"
          title="Date Updated"
          renderer={(value: string) => {
            return utils.formatter.dateTime(value);
          }}
          readOnly
        />
        {state?.request_type === 2 ? (
          <>
            <section
              style={{
                width: "100%",
                background: "#f2f2f2",
                height: 30,
                paddingRight: 24,
                paddingLeft: 29,
                alignItems: "center",
                fontSize: 16,
                fontWeight: 500,
                display: "flex",
              }}
            >
              Case and Sub-Case Category
            </section>
            <COneOrManyToManyFieldV2
              id={id}
              name="t_categories"
              saveOnlyFromMainForm
              state={state}
              setState={setState}
              mainTab
              readOnly
              additionalStateInAddNewRow={{ is_active: true }}
              columns={[
                {
                  name: "case_category",
                  title: "Case Category",
                  type: "select",
                  data: caseCategories,
                  readOnly: true,
                },
                {
                  name: "sub_case_category",
                  title: "Sub Case Category",
                  type: "dual_list",
                  width: 700,
                  dependsOn: "case_category",
                  data: "building/sub_case_category/?ordering=-id",
                  isDisabled: true,
                  hitApi: ({ index }: { index: number }) =>
                    !id ||
                    typeof state.t_categories[index].case_category ===
                      "number",
                  sortByField: "sequence",
                  itemsExtractor: (row: any, index: number) => ({
                    label: row.name,
                    value: row.id,
                    sequence: row.sequence,
                  }),
                },
                {
                  name: "is_active",
                  title: "Active",
                  type: "checkbox",
                  readOnly: true,
                },
              ]}
            />
          </>
        ) : (
          <></>
        )}
      </TabPaneSection>
    </TabPane>
  );
}

export default withRouter(ServiceTab);
