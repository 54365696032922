import React from "react";
import {
  HandleModalFunctionType,
  IModalOptionsObject,
  RuModal,
} from "./useModal";
import RuGeneralModal from "./RuGeneralModal";

export interface IModalContext {
  modal: boolean;
  handleModal: HandleModalFunctionType;
  ModalContent: React.ReactElement;
  modalOptions: IModalOptionsObject;
}

let ModalContext: any;
let { Provider } = (ModalContext =
  React.createContext<IModalContext>(null));

const ModalProvider: React.FC = ({ children }) => {
  const { modal, handleModal, ModalContent, modalOptions } =
    RuModal();

  return (
    <Provider
      value={{ modal, handleModal, ModalContent, modalOptions }}
    >
      <RuGeneralModal
        canvasClassName={modalOptions?.canvasClassName ?? "pt-0"}
      />
      {children}
    </Provider>
  );
};

export { ModalContext, ModalProvider };
