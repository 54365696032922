import configBuilder from "components/ubold/ConfigBuilder";
import utils from "utils";

let definitions = {
  title: "USP Highlights",
  frontendPath: "/building/usp_highlight",
  baseLink: "/building/usp_highlight",
  restAccessCode: "building.usp_highlight",
  __table__: {
    hideEditButton: true,
    filterColumn: 4,
    defaultOrdering: "-date_updated",
    filters: [
      {
        id: "name",
        name: "Name",
        type: "text",
      },
      {
        id: "name_en",
        name: "Name EN",
        type: "text",
      },
    ],
    columns: [
      {
        id: "name",
        name: "Name",
        enableHyperlink: true,
      },
      {
        id: "name_en",
        name: "Name EN",
      },
      {
        id: "order",
        name: "Order",
      },
      {
        id: "date_created",
        name: "Date Created",
        renderer: (value: string) => {
          return utils.formatter.dateTime(value);
        },
      },
      {
        id: "date_updated",
        name: "Date Updated",
        renderer: (value: string) => {
          return utils.formatter.dateTime(value);
        },
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
