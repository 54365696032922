import { useState } from "react";

import { useParams, withRouter } from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import Form from "../../../../components/ubold/organisms/Form";

import definitions from "./definitions";
import CVAsyncSelect from "../../../../components/ubold/molecules/forms/CVAsyncSelect";
import CVTextField from "../../../../components/ubold/molecules/forms/CVTextField";
import CVImageView from "components/ubold/molecules/forms/CVImageView";

function CAddEditPage() {
  let { id } = useParams<{ id: string }>();

  const initialState = {
    sequence: 0,
  };

  const [state, setState] = useState(initialState);

  return (
    <Page
      title={
        (id === undefined ? "Add New" : "Edit") +
        " " +
        definitions.title
      }
      restAccessCode={definitions.restAccessCode}
    >
      <Form
        id={id}
        definitions={definitions}
        state={state}
        setState={setState}
        readOnly
      >
        <CVAsyncSelect
          readOnly
          name="building"
          title="Building"
          data="building/building?__type__=select_entries"
          isRequired
          itemsExtractor={(row: {
            building_name: string;
            id: string;
          }) => {
            return {
              label: row.building_name,
              value: row.id,
            };
          }}
        />
        <CVImageView name="photo" title="Photo" isRequired />
        <CVTextField
          readOnly
          name="sequence"
          title="Sequence"
          type="number"
          maxLength={3}
        />
      </Form>
    </Page>
  );
}

export default withRouter(CAddEditPage);
