import { withRouter } from "react-router-dom";

import TabPaneSecondary from "../../../../../../components/ubold/organisms/TabPaneSecondary";

import CHTextView from "components/ubold/molecules/forms/CHTextView";
import CHSelect from "components/ubold/molecules/forms/CHSelect";
import TabPaneSection from "components/ubold/organisms/TabPaneSection";
import CHDateTimePicker from "components/ubold/molecules/forms/CHDateTimePicker";

function InvoicePayments(props) {
  const { definitions, tab, state, setState } = props;

  return (
    <TabPaneSecondary
      definitions={definitions}
      tab={tab}
      restAccessCodeNamespace="payment"
      state={state}
      setState={setState}
    >
      <TabPaneSection style={{ padding: "25px 16px 0px" }}>
        <CHTextView name="amount" title="Total Amount" />
        <CHTextView name="midtrans_number" title="Midtrans Number" />
        <CHTextView
          name="bank_account_number"
          title="Bank Account Number"
        />
        <CHTextView
          name="bank_account_name"
          title="Bank Account Name"
        />
        <CHTextView name="bank_name" title="Bank Name" />
        <CHDateTimePicker
          name="date_transfer"
          title="Date Transfer"
          readOnly
        />
        <CHSelect
          name="payment_status"
          title="Payment Status"
          data={{
            optionField: "finance_new_payment_status",
          }}
          readOnly
        />
        <CHSelect
          name="payment_method"
          title="Payment Method"
          data={{
            optionField: "finance_invoice_payment_method",
          }}
          readOnly
        />
      </TabPaneSection>
    </TabPaneSecondary>
  );
}

export default withRouter(InvoicePayments);
