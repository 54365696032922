import configBuilder from "components/ubold/ConfigBuilder";

let definitions = {
  title: "Terms And Conditions",
  frontendPath: "/admin/configuration/term_and_condition",
  baseLink: "/configuration/term_and_condition",
  restAccessCode: "configuration.term_and_condition",
  __table__: {
    filterColumn: 4,
    hideEditButton: true,
    isHideRemoveButton: true,
    isAddButtonDisabled: true,
    hideFilterButton: true,
    columns: [
      {
        id: "cancellation_policy",
        name: "Kebijakan Pembatalan",
        enableHyperlink: true,
      },
      {
        id: "refund_policy",
        name: "Kebijakan Pengembalian Dana",
      },
      {
        id: "parking_policy",
        name: "Kebijakan Parkir",
      },
      {
        id: "payment_policy",
        name: "Kebijakan Pembayaran Deposit",
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
