import configBuilder from "../../../../components/ubold/ConfigBuilder";

import pageDefinitions from "../definitions";
import utils from "../../../../utils";

const definitions = {
  ...pageDefinitions,
  validator: {
    name: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    sort_order: (value: string) => {
      if (isNaN(Number(value))) {
        return "The value should be number";
      } else if (Number(value) < 0) {
        return "The number should be positive number";
      } else {
        return true;
      }
    },
  },
};

export default configBuilder.buildAddEditPageConfig(definitions);
