import utils from "utils";
import configBuilder from "components/ubold/ConfigBuilder";
import PageDefinitions from "../definitions";

const definitions = {
  ...PageDefinitions,
  validators: {
    title: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    title_en: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    desc: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    desc_en: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    seo_page: (value: number) => {
      return utils.validator.validateRequired(value);
    },
    publish_status: (value: number) => {
      return utils.validator.validateRequired(value);
    },
    country: (value: number, state: { seo_page: number }) => {
      const countryVal = utils.validator.validateRequired(value);
      if (state.seo_page === 2 && countryVal !== true) {
        return "Country is Required";
      } else {
        return true;
      }
    },
    province: (value: number, state: { seo_page: number }) => {
      const provinceVal = utils.validator.validateRequired(value);
      if (state.seo_page === 2 && provinceVal !== true) {
        return "Province is Required";
      } else {
        return true;
      }
    },
    city: (value: number, state: { seo_page: number }) => {
      const countryVal = utils.validator.validateRequired(value);
      if (state.seo_page === 2 && countryVal !== true) {
        return "City is Required";
      } else {
        return true;
      }
    },
  },
};

export default configBuilder.buildAddEditPageConfig(definitions);
