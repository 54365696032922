import configBuilder from '../../../../components/ubold/ConfigBuilder';

import pageDefinitions from "../definitions";

import utils from "../../../../utils";

const definitions = {...pageDefinitions,
    validators: {
        title: value => {
            return utils.validator.validateRequired(value);
        },
        photo_original: value => {
            return utils.validator.validateRequired(value);
        }
    },
}

export default configBuilder.buildAddEditPageConfig(definitions, ['photo_original']);