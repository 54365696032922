import crypt from "./Crypt";

const store = {
    set: (keyOrObject, value) => {
        if(typeof keyOrObject === "object") {
            Object.keys(keyOrObject).forEach(function(key) {
                const encryptedValue = crypt.encrypt(keyOrObject[key]);

                localStorage.setItem(key, encryptedValue)
            });
        } else {
            const encryptedValue = crypt.encrypt(value);

            localStorage.setItem(keyOrObject, encryptedValue);
        }
    },

    isExists: (key) => {
        return this.get(key) !== null;
    },

    get: (key) => {
        const value = localStorage.getItem(key)

        if(value) {
            return crypt.decrypt(value)
        } else {
            return null;
        }
    },

    remove: (key) => {
        const value = localStorage.getItem(key)

        if(value !== null) {
            localStorage.removeItem(key);
        }
    }
}

export default store;