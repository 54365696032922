import { useState, useEffect } from "react";
import { useParams, withRouter } from "react-router";

import Page from "components/ubold/organisms/Page";
import Form from "components/ubold/organisms/Form";
import definitions from "./definitions";
import CVTextArea from "components/ubold/molecules/forms/CVTextArea";
import CVSelect from "components/ubold/molecules/forms/CVSelect";
import CVAsyncSelect from "components/ubold/molecules/forms/CVAsyncSelect";
import CVTextField from "components/ubold/molecules/forms/CVTextField";
import CVCheckBox from "components/ubold/molecules/forms/CVCheckBox";
import CVSimpleManyToMany from "components/ubold/molecules/forms/CVSimpleManyToMany";

function NotificationInfoAddEditPage() {
  let { id }: { id: any } = useParams();

  const initialState: any = {};

  const [state, setState] = useState(initialState);

  useEffect(() => {
    /** set default value
     * when add
     **/
    if (id) return;
    setState((prevState: any) => ({
      ...prevState,
      target: 1,
      notif_type: 1,
      publish: true,
    }));
  }, [id]);

  const selectedTarget = state["target"] ?? null;

  return (
    <Page
      title={
        (id === undefined ? "Add New" : "Edit") +
        " " +
        definitions.title
      }
      restAccessCode={definitions.restAccessCode}
    >
      <Form
        id={id}
        definitions={definitions}
        state={state}
        setState={setState}
      >
        <CVTextField
          name="title"
          title="Title"
          maxLength={254}
          isRequired
          readOnly={id && true}
        />
        <CVTextArea
          name="messages"
          title="Messages"
          isRequired
          readOnly={id && true}
        />
        <CVTextField
          name="sanity_id"
          title="Sanity id"
          isRequired
          readOnly={id && true}
        />
        <CVCheckBox
          name="publish"
          title="Publish"
          readOnly={id && !state["original___publish"] && true}
        />
        <CVSelect
          name="notif_type"
          title="Notif Type"
          data={[
            {
              label: "Info",
              value: 1,
            },
          ]}
          isRequired
          readOnly={id && true}
        />
        <CVSelect
          name="target"
          title="Target"
          data={[
            {
              label: "All User",
              value: 1,
            },
            {
              label: "Specific Building",
              value: 2,
            },
            {
              label: "All Tenant",
              value: 3,
            },
            {
              label: "Specific User",
              value: 4,
            },
          ]}
          isRequired
          readOnly={id && true}
        />
        <CVSimpleManyToMany
          name="users"
          title="Users"
          data="user/tenant?__type__=select_entries&limit=50"
          apiUrl="user/tenant/"
          readOnly={(id || selectedTarget !== 4) && true}
          customWarning={
            selectedTarget !== 4 &&
            !id &&
            "Select users only for target Specific User"
          }
          dataID={id}
          arrDataIdList={state.users}
          useV2
        />
        <CVAsyncSelect
          name="building"
          title="Building"
          data="building/building?__type__=select_entries"
          itemsExtractor={(row: any) => {
            return {
              label: row.building_name,

              value: row.id,
            };
          }}
          readOnly={(id || selectedTarget !== 2) && true}
          customWarning={
            selectedTarget !== 2 &&
            !id &&
            "Select building only for target Specific Building"
          }
        />
      </Form>
    </Page>
  );
}

export default withRouter(NotificationInfoAddEditPage);
