import { withRouter } from "react-router-dom";

import CCheckBox from "../../atoms/forms/CCheckBox";

import useGlobalState from "../../../../state";

import utils from "../../../../utils";

function CVCheckBox(props) {
  const {
    name,
    title,
    isRequired = false,
    restAccessCode,
    formColumn = 2,
    overrideLayout = null,
    state,
    setState,
    checkboxTitle,
    readOnly,
    customWarning = null,
  } = props;

  let formColumnReal = 12 / formColumn;

  const [user] = useGlobalState("user");
  const isSuperUser = user ? user.is_super_user : false;
  const [fieldAccesses] = useGlobalState("field_accesses");
  const [fieldAccessReversePolicy] = useGlobalState(
    "field_access_reverse_policy"
  );

  return (
    <div
      className={
        "col-lg-" + (overrideLayout ? overrideLayout : formColumnReal)
      }
      style={{
        display:
          !isRequired &&
          restAccessCode &&
          utils.access.isFieldHidden(
            fieldAccesses,
            restAccessCode + "." + name
          )
            ? "none"
            : "unset",
      }}
    >
      <div className={"form-group mb-3"}>
        <label
          className={
            (isRequired ? "font-weight-bold" : "") +
            (state[name + "Error"] ? " text-danger" : "")
          }
        >
          {title}
          {isRequired ? "*" : ""}{" "}
          {readOnly ??
          utils.access.isFieldReadOnly(
            fieldAccesses,
            restAccessCode + "." + name,
            fieldAccessReversePolicy,
            isSuperUser
          )
            ? "(read only)"
            : ""}
        </label>
        <div className="checkbox checkbox-success align-items-flex-start d-flex mb-2">
          <CCheckBox
            name={name}
            disabled={
              readOnly ??
              (restAccessCode &&
                utils.access.isFieldReadOnly(
                  fieldAccesses,
                  restAccessCode + "." + name,
                  fieldAccessReversePolicy,
                  isSuperUser
                ))
            }
            state={state}
            setState={setState}
          />
          <label
            htmlFor={name}
            style={{ fontWeight: "normal", marginLeft: 6 }}
          >
            {checkboxTitle}
          </label>
        </div>
        {state[name + "Error"] || customWarning ? (
          <span
            className={!customWarning ? "text-danger" : "text-muted"}
            style={{ fontSize: "12px", marginTop: 5 }}
          >
            {state[name + "Error"] || customWarning}
          </span>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default withRouter(CVCheckBox);
