import configBuilder from "../../../components/ubold/ConfigBuilder";

let definitions = {
  title: "User Vendors",
  frontendPath: "/operation/vendor/user_vendor",
  baseLink: "/vendor/user_vendor",
  restAccessCode: "vendor.user_vendor",
  __table__: {
    filterColumn: 4,
    hideEditButton: true,
    filters: [
      {
        id: "username",
        name: "Username",
        type: "text",
      },
      {
        id: "vendor",
        name: "Vendor",
        type: "async_select",
        data: "vendor/vendor?__type__=select_entries",
      },
      {
        id: "email",
        name: "Email",
        type: "text",
      },
      {
        id: "is_active",
        name: "Is Active?",
        type: "select",
        data: [
          {
            label: "Yes",
            value: true,
          },
          {
            label: "No",
            value: false,
          },
        ],
      },
    ],
    columns: [
      {
        id: "username",
        name: "Username",
        enableHyperlink: true,
      },
      {
        id: "vendor_name",
        name: "Vendor",
      },
      {
        id: "email",
        name: "Email",
      },
      {
        id: "is_active",
        name: "Is Active",
        isSafeContent: true,
        center: true,
        renderer: (value) => {
          if (value === "true") {
            return '<span style="color: rgb(26, 188, 156);"> ✔ </span>';
          } else {
            return '<span style="color: red;">✘</span>';
          }
        },
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
