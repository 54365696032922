import { useParams, withRouter } from "react-router-dom";

import TabPaneSecondary from "../../../../../../components/ubold/organisms/TabPaneSecondary";

import COneOrManyToManyField from "../../../../../../components/ubold/molecules/forms/COneOrManyToManyField";

function Building(props: any) {
  const { definitions, tab, state, setState } = props;

  let { id }: any = useParams();

  return (
    <TabPaneSecondary
      definitions={definitions}
      restAccessCodeNamespace="building"
      tab={tab}
      state={state}
      setState={setState}
      readOnly={true}
    >
      <COneOrManyToManyField
        id={id}
        name={tab.id}
        restPath="building/building__service/"
        relationKey="service_id"
        columns={[
          {
            name: "building",
            title: "Building",
            type: "async_select",
            readOnly: true,
            disabledAtEdit: true,
            isUniqueOption: true,
            data: "building/building?__type__=select_entries",
            itemsExtractor: (row: any) => {
              return {
                label: row.building_name,
                value: row.id,
              };
            },
          },
          {
            name: "active",
            title: "Is Active",
            type: "checkbox",
            default: true,
            readOnly: true,
          },
        ]}
      />
    </TabPaneSecondary>
  );
}

export default withRouter(Building);
