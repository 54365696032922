import {
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";

import { CustomWindowEventMap } from "../@types";
import { ModalCollectionContext, ToastContext } from "./context";

export function useEventListener<
  T extends HTMLElement = HTMLDivElement
>(
  eventName: keyof CustomWindowEventMap,
  handler: (event: Event) => void,
  element?: RefObject<T>
) {
  // Create a ref that stores handler
  const savedHandler = useRef<(event: Event) => void>();

  useEffect(() => {
    // Define the listening target
    const targetElement: T | Window = element?.current || window;
    if (!(targetElement && targetElement.addEventListener)) {
      return;
    }

    // Update saved handler if necessary
    if (savedHandler.current !== handler) {
      savedHandler.current = handler;
    }

    // Create event listener that calls handler function stored in ref
    const eventListener = (event: Event) => {
      // eslint-disable-next-line no-extra-boolean-cast
      if (!!savedHandler?.current) {
        savedHandler.current(event);
      }
    };

    targetElement.addEventListener(eventName, eventListener);

    // Remove event listener on cleanup
    return () => {
      targetElement.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element, handler]);
}

export const useInterval = (
  callback: () => void,
  delay: number | null
) => {
  const savedCallback = useRef(callback);

  // Remember the latest callback if it changes.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    if (delay === null) {
      return;
    }

    const id = setInterval(() => savedCallback.current(), delay);

    return () => clearInterval(id);
  }, [delay]);
};

export const useToast = () => {
  const { dispatch } = useContext(ToastContext);
  return dispatch;
};

export const useRukiModal = () => {
  const rukiModal = useContext(ModalCollectionContext);

  return rukiModal;
};

export const useIsMountedRef = () => {
  const isMountedRef = useRef<boolean | null>(null);
  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  return isMountedRef;
};

const useMutationObserver = (
  domNodeSelector: string,
  observerOptions: { attributes: boolean },
  callback: () => void
) => {
  useEffect(() => {
    const targetNode = document.querySelector(domNodeSelector);

    const observer = new MutationObserver(callback);

    observer.observe(targetNode, observerOptions);

    return () => {
      observer.disconnect();
    };
  }, [domNodeSelector, observerOptions, callback]);
};

export const useIsomorphicLayoutEffect =
  typeof window !== "undefined" ? useLayoutEffect : useEffect;

function useElementSize<T extends HTMLElement = HTMLDivElement>(): [
  (node: T | null) => void,
  {
    width: number;
    height: number;
  }
] {
  // Mutable values like 'ref.current' aren't valid dependencies
  // because mutating them doesn't re-render the component.
  // Instead, we use a state as a ref to be reactive.
  const [ref, setRef] = useState<T | null>(null);
  const [size, setSize] = useState<{
    width: number;
    height: number;
  }>({
    width: 0,
    height: 0,
  });

  // Prevent too many rendering using useCallback
  const handleSize = useCallback(() => {
    setSize({
      width: ref?.offsetWidth || 0,
      height: ref?.offsetHeight || 0,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref?.offsetHeight, ref?.offsetWidth]);

  useEventListener("resize", handleSize);

  useMutationObserver("body", { attributes: true }, () => {
    // Debounce
    setTimeout(() => {
      setSize((prevState) => {
        return {
          ...prevState,
          width: ref?.getClientRects()[0].width,
        };
      });
    }, 300);
  });

  useIsomorphicLayoutEffect(() => {
    handleSize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref?.offsetHeight, ref?.offsetWidth]);

  return [setRef, size];
}

export default useElementSize;
