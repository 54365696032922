import { useState } from "react";
import { useParams, withRouter } from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import Form from "../../../../components/ubold/organisms/Form";

import CVTextField from "../../../../components/ubold/molecules/forms/CVTextField";
import CVSelect from "../../../../components/ubold/molecules/forms/CVSelect";
import CVAsyncSelect from "../../../../components/ubold/molecules/forms/CVAsyncSelect";
import CVDateTimePicker from "../../../../components/ubold/molecules/forms/CVDateTimePicker";
import CVTextView from "../../../../components/ubold/molecules/forms/CVTextView";

import definitions from "./definitions";
import CVImageView from "components/ubold/molecules/forms/CVImageView";

function VoucherRedemptionViewPage() {
  let { id }: { id: string } = useParams();

  const initialState = {};

  const [state, setState] = useState(initialState);

  return (
    <Page
      title={"Detail of " + definitions.title}
      restAccessCode={definitions.restAccessCode}
    >
      <Form
        id={id}
        definitions={definitions}
        readOnly
        state={state}
        setState={setState}
      >
        <CVAsyncSelect
          title="Tenant"
          name="tenant"
          data="user/tenant?__type__=select_entries"
          isRequired
          readOnly
          itemsExtractor={(row: {
            name: string;
            email: string;
            id: number;
            username: string;
            country_code: number;
            phone_number: number;
          }) => {
            return {
              label:
                row.name +
                " - " +
                row.email +
                " - " +
                row.country_code +
                row.phone_number,
              value: row.id,
            };
          }}
        />
        <CVTextField
          title="Name"
          name="name"
          maxLength={254}
          readOnly
        />
        <CVTextField
          title="Phone Number"
          name="phone_number"
          maxLength={16}
          isRequired
          type="number"
          readOnly
        />
        <CVAsyncSelect
          title="Voucher"
          name="voucher"
          isRequired
          data="user/user_voucher?__type__=select_entries"
          readOnly
        />
        <CVDateTimePicker
          title="Date Transferred"
          name="date_transferred"
          readOnly
        />
        <CVImageView
          title="Prove Transferred (max size 500kB)"
          name="prove_transferred_original"
        />
        <CVSelect
          title="Status"
          name="status"
          isRequired
          data={[
            {
              label: "Pending",
              value: 1,
            },
            {
              label: "Done",
              value: 2,
            },
            {
              label: "Cancelled",
              value: 3,
            },
          ]}
          readOnly
        />
        <CVTextView
          title="Provider"
          name="external_provider"
          readOnly
        />
      </Form>
    </Page>
  );
}

export default withRouter(VoucherRedemptionViewPage);
