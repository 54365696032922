import { Link } from "react-router-dom";
import Tooltip from "../tooltip/Tooltip";

type Props = {
  label: string;
  url: string;
  id: number | string;
  isShowQRButton?: boolean;
};

function CQRCodeAndURL({
  url,
  label,
  id,
  isShowQRButton = true,
}: Props) {
  const env = process.env.REACT_APP_ENV_NAME;
  const urlBasedOnEnv =
    env === "production"
      ? "https://www.rukita.co" + url
      : "https://harbor.imarukita.ninja" + url;

  function copyUrlToClipboard() {
    navigator.clipboard.writeText(urlBasedOnEnv);
  }

  return (
    <div className="form-group row mb-3">
      <label className="col-md-3 col-form-label">{label}</label>
      <div className="col-md-9">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Tooltip
            contentMain={"Link Copied!"}
            customCSS={{
              fontSize: 12,
              fontWeight: 500,
              width: "auto",
            }}
            hideOnClick
            trigger={"click"}
            zIndex={1}
          >
            <div
              onClick={copyUrlToClipboard}
              style={styles.copyLinkButton}
            >
              <p style={styles.copyLinkText}>Click to copy link</p>
            </div>
          </Tooltip>

          <Link
            to={id + "/print_qr"}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              ...styles.showQrButton,
              visibility: isShowQRButton ? "visible" : "hidden",
            }}
          >
            <p style={styles.showQrText}>Print QR Code</p>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default CQRCodeAndURL;

const styles = {
  copyLinkButton: {
    height: "30px",
    display: "flex",
    width: "fit-content",
    paddingLeft: 12,
    paddingRight: 12,
    alignItems: "center",
    border: "1px solid #111",
    borderRadius: 4,
    cursor: "pointer",
  },
  copyLinkText: {
    margin: 0,
    fontWeight: 500,
    color: "#111",
  },
  showQrButton: {
    marginLeft: 12,
    height: "30px",
    display: "flex",
    color: "#FFF",
    border: "1px solid rgb(26, 188, 156)",
    backgroundColor: "rgb(26, 188, 156)",
    width: "fit-content",
    paddingLeft: 12,
    paddingRight: 12,
    alignItems: "center",
    borderRadius: 4,
    cursor: "pointer",
  },
  showQrText: {
    margin: 0,
    fontWeight: 500,
    color: "#FFF",
  },
};
