import { useState } from "react";

import { withRouter } from "react-router-dom";
import Select from "react-select";

import utils from "../../../../utils";

function Pagination(props) {
  const {
    fetch,
    jumpToSelectedPage,
    rowsPerPage,
    selectedPage,
    selectedRowPerPage,
    setJumpToSelectedPage,
    setSelectedPage,
    setSelectedRowPerPage,
    totalRows,
  } = props;

  const [isMoreThanTotalPage, setIsMoreThanTotalPage] =
    useState(false);

  const valueFromId = (opts, id) =>
    (opts ?? []).find((o) => o.value === id);

  const pageCount =
    totalRows === 0 ? 1 : Math.ceil(totalRows / selectedRowPerPage);

  let pages = [];

  pages.push("&laquo;");
  pages.push("Prev");

  const pagesPagination = utils.commons.createPagination(
    selectedPage,
    pageCount
  );
  pages = pages.concat(pagesPagination);

  pages.push("Next");
  pages.push("&raquo;");

  const pagination = [];
  for (let i = 0; i < pages.length; i++) {
    let pageNumber = pages[i];

    pagination[i] = (
      <li
        key={"pagination" + i}
        className={
          "page-item " + (pageNumber === selectedPage ? "active" : "")
        }
        style={{
          marginRight: "1px",
        }}
      >
        <a
          style={{
            cursor: "pointer",
            backgroundColor:
              pageNumber === selectedPage ? "#D9EFE5" : "unset",
            border:
              pageNumber === selectedPage
                ? "1px solid #35B0A7"
                : "1px solid #dee2e6",
            color:
              pageNumber === selectedPage ? "#35B0A7" : "#323a46",
          }}
          onClick={() => {
            if (pageNumber !== "...") {
              if (pageNumber === "&raquo;") {
                pageNumber = pageCount;
              } else if (pageNumber === "&laquo;") {
                pageNumber = 1;
              } else if (pageNumber === "Next") {
                pageNumber =
                  selectedPage < pageCount
                    ? selectedPage + 1
                    : pageCount;
              } else if (pageNumber === "Prev") {
                pageNumber = selectedPage > 1 ? selectedPage - 1 : 1;
              }
              setSelectedPage(pageNumber);
              setJumpToSelectedPage(pageNumber);

              fetch(pageNumber, selectedRowPerPage);
            }

            setIsMoreThanTotalPage(false);
          }}
          className="page-link"
          dangerouslySetInnerHTML={{ __html: pageNumber }}
        />
      </li>
    );
  }

  const handlePageSelection = (value, pageCount) => {
    if (value <= pageCount && value >= 1) {
      setIsMoreThanTotalPage(false);
      setJumpToSelectedPage(value);
    } else if (value > pageCount) {
      setIsMoreThanTotalPage(true);
      setJumpToSelectedPage(pageCount);
    } else if (value === "") {
      setJumpToSelectedPage(null);
    }
  };

  return (
    <div
      className="row p-2"
      style={{ fontWeight: 400, fontSize: 12 }}
    >
      <div className="col-12 col-md-9">
        <div className="grid-container">
          <div className="form-row align-items-center">
            <div className="col-auto">
              <label
                htmlFor="inlineFormInputGroup"
                style={{ marginBottom: 4 }}
              >
                Limit
              </label>
              <select
                id="inlineFormInput"
                className="form-control custom-select"
                value={selectedRowPerPage}
                style={{ color: "#999999" }}
                onChange={(e) => {
                  const newSelectedRowPerPage = parseInt(
                    e.target.value
                  );

                  setSelectedRowPerPage(newSelectedRowPerPage);

                  const resetPageNumber = 1;

                  setSelectedPage(resetPageNumber);
                  setJumpToSelectedPage(resetPageNumber);

                  setIsMoreThanTotalPage(false);

                  fetch(resetPageNumber, newSelectedRowPerPage);
                }}
              >
                {rowsPerPage.map((value, index) => {
                  return <option key={index}>{value}</option>;
                })}
              </select>
            </div>
            <div className="col-auto pt-2 pt-md-0">
              <label
                htmlFor="inlineFormInputGroup"
                style={{ marginBottom: 4 }}
              >
                Jump to:{" "}
                {isMoreThanTotalPage ? (
                  <span style={{ fontSize: "12px", color: "red" }}>
                    Maximum page number {pageCount}
                  </span>
                ) : (
                  <></>
                )}
              </label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <div
                    className="input-group-text"
                    style={{
                      backgroundColor: "#fff",
                      color: "#999999",
                    }}
                  >
                    Page:
                  </div>
                </div>
                <Select
                  styles={{
                    indicatorSeparator: (provided) => {
                      return { ...provided, display: "none" };
                    },
                    control: (provided) => ({
                      ...provided,
                      outline: "none",
                      borderRadius: 0,
                      width: 80,
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      color: "#999999",
                    }),
                  }}
                  closeMenuOnSelect
                  menuPortalTarget={document.body}
                  hideSelectedOptions={true}
                  value={valueFromId(
                    utils.commons.createPaginationObj(pageCount),
                    jumpToSelectedPage
                  )}
                  options={utils.commons.createPaginationObj(
                    pageCount
                  )}
                  onChange={(e) =>
                    handlePageSelection(e.value, pageCount)
                  }
                />

                <div className="input-group-append">
                  <button
                    type="button"
                    className="btn btn-success waves-effect waves-light"
                    style={{ backgroundColor: "#00BBB4" }}
                    onClick={() => {
                      const page = jumpToSelectedPage ?? 1;
                      setJumpToSelectedPage(page);

                      setSelectedPage(page);

                      setIsMoreThanTotalPage(false);

                      fetch(page, selectedRowPerPage);
                    }}
                  >
                    Go
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-3">
        <div className="grid-container h-100 d-flex align-items-end justify-content-start justify-content-md-end">
          <div className="form-row pt-2 pt-md-0">
            <div className="col-auto">
              <label style={{ marginBottom: 4 }}>
                {selectedPage} from {pageCount} ({totalRows} records)
              </label>
              <div className="input-group flex-column">
                <ul className="pagination pagination-m m-0 justify-content-end">
                  {pagination}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(Pagination);
