import configBuilder from "../../../components/ubold/ConfigBuilder";
import utils from "utils";

let definitions = {
  title: "Promo and Discount",
  frontendPath: "/marketing/promotion/discount_master",
  baseLink: "/promotion/discount_master",
  restAccessCode: "promotion.discount_master",
  __table__: {
    hideEditButton: true,
    filters: [
      {
        id: "name",
        name: "Name",
        type: "text",
      },
      {
        id: "category",
        name: "Category",
        type: "select",
        data: [
          { label: "Discount", value: 1 },
          { label: "Promo", value: 2 },
          { label: "Discount Voucher", value: 3 },
          { label: "Apology Voucher", value: 4 },
        ],
      },
      {
        id: "start_date",
        name: "Date Started",
        type: "date",
      },
      {
        id: "end_date",
        name: "Date Ended",
        type: "date",
      },
    ],
    columns: [
      {
        id: "name",
        name: "Name",
        enableHyperlink: true,
      },
      {
        id: "start_date",
        name: "Start Date",
        type: "date",
        renderer: (value: string) => {
          return utils.formatter.date(value);
        },
      },
      {
        id: "end_date",
        name: "End Date",
        type: "date",
        renderer: (value: string) => {
          return utils.formatter.date(value);
        },
      },
      {
        id: "category",
        name: "Category",
        renderer: (value: string) => {
          switch (value) {
            case "1":
              return "Discount";
            case "2":
              return "Promo";
            case "3":
              return "Discount Voucher";
            case "4":
              return "Apology Voucher";
            default:
              return "Unknown";
          }
        },
      },
      {
        id: "maximum_amount",
        name: "Maximum Amount",
        renderer: (value: number) => {
          return utils.formatter.currency(value);
        },
      },
      {
        id: "percentage",
        name: "Percentage",
      },
      {
        id: "budget_allocation",
        name: "Budget Allocation",
        renderer: (value: number) => {
          return utils.formatter.currency(value);
        },
      },
      {
        id: "budget_spent",
        name: "Budget Spent",
        renderer: (value: number) => {
          return utils.formatter.currency(value);
        },
      },
      {
        id: "date_created",
        name: "Date Created",
        renderer: (value: string) => {
          return utils.formatter.dateTime(value);
        },
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
