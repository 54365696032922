const { createGlobalState } = require("react-hooks-global-state");

const initialState = {
  user: null,
  menus: null,
  menu_accesses: null,
  accesses: null,
  field_accesses: null,
  constant_values: null,
  field_access_reverse_policy: [],
};

const { useGlobalState } = createGlobalState(initialState);

export default useGlobalState;
