import { withRouter } from "react-router-dom";

import CTextField from "../../atoms/forms/CTextField";

import useGlobalState from "state";

import utils from "utils";

function CHPhoneNumber(props) {
  const {
    countryCodeName,
    isRequired = false,
    maxLengthCountryCode,
    maxLengthPhoneNumber,
    phoneNumberName,
    readOnly,
    restAccessCode,
    setState,
    state,
    title,
    name,
  } = props;

  const [user] = useGlobalState("user");
  const isSuperUser = user ? user.is_super_user : false;
  const [fieldAccesses] = useGlobalState("field_accesses");
  const [fieldAccessReversePolicy] = useGlobalState(
    "field_access_reverse_policy"
  );

  return (
    <div
      className="form-group row mb-3"
      style={{
        display:
          !isRequired &&
          restAccessCode &&
          utils.access.isFieldHidden(
            fieldAccesses,
            restAccessCode + "." + name
          )
            ? "none"
            : "flex",
      }}
    >
      <label
        className={
          "col-md-3 col-form-label" +
          (isRequired ? " font-weight-bold" : "") +
          (state[phoneNumberName + "Error"] ? " text-danger" : "")
        }
      >
        {title}
        {isRequired ? "*" : ""}{" "}
        {readOnly ??
        utils.access.isFieldReadOnly(
          fieldAccesses,
          restAccessCode + "." + name,
          fieldAccessReversePolicy,
          isSuperUser
        )
          ? "(read only)"
          : ""}
      </label>
      <div className="col-md-9">
        <div className="d-flex">
          <div style={{ width: 65, marginRight: 4 }}>
            <CTextField
              type="number"
              maxLength={maxLengthCountryCode ?? 2}
              name={countryCodeName}
              readOnly={readOnly}
              state={state}
              setState={setState}
            />
          </div>
          <div>
            <CTextField
              type="number"
              maxLength={maxLengthPhoneNumber ?? 15}
              name={phoneNumberName}
              readOnly={
                readOnly ??
                (restAccessCode &&
                  utils.access.isFieldReadOnly(
                    fieldAccesses,
                    restAccessCode + "." + name,
                    fieldAccessReversePolicy,
                    isSuperUser
                  ))
              }
              state={state}
              setState={setState}
            />
          </div>
        </div>
        {state[phoneNumberName + "Error"] ? (
          <span className="text-danger" style={{ fontSize: "12px" }}>
            {state[phoneNumberName + "Error"]}
          </span>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default withRouter(CHPhoneNumber);
