import { useEffect, useState } from "react";

import { withRouter } from "react-router-dom";

import utils from "../../../../utils";

function CMultiCheckBox(props) {
  const {
    name,
    onChange,
    indexData = 0,
    readOnly,
    data = [],
    state,
    disabled = false,
  } = props;

  const [values, setValues] = useState([]);

  useEffect(() => {
    if (state[name]) {
      setValues(state[name]);
    }
  }, [state[name]]);

  return (
    <div>
      {data.map((item, index) => {
        return (
          <div
            key={"multi" + name + indexData + index}
            className="checkbox checkbox-success h-100 d-flex align-items-center px-1"
          >
            <input
              id={"multi" + name + indexData + index}
              name={name}
              disabled={disabled}
              readOnly={readOnly}
              value={item.value}
              checked={values && values.includes(item.value)}
              type="checkbox"
              onChange={() => {
                let valuesClone = JSON.parse(JSON.stringify(values));

                if (valuesClone.includes(item.value)) {
                  const index = valuesClone.indexOf(item.value);

                  if (index !== -1) {
                    valuesClone.splice(index, 1);
                  }
                } else {
                  valuesClone.push(item.value);
                }

                valuesClone.sort(function (a, b) {
                  return a - b;
                });

                setValues(valuesClone);

                if (utils.commons.isFunction(onChange)) {
                  onChange(valuesClone);
                }
              }}
            />
            <label
              htmlFor={"multi" + name + indexData + index}
              style={{ fontWeight: "normal" }}
            >
              {item.label}
            </label>
          </div>
        );
      })}
    </div>
  );
}

export default withRouter(CMultiCheckBox);
