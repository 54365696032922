import { useEffect, useMemo, useState } from "react";

import { withRouter } from "react-router-dom";
import useGlobalState from "state";

import TabPane from "../../../../../../components/ubold/organisms/TabPane";
import TabPaneSection from "../../../../../../components/ubold/organisms/TabPaneSection";

import CHTextView from "../../../../../../components/ubold/molecules/forms/CHTextView";
import CHTextField from "../../../../../../components/ubold/molecules/forms/CHTextField";
import CHSelect from "../../../../../../components/ubold/molecules/forms/CHSelect";
import CHAsyncSelect from "../../../../../../components/ubold/molecules/forms/CHAsyncSelect";
import CHCheckBox from "../../../../../../components/ubold/molecules/forms/CHCheckBox";
import CHRichTextField from "../../../../../../components/ubold/molecules/forms/CHRichTextField";
import CHTextArea from "../../../../../../components/ubold/molecules/forms/CHTextArea";
import CHDateTimePicker from "components/ubold/molecules/forms/CHDateTimePicker";

import utils from "../../../../../../utils";
import CQRCodeAndURL from "components/ubold/atoms/buttons/CQRCodeAndURL";

function Room(props) {
  const { id, definitions, tab, state, setState } = props;

  const [roomStatus, setRoomStatus] = useState({
    value: 0,
    label: "None",
  });
  const [
    roomStatusOccupiedByLegacyTenant,
    setRoomStatusOccupiedByLegacyTenant,
  ] = useState([]);

  const [constant] = useGlobalState("constant_values");
  const getParamBuildingId = (param) => {
    if (!param) return;
    return `&building_id=${param}`;
  };

  const getOccupiedByRuOptionsLegacyTenantStatus = () => {
    const roomStatusRuOptionOccupiedByLegacyTenant =
      constant?.buildings_room_status?.filter(
        (roomStatus) => roomStatus.value === 9
      );

    return roomStatusRuOptionOccupiedByLegacyTenant;
  };

  useEffect(() => {
    if (!id || !constant || !state["possible_transitions"]) return;
    const filteredRoomStatus = constant["buildings_room_status"];
    const possibleTransition = utils.commons.subsetVal(
      state?.["possible_transitions"]?.[0]
    );

    if (
      state?.["building_ruoptions"] &&
      !(
        state?.original___room_status === 11 ||
        state?.original___room_status === 12 ||
        state?.original___room_status === 13
      )
    )
      possibleTransition?.push(9);
    possibleTransition?.push(state["room_status"]);

    filteredRoomStatus?.forEach((obj) => {
      if (!possibleTransition?.includes(obj.value)) {
        obj.disabled = true;
      }
    });
    setRoomStatus(filteredRoomStatus);
  }, [
    id,
    constant,
    state?.["building_ruoptions"],
    state?.["possible_transitions"],
  ]);

  useEffect(() => {
    if (state?.room_status === 9) return;

    if (
      constant?.["buildings_room_status"] &&
      constant?.["buildings_room_status"].length > 0
    ) {
      if (state?.building_ruoptions) {
        setRoomStatusOccupiedByLegacyTenant(
          getOccupiedByRuOptionsLegacyTenantStatus()
        );
      } else {
        setRoomStatusOccupiedByLegacyTenant([]);
      }
    }
  }, [constant?.buildings_room_status, state?.building_ruoptions]);

  const expectedMTStatus = [1, 2];

  const readOnlyMaintenanceEndDate = useMemo(() => {
    /**
     * 1.For Room with Room Status
     *   - Out of Service (13)
     *   - Occupied Need Repair (12)
     * And Maintenance Status
     *   - Repair and Maintenance (1)
     * Then Date Ended Maintenance must be Read Only
     */

    if (state["room_status"] !== 13 && state["room_status"] !== 12) {
      return false;
    }

    if (state["maintenance_status"] !== 1) {
      return false;
    }

    return true;
  }, [state["room_status"], state["maintenance_status"]]);

  return (
    <TabPane
      id={id}
      definitions={definitions}
      tab={tab}
      state={state}
      setState={setState}
    >
      <TabPaneSection style={{ padding: "25px 16px 0px" }}>
        <CHTextField
          name="name"
          title="Name"
          isRequired
          maxLength={100}
          helperText="Room identity eg. `100` or `QR1`"
        />
        <CHTextView name="building_name" title="Building Name" />
        {/* Start of QR Code and URL Copy Button Section */}
        <CQRCodeAndURL
          label="Building URL and QR Code"
          url={`/book/verify?assetId=${state.building}&variantId=${state.room_variant}&externalRoomId=${state.external_id}&qr=1&utm_campaign=offline&utm_source=offline&utm_medium=offline&utm_content=offline`}
          id={id}
        />
        {/* End of QR Code and URL Copy Button Section */}
        <CHAsyncSelect
          afterChange={(value) => {
            if (value?.building_ruoption) {
              setRoomStatusOccupiedByLegacyTenant(
                getOccupiedByRuOptionsLegacyTenantStatus()
              );
            } else {
              setRoomStatusOccupiedByLegacyTenant([]);
            }

            if (value?.building_ruoption) {
              setState((prev) => ({
                ...prev,
                building_ruoption: true,
              }));
            } else {
              setState((prev) => ({
                ...prev,
                building_ruoption: false,
              }));
            }
          }}
          name="room_variant"
          title="Room Variant"
          isRequired
          data={`building/room_variant/?__type__=select_entries${
            getParamBuildingId(state.building)
              ? getParamBuildingId(state.building)
              : ""
          }`}
        />
        <CHTextField
          name="total_bed"
          title="Total Bed"
          type="number"
        />
        <CHAsyncSelect
          name="room_type"
          title="Room Type"
          data="building/room_type/?__type__=select_entries"
        />
        <CHTextField
          name="area_size"
          title="Area Size"
          type="number"
        />
        <CHTextField
          name="price"
          title="Price"
          type="number"
          helperText="Price should be greater than Rp. 0"
        />
        <CHSelect
          name="electricity"
          title="Electricity"
          data={[
            {
              label: "Centralized",
              value: 1,
            },
            {
              label: "Token",
              value: 2,
            },
          ]}
        />
        <CHRichTextField name="remarks" title="Remarks" />
        <CHSelect
          name="bathroom"
          title="Bathroom"
          data={[
            {
              label: "Inside Room",
              value: 1,
            },
            {
              label: "Outside Room",
              value: 2,
            },
            {
              label: "Shared Bathroom",
              value: 3,
            },
          ]}
        />
        <CHSelect
          name="gender"
          title="Gender"
          data={[
            {
              label: "All Gender",
              value: 0,
            },
            {
              label: "Male",
              value: 1,
            },
            {
              label: "Female",
              value: 2,
            },
          ]}
        />
        <CHAsyncSelect
          name="discount"
          title="Discount"
          data="finance/discount/?__type__=select_entries"
        />
        <CHTextView
          name="date_created"
          title="Date Created"
          renderer={(value) => {
            return value
              ? utils.formatter.dateTime(
                  value,
                  "MMMM DD, YYYY, HH:mm"
                )
              : "-";
          }}
        />
        <CHTextView
          name="date_updated"
          title="Date Updated"
          renderer={(value) => {
            return value
              ? utils.formatter.dateTime(
                  value,
                  "MMMM DD, YYYY, HH:mm"
                )
              : "-";
          }}
        />
        <CHSelect
          name="room_status"
          title="Room Status"
          data={
            !id
              ? { optionField: "buildings_room_status" }
              : roomStatus
          }
          filterItems={
            !id
              ? (options) => {
                  if (id === undefined) {
                    const newOptions = options?.filter((option) => {
                      // 6, 10, 13 -> initial state when add
                      return (
                        option?.value === 6 ||
                        option?.value === 10 ||
                        option?.value === 13
                      );
                    });
                    return [
                      ...new Set([
                        ...newOptions,
                        ...roomStatusOccupiedByLegacyTenant,
                      ]),
                    ];
                  } else {
                    return options;
                  }
                }
              : false
          }
        />

        <CHSelect
          name="maintenance_status"
          title="Maintenance Status"
          data={{
            optionField: "buildings_room_maintenance_status",
          }}
          filterItems={(options) => {
            const newOptions = options?.filter((option) => {
              if (state?.["room_status"] === 12) {
                // Occupied - Need Repair
                return (
                  option?.value !== 0 &&
                  option?.value !== 3 &&
                  option?.value !== 4
                );
              } else if (state?.["room_status"] === 13) {
                // Out of services
                return true;
              } else {
                return option?.value === 0;
              }
            });

            return newOptions;
          }}
          filterItemsDependencies={[state?.["room_status"]]}
        />
        <CHDateTimePicker
          name="date_started_maintenance"
          title="Date Started Maintenance"
          readOnly={
            !expectedMTStatus.includes(state["maintenance_status"])
          }
          type="date"
          disableMobile
        />
        <CHDateTimePicker
          name="maintenance_date_ended"
          title="Date Ended Maintenance"
          readOnly={
            !expectedMTStatus.includes(state["maintenance_status"]) ||
            readOnlyMaintenanceEndDate
          }
          type="date"
          disableMobile
        />

        <CHTextArea
          readOnly
          name="booked_remark"
          title="Booked Remark"
        />
        <CHTextArea
          name="room_status_notes"
          title="Room Status Notes"
        />
        <CHTextField name="floor" title="Floor" maxLength={10} />
        <CHCheckBox name="booked_by_sales" title="Booked by Sales" />
        <CHDateTimePicker
          name="live_date"
          title="Room Live Date"
          type="date"
        />
      </TabPaneSection>
    </TabPane>
  );
}

export default withRouter(Room);
