import {useState} from "react";

import {useParams, withRouter} from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import TabbedForm from "../../../../components/ubold/organisms/TabbedForm";
import TabHeaders from "../../../../components/ubold/organisms/TabHeaders";
import TabContent from "../../../../components/ubold/organisms/TabContent";

import Invoice from "./Tabs/Invoice";
import InvoiceDetails from "./Tabs/InvoiceDetails";
import InvoicePayments from "./Tabs/InvoicePayments";
import InvoiceMidtransTransactions from "./Tabs/InvoiceMidtransTransactions";

import definitions from "./definitions";

function InvoiceAddEditPage() {
    let {id} = useParams();

    const initialState = {};

    const [state, setState] = useState(initialState);

    return (
        <Page title={(id === undefined ? 'Add New' : 'Edit') + ' ' +  definitions.title}
              restAccessCode={definitions.restAccessCode}>
            <TabbedForm
                id={id}
                state={state}
                setState={setState}
                definitions={definitions}>
                <TabHeaders />
                <TabContent>
                    <Invoice />
                    <InvoiceDetails />
                    <InvoicePayments />
                    <InvoiceMidtransTransactions />
                </TabContent>
            </TabbedForm>
        </Page>
    );
}

export default withRouter(InvoiceAddEditPage);