import configBuilder from "../../../components/ubold/ConfigBuilder";

import utils from "../../../utils";

let definitions = {
  title: "Job/Employment/School",
  frontendPath: "/admin/tenant/occupation",
  baseLink: "/user/occupation",
  restAccessCode: "user.occupation",
  __table__: {
    hideEditButton: true,
    filterColumn: 4,
    filters: [
      {
        id: "name",
        name: "Name",
        type: "text",
      },
      {
        id: "job_group",
        name: "Job Group",
        type: "async_select",
        data: "user/job_group?__type__=select_entries",
      },
      {
        id: "date_created",
        name: "Date Created",
        type: "date",
      },
      {
        id: "date_updated",
        name: "Date Updated",
        type: "date",
      },
    ],
    columns: [
      {
        id: "name",
        name: "Name",
        enableHyperlink: true,
      },
      {
        id: "job_group_name",
        name: "Job Group",
      },
      {
        id: "date_created",
        name: "Date Created",
        renderer: (value) => {
          return utils.formatter.dateTime(value);
        },
      },
      {
        id: "date_updated",
        name: "Date Updated",
        renderer: (value) => {
          return utils.formatter.dateTime(value);
        },
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
