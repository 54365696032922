import {
  ModalNames,
  ModalTreeProviderShape,
  ToastType,
} from "@types";
import React, { ReactNode } from "react";

export const ToastContext = React.createContext<{
  dispatch: ({
    description,
    dTime,
    type,
  }: {
    description: string | number | ReactNode;
    dTime?: number;
    type?: ToastType;
    variant?: "DEFAULT" | "FULL_WIDTH";
  }) => void;
}>({
  dispatch: () => {},
});

export const ModalCollectionContext =
  React.createContext<ModalTreeProviderShape>({
    modalTree: {
      [ModalNames.PROMPT_DISCARD_CHANGES]: {
        isShow: false,
      },
    },
    setModalTree(prev) {
      return prev;
    },
  });
