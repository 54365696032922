import { withRouter } from "react-router-dom";

import AccordionPane from "../../../../../../../components/ubold/organisms/AccordionPane";

import CHCheckBox from "../../../../../../../components/ubold/molecules/forms/CHCheckBox";
import CHTextField from "../../../../../../../components/ubold/molecules/forms/CHTextField";
import CHSelect from "../../../../../../../components/ubold/molecules/forms/CHSelect";
import { sectionProps } from "@types";

function Services(props: sectionProps) {
  const { baseLink, accordionId, onSubmit, state, setState } = props;

  return (
    <AccordionPane
      id="attributes"
      name="Building Attributes"
      accordionId={accordionId}
      baseLink={baseLink}
      onSubmit={onSubmit}
      state={state}
      setState={setState}
    >
      <CHCheckBox name="laundry" title="Laundry" readOnly disabled />
      <CHTextField
        name="quota_laundry"
        title="Quota Laundry"
        type="number"
        readOnly
      />
      <CHSelect
        readOnly
        name="denomination"
        title="Denomination"
        data={[
          {
            label: "kgs",
            value: 1,
          },
          {
            label: "pcs",
            value: 2,
          },
        ]}
      />
      <CHSelect
        readOnly
        name="duration_laundry"
        title="(Laundry) Quota per"
        data={[
          {
            label: "week",
            value: 2,
          },
          {
            label: "month",
            value: 3,
          },
        ]}
      />

      <CHCheckBox
        disabled
        readOnly
        name="room_cleaning"
        title="Room Cleaning"
      />
      <CHTextField
        readOnly
        name="quota_rc"
        title="Quota RC"
        type="number"
      />
      <CHSelect
        readOnly
        name="duration_rc"
        title="(Room Cleaning) Quota per"
        data={[
          {
            label: "week",
            value: 2,
          },
          {
            label: "month",
            value: 3,
          },
        ]}
      />

      <CHCheckBox
        disabled
        readOnly
        name="internet"
        title="Internet"
      />
      <CHTextField
        readOnly
        name="internet_speed"
        title="Internet Speed"
        type="number"
      />

      <CHCheckBox
        disabled
        readOnly
        name="repair_maintenance"
        title="Repair Maintenance"
      />
      <CHTextField readOnly name="sla" title="SLA" type="number" />
    </AccordionPane>
  );
}

export default withRouter(Services);
