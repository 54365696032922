import { withRouter } from "react-router-dom";

import TabPane from "../../../../../../components/ubold/organisms/TabPane";
import TabPaneSection from "../../../../../../components/ubold/organisms/TabPaneSection";

import CVTextView from "../../../../../../components/ubold/molecules/forms/CVTextView";
import CVTextField from "../../../../../../components/ubold/molecules/forms/CVTextField";
import CVSelect from "../../../../../../components/ubold/molecules/forms/CVSelect";
import CVSwitch from "../../../../../../components/ubold/molecules/forms/CVSwitch";
import CVRichTextField from "../../../../../../components/ubold/molecules/forms/CVRichTextField";

import utils from "../../../../../../utils";

function Invoice(props: any) {
  const { id, definitions, tab, state, setState } = props;

  return (
    <TabPane
      id={id}
      definitions={definitions}
      tab={tab}
      hideAddAnother
      state={state}
      setState={setState}
      readOnly={true}
    >
      <TabPaneSection multipleColumn style={{ padding: "25px" }}>
        <CVTextView title="Invoice Number" name="number" />
        <CVTextView
          title="Grand Total"
          name="grand_total"
          renderer={(value: number) => {
            return utils.formatter.currency(value);
          }}
          readOnly
        />
        <CVTextView
          title="Order Number"
          name="order_number"
          readOnly
        />
        <CVTextView
          title="Additional Number"
          name="additional_number"
          readOnly
        />
        <CVTextView
          title="Amount"
          name="total_amount"
          renderer={(value: number) => {
            return utils.formatter.currency(value);
          }}
          readOnly
        />
        <CVTextView
          title="Status"
          name="status"
          renderer={(value: number) => {
            if (value === 1) {
              return "Waiting for Payment";
            } else if (value === 2) {
              return "Proceed";
            } else if (value === 3) {
              return "Done";
            }
          }}
          readOnly
        />
        <CVTextView
          title="Started at"
          name="started_at"
          renderer={(value: string) => {
            return utils.formatter.date(value);
          }}
          readOnly
        />
        <CVTextView
          title="End at"
          name="ended_at"
          renderer={(value: string) => {
            return utils.formatter.date(value);
          }}
          readOnly
        />
        <CVTextView
          title="Invoice Type"
          name="invoice_type"
          renderer={(value: number) => {
            if (value === 1) {
              return "Monthly";
            } else if (value === 2) {
              return "One Time";
            }
          }}
          readOnly
        />
        <CVTextView
          title="Building Name"
          name="building_name"
          readOnly
        />
        <CVTextField
          title="Discount Amount"
          name="discount_amount"
          type="number"
          readOnly
        />
        <CVSelect
          title="Release Status"
          name="release_status"
          data={[
            {
              label: "Hold",
              value: 1,
            },
            {
              label: "Ready to Release",
              value: 2,
            },
            {
              label: "Release",
              value: 3,
            },
          ]}
          readOnly
        />
        <CVSwitch
          title="Paid with Extra Digit"
          name="paid_with_extra_digit"
          readOnly
        />
        <CVSwitch
          title="Laundry Status"
          name="laundry_status"
          readOnly
        />
        <CVRichTextField
          title="Discount Notes"
          name="discount_notes"
          readOnly
        />
        <CVRichTextField title="Remarks" name="remarks" readOnly />
      </TabPaneSection>
    </TabPane>
  );
}

export default withRouter(Invoice);
