import { withRouter } from "react-router-dom";

import useGlobalState from "../../../../state";

import utils from "../../../../utils";

function CVImageView(props: any) {
  const {
    formColumn = 2,
    name,
    title,
    isRequired = false,
    overrideLayout = null,
    restAccessCode,
    state,
    helperText = "",
  } = props;

  let formColumnReal = 12 / formColumn;

  const [fieldAccesses] = useGlobalState("field_accesses");

  return (
    <div
      className={
        "col-lg-" + (overrideLayout ? overrideLayout : formColumnReal)
      }
      style={{
        display:
          restAccessCode &&
          utils.access.isFieldHidden(
            fieldAccesses,
            restAccessCode + "." + name
          )
            ? "none"
            : "unset",
      }}
    >
      <div
        className="form-group mb-3"
        style={{
          display:
            !isRequired &&
            restAccessCode &&
            utils.access.isFieldHidden(
              fieldAccesses,
              restAccessCode + "." + name
            )
              ? "none"
              : "block",
        }}
      >
        <label
          htmlFor="name"
          className={
            "col-form-label" + (isRequired ? " font-weight-bold" : "")
          }
        >
          {title}
        </label>
        <div style={{ paddingTop: "7px" }}>
          {state[name] === null ||
          state[name] === undefined ||
          !utils.commons.isString(state[name]) ? (
            "---"
          ) : (
            <img
              alt="image"
              src={state[name]}
              style={{ width: "200px" }}
            />
          )}
          {helperText !== "" ? (
            <p className="text-muted font-13 pt-1 mb-0">
              {helperText}
            </p>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default withRouter(CVImageView);
