import { useState } from "react";

import { useParams, withRouter } from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import Form from "../../../../components/ubold/organisms/Form";

import definitions from "./definitions";
import CVTextField from "../../../../components/ubold/molecules/forms/CVTextField";
import CVSimpleManyToMany from "../../../../components/ubold/molecules/forms/CVSimpleManyToMany";

function CVendorViewPage() {
  let { id }: any = useParams();

  const initialState = {};

  const [state, setState] = useState(initialState);

  return (
    <Page
      title={`Detail of ${definitions.title}`}
      restAccessCode={definitions.restAccessCode}
    >
      <Form
        id={id}
        definitions={definitions}
        state={state}
        setState={setState}
        readOnly={true}
      >
        <CVTextField
          name="name"
          title="Name"
          isRequired
          maxLength={80}
          readOnly
        />
        <CVTextField
          name="phone_number"
          title="Phone Number"
          type="number"
          maxLength={20}
          readOnly
        />
        <CVTextField
          name="address"
          title="Address"
          maxLength={255}
          readOnly
        />
        <CVSimpleManyToMany
          name="buildings"
          title="Buildings"
          data="building/building?__type__=select_entries"
          itemsExtractor={(row: any) => {
            return {
              label: row.building_name,
              value: row.id,
            };
          }}
          readOnly
        />
      </Form>
    </Page>
  );
}

export default withRouter(CVendorViewPage);
