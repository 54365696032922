import { useState } from "react";

import { useParams, withRouter } from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import TabHeaders from "../../../../components/ubold/organisms/TabHeaders";
import TabContent from "../../../../components/ubold/organisms/TabContent";
import TabbedForm from "../../../../components/ubold/organisms/TabbedForm";

import Order from "./Tabs/Order";
import OrderAdditionalDiscounts from "./Tabs/OrderAdditionalDiscounts";
import OrderAddon from "./Tabs/OrderAddon";
/** Hide this as requested on RTD-4553 */
// import OrderServiceSchedules from "./Tabs/OrderServiceSchedules";
import OrderUpdatePrice from "./Tabs/OrderUpdatePrice";

import definitions from "./definitions";

function OrderViewPage() {
  let { id }: any = useParams();

  const initialState = {
    payment_plan_discount: 0,
    price: 0,
    discount_amount: 0,
    deposit: 0,
    status_of_done: 0,
    tenant_category: 1,
    status: 1,
  };

  const [state, setState] = useState(initialState);

  return (
    <Page
      title={
        (id === undefined ? "Add New" : "Edit") +
        " " +
        definitions.title
      }
      restAccessCode={definitions.restAccessCode}
    >
      <TabbedForm
        id={id}
        state={state}
        setState={setState}
        definitions={definitions}
      >
        <TabHeaders />
        <TabContent>
          <Order />
          <OrderAdditionalDiscounts />
          <OrderAddon />
          {/*
           Hide this as requested on RTD-4553 
            <OrderServiceSchedules /> 
          */}
          <OrderUpdatePrice />
        </TabContent>
      </TabbedForm>
    </Page>
  );
}

export default withRouter(OrderViewPage);
