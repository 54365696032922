import configBuilder from "../../../components/ubold/ConfigBuilder";
import utils from "../../../utils";

let definitions = {
  title: "Agreements",
  frontendPath: "/building/agreement/building_agreement",
  baseLink: "/building/building__agreement",
  restAccessCode: "building.building_agreement",
  __table__: {
    filterColumn: 3,
    removeIdentifier: "building_name",
    hideEditButton: true,
    filters: [
      {
        id: "building",
        name: "Building",
        type: "async_select",
        data: "building/building?__type__=select_entries",
        itemsExtractor: (value) => {
          return {
            label: value.building_name,
            value: value.id,
          };
        },
      },
      {
        id: "building_live_date",
        name: "Live Date",
        type: "date",
      },
      {
        id: "building_effective_date",
        name: "Effective Date",
        type: "date",
      },
    ],
    columns: [
      {
        id: "building_name",
        name: "Building",
        enableHyperlink: true,
      },
      {
        id: "building_live_date",
        name: "Building Live Date",
        renderer: (value) => {
          return utils.formatter.date(value);
        },
      },
      {
        id: "building_effective_date",
        name: "Building Effective Date",
        renderer: (value) => {
          return utils.formatter.date(value);
        },
      },
      {
        id: "tiring_escalation",
        name: "Tiring Escalation",
        canDoOrdering: false,
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
