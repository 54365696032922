import configBuilder from "../../../components/ubold/ConfigBuilder";

let definitions = {
  title: "Bank Accounts",
  frontendPath: "/admin/finance/bank_account",
  baseLink: "/finance/bank_account",
  restAccessCode: "finance.bank_account",
  __table__: {
    hideEditButton: true,
    removeIdentifier: (value) => {
      return value.bank_name + " - " + value.bank_number;
    },
    filters: [
      {
        id: "bank_number",
        name: "Bank Number",
        type: "number",
      },
      {
        id: "bank_name",
        name: "Bank Name",
        type: "text",
      },
    ],
    columns: [
      {
        id: "bank_name",
        name: "Nama Bank",
        enableHyperlink: true,
      },
      {
        id: "bank_logo",
        name: "Bank Logo",
        isSafeContent: true,
        canDoOrdering: false,
        center: true,
        renderer: (value) => {
          if (value && value !== "-" && value !== "") {
            return (
              "<img alt='image' src='" + value + "' width='50px' />"
            );
          } else {
            return value;
          }
        },
      },
      {
        id: "bank_number",
        name: "Bank Number",
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
