import { useState } from "react";
import { useParams, withRouter } from "react-router-dom";

import Page from "components/ubold/organisms/Page";
import Form from "components/ubold/organisms/Form";
import CVTextField from "components/ubold/molecules/forms/CVTextField";
import CVDateTimePicker from "components/ubold/molecules/forms/CVDateTimePicker";
import CVCheckBox from "components/ubold/molecules/forms/CVCheckBox";
import CVSelect from "components/ubold/molecules/forms/CVSelect";
import CVSimpleManyToMany from "components/ubold/molecules/forms/CVSimpleManyToMany";

import definitions from "./definitions";
import CVTextView from "components/ubold/molecules/forms/CVTextView";
import CVTextArea from "components/ubold/molecules/forms/CVTextArea";

function DiscountMasterViewPage() {
  let { id }: { id: string } = useParams();
  const initialState: any = {
    category: 1,
    tenant_category: 1,
    tenant_type: 1,
    all_building: true,
    budget_period: 1,
    budget_allocation: 0,
    budget_spent: 0,
    minimum_monthly_commitment: 0,
    one_time: false,
    show: false,
    adjustment_order: false,
  };
  const [state, setState] = useState(initialState);

  return (
    <Page
      title={
        (id === undefined ? "Add New" : "Edit") +
        " " +
        definitions.title
      }
      restAccessCode={definitions.restAccessCode}
    >
      <Form
        id={id}
        definitions={definitions}
        state={state}
        setState={setState}
        readOnly
      >
        <CVTextField name="name" title="Name" isRequired readOnly />
        <CVDateTimePicker
          name="start_date"
          title="Start Date"
          type="date"
          isRequired
          readOnly
        />
        <CVDateTimePicker
          name="end_date"
          title="End Date"
          type="date"
          isRequired
          readOnly
        />
        <CVCheckBox name="active" title="Active" readOnly />
        <CVSelect
          name="category"
          title="Category"
          data={[
            { label: "Discount", value: 1 },
            { label: "Promo", value: 2 },
          ]}
          isRequired
          readOnly
        />
        <CVTextField
          name="percentage"
          title="Percentage"
          type="number"
          isRequired
          readOnly
        />
        <CVTextField
          name="maximum_amount"
          title="Maximum Amount"
          type="number"
          isRequired
          readOnly
          useCurrencyFormatHelper
        />
        <CVSelect
          name="tenant_category"
          title="Tenant Category"
          data={[
            { label: "All", value: 1 },
            { label: "Rukita Tenant", value: 2 },
            { label: "Legacy Tenant", value: 3 },
            { label: "Rukita Staff - Pay", value: 4 },
          ]}
          isRequired
          readOnly
        />
        <CVSelect
          name="tenant_type"
          title="Tenant Type"
          data={[
            { label: "All", value: 1 },
            { label: "New Tenant", value: 2 },
            { label: "Existing Tenant", value: 3 },
          ]}
          isRequired
          readOnly
        />
        <CVSimpleManyToMany
          name="building_eligibility"
          title="Building Eligibility"
          data="building/building?__type__=select_entries&limit=999"
          itemsExtractor={(row: {
            id: string;
            building_name: string;
          }) => {
            return {
              label: row.building_name,
              value: row.id,
            };
          }}
          readOnly
        />
        <CVCheckBox
          name="all_building"
          title="All Building"
          readOnly
        />
        <CVTextField
          name="budget_allocation"
          title="Budget Allocation"
          type="number"
          isRequired
          min={0}
          readOnly
          useCurrencyFormatHelper
        />
        <CVTextView
          name="budget_spent"
          title="Budget Spent"
          readOnly
        />
        <CVSelect
          name="budget_period"
          title="Budget Period"
          data={[
            { label: "All", value: 1 },
            { label: "Monthly", value: 2 },
          ]}
          isRequired
          min={0}
          readOnly
        />
        <CVCheckBox
          name="adjustment_order"
          title="Adjustment Order"
          readOnly
        />
        <CVCheckBox name="one_time" title="One-Time" readOnly />
        <CVCheckBox name="show" title="Show" readOnly />
        <CVTextField
          name="minimum_monthly_commitment"
          title="Minimum Monthly Commitment"
          type="number"
          readOnly
        />
        {state.adjustment_order ? (
          <CVTextArea
            name="adjustment_remarks"
            title="Adjust Remarks"
            readOnly={id}
          />
        ) : (
          <></>
        )}
        <CVTextView
          name="date_created"
          title="Date Created"
          readOnly
        />
      </Form>
    </Page>
  );
}

export default withRouter(DiscountMasterViewPage);
