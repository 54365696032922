import utils from "./index";

const access = {
  doAccessProtectionInSecondaryData: (
    restAccessCode,
    accesses,
    data
  ) => {
    for (const [key] of Object.entries(data)) {
      let fieldAccess = restAccessCode + ".table." + key;

      for (let i = 0; i < accesses.length; i++) {
        if (
          accesses[i]["field_access__code"] === fieldAccess ||
          accesses[i]["code"] === fieldAccess
        ) {
          if (
            accesses[i]["access"] !== undefined &&
            accesses[i]["access"] === 1
          ) {
            data[key] = "-hidden-";
          }
        }
      }
    }

    return data;
  },
  isFieldHidden: (accesses, fieldAccess) => {
    let isFieldHidden = false;

    if (
      fieldAccess !== null &&
      fieldAccess !== undefined &&
      utils.commons.isArray(accesses)
    ) {
      for (let i = 0; i < accesses.length; i++) {
        if (
          accesses[i]["field_access__code"] === fieldAccess ||
          accesses[i]["code"] === fieldAccess
        ) {
          if (
            accesses[i]["access"] !== undefined &&
            accesses[i]["access"] === 1
          ) {
            isFieldHidden = true;

            break;
          }
        }
      }
    }

    return isFieldHidden;
  },
  isUserHasAcess: (accesses, fieldAccess) => {
    // access value 2 means Read & Write
    return accesses?.some(
      (access) => access?.code === fieldAccess && access?.access === 2
    );
  },
  isFieldReadOnly: (
    accesses,
    fieldAccess,
    reversePolicyAccess,
    isSuperUser
  ) => {
    if (
      typeof fieldAccess !== "string" ||
      !Array.isArray(accesses) ||
      !Array.isArray(reversePolicyAccess)
    ) {
      console.error(
        "fieldAccess should be a string, accesses should be an array and reversePolicyAccess should be an array."
      );
      return false;
    }
    // check if the field is set to reverse policy
    const isReversePolicy = reversePolicyAccess.some((access) => {
      if (isSuperUser) return false;
      return access.code === fieldAccess && access.is_reverse_policy;
    });
    // check if the user have the access in the access array that match with reverse access policy
    const isUserHaveAccess = utils.access.isUserHasAcess(
      accesses,
      fieldAccess
    );

    // If the field is set to reverse policy and the user have the access in the access array that match with reverse access policy return false(editable) otherwise return true(readonly)
    return isReversePolicy && !isUserHaveAccess;
  },
};

export default access;
