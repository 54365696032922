import styled from "styled-components";

export const ButtonLoadMore = styled.button`
  width: 100%;

  background-color: #ffffff;
  border: unset;
  border-radius: 4px 4px 0px 0px;
  color: #35b0a7;
  font-size: 14px;
  font-weight: 500;
  padding-top: 12px;
  padding-bottom: 12px;

  &:disabled {
    color: #999999;
    font-weight: 400;
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TableHistoryBase = styled.table`
  width: 100%;

  border-collapse: separate;
  border-spacing: 0 2px;

  tbody,
  thead {
    color: #222222;
  }

  thead {
    background-color: #d9efe5;

    th {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      padding: 12.5px 8px;

      &:first-child {
        border-top-left-radius: 4px;
        padding: 12.5px 8px 12.5px 16px;
      }

      &:last-child {
        border-top-right-radius: 4px;
        padding: 12.5px 16px 12.5px 8px;
      }
    }
  }

  tbody {
    vertical-align: top;

    tr {
      background-color: #fff;

      td {
        font-weight: 300;
        font-size: 14px;
        line-height: 20px;
        padding: 12.5px 8px;

        &:first-child {
          padding: 12.5px 8px 12.5px 16px;
        }

        &:last-child {
          padding: 12.5px 16px 12.5px 8px;
        }

        &:nth-child(1) {
          min-width: 150px;
        }

        &:nth-child(2) {
          min-width: 100px;
        }

        &:nth-child(3) {
          min-width: 100px;
        }
      }
    }
  }

  ul {
    margin-block-start: unset;
    margin-block-end: unset;
    padding-inline-start: 20px;
  }
`;
