import configBuilder from "../../../components/ubold/ConfigBuilder";

let definitions = {
  title: "Enabled Payments",
  frontendPath: "/admin/configuration/enabled_payment",
  baseLink: "/payment_gateway/enabled_payment",
  restAccessCode: "payment_gateway.enabled_payment",
  __table__: {
    hideEditButton: true,
    ignoreOrdering: true,
    filterColumn: 3,
    filters: [
      {
        id: "name",
        name: "Name",
        type: "text",
      },
      {
        id: "code",
        name: "Code",
        type: "text",
      },
      {
        id: "status",
        name: "Status",
        type: "select",
        data: [
          {
            label: "PUBLISH",
            value: 1,
          },
          {
            label: "UNPUBLISH",
            value: 0,
          },
        ],
      },
    ],
    columns: [
      {
        id: "name",
        name: "Name",
        enableHyperlink: true,
      },
      {
        id: "code",
        name: "Code",
      },
      {
        id: "status",
        name: "Status",
        renderer: (value) => {
          if (parseInt(value) === 1) {
            return "PUBLISH";
          } else if (parseInt(value) === 0) {
            return "UNPUBLISH";
          } else {
            return "-";
          }
        },
      },
      {
        id: "sequence",
        name: "Sequence",
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
