import utils from "../../../../utils";

import configBuilder from '../../../../components/ubold/ConfigBuilder';

import pageDefinitions from "../definitions";

const definitions = {...pageDefinitions,
    tabs: [
        {
            id: 'role',
            name: 'Role'
        },
        {
            id: 't_accesses',
            name: 'Access List'
        },
        {
            id: 't_access_fields',
            name: 'Field Access List'
        },
        {
            id: 't_users',
            name: 'Users'
        },
    ],
    validators: {
        name: (value) => {
            return utils.validator.validateRequired(value);
        },
    },
}

export default configBuilder.buildAddEditPageConfig(definitions);