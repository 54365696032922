import {useParams, withRouter} from "react-router-dom";

import TabPaneSecondary from "../../../../../../components/ubold/organisms/TabPaneSecondary";

import COneOrManyToManyField from "../../../../../../components/ubold/molecules/forms/COneOrManyToManyField";

function Photo(props) {
    const {definitions, tab, state, setState} = props;

    let {id} = useParams();

    return (
        <TabPaneSecondary
            definitions={definitions}
            restAccessCodeNamespace='photo'
            tab={tab}
            state={state}
            setState={setState}>
            <COneOrManyToManyField
                id={id}
                name={tab.id}
                restPath='building/place_group_photo/'
                relationKey='place_group_id'
                photoFields={['photo_original']}
                columns={[
                    {
                        name: 'name',
                        title: 'Name',
                        type: 'text',
                        maxLength: 30,
                    },
                    {
                        name: 'photo_original',
                        title: 'Photo',
                        type: 'photo_upload',
                        isRequired: true
                    }
                ]}
            />
        </TabPaneSecondary>
    );
}

export default withRouter(Photo);