import {withRouter} from "react-router-dom";

function CSwitch(props) {
    const {name, state, setState, readOnly=false} = props;

    return (
        <div className="custom-control custom-switch">
            <input
                type="checkbox"
                id={name}
                name={name}
                value={state[name]}
                checked={state[name]}
                disabled={readOnly}
                className="custom-control-input"
                onChange={() => {
                    setState(prevState => ({ ...prevState, [name]: !state[name] }));
                }}/>
            <label htmlFor={name} className="custom-control-label">&nbsp;</label>
        </div>
    );
}

export default withRouter(CSwitch);