import utils from "../../../../utils";

import configBuilder from "../../../../components/ubold/ConfigBuilder";

import pageDefinitions from "../definitions";

const definitions = {
  ...pageDefinitions,
  tabs: [
    {
      id: "order",
      name: "Order",
    },
    {
      id: "t_additional_discounts",
      name: "Additional Discounts",
      alwaysEditable: true,
    },
    {
      id: "t_addon_buildings",
      name: "Addon Buildings",
      alwaysEditable: true,
    },
    {
      id: "t_service_schedules",
      name: "Service Schedules",
      alwaysEditable: true,
    },
  ],
  validators: {
    tenant: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    building: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    room_variant: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    room: (value: string, state: any) => {
      const roomValue = utils.validator.validateRequired(value);

      if (
        state["building_label"] &&
        state["building_label"].indexOf("Centralized") >= 0 &&
        roomValue !== true
      ) {
        return "Room is required if building is Centralized";
      } else {
        return true;
      }
    },
    payment_plan: (value: number) => {
      return utils.validator.validateRequired(value);
    },
    discount_amount: (value: number) => {
      return utils.validator.validateRequired(value);
    },
    tenant_category: (value: number) => {
      return utils.validator.validateRequired(value);
    },
    status: (value: number) => {
      return utils.validator.validateRequired(value);
    },
    date_started: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    reason_of_check_out: (value: string, state: any) => {
      if (state["date_checkout"]) {
        return utils.validator.validateRequired(value);
      }

      return true;
    },
    checkout_category: (value: number, state: any) => {
      if (state["date_checkout"]) {
        return utils.validator.validateRequired(value);
      }

      return true;
    },
    checkout_reason: (value: string, state: any) => {
      if (state["date_checkout"]) {
        return utils.validator.validateRequired(value);
      }

      return true;
    },
    status_of_done: (value: number) => {
      return utils.validator.validateRequired(value);
    },
    deposit: (value: string) => {
      if (parseInt(value) < 0) {
        return "Deposit should be greater than 0";
      } else {
        return true;
      }
    },
  },
};

export default configBuilder.buildAddEditPageConfig(
  definitions,
  [],
  ["created_by", "date_created", "date_updated"]
);
