import { withRouter } from "react-router-dom";

import TabPane from "../../../../../../components/ubold/organisms/TabPane";
import TabPaneSection from "../../../../../../components/ubold/organisms/TabPaneSection";

import CHTextField from "../../../../../../components/ubold/molecules/forms/CHTextField";
import CHSelect from "../../../../../../components/ubold/molecules/forms/CHSelect";
import CHImageUpload from "../../../../../../components/ubold/molecules/forms/CHImageUpload";

function HomepageCollectionTab(props: any) {
  const { id, definitions, tab, state, setState } = props;

  return (
    <TabPane
      id={id}
      definitions={definitions}
      tab={tab}
      state={state}
      setState={setState}
    >
      <TabPaneSection style={{ padding: "25px 16px 0px" }}>
        <CHTextField
          name="title_id"
          title="Homepage Collection Title (ID)"
          isRequired
          maxLength={15}
        />
        <CHTextField
          name="title_en"
          title="Homepage Collection Title (EN)"
          isRequired
          maxLength={15}
        />
        <CHImageUpload
          name="icon"
          title="Upload Icon (24 x 24px)"
          alertAllowedExtension=".svg"
          helperText="input file with these extension: .svg"
          isRequired
          allowedImageExtension={["svg+xml"]}
        />
        <CHSelect
          name="published"
          title="Published Flag"
          data={[
            {
              label: "Yes",
              value: true,
            },
            {
              label: "No",
              value: false,
            },
          ]}
        />
        <CHTextField
          name="url"
          title="URL"
          helperText="start with http:// or https:// (case sensitive) and contain one of these: rukita.co/collections, rukita.co/location, rukita.co/search, rukita.co/tipe"
          isRequired
        />
        <CHTextField
          name="order"
          title="Order"
          type="number"
          isRequired
          min={0}
        />
      </TabPaneSection>
    </TabPane>
  );
}

export default withRouter(HomepageCollectionTab);
