import configBuilder from "../../../components/ubold/ConfigBuilder";

let definitions = {
  title: "Landlords",
  frontendPath: "/landlord/landlord",
  baseLink: "/landlord/user_landlord",
  restAccessCode: "landlord.user_landlord",
  __table__: {
    filterColumn: 4,
    removeIdentifier: "landlord_name",
    hideEditButton: true,
    filters: [
      {
        id: "landlord",
        name: "Landlord",
        type: "async_select",
        data: "building/landlord?__type__=select_entries",
      },
      {
        id: "username",
        name: "Username",
        type: "text",
      },
      {
        id: "email",
        name: "Email",
        type: "text",
      },
      {
        id: "is_active",
        name: "Is Active?",
        type: "select",
        data: [
          {
            label: "Yes",
            value: true,
          },
          {
            label: "No",
            value: false,
          },
        ],
      },
    ],
    columns: [
      {
        id: "landlord_name",
        name: "Landlord",
        enableHyperlink: true,
      },
      {
        id: "username",
        name: "Username",
      },
      {
        id: "email",
        name: "Email",
      },
      {
        id: "is_active",
        name: "Is Active?",
        isSafeContent: true,
        center: true,
        renderer: (value) => {
          if (value === "true") {
            return '<span style="color: rgb(26, 188, 156);"> ✔ </span>';
          } else {
            return '<span style="color: red;">✘</span>';
          }
        },
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
