import axios from "axios";
import moment from "moment";

import store from "./Store";
import config from "../configs";
import utils from "./index";

let triedAttempt = 0;

export const handleRefreshToken = (storeParameter) => {
  return new Promise((resolve, reject) => {
    const refreshToken = store.get(config.r_key);
    if (refreshToken && triedAttempt < 3) {
      triedAttempt += 1;
      axios
        .post(`${config.apiUrl}refresh_token/`, {
          refresh: refreshToken,
        })
        .then(({ data }) => {
          storeParameter.set(config.t_key, data.access);
          storeParameter.set(config.r_key, data.refresh);
          triedAttempt = 0;
          resolve({
            access: data.access,
            refresh: data.refresh,
          });
        })
        .catch((err) => {
          store.remove(config.t_key);
          store.remove(config.r_key);
          if (window.location.pathname !== "/login") {
            window.location.href = "/login";
          }
          reject(err);
        });
    } else {
      store.remove(config.t_key);
      store.remove(config.r_key);
      if (window.location.pathname !== "/login") {
        window.location.href = "/login";
      }
    }
  });
};

axios.interceptors.response.use(null, (error) => {
  if (error.config && error.response.status === 401) {
    handleRefreshToken(store).then(({ access: newAccessToken }) => {
      const newConfig = {
        ...error.config,
        headers: {
          Authorization: "Bearer " + newAccessToken,
        },
      };
      return axios.request(newConfig);
    });
  }
  return Promise.reject(error);
});

const httpClient = {
  get: (
    url,
    successCallback,
    errorCallback,
    isByPassUnAuthorized = false,
    cacheKey = null,
    cacheExpired = 30
  ) => {
    const format = "YYYY-MM-DD HH:mm:ss";

    if (cacheKey) {
      const cache = utils.store.get(cacheKey);

      if (cache) {
        const content = JSON.parse(
          cache.substr(0, cache.length - 19)
        );
        const timestamp = moment(
          cache.substr(cache.length - 19),
          format
        );

        const difference = moment().diff(timestamp, "seconds");

        if (difference <= 60 * cacheExpired) {
          successCallback(content.data, content);

          return true;
        }
      }
    }

    if (url.indexOf("?") > -1) {
      url = url.replace("?", "/?").replace(/([^:])(\/{2,})/g, "$1/");
    } else {
      url = (url + "/").replace(/([^:])(\/{2,})/g, "$1/");
    }

    axios
      .get(url, httpClient._getConfig())
      .then((response) => {
        if (cacheKey) {
          utils.store.set(
            cacheKey,
            JSON.stringify(response) + moment().format(format)
          );
        }

        successCallback(response.data, response);
      })
      .catch((exception) => {
        httpClient._unauthorized_handler(
          exception,
          errorCallback,
          isByPassUnAuthorized
        );
      });
  },
  post: (
    url,
    payload,
    successCallback,
    errorCallback,
    isByPassUnAuthorized = false
  ) => {
    axios
      .post(url, payload, httpClient._getConfig())
      .then((response) => {
        successCallback(response.data, response);
      })
      .catch((exception) => {
        httpClient._unauthorized_handler(
          exception,
          errorCallback,
          isByPassUnAuthorized
        );
      });
  },
  postMultiPart: (
    url,
    payload,
    contentType,
    successCallback,
    errorCallback,
    isByPassUnAuthorized = false
  ) => {
    axios
      .post(
        url,
        payload,
        httpClient._multiPartConfig({}, contentType)
      )
      .then((response) => {
        successCallback(response.data, response);
      })
      .catch((exception) => {
        httpClient._unauthorized_handler(
          exception,
          errorCallback,
          isByPassUnAuthorized
        );
      });
  },
  put: (
    url,
    payload,
    successCallback,
    errorCallback,
    isByPassUnAuthorized = false
  ) => {
    axios
      .put(url, payload, httpClient._getConfig())
      .then((response) => {
        successCallback(response.data, response);
      })
      .catch((exception) => {
        httpClient._unauthorized_handler(
          exception,
          errorCallback,
          isByPassUnAuthorized
        );
      });
  },
  patch: (
    url,
    payload,
    successCallback,
    errorCallback,
    isByPassUnAuthorized = false
  ) => {
    axios
      .patch(url, payload, httpClient._getConfig())
      .then((response) => {
        successCallback(response.data, response);
      })
      .catch((exception) => {
        httpClient._unauthorized_handler(
          exception,
          errorCallback,
          isByPassUnAuthorized
        );
      });
  },
  patchMultipart: (
    url,
    payload,
    contentType,
    successCallback,
    errorCallback,
    isByPassUnAuthorized = false
  ) => {
    axios
      .patch(
        url,
        payload,
        httpClient._multiPartConfig({}, contentType)
      )
      .then((response) => {
        successCallback(response.data, response);
      })
      .catch((exception) => {
        httpClient._unauthorized_handler(
          exception,
          errorCallback,
          isByPassUnAuthorized
        );
      });
  },
  delete: (
    url,
    data,
    successCallback,
    errorCallback = [],
    isByPassUnAuthorized = false
  ) => {
    axios
      .delete(
        url,
        httpClient._getConfig({
          data: data,
        })
      )
      .then((response) => {
        successCallback(response.data, response);
      })
      .catch((exception) => {
        httpClient._unauthorized_handler(
          exception,
          errorCallback,
          isByPassUnAuthorized
        );
      });
  },
  _getConfig: (additionalConfigs = {}) => {
    const t = store.get(config.t_key);
    if (t !== null) {
      return {
        ...additionalConfigs,
        headers: {
          Authorization: "Bearer " + t,
        },
      };
    } else {
      return {};
    }
  },
  _multiPartConfig: (additionalConfigs = {}, contentType) => {
    const t = store.get(config.t_key);
    if (t !== null) {
      return {
        ...additionalConfigs,
        headers: {
          Authorization: "Bearer " + t,
          "Content-Type": contentType,
        },
      };
    } else {
      return {};
    }
  },
  _unauthorized_handler: (
    exception,
    errorCallback
    // isByPassUnAuthorized
  ) => {
    console.error(exception);
    errorCallback?.(exception, httpClient.getErrorMessage(exception));

    // deprecated by 15 june 2022
    // if (
    //   exception.response &&
    //   exception.response.status === 401 &&
    //   !isByPassUnAuthorized
    // ) {
    //   store.remove(config.t_key);
    //   window.location.href = "/login";
    // } else {
    // errorCallback(exception, httpClient.getErrorMessage(exception));
    // }
  },
  getErrorMessage: (error) => {
    let errors = [];

    if (
      error.response &&
      error.response.data &&
      error.response.data.message
    ) {
      if (utils.commons.isObject(error.response.data.message)) {
        for (let keyItem in error.response.data.message) {
          if (
            utils.commons.isArray(
              error.response.data.message[keyItem]
            )
          ) {
            for (
              let i = 0;
              i < error.response.data.message[keyItem].length;
              i++
            ) {
              errors.push(error.response.data.message[keyItem][i]);
            }
          } else if (
            utils.commons.isString(
              error.response.data.message[keyItem]
            )
          ) {
            errors.push(error.response.data.message[keyItem]);
          }
        }
      } else if (utils.commons.isArray(error.response.data.message)) {
        for (let i = 0; i < error.response.data.message.length; i++) {
          errors.push(error.response.data.message[i]);
        }
      } else {
        errors.push(error.response.data.message);
      }
    } else if (
      error.response &&
      error.response.data &&
      utils.commons.isJSON(error.response.data)
    ) {
      errors.push(JSON.stringify(error.response.data));
    } else {
      if (error.message) {
        errors.push(error.message);
      }
    }

    return errors.join("\n");
  },
};

export default httpClient;
