import { useCallback, useEffect, useState } from "react";

import { withRouter } from "react-router-dom";

import DualListBox from "react-dual-listbox";

import "react-dual-listbox/lib/react-dual-listbox.css";

import utils from "../../../../utils";
import configs from "../../../../configs";

function CDualistBoxV2(props) {
  const {
    name,
    state,
    setState,
    data,
    onChange,
    readOnly = false,
    ignoreDependsOnParam,
    dependsOn,
    dependsOnRelationKey,
    dependsOnHandler,
    itemsExtractor,
    dataID,
    arrDataIdList,
    apiUrl,
    hitApi = true,
    sortByField = null,
    sortType = "ascending",
  } = props;

  const [options, setOptions] = useState([]);
  const [inputFilter, setInputFilter] = useState(null);
  const [selected, setSelected] = useState([]);

  // Define an asynchronous function to load options from the API
  const load = async (dependsOn, callback, keyword) => {
    if (!hitApi) return;
    // If the data parameter is an array, set the options and return early
    if (utils.commons.isArray(data)) {
      setOptions(data);
      return;
    }
    // If the data parameter is a string, build the API URL
    else if (utils.commons.isString(data)) {
      // Define the base URL
      const baseUrl = configs.apiUrl;
      // Define the query string for the dependsOn parameter
      const dependsOnQuery =
        dependsOn && !ignoreDependsOnParam
          ? `&${
              dependsOnRelationKey
                ? dependsOnRelationKey
                : `${dependsOn}`
            }_id=${state[dependsOn]}`
          : "";
      // Define the query string for the keyword parameter
      const keywordQuery = keyword ? `&keyword=${keyword}` : "";
      // Combine the URL components into the final API URL

      const url = `${baseUrl}${data}${dependsOnQuery}${keywordQuery}`;

      try {
        // Make the API call using a Promise
        const response = await new Promise((resolve, reject) => {
          utils.httpClient.get(url, resolve, reject);
        });
        let resOptions = [];
        // Extract the rows from the response data
        const {
          data: { rows },
        } = response;

        // Map the rows to an array of options
        resOptions = rows.map((row, i) => {
          if (itemsExtractor === undefined) {
            return { label: row.name, value: row.id };
          } else {
            return itemsExtractor(row, i);
          }
        });

        if (!dependsOn) {
          // Combine the existing options with the new options and remove duplicates
          const uniqueOptions = new Map(
            [...options, ...resOptions].map((option) => [
              option.value,
              option,
            ])
          );
          resOptions = [...uniqueOptions.values()];
        }

        /**
         * if props dependsOn is truthy, response options will be used to set options
         * if not, it will merge previous options and remove the duplicate options
         */

        if (sortByField) {
          resOptions.sort((a, b) => {
            if (sortType === "ascending") {
              return a[sortByField] - b[sortByField];
            }
            return b[sortByField] - a[sortByField];
          });
        }

        setOptions(resOptions);
        // Call the callback function with the unique options
        if (utils.commons.isFunction(callback)) {
          callback(resOptions);
        }
      } catch (error) {
        // Log any errors to the console
        console.error(error.message);
      }
    }
  };

  const getDetailById = (selectedID) => {
    const isSelectedIdExistInOptions = options.some(
      (option) => option.value === selectedID
    );
    if (isSelectedIdExistInOptions) return;
    const baseUrl = configs.apiUrl;
    const selectedIDQuery =
      dataID && selectedID ? `${selectedID}` : "";
    const url = `${baseUrl}${apiUrl}${selectedIDQuery}?__type__=select_entries`;

    utils.httpClient.get(
      url,
      (data) => {
        const resOption = { label: data.name, value: data.id };
        setOptions((prevOptions) => [...prevOptions, resOption]);
      },
      (error, message) => {
        console.error(message);
      }
    );
  };

  const generateSelectedId = (selectedID) => {
    for (let i = 0; i < selectedID.length; i++) {
      getDetailById(selectedID[i]);
    }
  };

  const handleOnChange = useCallback(
    (selected) => {
      setState((prevState) => ({
        ...prevState,
        [name]: selected || [],
      }));
    },
    [name]
  );

  const handleFilterCallback = (option, filterInput) => {
    setInputFilter(filterInput);
    if (!filterInput) return true;
    return option.label
      ?.toLowerCase()
      ?.includes(filterInput?.toLowerCase());
  };

  useEffect(() => {
    if (!dependsOn) {
      load(null);
    }
  }, []);

  useEffect(() => {
    if (!dataID || !apiUrl) return;

    generateSelectedId(arrDataIdList);
  }, [dataID, arrDataIdList, apiUrl]);

  useEffect(() => {
    if (typeof inputFilter !== "string" || inputFilter === "") return;
    const timer = setTimeout(
      () => load(dependsOn, null, inputFilter),
      500
    );
    return () => {
      clearTimeout(timer);
    };
  }, [inputFilter]);

  useEffect(() => {
    // Check if dependsOn is defined and the data is a string
    if (dependsOn && typeof data === "string") {
      // Check if the dependsOn state value is defined and not null
      if (
        state[dependsOn] !== undefined &&
        state[dependsOn] !== null
      ) {
        // Check if there is a dependsOnHandler function defined, if so call it, otherwise call the load function
        if (typeof dependsOnHandler === "function") {
          dependsOnHandler(data);
        } else {
          load(dependsOn);
        }
      } else if (state[dependsOn] === null) {
        // Check if dependsOn state value is null
        if (onChange) {
          // Call onChange function with null
          onChange(null);
        } else {
          // Update the state with an empty array for the current name property
          setState((prevState) => ({ ...prevState, [name]: [] }));
        }
      }
    }
  }, [
    state[dependsOn],
    dependsOn,
    data,
    dependsOnHandler,
    name,
    onChange,
  ]);

  useEffect(() => {
    if (!utils.commons.isArray(state[name])) return;
    const tempSelected = [];
    for (let i = 0; i < state[name].length; i++) {
      tempSelected.push(state[name][i]);
    }
    setSelected([...new Set(tempSelected)]);
  }, [state[name]]);

  return (
    <DualListBox
      name={name}
      id={"dualist"}
      canFilter
      selected={selected}
      options={options}
      disabled={readOnly}
      filterCallback={handleFilterCallback}
      onChange={onChange || handleOnChange}
    />
  );
}

export default withRouter(CDualistBoxV2);
