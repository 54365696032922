import { withRouter } from "react-router-dom";

import CAsyncSelect from "../../atoms/forms/CAsyncSelect";

import useGlobalState from "../../../../state";

import utils from "../../../../utils";
import CSelectV3 from "components/ubold/atoms/forms/CSelectV3";

function CHSelect(props) {
  const {
    name,
    read_only_label = null,
    title,
    isRequired = false,
    restAccessCode,
    afterChange,
    state,
    readOnly = false,
    setState,
    data,
    helperText,
    dependsOn,
    dependsOnHandler,
    itemsExtractor,
    isClearable,
    // v3 props
    options,
    handleOnChange,
    handleOnInputChange,
    value,
    inputValue,
    isSearchable,
    isLoading,
    isUsingV3,
    isOptionValid,
  } = props;

  const [user] = useGlobalState("user");
  const isSuperUser = user ? user.is_super_user : false;
  const [fieldAccesses] = useGlobalState("field_accesses");
  const [fieldAccessReversePolicy] = useGlobalState(
    "field_access_reverse_policy"
  );

  const isReadOnly = () => {
    return (
      readOnly ??
      (restAccessCode &&
        utils.access.isFieldReadOnly(
          fieldAccesses,
          restAccessCode + "." + name,
          fieldAccessReversePolicy,
          isSuperUser
        ))
    );
  };

  const isFieldHidden = () => {
    return (
      !isRequired &&
      restAccessCode &&
      utils.access.isFieldHidden(
        fieldAccesses,
        restAccessCode + "." + name,
        fieldAccessReversePolicy,
        isSuperUser
      )
    );
  };

  return (
    <div
      className="form-group row mb-3"
      style={{
        display: isFieldHidden() ? "none" : "flex",
      }}
    >
      <label
        htmlFor="building_type"
        className={
          "col-md-3 col-form-label" +
          (isRequired ? " font-weight-bold" : "") +
          (state[name + "Error"] ? " text-danger" : "")
        }
      >
        {title}
        {isRequired ? "*" : ""} {isReadOnly() ? "(read only)" : ""}
      </label>
      <div className="col-md-9">
        {isUsingV3 ? (
          <CSelectV3
            options={options}
            handleOnChange={handleOnChange}
            handleOnInputChange={handleOnInputChange}
            readOnly={isReadOnly()}
            value={value}
            inputValue={inputValue}
            isSearchable={isSearchable}
            isLoading={isLoading}
          />
        ) : (
          <CAsyncSelect
            name={name}
            read_only_label={read_only_label}
            readOnly={isReadOnly()}
            state={state}
            setState={setState}
            data={data}
            afterChange={afterChange}
            dependsOn={dependsOn}
            dependsOnHandler={dependsOnHandler}
            itemsExtractor={itemsExtractor}
            isClearable={isClearable}
            isOptionValid={isOptionValid}
          />
        )}

        {helperText !== "" && helperText !== undefined ? (
          <span className="text-muted font-13 pt-1 mb-0">
            {helperText}
          </span>
        ) : (
          ""
        )}
        {helperText !== "" &&
        helperText !== undefined &&
        state[name + "Error"] ? (
          <br />
        ) : (
          <></>
        )}
        {state[name + "Error"] ? (
          <span className="text-danger" style={{ fontSize: "12px" }}>
            {state[name + "Error"]}
          </span>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default withRouter(CHSelect);
