import utils from "../../../../utils";

import configBuilder from "../../../../components/ubold/ConfigBuilder";

import pageDefinitions from "../definitions";

const definitions = {
  ...pageDefinitions,
  validators: {
    landlord: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    email: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    password: (value: string, state: any) => {
      if (!state.id && !value) {
        return "Password should be mandatory when adding new tenant.";
      } else if (value && value.length < 8) {
        return "Min password length is 8 characters";
      } else if (value && /^\d+$/.test(value)) {
        return "Password can't be all digit characters";
      }

      return true;
    },
    password_confirm: (value: string, state: any) => {
      if (
        state.password &&
        state.password !== "" &&
        state.password !== value
      ) {
        return "Confirm password should be the same with password. Value is not same.";
      }

      return true;
    },
  },
};

export default configBuilder.buildAddEditPageConfig(definitions);
