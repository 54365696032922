import moment from "moment";
import { isDecimalNum } from "./generalUtils";

const formatter = {
  currency: (value, removeSymbol = false) => {
    if ((typeof value === "number" && isNaN(value)) || isNaN(value)) {
      return "";
    } else {
      const isDecimal = isDecimalNum(value);

      const formattedNumber = new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: isDecimal ? 2 : 0,
      }).format(value);

      if (!removeSymbol) return formattedNumber;
      return formattedNumber.replace("Rp", "");
    }
  },
  date: (value, format = "DD MMM YYYY") => {
    return value !== undefined &&
      value !== null &&
      value !== "" &&
      value !== "-"
      ? moment(value).format(format)
      : "-";
  },
  dateRange: (valueStart, valueEnd, format = "DD MMM YYYY") => {
    return (
      (valueStart !== undefined &&
      valueStart !== null &&
      valueStart !== "" &&
      valueStart !== "-"
        ? moment(valueStart).format(format)
        : "###") +
      " - " +
      (valueEnd !== undefined &&
      valueEnd !== null &&
      valueEnd !== "" &&
      valueEnd !== "-"
        ? moment(valueEnd).format(format)
        : "###")
    );
  },
  dateTime: (value, format = "DD MMM YYYY HH:mm") => {
    const result = moment(value).format(format);

    return value !== undefined &&
      value !== null &&
      value !== "" &&
      value !== "-"
      ? result === "Invalid date"
        ? "-"
        : result
      : "-";
  },
  truncateWithEllipsis: (input, maxCharacters) =>
    input.length > maxCharacters
      ? `${input.substring(0, maxCharacters)}...`
      : input,
};

export default formatter;
