import React from "react";
import styled from "styled-components";

const CloseIcon =
  require("../../../../assets/icons/close.svg") as string;

type LabelOptionProps = {
  label: string;
  onClose: Function;
};

const LabelOption: React.FC<LabelOptionProps> = ({
  label,
  onClose,
}) => {
  return (
    <LabelOptionStyled>
      <div className="label-text">{label}</div>
      <div onClick={() => onClose()} className="close-btn">
        <img src={CloseIcon} alt="close" className="close" />
      </div>
    </LabelOptionStyled>
  );
};

const LabelOptionStyled = styled.div`
  display: flex;
  align-items: center;
  height: 26px;
  background: #fff;
  border-radius: 4px;
  padding: 4px 8px;
  border: 1px solid #999999;
  max-width: 140px;
  box-sizing: border-box;
  .label-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .close-btn {
    cursor: pointer;
    margin-left: 8px;
    flex-shrink: 0;
    width: 12px;
    height: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    & .close {
      width: 100%;
      height: 100%;
    }
  }
`;

export default LabelOption;
