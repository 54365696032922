import configBuilder from "../../../../components/ubold/ConfigBuilder";

import pageDefinitions from "../definitions";

import utils from "../../../../utils";

const definitions = {
  ...pageDefinitions,
  validators: {
    category: (value) => {
      return utils.validator.validateRequired(value);
    },
    keyname: (value) => {
      return utils.validator.validateRequired(value);
    },
    valueinfo: (value) => {
      return utils.validator.validateRequired(value);
    },
    fields: (values) => {
      // example data [{key: "key1", value: "value1"}, {key: "key2", value: "value2"}]
      // check if key and value in each object is not empty

      const checkValueError = values.map((value) => {
        if (value.key === "" || value.value === "") {
          return utils.validator.validateRequired(value);
        }
      });

      if (checkValueError.includes(true)) {
        return "The Label or Value field must not empty";
      } else {
        return true;
      }
    },
  },
};

export default configBuilder.buildAddEditPageConfig(definitions);
