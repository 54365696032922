import styled from "styled-components/macro";

export const Container = styled.div`
  flex-wrap: wrap;
  margin: 16px 0 16px 0;
  height: fit-content;
  font-family: "Rubik";
  font-size: 12px;
  font-weight: 400;
  color: #222;
`;

export const AddBtnContainer = styled.div`
  background: transparent;
  display: flex;
  justify-content: flex-end;
  padding: 16px;
`;

export const FilterContainer = styled.div`
  background: #fff;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  padding: 16px;
  width: 100%;
  height: fit-content;
  align-content: flex-start;
`;

export const FilterField = styled.div`
  height: fit-content;
  display: flex;
`;

export const FilterFieldItem = styled.div`
  margin: 0 16px 0 0;
  min-width: fit-content;
  height: inherit;

  & label {
    margin-bottom: 4px;
  }
`;

export const ActionBtnContainer = styled.div`
  margin-top: 23px;
  display: inherit;
`;

export const HelperLabel = styled.span`
  padding-top: 4px;
  font-size: 10px;
  line-height: 14px;
  color: #d00c1a;
`;

export const FilterTitle = styled.label<{
  error?: string;
}>`
  line-height: 18px;
  color: ${({ error }) => (error ? "#d00c1a" : "inherit")};
`;
