import utils from "../../../../utils";
import styled, { css } from "styled-components/macro";
import { TextFieldType } from "@types";
import React, { MouseEventHandler } from "react";

const CTextFieldV2: React.FC<TextFieldType> = ({
  autoComplete = "off",
  isParkingAddon,
  maxLength = null,
  min,
  name,
  onChange,
  onKeyDown,
  checkValueAvailability,
  placeholder,
  readOnly = false,
  setState,
  state,
  type = "text",
  value,
}) => {
  const getWarningText = (value: string) => {
    const isValidNopol = utils.validator.validateNopol(
      value.toUpperCase()
    );

    if (!isValidNopol) {
      return (
        <div
          style={{ fontSize: 12, fontWeight: 500, color: "#FFB71B" }}
        >
          Peringatan: Nomor plat tidak valid!
        </div>
      );
    } else {
      return <div />;
    }
  };

  return (
    <>
      <FieldContainer>
        <Input
          type={type}
          name={name}
          value={state?.[name] || value}
          placeholder={placeholder}
          maxLength={maxLength ? maxLength : 1000}
          min={min}
          autoComplete={autoComplete}
          disabled={readOnly}
          style={{
            textTransform: isParkingAddon ? "uppercase" : "none",
          }}
          onKeyDown={onKeyDown}
          onWheel={(e: any) => e.target.blur()}
          onChange={
            onChange
              ? onChange
              : (e) => {
                  const value =
                    type === "number"
                      ? parseInt(e.target.value)
                      : e.target.value;
                  if (checkValueAvailability) {
                    checkValueAvailability(name, e.target.value);
                  }

                  setState((prevState: any) => ({
                    ...prevState,
                    [name]: value === "" ? null : value,
                    [name + "Error"]: null,
                  }));
                }
          }
          hasError={state?.[name + "Error"]}
          isDisabled={readOnly}
          useValueChecking={state?.[name + "UseValueChecking"]}
          isValueAvailable={state?.[name + "Available"]}
        />
        {isParkingAddon &&
          state?.[name] &&
          getWarningText(state?.[name])}

        {state?.[name + "Error"] && (
          <ImgStyled
            src={"/assets/icons/exclamation-mark.svg"}
            height={16}
            width={16}
          />
        )}

        {!state?.[name + "Error"] &&
          state?.[name] &&
          state?.[name + "UseValueChecking"] && (
            <ImgStyled
              src={`${
                state?.[name + "Available"]
                  ? "/assets/icons/circle-check.svg"
                  : "/assets/icons/exclamation-mark.svg"
              }`}
              height={16}
              width={16}
            />
          )}

        {state?.[name + "UseValueChecking"] && (
          <FieldInfo
            isAvailable={state?.[name + "Available"]}
            useValueChecking={state?.[name + "UseValueChecking"]}
          >
            {state?.[name + "AvailableMessage"]}
          </FieldInfo>
        )}

        {type !== "number" &&
          !state?.[name + "AvailableMessage"] &&
          !state?.[name + "IsChecking"] &&
          maxLength &&
          !state?.[name + "Error"] && (
            <FieldInfo>{`Max. ${maxLength} characters. Letters and -/' only`}</FieldInfo>
          )}
        {state?.[name + "Error"] &&
          !state?.[name + "AvailableMessage"] && (
            <FieldInfo
              hasError={state?.[name + "Error"] ? true : false}
            >
              {state?.[name + "Error"]}
            </FieldInfo>
          )}
        {state?.[name + "IsChecking"] && (
          <FieldInfo>Checking availability...</FieldInfo>
        )}
      </FieldContainer>
    </>
  );
};

export default CTextFieldV2;

const FieldContainer = styled.div`
  position: relative;
  width: 100%;
`;

const Input = styled.input<{
  hasError?: string;
  isDisabled?: boolean;
  useValueChecking?: boolean;
  isValueAvailable?: boolean;
}>`
  color: ${({ isDisabled }) => (isDisabled ? "#CCCCCC" : "#222222")};
  height: 34px;
  font-size: 12px;
  width: 100%;
  ${({ hasError }) =>
    hasError
      ? css`
          border: 1px solid #d00c1a;
        `
      : css`
          border: 1px solid #cccccc;
        `};
  ${({ useValueChecking, isValueAvailable }) =>
    useValueChecking &&
    isValueAvailable &&
    css`
      border: 1px solid #3db165;
    `};
  ${({ useValueChecking, isValueAvailable }) =>
    useValueChecking &&
    !isValueAvailable &&
    css`
      border: 1px solid #d00c1a;
    `};
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      pointer-events: none;
    `};
  border-radius: 4px;
  outline: none;
  padding: 8px 12px;
  box-sizing: border-box;
  transition: 0.3s;

  :focus {
    border: 1px solid #009c96;
    box-shadow: 0 0 4px 0px #00bbb4;
  }

  :hover {
    border-color: #009c96;
  }

  :disabled {
    background: #fafafa;
  }
`;

const FieldInfo = styled.div<{
  hasError?: boolean;
  isAvailable?: boolean;
  useValueChecking?: boolean;
}>`
  color: #999999;
  font-size: 10px;

  ${({ hasError }) =>
    hasError &&
    css`
      color: #d00c1a;
    `};

  ${({ isAvailable, hasError, useValueChecking }) =>
    isAvailable &&
    !hasError &&
    useValueChecking &&
    css`
      color: #3db165;
    `};
  ${({ isAvailable, hasError, useValueChecking }) =>
    !isAvailable &&
    !hasError &&
    useValueChecking &&
    css`
      color: #d00c1a;
    `};

  margin-top: 4px;
  font-weight: 400;
`;

const ImgStyled = styled.img<{
  autoDismissed?: boolean;
}>`
  display: ${({ autoDismissed }) => autoDismissed && "none"};
  right: 12px;
  top: 9px;
  background-color: transparent;
  border: none;
  position: absolute;
`;
