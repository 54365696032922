import React from "react";
import ModalsCollectionProvider from "./ContextProviders/Modals";
import { ModalProvider } from "./Modal/ModalContext";
import WithToast from "./WithToast";

const ContextProviderWrapper: React.FC = ({ children }) => {
  return (
    <>
      <ModalProvider>
        <ModalsCollectionProvider>
          <WithToast>{children}</WithToast>
        </ModalsCollectionProvider>
      </ModalProvider>
    </>
  );
};

export default ContextProviderWrapper;
