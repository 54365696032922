import { useState } from "react";

import { useParams, withRouter } from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import definitions from "./definitions";

import TabHeaders from "../../../../components/ubold/organisms/TabHeaders";
import TabContent from "../../../../components/ubold/organisms/TabContent";
import TabbedForm from "../../../../components/ubold/organisms/TabbedForm";

import Event from "./Tabs/Event";
import Attendance from "./Tabs/Attendance";

function EventViewPage() {
  let { id }: { id: string } = useParams();

  const initialState = {
    event_type: 1,
    host: "Rukita",
    quota: 1,
    event_mode: 0,
  };

  const [state, setState] = useState(initialState);

  return (
    <Page
      title={"Detail of " + definitions.title}
      restAccessCode={definitions.restAccessCode}
    >
      <TabbedForm
        id={id}
        state={state}
        setState={setState}
        definitions={definitions}
      >
        <TabHeaders />
        <TabContent>
          <Event />
          <Attendance />
        </TabContent>
      </TabbedForm>
    </Page>
  );
}

export default withRouter(EventViewPage);
