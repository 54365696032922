import configBuilder from "../../../../components/ubold/ConfigBuilder";

import pageDefinitions from "../definitions";

import utils from "../../../../utils";

const definitions = {
  ...pageDefinitions,
  validators: {
    name: (value) => {
      return utils.validator.validateRequired(value);
    },
    country: (value) => {
      return utils.validator.validateRequired(value);
    },
    province: (value) => {
      return utils.validator.validateRequired(value);
    },
    photo_header_original: (value) =>
      utils.validator.validateRequired(value),
    sequence_number: (value) => {
      if (!value || value >= 1) return true;
      return "Sequence number minimum value is 1";
    },
  },
};

export default configBuilder.buildAddEditPageConfig(definitions, [
  "photo_header_original",
]);
