import configBuilder from "../../../../components/ubold/ConfigBuilder";

import pageDefinitions from "../definitions";

const definitions = {
  ...pageDefinitions,
  tabs: [
    {
      id: "service",
      name: "Service",
    },
    {
      id: "t_request_logs",
      name: "RequestLog",
    },
  ],
};

export default configBuilder.buildAddEditPageConfig(definitions);
