import { useEffect, useRef, useState } from "react";

import { useParams, withRouter } from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import definitions from "./definitions";

import TabHeaders from "../../../../components/ubold/organisms/TabHeaders";
import TabContent from "../../../../components/ubold/organisms/TabContent";
import TabbedForm from "../../../../components/ubold/organisms/TabbedForm";

import Tenant from "./Tabs/Tenant";
import RukiKoin from "./Tabs/RukiKoin";

function CAddEditPage() {
  let { id } = useParams();

  const initialState = {};

  const [state, setState] = useState(initialState);
  const isKeyRemoved = useRef(false);

  useEffect(() => {
    if (id && !isKeyRemoved.current) {
      const newState = { ...state };
      if ("password" in newState) {
        delete newState.password;
        if ("password_confirm" in newState) {
          delete newState.password_confirm;
        }
        setState(newState);
        isKeyRemoved.current = true;
      }
    }
  }, [id, state]);

  return (
    <Page
      title={
        (id === undefined ? "Add New" : "Edit") +
        " " +
        definitions.title
      }
      restAccessCode={definitions.restAccessCode}
    >
      <TabbedForm
        id={id}
        state={state}
        setState={setState}
        definitions={definitions}
      >
        <TabHeaders />
        <TabContent>
          <Tenant />
          <RukiKoin />
        </TabContent>
      </TabbedForm>
    </Page>
  );
}

export default withRouter(CAddEditPage);
