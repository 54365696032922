import {useState} from "react";
import {useParams, withRouter} from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import Form from "../../../../components/ubold/organisms/Form";

import CVTextField from "../../../../components/ubold/molecules/forms/CVTextField";
import CVSelect from "../../../../components/ubold/molecules/forms/CVSelect";
import CVAsyncSelect from "../../../../components/ubold/molecules/forms/CVAsyncSelect";
import CVDateTimePicker from "../../../../components/ubold/molecules/forms/CVDateTimePicker";
import CVTextArea from "../../../../components/ubold/molecules/forms/CVTextArea";

import definitions from "./definitions";

function DiscountAddEditPage() {
    let {id} = useParams();

    const initialState = {
        payment_type: 'bank_transfer',
        status: 1
    };

    const [state, setState] = useState(initialState);

    return (
        <Page title={(id === undefined ? 'Add New' : 'Edit') + ' ' +  definitions.title}
              restAccessCode={definitions.restAccessCode}>
            <Form
                id={id}
                definitions={definitions}
                state={state}
                setState={setState}>
                <CVAsyncSelect
                    title='Invoice'
                    name='invoice'
                    data='finance/invoice?__type__=select_entries'
                    itemsExtractor={row => {
                        return {
                            label: row.number,
                            value: row.id
                        }}
                    }
                />
                <CVTextField
                    title='Midtrans Number'
                    name='midtrans_number'
                    isRequired
                    maxLength={45}
                />
                <CVTextField
                    title='Amount'
                    name='amount'
                    type='number'
                />
                <CVSelect
                    title='Payment Type'
                    name='payment_type'
                    isRequired
                    data={[
                        {
                            label: 'Credit Card',
                            value: 'credit_card'
                        },
                        {
                            label: 'Gopay',
                            value: 'gopay'
                        },
                        {
                            label: 'Bank Transfer',
                            value: 'bank_transfer'
                        },
                        {
                            label: 'E-Channel',
                            value: 'echannel'
                        },
                        {
                            label: 'BCA Klikpay',
                            value: 'bca_klikpay'
                        },
                        {
                            label: 'BCA KlikBCA',
                            value: 'bca_klikbca'
                        },
                        {
                            label: 'Mandiri ClickPay',
                            value: 'mandiri_clickpay'
                        },
                        {
                            label: 'CIMB Clicks',
                            value: 'cimb_clicks'
                        },
                        {
                            label: 'Danamon Online',
                            value: 'danamon_online'
                        },
                        {
                            label: 'CStore',
                            value: 'cstore'
                        },
                        {
                            label: 'Akulaku',
                            value: 'akulaku'
                        },
                        {
                            label: 'BRI EPay',
                            value: 'bri_epay'
                        },
                    ]}
                />
                <CVSelect
                    title='Status'
                    name='status'
                    data={[
                        {
                            label: 'Waiting',
                            value: 1
                        },
                        {
                            label: 'Pending',
                            value: 2
                        },
                        {
                            label: 'Cancel',
                            value: 3
                        },
                        {
                            label: 'Fail',
                            value: 4
                        },
                        {
                            label: 'Paid',
                            value: 5
                        },
                        {
                            label: 'Expired',
                            value: 6
                        },
                        {
                            label: 'Others',
                            value: 13
                        },
                    ]}
                />
                <CVDateTimePicker
                    title='Status Timestamp'
                    name='status_timestamp'
                />
                <CVTextArea
                    title='Reason'
                    name='reason'
                />
                <CVTextArea
                    title='Reason Rukita'
                    name='reason_rukita'
                />
                <CVTextArea
                    title='Token'
                    name='token'
                />
                <CVTextArea
                    title='Payment URL'
                    name='payment_url'
                />
                <CVDateTimePicker
                    title='Expired Time'
                    name='expired_time'
                />
                <CVTextField
                    title='Bank'
                    name='bank'
                    maxLength={45}
                    isRequired
                />
            </Form>
        </Page>
    );
}

export default withRouter(DiscountAddEditPage);