import styled from "styled-components/macro";
import utils from "utils";
import { TextFieldType } from "@types";
import CTextFieldV2 from "components/ubold/atoms/forms/CTextFieldV2";
import useGlobalState from "state";

interface CHTextFieldProps {
  title?: string;
  restAccessCode?: string;
  isRequired?: boolean;
}

type FieldProps = CHTextFieldProps & TextFieldType;

const CHTextFieldV2: React.FC<FieldProps> = ({
  autoComplete,
  isParkingAddon,
  isRequired,
  maxLength,
  min,
  name,
  onChange,
  onKeyDown,
  checkValueAvailability,
  placeholder,
  readOnly,
  restAccessCode,
  setErrorMessage,
  setState,
  state,
  title,
  type,
  useValueChecking,
  validate,
  valueIsValid,
  value,
}) => {
  const [user] = useGlobalState("user");
  const isSuperUser = user ? user.is_super_user : false;
  const fieldAccesses = useGlobalState("field_accesses");
  const [fieldAccessReversePolicy] = useGlobalState(
    "field_access_reverse_policy"
  );

  return (
    <FieldStyled>
      <LabelStyled
        className={state?.[name + "Error"] ? "text-danger" : ""}
      >
        {title ?? "Default Label"}
        {isRequired && <span className="text-danger">*</span>}
      </LabelStyled>

      <CTextFieldV2
        autoComplete={autoComplete}
        isParkingAddon={isParkingAddon}
        maxLength={maxLength}
        min={min}
        name={name}
        onChange={onChange}
        onKeyDown={onKeyDown}
        checkValueAvailability={checkValueAvailability}
        placeholder={placeholder}
        readOnly={
          readOnly ??
          (restAccessCode &&
            utils.access.isFieldReadOnly(
              fieldAccesses,
              restAccessCode + "." + name,
              fieldAccessReversePolicy,
              isSuperUser
            ))
        }
        setErrorMessage={setErrorMessage}
        setState={setState}
        state={state}
        type={type}
        useValueChecking={useValueChecking}
        validate={validate}
        valueIsValid={valueIsValid}
        value={value}
      />
    </FieldStyled>
  );
};

export default CHTextFieldV2;

const FieldStyled = styled.div`
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 147px auto;
  height: 52px;
  margin-bottom: 16px;
`;

const LabelStyled = styled.label`
  font-weight: 400;
  font-size: 12px;
`;
