import {withRouter} from "react-router-dom";

function TabHeaders(props) {
    const {definitions, id, state} = props;

    return (
        <ul className="nav nav-tabs nav-bordered">
            {definitions.tabs.map((tab, i) => {
                return <li
                    key={'tabs' + tab.id}
                    className="nav-item">
                    <a
                        href={'#' + tab.id}
                        data-toggle="tab"
                        style={{color: state[tab.id + 'TabError'] ? 'red' : ''}}
                        className={"nav-link" + (i === 0 ? ' active' : id === undefined && i > 0 && !tab.alwaysEditable ? ' disabled' : '')}
                        aria-expanded='true'>
                        {tab.name}{state[tab.id + 'TabError'] ? ' (!)' : ''}
                    </a>
                </li>
            })}
        </ul>
    );
}

export default withRouter(TabHeaders);