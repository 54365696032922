import { useParams, withRouter } from "react-router-dom";

import TabPaneSecondary from "../../../../../../components/ubold/organisms/TabPaneSecondary";
import COneOrManyToManyField from "../../../../../../components/ubold/molecules/forms/COneOrManyToManyField";

function VoucherCode(props) {
  const { definitions, tab, state, setState } = props;
  let { id } = useParams();
  return (
    <TabPaneSecondary
      definitions={definitions}
      tab={tab}
      restAccessCodeNamespace="voucher_code"
      state={state}
      setState={setState}
    >
      <COneOrManyToManyField
        id={id}
        tab={tab}
        restPath="promotion/voucher_code/"
        relationKey="voucher_id"
        disableActionBtn={true}
        readOnly={true}
        columns={[
          {
            name: "voucher",
            title: "Voucher",
            type: "async_select",
            data: "promotion/voucher_management?__type__=select_entries",
            isReadOnly: true,
            itemsExtractor: (value) => {
              return {
                label: value.name,
                value: value.id,
              };
            },
          },
          {
            name: "unique_code",
            title: "Unique Code",
            type: "text",
            isReadOnly: true,
          },
          {
            name: "is_used",
            title: "Is Used",
            type: "checkbox",
            isReadOnly: true,
          },
          {
            name: "date_deleted",
            title: "Date Deleted",
            type: "datetime",
            isReadOnly: true,
          },
          {
            name: "deleted_by",
            title: "Deleted By",
            type: "text",
            isReadOnly: true,
          },
          {
            name: "created_by",
            title: "Created By",
            type: "text",
            isReadOnly: true,
          },
          {
            name: "updated_by",
            title: "Updated By",
            type: "text",
            isReadOnly: true,
          },
          {
            name: "is_deleted",
            title: "Is Deleted",
            type: "checkbox",
            isReadOnly: true,
          },
        ]}
      />
    </TabPaneSecondary>
  );
}

export default withRouter(VoucherCode);
