import utils from "utils";
import configBuilder from "../../../../components/ubold/ConfigBuilder";

import pageDefinitions from "../definitions";

const definitions = {
  ...pageDefinitions,
  linkHistory: (id) => `${definitions.frontendPath}/history/${id}`,
  tabs: [
    {
      id: "invoice",
      name: "Invoice SOP",
    },
    {
      id: "t_payments",
      name: "Payments",
    },
    {
      id: "t_midtrans_transactions",
      name: "Midtrans Transactions",
    },
  ],
  validators: {
    order: (value) => {
      return utils.validator.validateRequired(value);
    },
    started_at: (value) => {
      return utils.validator.validateRequired(value);
    },
    ended_at: (value) => {
      return utils.validator.validateRequired(value);
    },
  },
  manipulateStateBeforeSave: true,
  validatorManyToManyV2: true,
};

export default configBuilder.buildAddEditPageConfig(
  definitions,
  [],
  [],
  "edit",
  (originalState) => ({
    ...originalState,
    code_bank: "bca_va",
    code_bank_object: {
      value: "bca_va",
      label: "BCA Virtual Account",
    },
  })
);
