import styled, { css } from "styled-components/macro";

const CSSTableHeadSpecification = css`
  background-color: #d9efe5;
  color: #222;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  text-align: center;

  p {
    margin-bottom: unset;
  }
`;

export const BgStripesBase = styled.div<{
  backgroundColor: string;
  bar: string;
  radiusLeft?: boolean;
  radiusRight?: boolean;
}>`
  height: 18px;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background-color: ${({ backgroundColor }) => backgroundColor};

  ${({ radiusLeft, radiusRight }) => {
    if (radiusRight) {
      return css`
        width: 18px;

        border-radius: 0px 9px 9px 0px;
        overflow: hidden;
      `;
    } else if (radiusLeft) {
      return css`
        width: 18px;

        border-radius: 9px 0px 0px 9px;
        overflow: hidden;
      `;
    }
  }}

  .bar {
    height: 2.5px;

    background-color: ${({ bar }) => bar};
    margin: unset;
  }
`;

export const TableStickyInformation = styled.div`
  position: sticky;
  top: 70px;
  z-index: 10;

  background-color: #ffffff;
  padding-top: 16px;
`;

export const RefreshBtn = styled.button`
  height: 14px;
  color: #00bbb4;
  background: transparent;
  border: 0;
  cursor: pointer;
`;

export const TableInformation = styled.div`
  max-height: 14px;
  display: flex;
  flex-direction: row;
  font-size: 10px;
  line-height: 14px;
`;

export const LegendsContainer = styled.div`
  display: inline-grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 40px;
`;

export const LegendCategoryLabel = styled.span`
  color: #222222;
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
`;

export const LabelLink = styled.a`
  color: #00bbb4;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;

  &:active,
  &:focus,
  &:hover {
    text-decoration: underline;

    span {
      text-decoration: underline;
    }
  }

  &:active {
    color: #063735;
  }

  &:focus {
    border-radius: 2px;
    color: #009c96;
    outline: 4px solid rgba(0, 187, 180, 0.2);
    padding-left: unset;
  }

  &:hover {
    color: #009c96;
  }
`;

export const TableWhiteContainer = styled.div`
  background-color: #fff;
  border-radius: 4px;
  padding: 0 16px 16px 16px;
`;

export const Container = styled.div`
  border-radius: 4px;
  color: #000;
  display: flex;
  flex-direction: column;
  font-family: "Rubik";
  font-weight: 400;
  height: 100%;
  width: 100%;
`;

export const TableBase = styled.div<{
  grid?: { templateColumns?: { left?: string; right?: string } };
}>`
  display: grid;
  grid-template-areas:
    "table-head table-head"
    "table-body table-body";
  grid-template-columns: ${({ grid }) => {
    const left = grid?.templateColumns?.left ?? "460px";
    const right = grid?.templateColumns?.right ?? "540px";

    return `${left} ${right}`;
  }};
`;

export const TableHead = styled.div`
  grid-area: table-head;

  display: grid;

  position: sticky;
  top: 150.5px;
  z-index: 10;
`;

export const TableBaseHeadLevel1 = styled.div<{
  grid?: { templateColumns?: { left?: string; right?: string } };
}>`
  display: grid;
  grid-template-areas: "level-1-left level-1-right";
  grid-template-columns: ${({ grid }) => {
    const left: string = grid?.templateColumns?.left ?? "460px";
    const right: string = grid?.templateColumns?.right ?? "540px";

    return `${left} ${right}`;
  }};
  grid-template-rows: 30px;
`;

export const TableBaseHeadLevel2 = styled.div`
  display: grid;
  grid-template-areas: "col-head-room col-head-status col-head-booked-by-sales col-head-days-of-date-range";
  grid-template-columns:
    minmax(80px, auto) minmax(250px, auto) minmax(130px, auto)
    minmax(540px, auto);
  grid-template-rows: 45px;

  border-top: 1px solid #00bbb4;
  border-bottom: 1px solid #00bbb4;

  & > div {
    &:not(:last-child) {
      border-right: 1px solid rgba(0, 187, 180, 0.2);
    }
  }
`;

export const CurrentDateInformation = styled.div`
  grid-area: level-1-left;

  display: flex;
  align-items: center;
  justify-content: center;

  border-right: 1px solid rgba(0, 187, 180, 0.2);

  ${CSSTableHeadSpecification}
`;

export const CurrentSelectedDateRange = styled.div`
  grid-area: level-1-right;

  display: flex;
  align-items: center;
  justify-content: space-between;

  border-right: 1px solid rgba(0, 187, 180, 0.2);
  padding: 0 18.64px;

  ${CSSTableHeadSpecification}
`;

export const TableHeadColDaysOfSelectedDateRange = styled.div`
  grid-area: col-head-days-of-date-range;

  display: grid;
  grid-template-columns: repeat(30, minmax(18px, auto));
  grid-template-rows: 45px;
`;

export const HeadColItemDay = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: #ffffff;
  border-right: 1px solid rgba(0, 187, 180, 0.2);

  p {
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
    margin-bottom: unset;

    &:first-child {
      color: #999999;
    }
  }
`;

export const TableHeadColRoom = styled.div`
  grid-area: col-head-room;

  display: flex;
  align-items: center;
  justify-content: space-between;

  ${CSSTableHeadSpecification}

  p {
    padding-left: 6px;
  }
`;

export const TableHeadColStatus = styled.div`
  grid-area: col-head-status;

  display: flex;
  align-items: center;
  justify-content: space-between;

  ${CSSTableHeadSpecification}

  p {
    padding-left: 6px;
  }
`;

export const TableHeadColBookedBySales = styled.div`
  grid-area: col-head-booked-by-sales;

  display: flex;
  align-items: center;
  justify-content: space-between;

  ${CSSTableHeadSpecification}

  img {
    margin-right: 6px;
  }

  p {
    padding-left: 6px;
  }
`;

export const IconDateRangeNavigation = styled.img<{
  direction: "LEFT" | "RIGHT";
  disabled?: boolean;
}>`
  ${({ disabled }) =>
    disabled
      ? css`
          filter: brightness(0) saturate(100%) invert(99%) sepia(0%)
            saturate(16%) hue-rotate(160deg) brightness(89%)
            contrast(79%);
        `
      : ""}

  ${({ direction }) => {
    if (direction === "RIGHT") {
      return css`
        margin-left: 7.75px;
        transform: matrix(0, 1, 1, 0, 0, 0) rotate(-90deg);
      `;
    } else if (direction === "LEFT") {
      return css`
        margin-right: 7.75px;
      `;
    }

    return "";
  }}
`;

export const ButtonDateRangeNavigation = styled.button<{
  disabled?: boolean;
}>`
  display: inline-flex;
  align-items: center;

  background-color: unset;
  border: unset;
  color: ${({ disabled }) => (disabled ? "#CCCCCC" : " #222222")};
  outline: unset;
  padding: unset;

  &:not(:disabled) {
    &:active {
      color: #063735;

      & img {
        filter: brightness(0) saturate(100%) invert(17%) sepia(36%)
          saturate(829%) hue-rotate(129deg) brightness(95%)
          contrast(101%);
      }
    }

    &:hover {
      color: #009c96;

      img {
        filter: brightness(0) saturate(100%) invert(44%) sepia(36%)
          saturate(6638%) hue-rotate(153deg) brightness(94%)
          contrast(101%);
      }
    }
  }
`;

export const TableBody = styled.div`
  display: grid;
  grid-area: table-body;
`;

export const TableBodyRoomVariantItem = styled.div`
  display: grid;
  grid-template-areas:
    "room-variant-header room-variant-header room-variant-header room-variant-header"
    "room-variant-body room-variant-body room-variant-body room-variant-body";

  grid-template-columns:
    minmax(80px, auto) minmax(250px, auto) minmax(130px, auto)
    minmax(540px, auto);
  grid-template-rows: 35px;
`;

export const TableHeadRoomVariantItemHeader = styled.div`
  grid-area: room-variant-header;

  display: flex;
  align-items: center;

  background-color: rgba(231, 231, 231, 0.5);
  border-right: 1px solid #e7e7e7;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  padding: 0 6px;
`;

export const TableBodyRoomVariantItemBody = styled.div`
  grid-area: room-variant-body;

  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: 35px;
  grid-template-rows: 35px;
`;

export const TableRowRoomVariantItem = styled.div<{
  grid?: { templateColumns?: string };
}>`
  display: grid;
  align-items: stretch;
  grid-template-areas: "col-body-room col-body-status col-body-booked-by-sales col-body-days-of-date-range";
  grid-template-columns: ${({ grid }) =>
    grid?.templateColumns ??
    "minmax(80px, auto) minmax(250px, auto) minmax(130px, auto) 540px"};

  & > div {
    &:nth-of-type(1),
    &:nth-of-type(2),
    &:nth-of-type(3),
    &:nth-of-type(4) {
      border-top: 1px solid #e7e7e7;
      border-left: 1px solid #e7e7e7;
    }
  }

  &:last-child {
    & > div {
      &:nth-of-type(1),
      &:nth-of-type(2),
      &:nth-of-type(3),
      &:nth-of-type(4) {
        border-bottom: 1px solid #e7e7e7;
      }
    }
  }
`;

export const ColRoom = styled.div`
  grid-area: col-body-room;

  display: flex;
  align-items: center;

  padding-left: 6px;
`;

export const ColStatus = styled.div`
  grid-area: col-body-status;

  display: flex;
  align-items: center;

  padding-left: 6px;
`;

export const ColBookedBySales = styled.div`
  grid-area: col-body-booked-by-sales;

  display: flex;
  align-items: center;

  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  padding-left: 6px;
`;

export const ColDaysOfSelectedDateRange = styled.div`
  grid-area: col-body-days-of-date-range;

  display: grid;
  grid-template-columns: repeat(30, minmax(18px, 1fr));
  grid-template-rows: 35px;
`;

export const ColItemDay = styled.div<{
  position?: "left" | "right";
  radiusLeft?: boolean;
  radiusRight?: boolean;
  size?: "center" | "full";
}>`
  border-right: 1px solid #e7e7e7;

  display: flex;

  ${({ position, size }) => {
    if (position === "right") {
      return css`
        align-items: center;
        justify-content: flex-end;
      `;
    }

    if (size === "center") {
      return css`
        align-items: center;
      `;
    }
  }}
`;

export const BgRoomStatus = styled.div<{
  radiusLeft?: boolean;
  radiusRight?: boolean;
  size?: "center" | "full";
  theme?: "BLUE" | "PURPLE" | "RED" | "YELLOW" | "GREY";
}>`
  width: 100%;

  ${({ theme }) => {
    switch (theme) {
      case "BLUE":
        return css`
          background-color: #2a7de1;
        `;

      case "GREY":
        return css`
          background-color: #cccccc;
        `;

      case "PURPLE":
        return css`
          background-color: #7e57c5;
        `;

      case "RED":
        return css`
          background-color: #ff4438;
        `;

      case "YELLOW":
        return css`
          background-color: #ffb71b;
        `;

      default:
        return css`
          background-color: #fff;
        `;
    }
  }}

  ${({ size }) => {
    if (size === "center") {
      return css`
        height: 18px;
      `;
    } else if (size === "full") {
      return css`
        height: 100%;
      `;
    }
  }}

${({ radiusLeft, radiusRight }) => {
    if (radiusRight) {
      return css`
        width: 18px;

        border-radius: 0px 9px 9px 0px;
        overflow: hidden;
      `;
    } else if (radiusLeft) {
      return css`
        width: 18px;

        border-radius: 9px 0px 0px 9px;
        overflow: hidden;
      `;
    }
  }}
`;
