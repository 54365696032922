import { useState } from "react";
import { useParams, withRouter } from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import Form from "../../../../components/ubold/organisms/Form";

import CVTextField from "../../../../components/ubold/molecules/forms/CVTextField";
import CVSwitch from "../../../../components/ubold/molecules/forms/CVSwitch";
import CVImageUpload from "../../../../components/ubold/molecules/forms/CVImageUpload";

import definitions from "./definitions";

function DiscountAddEditPage() {
  let { id } = useParams();

  const initialState = {};

  const [state, setState] = useState(initialState);

  return (
    <Page
      title={
        (id === undefined ? "Add New" : "Edit") +
        " " +
        definitions.title
      }
      restAccessCode={definitions.restAccessCode}
    >
      <Form
        id={id}
        definitions={definitions}
        state={state}
        setState={setState}
      >
        <CVImageUpload
          title="Mobile screen (450x253px, 150dpi, max size 500KB)"
          name="banner_picture_original"
          isRequired
          maxSize={500000}
          alertFileSize="500KB"
        />
        <CVTextField title="URL" name="url" maxLength={2000} />
        <CVTextField title="Order No" name="order_no" type="number" />
        <CVTextField
          title="Promo Slug"
          name="promo_slug"
          maxLength={100}
        />
        <CVSwitch title="Published" name="published" />
      </Form>
    </Page>
  );
}

export default withRouter(DiscountAddEditPage);
