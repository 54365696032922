import { withRouter } from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";
import TableDashboardBuilding from "components/ubold/organisms/TableDashboardBuilding/TableDashboardBuilding";
import definitions from "./definitions";
import styled from "styled-components";

function BuildingDashboardPage() {
  return (
    <DashboardBuildingContainer>
      <Page title={definitions.title}>
        <TableDashboardBuilding {...definitions.table} />
      </Page>
    </DashboardBuildingContainer>
  );
}

export default withRouter(BuildingDashboardPage);

const DashboardBuildingContainer = styled.div`
  .content-page {
    overflow: unset;
  }

  #wrapper {
    overflow: unset;
  }
`;
