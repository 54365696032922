import { withRouter } from "react-router-dom";

import TabPane from "../../../../../../components/ubold/organisms/TabPane";
import TabPaneSection from "../../../../../../components/ubold/organisms/TabPaneSection";
import CHTextField from "../../../../../../components/ubold/molecules/forms/CHTextField";
import CHTextArea from "../../../../../../components/ubold/molecules/forms/CHTextArea";
import CHRichTextField from "../../../../../../components/ubold/molecules/forms/CHRichTextField";
import CHCheckBox from "../../../../../../components/ubold/molecules/forms/CHCheckBox";
import { genericTabProps } from "@types";
import CHImageView from "components/ubold/molecules/forms/CHImageView";

function PlaceGroup(props: genericTabProps<Object>) {
  const { id, definitions, tab, state, setState } = props;

  return (
    <TabPane
      id={id}
      definitions={definitions}
      tab={tab}
      state={state}
      setState={setState}
      readOnly
    >
      <TabPaneSection style={{ padding: "25px 16px 0px" }}>
        <CHTextField
          name="name"
          title="Name"
          maxLength={50}
          isRequired
          readOnly
        />
        <CHTextField
          name="slug"
          title="Slug"
          maxLength={50}
          isRequired
          readOnly
        />
        <CHTextField
          name="title_tag"
          title="Title"
          maxLength={160}
          readOnly
        />
        <CHTextField
          name="title_tag_en"
          title="Title in English"
          maxLength={160}
          readOnly
        />
        <CHTextArea
          name="meta_description"
          title="Meta Description"
          readOnly
        />
        <CHTextArea
          name="meta_description_en"
          title="Meta Description in English"
          readOnly
        />
        <CHRichTextField name="desc" title="Description" readOnly />
        <CHRichTextField
          name="desc_en"
          title="Description in English"
          readOnly
        />
        <CHImageView
          name="header_photo_original"
          title="Photo Header"
          isRequired
          readOnly
        />
        <CHCheckBox name="published" title="Published" disabled />
        <CHTextField
          name="order_no"
          title="Sequence Number"
          readOnly
          type="number"
        />
        <CHTextField
          name="lat"
          title="Latitude"
          type="number"
          readOnly
        />
        <CHTextField
          name="lon"
          title="Longitude"
          type="number"
          readOnly
        />
      </TabPaneSection>
    </TabPane>
  );
}

export default withRouter(PlaceGroup);
