import { useEffect, useState } from "react";
import { createPortal } from "react-dom";

type portalProp = {
  containerID: string;
};

const WithPortal: React.FC<portalProp> = ({
  children,
  containerID,
}) => {
  const [mounted, setMounted] = useState(false);
  const [placeholder, setPlaceholder] = useState<Element>();

  useEffect(() => {
    setMounted(true);
    const element: Element = document.createElement("div");
    element.id = containerID;
    setPlaceholder(element);
    return () => setMounted(false);
  }, []);

  return mounted && placeholder
    ? createPortal(
        children,
        document.getElementById(containerID) ?? placeholder
      )
    : null;
};

export default WithPortal;
