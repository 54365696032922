import { useParams, withRouter } from "react-router-dom";

import TabPaneSecondary from "../../../../../../components/ubold/organisms/TabPaneSecondary";

import COneOrManyToManyField from "../../../../../../components/ubold/molecules/forms/COneOrManyToManyField";

function RoomFeatures(props) {
  const { definitions, tab, state, setState } = props;

  let { id } = useParams();

  return (
    <TabPaneSecondary
      definitions={definitions}
      tab={tab}
      restAccessCodeNamespace="feature"
      state={state}
      setState={setState}
    >
      <COneOrManyToManyField
        id={id}
        name={tab.id}
        restAccessCode={definitions.restAccessCode}
        restPath="building/room_variant__feature/"
        relationKey="room_variant_id"
        columns={[
          {
            name: "facility",
            title: "Feature",
            isRequired: true,
            disabledAtEdit: true,
            isUniqueOption: true,
            type: "async_select",
            data: "building/master_facility?__type__=select_entries&facility_type=feature",
            itemsExtractor: (row) => {
              return {
                label: row["facility__name"]
                  ? row["facility__name"]
                  : row.name,
                value: row["id"],
              };
            },
          },
          {
            name: "properties",
            title: "Property",
            isRequired: true,
            dependsOn: "facility",
            dependsOnRelationKey: "master_facility",
            type: "dual_list",
            data: "building/master_facility_property?__type__=select_entries",
          },
        ]}
      />
    </TabPaneSecondary>
  );
}

export default withRouter(RoomFeatures);
