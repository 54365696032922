import { useState } from "react";

import { useParams, withRouter } from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import Form from "../../../../components/ubold/organisms/Form";

import definitions from "./definitions";
import CVTextField from "../../../../components/ubold/molecules/forms/CVTextField";
import CVImageView from "components/ubold/molecules/forms/CVImageView";

function AppHighlightViewPage() {
  let { id }: { id: string } = useParams();

  const initialState = {};

  const [state, setState] = useState(initialState);

  return (
    <Page
      title={"Detail of " + definitions.title}
      restAccessCode={definitions.restAccessCode}
    >
      <Form
        id={id}
        definitions={definitions}
        readOnly
        state={state}
        setState={setState}
      >
        <CVTextField
          name="title"
          title="Title"
          maxLength={120}
          isRequired
          readOnly
        />
        <CVImageView name="photo_original" title="Photo" isRequired />
        <CVTextField
          name="target_url"
          title="Target URL"
          maxLength={200}
          readOnly
        />
        <CVTextField
          name="sequence"
          title="Sequence"
          type="number"
          readOnly
        />
      </Form>
    </Page>
  );
}

export default withRouter(AppHighlightViewPage);
