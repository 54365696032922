import { useState } from "react";
import { useParams, withRouter } from "react-router";

import Page from "components/ubold/organisms/Page";
import definitions from "./definitions";
import TabHeaders from "components/ubold/organisms/TabHeaders";
import TabContent from "components/ubold/organisms/TabContent";
import TabbedForm from "components/ubold/organisms/TabbedForm";

import MonthlyReport from "./Tabs/MonthlyReports";
import ReportFile from "./Tabs/ReportFiles";

function CAddEditPage() {
  let { id }: { id: string } = useParams();

  const initialState: any = {
    building: null,
    building_type: null,
    building_object: null,
    room_variant: null,
    room_variant_object: null,
  };

  const [state, setState] = useState(initialState);

  return (
    <Page
      title={`${id === undefined ? "Add New" : "Edit"} ${
        definitions.title
      }`}
      restAccessCode={definitions.restAccessCode}
    >
      <TabbedForm
        id={id}
        state={state}
        setState={setState}
        definitions={definitions}
      >
        <TabHeaders />
        <TabContent>
          <MonthlyReport />
          <ReportFile />
        </TabContent>
      </TabbedForm>
    </Page>
  );
}

export default withRouter(CAddEditPage);
