import configBuilder from "../../../../components/ubold/ConfigBuilder";

import pageDefinitions from "../definitions";

import utils from "../../../../utils";

const definitions = {
  ...pageDefinitions,
  linkHistory: (id) => `${definitions.frontendPath}/history/${id}`,
  reloadAfterAdd: true,
  tabs: [
    {
      id: "service",
      name: "Help & Services",
    },
    {
      id: "t_buildings",
      name: "Buildings",
    },
  ],
  additionalValidatorWithoutTab: [
    {
      id: "t_categories",
      name: "Case and Sub Case Categories",
      excluded: {
        field: "request_type",
        value: 1,
      },
    },
  ],
  validators: {
    name: (value) => {
      return utils.validator.validateRequired(value);
    },
    name_en: (value) => {
      return utils.validator.validateRequired(value);
    },
  },
};

export default configBuilder.buildAddEditPageConfig(definitions);
