import { useState } from "react";

import { useParams, withRouter } from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import definitions from "./definitions";

import TabHeaders from "../../../../components/ubold/organisms/TabHeaders";
import TabContent from "../../../../components/ubold/organisms/TabContent";
import TabbedForm from "../../../../components/ubold/organisms/TabbedForm";

import Service from "./Tabs/Service";
import RequestLog from "./Tabs/RequestLog";

function CServiceOrderViewPage() {
  let { id }: any = useParams();

  const initialState: any = {
    status: 2,
    rate: null,
    service_images: [],
  };

  const [state, setState] = useState(initialState);

  return (
    <Page
      title={"Detail of" + definitions.title}
      restAccessCode={definitions.restAccessCode}
    >
      <TabbedForm
        id={id}
        state={state}
        setState={setState}
        definitions={definitions}
      >
        <TabHeaders />
        <TabContent>
          <Service />
          <RequestLog />
        </TabContent>
      </TabbedForm>
    </Page>
  );
}

export default withRouter(CServiceOrderViewPage);
