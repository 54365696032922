import { useParams, withRouter } from "react-router-dom";

import TabPaneSecondary from "../../../../../../components/ubold/organisms/TabPaneSecondary";

import COneOrManyToManyField from "../../../../../../components/ubold/molecules/forms/COneOrManyToManyField";

function OrderDetails(props: any) {
  const { definitions, tab, state, setState } = props;

  let { id }: any = useParams();

  return (
    <TabPaneSecondary
      definitions={definitions}
      tab={tab}
      restAccessCodeNamespace="order_detail"
      state={state}
      setState={setState}
      isAddNewBtnDisabled={true}
    >
      <COneOrManyToManyField
        id={id}
        name={tab.id}
        readOnly={true}
        restPath="one_time_purchase/order_detail/"
        saveOnlyFromMainForm={true}
        relationKey="order_id"
        index={1}
        columns={[
          {
            name: "product_text",
            type: "text",
            title: "PRODUCT",
            isReadOnly: true,
          },
          {
            name: "qty",
            type: "text",
            title: "QTY",
            isReadOnly: true,
          },
          {
            name: "product_text",
            type: "text",
            title: "PRODUCT NAME",
            isReadOnly: true,
          },
          {
            name: "price",
            type: "text",
            title: "PRICE",
            isReadOnly: true,
          },
          {
            name: "date_created",
            type: "datetime",
            title: "DATE CREATED",
            isReadOnly: true,
          },
          {
            name: "date_updated",
            type: "datetime",
            title: "DATE UPDATED",
            isReadOnly: true,
          },
        ]}
      />
    </TabPaneSecondary>
  );
}

export default withRouter(OrderDetails);
