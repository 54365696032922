import configBuilder from "components/ubold/ConfigBuilder";
import utils from "utils";

let definitions = {
  title: "Notification Info",
  frontendPath: "/admin/notification/notification_info",
  baseLink: "/notification/notification_inbox",
  restAccessCode: "notification.notification_inbox",
  __table__: {
    hideEditButton: true,
    isHideRemoveButton: true,
    filterColumn: 4,
    filters: [
      {
        id: "title",
        name: "Title",
        type: "text",
      },
      {
        id: "publish",
        name: "By Publish",
        type: "select",
        data: [
          {
            label: "Yes",
            value: true,
          },
          {
            label: "No",
            value: false,
          },
        ],
      },
    ],
    columns: [
      {
        id: "title",
        name: "Title",
        enableHyperlink: true,
      },
      {
        id: "target",
        name: "Target",
        renderer: (value: string) => {
          if (value === "1") {
            return "All User";
          } else if (value === "2") {
            return "Specific Building";
          } else if (value === "3") {
            return "All Tenant";
          } else {
            return "Specific user";
          }
        },
      },
      {
        id: "publish",
        name: "Publish",
        renderer: (value: string) => {
          if (value === "true") {
            return '<span style="color: rgb(26, 188, 156);"> ✔ </span>';
          } else {
            return '<span style="color: red;">✘</span>';
          }
        },
      },
      {
        id: "date_created",
        name: "Date Created",
        renderer: (value: string) => {
          return utils.formatter.dateTime(value);
        },
      },
      {
        id: "date_updated",
        name: "Date Updated",
        renderer: (value: string) => {
          return utils.formatter.dateTime(value);
        },
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
