import styled from "styled-components";

const PageRoomAllotmentBase = styled.div`
  .content-page {
    overflow: unset;
  }

  #wrapper {
    overflow: unset;
  }
`;

export default PageRoomAllotmentBase;
