import { useEffect, useState } from "react";

import { Link, useParams, withRouter } from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import definitions from "./definitions";
import html2canvas from "html2canvas";
import BuildingQR from "./BuildingQR";

function BuildingPrintQR() {
  let { id: buildingId } = useParams();

  const [state, setState] = useState({
    building_name: "",
    slug: "",
  });

  useEffect(() => {
    definitions.onInit(buildingId, setState);
  }, []);

  const onImageDownload = async (id) => {
    const element = document.getElementById(id),
      canvas = await html2canvas(element),
      data = canvas.toDataURL("image/jpg"),
      link = document.createElement("a");

    link.href = data;
    link.download = `QRCode-${state.building_name}.png`;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Page
      title={"QR Code"}
      restAccessCode={definitions.restAccessCode}
    >
      <h1>{state.building_name}</h1>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div style={styles.previewContainer}>
          <BuildingQR slug={state.slug} type="PREVIEW" />
          <BuildingQR slug={state.slug} type="DOWNLOAD" id="QRCode" />
          <div style={styles.buttonContainer}>
            <div
              onClick={() => onImageDownload("QRCode")}
              style={{ ...styles.button, marginRight: 12 }}
            >
              Download
            </div>
            <Link
              to={`/print?slug=${state.slug}&type=BUILDING`}
              target="_blank"
              rel="noopener noreferrer"
              style={styles.button}
            >
              Print
            </Link>
          </div>
        </div>

        <div style={styles.previewContainer}>
          <BuildingQR
            slug={state.slug}
            type="PREVIEW"
            name={state.building_name}
          />
          <BuildingQR
            slug={state.slug}
            type="DOWNLOAD"
            name={state.building_name}
            id="QRCode-WithName"
          />
          <div style={styles.buttonContainer}>
            <div
              onClick={() => onImageDownload("QRCode-WithName")}
              style={{ ...styles.button, marginRight: 12 }}
            >
              Download
            </div>
            <Link
              to={`/print?slug=${state.slug}&type=BUILDING&name=${state.building_name}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ ...styles.button, marginRight: 12 }}
            >
              Print
            </Link>
            <Link
              to={`/print?building=${buildingId}&building_name=${state?.building_name}&type=ROOM&all_rooms=1`}
              target="_blank"
              rel="noopener noreferrer"
              style={styles.button}
            >
              Print All Rooms
            </Link>
          </div>
        </div>
      </div>
    </Page>
  );
}

export default withRouter(BuildingPrintQR);

const styles = {
  previewContainer: {
    width: "fit-content",
    backgroundColor: "#fff",
    border: "1px solid rgb(26, 188, 156)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: "20px 0px",
    padding: "20px",
    borderRadius: 12,
    height: "fit-content",
    marginRight: 40,
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  button: {
    cursor: "pointer",
    color: "#FFF",
    border: "1px solid rgb(26, 188, 156)",
    backgroundColor: "rgb(26, 188, 156)",
    width: "fit-content",
    padding: "4px 12px",
    borderRadius: 4,
    fontWeight: 500,
    marginTop: 24,
  },
};
