import BuildingQR from "pages/Building/Building/BuildingPrintQR/BuildingQR";
import RoomQR from "pages/Building/Room/RoomPrintQR/RoomQR";
import { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import useQuery from "utils/hooks/useQuery";
import getRoomList from "./fetcher/getRoomList";
import { BuildingRoom } from "@types";

function Print() {
  const query = useQuery();
  const type = query.get("type") as
    | "ROOM"
    | "BUILDING"
    | ""
    | undefined;
  const slug = query.get("slug");
  const name = query.get("name");
  const printData = {
    building_name: query.get("building_name"),
    room_variant: query.get("room_variant"),
    name: query.get("name"),
    external_id: query.get("external_id"),
    building: query.get("building"),
    // search params that is only used for case when we want to render multiple QR room based on asset detail
    all_rooms: query.get("all_rooms"),
  };

  const [bulkBuildingPrint, setBulkBuildingPrint] = useState([]);
  const [bulkRoomPrint, setBulkRoomPrint] = useState([]);
  const [isRoomFetched, setIsRoomFetched] = useState(false);

  useEffect(() => {
    if (type === "BUILDING") {
      // check if slug and name are separated by comma
      if (slug && name) {
        const slugs = slug.split(",");
        const names = name.split(",");
        const bulkPrint = [];
        for (let i = 0; i < slugs.length; i++) {
          bulkPrint.push({
            slug: slugs[i],
            name: names[i],
          });
        }
        setBulkBuildingPrint(bulkPrint);
      } else {
        setBulkBuildingPrint([
          {
            slug: slug,
            name: name,
          },
        ]);
      }
    }
  }, [type, slug, name]);

  // Start section of code to handle type ROOM with all_rooms=1
  // we will fetch all rooms for the building and then render all rooms as QR code
  useEffect(() => {
    async function fetchAllRooms() {
      if (printData?.all_rooms === "1") {
        let allRooms: BuildingRoom[] = [];
        let page = 1;
        let hasMore = true;

        while (hasMore) {
          const data = await getRoomList({
            building: !Number.isNaN(Number(printData?.building))
              ? Number(printData?.building ?? 0)
              : 0,
            building_name: printData?.building_name ?? "",
            page: page,
            limit: 50,
          });

          const rooms = data?.data?.data?.rows.map((room) => ({
            ...room,
            building: printData?.building ?? 0,
          }));
          const totalRows = data?.data?.data?.count;

          if (rooms) {
            allRooms = [...allRooms, ...rooms];
          }

          hasMore = allRooms.length < totalRows;
          page++;
        }

        setIsRoomFetched(true);
        setBulkRoomPrint(allRooms);
      }
    }

    fetchAllRooms();
  }, [printData?.all_rooms, printData?.building]);

  if (type) {
    switch (type) {
      case "ROOM":
        // separate logic block for case render all rooms
        if (printData?.all_rooms === "1") {
          if (!printData?.building) {
            return <Redirect to="/" />;
          }
        } else {
          if (
            !printData.building_name ||
            !printData.room_variant ||
            !printData.name ||
            !printData.external_id
          ) {
            return <Redirect to="/" />;
          }
        }
        break;
      case "BUILDING":
        if (!slug) {
          return <Redirect to="/" />;
        }
        break;
      default:
        return <Redirect to="/" />;
    }
  } else {
    return <Redirect to="/" />;
  }

  const QRElement = () => {
    if (!type) return <div />;

    const commonGridStyle = {
      display: "grid",
      gridGap: 33,
      justifyItems: "center",
      alignItems: "center",
      padding: "50px 0",
    };

    const loadingIndicator = (
      <div
        style={{
          position: "fixed",
          top: "50%",
          left: "45%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          className="spinner-border m-1"
          style={{
            width: "15px",
            height: "15px",
            position: "relative",
          }}
          role="status"
        >
          <span className="sr-only">
            Generating All Room QR on{" "}
            {printData?.building_name ?? "this building"}
          </span>
        </div>
        <div className="text">
          Generating All Room QR on{" "}
          {printData?.building_name ?? "this building"}
        </div>
      </div>
    );

    switch (type) {
      case "ROOM":
        if (printData?.all_rooms === "1") {
          if (!isRoomFetched) return loadingIndicator;
          if (bulkRoomPrint.length === 0)
            return <div>This building does not have room</div>;
        }

        return (
          <>
            {bulkRoomPrint.length > 0 ? (
              bulkRoomPrint.map((room, index) => (
                <RoomQR
                  key={index}
                  roomData={room}
                  type="PRINT"
                  isUsePageBreak={index < bulkRoomPrint.length - 1}
                />
              ))
            ) : (
              <RoomQR roomData={printData} type="PRINT" />
            )}
          </>
        );
      case "BUILDING":
        return (
          <div
            style={{
              ...commonGridStyle,
              gridTemplateColumns: "repeat(3, 1fr)",
            }}
          >
            {bulkBuildingPrint.length > 0 ? (
              bulkBuildingPrint.map((building, index) => (
                <BuildingQR
                  key={index}
                  slug={building.slug}
                  type="PRINT"
                  name={building.name}
                />
              ))
            ) : (
              <BuildingQR
                slug={slug}
                type="PRINT"
                name={name ?? ""}
              />
            )}
          </div>
        );
      default:
        return <div />;
    }
  };

  return <div>{QRElement()}</div>;
}

export default Print;
