import configBuilder from "../../../components/ubold/ConfigBuilder";

let definitions = {
  title: "Payment Plans",
  frontendPath: "/admin/finance/payment_plan",
  baseLink: "/finance/payment_plan",
  restAccessCode: "finance.payment_plan",
  __table__: {
    hideEditButton: true,
    removeIdentifier: (value) => {
      return value.plan + " Month(s)";
    },
    filters: [
      {
        id: "plan",
        name: "Plan",
        type: "select",
        data: [
          {
            label: "1",
            value: 1,
          },
          {
            label: "2",
            value: 2,
          },
          {
            label: "3",
            value: 3,
          },
          {
            label: "4",
            value: 4,
          },
          {
            label: ">=5",
            value: ">5",
          },
        ],
      },
      {
        id: "full_paid",
        name: "Full Paid",
        type: "select",
        data: [
          {
            label: "Yes",
            value: true,
          },
          {
            label: "No",
            value: false,
          },
        ],
      },
    ],
    columns: [
      {
        id: "plan",
        name: "Plan",
        renderer: (value) => {
          return value + " Month(s)";
        },
        enableHyperlink: true,
      },
      {
        id: "discount_amount",
        name: "Discount (%)",
        renderer: (value) => {
          return value + "%";
        },
      },
      {
        id: "full_paid",
        name: "Full Paid",
        isSafeContent: true,
        renderer: (value) => {
          if (value === "true") {
            return '<span style="color: rgb(26, 188, 156);"> ✔ </span>';
          } else {
            return '<span style="color: red;">✘</span>';
          }
        },
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
