import { useParams, withRouter } from "react-router-dom";

import TabPaneSecondary from "../../../../../../components/ubold/organisms/TabPaneSecondary";

import COneOrManyToManyField from "../../../../../../components/ubold/molecules/forms/COneOrManyToManyField";

function BuildingPointOfInterest(props) {
  const { definitions, tab, state, setState } = props;

  let { id } = useParams();

  return (
    <TabPaneSecondary
      definitions={definitions}
      tab={tab}
      restAccessCodeNamespace="point_of_interest"
      state={state}
      setState={setState}
    >
      <COneOrManyToManyField
        id={id}
        name={tab.id}
        restPath="building/building__point_of_interest/"
        relationKey="building_id"
        readOnly
        columns={[
          {
            name: "point_of_interest",
            type: "async_select",
            title: "Point of Interest",
            isRequired: true,
            disabledAtEdit: true,
            isUniqueOption: true,
            data: "building/point_of_interest?__type__=select_entries",
          },
          {
            name: "distance",
            type: "number",
            width: 250,
            isRequired: true,
            title: "Distance",
            disabledAtEdit: true,
          },
        ]}
      />
    </TabPaneSecondary>
  );
}

export default withRouter(BuildingPointOfInterest);
