import configBuilder from "../../../components/ubold/ConfigBuilder";

import utils from "../../../utils";

let definitions = {
  title: "Grades",
  frontendPath: "/building/grade",
  baseLink: "/building/grade",
  restAccessCode: "building.building_grade",
  __table__: {
    filterColumn: 3,
    hideEditButton: true,
    filters: [
      {
        id: "name",
        name: "Grade",
        type: "text",
      },
      {
        id: "date_created",
        name: "Date Created",
        type: "date",
      },
      {
        id: "date_updated",
        name: "Date Updated",
        type: "date",
      },
    ],
    columns: [
      {
        id: "name",
        name: "Grade",
        enableHyperlink: true,
      },
      {
        id: "date_created",
        name: "Date Created",
        renderer: (value) => {
          return utils.formatter.dateTime(value);
        },
      },
      {
        id: "date_updated",
        name: "Date Updated",
        renderer: (value) => {
          return utils.formatter.dateTime(value);
        },
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
