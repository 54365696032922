import { useState } from "react";

import { useParams, withRouter } from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import Form from "../../../../components/ubold/organisms/Form";

import definitions from "./definitions";
import CVTextField from "../../../../components/ubold/molecules/forms/CVTextField";
import CVDateTimePicker from "../../../../components/ubold/molecules/forms/CVDateTimePicker";
import CVSelect from "../../../../components/ubold/molecules/forms/CVSelect";
import CVTextArea from "../../../../components/ubold/molecules/forms/CVTextArea";
import CVSimpleManyToMany from "../../../../components/ubold/molecules/forms/CVSimpleManyToMany";

function CBuildingLandlordViewPage() {
  let { id }: any = useParams();

  const initialState = {
    roles: 1,
  };

  const [state, setState] = useState(initialState);

  return (
    <Page
      title={"Detail Of" + definitions.title}
      restAccessCode={definitions.restAccessCode}
    >
      <Form
        id={id}
        definitions={definitions}
        state={state}
        setState={setState}
        readOnly
      >
        <CVTextField
          name="name"
          title="Name"
          maxLength={80}
          isRequired
          readOnly
        />
        <CVTextField
          name="country_code"
          title="Country Code"
          maxLength={2}
          type="number"
          readOnly
        />
        <CVTextField
          name="phone_number"
          title="Phone Number"
          maxLength={20}
          type="number"
          readOnly
        />
        <CVTextField
          name="email"
          title="Email"
          maxLength={254}
          readOnly
        />
        <CVTextField
          name="address"
          title="Address"
          maxLength={100}
          readOnly
        />
        <CVTextField
          name="id_number"
          title="ID Number"
          maxLength={20}
          type="number"
          readOnly
        />
        <CVDateTimePicker
          name="dob"
          title="DoB"
          type="date"
          readOnly
        />
        <CVSelect
          name="roles"
          title="Role"
          isRequired
          data={[
            {
              label: "Agent",
              value: 1,
            },
            {
              label: "Owner",
              value: 2,
            },
            {
              label: "Manager",
              value: 3,
            },
          ]}
          readOnly
        />
        <CVTextField
          name="organisation"
          title="Organisation"
          maxLength={50}
          readOnly
        />
        <CVTextField
          name="bank"
          title="Bank"
          maxLength={30}
          isRequired
          readOnly
        />
        <CVTextField
          name="bank_account_name"
          title="Bank Account Name"
          maxLength={80}
          isRequired
          readOnly
        />
        <CVTextField
          name="bank_account_number"
          title="Bank Account Number"
          maxLength={20}
          isRequired
          type="number"
          readOnly
        />
        <CVTextArea name="notes" title="Notes" readOnly />
        <CVSimpleManyToMany
          name="units"
          title="Units"
          data="building/room_variant?__type__=select_entries"
          readOnly
        />
        <CVTextField
          name="npwp"
          title="NPWP"
          maxLength={16}
          readOnly
        />
      </Form>
    </Page>
  );
}

export default withRouter(CBuildingLandlordViewPage);
