import styled from "styled-components/macro";

export const DefaultNavMenuContainer = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-rows: repeat(2, 1fr);
`;

export const Divider = styled.div`
  display: grid;
  grid-template-columns: 312px 1fr;
`;

export const LeftPanelContainer = styled.div`
  height: fit-content;
  background: #ffffff;
  padding: 24px;
  border-radius: 4px;
  margin-right: 24px;
`;

export const NavigationMenus = styled.div`
  display: flex;
  margin-top: 16px;
  flex-direction: column;
  font-weight: 400;
  color: #222222;
  font-size: 14px;
`;

export const RightPanelContainer = styled.div`
  width: 100%;
  border-radius: 4px;
`;
