import { useParams, withRouter } from "react-router-dom";
import TabPaneSecondary from "../../../../../../components/ubold/organisms/TabPaneSecondary";
import COneOrManyToManyField from "../../../../../../components/ubold/molecules/forms/COneOrManyToManyField";
import styled from "styled-components";
import useGlobalState from "state";

const BuildingDiscountPromoTab = (props) => {
  const { definitions, tab, state, setState } = props;
  const [accesses] = useGlobalState("accesses");
  let { id } = useParams();

  const discountNameRenderer = (discountName, { rowData }) => {
    let accessType = "view";
    if (
      accesses.includes("promotion.discount_master.update") ||
      accesses.includes("promotion.discount_master.create")
    ) {
      accessType = "edit";
    }
    const discountURL = `/marketing/promotion/discount_master/${accessType}/${rowData?.id}`;
    return (
      <StyledAnchorTag
        href={discountURL}
        target="_blank"
        title="Click to see detail"
      >
        {discountName}
      </StyledAnchorTag>
    );
  };

  return (
    <TabPaneSecondary
      definitions={definitions}
      tab={tab}
      restAccessCodeNamespace="building.building_discount"
      state={state}
      setState={setState}
    >
      <COneOrManyToManyField
        id={id}
        name={tab?.id}
        restPath="building/discount_master_building/"
        relationKey="building_id"
        readOnly
        columns={[
          {
            name: "category",
            type: "select",
            title: "Category",
            data: [
              { label: "Discount", value: 1 },
              { label: "Promo", value: 2 },
            ],
            isDisabled: true,
            width: "150",
          },
          {
            name: "name",
            type: "read_only",
            title: "Discount Title",
            isDisabled: true,
            renderer: discountNameRenderer,
          },
          {
            name: "start_date",
            type: "text",
            title: "Period - Start Date",
            isDisabled: true,
            width: "150",
          },
          {
            name: "end_date",
            type: "text",
            title: "Period - End Date",
            isDisabled: true,
            width: "150",
          },
          {
            name: "one_time",
            type: "checkbox",
            title: "One Time",
            isDisabled: true,
            width: "100",
          },
          {
            name: "show",
            type: "checkbox",
            title: "Show",
            isDisabled: true,
            width: "100",
          },
          {
            name: "active",
            type: "checkbox",
            title: "Active",
            isDisabled: true,
            width: "100",
          },
          {
            name: "minimum_monthly_commitment",
            type: "text",
            title: "Minimum Monthly Commitment",
            isDisabled: true,
          },
        ]}
      />
    </TabPaneSecondary>
  );
};

const StyledAnchorTag = styled.a`
  display: flex;
  margin-top: 8px;
`;

export default withRouter(BuildingDiscountPromoTab);
