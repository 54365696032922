const validateFilterValue = (
  state: any,
  setState: Function,
  filterValidator: any
) => {
  const errorMessage: any = {};
  let hasErrors = false;

  for (const [key, value] of Object.entries(filterValidator)) {
    const hasValue: any = value;
    const result = hasValue(state[key], state);

    if (result !== true) {
      errorMessage[key + "Error"] = result;
      hasErrors = true;
    } else {
      errorMessage[key + "Error"] = null;
    }
  }
  setState((prevState: any) => ({
    ...prevState,
    ...errorMessage,
  }));
  return !hasErrors;
};

export default validateFilterValue;
