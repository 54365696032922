import { useState } from "react";

import { useParams, withRouter } from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import definitions from "./definitions";

import TabHeaders from "../../../../components/ubold/organisms/TabHeaders";
import TabContent from "../../../../components/ubold/organisms/TabContent";
import TabbedForm from "../../../../components/ubold/organisms/TabbedForm";

import Addon from "./Tabs/Addon";
import AddonBuilding from "./Tabs/AddonBuilding";

function CAddEditPage() {
  let { id } = useParams();

  const initialState = {
    type: 1,
    purchase_type: "SUBSCRIPTION",
  };

  const [state, setState] = useState(initialState);

  return (
    <Page
      title={
        (id === undefined ? "Add New" : "Edit") +
        " " +
        definitions.title
      }
      restAccessCode={definitions.restAccessCode}
    >
      <TabbedForm
        id={id}
        state={state}
        setState={setState}
        definitions={definitions}
      >
        <TabHeaders />
        <TabContent>
          <Addon />
          <AddonBuilding />
        </TabContent>
      </TabbedForm>
    </Page>
  );
}

export default withRouter(CAddEditPage);
