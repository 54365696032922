import { HTMLAttributes, useEffect, useState } from "react";
import useGlobalState from "../../../../../state";
import BreadcrumbBase from "./Breadcrumb.css";
import { useLocation, useParams } from "react-router-dom";

interface EntryObject {
  id?: string;
  icon: string;
  exact?: boolean;
  link?: string;
  name?: string;
  parent?: string;
  entries?: EntryObject[];
}

type BreadcrumbObject = {
  link: string | undefined;
  parent: string | undefined;
  name: string | undefined;
};

function search(
  nodes: EntryObject[],
  value: string
): EntryObject[] | undefined {
  let result;

  nodes.forEach((o: EntryObject) => {
    let entries;

    if (o.link === value) {
      return (result = o);
    }

    if (o.entries && (entries = search(o.entries, value))) {
      return (result = Object.assign({}, o, { entries }));
    }
  });

  return result && [result];
}

interface IBreadcrumbProps extends HTMLAttributes<HTMLElement> {
  pageDetailTitleId: string;
  state: { [key: string]: string };
}

const Breadcrumb: React.FC<IBreadcrumbProps> = ({
  className,
  pageDetailTitleId,
  state,
}) => {
  const [menus] = useGlobalState("menus" as any);
  // const [menus]: readonly [{entries: Tree[]} | null, (u: SetStateAction<null>) => void] = useGlobalState("menus");
  const [pathBreadcrumb, setPathBreadcrumb] = useState<
    Array<BreadcrumbObject> | undefined
  >([]);
  const [pageDetailTitle, setPageDetailTitle] = useState<
    string | null
  >(null);

  let params = useParams();
  let { pathname } = useLocation();

  const urlParamLength = Object.keys(params).length;

  const generatePathname = () => {
    if (pathname[pathname.length - 1] === "/") {
      return pathname.slice(0, pathname.length - 1);
    }

    return pathname;
  };

  const getDetailBasePathMenu = () => {
    let stringToDelete: Array<string | unknown> = [];

    Object.entries(params).map((string) => {
      stringToDelete.push(string[1]);
    });

    if (stringToDelete[1]) {
      // If 'edit' and have id slug
      const pathDetail = stringToDelete.toString().replace(",", "/");

      return pathname.replace(`/${pathDetail}`, "");
    } else {
      // If 'add' and doesn't have id slug
      return pathname.replace(
        `/${stringToDelete
          .filter((string) => string !== undefined)
          .toString()}`,
        ""
      );
    }
  };

  useEffect(() => {
    // If the type of page is Add / Edit (listen to URL param)
    if (urlParamLength > 0) {
      if (pageDetailTitleId && state[pageDetailTitleId]) {
        setPageDetailTitle(state[pageDetailTitleId]);
      }
    }
  }, [state]);

  useEffect(() => {
    if (
      urlParamLength === 0 &&
      menus?.entries?.length > 0 &&
      menus?.entries[0]?.entries?.length > 0
    ) {
      // If the URL have no parameter
      const treeBreadcrumbPath: EntryObject[] | undefined = search(
        menus?.entries[0]?.entries,
        generatePathname()
      ); // 2nd param example: "/building/property_list"

      const flat: (Tree: EntryObject) => Array<BreadcrumbObject> = ({
        link,
        name,
        parent,
        entries = [],
      }) => [{ link, name, parent }, ...entries?.flatMap(flat)];

      const pathsBreadcrumbFlatResult =
        treeBreadcrumbPath?.flatMap(flat);

      setPathBreadcrumb(pathsBreadcrumbFlatResult);
    } else if (
      urlParamLength > 0 &&
      menus?.entries?.length > 0 &&
      menus?.entries[0]?.entries?.length > 0
    ) {
      // If the URL have a parameter like: /add or /edit/:id
      const pathDetail = getDetailBasePathMenu();

      const treeBreadcrumbPath = search(
        menus?.entries[0]?.entries,
        pathDetail
      ); // 2nd param example: "/building/property_list"

      const flat: (Tree: EntryObject) => Array<BreadcrumbObject> = ({
        link,
        name,
        parent,
        entries = [],
      }) => [{ link, name, parent }, ...entries?.flatMap(flat)];

      const pathsBreadcrumbFlatResult =
        treeBreadcrumbPath?.flatMap(flat);

      const currentBreadcrumb =
        pathsBreadcrumbFlatResult?.length > 0
          ? [...pathsBreadcrumbFlatResult]
          : [];
      const detailPath = [
        {
          link: pathname,
          name: pageDetailTitle,
          parent: pathsBreadcrumbFlatResult?.length
            ? pathsBreadcrumbFlatResult[
                pathsBreadcrumbFlatResult?.length - 1
              ]?.name
            : null,
        },
      ];

      const concated = Array.prototype.concat(
        ...currentBreadcrumb,
        ...detailPath
      );

      setPathBreadcrumb(concated);
    }
  }, [menus?.entries, pageDetailTitle]);

  const renderLinks = () => {
    if (pathBreadcrumb?.length > 0) {
      const links = pathBreadcrumb?.filter(
        (item) => item?.name !== null
      );

      return links.map((item, itemIndex) => {
        return (
          <li key={item?.link}>
            <span>{item?.name}</span>{" "}
            {itemIndex !== links?.length - 1 && (
              <i className="fa fa-chevron-right" />
            )}
          </li>
        );
      });
    }
  };

  return (
    <BreadcrumbBase className={className}>
      <ul>{renderLinks()}</ul>
    </BreadcrumbBase>
  );
};

export default Breadcrumb;
