import styled from "styled-components";

const PageRoomCC = styled.div`
  .content-page {
    overflow: unset;
  }

  #wrapper {
    overflow: unset;
  }
`;

export default PageRoomCC;
