import styled, { css } from "styled-components/macro";

interface NavItemProps {
  id?: string;
  imgSrc: string;
  imgSrcActive: string;
  title: string;
  useIcon?: boolean;
  isActive?: boolean;
  onClick?: (id: string) => void;
}

const arrowSrc = "/assets/icons/arrow.svg";
const arrowSrcActive = "/assets/icons/arrow-active.svg";

const NavItem: React.FC<NavItemProps> = (props) => {
  const {
    id,
    imgSrc,
    imgSrcActive,
    title,
    useIcon = true,
    isActive = false,
    onClick,
  } = props;

  return (
    <Container
      className={isActive ? "active" : ""}
      onClick={() => onClick(id)}
      src={imgSrcActive}
      tabIndex={0}
      isActive={isActive}
    >
      <img
        src={imgSrc}
        alt="icon"
        width="16px"
        height="16px"
        style={{ marginRight: 16 }}
        className="img-action"
      />
      <TitleStyled
        className={isActive ? "active" : ""}
        src={arrowSrcActive}
      >
        {title}
        {useIcon && (
          <img src={arrowSrc} width="5px" height="10px" alt="arrow" />
        )}
      </TitleStyled>
    </Container>
  );
};

export default NavItem;

const Container = styled.div<{
  src?: string;
  isActive?: boolean;
}>`
  min-height: 44px;
  padding: 12px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #222222;
  transition: color 0.3s, font-weight 0.3s, background 0.3s ease-out;
  cursor: pointer;

  :hover {
    background: #fafafa;
    color: #00bbb4;
    font-weight: 500;
    ${({ src }) =>
      src &&
      css`
        .img-action {
          content: url(${src});
        }
      `}
  }
  &.active {
    background: #d9efe5;
    color: #00bbb4;
    font-weight: 500;
    ${({ src }) =>
      src &&
      css`
        .img-action {
          content: url(${src});
        }
      `}
    pointer-events: none;
  }
  :focus {
    background: ${({ isActive }) =>
      isActive ? "#d9efe5" : "#fafafa"};
    color: #00bbb4;
    border: 3px solid rgba(0, 187, 180, 0.2);
    ${({ src }) =>
      src &&
      css`
        .img-action {
          content: url(${src});
        }
      `}
    outline: none;
  }
`;

const TitleStyled = styled.div<{
  src?: string;
}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  :hover {
    ${({ src }) =>
      src &&
      css`
        img {
          content: url(${src});
        }
      `}
  }

  &.active {
    ${({ src }) =>
      src &&
      css`
        img {
          content: url(${src});
        }
      `}
  }
  :focus {
    ${({ src }) =>
      src &&
      css`
        img {
          content: url(${src});
        }
      `}
  }
`;
