import configBuilder from "../../../../components/ubold/ConfigBuilder";

import pageDefinitions from "../definitions";
import utils from "../../../../utils";

const definitions = {
  ...pageDefinitions,
  validators: {
    name: (value) => {
      return utils.validator.validateRequired(value);
    },
    name_en: (value) => {
      return utils.validator.validateRequired(value);
    },
    country: (value) => {
      return utils.validator.validateRequired(value);
    },
    province: (value) => {
      return utils.validator.validateRequired(value);
    },
    category: (value) => {
      return utils.validator.validateRequired(value);
    },
    city: (value) => {
      return utils.validator.validateRequired(value);
    },
    district: (value) => {
      return utils.validator.validateRequired(value);
    },
    subdistrict: (value) => {
      return utils.validator.validateRequired(value);
    },
    address: (value) => {
      return utils.validator.validateRequired(value);
    },
    lat: (value) => {
      return utils.validator.validateRequired(value);
    },
    lon: (value) => {
      return utils.validator.validateRequired(value);
    },
    tag: (value) => {
      if (!value) return true;

      // only validate if there is whitespace after comma
      if (/,\s/g.test(value)) {
        return "please remove space after comma separation!";
      } else {
        return true;
      }
    },
  },
};

export default configBuilder.buildAddEditPageConfig(definitions);
