import React from "react";
import Page from "../../../../components/ubold/organisms/Page";
import TableHistory from "../../../../components/ubold/organisms/TableHistory/TableHistory";

import definitions from "./definitions";

const HelpServicesHistory = () => {
  return (
    <Page title={"Help & Services History"}>
      <TableHistory {...definitions} />
    </Page>
  );
};

export default HelpServicesHistory;
