import { useEffect, useState } from "react";
import { withRouter } from "react-router";
import TabPane from "components/ubold/organisms/TabPane";
import TabPaneSection from "components/ubold/organisms/TabPaneSection";
import CHTextView from "components/ubold/molecules/forms/CHTextView";
import CHTextField from "components/ubold/molecules/forms/CHTextField";
import CHSelect from "components/ubold/molecules/forms/CHSelect";
import axios from "axios";
import config from "configs";
import utils from "utils";
import HorizontalWrapper from "components/ubold/atoms/display/HorizontalWrapper";
import CSelectV3 from "components/ubold/atoms/forms/CSelectV3";
import _ from "lodash";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MonthlyReportTab = (props: any) => {
  const { id, definitions, tab, state, setState } = props;

  const [buildingPage, setBuildingPage] = useState<number>(1);
  const [selectedBuilding, setSelectedBuilding] = useState(null);
  const [buildingOptions, setBuildingOptions] = useState<any>([]);
  const [buildingKeyword, setBuildingKeyword] = useState<string>("");
  const [loadingBuilding, setLoadingBuilding] =
    useState<boolean>(false);

  const [roomVariantOptions, setRoomVariantOptions] = useState([]);
  const [selectedRoomVariant, setSelectedRoomVariant] =
    useState(null);
  const [loadingRoomVariant, setLoadingRoomVariant] =
    useState<boolean>(false);
  const monthData = [
    {
      label: "Januari",
      value: 1,
    },
    {
      label: "Februari",
      value: 2,
    },
    {
      label: "Maret",
      value: 3,
    },
    {
      label: "April",
      value: 4,
    },
    {
      label: "Mei",
      value: 5,
    },
    {
      label: "Juni",
      value: 6,
    },
    {
      label: "Juli",
      value: 7,
    },
    {
      label: "Agustus",
      value: 8,
    },
    {
      label: "September",
      value: 9,
    },
    {
      label: "October",
      value: 10,
    },
    {
      label: "November",
      value: 11,
    },
    {
      label: "Desember",
      value: 12,
    },
  ];
  const sweetAlert = withReactContent(Swal);
  const isBuildingCCWithNoEmail =
    state?.building_type === 1 &&
    state?.building_object &&
    !state?.building_object?.landlord_email;
  const isBuildingDCWithNoEmail =
    state?.building_type === 2 &&
    state?.room_variant_object &&
    !state?.room_variant_object?.landlord_email;

  const handleOnChangeBuilding = (newValue: any) => {
    setState((prevState: any) => ({
      ...prevState,
      building: newValue.id,
      building_type: newValue.building_type,
      building_object: newValue,
    }));
    setSelectedBuilding(newValue);
  };

  const handleOnChangeRoomVariant = (newValue: any) => {
    setState((prevState: any) => ({
      ...prevState,
      room_variant: newValue.id,
      room_variant_object: newValue,
    }));
    setSelectedRoomVariant(newValue);
  };

  const handleOnInputBuildingChange = (newValue: string) => {
    setBuildingKeyword(newValue);
    setBuildingPage(1);
  };

  const handleOnScrollBuildingMenuToBottom = () => {
    const newPage = buildingPage + 1;
    setBuildingPage(newPage);
    getBuildingOptions(newPage);
  };

  const getBuildingOptions = async (page?: number) => {
    try {
      setLoadingBuilding(true);
      // rtype=with_landlord_info <-- this params is to receive landlord id and email from building response
      const { data } = await axios.get(
        `${
          config.apiUrl
        }building/building/?__type__=select_entries&rtype=with_landlord_info&page=${
          page ?? 1
        }&limit=10${
          buildingKeyword ? `&keyword=${buildingKeyword}` : ""
        }`,
        utils.httpClient._getConfig()
      );
      setBuildingOptions(
        (buildingOptions ?? []).concat(
          [...data.data.rows].map((building: any) => ({
            ...building,
            label: building.building_name,
            value: building.id,
          })) ?? []
        )
      );
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingBuilding(false);
    }
  };

  const getRoomVariantOptions = async (
    buildingID: string | number
  ) => {
    try {
      setLoadingRoomVariant(true);
      const { data } = await axios.get(
        `${config.apiUrl}building/room_variant/?__type__=select_entries&rtype=with_landlord_info&building_id=${buildingID}`,
        utils.httpClient._getConfig()
      );
      setRoomVariantOptions(
        [...data.data.rows].map((roomVar: any) => ({
          ...roomVar,
          label: roomVar.name,
          value: roomVar.id,
        })) ?? []
      );
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingRoomVariant(false);
    }
  };

  useEffect(() => {
    if (state?.building_type !== 2) return;
    getRoomVariantOptions(state?.building);
  }, [state?.building_type, state?.building]);

  useEffect(() => {
    if (state?.building_type === 1 && state?.room_variant) {
      setState((prevState: any) => ({
        ...prevState,
        room_variant: null,
        room_variant_object: null,
      }));
      setSelectedRoomVariant(null);
    }
  }, [state?.building_type]);

  useEffect(() => {
    if (!buildingKeyword) {
      return setBuildingOptions([]);
    }
    const debounced = _.debounce(() => getBuildingOptions(), 500);
    debounced();

    return () => {
      debounced.cancel();
    };
  }, [buildingKeyword]);

  useEffect(() => {
    if (id) return;
    let landlord_name_email = "";
    if (isBuildingCCWithNoEmail || isBuildingDCWithNoEmail) {
      // triggerring sweeatalert if no landlord email is null or undefined
      const url = `/landlord/building_landlord/edit/${
        isBuildingCCWithNoEmail
          ? state?.building_object?.landlord_id
          : state?.room_variant_object?.landlord_id
      }`;
      sweetAlert.close();
      sweetAlert.fire({
        title: "Landlord Email Is Empty",
        html: (
          <div>
            <p>
              Please fill landlord email first before saving the
              monthly report request. You can navigate to landlord
              detail by clicking landlord name below.
            </p>
            <a href={url} target="_blank" rel="noreferrer">
              {isBuildingCCWithNoEmail
                ? `${
                    state?.building_object?.landlord_name ??
                    state?.building_object?.label
                  }`
                : `${
                    state?.room_variant_object?.landlord_name ??
                    state?.room_variant_object?.label
                  }`}
            </a>
          </div>
        ),
      });
    }
    if (
      state?.building_type === 1 &&
      state?.building_object?.landlord_name &&
      state?.building_object?.landlord_email
    ) {
      landlord_name_email = `${state?.building_object?.landlord_name} - ${state?.building_object?.landlord_email}`;
    }
    if (
      state?.building_type === 2 &&
      state?.room_variant_object?.landlord_name &&
      state?.room_variant_object?.landlord_email
    ) {
      landlord_name_email = `${state?.room_variant_object?.landlord_name} - ${state?.room_variant_object?.landlord_email}`;
    }
    setState((prevState: any) => ({
      ...prevState,
      landlord_name_email,
    }));
  }, [state?.building, state?.room_variant, id]);

  useEffect(() => {
    if (state["year"]) return;
    const getCurrentYear = new Date().getFullYear();

    setState((prevState: any) => ({
      ...prevState,
      year: getCurrentYear,
    }));
  }, [state["year"]]);

  useEffect(() => {
    if (!id) return;
    setSelectedBuilding({
      value: state?.original___building,
      label: state?.original___building_name,
    });
    if (state?.original___building_type === 2) {
      setSelectedRoomVariant({
        value: state?.original___room_variant,
        label: state?.original___room_variant_name,
      });
    }
  }, [id, state?.building]);

  return (
    <TabPane
      id={id}
      definitions={definitions}
      tab={tab}
      state={state}
      setState={setState}
      isNotAbleToSave={
        isBuildingCCWithNoEmail || isBuildingDCWithNoEmail
      }
    >
      <TabPaneSection style={{ padding: "25px 16px 0px" }}>
        <HorizontalWrapper
          title="Building"
          errorMessage={state?.buildingError ?? ""}
          isRequired
        >
          <CSelectV3
            options={buildingOptions}
            handleOnChange={handleOnChangeBuilding}
            value={selectedBuilding}
            handleOnInputChange={handleOnInputBuildingChange}
            onMenuScrollToBottom={handleOnScrollBuildingMenuToBottom}
            inputValue={buildingKeyword}
            readOnly={id}
            isSearchable
            isLoading={loadingBuilding}
          />
        </HorizontalWrapper>
        {state?.building_type === 2 ? (
          <HorizontalWrapper
            title="Room Variants"
            errorMessage={state?.room_variantError ?? ""}
            isRequired
          >
            <CSelectV3
              options={roomVariantOptions}
              handleOnChange={handleOnChangeRoomVariant}
              value={selectedRoomVariant}
              readOnly={id}
              isLoading={loadingRoomVariant}
            />
          </HorizontalWrapper>
        ) : (
          <></>
        )}
        <CHTextView
          name="landlord_name_email"
          title="Landlord Name & Email"
        />
        <CHSelect
          name="month"
          title="Month"
          readOnly={id && true}
          data={monthData}
          isRequired
        />
        <CHTextField
          name="year"
          title="Year"
          type="number"
          min={0}
          readOnly={id && true}
          isRequired
        />
        <CHTextView
          name="email_fmr_1"
          title="Additional email from Building(1)"
        />
        <CHTextView
          name="email_fmr_2"
          title="Additional email from Building(2)"
        />
      </TabPaneSection>
    </TabPane>
  );
};

export default withRouter(MonthlyReportTab);
