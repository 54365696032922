import React, { useEffect, useState } from "react";
import { useParams, withRouter } from "react-router-dom";

import AccordionPane from "components/ubold/organisms/AccordionPane";

import CHSelect from "components/ubold/molecules/forms/CHSelect";
import CHAsyncSelect from "components/ubold/molecules/forms/CHAsyncSelect";
import CHDateTimePicker from "components/ubold/molecules/forms/CHDateTimePicker";
import CHTextArea from "components/ubold/molecules/forms/CHTextArea";
import CHCheckBox from "components/ubold/molecules/forms/CHCheckBox";

import utils from "utils";
import CHTextField from "components/ubold/molecules/forms/CHTextField";
import useGlobalState from "state";

function Attributes(props: any) {
  const { baseLink, accordionId, onSubmit, state, setState } = props;

  const [constant] = useGlobalState("constant_values");

  const [orderStatusState, setOrderStatus] = useState([]);

  const defaultOrderStatus = constant?.["order_new_statuses"];
  const doneStatus = [5, 6, 10];

  let { id }: { id: string } = useParams();

  useEffect(() => {
    if (!defaultOrderStatus || !state["order_status"]) return;

    const orderStatus = [...defaultOrderStatus];

    let possibleTransition = [5];

    if (id) {
      switch (state?.order_status) {
        case 3:
          possibleTransition = [];
          break;
        case 6:
          possibleTransition = [];
          break;
        case 10:
          possibleTransition = [];
          break;
        default:
          possibleTransition.push(state?.original___order_status);
      }
    } else {
      possibleTransition.push(9);
    }

    const newOrderStatus = orderStatus?.map((obj) => {
      const tempObj = {
        label: obj.label,
        value: obj.value,
        disabled: false,
      };
      if (!possibleTransition?.includes(obj.value)) {
        tempObj.disabled = true;
      }
      return tempObj;
    });

    setOrderStatus(newOrderStatus);
  }, [defaultOrderStatus, state?.order_status, id]);

  return (
    <AccordionPane
      id="rent"
      name="Rent"
      accordionId={accordionId}
      baseLink={baseLink}
      onSubmit={onSubmit}
      shown={
        !!(
          utils.form.isFieldError(state, "status") ||
          utils.form.isFieldError(state, "date_started") ||
          utils.form.isFieldError(state, "date_ended") ||
          utils.form.isFieldError(state, "status_of_done")
        )
      }
      state={state}
      setState={setState}
    >
      <CHSelect
        name="subscription_type"
        title="Subscription Type"
        isRequired
        data={{
          optionField: "order_new_subscription_type",
        }}
        readOnly={true}
      />

      {state["subscription_type"] === 2 ? (
        <CHTextField
          name="monthly_commitment"
          title="Monthly Commitment"
          type="number"
          readOnly={true}
          min={0}
        />
      ) : (
        <React.Fragment />
      )}

      <CHDateTimePicker
        name="checkin_date"
        title="Check-In Date"
        readOnly
        type="date"
        isRequired
      />

      <CHDateTimePicker
        name="checkout_date"
        title="Check-Out Date"
        readOnly
        type="date"
        helperText="<b>Note</b>: Date checkout should be greater than Date Ended."
        isRequired={state?.subscription_type === 2}
      />

      {state?.monthly_commitment === 0 ? (
        <CHCheckBox
          name="short_stay"
          title="Short Stay"
          readOnly={true}
        />
      ) : (
        <React.Fragment />
      )}

      <CHSelect
        name="status_of_done"
        title="Status of Done"
        readOnly
        isRequired
        data={{
          optionField: "order_new_status_of_done",
        }}
      />
      <CHTextArea
        isRequired={state["checkout_date"]}
        name="reason_of_check_out"
        title="Reason of Checkout"
        readOnly
      />
      <CHAsyncSelect
        isRequired={state["checkout_date"]}
        name="checkout_category"
        title="Checkout Category"
        readOnly
        data="order/checkout_category/?__type__=select_entries&limit=10"
      />
      <CHAsyncSelect
        isRequired={state["checkout_date"]}
        name="checkout_reason"
        title="Checkout Reason"
        readOnly
        dependsOn="checkout_category"
        data="order/checkout_reason/?__type__=select_entries&limit=10"
      />

      <CHCheckBox
        name="on_leave_paying"
        title="On Leave Paying?"
        disabled
      />

      <CHSelect
        name="order_status"
        title="Status"
        isRequired
        data={orderStatusState}
        readOnly={
          !id ||
          (id &&
            doneStatus.includes(state["original___order_status"]))
        }
      />

      <CHTextArea
        name="cancellation_remarks"
        title="Cancellation Remarks"
        readOnly={true}
      />

      <CHDateTimePicker
        name="estimated_checkout_date"
        title="Estimated Check-Out Date"
        type="date"
        readOnly
      />

      <CHDateTimePicker
        name="checkin_time"
        title="Check-In Time"
        type="time"
        readOnly
      />
      <CHDateTimePicker
        name="checkout_time"
        title="Check-Out Time"
        type="time"
        readOnly
      />
      <CHCheckBox
        name="confirm_checkout"
        title="Confirm Check-Out"
        disabled
      />
      {/* <CHCheckBox
        name="split_deposit_and_rent"
        title="Split Deposit and Rent"
        disabled
      /> */}

      <CHCheckBox name="no_show" title="No Show" disabled />
    </AccordionPane>
  );
}

export default withRouter(Attributes);
