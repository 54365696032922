import { useState } from "react";

import { useParams, withRouter } from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import Form from "../../../../components/ubold/organisms/Form";

import CVTextField from "../../../../components/ubold/molecules/forms/CVTextField";
import CVAsyncSelect from "../../../../components/ubold/molecules/forms/CVAsyncSelect";

import definitions from "./definitions";

function UTMMediumViewPage() {
  let { id }: { id: string } = useParams();

  const initialState = {};

  const [state, setState] = useState(initialState);

  return (
    <Page
      title={"Detail of " + definitions.title}
      restAccessCode={definitions.restAccessCode}
    >
      <Form
        id={id}
        definitions={definitions}
        readOnly
        state={state}
        setState={setState}
      >
        <CVAsyncSelect
          name="utm_source"
          title="UTM Source"
          isRequired
          overrideLayout="12"
          data="marketing/utm_source?__type__=select_entries"
          itemsExtractor={(row: {
            id: string;
            utm_source: string;
          }) => {
            return {
              label: row.utm_source,
              value: row.id,
            };
          }}
          readOnly
        />
        <CVTextField
          name="utm_medium"
          title="UTM Medium"
          overrideLayout="12"
          maxLength={254}
          isRequired
          readOnly
        />
        <CVTextField
          name="code"
          title="Code"
          overrideLayout="12"
          maxLength={2}
          isRequired
          readOnly
        />
      </Form>
    </Page>
  );
}

export default withRouter(UTMMediumViewPage);
