import { useState, useEffect } from "react";

import { useParams, withRouter } from "react-router-dom";

import axios from "axios";
import configs from "../../../../configs";
import httpClient from "../../../../utils/HttpClient";

import Page from "../../../../components/ubold/organisms/Page";

import Form from "../../../../components/ubold/organisms/Form";

import definitions from "./definitions";
import CVSelect from "../../../../components/ubold/molecules/forms/CVSelect";
import CVAsyncSelect from "../../../../components/ubold/molecules/forms/CVAsyncSelect";
import CVTextArea from "../../../../components/ubold/molecules/forms/CVTextArea";
import CVDateTimePicker from "../../../../components/ubold/molecules/forms/CVDateTimePicker";
import CVTextView from "../../../../components/ubold/molecules/forms/CVTextView";
import utils from "../../../../utils";
import CVTextField from "../../../../components/ubold/molecules/forms/CVTextField";

function CAddonBuildingOrderViewPage() {
  let { id } = useParams();

  const initialState = {
    status: 1,
    source_of_request: 1,
    is_read: false,
  };

  const [state, setState] = useState(initialState);
  const [isParkingAddon, setIsParkingAddon] = useState(false);
  const [selectedAddon, setSelectedAddon] = useState(null);

  const checkAddonBuilding = (checkedValue) => {
    const checkResult = checkedValue
      .toLowerCase()
      .includes("parking");
    return checkResult;
  };

  useEffect(() => {
    if (!selectedAddon) return;

    const result = checkAddonBuilding(selectedAddon);
    setIsParkingAddon(result);
  }, [selectedAddon]);

  useEffect(async () => {
    if (state["addon_building_label"]) {
      setSelectedAddon(state["addon_building_label"]);
    } else {
      if (!state["building"] && !state["addon_building"]) return;

      try {
        const response = await axios.get(
          `${configs.apiUrl}building/addon__building/?__type__=select_entries&building=${state["building"]}&__inclusion__=${state["addon_building"]}`,
          httpClient._getConfig()
        );

        const findLabel = response.data.data.rows.find((obj) => {
          return obj.id === state["addon_building"];
        });
        setSelectedAddon(findLabel.name);
      } catch (error) {
        console.error(error);
      }
    }
  }, [
    state["building"],
    state["addon_building"],
    state["addon_building_label"],
  ]);

  return (
    <Page
      title={"Detail of" + " " + definitions.title}
      restAccessCode={definitions.restAccessCode}
    >
      <Form
        id={id}
        definitions={definitions}
        state={state}
        setState={setState}
        readOnly={true}
      >
        <CVAsyncSelect
          name="order"
          title="Order"
          data="order/order?__type__=select_entries"
          isRequired
          itemsExtractor={(value) => {
            return {
              label: value.number,
              value: value.id,
            };
          }}
          readOnly
        />
        <CVAsyncSelect
          name="addon_building"
          title="Addon Building"
          data={
            "building/addon__building?__type__=select_entries&building=" +
            (state["building"] ? state["building"] : 0)
          }
          itemsExtractor={(row) => {
            return {
              label:
                (row.name ? row.name : row["addon__name"]) +
                (row.payment_plan
                  ? " (" + row.payment_plan + " month)"
                  : " (None Month)") +
                (row.price
                  ? " - " + utils.formatter.currency(row.price)
                  : ""),
              value: row.id,
            };
          }}
          isRequired
          readOnly
        />
        <CVSelect
          name="status"
          title="Status"
          isRequired
          data={[
            {
              label: "Menunggu konfirmasi Rukita",
              value: 1,
            },
            {
              label: "Menunggu pembayaran awal",
              value: 6,
            },
            {
              label: "Memproses pembayaran awal",
              value: 7,
            },
            {
              label: "Pembayaran awal ditolak",
              value: 8,
            },
            {
              label: "Pembayaran awal kurang",
              value: 9,
            },
            {
              label: "Stok barang habis",
              value: 10,
            },
            {
              label: "Pembayaran lewat batas",
              value: 11,
            },
            {
              label: "Menunggu aktivasi",
              value: 5,
            },
            {
              label: "Sedang Berjalan",
              value: 3,
            },
            {
              label: "Unsubscribe",
              value: 12,
            },
            {
              label: "Add-ons sudah di ambil kembali",
              value: 13,
            },
          ]}
          readOnly
        />
        <CVDateTimePicker
          name="start_date"
          title="Start Date"
          isRequired
          type="date"
          readOnly
        />
        <CVDateTimePicker
          name="end_date"
          title="End Date"
          type="date"
          readOnly
        />
        <CVSelect
          name="is_read"
          title="Is Read?"
          data={[
            {
              label: "Yes",
              value: true,
            },
            {
              label: "No",
              value: false,
            },
            {
              label: "Unknown",
              value: null,
            },
          ]}
          readOnly
        />
        <CVSelect
          name="source_of_request"
          title="Source of Request"
          data={[
            {
              label: "Back Office",
              value: 1,
            },
            {
              label: "Mobile APP",
              value: 2,
            },
          ]}
          readOnly
        />
        {isParkingAddon ? (
          <CVTextField
            name="notes"
            title="Nomor Polisi"
            maxLength={15}
            isParkingAddon
            defaultValue={"cth: B 1234 ABC"}
            readOnly
          />
        ) : (
          <CVTextArea name="notes" title="Notes" readOnly />
        )}

        <CVTextView
          name="date_created"
          title="Date Created"
          renderer={(value) => {
            return utils.formatter.dateTime(value);
          }}
        />
        <CVTextView
          name="date_updated"
          title="Date Updated"
          renderer={(value) => {
            return utils.formatter.dateTime(value);
          }}
          readOnly
        />
        <CVTextView name="number" title="Addon ID" readOnly />
      </Form>
    </Page>
  );
}

export default withRouter(CAddonBuildingOrderViewPage);
