import { withRouter } from "react-router-dom";

import TabPane from "../../../../../../components/ubold/organisms/TabPane";
import TabPaneSection from "../../../../../../components/ubold/organisms/TabPaneSection";
import CHSelect from "../../../../../../components/ubold/molecules/forms/CHSelect";
import CHAsyncSelect from "../../../../../../components/ubold/molecules/forms/CHAsyncSelect";
import CHTextField from "../../../../../../components/ubold/molecules/forms/CHTextField";
import CHDateTimePicker from "../../../../../../components/ubold/molecules/forms/CHDateTimePicker";
import CHTextArea from "../../../../../../components/ubold/molecules/forms/CHTextArea";
import CHCheckBox from "../../../../../../components/ubold/molecules/forms/CHCheckBox";

function Agreement(props) {
  const { id, definitions, tab, state, setState } = props;

  return (
    <TabPane
      id={id}
      definitions={definitions}
      tab={tab}
      state={state}
      setState={setState}
    >
      <TabPaneSection style={{ padding: "25px 16px 0px" }}>
        <CHAsyncSelect
          name="building"
          title="Building"
          isRequired
          data="building/building?__type__=select_entries"
          itemsExtractor={(value) => {
            return {
              label:
                value["building_name"] +
                " - " +
                (value["building_type"] === 1
                  ? "Centralized"
                  : "Decentralized"),
              value: value.id,
              building_type: value.building_type,
            };
          }}
          afterChange={(value) => {
            setState((prev) => ({
              ...prev,
              building_type: value.building_type,
            }));
          }}
        />
        {state?.building_type === 2 ? (
          <CHAsyncSelect
            name="room_variant"
            title="Room Variant"
            dependsOn="building"
            data="building/room_variant?__type__=select_entries"
          />
        ) : (
          <></>
        )}
        <CHTextField
          name="collateral_deposit"
          title="Collateral Deposit"
          type="number"
        />
        <CHDateTimePicker
          name="building_effective_date"
          title="Building Effective Date"
          isRequired
          type="date"
        />
        <CHDateTimePicker
          name="building_live_date"
          title="Building Live Date"
          type="date"
          readOnly={state["original___live_date_confirm"]}
        />
        <CHCheckBox
          name="live_date_confirm"
          title="Building Live Date Confirm"
          readOnly={state["original___live_date_confirm"]}
        />
        <CHDateTimePicker
          name="soft_live_date"
          title="Building Soft Live Date"
          type="date"
          readOnly={state["original___soft_live_confirm"]}
        />
        <CHCheckBox
          name="soft_live_confirm"
          title="Building Soft Live Date Confirm"
          readOnly={state["original___soft_live_confirm"]}
        />
        <CHTextArea name="remarks" title="Remarks" />
        <CHSelect
          name="contract_length"
          title="Contract Length"
          data={[
            {
              label: "1 year",
              value: 1,
            },
            {
              label: "2 years",
              value: 2,
            },
            {
              label: "3 years",
              value: 3,
            },
            {
              label: "4 years",
              value: 4,
            },
            {
              label: "5 years",
              value: 5,
            },
            {
              label: "6 years",
              value: 6,
            },
            {
              label: "7 years",
              value: 7,
            },
            {
              label: "8 years",
              value: 8,
            },
            {
              label: "9 years",
              value: 9,
            },
            {
              label: "10 years",
              value: 10,
            },
            {
              label: "11 years",
              value: 11,
            },
            {
              label: "12 years",
              value: 12,
            },
          ]}
        />
        <CHSelect
          name="payment_schedule"
          title="Payment Schedule"
          data={[
            {
              label: "1 month",
              value: 1,
            },
            {
              label: "3 months",
              value: 3,
            },
            {
              label: "6 months",
              value: 6,
            },
            {
              label: "12 months",
              value: 12,
            },
          ]}
        />
        <CHTextField
          name="rental_fee_base_on_the_agreement"
          title="Rental fee base on the agreement (per month)"
          type="number"
        />
        <CHTextField
          name="total_room_count"
          title="Total room count"
          type="number"
        />
        <CHSelect
          name="service_charge"
          title="Service Charge"
          data={[
            {
              label: "Owner Pay",
              value: 1,
            },
            {
              label: "Rukita Pay",
              value: 2,
            },
          ]}
        />
        <CHTextField
          name="total_capex_budget"
          title="Total capex budget"
          type="number"
        />
        <CHTextField
          name="total_actual_renovation_costs"
          title="Total actual renovation costs"
          type="number"
        />
        <CHTextArea name="agreement_url" title="Agreement URL" />
        <CHCheckBox
          name="revenue_sharing"
          title="Is Revenue Sharing"
        />
        <CHTextField
          name="revenue_threshold_amount"
          title="Revenue threshold amount"
          type="number"
        />
        <CHTextField
          name="revenue_sharing_percentage"
          title="Revenue sharing percentage"
          type="number"
        />
        <CHCheckBox name="finished" title="Is Finished" />
      </TabPaneSection>
    </TabPane>
  );
}

export default withRouter(Agreement);
