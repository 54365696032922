import configBuilder from "../../../components/ubold/ConfigBuilder";
import utils from "../../../utils";

let definitions = {
  title: "UTM Contents",
  frontendPath: "/admin/utm/utm_content",
  baseLink: "/marketing/utm_content",
  restAccessCode: "marketing.utm_content",
  __table__: {
    hideEditButton: true,
    filterColumn: 4,
    removeIdentifier: "utm_content",
    filters: [
      {
        id: "utm_content",
        name: "UTM Content",
        type: "text",
      },
      {
        id: "utm_campaign",
        name: "UTM Campaign",
        type: "async_select",
        data: "marketing/utm_campaign?__type__=select_entries",
        itemsExtractor: (row) => {
          return {
            label: row.utm_campaign,
            value: row.id,
          };
        },
      },
      {
        id: "code",
        name: "Code",
        type: "text",
      },
      {
        id: "date_created",
        name: "Date Created",
        type: "date",
      },
    ],
    columns: [
      {
        id: "utm_content",
        name: "UTM Content",
        enableHyperlink: true,
      },
      {
        id: "utm_campaign_name",
        name: "UTM Campaign",
      },
      {
        id: "code",
        name: "Code",
      },
      {
        id: "date_created",
        name: "Date Created",
        renderer: (value) => {
          return utils.formatter.dateTime(value);
        },
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
