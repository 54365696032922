import utils from "../../../utils";

import configBuilder from "../../../components/ubold/ConfigBuilder";

import secondaryDataHandler from "./SecondaryDataHandler";

let definitions = {
  title: "Orders",
  frontendPath: "/sales/order",
  baseLink: "/order/order",
  restAccessCode: "order.order",
  __table__: {
    filterColumn: 3,
    removeIdentifier: "number",
    hasSecondaryData: true,
    secondaryDataRow: 2,
    secondaryDataItem: 13,
    selectedInitialRowsPerPage: 4,
    secondaryDataHandler: secondaryDataHandler,
    hideEditButton: true,
    filters: [
      {
        id: "order",
        name: "Order",
        type: "async_select",
        data: "order/order?__type__=select_entries",
        itemsExtractor: (item) => {
          return {
            label: item.number,
            value: item.id,
          };
        },
      },
      {
        id: "tenant",
        name: "Tenant",
        type: "async_select",
        data: "user/tenant?__type__=select_entries",
        itemsExtractor: (item) => {
          return {
            label: item.name,
            value: item.id,
          };
        },
      },
      {
        id: "building",
        name: "Building Name",
        type: "async_select",
        data: "building/building?__type__=select_entries",
        itemsExtractor: (item) => {
          return {
            label: item.building_name,
            value: item.id,
          };
        },
      },
      {
        id: "status",
        name: "Order Status",
        type: "select",
        data: [
          {
            value: 1,
            label: "Waiting confirm",
          },
          {
            value: 2,
            label: "Processing",
          },
          {
            value: 7,
            label: "Deposit received",
          },
          {
            value: 8,
            label: "Deposit and payment received",
          },
          {
            value: 4,
            label: "Rejected",
          },
          {
            value: 3,
            label: "Ongoing",
          },
          {
            value: 5,
            label: "Cancel",
          },
          {
            value: 6,
            label: "Done",
          },
        ],
      },
      {
        id: "status_of_done",
        name: "Status of Done",
        type: "select",
        data: [
          {
            value: 1,
            label: "Transfer Room",
          },
          {
            value: 2,
            label: "Transfer Building",
          },
          {
            value: 5,
            label: "Finish/Moving Out",
          },
          {
            value: 6,
            label: "Building Terminated",
          },
          {
            value: 7,
            label: "Change Payment Plan",
          },
          {
            value: 8,
            label: "Extend Stay",
          },
        ],
      },
    ],
    columns: [
      {
        id: "number",
        name: "Order Number",
        enableHyperlink: true,
      },
      {
        id: "tenant_name",
        name: "Tenant",
      },
      {
        id: "building_name",
        name: "Building",
        maxWidthColumn: "auto",
        renderer: (value, state) => {
          return `${value} | ${state["room_name"]}`;
        },
      },
      {
        id: "price",
        name: "Price",
        renderer: (value) => {
          return utils.formatter.currency(value);
        },
      },
      {
        id: "status",
        name: "Order Status",
        renderer: (value) => {
          if (value === "1") {
            return "Waiting confirm";
          } else if (value === "2") {
            return "Processing";
          } else if (value === "7") {
            return "Deposit received";
          } else if (value === "8") {
            return "Deposit and payment received";
          } else if (value === "4") {
            return "Rejected";
          } else if (value === "3") {
            return "Ongoing";
          } else if (value === "5") {
            return "Cancel";
          } else if (value === "6") {
            return "Done";
          } else {
            return "Unknown";
          }
        },
      },
      {
        id: "status_of_done",
        name: "Status of Done",
        renderer: (value) => {
          if (value === "1") {
            return "Transfer Room";
          } else if (value === "2") {
            return "Transfer Building";
          } else if (value === "5") {
            return "Finish/Moving Out";
          } else if (value === "6") {
            return "Building Terminated";
          } else if (value === "7") {
            return "Change Payment Plan";
          } else if (value === "8") {
            return "Extend Stay";
          } else if (value === "0") {
            return "None";
          } else {
            return "Unknown";
          }
        },
      },
      {
        id: "discount_code",
        name: "Discount Code",
      },
      {
        id: "date_created",
        name: "Date Created",
        renderer: (value) => {
          return utils.formatter.date(value);
        },
      },
      {
        id: "date_started",
        name: "Date Started",
        renderer: (value) => {
          return utils.formatter.date(value);
        },
      },
      {
        id: "date_ended",
        name: "Date Ended",
        renderer: (value) => {
          return utils.formatter.date(value);
        },
      },
      {
        id: "date_checkout",
        name: "Date Checkout",
        renderer: (value) => {
          return utils.formatter.date(value);
        },
      },
      {
        id: "date_updated",
        name: "Date Updated",
        renderer: (value) => {
          return utils.formatter.date(value);
        },
      },
      {
        id: "checkout_reason_name",
        name: "Reason of Checkout",
      },
      {
        id: "discount_amount",
        name: "Discount Amount",
        renderer: (value) => {
          return utils.formatter.currency(value);
        },
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
