import configBuilder from "../../../components/ubold/ConfigBuilder";
import utils from "../../../utils";

let definitions = {
  title: "Case Category",
  frontendPath: "/operation/case_category",
  baseLink: "/building/case_category",
  restAccessCode: "building.case_category",
  __table__: {
    filterColumn: 4,
    hideEditButton: true,
    filters: [
      {
        id: "name",
        name: "Name ID",
        type: "text",
      },
      {
        id: "name_en",
        name: "Name EN",
        type: "text",
      },
    ],
    columns: [
      {
        id: "name",
        name: "Name ID",
        enableHyperlink: true,
      },
      {
        id: "name_en",
        name: "Name EN",
      },
      {
        id: "date_created",
        name: "Date Created",
        renderer: (value: string) => {
          return utils.formatter.date(value);
        },
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
