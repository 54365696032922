import { withRouter } from "react-router-dom";

import useGlobalState from "../../../../state";

import utils from "../../../../utils";
import CMultiCheckBox from "../../atoms/forms/CMultiCheckBox";

const CVMultiCheckBox = (props: any) => {
  const {
    name,
    title,
    isRequired = false,
    onChange,
    restAccessCode,
    data = [],
    readOnly,
    state,
    setState,
    disabled = false,
    overrideLayout = null,
    formColumn = 2,
    boldTitle,
  } = props;

  const [user] = useGlobalState("user");
  const isSuperUser = user ? user.is_super_user : false;
  const [fieldAccesses] = useGlobalState("field_accesses");
  const [fieldAccessReversePolicy] = useGlobalState(
    "field_access_reverse_policy"
  );
  let formColumnReal = 12 / formColumn;

  return (
    <div
      className={
        "col-lg-" + (overrideLayout ? overrideLayout : formColumnReal)
      }
      style={{
        display:
          !isRequired &&
          restAccessCode &&
          utils.access.isFieldHidden(
            fieldAccesses,
            restAccessCode + "." + name
          )
            ? "none"
            : "unset",
      }}
    >
      <div className="form-group mb-3">
        <label
          className={
            (isRequired || boldTitle ? " font-weight-bold" : "") +
            (state[name + "Error"] ? " text-danger" : "")
          }
        >
          {title}
          {isRequired ? "*" : ""}{" "}
          {readOnly ??
          utils.access.isFieldReadOnly(
            fieldAccesses,
            restAccessCode + "." + name,
            fieldAccessReversePolicy,
            isSuperUser
          )
            ? "(read only)"
            : ""}
        </label>
        &nbsp;
        <CMultiCheckBox
          name={name}
          disabled={disabled}
          readOnly={
            readOnly ??
            (restAccessCode &&
              utils.access.isFieldReadOnly(
                fieldAccesses,
                restAccessCode + "." + name,
                fieldAccessReversePolicy,
                isSuperUser
              ))
          }
          onChange={onChange}
          state={state}
          data={data}
          setState={setState}
        />
        {state[name + "Error"] ? (
          <span className="text-danger" style={{ fontSize: "12px" }}>
            {state[name + "Error"]}
          </span>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default withRouter(CVMultiCheckBox);
