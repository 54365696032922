import { withRouter } from "react-router-dom";

import TabPane from "../../../../../../components/ubold/organisms/TabPane";
import TabPaneSection from "../../../../../../components/ubold/organisms/TabPaneSection";

import CHTextView from "../../../../../../components/ubold/molecules/forms/CHTextView";

import utils from "../../../../../../utils";
import LandlordInvoiceDetails from "./LandlordInvoiceDetails";
import CHSelect from "components/ubold/molecules/forms/CHSelect";
import CHSingleRow from "../../../../../../components/ubold/molecules/forms/CHSingleRow";

const NewLandlordInvoice = (props: any) => {
  const { id, definitions, tab, state, setState } = props;
  const generalKeys = state?.detail?.map(
    (tempDetail: any) => Object.keys(tempDetail)[0]
  );

  return (
    <TabPane
      id={id}
      definitions={definitions}
      tab={tab}
      hideAddAnother
      state={state}
      setState={setState}
    >
      <TabPaneSection style={{ padding: "25px" }}>
        <CHTextView title="Invoice ID" name="number" readOnly />
        <CHTextView
          title="Invoice Type"
          name="invoice_type"
          renderer={(value: any) => {
            if (value === 1) {
              return "Monthly";
            } else if (value === 2) {
              return "One Time";
            }
          }}
          readOnly
        />
        <CHTextView title="Order ID" name="order_number" readOnly />
        <CHTextView
          title="Started at"
          name="started_at"
          renderer={(value: any) => {
            return utils.formatter.date(value);
          }}
          readOnly
        />
        <CHTextView
          title="Ended at"
          name="ended_at"
          renderer={(value: any) => {
            return utils.formatter.date(value);
          }}
          readOnly
        />
        <CHTextView
          title="Building Name"
          name="building_name"
          readOnly
        />
        <CHTextView title="Room Name" name="room_name" readOnly />
        <CHTextView title="Tenant Name" name="tenant_name" readOnly />
        <CHTextView
          title="Landlord Name"
          name="landlord_name"
          readOnly
        />
        <CHTextView
          title="Grand Total"
          name="grand_total"
          renderer={(value: number) => {
            return utils.formatter.currency(value);
          }}
          readOnly
        />
        <CHSelect
          title="Release Status"
          name="release_status"
          data={[
            {
              label: "Hold",
              value: 1,
            },
            {
              label: "Ready to Release",
              value: 2,
            },
            {
              label: "Release",
              value: 3,
            },
          ]}
          readOnly
        />
        <CHTextView
          title="Invoice Status"
          name="invoice_status"
          renderer={(value: any) => {
            if (value === 1) {
              return "Waiting for Payment";
            } else if (value === 2) {
              return "Cancel";
            } else if (value === 3) {
              return "Done";
            }
          }}
          readOnly
        />
        <CHTextView
          title="Created at"
          name="date_created"
          renderer={(value: any) => {
            return utils.formatter.date(value);
          }}
          readOnly
        />
      </TabPaneSection>
      <TabPaneSection accordionId="formAccordion">
        {generalKeys?.map((key: string, index: number) => (
          <LandlordInvoiceDetails
            key={`${tab.id}-${key}-${index}`}
            generalKey={key}
            accordionIndex={index}
          />
        ))}
      </TabPaneSection>
      <CHSingleRow
        title="Grand total"
        data={state?.grand_total}
        titleWidth="20%"
      />
    </TabPane>
  );
};

export default withRouter(NewLandlordInvoice);
