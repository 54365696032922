import { useState, useEffect } from "react";
import { useParams, withRouter } from "react-router-dom";

import Page from "components/ubold/organisms/Page";
import Form from "components/ubold/organisms/Form";

import CVSelect from "components/ubold/molecules/forms/CVSelect";
import CVAsyncSelect from "components/ubold/molecules/forms/CVAsyncSelect";
import CVTextField from "components/ubold/molecules/forms/CVTextField";
import CVTextView from "components/ubold/molecules/forms/CVTextView";
import CVRichTextField from "components/ubold/molecules/forms/CVRichTextField";
import useGlobalState from "state";

import definitions from "./definitions";

function SeoCmsAddEditPage() {
  let { id }: { id: string } = useParams();
  const initialState: any = {};
  const [state, setState] = useState(initialState);
  const [constants] = useGlobalState("constant_values");

  useEffect(() => {
    if (id) return;
    setState((prevState: any) => ({
      ...prevState,
      publish_status: 2,
    }));
  }, [id]);

  useEffect(() => {
    /** reset val country, province and city
     * when seo_page = homepage */
    if (state["seo_page"] === 2) return;
    setState((prevState: any) => ({
      ...prevState,
      city: null,
      country: null,
      province: null,
    }));
  }, [state["seo_page"]]);

  return (
    <Page
      title={
        (id === undefined ? "Add New" : "Edit") +
        " " +
        definitions.title
      }
      restAccessCode={definitions.restAccessCode}
    >
      <Form
        id={id}
        definitions={definitions}
        state={state}
        setState={setState}
      >
        <CVTextField name="title" title="Title ID" isRequired />
        <CVTextField name="title_en" title="Title EN" isRequired />
        <CVRichTextField name="desc" title="Desc ID" isRequired />
        <CVRichTextField name="desc_en" title="Desc EN" isRequired />
        <CVSelect
          name="seo_page"
          title="Seo page"
          data={constants?.["website_cms_seo_page_choice"]}
          isRequired
        />
        <CVSelect
          name="publish_status"
          title="Publish Status"
          data={[
            {
              label: "Publish",
              value: 1,
            },
            {
              label: "Unpublish",
              value: 2,
            },
          ]}
          isRequired
        />
        {state?.["seo_page"] === 2 ? (
          <CVAsyncSelect
            name="country"
            title="Country"
            data="place/country?__type__=select_entries&limit=10"
          />
        ) : (
          <></>
        )}
        {state?.["seo_page"] === 2 ? (
          <CVAsyncSelect
            name="province"
            title="Province"
            dependsOn="country"
            data="place/province?__type__=select_entries&limit=10"
          />
        ) : (
          <></>
        )}
        {state?.["seo_page"] === 2 ? (
          <CVAsyncSelect
            name="city"
            title="City"
            data="place/city?__type__=select_entries&limit=10"
            dependsOn="province"
          />
        ) : (
          <></>
        )}

        <CVTextView name="date_created" title="Date Created" />
        <CVTextView name="date_updated" title="Date Updated" />
      </Form>
    </Page>
  );
}
export default withRouter(SeoCmsAddEditPage);
