import configBuilder from "components/ubold/ConfigBuilder";
import utils from "utils";

let definitions = {
  title: "Room Allotment",
  frontendPath: "/u/dashboard/building",
  baseLink: "u/dashboard/building/room",
  restAccessCode: "home.dashboard.building.room",
  breadcrumb: { alignWithPageTitle: true },
  __table__: {
    isAddButtonDisabled: true,
    filterValidators: {
      building: (val) => {
        if (typeof val === "object") {
          return utils.validator.validateFilterRequired(val?.value);
        } else {
          return utils.validator.validateFilterRequired(val);
        }
      },
    },
    filters: [
      {
        id: "building",
        name: "Building",
        type: "async_select",
        data: "building/building?__type__=select_entries",
        listenParam: "building_id",
        isRequired: true,
        itemsExtractor: (item) => {
          return {
            label: item.building_name,
            value: item.id,
          };
        },
        placeholder: "Search Building",
      },
      {
        id: "buildings_room_status",
        name: "Room Status",
        type: "select",
        data: {
          optionField: "buildings_room_status",
        },
        isMulti: true,
        isCheckbox: true,
        hideSelectedOptions: false,
        closeMenuOnSelect: false,
        append: { atFirst: [{ label: "All", value: "select_all" }] }, // Avoid fill the value with null
        minWidth: 240,
      },
      {
        id: "booked_by_sales",
        name: "Booked by Sales",
        type: "select",
        data: [
          {
            label: "Select All",
            value: "select_all",
          },
          {
            label: "Yes",
            value: true,
          },
          {
            label: "No",
            value: false,
          },
        ],
        isMulti: true,
        isCheckbox: true,
        hideSelectedOptions: false,
        closeMenuOnSelect: false,
        minWidth: 240,
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
