import React from "react";

import { withRouter } from "react-router-dom";
import utils from "../../../utils";

function AccordionPane(props) {
  const {
    children,
    id,
    name,
    shown = false,
    accordionId,
    cancelLink,
    onSubmit,
    state,
    setState,
  } = props;

  return (
    <div className="card mb-0">
      <div className="card-header" id={id + "Heading"}>
        <h5 className="m-0 position-relative">
          <a
            className={`custom-accordion-title text-reset ${
              !shown && "collapsed"
            } d-block`}
            data-toggle="collapse"
            href={"#" + id + "Collapse"}
            aria-controls={id + "Collapse"}
          >
            {name}
            <i className="mdi mdi-chevron-down accordion-arrow" />
          </a>
        </h5>
      </div>
      <div
        id={id + "Collapse"}
        className={"collapse" + (shown ? " show" : "")}
        aria-labelledby={id + "Heading"}
        data-parent={"#" + accordionId}
      >
        <div
          className="card-body"
          style={{ padding: "25px 16px 0px" }}
        >
          {utils.commons.isArray(children) ? (
            children.map((child, i) => {
              return React.cloneElement(child, {
                key: i,
                state: state,
                setState: setState,
                cancelLink: cancelLink,
                onSubmit: onSubmit,
              });
            })
          ) : children ? (
            React.cloneElement(children, {
              key: "0",
              state: state,
              setState: setState,
              cancelLink: cancelLink,
              onSubmit: onSubmit,
            })
          ) : (
            <></>
          )}
          {}
        </div>
      </div>
    </div>
  );
}

export default withRouter(AccordionPane);
