import { useState } from "react";

import { useParams, withRouter } from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import TabHeaders from "../../../../components/ubold/organisms/TabHeaders";
import TabContent from "../../../../components/ubold/organisms/TabContent";
import TabbedForm from "../../../../components/ubold/organisms/TabbedForm";

import RoomVariantRoom from "./Tabs/RoomVariantRoom";
import RoomVariantPhoto from "./Tabs/RoomVariantPhoto";
import RoomVariantMod from "./Tabs/RoomVariantMod";
import RoomVariantFacilities from "./Tabs/RoomVariantFacilities";
import RoomVariantFeatures from "./Tabs/RoomVariantFeatures";
import RoomVariant from "./Tabs/RoomVariant";

import definitions from "./definitions";

function RoomVariantAddEditPage() {
  let { id } = useParams<{ id: string }>();

  const initialState = {
    ac_type: 3,
    laundry_type: 4,
    base_price: 0,
    price: 0,
    electricity: 1,
    bathroom: 1,
    gender: 0,
    publish_status: 0,
    is_renovated: false,
  };

  const [state, setState] = useState(initialState);

  return (
    <Page
      title={
        (id === undefined ? "Add New" : "Edit") +
        " " +
        definitions.title
      }
      restAccessCode={definitions.restAccessCode}
    >
      <TabbedForm
        id={id}
        state={state}
        setState={setState}
        definitions={definitions}
      >
        <TabHeaders />
        <TabContent>
          <RoomVariant />
          <RoomVariantPhoto />
          <RoomVariantRoom />
          <RoomVariantMod />
          <RoomVariantFacilities />
          <RoomVariantFeatures />
        </TabContent>
      </TabbedForm>
    </Page>
  );
}

export default withRouter(RoomVariantAddEditPage);
