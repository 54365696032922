import configBuilder from "../../../components/ubold/ConfigBuilder";
import utils from "../../../utils";

let definitions = {
  title: "Promotion Highlights",
  frontendPath: "/marketing/promotion/partnership_on_website",
  baseLink: "/promotion/partnership_on_website",
  restAccessCode: "promotion.partnership_on_website",
  __table__: {
    filterColumn: 4,
    removeIdentifier: "url",
    filters: [
      {
        id: "url",
        name: "URL",
        type: "text",
      },
      {
        id: "order_no",
        name: "Order Number",
        type: "number",
      },
      {
        id: "published",
        name: "Published",
        type: "select",
        data: [
          {
            label: "Yes",
            value: true,
          },
          {
            label: "No",
            value: false,
          },
        ],
      },
      {
        id: "date_created",
        name: "Date Created",
        type: "date",
      },
      {
        id: "date_update",
        name: "Date Updated",
        type: "date",
      },
    ],
    columns: [
      {
        id: "banner_picture_original",
        name: "Banner Mobile Screen",
        isSafeContent: true,
        canDoOrdering: false,
        center: true,
        renderer: (value) => {
          if (value && value !== "" && value !== "-") {
            return (
              "<img alt='image' src='" + value + "' width='50px' />"
            );
          } else {
            return value;
          }
        },
      },
      {
        id: "url",
        name: "URL",
        center: true,
      },
      {
        id: "order_no",
        name: "Order Number",
        center: true,
      },
      {
        id: "published",
        name: "Published",
        isSafeContent: true,
        center: true,
        renderer: (value) => {
          if (value === "true") {
            return '<span style="color: rgb(26, 188, 156);"> ✔ </span>';
          } else {
            return '<span style="color: red;">✘</span>';
          }
        },
      },
      {
        id: "date_created",
        name: "Date Created",
        renderer: (value) => {
          return utils.formatter.dateTime(value);
        },
      },
      {
        id: "date_updated",
        name: "Date Updated",
        renderer: (value) => {
          return utils.formatter.dateTime(value);
        },
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
