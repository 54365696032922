import configBuilder from "../../../../components/ubold/ConfigBuilder";
import pageDefinitions from "../definitions";

const definitions = {
  ...pageDefinitions,
  tabs: [
    {
      id: "product",
      name: "Product",
    },
    {
      id: "t_category_products",
      name: "Category Products",
    },
    {
      id: "t_photo_products",
      name: "Photo Products",
    },
  ],
};

export default configBuilder.buildAddEditPageConfig(definitions);
