import {useState} from "react";

import {useParams, withRouter} from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import Form from "../../../../components/ubold/organisms/Form";

import definitions from "./definitions";
import CVAsyncSelect from "../../../../components/ubold/molecules/forms/CVAsyncSelect";
import CVTextField from "../../../../components/ubold/molecules/forms/CVTextField";
import CVImageUpload from "../../../../components/ubold/molecules/forms/CVImageUpload";

function CAddEditPage() {
    let {id} = useParams();

    const initialState = {
        sequence: 0
    };

    const [state, setState] = useState(initialState);

    return (
        <Page title={(id === undefined ? 'Add New' : 'Edit') + ' ' +  definitions.title}
              restAccessCode={definitions.restAccessCode}>
            <Form
                id={id}
                definitions={definitions}
                state={state}
                setState={setState}>
                <CVAsyncSelect
                    name='building'
                    title='Building'
                    data='building/building?__type__=select_entries'
                    isRequired
                    itemsExtractor={row => {
                        return {
                            label: row.building_name,
                            value: row.id
                        }
                    }}
                />
                <CVImageUpload
                    name='photo'
                    title='Photo'
                    isRequired
                />
                <CVTextField
                    name='sequence'
                    title='Sequence'
                    type='number'
                    maxLength={3}
                />
            </Form>
        </Page>
    );
}

export default withRouter(CAddEditPage);