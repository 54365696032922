import React, {
  MouseEventHandler,
  useContext,
  useState,
} from "react";

import { IModalContext, ModalContext } from "./ModalContext";

export interface IModalOptionsObject {
  btnLeft?: {
    onClick: MouseEventHandler<HTMLButtonElement>;
    text: string;
    htmlID?: string;
  };
  btnRight?: {
    onClick: MouseEventHandler<HTMLButtonElement>;
    text: string;
    htmlID?: string;
  };
  title: string;
  canvasClassName?: string;
  closeBtnID?: string;
}

export type HandleModalFunctionType = (
  action: "OPEN" | "CLOSE",
  content?: React.ReactElement,
  options?: IModalOptionsObject
) => void;

export const RuModal = () => {
  let [modal, setModal] = useState<boolean>(false);
  let [ModalContent, setModalContent] =
    React.useState<React.ReactElement | null>(null);

  let [modalOptions, setModalOptions] =
    useState<IModalOptionsObject>();

  let handleModal: HandleModalFunctionType = (
    action,
    content = null,
    options = null
  ) => {
    if (action === "CLOSE") {
      return setModal(false);
    }

    if (action === "OPEN") {
      setModal(true);
    }

    if (typeof action !== "string") {
      setModal(!modal);
    }

    if (content) {
      setModalContent(content);
    }

    if (options) {
      setModalOptions(options);
    }
  };

  return { modal, handleModal, ModalContent, modalOptions };
};

const useModal = () => {
  const contextRuModal: IModalContext = useContext(ModalContext);
  return contextRuModal;
};

export default useModal;
