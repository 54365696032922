import { withRouter } from "react-router";

import Page from "../../../components/ubold/organisms/Page";
import TableView from "../../../components/ubold/organisms/TableView";
import definitions from "./definitions";

function CPage() {
  return (
    <Page title={definitions.title}>
      <TableView {...definitions.table} />
    </Page>
  );
}

export default withRouter(CPage);
