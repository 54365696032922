import COneOrManyToManyField from "components/ubold/molecules/forms/COneOrManyToManyField";
import TabPaneSecondary from "components/ubold/organisms/TabPaneSecondary";
import TabPaneSection from "components/ubold/organisms/TabPaneSection";
import { useParams, withRouter } from "react-router-dom";
import { useEffect } from "react";

const RoomServiceSchedule = (props) => {
  const { definitions, tab, state, setState } = props;
  const { id } = useParams();
  const days = [
    {
      label: "Senin",
      value: "1",
    },
    {
      label: "Selasa",
      value: "2",
    },
    {
      label: "Rabu",
      value: "3",
    },
    {
      label: "Kamis",
      value: "4",
    },
    {
      label: "Jumat",
      value: "5",
    },
    {
      label: "Sabtu",
      value: "6",
    },
    {
      label: "Minggu",
      value: "7",
    },
  ];

  useEffect(() => {
    if (!state?.t_room_service_schedule?.length) {
      setState((prevState) => ({
        ...prevState,
        t_room_service_schedule: [
          {
            laundry_schedule: [],
            cleaning_schedule: [],
          },
        ],
      }));
    }
  }, [state?.t_room_service_schedule]);

  return (
    <TabPaneSecondary
      definitions={definitions}
      tab={tab}
      restAccessCodeNamespace="building.room_service_schedule"
      state={state}
      setState={setState}
    >
      <TabPaneSection style={{ padding: "25px 16px 0px" }}>
        <COneOrManyToManyField
          id={id}
          name={tab?.id}
          saveOnlyFromMainForm
          readOnly
          restPath="building/room__service_schedule/"
          relationKey="room_id"
          columns={[
            {
              name: "laundry_schedule",
              title: "Laundry",
              type: "multi_checkbox",
              data: days,
              disabled: true,
            },
            {
              name: "cleaning_schedule",
              title: "Room Cleaning",
              type: "multi_checkbox",
              data: days,
              disabled: true,
            },
          ]}
        />
      </TabPaneSection>
    </TabPaneSecondary>
  );
};

export default withRouter(RoomServiceSchedule);
