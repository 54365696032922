import { useParams, withRouter } from "react-router-dom";

import TabPaneSecondary from "../../../../../../components/ubold/organisms/TabPaneSecondary";

import COneOrManyToManyField from "../../../../../../components/ubold/molecules/forms/COneOrManyToManyField";

function RoomVariantMod(props) {
  const { definitions, tab, state, setState } = props;

  let { id } = useParams();

  return (
    <TabPaneSecondary
      definitions={definitions}
      restAccessCodeNamespace="mod"
      tab={tab}
      state={state}
      setState={setState}
    >
      <COneOrManyToManyField
        id={id}
        name={tab.id}
        restPath="building/room_variant__mods/"
        relationKey="room_variant_id"
        readOnly
        columns={[
          {
            name: "addon_building",
            type: "async_select",
            title: "Addon Building",
            isRequired: true,
            disabledAtEdit: true,
            isUniqueOption: true,
            width: 200,
            data: "building/addon?__type__=select_entries&type=3",
          },
        ]}
      />
    </TabPaneSecondary>
  );
}

export default withRouter(RoomVariantMod);
