import { useEffect, useState } from "react";

import { withRouter, useLocation } from "react-router-dom";

import { isMobile } from "react-device-detect";

import logoDark from "../../assets/logo-dark.png";

import utils from "../../utils";
import { useHistory } from "react-router-dom";

import useGlobalState from "../../state";
import DropdownItem from "components/specific/profileSetting/UserActionItem";
import useModal from "components/global/Modal/useModal";
import GeneralModalBodyTemplate from "components/global/Modal/GeneralModalBodyTemplate";
import styled from "styled-components/macro";
import packageJSON from "../../../package.json";

function TopBar(props) {
  const key = "sideBarClosed";

  const history = useHistory();
  const params = new URLSearchParams(window.location.search);
  const [isSideBarOpen, setIsSideBarOpen] = useState(true);

  // get environment from node
  const env = process.env.REACT_APP_ENV_NAME;
  const version = `v${packageJSON.version}${
    env === "production" ? "" : `-${env}`
  }`;

  /** specific for edit profile */
  const [currLocationState, setCurrLocationState] = useState();
  const { handleModal } = useModal();
  const currActiveDropdown = history.location.state?.target;
  const pageType = params.get("__page_type__");

  const [user] = useGlobalState("user");

  const { pathname } = useLocation();

  const logout = () => {
    if (
      currLocationState?.formProfileDirty ||
      currLocationState?.formPasswordDirty
    ) {
      handleModal(
        "OPEN",
        <GeneralModalBodyTemplate
          render={
            <p>
              You have unsaved changes. Are you sure you want to
              leave? Unsaved changes may be lost.
            </p>
          }
        />,
        {
          btnLeft: {
            onClick: () => {
              handleModal("CLOSE");
              props.history.push("/logout");
            },
            text: "Yes, Leave This Page",
          },
          btnRight: {
            onClick: () => handleModal("CLOSE"),
            text: "No, Stay on This Page",
          },
          title: "Unsaved Changes",
        }
      );
    } else {
      localStorage.clear();

      props.history.push("/logout");
    }
  };

  useEffect(() => {
    $(document).ready(function () {
      setTimeout(() => {
        if (!isMobile) {
          const sideBarStatus = utils.store.get(key);
          if (sideBarStatus === "true") {
            $.App.layout.leftSidebar.changeSize("condensed");
          } else {
            utils.store.set("sideBarClosed", false);
          }
        } else {
          $(".left-side-menu").css("display", "none");
        }
      }, 100);
    });
  });

  const handleNavigateHome = () => {
    if (
      currLocationState?.formProfileDirty ||
      currLocationState?.formPasswordDirty
    ) {
      handleModal(
        "OPEN",
        <GeneralModalBodyTemplate
          render={
            <p>
              You have unsaved changes. Are you sure you want to
              leave? Unsaved changes may be lost.
            </p>
          }
        />,
        {
          btnLeft: {
            onClick: () => {
              handleModal("CLOSE");
              props.history.push("/u/home");
            },
            text: "Yes, Leave This Page",
          },
          btnRight: {
            onClick: () => handleModal("CLOSE"),
            text: "No, Stay on This Page",
          },
          title: "Unsaved Changes",
        }
      );
    } else {
      history.push("/u/home");
    }
  };

  useEffect(() => {
    if (history.location.pathname !== "/profile_settings") return;
    const newState = history.location.state;

    if (newState.target === "edit_profile") {
      setCurrLocationState(newState);
    } else {
      setCurrLocationState(newState);
    }
  }, [history.location.state]);

  const handleChangeSection = (id) => {
    if (
      id !== currLocationState?.target &&
      (currLocationState?.formProfileDirty ||
        currLocationState?.formPasswordDirty)
    ) {
      handleModal(
        "OPEN",
        <GeneralModalBodyTemplate
          render={
            <p>
              You have unsaved changes. Are you sure you want to
              leave? Unsaved changes may be lost.
            </p>
          }
        />,
        {
          btnLeft: {
            onClick: () => {
              handleModal("CLOSE");
              props.history.push({
                pathname: "/profile_settings",
                state: {
                  target: id,
                },
              });
            },
            text: "Yes, Leave This Page",
          },
          btnRight: {
            onClick: () => handleModal("CLOSE"),
            text: "No, Stay on This Page",
          },
          title: "Unsaved Changes",
        }
      );
    } else {
      const currStateKey =
        id === "edit_profile"
          ? "formProfileDirty"
          : "formPasswordDirty";
      history.push({
        pathname: "/profile_settings",
        state: {
          target: id,
          [currStateKey]: false,
        },
      });
    }
  };

  return (
    <TopBarBase
      className={
        pathname === "/profile_settings"
          ? "navbar-light-full"
          : "navbar-custom"
      }
      style={{
        display:
          pageType && pageType === "no_frame" ? "none" : "block",
        top: 0,
      }}
    >
      <div className="container-fluid">
        <ul className="list-unstyled topnav-menu float-right mb-0">
          <li className="dropdown notification-list topbar-dropdown">
            <a
              className="nav-link dropdown-toggle nav-user mr-0 waves-effect waves-light"
              data-toggle="dropdown"
              href="#"
              role="button"
              aria-haspopup="false"
              aria-expanded="false"
            >
              {user ? (
                <div
                  style={{
                    backgroundColor: "#1abc9c",
                    borderRadius: "50px",
                    color: "#ffffff",
                    fontSize: "20px",
                    fontWeight: "bold",
                    float: "left",
                    width: "40px",
                    height: "40px",
                    marginTop: "15px",
                    lineHeight: "2.1",
                  }}
                >
                  {user.username.charAt(0).toUpperCase()}
                </div>
              ) : (
                <></>
              )}
              <span className="pro-user-name ml-1">
                {user ? (
                  <span>
                    {user.email ? user.email : user.username}
                  </span>
                ) : (
                  <></>
                )}{" "}
                <i className="mdi mdi-chevron-down" />
              </span>
            </a>
            <div className="dropdown-menu dropdown-menu-right profile-dropdown">
              <div className="dropdown-header noti-title">
                <h5 className="text-overflow m-0">Welcome</h5>
                {user ? (
                  <div style={{ marginTop: "5px", fontSize: "14px" }}>
                    {user.username} -{" "}
                    <b>
                      {user.is_super_user
                        ? "Super User"
                        : "Regular User"}
                    </b>
                  </div>
                ) : (
                  <></>
                )}
              </div>

              <hr style={{ margin: "0px" }} />
              <DropdownItem
                id="edit_profile"
                imgSrc="/assets/icons/person.svg"
                imgSrcActive="/assets/icons/person-active.svg"
                title="Edit Profile"
                useIcon={false}
                isActive={currActiveDropdown === "edit_profile"}
                onClick={() => handleChangeSection("edit_profile")}
              />

              <DropdownItem
                id="change_password"
                imgSrc="/assets/icons/unlock.svg"
                imgSrcActive="/assets/icons/unlock-active.svg"
                title="Change Password"
                useIcon={false}
                isActive={currActiveDropdown === "change_password"}
                onClick={() => handleChangeSection("change_password")}
              />
              <a
                onClick={() => logout()}
                className="dropdown-item notify-item"
                style={{ cursor: "pointer", paddingLeft: 12 }}
              >
                <i className="fe-log-out" />
                <span>Logout</span>
              </a>
            </div>
          </li>
        </ul>
        {!isMobile ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
            }}
            className="logo-box"
          >
            <div
              className="logo logo-dark"
              style={{ paddingLeft: "30px", cursor: "pointer" }}
              onClick={() => handleNavigateHome()}
            >
              <span className="logo-sm">
                <img src={logoDark} alt="" height="40" />
              </span>
              <span className="logo-lg">
                <img src={logoDark} alt="" height="24" />
              </span>
            </div>
            <div
              style={{
                display: isSideBarOpen ? "flex" : "none",
                justifyContent: "center",
                alignItems: "center",
                paddingBottom: "2px",
                marginLeft: "10px",
              }}
            >
              <p
                style={{
                  color: "black",
                  fontSize: "10px",
                  fontWeight: "500",
                }}
              >
                {version}
              </p>
            </div>
          </div>
        ) : (
          <></>
        )}
        <ul className="list-unstyled topnav-menu topnav-menu-left m-0">
          <li>
            <button
              className="button-menu-mobile waves-effect waves-light"
              onClick={() => {
                if (!isMobile) {
                  setIsSideBarOpen(!isSideBarOpen);
                  const sideBarStatus = utils.store.get(key);

                  utils.store.set(
                    "sideBarClosed",
                    sideBarStatus !== "true"
                  );

                  const sidebarSize = $(
                    "[data-sidebar-size='condensed']"
                  );

                  if (sidebarSize.length === 0) {
                    $.App.layout.leftSidebar.changeSize("condensed");
                  } else {
                    $.App.layout.leftSidebar.changeSize("default");
                  }
                } else {
                  let sidebar = $(".left-side-menu");

                  let flag = sidebar.css("display");

                  if (flag === "none") {
                    sidebar.css("display", "block");
                  } else {
                    sidebar.css("display", "none");
                  }
                }
              }}
            >
              <i className="fe-menu" />
            </button>
          </li>

          <li>
            <a
              className="navbar-toggle nav-link"
              data-toggle="collapse"
              data-target="#topnav-menu-content"
            >
              <div className="lines">
                <span />
                <span />
                <span />
              </div>
            </a>
          </li>
        </ul>
        <div className="clearfix" />
      </div>
    </TopBarBase>
  );
}

export default withRouter(TopBar);

const TopBarBase = styled.div`
  &.navbar-light-full {
    background-color: #fff;
    box-shadow: 0 0 35px 0 rgb(154 161 171 / 15%);
    padding: 0 10px 0 0;
    position: fixed;
    left: 0;
    right: 0;
    height: 70px;
    z-index: 1001;

    .button-menu-mobile {
      display: none;
    }

    .topnav-menu .nav-link {
      padding: 0 15px;
      color: #6c757d;
      min-width: 32px;
      display: block;
      line-height: 70px;
      text-align: center;
      max-height: 70px;
    }
  }
`;
