import React, { HTMLAttributes } from "react";

import styled, { css } from "styled-components";

interface ILegendItemProps extends HTMLAttributes<HTMLDivElement> {
  color?: string;
  stripes?: string;
  theme?: "BLACK" | "BLUE" | "GRAY" | "PURPLE" | "RED" | "YELLOW";
}
const LegendItem: React.FC<ILegendItemProps> = ({
  color,
  stripes,
  theme,
  ...props
}) => {
  if (stripes) {
    return (
      <RectangleCircle
        color={color}
        stripes={stripes}
        theme={theme}
        {...props}
      >
        <Stripe stripes={stripes} />
        <Stripe stripes={stripes} />
        <Stripe stripes={stripes} />
        <Stripe stripes={stripes} />
      </RectangleCircle>
    );
  }
  return <RectangleCircle color={color} theme={theme} {...props} />;
};

const Stripe = styled.span<{ stripes: string }>`
  display: block;

  height: 1.5px;
  width: 100%;

  background-color: ${({ stripes }) => {
    switch (stripes) {
      case "RED":
        return "#FF4438";

      case "BLUE":
        return "#2A7DE1";

      case "YELLOW":
        return "#FFB71B";

      case "PURPLE":
        return "#7E57C5";

      default:
        return stripes;
    }
  }};
`;

const RectangleCircle = styled.div<{
  color?: string;
  stripes?: string;
  theme?: "BLACK" | "BLUE" | "GRAY" | "PURPLE" | "RED" | "YELLOW";
}>`
  width: 20px;
  height: 10px;

  ${({ stripes }) => {
    if (stripes) {
      return css`
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      `;
    }

    return css`
      display: inline-block;
    `;
  }}

  border-radius: 99px;
  overflow: hidden;

  ${({ color, stripes, theme }) => {
    switch (theme) {
      case "BLACK":
        return css`
          background-color: ${color && !theme ? color : "#222222"};
        `;

      case "BLUE":
        if (stripes) {
          return css`
            background-color: ${color && !theme ? color : "#B8D4F5"};
          `;
        }

        return css`
          background-color: ${color && !theme ? color : "#2A7DE1"};
        `;

      case "YELLOW":
        if (stripes) {
          return css`
            background-color: ${color && !theme ? color : "#FFE7B3"};
          `;
        }

        return css`
          background-color: ${color && !theme ? color : "#FEC42F"};
        `;

      case "GRAY":
        return css`
          background-color: ${color && !theme ? color : "#CCCCCC"};
        `;

      case "PURPLE":
        if (stripes) {
          return css`
            background-color: ${color && !theme ? color : "#D4C7EC"};
          `;
        }

        return css`
          background-color: ${color && !theme ? color : "#7E57C5;"};
        `;

      case "RED":
        if (stripes) {
          return css`
            background-color: ${color && !theme ? color : "#FFC1BD"};
          `;
        }

        return css`
          background-color: ${color && !theme ? color : "#FF4438"};
        `;

      default:
        return css`
          background-color: ${color && !theme ? color : "#222222"};
        `;
    }
  }}
`;
export default LegendItem;
