import { useParams, withRouter } from "react-router-dom";

import TabPaneSecondary from "../../../../../../components/ubold/organisms/TabPaneSecondary";

import COneOrManyToManyField from "../../../../../../components/ubold/molecules/forms/COneOrManyToManyField";

import utils from "../../../../../../utils";

function TabPayments(props) {
  const { definitions, tab, state, setState } = props;

  let { id } = useParams();

  return (
    <TabPaneSecondary
      definitions={definitions}
      tab={tab}
      restAccessCodeNamespace="payment"
      state={state}
      setState={setState}
    >
      <COneOrManyToManyField
        id={id}
        name={tab.id}
        photoFields={["photo_original"]}
        restPath="one_time_purchase/payment/"
        relationKey="invoice_id"
        readOnly
        columns={[
          {
            name: "bank_account_number",
            type: "read_only",
            title: "Bank Account Number",
          },
          {
            name: "bank_account_name",
            type: "read_only",
            title: "Bank Account Name",
          },
          {
            name: "bank_name",
            type: "read_only",
            title: "Bank Name",
          },
          {
            name: "amount",
            type: "read_only",
            title: "Amount",
            renderer: (value) => {
              return utils.formatter.currency(value);
            },
          },
          {
            name: "transaction_date",
            type: "read_only",
            title: "Transaction Date",
            renderer: (value) => {
              return utils.formatter.dateTime(value);
            },
          },
          {
            name: "status_text",
            type: "read_only",
            title: "Status",
          },
          {
            name: "photo_original",
            title: "Photo",
            type: "read_only",
          },
          {
            name: "otp_payment_method_text",
            type: "read_only",
            title: "OTP Payment Method",
          },
          {
            name: "remaining_amount",
            type: "read_only",
            title: "Remaining Amount",
            renderer: (value) => {
              return utils.formatter.currency(value);
            },
          },
          {
            name: "midtrans_number",
            type: "read_only",
            title: "Midtrans Number",
          },
          {
            name: "date_created",
            type: "read_only",
            title: "Date Created",
            renderer: (value) => {
              return utils.formatter.dateTime(value);
            },
          },
          {
            name: "date_updated",
            type: "read_only",
            title: "Date Updated",
            renderer: (value) => {
              return utils.formatter.dateTime(value);
            },
          },
        ]}
      />
    </TabPaneSecondary>
  );
}

export default withRouter(TabPayments);
