import { useParams, withRouter } from "react-router-dom";

import TabPaneSecondary from "../../../../../../components/ubold/organisms/TabPaneSecondary";

import COneOrManyToManyField from "../../../../../../components/ubold/molecules/forms/COneOrManyToManyField";

function OrderServiceSchedules(props: any) {
  const { definitions, tab, state, setState } = props;

  let { id }: any = useParams();

  return (
    <TabPaneSecondary
      definitions={definitions}
      tab={tab}
      restAccessCodeNamespace="service_schedule"
      state={state}
      setState={setState}
      readOnly={true}
    >
      <COneOrManyToManyField
        id={id}
        name={tab.id}
        restPath="order/service_schedule/"
        relationKey="order_id"
        saveOnlyFromMainForm={true}
        index={3}
        maxRow={1}
        columns={[
          {
            name: "laundry",
            type: "multi_checkbox",
            data: [
              {
                label: "Senin",
                value: "1",
              },
              {
                label: "Selasa",
                value: "2",
              },
              {
                label: "Rabu",
                value: "3",
              },
              {
                label: "Kamis",
                value: "4",
              },
              {
                label: "Jumat",
                value: "5",
              },
              {
                label: "Sabtu",
                value: "6",
              },
              {
                label: "Minggu",
                value: "7",
              },
            ],
            title: "Laundry",
            readOnly: true,
          },
          {
            name: "room_cleaning",
            type: "multi_checkbox",
            data: [
              {
                label: "Senin",
                value: "1",
              },
              {
                label: "Selasa",
                value: "2",
              },
              {
                label: "Rabu",
                value: "3",
              },
              {
                label: "Kamis",
                value: "4",
              },
              {
                label: "Jumat",
                value: "5",
              },
              {
                label: "Sabtu",
                value: "6",
              },
              {
                label: "Minggu",
                value: "7",
              },
            ],
            title: "Room Cleaning",
            readOnly: true,
          },
          {
            name: "laundry_default",
            type: "checkbox",
            center: true,
            title: "Laundry Default",
            readOnly: true,
          },
          {
            name: "room_cleaning_default",
            type: "checkbox",
            center: true,
            title: "Room Cleaning Default",
            readOnly: true,
          },
        ]}
      />
    </TabPaneSecondary>
  );
}

export default withRouter(OrderServiceSchedules);
