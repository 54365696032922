import { withRouter } from "react-router-dom";

import TabPane from "../../../../../../components/ubold/organisms/TabPane";
import TabPaneSection from "../../../../../../components/ubold/organisms/TabPaneSection";
import CHSelect from "../../../../../../components/ubold/molecules/forms/CHSelect";
import CHTextField from "../../../../../../components/ubold/molecules/forms/CHTextField";
import { genericTabProps } from "@types";

function Facility(props: genericTabProps<Object>) {
  const { id, definitions, tab, state, setState } = props;

  return (
    <TabPane
      id={id}
      definitions={definitions}
      tab={tab}
      state={state}
      setState={setState}
      readOnly
    >
      <TabPaneSection style={{ padding: "25px 16px 0px" }}>
        <CHTextField
          name="name"
          title="Name"
          maxLength={100}
          isRequired
          readOnly
        />
        <CHTextField
          name="name_en"
          title="Name EN"
          readOnly
          maxLength={100}
        />
        <CHTextField
          name="priority"
          title="Priority"
          type="number"
          readOnly
        />
        <CHSelect
          name="category"
          title="Category"
          readOnly
          data={[
            {
              label: "General Facility",
              value: 1,
            },
            {
              label: "Sharing Area",
              value: 2,
            },
            {
              label: "Room Facility",
              value: 4,
            },
            {
              label: "Room Features",
              value: 5,
            },
          ]}
        />
      </TabPaneSection>
    </TabPane>
  );
}

export default withRouter(Facility);
