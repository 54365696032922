/**
 * All Fields inside this tab is deprecated
 * please change to latest update based on
 * order management confluence
 */
import { useParams, withRouter } from "react-router-dom";

import TabPaneSecondary from "../../../../../../components/ubold/organisms/TabPaneSecondary";

import COneOrManyToManyField from "../../../../../../components/ubold/molecules/forms/COneOrManyToManyField";
import moment from "moment";
import { genericTabProps } from "@types";

function OrderAdditionalDiscounts(
  props: genericTabProps<Record<string, any>>
) {
  const { definitions, tab, state, setState } = props;
  const doneStatus = [5, 6, 10];
  const { id } = useParams<{ id: string }>();

  return (
    <TabPaneSecondary
      definitions={definitions}
      tab={tab}
      restAccessCodeNamespace="discount_order"
      state={state}
      setState={setState}
    >
      <COneOrManyToManyField
        id={id}
        name={tab?.id}
        readOnly={
          (id && state.subscription_type === 2) ||
          (id && doneStatus.includes(state?.original___order_status))
        }
        disableActionBtn={!state.checkin_date || !state.building}
        restPath="order_new/discount_order/"
        relationKey="order_id"
        saveOnlyFromMainForm={true}
        infoMessageStandAlone={!id}
        index={1}
        additionalStateInAddNewRow={{
          monthly_commitment: state?.monthly_commitment,
          building: state?.building,
          selected_date: moment().format("Y-M-D"),
        }}
        afterDeleteRow={({ index }: { index: number }) => {
          const tempPromoDateRanges = { ...state.promoDateRanges };
          const tempDiscountDateRanges = {
            ...state?.discountDateRanges,
          };
          const tempCallbackDataInfo = {
            ...state?.callback_data_additional_discount,
          };
          delete tempCallbackDataInfo[index];
          tempPromoDateRanges.availableRange?.splice(index, 1);
          tempPromoDateRanges.selectedDate.splice(index, 1);
          tempDiscountDateRanges.availableRange?.splice(index, 1);
          tempDiscountDateRanges.selectedDate.splice(index, 1);
          setState((prevState) => ({
            ...prevState,
            promoDateRanges: tempPromoDateRanges,
            discountDateRanges: tempDiscountDateRanges,
            callback_data_additional_discount: tempCallbackDataInfo,
          }));
        }}
        columns={[
          {
            name: "category",
            type: "select",
            title: "Category",
            data: [
              { label: "Discount", value: 1 },
              {
                label: "Promo",
                value: 2,
                disabled: id && state["subscription_type"] === 2,
              },
            ],
            default: 1,
            disabledAtEdit: true,
            isClearable: false,
            afterChange: ({
              index,
              tempState,
            }: {
              index: number;
              tempState: Record<string, any>[];
            }) => {
              const tempAdditionalDiscounts = [...tempState];
              delete tempAdditionalDiscounts[index]?.one_time;
              delete tempAdditionalDiscounts[index]?.start_date;
              delete tempAdditionalDiscounts[index]?.end_date;
              delete tempAdditionalDiscounts[index]?.discount;
              setState((prevState) => ({
                ...prevState,
                t_discount_orders: tempAdditionalDiscounts,
              }));
            },
          },
          {
            name: "discount",
            type: "async_select",
            title: "Discount/Promo Name",
            disabledAtEdit: true,
            isRequired: true,
            isUniqueOption: true,
            dependsOn: "category",
            width: 300,
            data: "promotion/discount_master/?ordering=-id&__type__=select_entries&is_sop_filter=1",
            isClearable: false,
            itemsExtractor: (row: {
              name: string;
              id: number;
              start_date: string;
              end_date: string;
              minimum_monthly_commitment: number;
              one_time: boolean;
            }) => {
              return {
                label: row["name"],
                value: row["id"],
                start_date: row["start_date"],
                end_date: row["end_date"],
                minimum_monthly_commitment:
                  row["minimum_monthly_commitment"],
                one_time: row["one_time"],
              };
            },
            readOnly: true,
          },
          {
            name: "start_date",
            type: "date",
            isRequired: true,
            title: "Date Started",
            readOnly: true,
          },
          {
            name: "end_date",
            type: "date",
            title: "Date Ended",
            isRequired: false,
            readOnly: true,
          },
          {
            name: "one_time",
            title: "One Time",
            type: "checkbox",
            isDisabled: true,
            readOnly: true,
          },
          {
            name: "is_after_order_creation",
            type: "checkbox",
            title: "Flag",
            helperText: "For Devs Purpose",
            readOnly: true,
            dynamicValue: true,
            dataList: state?.[tab.id],
            hideColumnByCSS: true,
          },
        ]}
      />
    </TabPaneSecondary>
  );
}

export default withRouter(OrderAdditionalDiscounts);
