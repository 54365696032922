import { useState } from "react";

import { useParams, withRouter } from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import Form from "../../../../components/ubold/organisms/Form";

import CVTextField from "../../../../components/ubold/molecules/forms/CVTextField";
import CVAsyncSelect from "components/ubold/molecules/forms/CVAsyncSelect";
import CVSelect from "components/ubold/molecules/forms/CVSelect";
import CVCheckBox from "components/ubold/molecules/forms/CVCheckBox";
import definitions from "./definitions";

function CAddEditPage() {
  let { id } = useParams();

  const initialState = {};

  const [state, setState] = useState(initialState);

  return (
    <Page
      title={
        (id === undefined ? "Add New" : "Edit") +
        " " +
        definitions.title
      }
      restAccessCode={definitions.restAccessCode}
    >
      <Form
        id={id}
        definitions={definitions}
        state={state}
        setState={setState}
      >
        <CVTextField
          name="name"
          title="Name"
          maxLength={254}
          isRequired
        />
        <CVSelect
          name="country"
          title="Country"
          data="place/country?__type__=select_entries&limit=1000"
          isRequired
        />
        <CVTextField
          name="name_en"
          title="Name EN"
          maxLength={254}
          isRequired
        />

        <CVAsyncSelect
          name="province"
          title="Province"
          data="place/province?__type__=select_entries&limit=10"
          dependsOn="country"
          dependsOnStartFirst={true}
          isRequired
        />
        <CVAsyncSelect
          name="category"
          title="Category"
          isRequired
          data="building/category-poi?__type__=select_entries&limit=10"
        />
        <CVAsyncSelect
          name="city"
          title="City"
          data="place/city?__type__=select_entries&limit=10"
          dependsOn="province"
          isRequired
        />
        <CVTextField name="lat" title="Latitude" isRequired />
        <CVAsyncSelect
          name="district"
          title="District"
          data="place/district?__type__=select_entries&limit=10"
          dependsOn="city"
          isRequired
        />
        <CVTextField name="lon" title="Longitude" isRequired />

        <CVAsyncSelect
          name="subdistrict"
          title="Sub District"
          data="place/subdistrict?__type__=select_entries&limit=10"
          dependsOn="district"
          isRequired
        />
        <CVTextField name="address" title="Address" isRequired />
        <CVTextField
          name="tag"
          title="Alias"
          helperText="separate by comma, and without space"
        />
        <CVCheckBox name="booster" title="Boost POI" />
      </Form>
    </Page>
  );
}

export default withRouter(CAddEditPage);
