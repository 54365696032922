import CryptoAES from 'crypto-js/aes';
import CryptoENC from 'crypto-js/enc-utf8';

import config from '../configs'

const crypt = {
    k_ch1: "MCcpwQZqi9vr",
    k_ch2: "F586KW0bFwwn",
    k_ch3: "lM4rR5dWocaT",
    k_ch4: "wPd6W1tVUYXt",
    k_ch5: "vV8wQn5rqhCt",

    shuffles: () => {
        let key = config.s_key;

        key = crypt.k_ch2 + crypt.k_ch1 + crypt.k_ch5 + key + crypt.k_ch3 + crypt.k_ch4

        return key;
    },

    encrypt: (data) => {
        const key = crypt.shuffles();

        return CryptoAES.encrypt(data ? data.toString() : '', key).toString();
    },

    decrypt: (data) => {
        const key = crypt.shuffles();
        if(data) {
            return CryptoAES.decrypt(data, key).toString(CryptoENC);
        } else {
            return '';
        }
    }
}

export default crypt;