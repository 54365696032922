import { useParams, withRouter } from "react-router-dom";

import TabPaneSecondary from "../../../../../../components/ubold/organisms/TabPaneSecondary";

import COneOrManyToManyField from "../../../../../../components/ubold/molecules/forms/COneOrManyToManyField";

function RoleAccesses(props: any) {
  const { definitions, tab, state, setState } = props;

  let { id }: { id: string } = useParams();

  return (
    <TabPaneSecondary
      definitions={definitions}
      tab={tab}
      restAccessCodeNamespace="access"
      state={state}
      setState={setState}
    >
      <COneOrManyToManyField
        id={id}
        name={tab.id}
        readOnly
        restPath="team/role__access/"
        relationKey="role_id"
        columns={[
          {
            name: "access",
            type: "async_select",
            title: "Access",
            isRequired: true,
            disabledAtEdit: true,
            data: "team/access/?__type__=select_entries",
            itemsExtractor: (row: { code: string; id: string }) => {
              return {
                label: row.code,
                value: row.id,
              };
            },
            isReadOnly: true,
          },
          {
            name: "note",
            type: "text",
            title: "Note",
            maxLength: 255,
            isReadOnly: true,
          },
        ]}
      />
    </TabPaneSecondary>
  );
}

export default withRouter(RoleAccesses);
