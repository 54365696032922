import {useState} from "react";

import {useParams, withRouter} from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import definitions from "./definitions";

import TabHeaders from "../../../../components/ubold/organisms/TabHeaders";
import TabContent from "../../../../components/ubold/organisms/TabContent";
import TabbedForm from "../../../../components/ubold/organisms/TabbedForm";

import Agreement from "./Tabs/Agreement";
import TiringEscalation from "./Tabs/TiringEscalation";

function CAddEditPage() {
    let {id} = useParams();

    const initialState = {
        collateral_deposit: 0,
        contract_length: 1,
        payment_schedule: 1,
        rental_fee_base_on_the_agreement: 0,
        total_room_count: 0,
        service_charge: 1,
        total_capex_budget: 0,
        total_actual_renovation_costs: 0,
        revenue_sharing: false,
        revenue_threshold_amount: 0,
        revenue_sharing_percentage: 0
    };

    const [state, setState] = useState(initialState);

    return (
        <Page title={(id === undefined ? 'Add New' : 'Edit') + ' ' +  definitions.title}
              restAccessCode={definitions.restAccessCode}>
            <TabbedForm
                id={id}
                state={state}
                setState={setState}
                definitions={definitions}>
                <TabHeaders />
                <TabContent>
                    <Agreement />
                    <TiringEscalation />
                </TabContent>
            </TabbedForm>
        </Page>
    );
}

export default withRouter(CAddEditPage);