import configBuilder from "../../../../components/ubold/ConfigBuilder";

import pageDefinitions from "../definitions";

const definitions = {
  ...pageDefinitions,
  tabs: [
    {
      id: "order",
      name: "Order",
    },
    {
      id: "t_order_details",
      name: "Order Details",
      alwaysEditable: false,
    },
    {
      id: "t_history_status_orders",
      name: "History Status Orders",
      alwaysEditable: true,
    },
  ],
};

export default configBuilder.buildAddEditPageConfig(
  definitions,
  [],
  ["created_by", "date_created", "date_updated"]
);
