import utils from "../../../../utils";

import configBuilder from '../../../../components/ubold/ConfigBuilder';

import pageDefinitions from "../definitions";

const definitions = {...pageDefinitions,
    validators: {
        tenant: (value) => {
            return utils.validator.validateRequired(value);
        },
        phone_number: (value) => {
            return utils.validator.validateRequired(value);
        },
        voucher: (value) => {
            return utils.validator.validateRequired(value);
        },
        status: (value) => {
            return utils.validator.validateRequired(value);
        }
    },
}

export default configBuilder.buildAddEditPageConfig(definitions, ['prove_transferred_original']);