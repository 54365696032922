import {useState} from "react";
import {useParams, withRouter} from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import Form from "../../../../components/ubold/organisms/Form";

import CVTextField from "../../../../components/ubold/molecules/forms/CVTextField";
import CVSelect from "../../../../components/ubold/molecules/forms/CVSelect";
import CVAsyncSelect from "../../../../components/ubold/molecules/forms/CVAsyncSelect";
import CVRichTextField from "../../../../components/ubold/molecules/forms/CVRichTextField";
import CVDateTimePicker from "../../../../components/ubold/molecules/forms/CVDateTimePicker";
import CVSwitch from "../../../../components/ubold/molecules/forms/CVSwitch";

import definitions from "./definitions";

function DiscountAddEditPage() {
    let {id} = useParams();

    const initialState = {
        discount_type: 'flat',
        show_discount_period: true
    };

    const [state, setState] = useState(initialState);

    return (
        <Page title={(id === undefined ? 'Add New' : 'Edit') + ' ' +  definitions.title}
              restAccessCode={definitions.restAccessCode}>
            <Form
                id={id}
                definitions={definitions}
                state={state}
                setState={setState}>
                <CVTextField
                    title='Name'
                    name='name'
                    maxLength={200}
                    isRequired
                />
                <CVAsyncSelect
                    title='Category'
                    name='category'
                    data='finance/discount_category?__type__=select_entries'
                />
                <CVSelect
                    title='Type'
                    name='types'
                    data={[
                        {
                            label: 'Employee',
                            value: 1
                        }, {
                            label: 'Referal',
                            value: 2
                        }
                    ]}
                />
                <CVSelect
                    title='Discount Type'
                    name='discount_type'
                    isRequired
                    data={[
                        {
                            label: 'Percent',
                            value: 'percent'
                        }, {
                            label: 'Flat',
                            value: 'flat'
                        }
                    ]}
                />
                <CVTextField
                    title='Amount'
                    name='amount'
                    type='number'
                />
                <CVDateTimePicker
                    title='Date Started'
                    name='date_started'
                    type='datetime'
                    isRequired
                />
                <CVDateTimePicker
                    title='Date Ended'
                    name='date_ended'
                    type='datetime'
                    isRequired
                />
                <CVTextField
                    title='Period (in Month)'
                    name='valid_period'
                    type='number'
                />
                <CVSwitch
                    title='Is Stackable'
                    name='is_stackable'
                />
                <CVSwitch
                    title='Show discount end date in the website'
                    name='show_discount_period'
                />
                <CVRichTextField
                    title='Description'
                    name='description'
                />
            </Form>
        </Page>
    );
}

export default withRouter(DiscountAddEditPage);