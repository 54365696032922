import Print from "pages/Print/Print";

const PrintModule = [
  {
    path: "/print",
    component: Print,
  },
];

export default PrintModule;
