import { useState, useEffect } from "react";

import { useParams, withRouter } from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import Form from "../../../../components/ubold/organisms/Form";

import CVTextField from "../../../../components/ubold/molecules/forms/CVTextField";

import definitions from "./definitions";
import styled from "styled-components";

function CAddEditPage() {
  let { id } = useParams();

  const initialState = {
    valueinfo: "{}",
    fields: [],
  };

  const [state, setState] = useState(initialState);

  // Load initial data
  useEffect(() => {
    const initialData = JSON.parse(state.valueinfo);
    const initialFields = Object.entries(initialData).map(
      ([key, value]) => ({
        key,
        value,
      })
    );

    setState({
      ...state,
      fields: initialFields,
    });

    if (state.fields.length === 0) {
      handleAdd();
    }
  }, [state.valueinfo]);

  const handleAdd = () => {
    setState({
      ...state,
      fields: [...state.fields, { key: "", value: "" }],
    });
  };

  // This is a helper function that abstracts the common operation of modifying fields and setting state.
  // It takes a callback function as an argument, which performs the specific modification on fields.
  const updateFieldsAndState = (callback) => {
    // Create a shallow copy of fields from state
    let fields = [...state.fields];

    // Call the passed callback function with the fields copy, and reassign the modified fields
    fields = callback(fields);

    // Convert the fields array to an object
    const outputData = Object.fromEntries(
      fields.map(({ key, value }) => [key, value])
    );

    // Update state using a function to ensure we always have the latest state
    setState((prevState) => ({
      ...prevState,
      valueinfo: JSON.stringify(outputData),
      fields: fields,
    }));
  };

  // Remove the field at the given index
  const handleRemove = (index) => {
    updateFieldsAndState((fields) => {
      fields.splice(index, 1); // Remove the field from the copied fields array
      return fields;
    });
  };

  // Update the key of the field at the given index
  const handleKeyChange = (index, event) => {
    updateFieldsAndState((fields) => {
      // Make a deep copy of the field to be updated, and update its key
      const updatedField = [...fields];
      updatedField[index].key = String(event.target.value);
      return updatedField;
    });
  };

  // Update the value of the field at the given index
  const handleValueChange = (index, event) => {
    updateFieldsAndState((fields) => {
      // Make a deep copy of the field to be updated, and update its value
      const updatedField = [...fields];
      updatedField[index].value = event.target.value;
      return updatedField;
    });
  };

  return (
    <Page
      title={
        (id === undefined ? "Add New" : "Edit") +
        " " +
        definitions.title
      }
      restAccessCode={definitions.restAccessCode}
    >
      <Form
        id={id}
        definitions={definitions}
        state={state}
        setState={setState}
      >
        <CVTextField
          name="category"
          title="Category"
          isRequired
          maxLength={45}
        />
        <CVTextField
          name="keyname"
          title="Key Name"
          maxLength={45}
          isRequired
        />
        {/* <CVTextArea name="valueinfo" title="Value Info" isRequired /> */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            width: "100%",
            marginLeft: "12px",
            marginRight: "12px",
          }}
        >
          <p className="font-weight-bold" style={{ fontSize: 16 }}>
            Configuration
          </p>
          <div
            style={{
              flex: 1,
              flexDirection: "row",
              display: "flex",
            }}
            className={`font-weight-bold ${
              state.fieldsError && "text-danger"
            }`}
          >
            <p style={{ flex: 1 }}>Label*</p>
            <p style={{ flex: 1, marginLeft: 10, marginRight: 10 }}>
              Value*
            </p>
            <div style={{ width: "100px" }} />
          </div>
          {state.fields.map((field, index) => (
            <div
              key={index}
              style={{
                flex: 1,
                flexDirection: "row",
                display: "flex",
                marginBottom:
                  index === state.fields.length - 1 ? 0 : 10,
              }}
            >
              <input
                style={{
                  flex: 1,
                  display: "flex",
                  color: "#000",
                }}
                className="form-control"
                type="text"
                placeholder="Label"
                value={field.key}
                onChange={(event) => handleKeyChange(index, event)}
                required
              />

              <input
                style={{
                  flex: 1,
                  display: "flex",
                  marginLeft: 10,
                  marginRight: 10,
                  color: "#000",
                }}
                className="form-control"
                type="text"
                placeholder="Value"
                value={field.value}
                onChange={(event) => handleValueChange(index, event)}
                required
              />
              <button
                style={{ width: "100px" }}
                type="button"
                onClick={() => handleRemove(index)}
                className="btn btn-outline-danger"
              >
                Remove
              </button>
            </div>
          ))}
          <InformationContainer>
            {/* Error Label if key is not valid */}
            {state.fieldsError ? (
              <span
                className="text-danger"
                style={{ fontSize: "12px" }}
              >
                {state.fieldsError}
              </span>
            ) : (
              <></>
            )}
          </InformationContainer>
          <button
            type="button"
            className="btn btn-outline-success"
            onClick={handleAdd}
            style={{ width: 200, height: 40, marginTop: 10 }}
          >
            Add field
          </button>
        </div>
      </Form>
    </Page>
  );
}

export default withRouter(CAddEditPage);
const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
