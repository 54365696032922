import configBuilder from "../../../../components/ubold/ConfigBuilder";
import pageDefinitions from "../definitions";

import utils from "../../../../utils";

const definitions = {
  ...pageDefinitions,
  linkHistory: (id: string) =>
    `${definitions.frontendPath}/history/${id}`,
  additionalValidatorWithoutTab: [
    {
      id: "sub_categories",
      name: "Sub Categories",
    },
  ],
  validators: {
    name: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    name_en: (value: string) => {
      return utils.validator.validateRequired(value);
    },
  },
  sortStateBeforeSave: true,
  sortKey: "flag",
  targetSort: "sub_categories",
};

export default configBuilder.buildAddEditPageConfig(definitions);
