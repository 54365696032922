import configBuilder from "../../../components/ubold/ConfigBuilder";

import utils from "../../../utils";

let definitions = {
  title: "Interests",
  frontendPath: "/lead/tenant/interest",
  baseLink: "/lead/interest",
  restAccessCode: "lead.interest",
  __table__: {
    filterColumn: 4,
    addButton: false,
    hideEditButton: true,
    filters: [
      {
        id: "email",
        name: "Email",
        type: "text",
      },
      {
        id: "name",
        name: "Name",
        type: "text",
      },
      {
        id: "phone",
        name: "Phone",
        type: "text",
      },
      {
        id: "preferred_location",
        name: "Preferred Location",
        type: "text",
      },
      {
        id: "preferred_building",
        name: "Preferred Building",
        type: "text",
      },
      {
        id: "contact_preference",
        name: "Contact Preference",
        type: "select",
        data: {
          optionField: "lead_tenant_lead_contact_preference",
        },
      },
      {
        id: "budget",
        name: "Budget",
        type: "select",
        data: {
          optionField: "lead_tenant_lead_budget_choice",
        },
      },
      {
        id: "comment",
        name: "Comment",
        type: "text",
      },
      {
        id: "contact_time",
        name: "Contact Time",
        type: "date",
      },
      {
        id: "date_created",
        name: "Date Created",
        type: "date",
      },
      {
        id: "source",
        name: "Source",
        type: "text",
      },
      {
        id: "collection_id",
        name: "Collection ID",
        type: "number",
      },
      {
        id: "room_variant",
        name: "Room Variant",
        type: "text",
      },
      {
        id: "utm_source",
        name: "UTM Source",
        type: "text",
      },
      {
        id: "utm_medium",
        name: "UTM Medium",
        type: "text",
      },
      {
        id: "utm_campaign",
        name: "UTM Campaign",
        type: "text",
      },
      {
        id: "utm_content",
        name: "UTM Content",
        type: "text",
      },
      {
        id: "remarks",
        name: "Remarks",
        type: "text",
      },
      {
        id: "referral_code",
        name: "Referral Code",
        type: "text",
      },
      {
        id: "lead_type",
        name: "Lead Type",
        type: "select",
        data: {
          optionField: "lead_tenant_lead_type_interest",
        },
      },
    ],
    columns: [
      {
        id: "email",
        name: "Email",
        center: true,
      },
      {
        id: "name",
        name: "Name",
        center: true,
        enableHyperlink: true,
      },
      {
        id: "phone",
        name: "Phone",
        center: true,
      },
      {
        id: "preferred_location",
        name: "Preferred Location",
        center: true,
      },
      {
        id: "preferred_building",
        name: "Preferred Building",
        center: true,
      },
      {
        id: "lead_type",
        name: "Lead Type",
        center: true,
      },
      {
        id: "unit_type",
        name: "Unit Type",
        center: true,
      },
      {
        id: "contact_preference",
        name: "Contact Preference",
        center: true,
      },
      {
        id: "budget",
        name: "Budget",
        center: true,
      },
      {
        id: "comment",
        name: "Comment",
        center: true,
      },
      {
        id: "contact_time",
        name: "Contact Time",
        center: true,
        renderer: (value) => {
          return utils.formatter.dateTime(value);
        },
      },
      {
        id: "date_created",
        name: "Date Created",
        center: true,
        renderer: (value) => {
          return utils.formatter.dateTime(value);
        },
      },
      {
        id: "source",
        name: "Source",
        center: true,
      },
      {
        id: "collection_id",
        name: "Collection ID",
        center: true,
      },
      {
        id: "room_variant",
        name: "Room Variant",
        center: true,
      },
      {
        id: "utm_source",
        name: "UTM Source",
        center: true,
      },
      {
        id: "utm_medium",
        name: "UTM Medium",
        center: true,
      },
      {
        id: "utm_campaign",
        name: "UTM Campaign",
        center: true,
      },
      {
        id: "utm_content",
        name: "UTM Content",
        center: true,
      },
      {
        id: "remarks",
        name: "Remarks",
        center: true,
      },
      {
        id: "referral_code",
        name: "Referral Code",
        center: true,
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
