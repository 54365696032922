import LabelOption from "components/ubold/atoms/display/LabelOption";
import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";

type CFilterContainerProps = {
  filterName: string;
  labelList: {
    value: string | number;
    label: string;
  }[];
  handleRemoveItem: (name: string, param: number | string) => void;
};

const CFilterContainer: React.FC<CFilterContainerProps> = ({
  filterName,
  labelList,
  handleRemoveItem,
}) => {
  const [isShowExpand, setShowExpand] = useState<boolean>(false);
  const [isShowAll, setShowAll] = useState<boolean>(true);
  const refElement = useRef<HTMLDivElement>();
  useEffect(() => {
    if (refElement.current) {
      const rect = refElement.current.getBoundingClientRect();
      if (rect.height > 32) {
        setShowExpand(true);
        setShowAll(false);
      }
    }
  }, []);

  return (
    <CFilterContainerStyled ref={refElement} isShowAll={isShowAll}>
      <div className="name">{filterName}:</div>
      <div className="label-container mb-1">
        {(labelList ?? [])?.map((opt) => (
          <LabelOption
            key={opt.value + opt.label}
            label={opt.label}
            onClose={() => handleRemoveItem(filterName, opt.value)}
          />
        ))}
        {isShowExpand && isShowAll && (
          <button
            onClick={() => setShowAll((prev) => !prev)}
            className="link-btn ml-1 align-self-start"
          >
            View Less
          </button>
        )}
      </div>
      {isShowExpand && !isShowAll && (
        <button
          onClick={() => setShowAll((prev) => !prev)}
          className="link-btn ml-1 align-self-start"
        >
          View All
        </button>
      )}
    </CFilterContainerStyled>
  );
};

const CFilterContainerStyled = styled.div<{ isShowAll: boolean }>`
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  & .name {
    text-transform: capitalize;
    width: 60px;
    margin-right: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    align-self: start;
    flex-shrink: 0;
    margin-top: 2px;
  }

  .label-container {
    display: flex;
    column-gap: 8px;
    row-gap: 8px;
    flex-wrap: wrap;
    ${({ isShowAll }) =>
      !isShowAll &&
      css`
        height: 26px;
        overflow: hidden;
      `}
  }

  .link-btn {
    color: #35b0a7;
    background: none;
    border: none;
    padding: 0;
    flex-shrink: 0;
    white-space: nowrap;
    margin-top: 3px;
  }
`;

export default CFilterContainer;
