import { useParams, withRouter } from "react-router-dom";

import TabPaneSecondary from "../../../../../../components/ubold/organisms/TabPaneSecondary";

import COneOrManyToManyField from "../../../../../../components/ubold/molecules/forms/COneOrManyToManyField";

function RoleAccessFields(props: any) {
  const { definitions, tab, state, setState } = props;

  let { id }: { id: string } = useParams();

  return (
    <TabPaneSecondary
      definitions={definitions}
      tab={tab}
      restAccessCodeNamespace="access_field"
      state={state}
      setState={setState}
    >
      <COneOrManyToManyField
        id={id}
        name={tab.id}
        readOnly
        restPath="team/role__field_access/"
        relationKey="role_id"
        columns={[
          {
            name: "field_access",
            type: "async_select",
            title: "Field Access",
            isRequired: true,
            disabledAtEdit: true,
            data: "team/field_access?__type__=select_entries",
            itemsExtractor: (row: { code: string; id: string }) => {
              return {
                label: row.code,
                value: row.id,
              };
            },
            isReadOnly: true,
          },
          {
            name: "access",
            type: "select",
            title: "Access Behaviour",
            isRequired: true,
            data: [
              {
                label: "Read Only",
                value: 0,
              },
              {
                label: "Hidden",
                value: 1,
              },
            ],
            isReadOnly: true,
          },
        ]}
      />
    </TabPaneSecondary>
  );
}

export default withRouter(RoleAccessFields);
