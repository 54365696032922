import configBuilder from "../../../components/ubold/ConfigBuilder";
import utils from "../../../utils";

let definitions = {
  title: "Promotion Page",
  frontendPath: "/marketing/promotion/promotion",
  baseLink: "/promotion/promotion",
  restAccessCode: "promotion.promotion",
  __table__: {
    hideEditButton: true,
    filterColumn: 4,
    removeIdentifier: "url",
    filters: [
      {
        id: "is_published",
        name: "Published",
        type: "select",
        data: [
          {
            label: "Yes",
            value: true,
          },
          {
            label: "No",
            value: false,
          },
        ],
      },
    ],
    columns: [
      {
        id: "title",
        name: "Title",
        enableHyperlink: true,
      },
      {
        id: "start_date",
        name: "Start Date",
        type: "date",
      },
      {
        id: "end_date",
        name: "End Date",
        type: "date",
      },
      {
        id: "code",
        name: "Code",
      },
      {
        id: "sequence",
        name: "Sequence",
      },
      {
        id: "is_published",
        name: "Is Published",
        isSafeContent: true,
        center: true,
        renderer: (value) => {
          if (value === "true") {
            return '<span style="color: rgb(26, 188, 156);"> ✔ </span>';
          } else {
            return '<span style="color: red;">✘</span>';
          }
        },
      },
      {
        id: "date_updated",
        name: "Date Updated",
        renderer: (value) => {
          return utils.formatter.dateTime(value);
        },
      },
      {
        id: "date_created",
        name: "Date Created",
        renderer: (value) => {
          return utils.formatter.dateTime(value);
        },
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
