import { useState, useEffect } from "react";
import config from "configs";
import utils from "utils";
import _ from "lodash";
import moment from "moment";
import { InputActionMeta } from "react-select";

const useSearchableDropdown = (state: any, setState: any) => {
  const id = state["id"];
  const [tenantOptions, setTenantOptions] = useState([]);
  const [isTenantLoading, setIsTenantLoading] = useState(false);
  const [buildingOptions, setBuildingOptions] = useState([]);
  const [isBuildingLoading, setIsBuildingLoading] = useState(false);
  const [roomVariantOptions, setRoomVariantOptions] = useState([]);
  const [isRoomVariantLoading, setIsRoomVariantLoading] =
    useState(false);
  const [roomOptions, setRoomOptions] = useState([]);
  const [isRoomLoading, setIsRoomLoading] = useState(false);

  const [tenantKeyword, setTenantKeyword] = useState("");
  const [buildingKeyword, setBuildingKeyword] = useState("");

  const countDeposit = (
    depositRules: number,
    price: number
  ): number => {
    if (!price) return 0;

    switch (depositRules) {
      case 1:
        return 0;
      case 2:
        return 500000;
      case 3:
        return Math.ceil((50 / 100) * price);
      case 4:
        return price;
      case 5:
        return 1000000;
      default:
        return 200000;
    }
  };

  const editOrDuplicate = id || state.isDuplicate;
  const dropdownValues = {
    tenant: editOrDuplicate
      ? {
          // if there is an id
          label: `${state["tenant_name"]} ${
            state["tenant_email"] ? ` - ${state["tenant_email"]}` : ""
          } ${
            state["tenant_country_code"]
              ? ` - ${state["tenant_country_code"]}`
              : ""
          } ${
            state["tenant_phone_number"]
              ? state["tenant_phone_number"]
              : ""
          }`,
          value: state["tenant"],
        }
      : state["selectedTenant"],
    building: editOrDuplicate
      ? {
          label: `${state["building_name"]}`,
          value: state["building"],
        }
      : state["selectedBuilding"],
    roomVariant: editOrDuplicate
      ? {
          label: `${state["room_variant_name"]}`,
          value: state["room_variant"],
        }
      : state["selectedRoomVariant"],
    room: editOrDuplicate
      ? {
          label: `${state["room_name"]}`,
          value: state["room"],
        }
      : state["selectedRoom"],
  };

  // Hit get API when keyword changes

  const getOptions = (getOptions: () => void) => {
    const debounced = _.debounce(() => getOptions(), 500);
    return debounced;
  };

  useEffect(() => {
    if (!tenantKeyword) {
      return setTenantOptions([]);
    }
    const debounced = getOptions(getTenantOptions);
    debounced();

    return () => {
      debounced.cancel();
    };
  }, [tenantKeyword]);

  useEffect(() => {
    if (!buildingKeyword) {
      return setBuildingOptions([]);
    }
    const debounced = getOptions(getBuildingOptions);
    debounced();

    return () => {
      debounced.cancel();
    };
  }, [buildingKeyword]);

  useEffect(() => {
    if (!state["selectedBuilding"]) return;
    setRoomVariantOptions([]);
    setRoomOptions([]);
    const debounced = getOptions(getRoomVariantOptions);
    debounced();

    return () => {
      debounced.cancel();
    };
  }, [state["selectedBuilding"]]);

  useEffect(() => {
    if (!state["selectedRoomVariant"]) return;
    setRoomOptions([]);
    const debounced = getOptions(getRoomOptions);
    debounced();

    return () => {
      debounced.cancel();
    };
  }, [state["selectedRoomVariant"]?.value]);

  const handleTenantChange = (data: any) => {
    if (!data) {
      setState((previousState: any) => {
        const { selectedTenant, ...restState } = previousState;

        return {
          ...restState,
          tenant: "",
          tenant_label: "",
          tenant_name: "",
          tenant_object: {},
        };
      });
      return;
    }

    setState((previousState: any) => ({
      ...previousState,
      tenant: data.id,
      tenant_label: data.name,
      tenant_name: data.name,
      tenant_object: {
        label: data.name,
        value: data.id,
      },
      selectedTenant: {
        value: data.id,
        label: data.label,
        ...data,
      },
    }));

    setTenantOptions([]);
    setTenantKeyword("");
  };

  const handleBuildingChange = (data: any) => {
    if (!data) {
      setState((previousState: any) => {
        const { selectedBuilding, ...restState } = previousState;
        return {
          ...restState,
          allow_for_more_than_30_days: false,
          building: "",
          building_label: "",
          building_name: "",
          building_object: {},
        };
      });
      return;
    }
    setState((previousState: any) => ({
      ...previousState,
      allow_for_more_than_30_days: data.allow_for_more_than_30_days,
      building: data.id,
      building_label: data.label,
      building_name: data.label,
      building_object: {
        allow_for_more_than_30_days: data.allow_for_more_than_30_days,
        building_type: data.building_type,
        eligible_for_discount: data.eligible_for_discount,
        label: data.label,
        live_date: data.live_date,
        minimum_deposit: data.minimum_deposit,
        rukita_option: data.rukita_option,
        value: data.id,
      },
      live_date: data.live_date,
      building_type: data.building_type,
      deposit: 0,
      deposit_rules:
        previousState?.tenant_category === 2
          ? 1
          : data.minimum_deposit,
      deposit_holder: data?.deposit_holder
        ? data?.deposit_holder
        : undefined,
      eligible_for_discount: data.eligible_for_discount,
      selectedBuilding: {
        value: data.id,
        label: data.building_name,
        ...data,
      },
      room: "",
      room_label: "",
      room_name: "",
      room_object: {},
      selected_room_variant_price: 0,
      room_variant: "",
      room_variant_label: "",
      room_variant_name: "",
      room_variant_object: {},
      selectedRoomVariant: {},
      selectedRoom: {},
    }));

    setBuildingOptions([]);
    setBuildingKeyword("");
  };

  const handleRoomVariantChange = (data: any) => {
    if (!data) {
      setState((previousState: any) => {
        const { selectedRoomVariant, ...restState } = previousState;
        return {
          ...restState,
          selected_room_variant_price: 0,
          deposit: 0,
          room_variant: "",
          room_variant_label: "",
          room_variant_name: "",
          room_variant_object: {},
          selectedRoomVariant: {},
          room: "",
          room_label: "",
          room_name: "",
          room_object: {},
          selectedRoom: {},
        };
      });
      return;
    }
    removeSelectedRoom();
    setState((previousState: any) => ({
      ...previousState,
      selected_room_variant_price: data.price,
      deposit: countDeposit(previousState?.deposit_rules, data.price),
      selectedRoomVariant: {
        value: data.id,
        label: data.name,
        ...data,
      },
      room_variant: data.id,
      room_variant_label: data.name,
      room_variant_name: data.name,
      room_variant_object: {
        room_variant: data.id,
        room_variant_label: data.name,
        price: data.price,
      },
    }));
  };

  const handleRoomChange = (data: any) => {
    setState((previousState: any) => ({
      ...previousState,
      room: data.id,
      room_label: data.name,
      room_name: data.name,
      room_object: {
        label: data.name,
        value: data.id,
        price: data.price,
        available_on: data.available_on,
      },
      selectedRoom: {
        value: data.id,
        label: data.name,
        ...data,
      },
    }));
  };

  const removeSelectedRoom = () => {
    setState((previousState: any) => {
      const { selectedRoom, ...restState } = previousState;

      return {
        ...restState,
        room: "",
        room_label: "",
        room_name: "",
        room_object: {},
        selectedRoom: {},
      };
    });
  };

  const handleOnChange = (data: any, name: string) => {
    switch (name) {
      case "tenant":
        handleTenantChange(data);
        break;
      case "building":
        handleBuildingChange(data);
        break;
      case "room_variant":
        handleRoomVariantChange(data);
        break;
      case "room":
        handleRoomChange(data);
        break;
      default:
        break;
    }
  };

  const handleOnInputChange = (
    value: string,
    meta: InputActionMeta,
    name: string
  ) => {
    if (
      meta.action !== "input-blur" &&
      meta.action !== "menu-close"
    ) {
      switch (name) {
        case "tenant":
          setTenantKeyword(value);
          break;
        case "building":
          setBuildingKeyword(value);
          break;
        default:
          break;
      }
    }
  };

  const getTenantOptions = () => {
    setIsTenantLoading(true);
    const url = `${config.apiUrl}user/tenant?__type__=select_entries&keyword=${tenantKeyword}`;
    utils.httpClient.get(
      url,
      ({ data }: any) => {
        const { rows } = data;
        const modifyRows = rows.map((row: any) => ({
          ...row,
          value: row.id,
          label: `${row.name} - ${row.email} - ${row.phone_number}`,
        }));
        setTenantOptions(modifyRows);
        setIsTenantLoading(false);
      },
      (error: any) => {
        setIsTenantLoading(false);
        console.error(error);
      }
    );
  };

  const getBuildingOptions = () => {
    setIsBuildingLoading(true);
    const buildingId = state["building"];
    const url = `${config.apiUrl}building/building?__type__=select_entries&is_sop_filter=1&building_id=${buildingId}&keyword=${buildingKeyword}`;
    utils.httpClient.get(
      url,
      ({ data }: any) => {
        const { rows } = data;
        const modifyRows = rows.map((row: any) => ({
          ...row,
          value: row.id,
          label: `${row.building_name} - ${
            row.building_type === 1 ? "Centralized" : "Decentralized"
          } `,
        }));
        setBuildingOptions(modifyRows);
        setIsBuildingLoading(false);
      },
      (error: any) => {
        console.error(error);
        setIsBuildingLoading(false);
      }
    );
  };

  const getRoomVariantOptions = () => {
    setIsRoomVariantLoading(true);
    const url = `${config.apiUrl}building/room_variant?__type__=select_entries&is_sop_filter=1&building_id=${state["selectedBuilding"].value}`;
    utils.httpClient.get(
      url,
      ({ data }: any) => {
        const { rows } = data;
        const modifyRows = rows.map((row: any) => ({
          ...row,
          label: `${row.name}${
            !id ? `- ${utils.formatter.currency(row.price)}` : ""
          }`,
          value: row.id,
        }));
        setRoomVariantOptions(modifyRows);
        setIsRoomVariantLoading(false);
      },
      (error: any) => {
        console.error(error);
        setIsRoomVariantLoading(false);
      }
    );
  };

  const getRoomOptions = () => {
    setIsRoomLoading(true);
    const today = moment().format("YYYY-MM-DD");

    const url = `${config.apiUrl}building/room?__type__=select_entries&selected_date=${today}&room_variant_id=${state["selectedRoomVariant"].value}`;

    utils.httpClient.get(
      url,
      ({ data }: any) => {
        const { rows } = data;
        const modifyRows = rows.map((row: any) => ({
          ...row,
          label: `${row.name}${
            state?.building_type === 2
              ? `- ${utils.formatter.currency(row.price)}`
              : ""
          }`,
          value: row.id,
        }));
        setRoomOptions(modifyRows);
        setIsRoomLoading(false);
      },
      (error: any) => {
        console.error(error);
        setIsRoomLoading(false);
      }
    );
  };

  return {
    tenantOptions,
    buildingOptions,
    roomVariantOptions,
    roomOptions,
    tenantKeyword,
    buildingKeyword,
    isTenantLoading,
    isBuildingLoading,
    isRoomVariantLoading,
    isRoomLoading,
    handleOnChange,
    handleOnInputChange,
    dropdownValues,
  };
};

export default useSearchableDropdown;
