import { useEffect, useState } from "react";

import { useParams, withRouter } from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";
import TabbedForm from "../../../../components/ubold/organisms/TabbedForm";
import TabHeaders from "../../../../components/ubold/organisms/TabHeaders";
import TabContent from "../../../../components/ubold/organisms/TabContent";

import TabCartView from "./Tabs/CartView/index";

import definitions from "./definitions";

function OTPCartDetailPage() {
  let { id } = useParams();

  const [state, setState] = useState({});

  useEffect(() => {
    definitions.onInit(id, setState);
  }, []);

  return (
    <Page
      title={"Detail Cart"}
      restAccessCode={definitions.restAccessCode}
    >
      <TabbedForm
        id={id}
        state={state}
        setState={setState}
        definitions={definitions}
      >
        <TabHeaders />
        <TabContent>
          <TabCartView />
        </TabContent>
      </TabbedForm>
    </Page>
  );
}

export default withRouter(OTPCartDetailPage);
