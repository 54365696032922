import { WrapperProps } from "@types";

const HorizontalWrapper = (props: WrapperProps) => {
  const {
    title,
    isRequired = false,
    isReadOnly = false,
    errorMessage,
    helperMessage,
    children,
  } = props;

  return (
    <div
      className="form-group row mb-3"
      style={{
        display: "flex",
      }}
    >
      <label
        className={
          "col-md-3 col-form-label" +
          (isRequired ? " font-weight-bold" : "") +
          (errorMessage ? " text-danger" : "")
        }
      >
        {title}
        {isRequired ? "*" : ""} {isReadOnly ? "(read only)" : ""}
      </label>
      <div className="col-md-9">
        {children}
        {helperMessage ? (
          <span className="text-muted font-13 pt-1 mb-0">
            {helperMessage}
          </span>
        ) : null}
        {helperMessage && errorMessage ? <br /> : null}
        {errorMessage ? (
          <span className="text-danger">{errorMessage}</span>
        ) : null}
      </div>
    </div>
  );
};

export default HorizontalWrapper;
