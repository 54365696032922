import React, { HTMLAttributes } from "react";
import styled from "styled-components";

const Loader: React.FC<HTMLAttributes<HTMLDivElement>> = (props) => {
  return (
    <LoaderContainer {...props}>
      <LoaderStyled />
    </LoaderContainer>
  );
};

export default Loader;

const LoaderStyled = styled.div`
  border: 10px solid #f7f7f7;
  border-radius: 50%;
  border-top: 10px solid #00bbb4;
  border-bottom: 10px solid #00bbb4;
  width: 64px;
  height: 64px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 0 16px 0;
`;
