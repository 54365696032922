import { CSSProperties } from "react";
import Logo from "../../../../assets/logo-dark.png";
import QRCode from "react-qr-code";

type Props = {
  type: "PRINT" | "DOWNLOAD" | "PREVIEW";
  roomData: {
    building_name: string;
    name: string;
    building: string;
    room_variant: string;
    external_id: string;
  };
  /**
   * @description if true, it will apply certain class that will make the item gives  page brake for the next item in the position
   */
  isUsePageBreak?: boolean;
};

function RoomQR({ type = "PRINT", roomData, isUsePageBreak }: Props) {
  const { building, building_name, external_id, room_variant, name } =
    roomData;
  const env = process.env.REACT_APP_ENV_NAME;
  const urlBasedOnEnv =
    env === "production"
      ? "https://www.rukita.co"
      : "https://harbor.imarukita.ninja";
  const roomUrl = `${urlBasedOnEnv}/book/verify?assetId=${building}&variantId=${room_variant}&externalRoomId=${external_id}&qr=1&utm_campaign=offline&utm_source=offline&utm_medium=offline&utm_content=offline`;

  function determineFontSize(
    nameLength: number,
    scaleFactor: number = 1
  ) {
    let fontSize: number;

    if (nameLength > 10) {
      fontSize = 12;
    } else if (nameLength > 7) {
      fontSize = 18;
    } else if (nameLength > 4) {
      fontSize = 28;
    } else {
      fontSize = 50;
    }

    return fontSize * scaleFactor;
  }

  function determineWidthSize(
    nameLength: number,
    scaleFactor: number = 1
  ) {
    let width: number;

    if (nameLength > 10) {
      width = 132;
    }

    return width * scaleFactor;
  }

  const paragraphLargeWidth = determineWidthSize(
    name?.length || 0,
    type === "DOWNLOAD" ? 2 : type === "PRINT" ? 1.332 : 1
  );

  const fontSize = determineFontSize(
    name?.length || 0,
    type === "DOWNLOAD" ? 2 : type === "PRINT" ? 1.332 : 1
  );

  const styles =
    type === "DOWNLOAD"
      ? downloadStyles
      : type === "PRINT"
      ? printStyles
      : displayStyles;

  return (
    <div
      id={type === "DOWNLOAD" ? "download-4r" : "preview-download-4r"}
      style={{
        ...(styles.container as CSSProperties),
        position: type === "DOWNLOAD" ? "absolute" : "relative",
        left: type === "DOWNLOAD" ? "-2000px" : "0",
        top: type === "DOWNLOAD" ? "-2000px" : "0",
      }}
      className={isUsePageBreak ? "page-break-after" : ""}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div style={styles.innerDiv as CSSProperties}>
          <img src={Logo} style={styles.img as CSSProperties} />
          <div style={styles.qrCode as CSSProperties}>
            <QRCode
              value={roomUrl}
              style={{
                height: "auto",
                maxWidth: "100%",
                width: "100%",
              }}
              bgColor="#F7F2E8"
              fgColor="#023C39"
              viewBox="0 0 258 258"
            />
          </div>
          <p style={styles.scanText as CSSProperties}>
            SCAN HERE TO BOOK YOUR ROOM
          </p>
        </div>
        <div style={styles.roomContainer as CSSProperties}>
          <p
            style={{
              fontSize: `${fontSize}px`,
              maxWidth:
                name?.length > 10
                  ? `${paragraphLargeWidth}px`
                  : "auto",
              ...(styles.paragraphLarge as CSSProperties),
            }}
          >
            {name}
          </p>
          <p style={styles.paragraphSmall as CSSProperties}>
            {building_name}
          </p>
        </div>
      </div>
    </div>
  );
}

export default RoomQR;

type StyleObject = {
  [key: string]: string | number | StyleObject;
};

const scaleStyle = (
  style: StyleObject,
  scale: number
): StyleObject => {
  const scaledStyle: StyleObject = {};
  for (const key in style) {
    const value = style[key];
    if (typeof value === "object") {
      scaledStyle[key] = scaleStyle(value as StyleObject, scale);
    } else if (typeof value === "string" && value.includes("px")) {
      const numericValue = parseInt(value.replace("px", "")) * scale;
      scaledStyle[key] = `${numericValue}px`;
    } else {
      scaledStyle[key] = value;
    }
  }
  return scaledStyle;
};

const commonStyles: StyleObject = {
  container: {
    backgroundColor: "#F7F2E8",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "justify-between",
  },
  roomContainer: {
    backgroundColor: "#023C39",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  scanText: {
    color: "#023C39",
    fontWeight: 700,
  },
  paragraph: {
    color: "#F7F2E8",
    fontWeight: 500,
    fontFamily: "Morebi",
    textAlign: "center",
  },
};

const displayStyles: StyleObject = {
  container: {
    ...(commonStyles.container as CSSProperties),
    width: "198px",
    height: "281px",
  },
  innerDiv: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  img: {
    marginTop: "20px",
    width: "60px",
    height: "20px",
  },
  qrCode: {
    marginTop: "12px",
    height: "97px",
    width: "97px",
  },
  scanText: {
    ...(commonStyles.scanText as CSSProperties),
    marginTop: "6px",
    fontSize: "7px",
  },
  roomContainer: {
    ...(commonStyles.roomContainer as CSSProperties),
    width: "100%",
    height: "104px",
    borderTopLeftRadius: "40px",
  },
  paragraphLarge: {
    ...(commonStyles.paragraph as CSSProperties),
    fontWeight: "500",
    lineHeight: "24px",
    fontFamily: "MorebiBlack",
    marginTop: 32,
  },
  paragraphSmall: {
    ...(commonStyles.paragraph as CSSProperties),
    fontSize: "8px",
    width: "127px",
  },
};

const downloadStyles: StyleObject = scaleStyle(displayStyles, 2);
const printStyles: StyleObject = scaleStyle(displayStyles, 2);
