import { withRouter } from "react-router-dom";

import TabPane from "../../../../../../components/ubold/organisms/TabPane";
import TabPaneSection from "../../../../../../components/ubold/organisms/TabPaneSection";

import CVTextView from "../../../../../../components/ubold/molecules/forms/CVTextView";

import utils from "../../../../../../utils";

function TabInvoice(props) {
  const { id, definitions, tab, state, setState } = props;

  return (
    <TabPane
      id={id}
      definitions={definitions}
      tab={tab}
      hideAddAnother
      readOnly
      state={state}
      setState={setState}
    >
      <TabPaneSection multipleColumn style={{ padding: "25px" }}>
        <CVTextView title="Number" name="number" />
        <CVTextView title="Order" name="order_text" />
        <CVTextView title="Tenant" name="tenant_text" />
        <CVTextView title="Status" name="otp_status_text" />
        <CVTextView
          title="Additional Number"
          name="additional_number"
        />
        <CVTextView
          title="Service Fee"
          name="service_fee"
          renderer={(value) => {
            return utils.formatter.currency(value);
          }}
        />
        <CVTextView
          title="Delivery Fee"
          name="delivery_fee"
          renderer={(value) => {
            return utils.formatter.currency(value);
          }}
        />
        <CVTextView
          title="Grand Total"
          name="grand_total"
          renderer={(value) => {
            return utils.formatter.currency(value);
          }}
        />
        <CVTextView
          title="Payment Method"
          name="payment_method_text"
        />
        <CVTextView
          title="Date Created"
          name="date_created"
          renderer={(value) => {
            return utils.formatter.dateTime(value);
          }}
        />
        <CVTextView
          title="Date Updated"
          name="date_updated"
          renderer={(value) => {
            return utils.formatter.dateTime(value);
          }}
        />
      </TabPaneSection>
    </TabPane>
  );
}

export default withRouter(TabInvoice);
