import configBuilder from "../../../components/ubold/ConfigBuilder";

import utils from "../../../utils";

let definitions = {
  title: "Checkout Reasons",
  frontendPath: "/admin/order/checkout_reason",
  baseLink: "/order/checkout_reason",
  restAccessCode: "order.checkout_reason",
  __table__: {
    hideEditButton: true,
    filterColumn: 4,
    filters: [
      {
        id: "name",
        name: "Name",
        type: "text",
      },
      {
        id: "category",
        name: "Checkout Category",
        type: "async_select",
        data: "order/checkout_category?__type__=select_entries",
      },
      {
        id: "date_created",
        name: "Date Created",
        type: "date",
      },
      {
        id: "date_updated",
        name: "Date Updated",
        type: "date",
      },
    ],
    columns: [
      {
        id: "name",
        name: "Name",
        enableHyperlink: true,
      },
      {
        id: "category_name",
        name: "Checkout Category",
      },
      {
        id: "date_created",
        name: "Date Created",
        renderer: (value) => {
          return utils.formatter.dateTime(value);
        },
      },
      {
        id: "date_updated",
        name: "Date Updated",
        renderer: (value) => {
          return utils.formatter.dateTime(value);
        },
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
