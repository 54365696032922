import { withRouter } from "react-router-dom";

import TabPane from "../../../../../../components/ubold/organisms/TabPane";
import TabPaneSection from "../../../../../../components/ubold/organisms/TabPaneSection";
import CHTextField from "../../../../../../components/ubold/molecules/forms/CHTextField";
import CHTextView from "../../../../../../components/ubold/molecules/forms/CHTextView";
import CHSelect from "../../../../../../components/ubold/molecules/forms/CHSelect";
import CHAsyncSelect from "../../../../../../components/ubold/molecules/forms/CHAsyncSelect";
import CHTextArea from "../../../../../../components/ubold/molecules/forms/CHTextArea";
import CHDateTimePicker from "../../../../../../components/ubold/molecules/forms/CHDateTimePicker";

function Service(props) {
  const { id, definitions, tab, state, setState } = props;

  return (
    <TabPane
      id={id}
      definitions={definitions}
      tab={tab}
      state={state}
      setState={setState}
    >
      <TabPaneSection style={{ padding: "25px 16px 0px" }}>
        <CHAsyncSelect
          name="order_new"
          title="Order"
          data="order_new/order?__type__=select_entries&with_related=1"
          isRequired
          itemsExtractor={(value) => {
            return {
              label:
                value["tenant__name"] +
                " - " +
                value["building__building_name"] +
                " | " +
                value["room_variant__name"] +
                " | " +
                value["room__name"] +
                " (" +
                value.number +
                ")",
              value: value.id,
            };
          }}
        />
        <CHAsyncSelect
          name="tenant"
          title="Tenant"
          dependsOn="order"
          data="user/tenant?__type__=select_entries"
          isRequired
          itemsExtractor={(row) => {
            return {
              label:
                row.name +
                " - " +
                row.email +
                " - " +
                row.country_code +
                row.phone_number,
              value: row.id,
            };
          }}
        />
        <CHAsyncSelect
          name="building_service"
          title="Building Service"
          dependsOn="order"
          isRequired
          data="building/building__service?__type__=select_entries"
        />
        <CHAsyncSelect
          name="service"
          title="Service"
          readOnly
          data="building/service?__type__=select_entries"
        />
        <CHTextView name="pic" title="Pic" />
        <CHTextArea name="notes" title="Notes" />
        <CHSelect
          name="status"
          title="Status"
          isRequired
          data={[
            {
              label: "Waiting",
              value: 1,
            },
            {
              label: "Processing",
              value: 2,
            },
            {
              label: "Done",
              value: 4,
            },
            {
              label: "Cancel",
              value: 5,
            },
          ]}
        />
        <CHDateTimePicker
          name="estimation_date"
          title="Estimation Date"
          type="date"
        />
        <CHDateTimePicker
          name="estimation_time"
          title="Estimation Time"
          type="time"
        />
        <CHTextArea
          name="estimation_reason"
          title="Comment from Rukita"
        />
        <CHSelect
          name="rate"
          title="Rate"
          data={[
            {
              label: "Yes",
              value: true,
            },
            {
              label: "No",
              value: false,
            },
          ]}
        />
        <CHTextArea name="comment" title="Comment" />
        <CHTextArea
          name="cancelation_notes"
          title="Cancellation Notes"
        />
        <CHSelect
          name="selected_days"
          title="Selected Days"
          data={[
            {
              label: "Senin",
              value: 1,
            },
            {
              label: "Selasa",
              value: 2,
            },
            {
              label: "Rabu",
              value: 3,
            },
            {
              label: "Kamis",
              value: 4,
            },
            {
              label: "Jumat",
              value: 5,
            },
            {
              label: "Sabtu",
              value: 6,
            },
            {
              label: "Minggu",
              value: 7,
            },
          ]}
        />
        <CHDateTimePicker
          name="selected_date"
          title="Schedule Date"
          type="date"
        />
        <CHTextView name="quota_periode" title="Quota Period" />
        <CHTextField
          name="used_quota"
          title="Used Quota"
          type="number"
        />
        <CHTextView name="available_quota" title="Available Quota" />
      </TabPaneSection>
    </TabPane>
  );
}

export default withRouter(Service);
