import { useState } from "react";

import { useParams, withRouter } from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import Form from "../../../../components/ubold/organisms/Form";

import definitions from "./definitions";
import CVSelect from "../../../../components/ubold/molecules/forms/CVSelect";
import CVTextField from "../../../../components/ubold/molecules/forms/CVTextField";
import CVTextArea from "../../../../components/ubold/molecules/forms/CVTextArea";
import CVRichTextField from "../../../../components/ubold/molecules/forms/CVRichTextField";
import CVSimpleManyToMany from "../../../../components/ubold/molecules/forms/CVSimpleManyToMany";
import CVSwitch from "../../../../components/ubold/molecules/forms/CVSwitch";
import CVImageView from "components/ubold/molecules/forms/CVImageView";

function CAddEditPage() {
  let { id } = useParams<{ id: string }>();

  const initialState = {
    collection_type: 1,
  };

  const [state, setState] = useState(initialState);

  return (
    <Page
      title={
        (id === undefined ? "Add New" : "Edit") +
        " " +
        definitions.title
      }
      restAccessCode={definitions.restAccessCode}
    >
      <Form
        id={id}
        definitions={definitions}
        state={state}
        setState={setState}
        readOnly
      >
        <CVSelect
          name="collection_type"
          title="Collection Type"
          isRequired
          data={[
            {
              label: "Building",
              value: 1,
            },
            {
              label: "Promo",
              value: 2,
            },
            {
              label: "Rukees Benefit",
              value: 3,
            },
          ]}
          readOnly
        />
        <CVTextField
          name="name"
          title="Name"
          maxLength={50}
          isRequired
          readOnly
        />
        <CVTextField
          name="slug"
          title="Slug"
          maxLength={50}
          isRequired
          readOnly
        />
        <CVTextField
          name="title_tag"
          title="Title Tag"
          maxLength={160}
          isRequired
          readOnly
        />
        <CVTextField
          name="display_name_on_footer"
          title="Display Name on Footer"
          maxLength={150}
          readOnly
        />
        <CVTextField
          name="title_tag_en"
          title="Title Tag EN"
          maxLength={160}
          readOnly
        />
        <CVTextArea
          name="meta_description"
          title="Meta Description"
          readOnly
        />
        <CVTextArea
          name="meta_description_en"
          title="Meta Description EN"
          readOnly
        />
        <CVRichTextField name="desc" title="Description" readOnly />
        <CVRichTextField
          name="desc_en"
          title="Description EN"
          readOnly
        />
        <CVImageView
          name="banner_photo_original"
          title="Banner for large screen"
          helperText="Ratio 2:1"
        />
        <CVImageView
          name="banner_photo_small_original"
          title="Banner for small screen"
          helperText="Ratio 2:1"
        />
        <CVSimpleManyToMany
          name="buildings"
          title="Buildings"
          data="building/building?__type__=select_entries"
          itemsExtractor={(value: {
            id: string;
            building_name: string;
          }) => {
            return {
              label: value.building_name,
              value: value.id,
            };
          }}
          readOnly
        />
        <CVSwitch name="published" title="Is Published?" readOnly />
      </Form>
    </Page>
  );
}

export default withRouter(CAddEditPage);
