import { useEffect, useState } from "react";
import { useParams, withRouter } from "react-router-dom";

import TabPaneSecondary from "../../../../../../components/ubold/organisms/TabPaneSecondary";

import COneOrManyToManyField from "../../../../../../components/ubold/molecules/forms/COneOrManyToManyField";
import checkValue from "../../../../../../utils/checkValue";

function AddonBuilding(props) {
  const { definitions, tab, state, setState } = props;
  const [checkResult, setCheckResult] = useState({});

  let { id } = useParams();

  useEffect(() => {
    const fieldWithCheckAll = [
      "exclude_ll_report",
      "visible",
      "is_publish",
    ];
    if (!state[tab.id]) return;
    for (let idx = 0; idx < fieldWithCheckAll.length; idx++) {
      const result = checkValue(
        state[tab.id],
        fieldWithCheckAll[idx]
      );
      setCheckResult((prevState) => ({
        ...prevState,
        [fieldWithCheckAll[idx]]: result,
      }));
    }
  }, [state[tab.id]]);

  return (
    <TabPaneSecondary
      definitions={definitions}
      restAccessCodeNamespace="addon_building"
      tab={tab}
      state={state}
      setState={setState}
    >
      <COneOrManyToManyField
        id={id}
        name={tab.id}
        restPath="building/addon__building/"
        relationKey="addon_id"
        setCheckResult={setCheckResult}
        saveOnlyFromMainForm={true}
        columns={[
          {
            name: "building",
            title: "Building",
            isRequired: true,
            isUniqueOption: true,
            type: "async_select",
            disabledAtEdit: true,
            data: "building/building?__type__=select_entries",
            itemsExtractor: (row) => {
              return {
                label: row.building_name,
                value: row.id,
              };
            },
          },
          {
            name: "exclude_ll_report",
            title: "Exclude LL Report",
            type: "checkbox",
            default: true,
            enableCheckAll: true,
            isCheckAll: checkResult["exclude_ll_report"],
          },
          {
            name: "payment_plan",
            title: "Payment Plan",
            type: "number",
          },
          {
            name: "price",
            title: "Price",
            type: "number",
            default: 0,
          },
          {
            name: "work_estimation",
            title: "Work Estimation",
            type: "number",
            default: 0,
          },
          {
            name: "visible",
            title: "Visible",
            type: "checkbox",
            default: true,
            enableCheckAll: true,
            isCheckAll: checkResult["visible"],
          },
          {
            name: "is_publish",
            title: "Published",
            type: "checkbox",
            default: false,
            enableCheckAll: true,
            isCheckAll: checkResult["is_publish"],
          },
        ]}
      />
    </TabPaneSecondary>
  );
}

export default withRouter(AddonBuilding);
