import styled from "styled-components";

const BreadcrumbBase = styled.nav`
  color: #999999;

  ul {
    display: flex;
    font-size: 12px;
    font-weight: 400;
    list-style: none;
    margin-bottom: unset;
    padding-inline-start: unset;

    li {
      &:not(:last-child) {
        margin-right: 10px;
      }

      &:first-child {
        color: #222222;
      }

      &:last-child {
        font-size: 12px;
      }

      span {
        margin-right: 6px;
      }
    }
  }
`;

export default BreadcrumbBase;
