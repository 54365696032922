import {useState} from "react";
import {useParams, withRouter} from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import Form from "../../../../components/ubold/organisms/Form";

import CVTextField from "../../../../components/ubold/molecules/forms/CVTextField";
import CVSelect from "../../../../components/ubold/molecules/forms/CVSelect";
import CVAsyncSelect from "../../../../components/ubold/molecules/forms/CVAsyncSelect";
import CVDateTimePicker from "../../../../components/ubold/molecules/forms/CVDateTimePicker";
import CVImageUpload from "../../../../components/ubold/molecules/forms/CVImageUpload";
import CVTextView from "../../../../components/ubold/molecules/forms/CVTextView";

import definitions from "./definitions";

function DiscountAddEditPage() {
    let {id} = useParams();

    const initialState = {};

    const [state, setState] = useState(initialState);

    return (
        <Page title={(id === undefined ? 'Add New' : 'Edit') + ' ' +  definitions.title}
              restAccessCode={definitions.restAccessCode}>
            <Form
                id={id}
                definitions={definitions}
                state={state}
                setState={setState}>
                <CVAsyncSelect
                    title='Tenant'
                    name='tenant'
                    data='user/tenant?__type__=select_entries'
                    isRequired
                />
                <CVTextField
                    title='Name'
                    name='name'
                    maxLength={254}
                />
                <CVTextField
                    title='Phone Number'
                    name='phone_number'
                    maxLength={16}
                    isRequired
                    type='number'
                />
                <CVAsyncSelect
                    title='Voucher'
                    name='voucher'
                    isRequired
                    data='user/user_voucher?__type__=select_entries'
                />
                <CVDateTimePicker
                    title='Date Transferred'
                    name='date_transferred'
                />
                <CVImageUpload
                    title='Prove Transferred (max size 500kB)'
                    name='prove_transferred_original'
                />
                <CVSelect
                    title='Status'
                    name='status'
                    isRequired
                    data={[
                        {
                            label: 'Pending',
                            value: 1
                        },
                        {
                            label: 'Done',
                            value: 2
                        },
                        {
                            label: 'Cancelled',
                            value: 3
                        },
                    ]}
                />
                <CVTextView
                    title='Provider'
                    name='external_provider'
                />
            </Form>
        </Page>
    );
}

export default withRouter(DiscountAddEditPage);