import { withRouter } from "react-router-dom";

import AccordionPane from "../../../../../../../components/ubold/organisms/AccordionPane";
import CHTextView from "../../../../../../../components/ubold/molecules/forms/CHTextView";
import { sectionProps } from "@types";

function RukiKoinAndRefferal(props: sectionProps) {
  const { baseLink, accordionId, onSubmit, state, setState } = props;

  return (
    <AccordionPane
      id="rukiKoinAndReferral"
      name="RukiKoins and Referral"
      accordionId={accordionId}
      baseLink={baseLink}
      onSubmit={onSubmit}
      state={state}
      setState={setState}
    >
      <CHTextView name="rukikoin" title="RukiKoins" />
      <CHTextView name="referral_code" title="Referral Code" />
    </AccordionPane>
  );
}

export default withRouter(RukiKoinAndRefferal);
