import configBuilder from "../../../components/ubold/ConfigBuilder";

let definitions = {
  title: "Cities",
  frontendPath: "/admin/location/city",
  baseLink: "/place/city",
  restAccessCode: "place.city",
  __table__: {
    hideEditButton: true,
    filterColumn: 3,
    filters: [
      {
        id: "name",
        name: "Name",
        type: "text",
      },
      {
        id: "country",
        name: "Country",
        type: "async_select",
        data: "place/country?__type__=select_entries",
      },
      {
        id: "province",
        name: "Province",
        type: "async_select",
        dependsOn: "country",
        data: "place/province?__type__=select_entries",
      },
    ],
    columns: [
      {
        id: "name",
        name: "Name",
        enableHyperlink: true,
      },
      {
        id: "code",
        name: "Code",
      },
      {
        id: "province_name",
        name: "Province",
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
