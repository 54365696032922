import styled, { css } from "styled-components";

export const LinkText = styled.a`
  color: #00bbb4;
  font-size: 14px;
  font-weight: 400;
`;

export const FormGroup = styled.div`
  width: 100%;

  display: grid;
  grid-column-gap: 9px;
  grid-template-columns: 180px auto;
  margin-bottom: 12px;

  label {
    position: relative;

    color: #222222;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: unset;

    &:after {
      position: absolute;
      right: -4px;

      content: ":";
    }
  }
`;

export const CustomFormGroup = styled.div`
  width: 100%;

  & > div {
    height: unset;

    grid-gap: 9px;
    grid-template-columns: 180px auto;
    margin-bottom: 12px;
  }

  input {
    height: unset;

    background: unset;
    border: unset;
    color: #222222;
    font-size: 14px;
    padding: unset;

    &:disabled {
      background: unset;
    }
  }

  label {
    position: relative;

    color: #222222;
    font-size: 14px;
    margin-bottom: unset;

    &:after {
      position: absolute;
      right: -4px;

      content: ":";
    }
  }
`;

export const ModalPreviewOrderSubmitCSSDefault = css`
  .box-dialog {
    min-width: 496px;
    width: 1032px;

    .box-content {
      padding: 16px;

      & > div {
        align-items: flex-start;
      }
    }

    .box-header {
      height: 52px;

      border-bottom: 1px solid #e7e7e7;
      padding: 16px;

      .box-title {
        font-size: 14px;
        font-weight: 500;
      }

      .x-close {
        right: 16px;
      }
    }
  }

  .box-wrapper {
    margin: auto;
  }
`;

export const ModalPreviewOrderSubmitCSSError = css`
  .box-dialog {
    min-width: 387px;
    width: 387px;

    .box-content {
      padding: 16px;

      & > div {
        align-items: center;
      }
    }

    .box-header {
      height: 52px;

      border-bottom: 1px solid #e7e7e7;
      padding: 16px;

      .box-title {
        font-size: 14px;
        font-weight: 500;
      }

      .x-close {
        right: 16px;
      }
    }
  }

  .box-wrapper {
    margin: auto;
  }
`;

export const ModalPreviewOrderSubmitStyled = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  button {
    height: 36px;
    width: 76px;

    background-color: #35b0a7;
    border: unset;
    border-radius: 2px;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    padding: 8px 28.5px;
  }

  h5,
  p {
    text-align: center;
  }

  h5 {
    font-size: 16px;
    font-weight: 500;
    margin-top: unset;
    margin-bottom: 16px;
  }

  img {
    margin-bottom: 30px;
  }

  p {
    color: #222222;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 30px;
  }

  strong {
    color: #222222;
    font-weight: 500;
  }
`;

export const ButtonModal = styled.a<{ disabled?: boolean }>`
  ${({ disabled }) => {
    if (disabled) {
      return css`
        color: #cccccc;
        border: 1px solid #e7e7e7;

        &:hover {
          color: #cccccc;
        }
      `;
    }

    return "";
  }}
`;
