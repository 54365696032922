import LegendItem from "components/ubold/atoms/Legends/LegendItem";
import React from "react";
import styled from "styled-components";

export type ThemeTextLegendType =
  | "BLACK"
  | "BLUE"
  | "GRAY"
  | "PURPLE"
  | "RED"
  | "YELLOW";

interface ITextLegendProps
  extends React.HTMLAttributes<HTMLDivElement> {
  color?: string;
  label?: string;
  stripes?: string;
  theme?: ThemeTextLegendType;
}
const TextLegend: React.FC<ITextLegendProps> = ({
  color,
  label,
  theme,
  stripes,
  ...props
}) => {
  return (
    <TextLegendBase {...props}>
      <LegendItem color={color} stripes={stripes} theme={theme} />
      {label && <span>{label}</span>}
    </TextLegendBase>
  );
};

const TextLegendBase = styled.div`
  display: inline-flex;
  align-items: center;

  color: #222222;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;

  span {
    padding-left: 4px;
  }
`;

export default TextLegend;
