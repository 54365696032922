import React from "react";
import styled from "styled-components";
import Tooltip from "../tooltip/Tooltip";

interface TextWithIconProps {
  iconPath?: string;
  textPath?: string;
  trigger?: "click" | "hover";
  zIndex?: number;
  id?: string | number;
}

const TextWithIcon: React.FC<TextWithIconProps> = ({
  iconPath,
  textPath,
  trigger,
  zIndex,
  id,
}) => {
  return (
    <Container>
      <a
        className="link-rukita-v2"
        title="Click to see detail"
        href={textPath}
        target="_blank"
        rel="noreferrer"
      >
        {id}
      </a>
      <IconContainer tabIndex={0}>
        <Tooltip
          contentHover={"Room Allotment"}
          contentMain={"Room Allotment"}
          customCSS={{
            fontSize: 12,
            fontWeight: 500,
            width: "auto",
          }}
          hideOnClick
          trigger={trigger}
          zIndex={zIndex}
        >
          <a href={iconPath} target="_blank" rel="noreferrer">
            <img
              src="/assets/icons/icon-calendar.svg"
              alt="room-allotment"
              width={12}
              height={12}
            />
          </a>
        </Tooltip>
      </IconContainer>
    </Container>
  );
};

export default TextWithIcon;

const Container = styled.div`
  display: flex;
  font-family: inherit;
  font-size: 10px;
  font-weight: 400;
  flex-direction: row;
  line-height: 14px;
  justify-content: space-between;
`;

const IconContainer = styled.div`
  height: fit-content;
  width: auto;
  margin-left: 4px;
  outline: none;

  &:hover {
    background-color: #d9efe5;
    border-radius: 2px;
    & img {
      box-shadow: 0 0 0 2px rgb(217, 239, 229, 0.2);
      border-radius: 2px;
      filter: brightness(0) saturate(100%) invert(44%) sepia(36%)
        saturate(6638%) hue-rotate(153deg) brightness(94%)
        contrast(101%);
    }
  }
  &:focus {
    box-shadow: 0 0 0 4px rgba(0, 187, 180, 0.2);
    border-radius: 2px;
    background-color: #d9efe5;
    & img {
      box-shadow: 0 0 0 2px rgb(217, 239, 229, 0.2);
      border-radius: 2px;
      filter: brightness(0) saturate(100%) invert(44%) sepia(36%)
        saturate(6638%) hue-rotate(153deg) brightness(94%)
        contrast(101%);
    }
  }
  &:active {
    & img {
      filter: brightness(0) saturate(100%) invert(17%) sepia(36%)
        saturate(829%) hue-rotate(129deg) brightness(95%)
        contrast(101%);
    }
  }
`;
