import secondaryDataHandler from "./SecondaryDataHandler";

import utils from "../../../utils";

import configBuilder from "../../../components/ubold/ConfigBuilder";

let definitions = {
  title: "Room Variants",
  frontendPath: "/building/room/room_variant",
  baseLink: "/building/room_variant",
  restAccessCode: "building.room_variant",
  __table__: {
    filterColumn: 2,
    hasSecondaryData: true,
    secondaryDataItem: 1,
    hideEditButton: true,
    secondaryDataHandler: secondaryDataHandler,
    filters: [
      {
        id: "name",
        name: "Name",
        type: "text",
      },
      {
        id: "building",
        name: "Building Name",
        type: "async_select",
        data: "building/building?__type__=select_entries",
        itemsExtractor: (item) => {
          return {
            label: item.building_name,
            value: item.id,
          };
        },
      },
    ],
    columns: [
      {
        id: "name",
        name: "Name",
        enableHyperlink: true,
      },
      {
        id: "building_name",
        name: "Building Name",
      },
      {
        id: "code",
        name: "Code",
      },
      {
        id: "room_type_name",
        name: "Room Type",
      },
      {
        id: "price",
        name: "Price",
        renderer: (value) => {
          return utils.formatter.currency(value);
        },
      },
      {
        id: "max_occupancy",
        name: "Max Occupancy",
        center: true,
      },
      {
        id: "is_renovated",
        name: "Is Renovated",
        center: true,
        isSafeContent: true,
        renderer: (value) => {
          if (value === "true") {
            return '<span style="color: rgb(26, 188, 156);"> ✔ </span>';
          } else {
            return '<span style="color: red;">✘</span>';
          }
        },
        canDoOrdering: false,
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
