// TENANT
//
// FULLY BOOKED
import FullyBooked from "pages/Leads/FullyBooked";
import FullyBookedAddEdit from "pages/Leads/FullyBooked/AddEdit";
import FullyBookedView from "pages/Leads/FullyBooked/ViewOnly";
// BOOKING
import Booking from "pages/Leads/Booking";
import BookingAddEdit from "pages/Leads/Booking/AddEdit";
import BookingView from "pages/Leads/Booking/ViewOnly";
// INTEREST
import Interest from "pages/Leads/Interest";
import InterestAddEdit from "pages/Leads/Interest/AddEdit";
import InterestView from "pages/Leads/Interest/ViewOnly";
// LEAD INQUIRIES
import LeadInquiry from "pages/Leads/LeadInquiry";
import LeadInquiryAddEdit from "pages/Leads/LeadInquiry/AddEdit";
import LeadInquiryView from "pages/Leads/LeadInquiry/ViewOnly";
// GENERAL INQUIRIES
import GeneralInquiry from "pages/Leads/GeneralInquiry";
import GeneralInquiryAddEdit from "pages/Leads/GeneralInquiry/AddEdit";
import GeneralInquiryView from "pages/Leads/GeneralInquiry/ViewOnly";
//
// LANDLORD
//
// BUILDING LEADS
import LandlordLeadBuilding from "pages/User/LandlordLeadBuilding";
import LandlordLeadBuildingAddEdit from "pages/User/LandlordLeadBuilding/AddEdit";
import LandlordLeadBuildingView from "pages/User/LandlordLeadBuilding/ViewOnly";
// LANDLORD LEADS
import LandlordLead from "pages/User/LandlordLead";
import LandlordLeadAddEdit from "pages/User/LandlordLead/AddEdit";
import LandlordLeadView from "pages/User/LandlordLead/ViewOnly";
// CORPORATE LEADS
import CorporateLead from "pages/Leads/CorporateLead";
import CorporateLeadAddEdit from "pages/Leads/CorporateLead/AddEdit";
import CorporateLeadView from "pages/Leads/CorporateLead/ViewOnly";

const LeadRoutes = [
  // TENANT
  //
  // FULLY BOOKED
  {
    path: "/lead/tenant/fully_booked",
    component: FullyBooked,
  },
  {
    path: "/lead/tenant/fully_booked/(add|edit)/:id([0-9]+)?",
    component: FullyBookedAddEdit,
  },
  {
    path: "/lead/tenant/fully_booked/view/:id([0-9]+)?",
    component: FullyBookedView,
  },
  // BOOKING
  {
    path: "/lead/tenant/booking",
    component: Booking,
  },
  {
    path: "/lead/tenant/booking/(add|edit)/:id([0-9]+)?",
    component: BookingAddEdit,
  },
  {
    path: "/lead/tenant/booking/view/:id([0-9]+)?",
    component: BookingView,
  },
  // INTEREST
  {
    path: "/lead/tenant/interest",
    component: Interest,
  },
  {
    path: "/lead/tenant/interest/(add|edit)/:id([0-9]+)?",
    component: InterestAddEdit,
  },
  {
    path: "/lead/tenant/interest/view/:id([0-9]+)?",
    component: InterestView,
  },
  // LEAD INQUIRIES
  {
    path: "/lead/tenant/inquiry",
    component: LeadInquiry,
  },
  {
    path: "/lead/tenant/inquiry/(add|edit)/:id([0-9]+)?",
    component: LeadInquiryAddEdit,
  },
  {
    path: "/lead/tenant/inquiry/view/:id([0-9]+)?",
    component: LeadInquiryView,
  },
  // GENERAL INQUIRIES
  {
    path: "/lead/tenant/general_inquiry",
    component: GeneralInquiry,
  },
  {
    path: "/lead/tenant/general_inquiry/(add|edit)/:id([0-9]+)?",
    component: GeneralInquiryAddEdit,
  },
  {
    path: "/lead/tenant/general_inquiry/view/:id([0-9]+)?",
    component: GeneralInquiryView,
  },
  //
  // LANDLORD
  //
  // BUILDING LEADS
  {
    path: "/lead/landlord/building",
    component: LandlordLeadBuilding,
  },
  {
    path: "/lead/landlord/building/(add|edit)/:id([0-9]+)?",
    component: LandlordLeadBuildingAddEdit,
  },
  {
    path: "/lead/landlord/building/view/:id([0-9]+)?",
    component: LandlordLeadBuildingView,
  },
  // LANDLORD LEADS
  {
    path: "/lead/landlord/landlord_lead",
    component: LandlordLead,
  },
  {
    path: "/lead/landlord/landlord_lead/(add|edit)/:id([0-9]+)?",
    component: LandlordLeadAddEdit,
  },
  {
    path: "/lead/landlord/landlord_lead/view/:id([0-9]+)?",
    component: LandlordLeadView,
  },
  // CORPORATE LEADS
  {
    path: "/lead/corporate_lead",
    component: CorporateLead,
  },
  {
    path: "/lead/corporate_lead/(add|edit)/:id([0-9]+)?",
    component: CorporateLeadAddEdit,
  },
  {
    path: "/lead/corporate_lead/view/:id([0-9]+)?",
    component: CorporateLeadView,
  },
];

export default LeadRoutes;
