import utils from "../../../../utils";

import configBuilder from "../../../../components/ubold/ConfigBuilder";

import pageDefinitions from "../definitions";

const definitions = {
  ...pageDefinitions,
  validators: {
    banner_picture_original: (value) => {
      return utils.validator.validateRequired(value);
    },
    url: (value, state) => {
      const urlVal = utils.validator.validateRequired(value);
      if (!state["promo_slug"] && urlVal !== true) {
        return "You need to fill either URL or Promo Slug";
      } else {
        return true;
      }
    },
    promo_slug: (value, state) => {
      const promoSlugVal = utils.validator.validateRequired(value);
      if (!state["url"] && promoSlugVal !== true) {
        return "You need to fill either Promo Slug or URL";
      } else {
        return true;
      }
    },
  },
};

export default configBuilder.buildAddEditPageConfig(definitions, [
  "banner_picture_original",
  "banner_picture_tablet_screen",
  "banner_picture_desktop_screen",
]);
