import CSelectV2 from "components/ubold/atoms/forms/CSelectV2";
import { withRouter } from "react-router-dom";

import useGlobalState from "../../../../state";

import utils from "../../../../utils";

const CVSelectV2 = (props: any) => {
  const {
    name,
    title,
    isRequired = false,
    restAccessCode,
    formColumn = 2,
    overrideLayout = null,
    state,
    setState,
    data,
    dependsOn,
    itemsExtractor,
    readOnly,
    isMulti = false,
    closeMenuOnSelect = false,
    hideSelectedOptions = false,
    isCheckbox = false,
    isClearable = false,
    handleOnChange = null,
    disableMultiValue = false,
    removeCloseIcon = false,
  } = props;

  let formColumnReal = 12 / formColumn;

  const [user] = useGlobalState("user");
  const isSuperUser = user ? user.is_super_user : false;
  const [fieldAccesses] = useGlobalState("field_accesses");
  const [fieldAccessReversePolicy] = useGlobalState(
    "field_access_reverse_policy"
  );

  return (
    <div
      className={
        "col-lg-" + (overrideLayout ? overrideLayout : formColumnReal)
      }
      style={{
        display:
          !isRequired &&
          restAccessCode &&
          utils.access.isFieldHidden(
            fieldAccesses,
            restAccessCode + "." + name
          )
            ? "none"
            : "unset",
      }}
    >
      <div className="form-group mb-3">
        <label
          className={
            (isRequired ? " font-weight-bold" : "") +
            (state[name + "Error"] ? " text-danger" : "")
          }
        >
          {title}
          {isRequired ? "*" : ""}{" "}
          {readOnly ??
          utils.access.isFieldReadOnly(
            fieldAccesses,
            restAccessCode + "." + name,
            fieldAccessReversePolicy,
            isSuperUser
          )
            ? "(read only)"
            : ""}
        </label>
        &nbsp;
        <CSelectV2
          name={name}
          state={state}
          isMulti={isMulti}
          setState={setState}
          data={data}
          dependsOn={dependsOn}
          closeMenuOnSelect={closeMenuOnSelect}
          itemsExtractor={itemsExtractor}
          hideSelectedOption={hideSelectedOptions}
          isCheckbox={isCheckbox}
          isClearable={isClearable}
          customStyles={{ menuPortal: { zIndex: 11 } }}
          readOnly={
            readOnly ??
            utils.access.isFieldReadOnly(
              fieldAccesses,
              restAccessCode + "." + name,
              fieldAccessReversePolicy,
              isSuperUser
            )
          }
          onChange={handleOnChange}
          disableMultiValue={disableMultiValue}
          removeCloseIcon={removeCloseIcon}
        />
        {state[name + "Error"] ? (
          <span className="text-danger" style={{ fontSize: "12px" }}>
            {state[name + "Error"]}
          </span>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default withRouter(CVSelectV2);
