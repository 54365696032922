import utils from "../../../../utils";

import configBuilder from "../../../../components/ubold/ConfigBuilder";

import pageDefinitions from "../definitions";

const definitions = {
  ...pageDefinitions,
  linkHistory: (id: string) =>
    `${definitions.frontendPath}/history/${id}`,
  tabs: [
    {
      id: "roomVariant",
      name: "Variant",
    },
    {
      id: "t_photos",
      name: "Photos",
    },
    {
      id: "t_rooms",
      name: "Rooms",
    },
    {
      id: "t_mods",
      name: "Mods",
    },
    {
      id: "t_facilities",
      name: "Facilities",
    },
    {
      id: "t_features",
      name: "Features",
    },
  ],
  validators: {
    name: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    building: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    room_type: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    price: (value: string) => {
      return utils.validator.validateRequired(value);
    },
  },
};

export default configBuilder.buildAddEditPageConfig(definitions);
