import configBuilder from "../../../components/ubold/ConfigBuilder";

import utils from "../../../utils";

let definitions = {
  title: "Promotion Benefits",
  frontendPath: "/marketing/promotion/benefit",
  baseLink: "/promotion/benefit",
  restAccessCode: "promotion.benefit",
  __table__: {
    filterColumn: 4,
    hideEditButton: true,
    removeIdentifier: "headline_of_benefit",
    filters: [
      {
        id: "headline_of_benefit",
        name: "Headline of Benefit",
        type: "text",
      },
      {
        id: "brand_name",
        name: "Brand Name",
        type: "text",
      },
      {
        id: "benefit_category",
        name: "Benefit Category",
        type: "select",
        data: {
          optionField: "promotion_benefit_category",
        },
      },
      {
        id: "published",
        name: "Published",
        type: "select",
        data: [
          {
            label: "Yes",
            value: true,
          },
          {
            label: "No",
            value: false,
          },
        ],
      },
      {
        id: "eligible_to_all_unit",
        name: "Eligible to All Unit?",
        type: "select",
        data: [
          {
            label: "Yes",
            value: true,
          },
          {
            label: "No",
            value: false,
          },
        ],
      },
      {
        id: "coupon_code",
        name: "Coupon Code",
        type: "text",
      },
      {
        id: "date_started",
        name: "Date Started",
        type: "date",
      },
      {
        id: "date_ended",
        name: "Date Ended",
        type: "date",
      },
    ],
    columns: [
      {
        id: "headline_of_benefit",
        name: "Headline of Benefit",
        wrap: true,
        enableHyperlink: true,
      },
      {
        id: "brand_name",
        name: "Brand Name",
        wrap: true,
      },
      {
        id: "benefit_category",
        name: "Benefit Category",
        center: true,
        referStateFieldName: "promotion_benefit_category",
        renderer: (value, _, constants) => {
          let label = "-";
          (constants ?? []).forEach((obj) => {
            if (obj.value === parseInt(value)) {
              label = obj.label;
            }
          });
          return label;
        },
      },
      {
        id: "published",
        name: "Published",
        isSafeContent: true,
        center: true,
        renderer: (value) => {
          if (value === "true") {
            return '<span style="color: rgb(26, 188, 156);"> ✔ </span>';
          } else {
            return '<span style="color: red;">✘</span>';
          }
        },
      },
      {
        id: "eligible_to_all_unit",
        name: "Eligible to All Unit?",
        isSafeContent: true,
        center: true,
        renderer: (value) => {
          if (value === "true") {
            return '<span style="color: rgb(26, 188, 156);"> ✔ </span>';
          } else {
            return '<span style="color: red;">✘</span>';
          }
        },
      },
      {
        id: "coupon_code",
        name: "Coupon Code",
      },
      {
        id: "date_started",
        name: "Date Started",
        renderer: (value) => {
          return utils.formatter.dateTime(value);
        },
      },
      {
        id: "date_ended",
        name: "Date Ended",
        renderer: (value) => {
          return utils.formatter.dateTime(value);
        },
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
