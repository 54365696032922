import styled from "styled-components/macro";
import { withRouter } from "react-router";

import utils from "utils";

const CHSingleRow = (props: any) => {
  const { data, title, titleWidth = "44%", moreLeft } = props;

  return (
    <Parent>
      <Container>
        <TitleContainer titleWidth={titleWidth}>
          {title}
        </TitleContainer>
        {/* <DataContainer></DataContainer> */}
        <DataContainer moreLeft={moreLeft}>
          {utils.formatter.currency(data)}
        </DataContainer>
      </Container>
    </Parent>
  );
};

export default withRouter(CHSingleRow);

const Parent = styled.div`
  width: 100%;
  margin: 0 12px 0 12px;
  border-top: 1px solid #edeff1;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  padding: 5px 0 5px 0;
  border-bottom: 2px solid #edeff1;
`;

const TitleContainer = styled.div<{ titleWidth?: string }>`
  padding-left: 10px;
  width: ${({ titleWidth }) => titleWidth};
  font-size: 16px;
  font-weight: 900;
`;

const DataContainer = styled.div<{
  moreLeft: boolean;
}>`
  padding-top: 7px;
  width: 300px;
  padding-left: ${(props) => (props.moreLeft ? "32px" : "18px")};
`;
