import { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { useHistory } from "react-router-dom";

import configs from "configs";
import utils from "utils";
import {
  removeProps,
  compareObj,
  parseReturnMessage,
} from "utils/generalUtils";
import { UserData } from "@types";
import definitions from "../definitions";

import CHTextFieldV2 from "components/ubold/molecules/forms/CHTextFieldV2";
import { useToast } from "utils/customHooks";
import useGlobalState from "state";
import { ButtonPrimary } from "components/ubold/atoms/General.css";

import withReactContent from "sweetalert2-react-content";
import loading from "assets/loading.gif";
import Swal from "sweetalert2";
interface EditProfileSectionProps {
  title?: string;
  setIsFormProfileDirty?: any;
}

const EditProfileSection = (props: EditProfileSectionProps) => {
  const { title, setIsFormProfileDirty } = props;
  const restUserPath = "lookup-user/";
  const history = useHistory();

  const user = useGlobalState("user");
  const [userData, setUserData] = useState<UserData>(user[0]);
  const [clonedUserData, setClonedUserData] =
    useState<UserData>(null);
  const [inputTimeout, setInputTimeout] = useState(null);
  const [stateIsEqual, setStateIsEqual] = useState<boolean>(true);
  const [clickDisabled, setClickDisabled] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const toast = useToast();

  const resetState = (name: string) => {
    if (!name + "Available" in userData) return;
    setUserData((prevState: UserData) => ({
      ...prevState,
      [name + "IsChecking"]: null,
      [name + "Available"]: null,
      [name + "UseValueChecking"]: null,
      [name + "AvailableMessage"]: null,
    }));
  };

  const checkValueAvailability = (name: string, value: string) => {
    if (stateIsEqual) return;
    if (inputTimeout) clearTimeout(inputTimeout);
    if (value === "") {
      resetState(name);
      return;
    }
    if (value.length < 4) return;

    setInputTimeout(
      setTimeout(() => {
        if (name === "email") {
          const notValidEmail =
            !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
              value
            );
          if (notValidEmail) return;
        }
        const currUserData = clonedUserData as any;

        setUserData((prevState: UserData) => ({
          ...prevState,
          [name + "IsChecking"]: true,
        }));

        if (value === currUserData[name]) {
          setClickDisabled(false);
          resetState(name);
          return;
        }

        const checkParam = { [name]: value };
        utils.httpClient.post(
          configs.apiUrl + restUserPath,
          checkParam,
          (response: any) => {
            const data = response.exist;
            if (data) {
              setClickDisabled(true);
              setUserData((prevState: UserData) => ({
                ...prevState,
                [name + "IsChecking"]: false,
                [name + "Available"]: false,
                [name + "UseValueChecking"]: true,
                [name + "Error"]:
                  name === "username"
                    ? "Username has already taken"
                    : "Work email has already taken",
                [name + "AvailableMessage"]:
                  name === "username"
                    ? "Username has already taken"
                    : "Work email has already taken",
              }));
              return;
            } else {
              setClickDisabled(false);
              setUserData((prevState: UserData) => ({
                ...prevState,
                [name + "IsChecking"]: false,
                [name + "Available"]: true,
                [name + "UseValueChecking"]: true,
                [name + "AvailableMessage"]:
                  name === "username" ? "Username available" : null,
              }));
              return;
            }
          },
          (error: any, message: string) => {
            return;
          }
        );
      }, 1500)
    );
  };

  const sendData = () => {
    const clearFields = ["username", "email"];
    for (let i = 0; i < clearFields.length; i++) {
      resetState(clearFields[i]);
    }

    const payload = {
      username: userData.username,
      first_name: userData.first_name,
      last_name: userData.last_name,
      email: userData.email,
    };
    let validatorPassed = true;
    if (definitions.validators) {
      validatorPassed = utils.form.validate(
        userData,
        setUserData,
        definitions.validators
      );
    }

    if (validatorPassed) {
      const stateStriped = utils.form.stripErrorFields(
        payload,
        [],
        ""
      );
      const sweetAlert = withReactContent(Swal);
      sweetAlert.fire({
        title: "Saving data",
        html: (
          <img
            style={{ width: "125px", height: "125px" }}
            alt="loading..."
            src={loading}
          />
        ),
        showConfirmButton: false,
        allowOutsideClick: false,
      });

      utils.httpClient.patch(
        configs.apiUrl + "update-profile/" + userData.id + "/",
        stateStriped,
        () => {
          setClonedUserData({ ...payload, id: userData.id });
          setIsFormProfileDirty(false);
          history.push({
            pathname: "/profile_settings",
            state: {
              target: "edit_profile",
              formProfileDirty: false,
            },
          });
          sweetAlert.close();
          toast({
            description: "Data has been saved successfully ",
            dTime: 1000,
          });
        },
        (error: number, message: string) => {
          sweetAlert.close();
          if (utils.commons.isJSON(message)) {
            parseReturnMessage(message, setUserData);
            toast({
              description: "Failed",
              type: "danger",
              dTime: 1000,
            });
          } else {
            let errorMessage = utils.httpClient
              .getErrorMessage(error)
              .split("\n")[0];

            toast({
              description: errorMessage,
              type: "danger",
              dTime: 1000,
            });
          }
        }
      );
    }
  };

  useEffect(() => {
    setIsFormProfileDirty(false);
    return () => {
      setIsFormProfileDirty(false);
    };
  }, []);

  useEffect(() => {
    const duplicateState = JSON.parse(JSON.stringify(userData));
    const cleanedUserData = removeProps(duplicateState, [
      "usernameAvailable",
      "usernameAvailableMessage",
      "usernameIsChecking",
      "usernameUseValueChecking",
      "emailAvailable",
      "emailAvailableMessage",
      "emailIsChecking",
      "emailUseValueChecking",
    ]);
    if (!cleanedUserData || !clonedUserData) return;
    const compareResult = compareObj(cleanedUserData, clonedUserData);

    setStateIsEqual(compareResult);
    setIsFormProfileDirty(!compareResult);
    history.push({
      pathname: "/profile_settings",
      state: {
        target: "edit_profile",
        formProfileDirty: !compareResult,
      },
    });
  }, [userData]);

  useEffect(() => {
    if (!user[0]) return;
    if (clonedUserData !== null) return;
    setIsLoading(true);
    utils.httpClient.get(configs.apiUrl + "init/", (data: any) => {
      const newObj = removeProps(data.data, [
        "accesses",
        "field_accesses",
        "has_team_management_access",
        "menu_accesses",
        "menus",
        "is_super_user",
      ]);
      setUserData(newObj);
      setClonedUserData(newObj);
      setIsLoading(false);
      return;
    });
  }, [user]);

  return (
    <Container>
      <TitleStyled>{title ?? "Page Title"}</TitleStyled>
      <SubSectionStyled>
        <SubInfoStyled>Personal Info</SubInfoStyled>
        <CHTextFieldV2
          isRequired
          maxLength={30}
          name="username"
          placeholder={"Username"}
          title="Username"
          state={userData}
          setState={setUserData}
          checkValueAvailability={checkValueAvailability}
          readOnly={isLoading}
        />
        <CHTextFieldV2
          isRequired
          maxLength={30}
          name="first_name"
          placeholder={"First Name"}
          title="First Name"
          state={userData}
          setState={setUserData}
          readOnly={isLoading}
        />
        <CHTextFieldV2
          isRequired
          maxLength={30}
          name="last_name"
          placeholder={"Last Name"}
          title="Last Name"
          state={userData}
          setState={setUserData}
          readOnly={isLoading}
        />
        <CHTextFieldV2
          isRequired
          name="email"
          placeholder={"Work Email"}
          title="Work Email"
          type="email"
          state={userData}
          setState={setUserData}
          checkValueAvailability={checkValueAvailability}
          readOnly={isLoading}
        />
      </SubSectionStyled>
      <SubSectionStyled style={{ paddingTop: 8 }}>
        <SubInfoStyled>Roles</SubInfoStyled>
        <CHTextFieldV2
          name="roles"
          title="Roles Assigned"
          readOnly
          value={utils.store.get("userRole")}
        />
        <CHTextFieldV2
          name="supervisor"
          title="Supervisor"
          readOnly
          value={utils.store.get("supervisor") ?? "-"}
        />
        <ButtonContainer>
          <ButtonPrimary
            onClick={() => sendData()}
            disabled={stateIsEqual || clickDisabled}
          >
            Save Changes
          </ButtonPrimary>
        </ButtonContainer>
      </SubSectionStyled>
    </Container>
  );
};

export default EditProfileSection;

const Container = styled.div`
  display: block;
  height: 100%;
  padding: 24px;
  background: #fff;
  border-radius: 4px;
`;

const TitleStyled = styled.div`
  font-weight: 500;
  font-size: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #e7e7e7;
`;

const SubSectionStyled = styled.div`
  padding-top: 24px;
`;

const SubInfoStyled = styled.div`
  font-weight: 500;
  font-size: 14px;
  padding-bottom: 16px;
`;
const ButtonContainer = styled.div`
  height: 34px;
  display: flex;
  justify-content: flex-end;
`;
