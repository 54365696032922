import { useParams, withRouter } from "react-router-dom";

import TabPaneSecondary from "../../../../../../components/ubold/organisms/TabPaneSecondary";

import COneOrManyToManyField from "../../../../../../components/ubold/molecules/forms/COneOrManyToManyField";
import { genericTabProps } from "@types";

function AddonBuilding(props: genericTabProps<Object>) {
  const { definitions, tab, state, setState } = props;

  let { id } = useParams<{ id: string }>();

  return (
    <TabPaneSecondary
      definitions={definitions}
      restAccessCodeNamespace="addon_building"
      tab={tab}
      state={state}
      setState={setState}
    >
      <COneOrManyToManyField
        id={id}
        name={tab.id}
        restPath="building/addon__building/"
        relationKey="addon_id"
        readOnly
        columns={[
          {
            name: "building",
            title: "Building",
            isRequired: true,
            isUniqueOption: true,
            type: "async_select",
            disabledAtEdit: true,
            data: "building/building?__type__=select_entries",
            itemsExtractor: (row: {
              id: string;
              building_name: string;
            }) => {
              return {
                label: row.building_name,
                value: row.id,
              };
            },
          },
          {
            name: "exclude_ll_report",
            title: "Exclude LL Report",
            type: "checkbox",
            default: true,
            disabledAtEdit: true,
          },
          {
            name: "payment_plan",
            title: "Payment Plan",
            type: "number",
            disabledAtEdit: true,
          },
          {
            name: "price",
            title: "Price",
            type: "number",
            default: 0,
            disabledAtEdit: true,
          },
          {
            name: "work_estimation",
            title: "Work Estimation",
            type: "number",
            default: 0,
            disabledAtEdit: true,
          },
          {
            name: "visible",
            title: "Visible",
            type: "checkbox",
            default: true,
            disabledAtEdit: true,
          },
          {
            name: "is_publish",
            title: "Published",
            type: "checkbox",
            default: false,
            disabledAtEdit: true,
          },
        ]}
      />
    </TabPaneSecondary>
  );
}

export default withRouter(AddonBuilding);
