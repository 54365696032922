import React, { useEffect, useRef, useState } from "react";
import { withRouter, useLocation } from "react-router-dom";
import { FilterPanelProps } from "@types";

import useGlobalState from "state";

import CSelectV2 from "components/ubold/atoms/forms/CSelectV2";
import CAsyncSelectV2 from "components/ubold/atoms/forms/CAsyncSelectV2";
import CDatePicker from "components/ubold/atoms/forms/CDatePickerV2";
import CTextField from "components/ubold/atoms/forms/CTextField";
import utils from "utils";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import CButtonV2 from "components/ubold/atoms/buttons/CButtonV2";
import ValidateFilter from "utils/ValidateFilter";
import {
  ActionBtnContainer,
  AddBtnContainer,
  Container,
  FilterContainer,
  FilterField,
  FilterFieldItem,
  FilterTitle,
  HelperLabel,
} from "./FilterPanelV2.styled";
import moment, { isMoment } from "moment";

const FilterPanelV2: React.FC<FilterPanelProps> = (props) => {
  const {
    addButton = true,
    defaultState,
    filters = [],
    isTeamMenu = false,
    restAccessCode,
    fetch,
    filterValidators,
    ordering,
    setParams,
    dateDependsOn,
    dateDependent,
    useOldResetFunc,
    dataTableList,
    dateWithDefaultValue,
    selectedRowPerPage,
  } = props;
  const { search: searchUrlQuery } = useLocation();
  const hasFetchInitialState = useRef(false);
  const initialState: any = {};
  const requiredFilter: any = {};
  const conditionalRender: any = {};

  const [state, setState] = useState(initialState);
  const [resetIsTriggered, setResetIsTriggered] = useState<{
    [key: string]: boolean;
  }>({});
  const sweetAlert = withReactContent(Swal);
  const [user] = useGlobalState("user");
  const [accesses] = useGlobalState("accesses");
  const location = useLocation();

  useEffect(() => {
    setState((prevState: any) => {
      return {
        ...prevState,
        ...defaultState,
      };
    });
  }, []);

  useEffect(() => {
    if (state?.filter_by === "by_placegroup") {
      setState((prevState: any) => {
        return {
          ...prevState,
          building_id: null,
        };
      });
    } else {
      setState((prevState: any) => {
        return {
          ...prevState,
          place_group_id: null,
        };
      });
    }
  }, [state?.filter_by]);

  for (let i = 0; i < filters.length; i++) {
    if (
      dateDependsOn &&
      dateDependent &&
      filters[i].type === "date"
    ) {
      if (filters[i].id === dateDependsOn) {
        initialState[dateDependsOn] = moment();
      } else {
        initialState[dateDependent] = moment().add(30, "days");
      }
    } else if (filters[i].type === "date" && dateWithDefaultValue) {
      initialState[dateWithDefaultValue] = moment();
    } else if (filters[i].id === "filter_by" && !filters[i].isMulti) {
      initialState[filters[i].id] = "by_building";
    } else {
      initialState[filters[i].id] = null;
    }
    requiredFilter[filters[i].id] = filters[i].isRequired ?? false;
    conditionalRender[filters[i].id] =
      filters[i].conditionalRenderIdentifier ?? null;
  }

  const handleOnClickReset = () => {
    if (useOldResetFunc) {
      setState(initialState);
      return;
    }
    const tempResetIsTriggered: any = {};
    const resetIsTriggeredKeys = Object.keys(resetIsTriggered || {});
    resetIsTriggeredKeys.forEach((key) => {
      tempResetIsTriggered[key] = true;
    });
    setResetIsTriggered(tempResetIsTriggered);
  };

  const buildParams = () => {
    const params = [];

    const stateCleaned = utils.commons.stripErrorFieldFilter(state);

    for (let key of Object.keys(stateCleaned)) {
      if (
        stateCleaned[key] !== null &&
        stateCleaned[key] !== undefined
      ) {
        if (typeof stateCleaned[key] === "string") {
          if (stateCleaned[key].trim() !== "") {
            params.push(key + "=" + stateCleaned[key]);
          }
        } else if (typeof stateCleaned[key] === "object") {
          if (isMoment(stateCleaned[key])) {
            const dateToString = moment(stateCleaned[key]).format(
              "YYYY-MM-DD"
            );
            params.push(key + "=" + dateToString);
          } else {
            if (stateCleaned[key]?.length > 0) {
              let multipleParams = [];
              for (let i = 0; i < stateCleaned[key].length; i++) {
                multipleParams.push(stateCleaned[key][i].value);
              }
              params.push(`${key}=${multipleParams}`);
            } else {
              params.push(key + "=" + stateCleaned[key].value);
            }
          }
        } else {
          params.push(key + "=" + stateCleaned[key]);
        }
      }
    }
    return params;
  };

  const search = () => {
    let validatorPassed = true;
    if (filterValidators) {
      const result = ValidateFilter(
        state,
        setState,
        filterValidators
      );
      validatorPassed = result;
    }
    let isEmptyForm = true;
    for (let key of Object.keys(state)) {
      if (state[key] !== null && state[key] !== undefined) {
        isEmptyForm = false;
        break;
      }
    }

    if (!isEmptyForm && validatorPassed) {
      const params = buildParams();
      setParams(params.join("&"));
      if (!utils.commons.isFunction(fetch)) return;
      fetch(1, selectedRowPerPage, ordering, params.join("&"));
    }
  };

  const asyncSelectWithConditionalRender = (
    state: any,
    filter: any
  ) => {
    if (utils.commons.isArray(state?.["filter_by"])) {
      return state?.["filter_by"]?.some(
        (item: any) =>
          item.value === filter.conditionalRenderIdentifier
      );
    }
    return (
      state?.["filter_by"] === filter.conditionalRenderIdentifier
    );
  };

  useEffect(() => {
    const tempResetIsTriggered: { [key: string]: boolean } = {};
    filters.forEach((filter) => {
      tempResetIsTriggered[filter.id] = false;
    });
    setResetIsTriggered(tempResetIsTriggered);
  }, []);

  useEffect(() => {
    if (
      typeof searchUrlQuery === "string" &&
      searchUrlQuery.length > 0 &&
      !hasFetchInitialState.current
    ) {
      search();
      if (dataTableList.length > 0) {
        hasFetchInitialState.current = true;
      }
    }
  }, [search, dataTableList]);

  const renderFilters = filters.map((filter, i) => {
    if (
      filter.type === "text" ||
      filter.type === "number" ||
      filter.type === "email"
    ) {
      return (
        <FilterFieldItem key={"filter" + i}>
          <FilterTitle error={state[filter.id + "Error"]}>
            {filter.name}
            {filter.isRequired && "*"}
          </FilterTitle>

          <CTextField
            name={filter.id}
            type={filter.type}
            onKeyDown={(e: KeyboardEvent) => {
              if (e.key === "Enter") {
                alert("dummy function");
              }
            }}
            state={state}
            setState={setState}
          />
          {state[filter.id + "Error"] && (
            <HelperLabel>{state[filter.id + "Error"]}</HelperLabel>
          )}
        </FilterFieldItem>
      );
    } else if (filter.type === "datetime" || filter.type === "date") {
      return (
        <FilterFieldItem key={"filter" + i} className="form-group">
          <FilterTitle error={state[filter.id + "Error"]}>
            {filter.name}
            {filter.isRequired && "*"}
          </FilterTitle>
          <CDatePicker
            name={filter.id}
            state={state}
            setState={setState}
            placeholder={filter.name}
            showClearDate={filter.showClearDate}
          />
          {state[filter.id + "Error"] && (
            <HelperLabel>{state[filter.id + "Error"]}</HelperLabel>
          )}
        </FilterFieldItem>
      );
    } else if (filter.type === "select") {
      return (
        <FilterFieldItem key={"filter" + i}>
          <FilterTitle error={state[filter.id + "Error"]}>
            {filter.name}
            {filter.isRequired && "*"}
          </FilterTitle>
          <CSelectV2
            extraOptions={filter?.append}
            name={filter.id}
            state={state}
            isMulti={filter.isMulti}
            setState={setState}
            data={filter.data}
            dependsOn={filter.dependsOn}
            cacheKey={
              filter.notCached === true ? null : "filter" + filter.id
            }
            closeMenuOnSelect={filter.closeMenuOnSelect}
            itemsExtractor={filter.itemsExtractor}
            hideSelectedOption={filter.hideSelectedOptions}
            isCheckbox={filter.isCheckbox}
            isClearable={filter.isClearable}
            defaultState={defaultState}
            resetIsTriggered={resetIsTriggered}
            setResetIsTriggered={setResetIsTriggered}
            minWidth={filter.minWidth}
            customStyles={{ menuPortal: { zIndex: 11 } }}
          />
          {state[filter.id + "Error"] && (
            <HelperLabel>{state[filter.id + "Error"]}</HelperLabel>
          )}
        </FilterFieldItem>
      );
    } else if (
      filter.type === "async_select" &&
      filter?.conditionalRenderIdentifier &&
      asyncSelectWithConditionalRender(state, filter)
    ) {
      return (
        <FilterFieldItem key={"filter" + i}>
          <FilterTitle error={state[filter.id + "Error"]}>
            {filter.name}
            {filter.isRequired && "*"}
          </FilterTitle>
          <CAsyncSelectV2
            name={filter.id}
            state={state}
            isMulti={filter.isMulti}
            setState={setState}
            data={filter.data}
            dependsOn={filter.dependsOn}
            cacheKey={
              filter.notCached === true ? null : "filter" + filter.id
            }
            itemsExtractor={filter.itemsExtractor}
            hideSelectedOptions={filter.hideSelectedOptions}
            resetIsTriggered={resetIsTriggered}
            setResetIsTriggered={setResetIsTriggered}
            defaultState={defaultState}
            listenParam={filter.listenParam}
            placeholder={filter.placeholder}
            customStyles={{ menuPortal: { zIndex: 11 } }}
            isInputRequired={filter.isInputRequired}
            onInputChange={filter.onInputChange}
            customNoOptionMessage={filter.customNoOptionMessage}
          />
          {state[filter.id + "Error"] && (
            <HelperLabel>{state[filter.id + "Error"]}</HelperLabel>
          )}
        </FilterFieldItem>
      );
    } else if (
      filter.type === "async_select" &&
      !filter?.conditionalRenderIdentifier
    ) {
      return (
        <FilterFieldItem key={"filter" + i}>
          <FilterTitle error={state[filter.id + "Error"]}>
            {filter.name}
            {filter.isRequired && "*"}
          </FilterTitle>
          <CAsyncSelectV2
            name={filter.id}
            state={state}
            isMulti={filter.isMulti}
            setState={setState}
            data={filter.data}
            dependsOn={filter.dependsOn}
            cacheKey={
              filter.notCached === true ? null : "filter" + filter.id
            }
            itemsExtractor={filter.itemsExtractor}
            hideSelectedOptions={filter.hideSelectedOptions}
            resetIsTriggered={resetIsTriggered}
            setResetIsTriggered={setResetIsTriggered}
            defaultState={defaultState}
            listenParam={filter.listenParam}
            placeholder={filter.placeholder}
            customStyles={{ menuPortal: { zIndex: 11 } }}
            isInputRequired={filter.isInputRequired}
            onInputChange={filter.onInputChange}
            customNoOptionMessage={filter.customNoOptionMessage}
          />
          {state[filter.id + "Error"] && (
            <HelperLabel>{state[filter.id + "Error"]}</HelperLabel>
          )}
        </FilterFieldItem>
      );
    }
  });

  return (
    <Container>
      {addButton && (
        <AddBtnContainer>
          <a
            onClick={(e) => {
              e.preventDefault();
              if (
                accesses &&
                (accesses.includes(restAccessCode + ".create") ||
                  user["is_super_user"] ||
                  (isTeamMenu && user["has_team_management_access"]))
              ) {
                props.history.push(
                  utils.commons.stripMultipleSlashes(
                    location.pathname + "/add"
                  )
                );
              } else {
                sweetAlert.fire(
                  "Error!",
                  "You have no access to do this action.",
                  "error"
                );
              }
            }}
            className="btn btn-success waves-effect waves-light float-right"
            style={{ marginBottom: "10px" }}
          >
            <i className="fas fa-plus" /> Add
          </a>
        </AddBtnContainer>
      )}

      <FilterContainer>
        <FilterField>{renderFilters}</FilterField>
        <ActionBtnContainer>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "auto auto",
              gap: 16,
            }}
          >
            <CButtonV2 onClick={() => search()} title="Apply" />
            <CButtonV2
              onClick={handleOnClickReset}
              title="Reset"
              variant="OUTLINED"
              icon="yes"
            />
          </div>
        </ActionBtnContainer>
      </FilterContainer>
    </Container>
  );
};

export default withRouter(FilterPanelV2);
