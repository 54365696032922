import { useState } from "react";

import { useParams, withRouter } from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import Form from "../../../../components/ubold/organisms/Form";

import CVTextField from "../../../../components/ubold/molecules/forms/CVTextField";
import CVRichTextField from "../../../../components/ubold/molecules/forms/CVRichTextField";

import definitions from "./definitions";

function DiscountBannerViewPage() {
  let { id }: { id: string } = useParams();

  const initialState = {
    small_title: "-",
    small_title_en: "-",
    small_description: "-",
    small_description_en: "-",
  };

  const [state, setState] = useState(initialState);

  return (
    <Page
      title={"Detail of " + definitions.title}
      restAccessCode={definitions.restAccessCode}
    >
      <Form
        id={id}
        definitions={definitions}
        state={state}
        setState={setState}
        readOnly={true}
      >
        <CVTextField
          name="title"
          title="Title"
          maxLength={25}
          isRequired
          readOnly={true}
        />
        <CVTextField
          name="title_en"
          title="Title EN"
          maxLength={25}
          isRequired
          readOnly={true}
        />
        <CVRichTextField
          name="description"
          title="Description (Max 250 Character)"
          maxLength="150"
          isRequired
          readOnly={true}
        />
        <CVRichTextField
          name="description_en"
          title="Description En (Max 250 Character)"
          maxLength="150"
          isRequired
          readOnly={true}
        />
        <CVTextField
          name="url"
          title="URL"
          maxLength={250}
          readOnly={true}
        />
      </Form>
    </Page>
  );
}

export default withRouter(DiscountBannerViewPage);
