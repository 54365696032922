import { withRouter } from "react-router-dom";

import TabPane from "../../../../../../components/ubold/organisms/TabPane";
import TabPaneSection from "../../../../../../components/ubold/organisms/TabPaneSection";
import CHTextField from "../../../../../../components/ubold/molecules/forms/CHTextField";
import CHTextView from "../../../../../../components/ubold/molecules/forms/CHTextView";
import CHSelect from "../../../../../../components/ubold/molecules/forms/CHSelect";
import CHAsyncSelect from "../../../../../../components/ubold/molecules/forms/CHAsyncSelect";
import CHTextArea from "../../../../../../components/ubold/molecules/forms/CHTextArea";
import CHDateTimePicker from "../../../../../../components/ubold/molecules/forms/CHDateTimePicker";
import COneOrManyToManyFieldV2 from "../../../../../../components/ubold/molecules/forms/COneOrManyToManyFieldV2";
import utils from "../../../../../../utils";
function Service(props: any) {
  const { id, definitions, tab, state, setState } = props;

  return (
    <TabPane
      id={id}
      definitions={definitions}
      tab={tab}
      state={state}
      setState={setState}
      readOnly={true}
    >
      <TabPaneSection style={{ padding: "25px 16px 0px" }}>
        <CHAsyncSelect
          name="order_new"
          title="Order"
          data="order_new/order?__type__=select_entries&with_related=1&only_ongoing_order=true"
          isRequired
          itemsExtractor={(value: any) => {
            return {
              label:
                value["tenant_name"] +
                " - " +
                value["building_name"] +
                " | " +
                value["room_variant_name"] +
                " | " +
                value["room_name"] +
                " (" +
                value.number +
                ")",
              value: value.id,
              tenant: value.tenant,
            };
          }}
          afterChange={(value: any) => {
            setState((prevState: any) => ({
              ...prevState,
              tenant: value?.tenant,
            }));
          }}
          readOnly
        />
        <CHAsyncSelect
          name="tenant"
          title="Tenant"
          dependsOn="order_new"
          data="user/tenant?__type__=select_entries"
          isRequired
          itemsExtractor={(row: {
            name: string;
            email: string;
            id: number;
            username: string;
            country_code: number;
            phone_number: number;
          }) => {
            return {
              label:
                row.name +
                " - " +
                row.email +
                " - " +
                row.country_code +
                row.phone_number,
              value: row.id,
            };
          }}
          readOnly
        />
        <CHSelect
          name="building_service_request_type"
          title="Request Type"
          data={{
            optionField: "buildings_service_request_type",
          }}
          readOnly
        />
        <CHAsyncSelect
          name="building_service"
          title={`Help & Service Name`}
          dependsOn="order_new"
          isRequired
          data="building/building__service?__type__=select_entries"
          readOnly
        />
        <CHAsyncSelect
          name="case_category"
          title="Case Category"
          data="building/case_category?__type__=select_entries"
          readOnly
        />
        <CHAsyncSelect
          name="sub_case_category"
          title="Sub Case Category"
          dependsOn="case_category"
          data="building/sub_case_category/?__type__=select_entries"
          readOnly
        />
        <CHTextArea name="notes" title="Notes" readOnly />
        <CHSelect
          name="status"
          title="Status"
          isRequired
          data={[
            {
              label: "Waiting",
              value: 1,
            },
            {
              label: "Processing",
              value: 2,
            },
            {
              label: "Done",
              value: 4,
            },
            {
              label: "Cancel",
              value: 5,
            },
          ]}
          readOnly
        />
        <CHDateTimePicker
          name="estimation_date"
          title="Estimation Date"
          type="date"
          readOnly
        />
        <CHDateTimePicker
          name="estimation_time"
          title="Estimation Time"
          type="time"
          readOnly
        />
        <CHTextArea
          name="estimation_reason"
          title="Comment from Rukita"
          readOnly
        />
        <CHSelect
          name="rate"
          title="Rate"
          data={[
            {
              label: "Yes",
              value: true,
            },
            {
              label: "No",
              value: false,
            },
          ]}
          readOnly
        />
        <CHTextArea
          name="comment"
          title="Comment from Tenant"
          readOnly
        />
        <CHTextArea
          name="cancelation_notes"
          title="Cancellation Notes"
          readOnly
        />
        <section
          style={{
            width: "100%",
            background: "#f2f2f2",
            height: 30,
            paddingRight: 24,
            paddingLeft: 29,
            alignItems: "center",
            fontSize: 16,
            fontWeight: 500,
            display: "flex",
          }}
        >
          Images
        </section>
        <COneOrManyToManyFieldV2
          id={id}
          name="service_images"
          saveOnlyFromMainForm
          mainTab={true}
          readOnly={true}
          columns={[
            {
              name: "photo_original",
              title: "Photo",
              type: "photo_upload",
              isRequired: true,
              readOnly: true,
            },
          ]}
        />
        <CHTextView
          name="date_created"
          title="Date Created"
          renderer={(value: string) => {
            return utils.formatter.dateTime(value);
          }}
        />
        <CHTextView
          name="date_updated"
          title="Date Updated"
          renderer={(value: string) => {
            return utils.formatter.dateTime(value);
          }}
        />
        <CHSelect
          name="selected_days"
          title="Selected Days"
          data={[
            {
              label: "Senin",
              value: 1,
            },
            {
              label: "Selasa",
              value: 2,
            },
            {
              label: "Rabu",
              value: 3,
            },
            {
              label: "Kamis",
              value: 4,
            },
            {
              label: "Jumat",
              value: 5,
            },
            {
              label: "Sabtu",
              value: 6,
            },
            {
              label: "Minggu",
              value: 7,
            },
          ]}
          readOnly
        />
        <CHDateTimePicker
          name="selected_date"
          title="Schedule Date"
          type="date"
          readOnly
        />
        <CHTextView
          name="quota_periode"
          title="Quota Period"
          readOnly
        />
        <CHTextField
          name="used_quota"
          title="Used Quota"
          type="number"
          readOnly
        />
        <CHTextView
          name="available_quota"
          title="Available Quota"
          readOnly
        />
      </TabPaneSection>
    </TabPane>
  );
}

export default withRouter(Service);
