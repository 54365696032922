import configBuilder from "../../../components/ubold/ConfigBuilder";

let definitions = {
  title: "Facilities",
  frontendPath: "/building/facility/facility",
  baseLink: "/building/facility",
  restAccessCode: "building.facility",
  __table__: {
    filterColumn: 3,
    hideEditButton: true,
    filters: [
      {
        id: "name",
        name: "Name",
        type: "text",
      },
      {
        id: "name_en",
        name: "Name EN",
        type: "text",
      },
      {
        id: "category",
        name: "Category",
        type: "select",
        data: {
          optionField: "buildings_facility_category",
        },
      },
    ],
    columns: [
      {
        id: "name",
        name: "Name",
        enableHyperlink: true,
      },
      {
        id: "name_en",
        name: "Name EN",
      },
      {
        id: "category",
        name: "Category",
        referStateFieldName: "buildings_facility_category",
        renderer: (value, _, constants) => {
          let label = "-";
          (constants ?? []).forEach((obj) => {
            if (obj.value === parseInt(value)) {
              label = obj.label;
            }
          });
          return label;
        },
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
