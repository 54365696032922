import {withRouter} from "react-router-dom";

import useGlobalState from "../../../../state";

import utils from "../../../../utils";

function CVTextView(props) {
    const {name, title, formColumn=2, isSafeContent=false, boldTitle=false, overrideLayout=null, restAccessCode, helperText=null, renderer, state} = props;

    let formColumnReal = 12 / formColumn;

    const [fieldAccesses,] = useGlobalState('field_accesses');

    return (
        <div
            className={"col-lg-" + (overrideLayout ? overrideLayout : formColumnReal)}
            style={{display: restAccessCode && utils.access.isFieldHidden(fieldAccesses, restAccessCode + '.' + name) ? 'none' : 'unset'}}
        >
            <div className="form-group mb-3">
                <label style={{fontWeight: boldTitle ? 'bold' : 'normal'}}>
                    {title}
                </label><br/>
                {isSafeContent ? <span dangerouslySetInnerHTML={{__html: state[name] !== null && state[name] !== undefined ? renderer && utils.commons.isFunction(renderer) ? renderer(state[name], state) : state[name] : '---'}} /> :
                    state[name] !== null && state[name] !== undefined ? renderer && utils.commons.isFunction(renderer) ? renderer(state[name], state) : state[name] : '---'}
                {helperText ? <br /> : ''}
                {helperText ? <span className="text-muted font-13 pt-1 mb-0"  dangerouslySetInnerHTML={{ __html: helperText }} /> : ''}
            </div>
        </div>
    );
}

export default withRouter(CVTextView);