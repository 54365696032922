import {useEffect, useState} from "react";

import {useParams, withRouter} from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import utils from "../../../../utils";

import definitions from "./definitions";

function LandlordInvoiceDetailPage() {
    let { id } = useParams();

    const [state, setState] = useState({});

    useEffect(() => {
        definitions.onInit(id, setState);
    }, []);

    return (
        <Page title={'Detail Landlord Invoice'}>
            <div className="row">
                <div className="col-lg-4">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="header-title mb-3">{state.landlord_name}</h4>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="mb-4">
                                        <h5 className="mt-0">Invoice Number:</h5>
                                        <p># {state.number === '' ? '---' : state.number}</p>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="mb-4">
                                        <h5 className="mt-0">Building Agreement:</h5>
                                        <p>{state.building_name}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="track-order-list">
                                <ul className="list-unstyled">
                                    <li className="completed">
                                        <h5 className="mt-0 mb-1">Building</h5>
                                        <p className="text-muted">{state.building_name}</p>
                                    </li>
                                    <li className="completed">
                                        <h5 className="mt-0 mb-1">Room Variant</h5>
                                        <p className="text-muted">{state.roomvariant_name}</p>
                                    </li>
                                    <li className="completed">
                                        <h5 className="mt-0 mb-1">Description</h5>
                                        <p className="text-muted">{state.description}</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-8">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="header-title mb-3">Detail</h4>
                            <div className="table-responsive">
                                <table className="table table-bordered table-centered mb-0">
                                    <thead className="thead-light">
                                    <tr>
                                        <th>Name</th>
                                        <th>Description</th>
                                        <th>Amount</th>
                                        <th>Total</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {state.details ? state.details.map((detail, i) => {
                                        return <tr key={'detail' + i}>
                                            <th scope="row">{detail.name}</th>
                                            <td>{detail.description}</td>
                                            <td>{utils.formatter.currency(detail.amount)}</td>
                                            <td>{utils.formatter.currency(detail.amount)}</td>
                                        </tr>;
                                    }) : <tr><td>No data</td></tr> }
                                    <tr>
                                        <th scope="row" colSpan="3" className="text-right">Total :</th>
                                        <td>
                                            <div className="font-weight-bold">{utils.formatter.currency(state.total_amount)}</div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Page>
    );
}

export default withRouter(LandlordInvoiceDetailPage);