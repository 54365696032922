import moment from "moment";

import utils from "../../../../utils";

import configBuilder from "../../../../components/ubold/ConfigBuilder";

import pageDefinitions from "../definitions";

const definitions = {
  ...pageDefinitions,
  linkHistory: (id: string) =>
    `${definitions.frontendPath}/history/${id}`,
  tabs: [
    {
      id: "building",
      name: "Building",
    },
    {
      id: "t_photos",
      name: "Photos",
    },
    {
      id: "t_services",
      name: "Help & Services",
    },
    {
      id: "t_facilities",
      name: "Facilities",
    },
    {
      id: "t_addons",
      name: "Addons",
    },
    {
      id: "t_room_variants",
      name: "Room Variants",
    },
    {
      id: "t_point_of_interests",
      name: "Point of Interests",
    },
    {
      id: "t_building_discount",
      name: "Discount / Promo",
    },
  ],
  validators: {
    building_type: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    landlord: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    building_name: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    release_status: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    publish_status: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    country: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    province: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    city: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    district: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    subdistrict: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    lat: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    lon: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    address: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    neighborhood: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    postal_code: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    property_type: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    place_groups: (value: string) => {
      return utils.validator.validateRequired(value);
    },
  },
};

type dataShape = {
  country: number;
  last_maintenance: string;
  last_maintenanceDate: string;
  last_maintenanceTime: string;
  next_maintenance: string;
  next_maintenanceDate: string;
  next_maintenanceTime: string;
};

export default configBuilder.buildAddEditPageConfig(
  definitions,
  ["photo_thumbnail_original"],
  ["building_services", "facilities"],
  "edit",
  (data: dataShape) => {
    if (data.last_maintenance) {
      data.last_maintenanceDate = moment(
        data.last_maintenance
      ).format("YYYY-MM-DD");
      data.last_maintenanceTime = moment(
        data.last_maintenance
      ).format("HH:mm");
    }

    if (data.next_maintenance) {
      data.next_maintenanceDate = moment(
        data.next_maintenance
      ).format("YYYY-MM-DD");
      data.next_maintenanceTime = moment(
        data.next_maintenance
      ).format("HH:mm");
    }

    if (data.country) {
      data.country = 84;
    }

    return data;
  }
);
