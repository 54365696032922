import configBuilder from '../../../../components/ubold/ConfigBuilder';

import pageDefinitions from "../definitions";

import utils from "../../../../utils";

const definitions = {...pageDefinitions,
    tabs: [
        {
            id: 'event',
            name: 'Event'
        },
        {
            id: 't_attendances',
            name: 'Attendances'
        },
    ],
    validators: {
        event_name: value => {
            return utils.validator.validateRequired(value);
        },
        event_type: value => {
            return utils.validator.validateRequired(value);
        },
        host: value => {
            return utils.validator.validateRequired(value);
        },
        photo_original: value => {
            return utils.validator.validateRequired(value);
        },
        date_started: value => {
            return utils.validator.validateRequired(value);
        },
        date_ended: value => {
            return utils.validator.validateRequired(value);
        },
        time_started: value => {
            return utils.validator.validateRequired(value);
        },
        latest_cancellation_date: value => {
            return utils.validator.validateRequired(value);
        },
        latest_registration_date: value => {
            return utils.validator.validateRequired(value);
        },
    },
}

export default configBuilder.buildAddEditPageConfig(definitions, ['photo_original', 'host_pic_original']);