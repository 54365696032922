import { withRouter } from "react-router-dom";

import TabPane from "../../../../../../components/ubold/organisms/TabPane";
import TabPaneSection from "../../../../../../components/ubold/organisms/TabPaneSection";
import CHAsyncSelect from "../../../../../../components/ubold/molecules/forms/CHAsyncSelect";
import CHTextField from "../../../../../../components/ubold/molecules/forms/CHTextField";
import CHCheckBox from "../../../../../../components/ubold/molecules/forms/CHCheckBox";
import CHTextView from "../../../../../../components/ubold/molecules/forms/CHTextView";

import utils from "../../../../../../utils";

function FirstTab(props: any) {
  const { id, definitions, tab, state, setState } = props;

  return (
    <TabPane
      id={id}
      definitions={definitions}
      tab={tab}
      readOnly
      state={state}
      setState={setState}
    >
      <TabPaneSection style={{ padding: "25px 16px 0px" }}>
        <CHAsyncSelect
          name="event"
          title="Event"
          data="event/event?__type__=select_entries"
          isRequired
          itemsExtractor={(value: {
            event_name: string;
            id: string;
          }) => {
            return {
              label: value.event_name,
              value: value.id,
            };
          }}
          readOnly
        />
        <CHAsyncSelect
          name="tenant"
          title="Tenant"
          isRequired
          data="user/tenant?__type__=select_entries"
          readOnly
          itemsExtractor={(row: {
            name: string;
            email: string;
            id: number;
            username: string;
            country_code: number;
            phone_number: number;
          }) => {
            return {
              label:
                row.name +
                " - " +
                row.email +
                " - " +
                row.country_code +
                row.phone_number,
              value: row.id,
            };
          }}
        />
        <CHTextField
          name="comment"
          title="Comment"
          maxLength={150}
          isRequired
          readOnly
        />
        <CHAsyncSelect
          name="parent"
          title="Parent Comment"
          data="event/event_comment?__type__=select_entries"
          itemsExtractor={(value: {
            comment: string;
            id: string;
          }) => {
            return {
              label: value.comment,
              value: value.id,
            };
          }}
          readOnly
        />
        <CHTextField
          name="total_likes"
          title="Total Likes"
          type="number"
          readOnly
        />
        <CHCheckBox name="pinned" title="Pinned" readOnly />
        <CHTextView
          name="date_created"
          title="Date Created"
          renderer={(value: string) => {
            return utils.formatter.dateTime(value);
          }}
          readOnly
        />
        <CHTextView
          name="date_update"
          title="Date Updated"
          renderer={(value: string) => {
            return utils.formatter.dateTime(value);
          }}
          readOnly
        />
      </TabPaneSection>
    </TabPane>
  );
}

export default withRouter(FirstTab);
