import {useState} from "react";

import {useParams, withRouter} from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import Form from "../../../../components/ubold/organisms/Form";

import definitions from "./definitions";
import CVTextField from "../../../../components/ubold/molecules/forms/CVTextField";
import CVImageUpload from "../../../../components/ubold/molecules/forms/CVImageUpload";

function CAddEditPage() {
    let {id} = useParams();

    const initialState = {};

    const [state, setState] = useState(initialState);

    return (
        <Page title={(id === undefined ? 'Add New' : 'Edit') + ' ' +  definitions.title}
              restAccessCode={definitions.restAccessCode}>
            <Form
                id={id}
                definitions={definitions}
                state={state}
                setState={setState}>
                <CVTextField
                    name='title'
                    title='Title'
                    maxLength={120}
                    isRequired
                />
                <CVImageUpload
                    name='photo_original'
                    title='Photo'
                    isRequired
                />
                <CVTextField
                    name='target_url'
                    title='Target URL'
                    maxLength={200}
                />
                <CVTextField
                    name='sequence'
                    title='Sequence'
                    type='number'
                />
            </Form>
        </Page>
    );
}

export default withRouter(CAddEditPage);