import { withRouter } from "react-router-dom";

import TabPane from "../../../../../../components/ubold/organisms/TabPane";
import TabPaneSection from "../../../../../../components/ubold/organisms/TabPaneSection";

import CVTextView from "../../../../../../components/ubold/molecules/forms/CVTextView";

import utils from "../../../../../../utils";

function TabPaymentView(props: any) {
  const { id, definitions, tab, state, setState } = props;

  return (
    <TabPane
      id={id}
      definitions={definitions}
      tab={tab}
      hideAddAnother
      readOnly
      state={state}
      setState={setState}
    >
      <TabPaneSection multipleColumn style={{ padding: "25px" }}>
        <CVTextView title="Invoice" name="invoice_number" readOnly />
        <CVTextView
          title="Bank Account Number"
          name="bank_account_number"
          readOnly
        />
        <CVTextView
          title="Bank Account Name"
          name="bank_account_name"
          readOnly
        />
        <CVTextView title="Bank Name" name="bank_name" />
        <CVTextView
          title="Transaction Date"
          name="transaction_date"
          renderer={(value: string) => {
            return utils.formatter.dateTime(value);
          }}
          readOnly
        />
        <CVTextView title="Status" name="status_text" />
        {/* Photo */}
        <CVTextView
          title="OTP Payment Method"
          name="otp_payment_method_text"
          readOnly
        />
        <CVTextView
          title="Amount"
          name="amount"
          renderer={(value: string) => {
            return utils.formatter.currency(value);
          }}
          readOnly
        />
        <CVTextView
          title="Date Created"
          name="date_created"
          renderer={(value: string) => {
            return utils.formatter.dateTime(value);
          }}
          readOnly
        />
        <CVTextView
          title="Date Updated"
          name="date_updated"
          renderer={(value: string) => {
            return utils.formatter.dateTime(value);
          }}
          readOnly
        />
        <CVTextView
          title="Remaining Amount"
          name="remaining_amount"
          renderer={(value: string) => {
            return utils.formatter.currency(value);
          }}
          readOnly
        />
        <CVTextView
          title="Midtrans Number"
          name="midtrans_number"
          readOnly
        />
        <CVTextView title="Tenant" name="tenant_text" readOnly />
      </TabPaneSection>
    </TabPane>
  );
}

export default withRouter(TabPaymentView);
