import configBuilder from "../../../../components/ubold/ConfigBuilder";

import pageDefinitions from "../definitions";
import utils from "../../../../utils";

const definitions = {
  ...pageDefinitions,
  tabs: [
    {
      id: "event_comment",
      name: "Event Comment",
    },
    {
      id: "t_event_comment_like",
      name: "Likes",
    },
  ],
  validators: {
    event: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    tenant: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    comment: (value: string) => {
      return utils.validator.validateRequired(value);
    },
  },
};

export default configBuilder.buildAddEditPageConfig(definitions);
