import utils from "../../../../utils";
import configBuilder from "../../../../components/ubold/ConfigBuilder";
import pageDefinitions from "../definitions";

const expectedMTStatus = [1, 2];

const definitions = {
  ...pageDefinitions,
  linkHistory: (id) => `${definitions.frontendPath}/history/${id}`,
  tabs: [
    {
      id: "room",
      name: "Room",
    },
    {
      id: "t_photos",
      name: "Photos",
    },
    {
      id: "t_facilities",
      name: "Facilities",
    },
    {
      id: "t_features",
      name: "Features",
    },
    {
      id: "t_room_service_schedule",
      name: "Service Schedule",
    },
  ],
  validators: {
    name: (value) => {
      return utils.validator.validateRequired(value);
    },
    room_variant: (value) => {
      return utils.validator.validateRequired(value);
    },
    date_started_maintenance: (value, state) => {
      const dateStartedMaintenanceValue =
        utils.validator.validateRequired(value);
      if (
        expectedMTStatus.includes(state["maintenance_status"]) &&
        dateStartedMaintenanceValue !== true
      ) {
        return "Date Started Maintenance is Required";
      } else {
        return true;
      }
    },
  },
};

export default configBuilder.buildAddEditPageConfig(definitions);
