import configBuilder from "../../../components/ubold/ConfigBuilder";

let definitions = {
  title: "App Highlights",
  frontendPath: "/marketing/highlight",
  baseLink: "/user/highlight",
  restAccessCode: "user.highlight",
  __table__: {
    filterColumn: 3,
    hideEditButton: true,
    removeIdentifier: "title",
    filters: [
      {
        id: "title",
        name: "Title",
        type: "text",
      },
      {
        id: "target_url",
        name: "Target URL",
        type: "text",
      },
      {
        id: "sequence",
        name: "Sequence",
        type: "number",
      },
    ],
    columns: [
      {
        id: "title",
        name: "Title",
        enableHyperlink: true,
      },
      {
        id: "target_url",
        name: "Target URL",
      },
      {
        id: "sequence",
        name: "Sequence",
        center: true,
      },
      {
        id: "photo_original",
        name: "Photo",
        isSafeContent: true,
        canDoOrdering: false,
        center: true,
        renderer: (value) => {
          if (value && value !== "-" && value !== "") {
            return (
              "<img alt='image' src='" + value + "' width='50px' />"
            );
          } else {
            return value;
          }
        },
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
