import Select, { InputActionMeta, ActionMeta } from "react-select";

interface CSelectV3Props {
  value: {
    label: string;
    value: number;
  };
  isLoading?: boolean;
  options?: any[];
  handleOnChange?: (
    newValue: unknown,
    actionMeta: ActionMeta<unknown>
  ) => void;
  inputValue?: string;
  isSearchable?: boolean;
  readOnly?: boolean;
  isClearable?: boolean;
  handleOnInputChange?: (
    newValue: string,
    actionMeta: InputActionMeta
  ) => void;
  onMenuScrollToBottom?: (event: WheelEvent | TouchEvent) => void;
}

const CSelectV3 = (props: CSelectV3Props) => {
  const {
    options,
    readOnly = false,
    value,
    inputValue,
    isSearchable = false,
    isLoading,
    isClearable = false,
    handleOnChange = (
      newValue: unknown,
      actionMeta: ActionMeta<unknown>
    ) => {},
    handleOnInputChange,
    onMenuScrollToBottom,
  } = props;

  return (
    <Select
      styles={{
        container: (provided, state) => ({
          ...provided,
          zIndex: state.isFocused ? "9999" : "1",
        }),
      }}
      isDisabled={readOnly}
      options={options}
      onChange={handleOnChange}
      inputValue={inputValue}
      onInputChange={handleOnInputChange}
      placeholder={isSearchable ? "Type to search..." : "Select..."}
      value={value}
      isSearchable={isSearchable}
      isClearable={isClearable}
      isLoading={isLoading}
      onMenuScrollToBottom={onMenuScrollToBottom}
    />
  );
};

export default CSelectV3;
