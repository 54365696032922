import utils from "../../../../utils";

import configBuilder from "../../../../components/ubold/ConfigBuilder";

import pageDefinitions from "../definitions";

const definitions: any = {
  ...pageDefinitions,
  tabs: [
    {
      id: "vouchers",
      name: "Voucher Management",
    },
    {
      id: "t_voucher_targets",
      name: "Voucher Targets",
    },
    {
      id: "t_voucher_codes",
      name: "Voucher Codes",
    },
  ],
  validators: {
    name: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    image: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    category: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    voucher_category: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    amount: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    price: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    quota: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    vouchers_detail: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    htu: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    tnc: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    date_started: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    date_ended: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    date_published: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    icon: (value: string) => {
      return utils.validator.validateRequired(value);
    },
  },
  useBEValidation: true,
  /** pass only field which receive the validation from BE below */
  validationFromBE: {
    external_provider: true,
    expire_time: true,
    brand_name: true,
    brand_information: true,
    multiple_use: true,
    upload_voucher_code: true,
    order_no: true,
    partner_url: true,
  },
};

export default configBuilder.buildAddEditPageConfig(definitions, [
  "image",
  "icon",
  "pn_picture_original",
]);
