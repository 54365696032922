import secondaryDataHandler from "./SecondaryDataHandler";

import utils from "../../../utils";

import configBuilder from "../../../components/ubold/ConfigBuilder";

// hidden to hide export button
// import configs from "../../../configs";
// import withReactContent from "sweetalert2-react-content";
// import Swal from "sweetalert2";

let definitions = {
  title: "Outstanding Tenant Invoices",
  frontendPath: "/finance/invoice_outstanding",
  baseLink: "/finances_new/invoice",
  restAccessCode: "finance_new.invoice_outstanding",
  reloadAfterAdd: true,
  __table__: {
    filterColumn: 3,
    isHideRemoveButton: true,
    hideRemoveButton: true,
    removeIdentifier: "number",
    hasSecondaryData: true,
    secondaryDataRow: 3,
    secondaryDataItem: 13,
    secondaryDataHandler: secondaryDataHandler,
    hideEditButton: true,
    isAddButtonDisabled: true,
    presetParams: "source=2",
    // hidden to hide export button
    // filterButtonsRight: [
    //   {
    //     name: "Export",
    //     onClick: (e: React.MouseEventHandler, state: any) => {
    //       const fieldMaps: any = {
    //         date: "date",
    //         invoice: "number",
    //         order: "order",
    //         tenant: "tenant",
    //         building: "building",
    //         payment_method: "selected_payment_method",
    //         building_type: "building_type",
    //         midtrans_number: "midtrans",
    //         invoice_status: "invoice_status",
    //         release_status: "release_status",
    //       };

    //       const params: string[] = [];

    //       for (let key of Object.keys(state)) {
    //         if (
    //           state[key] !== null &&
    //           state[key] !== undefined &&
    //           fieldMaps[key]
    //         ) {
    //           if (typeof state[key] === "string") {
    //             if (state[key].trim() !== "") {
    //               params.push(fieldMaps[key] + "=" + state[key]);
    //             }
    //           } else if (typeof state[key] === "object") {
    //             params.push(fieldMaps[key] + "=" + state[key].value);
    //           } else {
    //             params.push(fieldMaps[key] + "=" + state[key]);
    //           }
    //         }
    //       }

    //       const sweetAlert = withReactContent(Swal);

    //       utils.httpClient.get(
    //         configs.apiUrl +
    //           "finances_new/invoice/export/?" +
    //           params.join("&"),
    //         (response: any) => {
    //           sweetAlert.fire("", response.message, "info");
    //         },
    //         (error: any, message: any) => {
    //           sweetAlert.fire("", message, "error");
    //         }
    //       );
    //     },
    //   },
    // ],
    filters: [
      {
        id: "invoice",
        name: "Invoice ID",
        type: "async_select",
        data: "finances_new/invoice?__type__=select_entries",
        customAdditionalParams: "&source=2",
        itemsExtractor: (item: {
          number: string;
          id: string | number;
        }) => {
          return {
            label: item.number,
            value: item.id,
          };
        },
      },
      {
        id: "order",
        name: "Order ID",
        type: "async_select",
        data: "order_new/order?__type__=select_entries",
        itemsExtractor: (item: {
          number: string;
          id: string | number;
        }) => {
          return {
            label: item.number,
            value: item.id,
          };
        },
      },
      {
        id: "invoice_status",
        name: "Invoice Status",
        type: "select",
        data: {
          optionField: "finance_new_invoice_status",
        },
      },
      {
        id: "tenant",
        name: "Tenant Name & Email",
        type: "async_select",
        data: "user/tenant?__type__=select_entries",
        itemsExtractor: (item: {
          name: string;
          email: string;
          id: string | number;
        }) => {
          const label = `${item.name} - ${item.email}`;
          return {
            label,
            value: item.id,
          };
        },
      },
      {
        id: "building",
        name: "Building Name",
        type: "async_select",
        data: "building/building?__type__=select_entries",
        itemsExtractor: (item: {
          building_name: string;
          id: string | number;
        }) => {
          return {
            label: item.building_name,
            value: item.id,
          };
        },
      },
      {
        id: "room_number",
        name: "Room Number",
        type: "async_select",
        data: "building/room?__type__=select_entries",
        dependsOnPrefix: "",
        dependsOn: "building",
        requiredDependsOnBeforeLoad: true,
        helperText:
          "Please select building name to enable this filter",
      },
    ],
    columns: [
      {
        id: "number",
        name: "Invoice ID",
        enableHyperlink: true,
      },
      {
        id: "order_number",
        name: "Order ID",
        renderer: (
          _: any,
          value: { order: string | number; order_number: string }
        ) =>
          `<a class='link-rukita' target='_blank' rel='noopener' style='color: #35b0a7;' href='/sales/order_sop/edit/${value.order}'>${value.order_number}</a>`,
      },
      {
        id: "tenant_name",
        name: "Tenant Name",
      },
      {
        id: "building_name",
        name: "Building Name",
      },
      {
        id: "room_name",
        name: "Room Name",
      },
      {
        id: "grand_total",
        name: "Invoice Grand Total",
        renderer: (value: string) => {
          return utils.formatter.currency(value);
        },
      },
      {
        id: "release_status",
        name: "Release Status",
        canDoOrdering: false,
        referStateFieldName: "finance_new_invoice_release_status",
        renderer: (
          value: string,
          _: any,
          constants: { label: string; value: string | number }[]
        ) => {
          let label = "-";
          (constants ?? []).forEach(
            (obj: { label: string; value: string | number }) => {
              if (obj.value === parseInt(value)) {
                label = obj.label;
              }
            }
          );
          return label;
        },
      },
      {
        id: "started_at",
        name: "Started Date",
        renderer: (value: string) => {
          return utils.formatter.dateTime(value);
        },
      },
      {
        id: "ended_at",
        name: "Ended Date",
        renderer: (value: string) => {
          return utils.formatter.dateTime(value);
        },
      },
      {
        id: "date_created",
        name: "Created Date",
        renderer: (value: string) => {
          return utils.formatter.dateTime(value);
        },
      },
      {
        id: "created_by",
        name: "Created By",
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
