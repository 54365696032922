import { useEffect, useState } from "react";

import { useParams, withRouter } from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import TabbedForm from "../../../../components/ubold/organisms/TabbedForm";
import TabHeaders from "../../../../components/ubold/organisms/TabHeaders";
import TabContent from "../../../../components/ubold/organisms/TabContent";

import Invoice from "./Tabs/InvoiceGeneral";
import InvoicePayments from "./Tabs/InvoicePayments";
import InvoiceMidtransTransactions from "./Tabs/InvoiceMidtransTransactions";

import definitions from "./definitions";

function InvoiceViewPage() {
  let { id }: any = useParams();

  const initialState: any = {};

  const [state, setState] = useState(initialState);

  useEffect(() => {
    if (!state?.payment_link?.url) return;
    setState((prevState: any) => ({
      ...prevState,
      payment_link_url: state?.payment_link?.url,
    }));
  }, [state?.payment_link]);

  return (
    <Page
      title={`Detail of ${definitions.title}`}
      restAccessCode={definitions.restAccessCode}
    >
      <TabbedForm
        id={id}
        state={state}
        setState={setState}
        definitions={definitions}
      >
        <TabHeaders />
        <TabContent>
          <Invoice />
          <InvoicePayments />
          <InvoiceMidtransTransactions />
        </TabContent>
      </TabbedForm>
    </Page>
  );
}

export default withRouter(InvoiceViewPage);
