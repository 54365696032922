import configBuilder from "../../../../../components/ubold/ConfigBuilder";

import utils from "../../../../../utils";

let definitions = {
  title: "All Rooms DC",
  frontendPath: "/u/dashboard/sales/all_rooms_dc",
  baseLink: "/u/dashboard/sales/all_rooms_dc",
  restAccessCode: "home.dashboard.sales.all_rooms_dc",
  __table__: {
    filterColumn: 4,
    isAddButtonDisabled: true,
    freezeColumns: 4,
    hideColumnNumbering: false,
    hideEditButton: true,
    isHideRemoveButton: true,
    isUseNewFilter: true,
    isExportEnabled: true,
    exportEndpointURL: "u/dashboard/sales/all_rooms_dc/export",
    hideRemoveButton: true,
    isDisableInitialLoad: true,
    filters: [
      {
        id: "district",
        mandatoryField: true,
        name: "District",
        type: "async_select",
        notCached: true,
        isMulti: true,
        data: "place/district?__type__=select_entries&only_with_buildings=true&building_type=dc",
        itemsExtractor: (item) => {
          return {
            label:
              item.name +
              (item.city ? ", " + item.city : "") +
              (item.province ? ", " + item.province : "") +
              (item.country ? ", " + item.country : ""),
            value: item.id,
          };
        },
      },
      {
        id: "building",
        mandatoryField: true,
        name: "Building",
        type: "async_select",
        notCached: true,
        isMulti: true,
        data: "building/building?__type__=select_entries&building_type=2",
        itemsExtractor: (item) => {
          return {
            label: item.building_name,
            value: item.id,
          };
        },
      },
      {
        id: "tenant",
        name: "Tenant",
        type: "async_select",
        data: "user/tenant?__type__=select_entries",
        isMulti: true,
        itemsExtractor: (item) => {
          return {
            label: item.name,
            value: item.id,
          };
        },
      },
      {
        id: "status",
        name: "Status",
        type: "checkbox",
        constantField: "buildings_room_status",
      },
    ],
    columns: [
      {
        id: "building_name",
        name: "Property",
        canDoOrdering: true,
        isSafeContent: true,
        maxWidthColumn: "unset",
        tooltip: {
          enabled: true,
          limitCharacters: 24,
          trigger: "hover",
          zIndex: 2,
        },
        wrap: false,
        renderer: (value, state) => {
          return (
            "<a class='link-rukita' title='Click to see detail' style='color: #1abc9c;' href='/building/property_list/edit/" +
            state["building"] +
            "' target='_blank'>" +
            utils.formatter.truncateWithEllipsis(value, 24) +
            "</a>"
          );
        },
      },
      {
        id: "room_name",
        name: "Room",
        canDoOrdering: true,
        isSafeContent: true,
        maxWidthColumn: "unset",
        wrap: false,
        renderer: (value, state) => {
          return (
            "<a class='link-rukita' title='Click to see detail' style='color: #1abc9c;' href='/building/room/room/edit/" +
            state["id"] +
            "' target='_blank'>" +
            utils.formatter.truncateWithEllipsis(value, 5) +
            "</a>"
          );
        },
        tooltip: {
          enabled: true,
          limitCharacters: 5,
          trigger: "hover",
          zIndex: 2,
        },
      },
      {
        id: "room_variant_name",
        name: "Room Variant",
        canDoOrdering: true,
        isSafeContent: true,
        maxWidthColumn: "unset",
        wrap: false,
        renderer: (value, state) => {
          return (
            "<a class='link-rukita' title='Click to see detail' style='color: #1abc9c;' href='/building/room/room_variant/edit/" +
            state["room_variant"] +
            "' target='_blank'>" +
            utils.formatter.truncateWithEllipsis(value, 15) +
            "</a>"
          );
        },
        tooltip: {
          enabled: true,
          limitCharacters: 15,
          trigger: "hover",
          zIndex: 2,
        },
      },
      {
        id: "room_status",
        name: "Status",
        canDoOrdering: true,
        maxWidthColumn: "unset",
        tooltip: {
          enabled: true,
          limitCharacters: 15,
          zIndex: 5,
        },
        wrap: false,
      },
      {
        id: "maintenance_status",
        name: "Maintenance Status",
        referStateFieldName: "buildings_room_maintenance_status",
        renderer: (value, _, constants) => {
          let label = "-";
          (constants ?? []).forEach((obj) => {
            if (obj.value === parseInt(value)) {
              label = obj.label;
            }
          });
          return label;
        },
      },
      {
        id: "retail_price",
        name: "Retail Price",
        canDoOrdering: true,
        maxWidthColumn: "unset",
        renderer: (value) => {
          return utils.formatter.currency(value);
        },
      },
      {
        id: "promo_price",
        name: "Promo Price",
        canDoOrdering: true,
        maxWidthColumn: "unset",
        renderer: (value) => {
          return utils.formatter.currency(value);
        },
      },
      {
        id: "order_id",
        name: "Order ID",
        canDoOrdering: false,
        isSafeContent: true,
        center: false,
        maxWidthColumn: "unset",
        renderer: (value, state) => {
          if (state["order_real_id"]) {
            return (
              "<a class='link-rukita' title='Click to see detail' style='color: #1abc9c;' href='/sales/order/edit/" +
              state["order_real_id"] +
              "' target='_blank'>" +
              value +
              "</a>"
            );
          } else {
            return value;
          }
        },
      },
      {
        id: "tenant",
        name: "Tenant",
        canDoOrdering: false,
        center: false,
        isSafeContent: true,
        maxWidthColumn: "unset",
        renderer: (value, state) => {
          if (state["tenant_id"]) {
            return (
              "<a class='link-rukita' title='Click to see detail' style='color: #1abc9c;' href='/tenant/tenant/edit/" +
              state["tenant_id"] +
              "' target='_blank'>" +
              value +
              "</a>"
            );
          } else {
            return value;
          }
        },
      },
      {
        id: "date_started",
        name: "Date Started",
        canDoOrdering: true,
        center: false,
        maxWidthColumn: "unset",
        renderer: (value) => {
          return utils.formatter.date(value);
        },
      },
      {
        id: "date_checkout",
        name: "Date Checkout",
        canDoOrdering: true,
        center: false,
        maxWidthColumn: "unset",
        renderer: (value) => {
          return utils.formatter.date(value);
        },
      },
      {
        id: "checkout_remarks",
        name: "Checkout Remarks",
        center: false,
        canDoOrdering: false,
        maxWidthColumn: "unset",
        tooltip: {
          enabled: true,
          limitCharacters: 47,
        },
      },
      {
        id: "room_remarks",
        name: "Room Remarks",
        canDoOrdering: false,
        center: false,
        maxWidthColumn: "unset",
        tooltip: {
          enabled: true,
          limitCharacters: 47,
        },
      },
      {
        id: "days_vacant",
        name: "Days Vacant",
        canDoOrdering: true,
        center: false,
        maxWidthColumn: "unset",
      },
      {
        id: "waiting_list",
        name: "Waiting List",
        canDoOrdering: false,
        isSafeContent: true,
        center: false,
        maxWidthColumn: "unset",
        renderer: (value, state) => {
          if (state["waiting_list_id"]) {
            return (
              "<a class='link-rukita' title='Click to see detail' style='color: #1abc9c;' href='/sales/order/edit/" +
              state["waiting_list_id"] +
              "' target='_blank'>" +
              value +
              "</a>"
            );
          } else {
            return value;
          }
        },
      },
      {
        id: "variant_remark",
        name: "Variant Remarks",
        center: false,
        canDoOrdering: false,
        maxWidthColumn: "unset",
        tooltip: {
          enabled: true,
          limitCharacters: 47,
        },
        renderer: (value) => {
          return utils.removeHtmlTags(value);
        },
      },
      {
        id: "booked_remark",
        name: "Booked Remarks",
        center: false,
        canDoOrdering: false,
        maxWidthColumn: "unset",
        tooltip: {
          enabled: true,
          limitCharacters: 47,
        },
      },
      {
        id: "district",
        name: "District",
        center: false,
        canDoOrdering: false,
        maxWidthColumn: "unset",
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
