// EVENTS
import Event from "pages/Event/Event";
import EventView from "pages/Event/Event/ViewOnly";
import EventAddEdit from "pages/Event/Event/AddEdit";
// EVENT COMMENTS
import EventComment from "pages/Event/EventComment";
import EventCommentAddEdit from "pages/Event/EventComment/AddEdit";
import EventCommentView from "pages/Event/EventComment/ViewOnly";

const ComEventRoutes = [
  // EVENTS
  {
    path: "/community_event/event",
    component: Event,
  },
  {
    path: "/community_event/event/(add|edit)/:id([0-9]+)?",
    component: EventAddEdit,
  },
  {
    path: "/community_event/event/view/:id([0-9]+)?",
    component: EventView,
  },
  // EVENT COMMENTS
  {
    path: "/community_event/event_comment",
    component: EventComment,
  },
  {
    path: "/community_event/event_comment/(add|edit)/:id([0-9]+)?",
    component: EventCommentAddEdit,
  },
  {
    path: "/community_event/event_comment/view/:id([0-9]+)?",
    component: EventCommentView,
  },
];

export default ComEventRoutes;
