import { withRouter } from "react-router-dom";

import TabPane from "components/ubold/organisms/TabPane";
import TabPaneSection from "../../../../../../components/ubold/organisms/TabPaneSection";
import CHTextField from "../../../../../../components/ubold/molecules/forms/CHTextField";
import CHAsyncSelect from "../../../../../../components/ubold/molecules/forms/CHAsyncSelect";
import CHSelect from "../../../../../../components/ubold/molecules/forms/CHSelect";
import CHTextView from "components/ubold/molecules/forms/CHTextView";
import CHDateTimePicker from "components/ubold/molecules/forms/CHDateTimePicker";
import CHPhoneNumber from "components/ubold/molecules/forms/CHPhoneNumber";
import CHImageView from "components/ubold/molecules/forms/CHImageView";

import Detail from "./Sections/Detail";
import EmergencyContact from "./Sections/EmergencyContact";
import RukiKoinAndRefferal from "./Sections/RukiKoinAndReferral";
import OtherInfo from "./Sections/OtherInfo";
import { genericTabProps } from "@types";
import useGlobalState from "state";

function Tenant(props: genericTabProps<Object>) {
  const { id, definitions, tab, state, setState } = props;
  const [constants] = useGlobalState("constant_values");

  return (
    <TabPane
      id={id}
      definitions={definitions}
      tab={tab}
      state={state}
      setState={setState}
      readOnly={true}
    >
      <TabPaneSection style={{ padding: "25px 16px 0px" }}>
        <CHTextView
          name="id"
          title="Tenant ID"
          helperText="This value is auto generated"
        />
        <CHTextField
          readOnly
          name="name"
          title="Fullname"
          isRequired
          maxLength={150}
          helperText="Required. 150 characters or fewer. Letters, space and -/' only."
        />
        <CHSelect
          readOnly
          name="gender"
          title="Gender"
          data={constants?.["user_tenant_gender"]}
          isRequired
        />
        <CHDateTimePicker
          name="dob"
          title="Birthday (Date of Birth)"
          isRequired
          type="date"
          readOnly
        />
        <CHPhoneNumber
          isRequired
          countryCodeName="country_code"
          phoneNumberName="phone_number"
          title="Phone Number"
          readOnly
        />
        <CHTextField
          name="email"
          title="Email"
          type="email"
          maxLength={254}
          isRequired
          readOnly
        />
        <CHSelect
          name="country"
          title="Country"
          data="place/country?__type__=select_entries&limit=1000"
          isRequired
          readOnly
        />
        <CHAsyncSelect
          name="province"
          title="Province"
          data="place/province?__type__=select_entries&limit=10"
          dependsOn="country"
          dependsOnStartFirst={true}
          isRequired
          readOnly
        />
        <CHAsyncSelect
          name="city"
          title="City"
          data="place/city?__type__=select_entries&limit=10"
          dependsOn="province"
          isRequired
          readOnly
        />
        <CHAsyncSelect
          name="district"
          title="District"
          data="place/district?__type__=select_entries&limit=10"
          dependsOn="city"
          isRequired
          readOnly
        />
        <CHAsyncSelect
          name="subdistrict"
          title="Sub District"
          data="place/subdistrict?__type__=select_entries&limit=10"
          dependsOn="district"
          isRequired
          readOnly
        />
        <CHTextField
          name="address"
          title="Address"
          maxLength={100}
          isRequired
          readOnly
        />
        <CHSelect
          name="identity_type"
          title="Identity Type"
          data={constants?.["user_tenant_identity_type"]}
          isRequired
          readOnly
        />
        <CHTextField
          name="identity_id"
          title="Identity ID"
          type="number"
          password={50}
          isRequired
          readOnly
        />
        <CHImageView
          name="id_card_photo_original"
          title="UIdentity Id Preview"
          imgWidth="300px"
        />
      </TabPaneSection>
      {id ? (
        <TabPaneSection accordionId="formAccordion">
          <Detail />
          <EmergencyContact />
          <RukiKoinAndRefferal />
          <OtherInfo />
        </TabPaneSection>
      ) : (
        <></>
      )}
    </TabPane>
  );
}

export default withRouter(Tenant);
