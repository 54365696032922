import { useEffect, useState } from "react";

import { withRouter } from "react-router-dom";

import { isMobile } from "react-device-detect";

import useGlobalState from "../../../../state";

import utils from "../../../../utils";

function CRichTextField(props) {
  const {
    name,
    state,
    setState,
    setErrorMessage,
    readOnly,
    validate,
    fontOnly = false,
  } = props;

  const [isLoaded, setIsLoaded] = useState(false);

  const [fieldAccesses] = useGlobalState("field_accesses");
  const [fieldAccessReversePolicy] = useGlobalState(
    "field_access_reverse_policy"
  );

  useEffect(() => {
    $(function () {
      if (fieldAccesses && !readOnly) {
        const component = $("#" + name);

        component.summernote({
          callbacks: {
            onChange: function (contents) {
              setIsLoaded(true);
              setState((prevState) => ({
                ...prevState,
                [name]: contents,
              }));

              if (
                (contents !== "" || contents !== "<p></p>") &&
                utils.commons.isFunction(validate)
              ) {
                validate(contents, setErrorMessage);
              } else {
                if (
                  setErrorMessage &&
                  utils.commons.isFunction(setErrorMessage)
                ) {
                  setErrorMessage(null);
                }
              }
            },
          },
          height: isMobile ? 350 : 200,
          toolbar: fontOnly
            ? [
                ["style", ["bold", "italic", "underline", "clear"]],
                [
                  "font",
                  ["strikethrough", "superscript", "subscript"],
                ],
                ["fontsize", ["fontsize"]],
                ["fontname", ["Rubik"]],
                ["color", ["color"]],
                ["para", ["ul", "ol", "paragraph"]],
                ["height", ["height"]],
              ]
            : [
                ["style", ["style"]],
                ["font", ["bold", "underline", "clear"]],
                ["fontname", ["fontname"]],
                ["color", ["color"]],
                ["para", ["ul", "ol", "paragraph"]],
                ["table", ["table"]],
                ["insert", ["link", "picture", "video"]],
                ["view", ["fullscreen", "codeview", "help"]],
              ],
        });
      }
    });
  });

  useEffect(() => {
    const component = $("#" + name);
    if ((fieldAccesses || fieldAccessReversePolicy) && !readOnly) {
      if (component && !isLoaded && state[name]) {
        setIsLoaded(true);

        component.summernote("code", state[name]);
      }
    } else if (readOnly) {
      component.summernote("code", state[name]);
      component.summernote("disable");
    } else {
      component.summernote({
        height: isMobile ? 350 : 200,
        callbacks: {
          onChange: function (contents) {
            setIsLoaded(true);
            setState((prevState) => ({
              ...prevState,
              [name]: contents,
            }));

            if (
              (contents !== "" || contents !== "<p></p>") &&
              utils.commons.isFunction(validate)
            ) {
              validate(contents, setErrorMessage);
            } else {
              if (
                setErrorMessage &&
                utils.commons.isFunction(setErrorMessage)
              ) {
                setErrorMessage(null);
              }
            }
          },
        },
      });
      component.summernote("code", state[name]);
    }
  }, [state[name]]);

  return <div id={name} />;
}

export default withRouter(CRichTextField);
