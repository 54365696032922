import { withRouter } from "react-router-dom";

import TabPane from "../../../../../../components/ubold/organisms/TabPane";
import TabPaneSection from "../../../../../../components/ubold/organisms/TabPaneSection";

import Services from "./Sections/Services";
import CHTextField from "../../../../../../components/ubold/molecules/forms/CHTextField";
import CHTextArea from "../../../../../../components/ubold/molecules/forms/CHTextArea";
import { genericTabProps } from "@types";

function Grade(props: genericTabProps<Object>) {
  const { id, definitions, tab, state, setState } = props;

  return (
    <TabPane
      id={id}
      definitions={definitions}
      tab={tab}
      state={state}
      setState={setState}
      readOnly
    >
      <TabPaneSection style={{ padding: "25px 16px 0px" }}>
        <CHTextField
          name="name"
          title="Name"
          maxLength={100}
          isRequired
          readOnly
        />
        <CHTextArea name="desc" title="Description" readOnly />
        <CHTextArea name="desc_en" title="Description EN" readOnly />
      </TabPaneSection>
      <TabPaneSection accordionId="formAccordion">
        <Services />
      </TabPaneSection>
    </TabPane>
  );
}

export default withRouter(Grade);
