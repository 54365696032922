import { withRouter } from "react-router-dom";

import AccordionPane from "../../../../../../../components/ubold/organisms/AccordionPane";

import CHDateTimePicker from "../../../../../../../components/ubold/molecules/forms/CHDateTimePicker";

import utils from "../../../../../../../utils";
import { sectionProps } from "@types";
import CHTextField from "components/ubold/molecules/forms/CHTextField";

function LegacyTenantInfo(props: sectionProps) {
  const { baseLink, accordionId, onSubmit, state, setState } = props;

  if (state["tenant_category"] !== 2) return null;

  return (
    <AccordionPane
      id="legacyTenantInfo"
      name="Legacy Tenant Info"
      accordionId={accordionId}
      baseLink={baseLink}
      onSubmit={onSubmit}
      shown={
        !!(
          utils.form.isFieldError(state, "checkin_to_landlord") ||
          utils.form.isFieldError(state, "price_from_landlord") ||
          utils.form.isFieldError(state, "last_payment_to_landlord")
        )
      }
      state={state}
      setState={setState}
    >
      <CHDateTimePicker
        isRequired
        name="checkin_to_landlord"
        readOnly
        title="Checkin to Landlord"
        type="date"
      />

      <CHTextField
        isRequired
        min={0}
        name="price_from_landlord"
        readOnly
        title="Landlord Price"
        type="number"
      />

      <CHDateTimePicker
        isRequired
        name="last_payment_to_landlord"
        readOnly
        title="Last Payment to Landlord"
        type="date"
      />
    </AccordionPane>
  );
}

export default withRouter(LegacyTenantInfo);
