import {withRouter} from "react-router-dom";

import AccordionPane from "../../../../../../../components/ubold/organisms/AccordionPane";

import CHTextView from "../../../../../../../components/ubold/molecules/forms/CHTextView";

import utils from "../../../../../../../utils";

function OtherInfos(props) {
    const {baseLink, accordionId, onSubmit, state, setState} = props;

    return (
        <AccordionPane
            id='information'
            name='Information'
            accordionId={accordionId}
            baseLink={baseLink}
            onSubmit={onSubmit}
            state={state}
            setState={setState}>
            <CHTextView
                name='date_created'
                title='Date Created'
                renderer={value => {
                    return utils.formatter.dateTime(value)
                }}
            />
            <CHTextView
                name='date_updated'
                title='Date Updated'
                renderer={value => {
                    return utils.formatter.dateTime(value)
                }}
            />
            <CHTextView
                name='created_by'
                title='Created By'
            />
        </AccordionPane>
    );
}

export default withRouter(OtherInfos);