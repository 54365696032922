import styled from "styled-components";

const PageRoomDC = styled.div`
  .content-page {
    overflow: unset;
  }

  #wrapper {
    overflow: unset;
  }
`;

export default PageRoomDC;
