import configBuilder from "../../../../components/ubold/ConfigBuilder";

import pageDefinitions from "../definitions";

import utils from "../../../../utils";

const definitions = {
  ...pageDefinitions,
  tabs: [
    {
      id: "place_group",
      name: "PlaceGroup",
    },
    {
      id: "t_photos",
      name: "Photos",
    },
  ],
  validators: {
    name: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    slug: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    header_photo_original: (value: string) => {
      return utils.validator.validateRequired(value);
    },
  },
};

export default configBuilder.buildAddEditPageConfig(definitions, [
  "header_photo_original",
]);
