import { withRouter } from "react-router-dom";

import { useEffect } from "react";

import CTextField from "../../atoms/forms/CTextField";
import CSelect from "../../atoms/forms/CSelect";
import CAsyncSelect from "../../atoms/forms/CAsyncSelect";
import CTextArea from "../../atoms/forms/CTextArea";
import CDateTimePicker from "../../atoms/forms/CDateTimePicker";
import CImageUpload from "../../atoms/forms/CImageUpload";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

import { isMobile } from "react-device-detect";

import loading from "../../../../assets/loading.gif";

import moment from "moment";

import useGlobalState from "../../../../state";

import utils from "../../../../utils";
import configs from "../../../../configs";
import CCheckBox from "components/ubold/atoms/forms/CCheckBox";
import CDualistBoxV2 from "components/ubold/atoms/forms/CDualistBoxV2";

function COneOrManyToManyFieldV2(props) {
  const {
    id,
    state,
    setState,
    saveOnlyFromMainForm,
    restAccessCode,
    definitions,
    maxRow,
    restAccessCodeNamespace,
    name,
    restPath,
    photoFields = [],
    excludedFieldsOnSubmit = [],
    readOnly,
    relationKey,
    columns,
    disableActionBtn = false,
    dependsTabName = "",
    rowIdentifier = "",
    useNewClone = false,
    additionalStateInAddNewRow = null,
    afterDeleteRow,
    mainTab,
    isSequenceEnabled = false,
    sequenceTitle = null,
    customHandleDeleteRow = null,
  } = props;

  const [user] = useGlobalState("user");
  const isSuperUser = user ? user.is_super_user : false;

  const [accesses] = useGlobalState("accesses");
  const [fieldAccesses] = useGlobalState("field_accesses");
  const [fieldAccessReversePolicy] = useGlobalState(
    "field_access_reverse_policy"
  );

  const paramRestAccessCode = `rest_access_code=${restAccessCode}`;
  const paramObjectId = `object_id=${id}`;

  const getDataIndex = (col, index, name) => {
    const result = col?.dataList?.[index]?.[name];
    return result;
  };

  const getFieldValue = (col, index, name) => {
    const flag = col?.dataList[index]?.flag;
    const result =
      flag !== "New" ? col?.dataList[index]?.[name] : null;
    return result;
  };

  const handleOnClickSequenceButton = (params) => {
    const { index, type } = params;
    const targetIndex = type === "down" ? index + 1 : index - 1;
    if (
      // safeguard if there is a bug that suddenly allowed user to move data to targetIndex that out of bounds **0 < index < state[name].length**
      (type === "up" && targetIndex < 0) ||
      (type === "down" && targetIndex > state[name]?.length - 1)
    ) {
      return;
    }
    const cloneFieldState = [...state[name]];
    const targetedItem = cloneFieldState[index];
    cloneFieldState.splice(index, 1);
    cloneFieldState.splice(targetIndex, 0, targetedItem);
    const mappedFieldState = cloneFieldState.map((state, index) => ({
      ...state,
      sequence: index + 1,
    }));
    setState((prevState) => ({
      ...prevState,
      [name]: mappedFieldState,
    }));
  };

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      [name + "Columns"]: columns,
    }));
  }, [state[name]]);

  return (
    <div
      className="row"
      style={{
        width: "100%",
        marginTop: "1px",
        marginLeft: isMobile ? "0px" : "-12px",
        marginRight: isMobile ? "0px" : "-12px",
        paddingLeft: mainTab ? "20px" : "0",
        paddingTop: mainTab ? "10px" : "0",
      }}
    >
      <div className="col-12 px-0 px-md-2">
        <div className="card" style={{ marginBottom: "0px" }}>
          {state[name + "ErrorMessage"] ? (
            <div
              className="alert alert-danger alert-dismissible fade show mb-0"
              style={{ borderRadius: "0px" }}
              role="alert"
            >
              <button
                onClick={() =>
                  setState((prevState) => ({
                    ...prevState,
                    [name + "ErrorMessage"]: null,
                  }))
                }
                type="button"
                className="close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div
                dangerouslySetInnerHTML={{
                  __html: state[name + "ErrorMessage"],
                }}
              />
            </div>
          ) : (
            <></>
          )}
          {state[name + "HasBeenSaved"] &&
          state[name + "InfoMessage"] ? (
            <div
              className="alert alert-info alert-dismissible fade show mb-0"
              role="alert"
              style={{ borderRadius: "0px" }}
            >
              <button
                onClick={() =>
                  setState((prevState) => ({
                    ...prevState,
                    [name + "InfoMessage"]: null,
                  }))
                }
                type="button"
                className="close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <span
                dangerouslySetInnerHTML={{
                  __html: state[name + "InfoMessage"],
                }}
              />
            </div>
          ) : (
            <></>
          )}
          <div
            className="card-body p-0"
            style={{ overflowX: "auto" }}
          >
            <div
              className="table-responsive"
              style={{
                height: "max-content",
                width: "max-content",
                minWidth: "100%",
              }}
            >
              <table
                className="table table-nowrap mb-0"
                style={{ minWidth: "100%" }}
              >
                <thead className="thead-light">
                  <tr>
                    {!readOnly ? (
                      <th
                        className="border-0"
                        style={{
                          width: "1%",
                          padding: "10px",
                          textAlign: "center",
                        }}
                      >
                        Action
                      </th>
                    ) : (
                      <></>
                    )}
                    {!isSequenceEnabled ? (
                      <></>
                    ) : (
                      <th
                        className="border-0"
                        style={{
                          width: "1%",
                          padding: "10px",
                          textAlign: "center",
                        }}
                      >
                        {sequenceTitle ?? "Sequence"}
                      </th>
                    )}
                    {columns.map((value, index) => {
                      return !value.isRequired &&
                        restAccessCode &&
                        utils.access.isFieldHidden(
                          fieldAccesses,
                          restAccessCode +
                            "." +
                            restAccessCodeNamespace +
                            "." +
                            value.name
                        ) ? (
                        <></>
                      ) : (
                        <th
                          className="border-0"
                          key={"columns" + index}
                          style={{
                            padding: "10px",
                            width: value.width
                              ? value.width + "px"
                              : "250px",
                            textAlign: value.center
                              ? "center"
                              : "left",
                          }}
                        >
                          <span
                            title={
                              value.isRequired
                                ? "This field is required"
                                : "Optional field"
                            }
                            style={{
                              borderBottom: value.isRequired
                                ? "1px dashed #666"
                                : "none",
                            }}
                          >
                            {value["title"]}
                          </span>
                          {value.isRequired ? "*" : ""}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {state[name]?.map((row, index) => {
                    const hasDependByFlag = state[dependsTabName];
                    let isDisableAction = false;
                    if (hasDependByFlag) {
                      /** usage: Enable and disable Action button dynamicly
                       *  pre condition: define dependsTabName inside component declaration on respective page
                       */
                      const flagStatus =
                        hasDependByFlag &&
                        hasDependByFlag[index]?.flag;

                      isDisableAction =
                        flagStatus === "New" ? false : true;
                    }

                    return (
                      <tr
                        key={"row" + index}
                        style={{
                          backgroundColor:
                            row.flag === "Deleted"
                              ? "#e30517"
                              : "#ffffff",
                        }}
                      >
                        {!readOnly ? (
                          <td
                            className="border-0"
                            style={{
                              padding: "10px",
                              textAlign: "center",
                            }}
                          >
                            {row.flag !== "Deleted" &&
                            ((user && user["is_super_user"]) ||
                              (user &&
                                user["has_team_management_access"] &&
                                definitions &&
                                definitions.isTeamMenu) ||
                              !restAccessCodeNamespace ||
                              (accesses &&
                                accesses.includes(
                                  restAccessCode +
                                    "." +
                                    restAccessCodeNamespace +
                                    ".destroy"
                                ))) ? (
                              <button
                                title="Delete row"
                                disabled={
                                  disableActionBtn || isDisableAction
                                }
                                style={{ marginRight: "5px" }}
                                type="button"
                                className="btn btn-danger waves-effect waves-light btn-xs"
                                onClick={
                                  utils.commons.isFunction(
                                    customHandleDeleteRow
                                  )
                                    ? () =>
                                        customHandleDeleteRow(index)
                                    : () => {
                                        let dataCloned = JSON.parse(
                                          JSON.stringify(state[name])
                                        );

                                        if (row.flag === "New") {
                                          delete dataCloned.splice(
                                            index,
                                            1
                                          );
                                        } else {
                                          dataCloned[index].flag =
                                            "Deleted";
                                        }

                                        setState((prevState) => ({
                                          ...prevState,
                                          [name]: dataCloned,
                                        }));
                                        if (
                                          utils.commons.isFunction(
                                            afterDeleteRow
                                          )
                                        ) {
                                          afterDeleteRow({ index });
                                        }
                                      }
                                }
                              >
                                <i className="mdi mdi-close" />
                              </button>
                            ) : (user && user["is_super_user"]) ||
                              !restAccessCodeNamespace ||
                              (accesses &&
                                accesses.includes(
                                  restAccessCode +
                                    "." +
                                    restAccessCodeNamespace +
                                    ".destroy"
                                )) ? (
                              <button
                                title="Undo deletion"
                                style={{ marginRight: "5px" }}
                                type="button"
                                className="btn btn-warning waves-effect waves-light btn-xs"
                                onClick={() => {
                                  let dataCloned = JSON.parse(
                                    JSON.stringify(state[name])
                                  );

                                  dataCloned[index].flag = "Editing";

                                  setState((prevState) => ({
                                    ...prevState,
                                    [name]: dataCloned,
                                  }));
                                }}
                              >
                                <i className="fas fa-undo" />
                              </button>
                            ) : (
                              <></>
                            )}
                            <br />
                            <span
                              style={{
                                fontSize: "10px",
                                color:
                                  row.flag === "Deleted"
                                    ? "#ffffff"
                                    : "inherit",
                              }}
                            >
                              Status: <b>{row.flag}</b>
                            </span>
                          </td>
                        ) : (
                          <></>
                        )}
                        {!isSequenceEnabled ? (
                          <></>
                        ) : (
                          <td
                            className="border-0"
                            style={{
                              padding: "10px",
                              textAlign: "center",
                            }}
                          >
                            <div
                              className="row"
                              style={{
                                display: "flex",
                                justifyContent: "space-evenly",
                              }}
                            >
                              <button
                                className="btn btn-success waves-effect waves-light btn-xs"
                                onClick={(event) => {
                                  event.preventDefault();
                                  handleOnClickSequenceButton({
                                    index,
                                    type: "down",
                                  });
                                }}
                                disabled={
                                  index === state[name]?.length - 1
                                }
                              >
                                <i className="mdi mdi-chevron-down" />
                              </button>
                              <button
                                className="btn btn-success waves-effect waves-light btn-xs"
                                onClick={(event) => {
                                  event.preventDefault();
                                  handleOnClickSequenceButton({
                                    index,
                                    type: "up",
                                  });
                                }}
                                disabled={!index}
                              >
                                <i className="mdi mdi-chevron-up" />
                              </button>
                            </div>
                          </td>
                        )}
                        {columns.map((column, j) => {
                          let isEditing =
                            state[name][index]["flag"] === "Editing";

                          let isReadOnly =
                            readOnly ??
                            ((isEditing ||
                              (!isEditing && !column.isRequired)) &&
                              restAccessCode &&
                              utils.access.isFieldReadOnly(
                                fieldAccesses,
                                `${restAccessCode}.${restAccessCodeNamespace}.${column.name}`,
                                fieldAccessReversePolicy,
                                isSuperUser
                              ));

                          if (
                            accesses &&
                            !accesses.includes(
                              restAccessCode +
                                "." +
                                restAccessCodeNamespace +
                                ".update"
                            )
                          ) {
                            isReadOnly =
                              isEditing && !user?.["is_super_user"];
                          }

                          if (
                            user &&
                            user["has_team_management_access"] &&
                            definitions &&
                            definitions.isTeamMenu
                          ) {
                            isReadOnly = false;
                          }

                          if (column?.isReadOnly) isReadOnly = true;

                          if (column.enableReadOnlyLabel) {
                            return (
                              <td
                                key={"cell_" + j}
                                className="border-0"
                                style={{
                                  padding: "10px",
                                  width: column.width
                                    ? column.width + "px"
                                    : "250px",
                                  textAlign: column.center
                                    ? "center"
                                    : "left",
                                }}
                              >
                                <div>
                                  {state[name][index][column.name]}
                                </div>
                                <div
                                  style={{
                                    marginTop: "5px",
                                    fontSize: "12px",
                                    color: "#aaa",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontStyle: "italic",
                                    }}
                                  >
                                    Read Only
                                  </span>
                                </div>
                              </td>
                            );
                          } else if (
                            column.type === "text" ||
                            column.type === "number" ||
                            column.type === "email"
                          ) {
                            let customReadOnly;

                            if (column.dynamicReadOnly && id) {
                              const catg = getFieldValue(
                                column,
                                index,
                                "category"
                              );
                              const subCatg = getFieldValue(
                                column,
                                index,
                                "sub_category"
                              );
                              const flag =
                                column.dataList[index]?.flag;
                              if (
                                flag !== "New" &&
                                column.name === "item"
                              ) {
                                customReadOnly = true;
                              }
                              if (
                                flag !== "New" &&
                                column.releaseStatus &&
                                column.invoiceStatus &&
                                column.name === "price"
                              ) {
                                if (catg !== 2) {
                                  customReadOnly = true;
                                }
                                if (catg === 2 && subCatg !== 5) {
                                  customReadOnly = true;
                                }
                                if (
                                  catg === 2 &&
                                  subCatg === 5 &&
                                  (state[column.releaseStatus] ===
                                    3 ||
                                    state[column.invoiceStatus] !== 1)
                                ) {
                                  customReadOnly = true;
                                }
                              }
                            }

                            const stateTabId = state[column?.tabId];
                            const dependKeyHasValue = stateTabId
                              ? stateTabId[index][column?.dependKey]
                              : null;

                            if (dependKeyHasValue) isReadOnly = true;

                            return !column.isRequired &&
                              restAccessCode &&
                              utils.access.isFieldHidden(
                                fieldAccesses,
                                restAccessCode +
                                  "." +
                                  restAccessCodeNamespace +
                                  "." +
                                  column.name
                              ) ? (
                              <></>
                            ) : (
                              <td
                                key={"cell_" + j}
                                className="border-0"
                                style={{
                                  padding: "10px",
                                  width: column.width
                                    ? column.width + "px"
                                    : "250px",
                                  textAlign: column.center
                                    ? "center"
                                    : "left",
                                }}
                              >
                                {!column.dynamicTypeField ? (
                                  <>
                                    <CTextField
                                      type={column.type}
                                      name={column.name}
                                      state={state[name][index]}
                                      defaultValue={column.default}
                                      readOnly={
                                        isReadOnly ||
                                        customReadOnly ||
                                        column.readOnly
                                      }
                                      isParkingAddon={
                                        column?.isParkingAddon
                                      }
                                      maxLength={
                                        column.maxLength
                                          ? column.maxLength
                                          : 255
                                      }
                                      min={column.min}
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        const clonedState = {
                                          ...state,
                                        };
                                        const clonedFieldState = [
                                          ...clonedState[name],
                                        ].map((object, i) => {
                                          if (i === index) {
                                            let targetObject = {
                                              ...object,
                                              [column.name]: value,
                                            };
                                            return targetObject;
                                          } else return object;
                                        });
                                        setState({
                                          ...clonedState,
                                          [name]: clonedFieldState,
                                        });
                                        if (
                                          utils.commons.isFunction(
                                            column.afterChange
                                          )
                                        ) {
                                          column.afterChange({
                                            index,
                                            data: clonedFieldState,
                                            name,
                                            state,
                                          });
                                        }
                                      }}
                                    />
                                    {column.useCurrencyHelper ? (
                                      <div
                                        style={{
                                          marginTop: "5px",
                                          fontSize: "13px",
                                          color: "#aaa",
                                        }}
                                      >
                                        <span>
                                          Amount:{" "}
                                          {utils.formatter.currency(
                                            state[name][index][
                                              column.name
                                            ]
                                          )}
                                        </span>
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                ) : state[name][index].id ? (
                                  <>
                                    {state[name][index].category ===
                                      1 && "(-) "}
                                    {utils.formatter.currency(
                                      state[name][index][column.name]
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <CTextField
                                      type={column.type}
                                      name={column.name}
                                      state={state[name][index]}
                                      defaultValue={column.default}
                                      readOnly={
                                        isReadOnly ||
                                        customReadOnly ||
                                        column.readOnly
                                      }
                                      isParkingAddon={
                                        column?.isParkingAddon
                                      }
                                      maxLength={
                                        column.maxLength
                                          ? column.maxLength
                                          : 255
                                      }
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        const clonedState = {
                                          ...state,
                                        };
                                        const clonedFieldState = [
                                          ...clonedState[name],
                                        ].map((object, i) => {
                                          if (i === index) {
                                            let targetObject = {
                                              ...object,
                                              [column.name]: value,
                                            };
                                            return targetObject;
                                          } else return object;
                                        });
                                        setState({
                                          ...clonedState,
                                          [name]: clonedFieldState,
                                        });
                                        if (
                                          utils.commons.isFunction(
                                            column.afterChange
                                          )
                                        ) {
                                          column.afterChange({
                                            index,
                                            data: clonedFieldState,
                                            name,
                                            state,
                                          });
                                        }
                                      }}
                                    />
                                    <div
                                      style={{
                                        marginTop: "5px",
                                        fontSize: "13px",
                                        color: "#aaa",
                                      }}
                                    >
                                      <span>
                                        Amount:{" "}
                                        {utils.formatter.currency(
                                          state[name][index][
                                            column.name
                                          ]
                                        )}
                                      </span>
                                    </div>
                                  </>
                                )}

                                {isReadOnly ? (
                                  <div
                                    style={{
                                      marginTop: "5px",
                                      fontSize: "12px",
                                      color: "#aaa",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontStyle: "italic",
                                      }}
                                    >
                                      Read Only
                                    </span>
                                  </div>
                                ) : (
                                  <></>
                                )}
                                {state[name][index][
                                  column.name + "Error"
                                ] ? (
                                  <span style={{ color: "red" }}>
                                    {
                                      state[name][index][
                                        column.name + "Error"
                                      ]
                                    }
                                  </span>
                                ) : (
                                  <></>
                                )}
                              </td>
                            );
                          } else if (column.type === "select") {
                            const flag =
                              column?.dataList?.[index]?.flag;
                            if (
                              id &&
                              flag === "New" &&
                              column.defaultVal
                            ) {
                              if (column.name === "category") {
                                state[name][index][column.name] =
                                  column.defaultVal;
                              } else if (
                                column.name === "sub_category"
                              ) {
                                state[name][index][column.name] =
                                  column.defaultVal;
                              }
                            }
                            return !column.isRequired &&
                              restAccessCode &&
                              utils.access.isFieldHidden(
                                fieldAccesses,
                                restAccessCode +
                                  "." +
                                  restAccessCodeNamespace +
                                  "." +
                                  column.name
                              ) ? (
                              <></>
                            ) : (
                              <td
                                key={"cell_" + j}
                                className="border-0"
                                style={{
                                  padding: "10px",
                                  width: column.width
                                    ? column.width + "px"
                                    : "250px",
                                  textAlign: column.center
                                    ? "center"
                                    : "left",
                                }}
                              >
                                <CSelect
                                  name={column.name}
                                  state={state[name][index]}
                                  data={column.data}
                                  defaultValue={column.default}
                                  inclusionKey={column.inclusionKey}
                                  isClearable={column.isClearable}
                                  uniqueIdentifier={
                                    column.uniqueIdentifier
                                  }
                                  isUniqueOption={
                                    column.isUniqueOption
                                      ? column.isUniqueOption
                                      : false
                                  }
                                  readOnly={
                                    (utils.commons.isFunction(
                                      column.disabledAtEdit
                                    ) &&
                                      column.disabledAtEdit(index)) ||
                                    column.readOnly
                                  }
                                  itemsExtractor={
                                    column.itemsExtractor
                                  }
                                  onChange={(dataValue) => {
                                    const tempState = [
                                      ...state[name],
                                    ].map((object, i) => {
                                      if (i === index) {
                                        return {
                                          ...object,
                                          [column.name]: dataValue
                                            ? dataValue.value
                                            : null,
                                        };
                                      } else {
                                        return object;
                                      }
                                    });
                                    if (
                                      column?.unallowManualSelection?.includes(
                                        dataValue.value
                                      )
                                    ) {
                                      const sweetAlert =
                                        withReactContent(Swal);
                                      sweetAlert.fire(
                                        "Error",
                                        "Manual Selection is not allowed",
                                        "error"
                                      );
                                      return;
                                    }
                                    setState((prevState) => ({
                                      ...prevState,
                                      [name]: tempState,
                                    }));
                                    if (
                                      utils.commons.isFunction(
                                        column.afterChange
                                      )
                                    ) {
                                      column.afterChange({
                                        dataValue,
                                        index,
                                        tempState,
                                      });
                                    }
                                  }}
                                />
                                {isReadOnly ? (
                                  <div
                                    style={{
                                      marginTop: "5px",
                                      fontSize: "12px",
                                      color: "#aaa",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontStyle: "italic",
                                      }}
                                    >
                                      Read Only
                                    </span>
                                  </div>
                                ) : (
                                  <></>
                                )}
                                {state[name][index][
                                  column.name + "Error"
                                ] ? (
                                  <span style={{ color: "red" }}>
                                    {
                                      state[name][index][
                                        column.name + "Error"
                                      ]
                                    }
                                  </span>
                                ) : (
                                  <></>
                                )}
                              </td>
                            );
                          } else if (column.type === "async_select") {
                            return !column.isRequired &&
                              restAccessCode &&
                              utils.access.isFieldHidden(
                                fieldAccesses,
                                restAccessCode +
                                  "." +
                                  restAccessCodeNamespace +
                                  "." +
                                  column.name
                              ) ? (
                              <></>
                            ) : (
                              <td
                                key={"cell_" + j}
                                className="border-0"
                                style={{
                                  padding: "10px",
                                  width: column.width
                                    ? column.width + "px"
                                    : "250px",
                                  textAlign: column.center
                                    ? "center"
                                    : "left",
                                  display: column.hideColumnByCSS
                                    ? "none"
                                    : undefined,
                                }}
                              >
                                <CAsyncSelect
                                  name={column.name}
                                  state={state[name][index]}
                                  stateComponent={state[name]}
                                  defaultValue={column.default}
                                  inclusionKey={column.inclusionKey}
                                  uniqueIdentifier={
                                    column.uniqueIdentifier
                                  }
                                  dependsOn={column.dependsOn}
                                  dependsOnPrefix={
                                    column.dependsOnPrefix
                                  }
                                  isUniqueOption={
                                    column.isUniqueOption
                                      ? column.isUniqueOption
                                      : false
                                  }
                                  row={index}
                                  data={column.data}
                                  isClearable={column.isClearable}
                                  readOnly={isReadOnly}
                                  isDisabled={
                                    column.disabledAtEdit &&
                                    (state[name][index]["flag"] ===
                                      "Editing" ||
                                      state[name][index]["flag"] ===
                                        "Deleted")
                                      ? true
                                      : column.isDisabled
                                      ? column.isDisabled
                                      : false
                                  }
                                  itemsExtractor={
                                    column.itemsExtractor
                                  }
                                  setState={setState}
                                  onChange={(dataValue) => {
                                    const tempState = [
                                      ...state[name],
                                    ].map((object, i) => {
                                      if (i === index) {
                                        return {
                                          ...object,
                                          [column.name]: dataValue
                                            ? dataValue.value
                                            : null,
                                        };
                                      } else {
                                        return object;
                                      }
                                    });
                                    setState((prevState) => ({
                                      ...prevState,
                                      [name]: tempState,
                                    }));
                                    if (
                                      utils.commons.isFunction(
                                        column.afterChange
                                      )
                                    ) {
                                      column.afterChange({
                                        dataValue,
                                        index,
                                        tempState,
                                      });
                                    }
                                  }}
                                />
                                {state[column.requireField] && (
                                  <div
                                    style={{
                                      marginTop: "5px",
                                      fontSize: "12px",
                                      color: "#aaa",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontStyle: "italic",
                                      }}
                                    >
                                      {column.requireField} required
                                    </span>
                                  </div>
                                )}
                                {isReadOnly ? (
                                  <div
                                    style={{
                                      marginTop: "5px",
                                      fontSize: "12px",
                                      color: "#aaa",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontStyle: "italic",
                                      }}
                                    >
                                      Read Only
                                    </span>
                                  </div>
                                ) : (
                                  <></>
                                )}
                                {state[name][index][
                                  column.name + "Error"
                                ] ? (
                                  <span style={{ color: "red" }}>
                                    {
                                      state[name][index][
                                        column.name + "Error"
                                      ]
                                    }
                                  </span>
                                ) : (
                                  <></>
                                )}
                              </td>
                            );
                          } else if (
                            column.type === "datetime" ||
                            column.type === "date" ||
                            column.type === "time"
                          ) {
                            let customMinDate = "";
                            let customReadOnly;

                            if (column.dynamicMinDate) {
                              const startDate = getDataIndex(
                                column,
                                index,
                                "start_date"
                              );
                              if (column.plusOne) {
                                customMinDate = moment(startDate)
                                  .add(1, "day")
                                  .format("YYYY-MM-DD");
                              } else {
                                customMinDate =
                                  moment(startDate).format(
                                    "YYYY-MM-DD"
                                  );
                              }
                            }
                            const flag =
                              column.dataList?.[index]?.flag;

                            if (column.dynamicValue && id) {
                              const startDateFirstIndex =
                                state[name][0]["start_date"];
                              const endDateFirstIndex =
                                state[name][0]["end_date"];
                              if (
                                flag === "New" &&
                                column.name === "start_date"
                              ) {
                                state[name][index][column.name] =
                                  moment(startDateFirstIndex).format(
                                    "YYYY-MM-DD"
                                  );
                              } else if (
                                flag === "New" &&
                                column.name === "end_date"
                              ) {
                                state[name][index][column.name] =
                                  moment(endDateFirstIndex).format(
                                    "YYYY-MM-DD"
                                  );
                              }
                            }

                            if (column.dynamicReadOnly) {
                              if (
                                id &&
                                flag === "New" &&
                                column.name === "end_date"
                              ) {
                                const startDate = getDataIndex(
                                  column,
                                  index,
                                  "start_date"
                                );
                                customReadOnly = !startDate && true;
                              } else if (id && flag !== "New") {
                                customReadOnly = true;
                              }
                            }

                            return !column.isRequired &&
                              restAccessCode &&
                              utils.access.isFieldHidden(
                                fieldAccesses,
                                restAccessCode +
                                  "." +
                                  restAccessCodeNamespace +
                                  "." +
                                  column.name
                              ) ? (
                              <></>
                            ) : (
                              <td
                                key={"cell_" + j}
                                className="border-0"
                                style={{
                                  padding: "10px",
                                  width: column.width
                                    ? column.width + "px"
                                    : "250px",
                                  textAlign: column.center
                                    ? "center"
                                    : "left",
                                }}
                              >
                                <CDateTimePicker
                                  name={column.name}
                                  state={state[name][index]}
                                  data={column.data}
                                  defaultDate={column.default}
                                  readOnly={
                                    isReadOnly ||
                                    customReadOnly ||
                                    column.readOnly ||
                                    state[name][index][
                                      column.readOnlyDependency
                                    ]
                                  }
                                  type={column.type}
                                  monthSelect={column.monthSelect}
                                  minDate={
                                    column.dynamicMinDate
                                      ? customMinDate
                                      : column.dateRanges
                                      ? column.dateRanges
                                          ?.availableRange?.[index]
                                          ?.start_date
                                      : column.minDate
                                  }
                                  maxDate={
                                    column.dateRanges
                                      ? column.dateRanges
                                          ?.availableRange?.[index]
                                          ?.end_date
                                      : column.maxDate
                                  }
                                  isDisabled={
                                    column.disabledAtEdit &&
                                    (state[name][index]["flag"] ===
                                      "Editing" ||
                                      state[name][index]["flag"] ===
                                        "Deleted")
                                      ? true
                                      : column.isDisabled
                                      ? column.isDisabled
                                      : false
                                  }
                                  disabledDate={
                                    column.dateRanges?.selectedDate
                                  }
                                  onClear={() => {
                                    setState((prevState) => ({
                                      ...prevState,
                                      [name]: [...state[name]].map(
                                        (object, i) => {
                                          if (i === index) {
                                            return {
                                              ...object,
                                              [column.name]: null,
                                            };
                                          } else {
                                            return object;
                                          }
                                        }
                                      ),
                                    }));
                                  }}
                                  onChange={(dataValue) => {
                                    let result = moment(
                                      dataValue[0].toISOString()
                                    ).format("YYYY-MM-DD HH:mm");

                                    if (column.type === "date") {
                                      result = moment(
                                        dataValue[0].toISOString()
                                      ).format("YYYY-MM-DD");
                                    } else if (
                                      column.type === "time"
                                    ) {
                                      result = moment(
                                        dataValue[0].toISOString()
                                      ).format("HH:mm:ss");
                                    }

                                    setState((prevState) => ({
                                      ...prevState,
                                      [name]: [...state[name]].map(
                                        (object, i) => {
                                          if (i === index) {
                                            return {
                                              ...object,
                                              [column.name]: result
                                                ? result
                                                : null,
                                            };
                                          } else {
                                            return object;
                                          }
                                        }
                                      ),
                                    }));
                                  }}
                                />
                                {!id &&
                                column.requireField &&
                                customReadOnly ? (
                                  <div
                                    style={{
                                      marginTop: "5px",
                                      fontSize: "12px",
                                      color: "#aaa",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontStyle: "italic",
                                      }}
                                    >
                                      {column.requireField} required
                                    </span>
                                  </div>
                                ) : (
                                  <></>
                                )}
                                {isReadOnly ? (
                                  <div
                                    style={{
                                      marginTop: "5px",
                                      fontSize: "12px",
                                      color: "#aaa",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontStyle: "italic",
                                      }}
                                    >
                                      Read Only
                                    </span>
                                  </div>
                                ) : (
                                  <></>
                                )}
                                {state[name][index][
                                  column.name + "Error"
                                ] ? (
                                  <span style={{ color: "red" }}>
                                    {
                                      state[name][index][
                                        column.name + "Error"
                                      ]
                                    }
                                  </span>
                                ) : (
                                  <></>
                                )}
                              </td>
                            );
                          } else if (column.type === "textarea") {
                            return !column.isRequired &&
                              restAccessCode &&
                              utils.access.isFieldHidden(
                                fieldAccesses,
                                restAccessCode +
                                  "." +
                                  restAccessCodeNamespace +
                                  "." +
                                  column.name
                              ) ? (
                              <></>
                            ) : (
                              <td
                                key={"cell_" + j}
                                className="border-0"
                                style={{
                                  padding: "10px",
                                  width: column.width
                                    ? column.width + "px"
                                    : "250px",
                                  textAlign: column.center
                                    ? "center"
                                    : "left",
                                }}
                              >
                                <CTextArea
                                  name={column.name}
                                  state={state[name][index]}
                                  readOnly={column.readOnly}
                                  maxLength={
                                    column.maxLength
                                      ? column.maxLength
                                      : 255
                                  }
                                  onChange={(e) => {
                                    const value = e.target.value;

                                    setState((prevState) => ({
                                      ...prevState,
                                      [name]: [...state[name]].map(
                                        (object, i) => {
                                          if (i === index) {
                                            return {
                                              ...object,
                                              [column.name]: value,
                                            };
                                          } else {
                                            return object;
                                          }
                                        }
                                      ),
                                    }));
                                  }}
                                />
                                {isReadOnly ? (
                                  <div
                                    style={{
                                      marginTop: "5px",
                                      fontSize: "12px",
                                      color: "#aaa",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontStyle: "italic",
                                      }}
                                    >
                                      Read Only
                                    </span>
                                  </div>
                                ) : (
                                  <></>
                                )}
                                {state[name][index][
                                  column.name + "Error"
                                ] ? (
                                  <span style={{ color: "red" }}>
                                    {
                                      state[name][index][
                                        column.name + "Error"
                                      ]
                                    }
                                  </span>
                                ) : (
                                  <></>
                                )}
                              </td>
                            );
                          } else if (column.type === "photo_upload") {
                            const stateTabId = state[column?.tabId];
                            const dependKeyHasValue = stateTabId
                              ? stateTabId[index][column?.dependKey]
                              : null;
                            if (dependKeyHasValue) isReadOnly = true;

                            return !column.isRequired &&
                              restAccessCode &&
                              utils.access.isFieldHidden(
                                fieldAccesses,
                                restAccessCode +
                                  "." +
                                  restAccessCodeNamespace +
                                  "." +
                                  column.name
                              ) ? (
                              <></>
                            ) : (
                              <td
                                valign="top"
                                key={"cell_" + j}
                                className="border-0"
                                style={{
                                  padding: "10px",
                                  width: column.width
                                    ? column.width + "px"
                                    : "250px",
                                  textAlign: column.center
                                    ? "center"
                                    : "left",
                                  display: column.hideColumnByCSS
                                    ? "none"
                                    : undefined,
                                }}
                              >
                                <CImageUpload
                                  name={column.name}
                                  state={state[name][index]}
                                  readOnly={
                                    isReadOnly ||
                                    (column.disabledAtEdit &&
                                      (state[name][index]["flag"] ===
                                        "Editing" ||
                                        state[name][index]["flag"] ===
                                          "Deleted"))
                                  }
                                  isPdf={column.isPdf}
                                  alertFileSize={column.alertFileSize}
                                  alertAllowedExtension={
                                    column.alertAllowedExtension
                                  }
                                  maxSize={column.maxSize}
                                  onChange={(acceptedFiles) => {
                                    if (!column.useURL) {
                                      utils.form.getBase64(
                                        acceptedFiles,
                                        1,
                                        column.name,
                                        state[name],
                                        setState,
                                        (base64) => {
                                          setState((prevState) => ({
                                            ...prevState,
                                            [name]: [
                                              ...state[name],
                                            ].map((object, i) => {
                                              if (i === index) {
                                                return {
                                                  ...object,
                                                  [column.name]:
                                                    base64,
                                                };
                                              } else {
                                                return object;
                                              }
                                            }),
                                          }));
                                        },
                                        name
                                      );
                                    } else {
                                      const formData = new FormData();
                                      formData.append(
                                        "file",
                                        acceptedFiles?.[0]
                                      );
                                      formData.append(
                                        "upload_path",
                                        column.uploadPath
                                      );

                                      utils.httpClient.postMultiPart(
                                        configs.apiUrl +
                                          column.restPath +
                                          "/",
                                        formData,
                                        column.contentType,
                                        (result) => {
                                          setState((prevState) => ({
                                            ...prevState,
                                            [name]: [
                                              ...state[name],
                                            ].map((object, i) => {
                                              if (i === index) {
                                                return {
                                                  ...object,
                                                  [column.name]:
                                                    result.url,
                                                };
                                              } else {
                                                return object;
                                              }
                                            }),
                                          }));
                                        },
                                        (error, message) => {
                                          alert(error);
                                        }
                                      );
                                    }
                                  }}
                                />
                                {isReadOnly ? (
                                  <div
                                    style={{
                                      marginTop: "5px",
                                      fontSize: "12px",
                                      color: "#aaa",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontStyle: "italic",
                                      }}
                                    >
                                      Read Only
                                    </span>
                                  </div>
                                ) : (
                                  <></>
                                )}
                                {state[name][index][
                                  column.name + "Error"
                                ] ? (
                                  <span style={{ color: "red" }}>
                                    {
                                      state[name][index][
                                        column.name + "Error"
                                      ]
                                    }
                                  </span>
                                ) : (
                                  <></>
                                )}
                              </td>
                            );
                          } else if (column.type === "read_only") {
                            return !column.isRequired &&
                              restAccessCode &&
                              utils.access.isFieldHidden(
                                fieldAccesses,
                                restAccessCode +
                                  "." +
                                  restAccessCodeNamespace +
                                  "." +
                                  column.name
                              ) ? (
                              <></>
                            ) : (
                              <td
                                key={"cell_" + j}
                                className="border-0"
                                style={{
                                  padding: "10px",
                                  width: column.width
                                    ? column.width + "px"
                                    : "250px",
                                  textAlign: column.center
                                    ? "center"
                                    : "left",
                                }}
                              >
                                {state[name][index][column.name] !==
                                undefined
                                  ? utils.commons.isFunction(
                                      column.renderer
                                    )
                                    ? column.renderer(
                                        state[name][index][
                                          column.name
                                        ]
                                      )
                                    : state[name][index][column.name]
                                  : "---"}
                              </td>
                            );
                          } else if (column.type === "checkbox") {
                            /** add custom read only to disable component conditionally */
                            let customReadOnly;

                            if (column.dynamicReadOnly) {
                              const priceStatus = getDataIndex(
                                column,
                                index,
                                "status"
                              );

                              customReadOnly = priceStatus === 3;
                            }
                            if (column.dynamicValue) {
                              const flag =
                                column?.dataList?.[index]?.flag;
                              if (id && flag === "New") {
                                state[name][index][
                                  column.name
                                ] = true;
                              }
                            }
                            return !column.isRequired &&
                              restAccessCode &&
                              utils.access.isFieldHidden(
                                fieldAccesses,
                                restAccessCode +
                                  "." +
                                  restAccessCodeNamespace +
                                  "." +
                                  column.name
                              ) ? (
                              <></>
                            ) : (
                              <td
                                key={"cell_" + j}
                                className="border-0"
                                style={{
                                  padding: "10px",
                                  width: column.width
                                    ? column.width + "px"
                                    : "250px",
                                  textAlign: column.center
                                    ? "center"
                                    : "left",
                                  display: column.hideColumnByCSS
                                    ? "none"
                                    : undefined,
                                }}
                              >
                                <CCheckBox
                                  name={column.name}
                                  readOnly={
                                    isReadOnly ||
                                    column.isDisabled ||
                                    column.readOnly
                                  }
                                  disabled={
                                    isReadOnly ||
                                    column.readOnly ||
                                    customReadOnly ||
                                    column.isDisabled
                                  }
                                  state={state[name][index]}
                                  onChange={() => {
                                    setState((prevState) => ({
                                      ...prevState,
                                      [name]: [...state[name]].map(
                                        (object, i) => {
                                          if (i === index) {
                                            return {
                                              ...object,
                                              [column.name]:
                                                !state[name][index][
                                                  column.name
                                                ],
                                            };
                                          } else {
                                            return object;
                                          }
                                        }
                                      ),
                                    }));
                                  }}
                                />
                                {column.helperText && !isReadOnly ? (
                                  <div
                                    style={{
                                      marginTop: "5px",
                                      fontSize: "12px",
                                      color: "#aaa",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontStyle: "italic",
                                      }}
                                    >
                                      {column.helperText}
                                    </span>
                                  </div>
                                ) : (
                                  <></>
                                )}
                                {isReadOnly ? (
                                  <div
                                    style={{
                                      marginTop: "5px",
                                      fontSize: "12px",
                                      color: "#aaa",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontStyle: "italic",
                                      }}
                                    >
                                      Read Only
                                    </span>
                                  </div>
                                ) : (
                                  <></>
                                )}
                                {state[name][index][
                                  column.name + "Error"
                                ] ? (
                                  <span style={{ color: "red" }}>
                                    {
                                      state[name][index][
                                        column.name + "Error"
                                      ]
                                    }
                                  </span>
                                ) : (
                                  <></>
                                )}
                              </td>
                            );
                          } else if (column.type === "dual_list") {
                            return !column.isRequired &&
                              restAccessCode &&
                              utils.access.isFieldHidden(
                                fieldAccesses,
                                restAccessCode +
                                  "." +
                                  restAccessCodeNamespace +
                                  "." +
                                  column.name
                              ) ? (
                              <></>
                            ) : (
                              <td
                                key={"cell_" + j}
                                className="border-0"
                                style={{
                                  padding: "10px",
                                  width: column.width
                                    ? column.width + "px"
                                    : "250px",
                                  textAlign: column.center
                                    ? "center"
                                    : "left",
                                  display: column.hideColumnByCSS
                                    ? "none"
                                    : undefined,
                                }}
                              >
                                <CDualistBoxV2
                                  name={column.name}
                                  state={state[name][index]}
                                  data={column.data}
                                  dependsOn={column.dependsOn}
                                  dependsOnRelationKey={
                                    column.dependsOnRelationKey
                                  }
                                  sortByField={column.sortByField}
                                  hitApi={
                                    typeof column.hitApi ===
                                    "function"
                                      ? column.hitApi({ index })
                                      : column.hitApi
                                  }
                                  itemsExtractor={
                                    column.itemsExtractor
                                  }
                                  readOnly={
                                    (column.disabledAtEdit &&
                                      (state[name][index]["flag"] ===
                                        "Editing" ||
                                        state[name][index]["flag"] ===
                                          "Deleted")) ||
                                    isReadOnly
                                      ? true
                                      : column.isDisabled
                                      ? column.isDisabled
                                      : false
                                  }
                                  onChange={(dataValue) => {
                                    setState((prevState) => ({
                                      ...prevState,
                                      [name]: [...state[name]].map(
                                        (object, i) => {
                                          if (i === index) {
                                            return {
                                              ...object,
                                              [column.name]: dataValue
                                                ? dataValue
                                                : [],
                                            };
                                          } else {
                                            return object;
                                          }
                                        }
                                      ),
                                    }));
                                  }}
                                />
                                {isReadOnly ? (
                                  <div
                                    style={{
                                      marginTop: "5px",
                                      fontSize: "12px",
                                      color: "#aaa",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontStyle: "italic",
                                      }}
                                    >
                                      Read Only
                                    </span>
                                  </div>
                                ) : (
                                  <></>
                                )}
                                {state[name][index][
                                  column.name + "Error"
                                ] ? (
                                  <span style={{ color: "red" }}>
                                    {
                                      state[name][index][
                                        column.name + "Error"
                                      ]
                                    }
                                  </span>
                                ) : (
                                  <></>
                                )}
                              </td>
                            );
                          }
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          {!readOnly ? (
            <div
              style={{
                padding: "10px",
                display: !disableActionBtn ? "flex" : "none",
              }}
            >
              {!(
                maxRow &&
                state[name] &&
                state[name].length >= maxRow
              ) &&
              ((user && user["is_super_user"]) ||
                (user &&
                  user["has_team_management_access"] &&
                  definitions &&
                  definitions.isTeamMenu) ||
                !restAccessCodeNamespace ||
                (accesses &&
                  accesses.includes(
                    restAccessCode +
                      "." +
                      restAccessCodeNamespace +
                      ".create"
                  ))) ? (
                <button
                  type="button"
                  disabled={disableActionBtn}
                  className="btn btn-primary waves-effect waves-light"
                  onClick={() => {
                    let dataCloned = [];
                    if (!useNewClone) {
                      dataCloned = JSON.parse(
                        JSON.stringify(state[name])
                      );
                    } else {
                      dataCloned = [...state[name]];
                    }

                    let newRow = { flag: "New" };
                    if (
                      utils.commons.isObject(
                        additionalStateInAddNewRow
                      )
                    ) {
                      newRow = {
                        ...newRow,
                        ...additionalStateInAddNewRow,
                      };
                    }

                    for (let i = 0; i < columns.length; i++) {
                      if (columns[i].default) {
                        newRow[columns[i].name] = columns[i].default;
                      }
                    }

                    dataCloned.push(newRow);

                    setState((prevState) => ({
                      ...prevState,
                      [name]: dataCloned,
                    }));
                  }}
                >
                  <i className="fas fa-plus" /> Add New
                </button>
              ) : (
                <></>
              )}
              {id !== undefined &&
              ((user && user["is_super_user"]) ||
                !restAccessCodeNamespace ||
                (accesses &&
                  accesses.includes(
                    restAccessCode +
                      "." +
                      restAccessCodeNamespace +
                      ".update"
                  )) ||
                (user && user["is_super_user"]) ||
                !restAccessCodeNamespace ||
                (accesses &&
                  accesses.includes(
                    restAccessCode +
                      "." +
                      restAccessCodeNamespace +
                      ".create"
                  )) ||
                (user && user["is_super_user"]) ||
                !restAccessCodeNamespace ||
                (accesses &&
                  accesses.includes(
                    restAccessCode +
                      "." +
                      restAccessCodeNamespace +
                      ".destroy"
                  )) ||
                (user &&
                  user["has_team_management_access"] &&
                  definitions &&
                  definitions.isTeamMenu)) &&
              !saveOnlyFromMainForm ? (
                <button
                  type="button"
                  className="btn btn-success waves-effect waves-light float-right"
                  onClick={() => {
                    if (
                      state[name] &&
                      state[name].length <= 0 &&
                      !maxRow
                    ) {
                      setState((prevState) => ({
                        ...prevState,
                        [name + "ErrorMessage"]:
                          "Please make sure at least one record exists before saving.",
                      }));
                    } else if (
                      maxRow &&
                      state[name]?.length !== maxRow
                    ) {
                      setState((prevState) => ({
                        ...prevState,
                        [name +
                        "ErrorMessage"]: `Please select ${maxRow} ${rowIdentifier}s.`,
                      }));
                    } else {
                      const deleted = [];
                      const editing = [];
                      const additions = [];

                      let dataCloned = JSON.parse(
                        JSON.stringify(state[name])
                      );

                      for (let i = 0; i < state[name].length; i++) {
                        for (let j = 0; j < columns.length; j++) {
                          if (
                            columns[j].isRequired &&
                            utils.form.isEmpty(
                              state[name][i][columns[j].name]
                            )
                          ) {
                            setState((prevState) => ({
                              ...prevState,
                              [name + "ErrorMessage"]:
                                "There are error(s) in form, please fix them first!",
                            }));

                            dataCloned[i][columns[j].name + "Error"] =
                              "This field is required";
                          } else {
                            delete dataCloned[i][
                              columns[j].name + "Error"
                            ];
                          }
                        }
                      }

                      let hasError = false;
                      for (let i = 0; i < state[name].length; i++) {
                        for (let j = 0; j < columns.length; j++) {
                          if (
                            dataCloned[i][columns[j].name + "Error"]
                          ) {
                            hasError = true;

                            break;
                          }
                        }
                      }

                      if (hasError) {
                        setState((prevState) => ({
                          ...prevState,
                          [name]: dataCloned,
                        }));

                        window.scrollTo({
                          top: 0,
                          left: 0,
                          behavior: "smooth",
                        });
                      } else {
                        if (!hasError) {
                          for (
                            let i = 0;
                            i < state[name].length;
                            i++
                          ) {
                            if (state[name][i].flag === "Deleted") {
                              deleted.push(state[name][i].id);
                            } else if (
                              state[name][i].flag === "Editing"
                            ) {
                              for (
                                let j = 0;
                                j < photoFields.length;
                                j++
                              ) {
                                if (
                                  state[name][i][
                                    photoFields[j]
                                  ].startsWith("http")
                                ) {
                                  delete state[name][i][
                                    photoFields[j]
                                  ];
                                }
                              }

                              editing.push(state[name][i]);
                            } else if (
                              state[name][i].flag === "New"
                            ) {
                              state[name][i][
                                relationKey.replace("_id", "")
                              ] = id;
                              additions.push(state[name][i]);
                            }
                          }

                          const sweetAlert = withReactContent(Swal);

                          sweetAlert.fire({
                            title: "Saving data",
                            html: (
                              <img
                                style={{
                                  width: "125px",
                                  height: "125px",
                                }}
                                alt="loading..."
                                src={loading}
                              />
                            ),
                            showConfirmButton: false,
                            allowOutsideClick: false,
                          });

                          for (let i = 0; i < editing.length; i++) {
                            for (
                              let j = 0;
                              j < excludedFieldsOnSubmit.length;
                              j++
                            ) {
                              if (
                                typeof editing[i][
                                  excludedFieldsOnSubmit[j]
                                ] === "object"
                              ) {
                                delete editing[i][
                                  excludedFieldsOnSubmit[j]
                                ];
                              }
                            }
                          }

                          for (let i = 0; i < additions.length; i++) {
                            for (
                              let j = 0;
                              j < excludedFieldsOnSubmit.length;
                              j++
                            ) {
                              if (
                                typeof additions[i][
                                  excludedFieldsOnSubmit[j]
                                ] === "object"
                              ) {
                                delete additions[i][
                                  excludedFieldsOnSubmit[j]
                                ];
                              }
                            }
                          }

                          let responseCount = 0;
                          let updateResponseSuccess = false;
                          let createResponseSuccess = false;
                          let destroyResponseSuccess = false;
                          let errorMessages = [];
                          let nonFieldErrors = [];

                          let dataCloned = JSON.parse(
                            JSON.stringify(state[name])
                          );

                          if (
                            ((user && user["is_super_user"]) ||
                              !restAccessCodeNamespace ||
                              (accesses &&
                                accesses.includes(
                                  restAccessCode +
                                    "." +
                                    restAccessCodeNamespace +
                                    ".update"
                                )) ||
                              (user &&
                                user["has_team_management_access"] &&
                                definitions &&
                                definitions.isTeamMenu)) &&
                            editing.length > 0
                          ) {
                            utils.httpClient.patch(
                              configs.apiUrl +
                                restPath +
                                "*/" +
                                "?" +
                                paramRestAccessCode +
                                "&" +
                                paramObjectId,
                              editing,
                              () => {
                                responseCount++;

                                updateResponseSuccess = true;
                              },
                              (e) => {
                                let errors = utils.httpClient
                                  .getErrorMessage(e)
                                  .split("\n")[0];

                                if (utils.commons.isJSON(errors)) {
                                  let errorsJSON = JSON.parse(
                                    errors.replaceAll("'", '"')
                                  );

                                  let itemRow = 0;

                                  if (
                                    utils.commons.isArray(errorsJSON)
                                  ) {
                                    for (
                                      let i = 0;
                                      i < errorsJSON.length;
                                      i++
                                    ) {
                                      for (let keyItem in errorsJSON[
                                        i
                                      ]) {
                                        if (
                                          keyItem ===
                                          "non_field_errors"
                                        ) {
                                          nonFieldErrors.push(
                                            errorsJSON[i][keyItem]
                                          );
                                        }
                                      }
                                    }
                                  }

                                  for (
                                    let i = 0;
                                    i < dataCloned.length;
                                    i++
                                  ) {
                                    if (
                                      dataCloned[i].flag === "Editing"
                                    ) {
                                      for (let keyItem in dataCloned[
                                        i
                                      ]) {
                                        delete dataCloned[i][
                                          keyItem + "Error"
                                        ];
                                      }

                                      if (
                                        typeof errorsJSON[itemRow] !==
                                          "undefined" &&
                                        errorsJSON[itemRow] !== null
                                      ) {
                                        for (let keyItem in errorsJSON[
                                          itemRow
                                        ]) {
                                          dataCloned[i][
                                            keyItem + "Error"
                                          ] =
                                            errorsJSON[itemRow][
                                              keyItem
                                            ];
                                        }
                                      }

                                      itemRow++;
                                    }
                                  }

                                  window.scrollTo({
                                    top: 0,
                                    left: 0,
                                    behavior: "smooth",
                                  });
                                } else {
                                  errorMessages.push(errors);
                                }

                                responseCount++;
                              }
                            );
                          } else {
                            updateResponseSuccess = true;
                            responseCount++;
                          }

                          if (
                            ((user && user["is_super_user"]) ||
                              !restAccessCodeNamespace ||
                              (accesses &&
                                accesses.includes(
                                  restAccessCode +
                                    "." +
                                    restAccessCodeNamespace +
                                    ".create"
                                )) ||
                              (user &&
                                user["has_team_management_access"] &&
                                definitions &&
                                definitions.isTeamMenu)) &&
                            additions.length > 0
                          ) {
                            utils.httpClient.post(
                              configs.apiUrl +
                                restPath +
                                "?" +
                                paramRestAccessCode +
                                "&" +
                                paramObjectId,
                              additions,
                              () => {
                                responseCount++;

                                createResponseSuccess = true;
                              },
                              (e) => {
                                let errors = utils.httpClient
                                  .getErrorMessage(e)
                                  .split("\n")[0];

                                if (utils.commons.isJSON(errors)) {
                                  let errorsJSON = JSON.parse(errors);

                                  if (
                                    utils.commons.isArray(errorsJSON)
                                  ) {
                                    for (
                                      let i = 0;
                                      i < errorsJSON.length;
                                      i++
                                    ) {
                                      for (let keyItem in errorsJSON[
                                        i
                                      ]) {
                                        if (
                                          keyItem ===
                                          "non_field_errors"
                                        ) {
                                          nonFieldErrors.push(
                                            errorsJSON[i][keyItem]
                                          );
                                        }
                                      }
                                    }
                                  }

                                  let itemRow = 0;
                                  for (
                                    let i = 0;
                                    i < dataCloned.length;
                                    i++
                                  ) {
                                    if (
                                      dataCloned[i].flag === "New"
                                    ) {
                                      for (let keyItem in dataCloned[
                                        i
                                      ]) {
                                        delete dataCloned[i][
                                          keyItem + "Error"
                                        ];
                                      }

                                      if (
                                        typeof errorsJSON[itemRow] !==
                                          "undefined" &&
                                        errorsJSON[itemRow] !== null
                                      ) {
                                        for (let keyItem in errorsJSON[
                                          itemRow
                                        ]) {
                                          dataCloned[i][
                                            keyItem + "Error"
                                          ] =
                                            errorsJSON[itemRow][
                                              keyItem
                                            ];
                                        }
                                      }

                                      itemRow++;
                                    }
                                  }

                                  window.scrollTo({
                                    top: 0,
                                    left: 0,
                                    behavior: "smooth",
                                  });
                                } else {
                                  errorMessages.push(errors);
                                }

                                responseCount++;
                              }
                            );
                          } else {
                            createResponseSuccess = true;
                            responseCount++;
                          }

                          if (
                            ((user && user["is_super_user"]) ||
                              !restAccessCodeNamespace ||
                              (accesses &&
                                accesses.includes(
                                  restAccessCode +
                                    "." +
                                    restAccessCodeNamespace +
                                    ".destroy"
                                )) ||
                              (user &&
                                user["has_team_management_access"] &&
                                definitions &&
                                definitions.isTeamMenu)) &&
                            deleted.length > 0
                          ) {
                            utils.httpClient.delete(
                              configs.apiUrl +
                                restPath +
                                "*/" +
                                "?" +
                                paramRestAccessCode +
                                "&" +
                                paramObjectId,
                              deleted,
                              () => {
                                responseCount++;

                                destroyResponseSuccess = true;
                              },
                              (e) => {
                                errorMessages.push(
                                  utils.httpClient
                                    .getErrorMessage(e)
                                    .split("\n")[0]
                                );

                                responseCount++;
                              }
                            );
                          } else {
                            destroyResponseSuccess = true;
                            responseCount++;
                          }

                          let intervalID = setInterval(() => {
                            if (responseCount === 3) {
                              clearInterval(intervalID);

                              sweetAlert.close();

                              if (
                                updateResponseSuccess &&
                                createResponseSuccess &&
                                destroyResponseSuccess
                              ) {
                                sweetAlert
                                  .fire(
                                    "Saved",
                                    "Record has been updated successfully.",
                                    "success"
                                  )
                                  .then(() => {
                                    setState((prevState) => ({
                                      ...prevState,
                                      [name + "HasBeenSaved"]: true,
                                    }));

                                    // load();
                                  });
                              } else {
                                setState((prevState) => ({
                                  ...prevState,
                                  [name]: dataCloned,
                                  [name + "ErrorMessage"]:
                                    nonFieldErrors.length === 0
                                      ? "There are error(s) in form, please fix them first!<br/>"
                                      : "<b>Error(s)</b>: " +
                                        (nonFieldErrors.length > 1
                                          ? "<br/>"
                                          : "") +
                                        nonFieldErrors.join("<br/>"),
                                  [name + "HasBeenSaved"]: false,
                                }));

                                if (errorMessages.length > 0) {
                                  let message = "Error in: ";

                                  const errorList = [];
                                  if (!updateResponseSuccess) {
                                    errorList.push("UPDATE");
                                  }

                                  if (!createResponseSuccess) {
                                    errorList.push("CREATE");
                                  }

                                  if (!destroyResponseSuccess) {
                                    errorList.push("DELETE");
                                  }

                                  sweetAlert.fire(
                                    "Error",
                                    message +
                                      errorList.join(", ") +
                                      "<br/><br/>" +
                                      errorMessages.join(", "),
                                    "error"
                                  );
                                }
                              }
                            }
                          }, 1000);
                        }
                      }
                    }
                  }}
                >
                  <i className="fas fa-save" /> Save
                </button>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}

export default withRouter(COneOrManyToManyFieldV2);
