// PEOPLE
//
// TEAM
import TeamMember from "pages/Team/Member";
import TeamMemberAddEdit from "pages/Team/Member/AddEdit";
import TeamMemberView from "pages/Team/Member/ViewOnly";
// TEAM ROLES
import TeamMemberRole from "pages/Team/MemberRole";
import TeamMemberRoleAddEdit from "pages/Team/MemberRole/AddEdit";
import TeamMemberRoleView from "pages/Team/MemberRole/ViewOnly";
//
// ORDER
//
// CHECKOUT REASONS
import CheckoutReason from "pages/Order/CheckoutReason";
import CheckoutReasonAddEdit from "pages/Order/CheckoutReason/AddEdit";
import CheckoutReasonView from "pages/Order/CheckoutReason/ViewOnly";
// CHECKOUT CATEGORIES
import CheckoutCategory from "pages/Order/CheckoutCategory";
import CheckoutCategoryAddEdit from "pages/Order/CheckoutCategory/AddEdit";
import CheckoutCategoryView from "pages/Order/CheckoutCategory/ViewOnly";
//
// FINANCE
//
// PAYMENT PLANS
import PaymentPlan from "pages/Finance/PaymentPlan";
import PaymentPlanAddEdit from "pages/Finance/PaymentPlan/AddEdit";
import PaymentPlanView from "pages/Finance/PaymentPlan/ViewOnly";
// BANK ACCOUNTS
import BankAccount from "pages/Finance/BankAccount";
import BankAccountAddEdit from "pages/Finance/BankAccount/AddEdit";
import BankAccountView from "pages/Finance/BankAccount/ViewOnly";
//
// TENANT
//
// OCCUPATIONS
import Occupation from "pages/User/Occupation";
import OccupationAddEdit from "pages/User/Occupation/AddEdit";
import OccupationView from "pages/User/Occupation/ViewOnly";
// ORGANIZATIONS
import Organization from "pages/User/Organization";
import OrganizationAddEdit from "pages/User/Organization/AddEdit";
import OrganizationView from "pages/User/Organization/ViewOnly";
//
// UTM CONFIGURATIONS
//
// UTM MEDIUMS
import UTMMedium from "pages/Marketing/UTMMedium";
import UTMMediumAddEdit from "pages/Marketing/UTMMedium/AddEdit";
import UTMMediumView from "pages/Marketing/UTMMedium/ViewOnly";
// UTM CONTENTS
import UTMContent from "pages/Marketing/UTMContent";
import UTMContentAddEdit from "pages/Marketing/UTMContent/AddEdit";
import UTMContentView from "pages/Marketing/UTMContent/ViewOnly";
// UTM SOURCES
import UTMSource from "pages/Marketing/UTMSource";
import UTMSourceAddEdit from "pages/Marketing/UTMSource/AddEdit";
import UTMSourceView from "pages/Marketing/UTMSource/ViewOnly";
// UTM CAMPAIGNS
import UTMCampaign from "pages/Marketing/UTMCampaign";
import UTMCampaignAddEdit from "pages/Marketing/UTMCampaign/AddEdit";
import UTMCampaignView from "pages/Marketing/UTMCampaign/ViewOnly";
//
// CONFIGURATIONS
//
// APP CONFIGURATIONS
import Configuration from "pages/Configuration/Config";
import ConfigurationAddEdit from "pages/Configuration/Config/AddEdit";
// LANGUAGES
import Language from "pages/User/Language";
import LanguageAddEdit from "pages/User/Language/AddEdit";
import LanguageView from "pages/User/Language/ViewOnly";
// ENABLED PAYMENTS
import EnabledPayment from "pages/PaymentGateway/EnabledPayment";
import EnabledPaymentAddEdit from "pages/PaymentGateway/EnabledPayment/AddEdit";
import EnabledPaymentView from "pages/PaymentGateway/EnabledPayment/ViewOnly";
// TERMS AND CONDITION
import TermsAndCondition from "pages/Admin/Configuration/TermsAndCondition";
import TermsAndConditionAddEdit from "pages/Admin/Configuration/TermsAndCondition/AddEdit";
import TermsAndConditionView from "pages/Admin/Configuration/TermsAndCondition/ViewOnly";
//
// LOGS
//
// MIDTRANS TRANSACTIONS
import MidtransTransaction from "pages/PaymentGateway/MidtransTransaction";
import MidtransTransactionAddEdit from "pages/PaymentGateway/MidtransTransaction/AddEdit";
import MidtransTransactionView from "pages/PaymentGateway/MidtransTransaction/ViewOnly";
// VOUCHER LOGS
import Voucher from "pages/User/Voucher";
import VoucherAddEdit from "pages/User/Voucher/AddEdit";
import VoucherView from "pages/User/Voucher/ViewOnly";
// NOTIFICATIONS
import Notification from "pages/Notification/Notification";
import NotificationAddEdit from "pages/Notification/Notification/AddEdit";
import NotificationView from "pages/Notification/Notification/ViewOnly";
//
// ONE TIME PURCHASE LOGS
//
// CARTS
import Carts from "pages/OneTimePurchase/Carts";
import CartDetail from "pages/OneTimePurchase/Carts/Detail";
import CartView from "pages/OneTimePurchase/Carts/ViewOnly";
// PAYMENTS
import Payments from "pages/OneTimePurchase/Payments";
import PaymentDetail from "pages/OneTimePurchase/Payments/Detail";
import PaymentView from "pages/OneTimePurchase/Payments/ViewOnly";
//
// LOCATION
//
// CITIES
import City from "pages/Places/City";
import CityAddEdit from "pages/Places/City/AddEdit";
import CityView from "pages/Places/City/ViewOnly";
// COUNTRIES
import Country from "pages/Places/Country";
import CountryAddEdit from "pages/Places/Country/AddEdit";
import CountryView from "pages/Places/Country/ViewOnly";
// DISTRICTS
import District from "pages/Places/District";
import DistrictAddEdit from "pages/Places/District/AddEdit";
import DistrictView from "pages/Places/District/ViewOnly";
// PROVINCES
import Province from "pages/Places/Province";
import ProvinceAddEdit from "pages/Places/Province/AddEdit";
import ProvinceView from "pages/Places/Province/ViewOnly";
// SUBDISTRICTS
import Subdistrict from "pages/Places/Subdistrict";
import SubdistrictAddEdit from "pages/Places/Subdistrict/AddEdit";
import SubdistrictView from "pages/Places/Subdistrict/ViewOnly";
//
// NOTIFICATIONS
//
// NOTIFICATION INFO
import NotificationInfo from "pages/Admin/Notification/InfoNotification";
import NotificationInfoAddEdit from "pages/Admin/Notification/InfoNotification/AddEdit";
import NotificationInfoView from "pages/Admin/Notification/InfoNotification/ViewOnly";

const AdminRoutes = [
  // PEOPLE
  //
  // TEAM
  {
    path: "/admin/people/team_member",
    component: TeamMember,
  },
  {
    path: "/admin/people/team_member/(add|edit)/:id([0-9]+)?",
    component: TeamMemberAddEdit,
  },
  {
    path: "/admin/people/team_member/view/:id([0-9]+)?",
    component: TeamMemberView,
  },
  // TEAM ROLES
  {
    path: "/admin/people/role",
    component: TeamMemberRole,
  },
  {
    path: "/admin/people/role/(add|edit)/:id([0-9]+)?",
    component: TeamMemberRoleAddEdit,
  },
  {
    path: "/admin/people/role/view/:id([0-9]+)?",
    component: TeamMemberRoleView,
  },
  //
  // ORDER
  //
  // CHECKOUT REASONS
  {
    path: "/admin/order/checkout_reason",
    component: CheckoutReason,
  },
  {
    path: "/admin/order/checkout_reason/(add|edit)/:id([0-9]+)?",
    component: CheckoutReasonAddEdit,
  },
  {
    path: "/admin/order/checkout_reason/view/:id([0-9]+)?",
    component: CheckoutReasonView,
  },
  // CHECKOUT CATEGORIES
  {
    path: "/admin/order/checkout_category",
    component: CheckoutCategory,
  },
  {
    path: "/admin/order/checkout_category/(add|edit)/:id([0-9]+)?",
    component: CheckoutCategoryAddEdit,
  },
  {
    path: "/admin/order/checkout_category/view/:id([0-9]+)?",
    component: CheckoutCategoryView,
  },
  //
  // FINANCE
  //
  // PAYMENT PLANS
  {
    path: "/admin/finance/payment_plan",
    component: PaymentPlan,
  },
  {
    path: "/admin/finance/payment_plan/(add|edit)/:id([0-9]+)?",
    component: PaymentPlanAddEdit,
  },
  {
    path: "/admin/finance/payment_plan/view/:id([0-9]+)?",
    component: PaymentPlanView,
  },
  // BANK ACCOUNTS
  {
    path: "/admin/finance/bank_account",
    component: BankAccount,
  },
  {
    path: "/admin/finance/bank_account/(add|edit)/:id([0-9]+)?",
    component: BankAccountAddEdit,
  },
  {
    path: "/admin/finance/bank_account/view/:id([0-9]+)?",
    component: BankAccountView,
  },
  //
  // TENANT
  //
  // OCCUPATIONS
  {
    path: "/admin/tenant/occupation",
    component: Occupation,
  },
  {
    path: "/admin/tenant/occupation/(add|edit)/:id([0-9]+)?",
    component: OccupationAddEdit,
  },
  {
    path: "/admin/tenant/occupation/view/:id([0-9]+)?",
    component: OccupationView,
  },
  // ORGANIZATIONS
  {
    path: "/admin/tenant/organization",
    component: Organization,
  },
  {
    path: "/admin/tenant/organization/(add|edit)/:id([0-9]+)?",
    component: OrganizationAddEdit,
  },
  {
    path: "/admin/tenant/organization/view/:id([0-9]+)?",
    component: OrganizationView,
  },
  //
  // UTM CONFIGURATIONS
  //
  // UTM MEDIUMS
  {
    path: "/admin/utm/utm_medium",
    component: UTMMedium,
  },
  {
    path: "/admin/utm/utm_medium/(add|edit)/:id([0-9]+)?",
    component: UTMMediumAddEdit,
  },
  {
    path: "/admin/utm/utm_medium/view/:id([0-9]+)?",
    component: UTMMediumView,
  },
  // UTM CONTENTS
  {
    path: "/admin/utm/utm_content",
    component: UTMContent,
  },
  {
    path: "/admin/utm/utm_content/(add|edit)/:id([0-9]+)?",
    component: UTMContentAddEdit,
  },
  {
    path: "/admin/utm/utm_content/view/:id([0-9]+)?",
    component: UTMContentView,
  },
  // UTM SOURCES
  {
    path: "/admin/utm/utm_source",
    component: UTMSource,
  },
  {
    path: "/admin/utm/utm_source/(add|edit)/:id([0-9]+)?",
    component: UTMSourceAddEdit,
  },
  {
    path: "/admin/utm/utm_source/view/:id([0-9]+)?",
    component: UTMSourceView,
  },
  // UTM CAMPAIGNS
  {
    path: "/admin/utm/utm_campaign",
    component: UTMCampaign,
  },
  {
    path: "/admin/utm/utm_campaign/(add|edit)/:id([0-9]+)?",
    component: UTMCampaignAddEdit,
  },
  {
    path: "/admin/utm/utm_campaign/view/:id([0-9]+)?",
    component: UTMCampaignView,
  },
  //
  // CONFIGURATIONS
  //
  // APP CONFIGURATIONS
  {
    path: "/admin/configuration/app_configuration",
    component: Configuration,
  },
  {
    path: "/admin/configuration/app_configuration/(add|edit)/:id([0-9]+)?",
    component: ConfigurationAddEdit,
  },
  // LANGUAGES
  {
    path: "/admin/configuration/language",
    component: Language,
  },
  {
    path: "/admin/configuration/language/(add|edit)/:id([0-9]+)?",
    component: LanguageAddEdit,
  },
  {
    path: "/admin/configuration/language/view/:id([0-9]+)?",
    component: LanguageView,
  },
  // ENABLED PAYMENTS
  {
    path: "/admin/configuration/enabled_payment",
    component: EnabledPayment,
  },
  {
    path: "/admin/configuration/enabled_payment/(add|edit)/:id([0-9]+)?",
    component: EnabledPaymentAddEdit,
  },
  {
    path: "/admin/configuration/enabled_payment/view/:id([0-9]+)?",
    component: EnabledPaymentView,
  },
  // TERMS AND CONDITION
  {
    path: "/admin/configuration/term_and_condition",
    component: TermsAndCondition,
  },
  {
    path: "/admin/configuration/term_and_condition/(add|edit)/:id([0-9]+)?",
    component: TermsAndConditionAddEdit,
  },
  {
    path: "/admin/configuration/term_and_condition/view/:id([0-9]+)?",
    component: TermsAndConditionView,
  },
  //
  // LOGS
  //
  // MIDTRANS TRANSACTIONS
  {
    path: "/admin/logs/midtrans_transaction",
    component: MidtransTransaction,
  },
  {
    path: "/admin/logs/midtrans_transaction/(add|edit)/:id([0-9]+)?",
    component: MidtransTransactionAddEdit,
  },
  {
    path: "/admin/logs/midtrans_transaction/view/:id([0-9]+)?",
    component: MidtransTransactionView,
  },
  // VOUCHER LOGS

  {
    path: "/admin/logs/voucher_log",
    component: Voucher,
  },
  {
    path: "/admin/logs/voucher_log/(add|edit)/:id([0-9]+)?",
    component: VoucherAddEdit,
  },
  {
    path: "/admin/logs/voucher_log/view/:id([0-9]+)?",
    component: VoucherView,
  },
  // NOTIFICATIONS
  {
    path: "/admin/logs/notification",
    component: Notification,
  },
  {
    path: "/admin/logs/notification/(add|edit)/:id([0-9]+)?",
    component: NotificationAddEdit,
  },
  {
    path: "/admin/logs/notification/view/:id([0-9]+)?",
    component: NotificationView,
  },
  //
  // ONE TIME PURCHASE LOGS
  // CARTS
  {
    path: "/admin/logs/onetimepurchase/cart",
    component: Carts,
  },
  {
    path: "/admin/logs/onetimepurchase/cart/:id([0-9]+)?",
    component: CartDetail,
  },
  {
    path: "/admin/logs/onetimepurchase/cart/view/:id([0-9]+)?",
    component: CartView,
  },
  // PAYMENTS
  {
    path: "/admin/logs/onetimepurchase/payment",
    component: Payments,
  },
  {
    path: "/admin/logs/onetimepurchase/payment/:id([0-9]+)?",
    component: PaymentDetail,
  },
  {
    path: "/admin/logs/onetimepurchase/payment/view/:id([0-9]+)?",
    component: PaymentView,
  },
  //
  // LOCATION
  //
  // CITIES
  {
    path: "/admin/location/city",
    component: City,
  },
  {
    path: "/admin/location/city/(add|edit)/:id([0-9]+)?",
    component: CityAddEdit,
  },
  {
    path: "/admin/location/city/view/:id([0-9]+)?",
    component: CityView,
  },
  // COUNTRIES
  {
    path: "/admin/location/country",
    component: Country,
  },
  {
    path: "/admin/location/country/(add|edit)/:id([0-9]+)?",
    component: CountryAddEdit,
  },
  {
    path: "/admin/location/country/view/:id([0-9]+)?",
    component: CountryView,
  },
  // DISCTRICTS
  {
    path: "/admin/location/district",
    component: District,
  },
  {
    path: "/admin/location/district/(add|edit)/:id([0-9]+)?",
    component: DistrictAddEdit,
  },
  {
    path: "/admin/location/district/view/:id([0-9]+)?",
    component: DistrictView,
  },
  // PROVINCES
  {
    path: "/admin/location/province",
    component: Province,
  },
  {
    path: "/admin/location/province/(add|edit)/:id([0-9]+)?",
    component: ProvinceAddEdit,
  },
  {
    path: "/admin/location/province/view/:id([0-9]+)?",
    component: ProvinceView,
  },
  // SUBDISTRICTS
  {
    path: "/admin/location/subdistrict",
    component: Subdistrict,
  },
  {
    path: "/admin/location/subdistrict/(add|edit)/:id([0-9]+)?",
    component: SubdistrictAddEdit,
  },
  {
    path: "/admin/location/subdistrict/view/:id([0-9]+)?",
    component: SubdistrictView,
  },
  //
  // NOTIFICATIONS
  //
  // NOTIFICATION INFO
  {
    path: "/admin/notification/notification_info",
    component: NotificationInfo,
  },
  {
    path: "/admin/notification/notification_info/(add|edit)/:id([0-9]+)?",
    component: NotificationInfoAddEdit,
  },
  {
    path: "/admin/notification/notification_info/view/:id([0-9]+)?",
    component: NotificationInfoView,
  },
];

export default AdminRoutes;
