import configBuilder from "../../../components/ubold/ConfigBuilder";
import utils from "../../../utils";

let definitions = {
  title: "UTM Campaigns",
  frontendPath: "/admin/utm/utm_campaign",
  baseLink: "/marketing/utm_campaign",
  restAccessCode: "marketing.utm_campaign",
  __table__: {
    hideEditButton: true,
    filterColumn: 3,
    removeIdentifier: "utm_campaign",
    filters: [
      {
        id: "utm_campaign",
        name: "UTM Campaign",
        type: "text",
      },
      {
        id: "code",
        name: "Code",
        type: "text",
      },
      {
        id: "date_created",
        name: "Date Created",
        type: "date",
      },
    ],
    columns: [
      {
        id: "utm_campaign",
        name: "UTM Campaign",
        enableHyperlink: true,
      },
      {
        id: "code",
        name: "Code",
      },
      {
        id: "date_created",
        name: "Date Created",
        renderer: (value) => {
          return utils.formatter.dateTime(value);
        },
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
