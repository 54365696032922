import { useParams, withRouter } from "react-router-dom";
import COneOrManyToManyFieldV2 from "../../../../../../components/ubold/molecules/forms/COneOrManyToManyFieldV2";
import AccordionPane from "components/ubold/organisms/AccordionPane";

import CHSingleRow from "components/ubold/molecules/forms/CHSingleRow";
import moment from "moment";
import React, { useEffect } from "react";

function InvoiceDetails(props) {
  const {
    state,
    setState,
    generalKey,
    accordionId,
    baseLink,
    onSubmit,
  } = props;

  let { id } = useParams();
  const getSubTotalPerPeriode = (data) => {
    let total = 0;
    let roomPrice = 0;
    let deduction = 0;
    let other = 0;

    if (data.length === 0)
      return (state[`${generalKey}-subtotal`] = total);
    for (let i = 0; i < data.length; i++) {
      if (data[i].category === 4) {
        roomPrice = data[i].price;
      } else if (data[i].category === 1) {
        deduction += data[i].price;
      } else if (data[i].category === 2 && data[i]?.flag === "New") {
        other += parseFloat(data[i].price);
      } else {
        other += data[i].price;
      }
    }

    const finalRoomPriceAfterDeduction =
      roomPrice - deduction < 0 ? 0 : roomPrice - deduction;

    total = finalRoomPriceAfterDeduction + other;
    state[`${generalKey}-subtotal`] = total;
  };

  const hasAdditionalFee = (data, condition) => {
    let result = false;
    if (condition === "beforeAdd") {
      result = data?.some(
        (el) => el?.flag !== "New" && el.sub_category === 5
      );
    } else if (condition === "afterAdd") {
      result = data?.some((el) => el.sub_category === 5);
    }
    return result;
  };

  const getPeriode = (data, type) => {
    let newDate = "";
    if (type === "min") {
      newDate = moment(data[0]?.start_date)
        .startOf("month")
        .format("YYYY-MM-DD");
    } else {
      newDate = moment(data[0]?.start_date)
        .endOf("month")
        .format("YYYY-MM-DD");
    }
    return newDate;
  };

  const getConditionallyDisabled = () => {
    let checkResult = false;

    if (
      state?.invoice_type === 1 &&
      state?.invoice_status === 2 &&
      state?.original___release_status === 3
    ) {
      checkResult = true;
    } else if (
      state?.invoice_type === 1 &&
      state?.original___release_status === 3
    ) {
      checkResult = true;
    } else if (
      state?.invoice_type === 1 &&
      state?.invoice_status !== 2 &&
      state?.original___release_status !== 3 &&
      hasAdditionalFee(state[generalKey])
    ) {
      checkResult = true;
    } else if (
      state?.invoice_type === 4 &&
      state?.original___invoice_status === 1 &&
      state?.original___release_status === 3
    ) {
      checkResult = true;
    } else if (state?.invoice_type === 3) {
      checkResult = true;
    } else if (state?.invoice_type === 2) {
      checkResult = true;
    }
    return checkResult;
  };

  useEffect(() => {
    getSubTotalPerPeriode(state[generalKey]);
  }, [state[generalKey]]);

  if (
    !state?.[generalKey]?.length &&
    state?.[`${generalKey}-default`]?.length
  ) {
    state[generalKey] = state?.[`${generalKey}-default`];
  }

  return (
    <AccordionPane
      id={generalKey}
      name={generalKey}
      accordionId={accordionId}
      baseLink={baseLink}
      onSubmit={onSubmit}
      state={state}
      setState={setState}
    >
      <COneOrManyToManyFieldV2
        id={id}
        name={generalKey}
        saveOnlyFromMainForm
        disableActionBtn={id && getConditionallyDisabled()}
        dependsTabName={generalKey}
        maxRow={
          state?.invoice_type === 1 &&
          hasAdditionalFee(state[generalKey], "afterAdd")
        }
        relationKey="invoice_id"
        columns={[
          {
            name: "category",
            type: "select",
            title: "Category",
            readOnly: true,
            defaultVal: 2,
            data: {
              optionField: "finance_new_invoice_detail_category",
            },
            dataList: state[generalKey],
          },
          {
            name: "sub_category",
            type: "select",
            title: "Sub Category",
            readOnly: true,
            defaultVal: 5,
            data: {
              optionField: "finance_new_invoice_detail_sub_category",
            },
            dataList: state[generalKey],
          },

          {
            name: "item",
            title: "Name",
            type: "text",
            dynamicReadOnly: true,
            dataList: state[generalKey],
            isRequired: true,
          },
          {
            name: "price",
            type: "number",
            title: "Amount",
            dynamicReadOnly: true,
            dataList: state[generalKey],
            releaseStatus: "original___release_status",
            invoiceStatus: "original___invoice_status",
            isRequired: true,
            dynamicTypeField: true,
          },
          {
            name: "description",
            type: "textarea",
            title: "Descriptions",
            readOnly: true,
          },
          {
            name: "start_date",
            type: "date",
            title: "Start Date",
            minDate: getPeriode(state[generalKey], "min"),
            maxDate: getPeriode(state[generalKey], "max"),
            dynamicValue: true,
            dynamicReadOnly: true,
            dataList: state[generalKey],
          },
          {
            name: "end_date",
            type: "date",
            title: "End Date",
            minDate: getPeriode(state[generalKey], "min"),
            maxDate: getPeriode(state[generalKey], "max"),
            dataList: state[generalKey],
            dynamicMinDate: true,
            dynamicReadOnly: true,
            dynamicValue: true,
            plusOne: false,
            requireField: "Start Date",
          },
        ]}
      />
      {state?.[`${generalKey}-subtotal`] ? (
        <CHSingleRow
          title="Total"
          data={state?.[`${generalKey}-subtotal`]}
          titleWidth="57%"
        />
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </AccordionPane>
  );
}

export default withRouter(InvoiceDetails);
