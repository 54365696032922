import configBuilder from "../../../components/ubold/ConfigBuilder";

let definitions = {
  title: "Landlord Page",
  frontendPath: "/website/landlord_page",
  baseLink: "/building/make_over_gallery",
  restAccessCode: "building.make_over_gallery",
  __table__: {
    filterColumn: 2,
    removeIdentifier: "building_name",
    hideEditButton: true,
    filters: [
      {
        id: "building",
        name: "Building",
        type: "async_select",
        data: "building/building?__type__=select_entries",
        itemsExtractor: (row) => {
          return {
            label: row.building_name,
            value: row.id,
          };
        },
      },
      {
        id: "sequence",
        name: "Sequence",
        type: "number",
      },
    ],
    columns: [
      {
        id: "building_name",
        name: "Building",
        enableHyperlink: true,
      },
      {
        id: "photo",
        name: "Photo",
        isSafeContent: true,
        canDoOrdering: false,
        center: true,
        renderer: (value) => {
          if (value && value !== "-" && value !== "") {
            return (
              "<img alt='image' src='" + value + "' width='50px' />"
            );
          } else {
            return value;
          }
        },
      },
      {
        id: "sequence",
        name: "Sequence",
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
