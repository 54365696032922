import configBuilder from "../../../components/ubold/ConfigBuilder";

import utils from "../../../utils";

let definitions = {
  title: "Notifications",
  frontendPath: "/admin/logs/notification",
  baseLink: "/notification/notification",
  restAccessCode: "notification.notification",
  __table__: {
    filterColumn: 4,
    editAsDetail: true,
    addButton: false,
    hideEditButton: true,
    hideRemoveButton: true,
    filters: [
      {
        id: "title",
        name: "Title",
        type: "text",
      },
      {
        id: "messages",
        name: "Message",
        type: "text",
      },
      {
        id: "tenant",
        name: "Tenant",
        type: "async_select",
        data: "user/tenant?__type__=select_entries",
      },
      {
        id: "relation_type",
        name: "Relation Type",
        type: "select",
        data: {
          optionField: "notification_notification_type",
        },
      },
      {
        id: "status",
        name: "Status",
        type: "select",
        data: {
          optionField: "notification_status_choice",
        },
      },
      {
        id: "date_created",
        name: "Date Created",
        type: "date",
      },
    ],
    columns: [
      {
        id: "tenant_name",
        name: "Tenant",
        renderer: (value, row) => {
          return value + " - " + row["tenant_email"];
        },
        enableHyperlink: true,
      },
      {
        id: "title",
        name: "Title",
      },
      {
        id: "messages",
        name: "Message",
        isSafeContent: true,
        wrap: true,
      },
      {
        id: "relation_type",
        name: "Relation Type",
        referStateFieldName: "notification_notification_type",
        renderer: (value, _, constants) => {
          let label = "-";
          (constants ?? []).forEach((obj) => {
            if (obj.value === parseInt(value)) {
              label = obj.label;
            }
          });
          return label;
        },
      },
      {
        id: "status",
        name: "Status",
        referStateFieldName: "notification_status_choice",
        renderer: (value, _, constants) => {
          let label = "-";
          (constants ?? []).forEach((obj) => {
            if (obj.value === parseInt(value)) {
              label = obj.label;
            }
          });
          return label;
        },
      },
      {
        id: "date_created",
        name: "Date Created",
        renderer: (value) => {
          return utils.formatter.dateTime(value);
        },
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
