import { withRouter } from "react-router-dom";

import TabPane from "../../../../../../components/ubold/organisms/TabPane";
import TabPaneSection from "../../../../../../components/ubold/organisms/TabPaneSection";

import CHTextField from "../../../../../../components/ubold/molecules/forms/CHTextField";
import CHTextArea from "../../../../../../components/ubold/molecules/forms/CHTextArea";

function Role(props: any) {
  const { id, definitions, tab, state, setState } = props;

  return (
    <TabPane
      id={id}
      definitions={definitions}
      tab={tab}
      readOnly
      state={state}
      setState={setState}
    >
      <TabPaneSection style={{ padding: "25px 16px 0px" }}>
        <CHTextField
          name="name"
          title="Name"
          isRequired
          maxLength={50}
          readOnly
        />
        <CHTextArea
          name="description"
          title="Description"
          maxLength={255}
          readOnly
        />
      </TabPaneSection>
    </TabPane>
  );
}

export default withRouter(Role);
