import { useParams, withRouter } from "react-router-dom";

import TabPaneSecondary from "../../../../../../components/ubold/organisms/TabPaneSecondary";

import COneOrManyToManyField from "../../../../../../components/ubold/molecules/forms/COneOrManyToManyField";

function RoomVariantPhoto(props) {
  const { definitions, tab, state, setState } = props;

  let { id } = useParams();

  return (
    <TabPaneSecondary
      definitions={definitions}
      tab={tab}
      state={state}
      restAccessCodeNamespace="photo"
      setState={setState}
    >
      <COneOrManyToManyField
        id={id}
        name={tab.id}
        restAccessCode={definitions.restAccessCode}
        restPath="building/room_variant_photos/"
        relationKey="room_variant_id"
        photoFields={["photo"]}
        columns={[
          {
            name: "photo",
            type: "photo_upload",
            isRequired: true,
            title: "Photo (Max Size 2MB)",
          },
          {
            name: "description",
            type: "textarea",
            width: 400,
            maxLength: 120,
            title: "Description",
          },
        ]}
      />
    </TabPaneSecondary>
  );
}

export default withRouter(RoomVariantPhoto);
