import configBuilder from "components/ubold/ConfigBuilder";

import pageDefinitions from "../definitions";
import utils from "utils";

const definitions = {
  ...pageDefinitions,
  validators: {
    cancellation_policy: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    refund_policy: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    parking_policy: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    payment_policy: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    cancellation_policy_title: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    refund_policy_title: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    parking_policy_title: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    payment_policy_title: (value: string) => {
      return utils.validator.validateRequired(value);
    },
  },
};

export default configBuilder.buildAddEditPageConfig(definitions);
