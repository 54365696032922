import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import React, { useState } from "react";
import { SingleDatePicker } from "react-dates";
import { StyledDatePickerWrapper } from "./CDatePickerV2.styled";

type DatePickerProps = {
  name?: string;
  numberOfMonths?: number;
  readOnly?: boolean;
  setState?: Function;
  state?: any;
  placeholder?: string;
  showClearDate?: boolean;
};
const CDatePickerV2: React.FC<DatePickerProps> = (props) => {
  const {
    name,
    readOnly,
    state,
    setState,
    numberOfMonths = 1,
    placeholder,
    showClearDate,
  } = props;
  const [focused, setFocused] = useState<boolean>(false);

  return (
    <StyledDatePickerWrapper
      disabled={readOnly}
      hasError={state[name + "Error"]}
      isFocused={focused}
    >
      <SingleDatePicker
        date={state[name]}
        disabled={readOnly}
        focused={focused}
        id={name}
        inputIconPosition="after"
        numberOfMonths={numberOfMonths}
        onDateChange={(date: any) => {
          setState((prevState: any) => ({
            ...prevState,
            [name]: date,
          }));
        }}
        showClearDate={showClearDate}
        placeholder={placeholder}
        onFocusChange={({ focused }: { focused: any }) =>
          setFocused(focused)
        }
        customInputIcon={
          <img
            src="/assets/icons/icon-calendar.svg"
            alt="calendar"
            width="16px"
            height="16px"
          />
        }
        displayFormat="DD MMM YYYY"
        hideKeyboardShortcutsPanel
      />
    </StyledDatePickerWrapper>
  );
};

export default CDatePickerV2;
