import React from "react";
import styled from "styled-components";

import Tooltip from "../tooltip/Tooltip";
import utils from "../../../../utils";
import { firstLetterUppercase } from "../../../../utils/generalUtils";

interface IconWithTextProps {
  disableText?: boolean;
  label?: string;
  limitChar?: number;
  splitLabel?: boolean;
  trigger?: "click" | "hover";
  useTooltip?: boolean;
  zIndex?: number;
  getUsername?: boolean;
}

const IconWithText: React.FC<IconWithTextProps> = ({
  disableText,
  label,
  limitChar,
  splitLabel,
  trigger,
  useTooltip,
  zIndex,
  getUsername,
}) => {
  const labelSplitted = (label: string) => {
    const splitted = label.split("|");
    return `${splitted?.[1] ?? ""}`;
  };

  const getBookedByUser = (label: string) => {
    const splitStr = label.split(" ");
    const finalWord = firstLetterUppercase(splitStr?.[2]);
    return finalWord;
  };

  return (
    <Container>
      <img
        src={`${
          label.includes("Yes")
            ? "/assets/icons/check-circle-fill.png"
            : "/assets/icons/x-circle-fill.png"
        }`}
        width={12}
        height={12}
        alt="icon"
        style={{
          marginRight: 4,
        }}
      />
      {!useTooltip && (
        <span style={{ display: "flex", marginTop: -1 }}>
          {!disableText && !splitLabel && label}
          {disableText && splitLabel && labelSplitted(label)}
        </span>
      )}
      {useTooltip && (
        <Tooltip
          contentHover={"see detail"}
          contentMain={
            !disableText && !splitLabel
              ? label
              : utils.formatter.truncateWithEllipsis(
                  !getUsername
                    ? labelSplitted(label)
                    : getBookedByUser(label),
                  limitChar
                )
          }
          trigger={trigger}
          zIndex={zIndex}
        >
          <span style={{ display: "flex", marginTop: -1 }}>
            {!disableText && !splitLabel && label}
            {disableText &&
              splitLabel &&
              getUsername &&
              getBookedByUser(label)}
          </span>
        </Tooltip>
      )}
    </Container>
  );
};

export default IconWithText;

const Container = styled.div`
  display: flex;
  font-family: inherit;
  font-size: 10px;
  font-weight: 400;
  flex-direction: row;
  line-height: 14px;
  overflow: hidden;
  color: #222222; ;
`;
