import configBuilder from "components/ubold/ConfigBuilder";
import pageDefinitions from "../definitions";

const definitions = {
  ...pageDefinitions,
  tabs: [
    {
      id: "homepage_collection",
      name: "Homepage Collection",
    },
    {
      id: "t_building_collection",
      name: "Building Collection",
    },
  ],
};

export default configBuilder.buildAddEditPageConfig(definitions, [
  "icon",
]);
