import { withRouter } from "react-router-dom";

import CDateTimePicker from "../../atoms/forms/CDateTimePicker";

import useGlobalState from "../../../../state";

import utils from "../../../../utils";

function CHDateTimePicker(props) {
  const {
    name,
    title,
    isRequired = false,
    restAccessCode,
    helperText,
    type = "datetime",
    state,
    setState,
    readOnly,
    disableMobile = false,
    minDate,
    maxDate,
    helperTextAdditional,
    isClearable,
    modifiedCol,
    children,
  } = props;

  const [user] = useGlobalState("user");
  const isSuperUser = user ? user.is_super_user : false;
  const [fieldAccesses] = useGlobalState("field_accesses");
  const [fieldAccessReversePolicy] = useGlobalState(
    "field_access_reverse_policy"
  );

  return (
    <div
      className="form-group row mb-3"
      style={{
        display:
          !isRequired &&
          restAccessCode &&
          utils.access.isFieldHidden(
            fieldAccesses,
            restAccessCode + "." + name
          )
            ? "none"
            : "flex",
      }}
    >
      <label
        className={
          "col-md-3 col-form-label" +
          (isRequired ? " font-weight-bold" : "") +
          (state[name + "Error"] ? " text-danger" : "")
        }
      >
        {title}
        {isRequired ? "*" : ""}{" "}
        {readOnly ??
        utils.access.isFieldReadOnly(
          fieldAccesses,
          restAccessCode + "." + name,
          fieldAccessReversePolicy,
          isSuperUser
        )
          ? "(read only)"
          : ""}
      </label>
      <div
        className={!children ? `col-md-9` : `col-md-${modifiedCol}`}
      >
        <CDateTimePicker
          name={name}
          type={type}
          readOnly={
            readOnly ??
            (restAccessCode &&
              utils.access.isFieldReadOnly(
                fieldAccesses,
                restAccessCode + "." + name,
                fieldAccessReversePolicy,
                isSuperUser
              ))
          }
          state={state}
          setState={setState}
          disableMobile={disableMobile}
          minDate={minDate}
          maxDate={maxDate}
          isClearable={isClearable}
        />
        {helperText ? (
          <span
            className="text-muted font-13 pt-1 mb-0"
            dangerouslySetInnerHTML={{ __html: helperText }}
          />
        ) : (
          ""
        )}
        {helperText ? <br /> : ""}
        {helperTextAdditional ? (
          <span
            className="text-muted font-13 pt-1 mb-0"
            dangerouslySetInnerHTML={{ __html: helperTextAdditional }}
          />
        ) : (
          ""
        )}
        {state[name + "Error"] ? (
          <span className="text-danger" style={{ fontSize: "12px" }}>
            {state[name + "Error"]}
          </span>
        ) : (
          <></>
        )}
      </div>
      {children ? (
        <div className={`col-md-${9 - modifiedCol}`}>{children}</div>
      ) : null}
    </div>
  );
}

export default withRouter(CHDateTimePicker);
