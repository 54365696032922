import configBuilder from "../../../../components/ubold/ConfigBuilder";

import pageDefinitions from "../definitions";

import utils from "../../../../utils";

const definitions = {
  ...pageDefinitions,
  tabs: [
    {
      id: "agreement",
      name: "Agreement",
    },
    {
      id: "t_tiring_escalations",
      name: "Tiring Escalations",
    },
  ],
  validators: {
    building: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    building_effective_date: (value: string) => {
      return utils.validator.validateRequired(value);
    },
  },
};

export default configBuilder.buildAddEditPageConfig(definitions);
