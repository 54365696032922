import React, { HTMLAttributes } from "react";
import moment from "moment";
import { withRouter } from "react-router-dom";

import AccordionPane from "../../../../ubold/organisms/AccordionPane";

import { IPreviewOrder_PeriodObject, sectionProps } from "@types";
import AccordionItemBase, {
  GridItemSingleRow,
  GridTableHead,
} from "./AccordionItem.css";
import {
  LabelMBold,
  LabelMRegular,
} from "components/ubold/atoms/General.css";

import useGlobalState from "../../../../../state";
import utils from "../../../../../utils";

const LegacyTenantInfo: React.FC<
  sectionProps & {
    data: IPreviewOrder_PeriodObject[];
    name?: string;
    shown?: boolean;
    total: string;
  } & HTMLAttributes<HTMLDivElement>
> = (props) => {
  const {
    baseLink,
    accordionId,
    data,
    name,
    onSubmit,
    state,
    setState,
    shown = false,
    total,
    ...rest
  } = props;
  const [constants] = useGlobalState("constant_values");

  const generateConstantObject = (
    constantField:
      | "finance_new_invoice_detail_category"
      | "finance_new_invoice_detail_sub_category",
    value: number
  ): { label: string; value: number } => {
    if (constants?.[constantField]?.length > 0) {
      return constants?.[constantField]?.find(
        (category: { label: string; value: number }) =>
          category.value === value
      );
    }

    return null;
  };

  if (data?.length === 0) return null;

  const renderRow = (): React.ReactElement => {
    if (data?.length === 0) return null;

    return (
      <React.Fragment>
        {data?.map(
          (
            invoiceDetail: IPreviewOrder_PeriodObject,
            invoiceDetailIndex: number
          ) => {
            return (
              <GridItemSingleRow
                key={`preview-order-row-${invoiceDetailIndex}`}
              >
                <div>
                  <LabelMBold>
                    {
                      generateConstantObject(
                        "finance_new_invoice_detail_category",
                        invoiceDetail?.category
                      ).label
                    }
                  </LabelMBold>
                </div>

                <div>
                  <LabelMRegular>
                    {invoiceDetail?.sub_category
                      ? generateConstantObject(
                          "finance_new_invoice_detail_sub_category",
                          invoiceDetail.sub_category
                        ).label
                      : "-"}
                  </LabelMRegular>
                </div>

                <div>
                  <LabelMRegular className="text-center">
                    {invoiceDetail?.item}
                  </LabelMRegular>
                </div>

                <div>
                  <LabelMRegular className="justify-self-start">
                    {invoiceDetail.category === 1 && "(-) "}
                    Rp
                  </LabelMRegular>
                  <LabelMRegular>
                    {utils.formatter
                      .currency(invoiceDetail?.price)
                      .replace("Rp", "")
                      .replace(/\s/g, "")}
                  </LabelMRegular>
                </div>

                <div>
                  <LabelMRegular>
                    {generateConstantObject(
                      "finance_new_invoice_detail_category",
                      invoiceDetail?.category
                    ).value === 1 ||
                    generateConstantObject(
                      "finance_new_invoice_detail_category",
                      invoiceDetail?.category
                    ).value === 2
                      ? moment(
                          invoiceDetail?.start_date,
                          "YYYY-MM-DD"
                        ).format("DD-MM-YYYY")
                      : moment(
                          invoiceDetail?.period_start,
                          "YYYY-MM-DD"
                        ).format("DD-MM-YYYY")}
                  </LabelMRegular>
                </div>

                <div>
                  <LabelMRegular>
                    {moment(
                      invoiceDetail?.end_date,
                      "YYYY-MM-DD"
                    ).format("DD-MM-YYYY") ??
                      moment(
                        invoiceDetail?.period_end,
                        "YYYY-MM-DD"
                      ).format("DD-MM-YYYY")}
                  </LabelMRegular>
                </div>
              </GridItemSingleRow>
            );
          }
        )}
      </React.Fragment>
    );
  };

  return (
    <AccordionItemBase {...rest}>
      <AccordionPane
        id={name}
        name={name?.replace("-", " ")}
        accordionId={accordionId}
        baseLink={baseLink}
        onSubmit={onSubmit}
        //   shown={
        //     !!(
        //       utils.form.isFieldError(state, "check_in_to_landlord") ||
        //       utils.form.isFieldError(state, "landlord_price") ||
        //       utils.form.isFieldError(state, "last_payment_to_landlord")
        //     )
        //   }
        shown={shown}
        state={state}
        setState={setState}
      >
        <GridTableHead>
          <div>
            <LabelMBold>Category</LabelMBold>
          </div>

          <div>
            <LabelMBold>Sub-Category</LabelMBold>
          </div>

          <div>
            <LabelMBold>Name</LabelMBold>
          </div>

          <div>
            <LabelMBold>Amount</LabelMBold>
          </div>

          <div>
            <LabelMBold>Start Date</LabelMBold>
          </div>

          <div>
            <LabelMBold>End Date</LabelMBold>
          </div>
        </GridTableHead>

        {renderRow()}

        <GridItemSingleRow>
          <div>
            <LabelMBold>Total</LabelMBold>
          </div>

          <div className="table-border-r-unset"></div>

          <div className="table-border-r-unset"></div>

          <div>
            <LabelMRegular className="justify-self-start">
              Rp
            </LabelMRegular>
            <LabelMRegular>{total}</LabelMRegular>
          </div>

          <div className="table-border-r-unset">
            <LabelMRegular>{""}</LabelMRegular>
          </div>

          <div>
            <LabelMRegular>{""}</LabelMRegular>
          </div>
        </GridItemSingleRow>
      </AccordionPane>
    </AccordionItemBase>
  );
};

export default withRouter(LegacyTenantInfo);
