import configBuilder from "../../../components/ubold/ConfigBuilder";

let definitions = {
  title: "Place Groups",
  frontendPath: "/website/place/place_group",
  baseLink: "/building/place_group",
  restAccessCode: "building.place_group",
  __table__: {
    filterColumn: 4,
    hideEditButton: true,
    filters: [
      {
        id: "name",
        name: "Name",
        type: "text",
      },
      {
        id: "slug",
        name: "Slug",
        type: "text",
      },
      {
        id: "published",
        name: "Published",
        type: "select",
        data: [
          {
            label: "Yes",
            value: true,
          },
          {
            label: "No",
            value: false,
          },
        ],
      },
      {
        id: "order_no",
        name: "Sequence Number",
        type: "number",
      },
    ],
    columns: [
      {
        id: "name",
        name: "Name",
        enableHyperlink: true,
      },
      {
        id: "slug",
        name: "Slug",
      },
      {
        id: "published",
        name: "Published",
        isSafeContent: true,
        renderer: (value) => {
          if (value === "true") {
            return '<span style="color: rgb(26, 188, 156);"> ✔ </span>';
          } else {
            return '<span style="color: red;">✘</span>';
          }
        },
      },
      {
        id: "order_no",
        name: "Sequence Number",
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
