import { useState } from "react";
import { useParams, withRouter } from "react-router-dom";

import Page from "components/ubold/organisms/Page";
import Form from "components/ubold/organisms/Form";

import CVImageUpload from "components/ubold/molecules/forms/CVImageUpload";
import CVTextField from "components/ubold/molecules/forms/CVTextField";
import CVCheckBox from "components/ubold/molecules/forms/CVCheckBox";

import definitions from "./definitions";

function HomepageHeaderAddEditPage() {
  let { id }: { id: string } = useParams();
  const initialState = {
    published: true,
  };

  const [state, setState] = useState(initialState);

  return (
    <Page
      title={(!id ? "Add New" : "Edit") + " " + definitions.title}
      restAccessCode={definitions.restAccessCode}
    >
      <Form
        id={id}
        definitions={definitions}
        state={state}
        setState={setState}
      >
        <CVImageUpload
          alertFileSize="500Kb"
          name="mobile_screen_original"
          title={"Banner Mobile Screen (400 x 380px (150 dpi))"}
          helperText=".jpg, .jpeg, .png, Max 500kb"
          isRequired
          maxSize={500000}
        />
        <CVImageUpload
          alertFileSize="700Kb"
          name="tablet_screen_original"
          title={"Banner Tablet Screen (800 x 380px (150 dpi))"}
          helperText=".jpg, .jpeg, .png, Max 700kb"
          isRequired
          maxSize={700000}
        />
        <CVImageUpload
          alertFileSize="1MB"
          name="desktop_screen_original"
          title={"Banner Desktop Screen (1170 x 380px (150 dpi))"}
          helperText=".jpg, .jpeg, .png, Max 1Mb"
          isRequired
          maxSize={1000000}
        />
        <CVTextField
          name="url"
          title="URL"
          isRequired
          helperText="input start with http:// or https:// (case sensitive)"
        />
        <CVTextField
          min={0}
          name="order"
          title="Order"
          type="number"
          isRequired
        />
        <CVCheckBox name="published" title="Published Flag" />
      </Form>
    </Page>
  );
}

export default withRouter(HomepageHeaderAddEditPage);
