import configBuilder from "components/ubold/ConfigBuilder";

import pageDefinitions from "../definitions";

const definitions = {
  ...pageDefinitions,
};

export default configBuilder.buildAddEditPageConfig(definitions, [
  "icon_original",
]);
