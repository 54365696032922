import utils from "../../../../utils";

import configBuilder from "../../../../components/ubold/ConfigBuilder";

import pageDefinitions from "../definitions";

const definitions = {
  ...pageDefinitions,
  tabs: [
    {
      id: "user",
      name: "User",
    },
    {
      id: "t_roles",
      name: "Roles",
    },
  ],
  validators: {
    first_name: (value: string) => {
      if (value && !/^[a-zA-Z'\-]+$/.test(value)) {
        return "This is not valid name format.";
      } else {
        return utils.validator.validateRequired(value);
      }
    },
    last_name: (value: string) => {
      if (value && !/^[a-zA-Z'\-]+$/.test(value)) {
        return "This is not valid name format.";
      } else {
        return utils.validator.validateRequired(value);
      }
    },
    username: (value: string) => {
      if (value && !/^[a-zA-Z0-9.@+_-]+$/.test(value)) {
        return "This is not valid username format.";
      } else {
        return utils.validator.validateRequired(value);
      }
    },
    email: (value: string) => {
      if (
        value &&
        !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          value
        )
      ) {
        return "This is not valid email.";
      } else {
        return utils.validator.validateRequired(value);
      }
    },
    password: (value: string, state: any) => {
      if (!state.id && !value) {
        return "Password should be mandatory when adding new user.";
      } else if (value && value.length < 8) {
        return "Min password length is 8 characters";
      } else if (value && /^\d+$/.test(value)) {
        return "Password can't be all digit characters";
      }

      return true;
    },
    password_confirm: (value: string, state: any) => {
      if (
        state.password &&
        state.password !== "" &&
        state.password !== value
      ) {
        return "Confirm password should be the same with password. Value is not same.";
      }

      return true;
    },
  },
};

export default configBuilder.buildAddEditPageConfig(
  definitions,
  [],
  ["password_confirm", "groups", "user_permissions"]
);
