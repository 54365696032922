import configBuilder from "../../../components/ubold/ConfigBuilder";

import utils from "../../../utils";

let definitions = {
  title: "Building Landlords",
  frontendPath: "/landlord/building_landlord",
  baseLink: "/building/landlord",
  restAccessCode: "building.landlord",
  __table__: {
    filterColumn: 4,
    hideEditButton: true,
    filters: [
      {
        id: "name",
        name: "Name",
        type: "text",
      },
      {
        id: "email",
        name: "Email",
        type: "text",
      },
      {
        id: "phone_number",
        name: "Phone Number",
        type: "number",
      },
      {
        id: "date_created",
        name: "Date Created",
        type: "date",
      },
    ],
    columns: [
      {
        id: "name",
        name: "Name",
        enableHyperlink: true,
      },
      {
        id: "email",
        name: "Email",
      },
      {
        id: "phone_number",
        name: "Phone Number",
      },
      {
        id: "date_created",
        name: "Date Created",
        renderer: (value) => {
          return utils.formatter.dateTime(value);
        },
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
