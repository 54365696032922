import { withRouter } from "react-router-dom";

function CCheckBox(props) {
  const {
    name,
    onChange,
    state,
    setState,
    readOnly,
    disabled = false,
  } = props;

  return (
    <input
      id={name}
      name={name}
      checked={state[name] ?? false}
      type="checkbox"
      disabled={disabled}
      readOnly={readOnly}
      onChange={
        onChange
          ? onChange
          : () => {
              setState((prevState) => ({
                ...prevState,
                [name]: !state[name],
              }));
            }
      }
    />
  );
}

export default withRouter(CCheckBox);
