import utils from "utils";

const commons = {
  isFunction: (functionToCheck) => {
    return (
      functionToCheck &&
      {}.toString.call(functionToCheck) === "[object Function]"
    );
  },
  isArray: (instance) => {
    return instance instanceof Array;
  },
  isHTML: RegExp.prototype.test.bind(/(<([^>]+)>)/i),
  isObject: (instance) => {
    return typeof instance === "object" && instance !== null;
  },
  isString: (instance) => {
    return typeof instance === "string";
  },
  isJSON: (strOrObject) => {
    try {
      if (
        commons.isObject(strOrObject) ||
        commons.isArray(strOrObject)
      ) {
        return true;
      } else {
        return (
          JSON.parse(
            strOrObject
              .replaceAll("['", '["')
              .replaceAll("']", '"]')
              .replaceAll("{'", '{"')
              .replaceAll("'}", '"}')
              .replaceAll("':", '":')
          ) && !!strOrObject
        );
      }
    } catch (e) {
      return false;
    }
  },
  valueExists: (arrayObjects, key, searchedValue) => {
    return arrayObjects.some(function (el) {
      return el[key] === searchedValue;
    });
  },
  createPagination: (c, m) => {
    let current = c,
      last = m,
      delta = 2,
      left = current - delta,
      right = current + delta + 1,
      range = [],
      rangeWithDots = [],
      l;

    for (let i = 1; i <= last; i++) {
      if (i === 1 || i === last || (i >= left && i < right)) {
        range.push(i);
      }
    }

    for (let i of range) {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push(l + 1);
        } else if (i - l !== 1) {
          rangeWithDots.push("...");
        }
      }
      rangeWithDots.push(i);
      l = i;
    }

    return rangeWithDots;
  },
  hasAccess: (path, accesses) => {
    let hasAccess = false;

    if (accesses) {
      for (let i = 0; i < accesses.length; i++) {
        if (
          path &&
          accesses[i]["is_exact_link"] &&
          path === accesses[i]["link"]
        ) {
          hasAccess = true;

          break;
        } else if (
          path &&
          !accesses[i]["is_exact_link"] &&
          path.startsWith(accesses[i]["link"])
        ) {
          hasAccess = true;

          break;
        }
      }
    } else {
      return true;
    }

    return hasAccess;
  },

  getTopAccessibleMenu: (accesses) => {
    let topMenuAccessible =
      accesses.length > 0 ? accesses[0]["link"] : "/u/home";

    if (
      topMenuAccessible === null ||
      topMenuAccessible === undefined
    ) {
      topMenuAccessible =
        accesses.length > 0 ? accesses[0]["link"] : "/u/home";
    }

    topMenuAccessible = "/u/home";

    return topMenuAccessible;
  },
  stripMultipleSlashes: (text) => {
    return text.replace(/\/+/g, "/");
  },
  stripErrorFieldFilter: (state) => {
    const cleanedState = state;
    for (const [key] of Object.entries(cleanedState)) {
      if (key.indexOf("Error") !== -1) {
        delete cleanedState[key];
      }
    }
    return cleanedState;
  },
  toTitleCase: (str) => {
    return str.replace(/\w\S*/g, function (txt) {
      return (
        txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
      );
    });
  },
  createPaginationObj: (pageCount) => {
    let opts = {};
    for (let i = 1; i < pageCount + 1; i++) {
      opts[i] = {
        label: `${i}`,
        value: i,
      };
    }
    const objToArr = (obj) => Object.assign([], Object.values(obj));
    return objToArr(opts);
  },
  removeItems: (array, itemToRemove) => {
    return array?.filter((val) => {
      return !itemToRemove.includes(val.value);
    });
  },
  subsetVal: (arrObj) => {
    if (!arrObj) return;
    const newSet = arrObj.map(({ value }) => value);
    return newSet;
  },
  checkRequiredField: (targetState, targetStateColumns) => {
    if (!targetState || !targetStateColumns) return;
    for (let i = 0; i < targetState.length; i++) {
      for (let j = 0; j < targetStateColumns.length; j++) {
        if (
          targetStateColumns[j].isRequired &&
          utils.form.isEmpty(
            targetState[i][targetStateColumns[j].name]
          )
        ) {
          targetState[i][targetStateColumns[j].name + "Error"] =
            "This field is required";
        } else {
          delete targetState[i][targetStateColumns[j].name + "Error"];
        }
      }
    }
    return targetState;
  },
  checkErrorField: (state, stateColumns) => {
    let hasError = false;
    if (state.length === 0) return;
    for (let i = 0; i < state.length; i++) {
      for (let j = 0; j < stateColumns.length; j++) {
        if (state[i][stateColumns[j].name + "Error"]) {
          hasError = true;
          break;
        }
      }
    }
    return hasError;
  },
  filterStateObjByProperty: (state, filteredKey, reversed) => {
    let filteredState = [];
    if (!reversed) {
      filteredState = state.filter(
        (filteredState) => !filteredState.hasOwnProperty(filteredKey)
      );
    } else {
      filteredState = state.filter((filteredState) =>
        filteredState.hasOwnProperty(filteredKey)
      );
    }
    return filteredState;
  },
  debounce: (func, timeout = 1000) => {
    /**
     * how to use debounce
     * debounce(functionName, timeout)(functionParams)
     * @param func to passing the function that want to be debounced
     * @param timeout (Optional) how long you want to delay the function before triggered
     */
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  },
  cloneState: (
    currentState,
    removedDuplicateFields,
    replaceValueWhenDuplicate
  ) => {
    let newState = { ...currentState };

    for (let i = 0; i <= removedDuplicateFields.length; i++) {
      delete newState[removedDuplicateFields[i]];
    }

    for (let key of Object.keys(newState)) {
      if (key.startsWith("original__")) {
        delete newState[key];
      }
    }

    for (let j = 0; j <= replaceValueWhenDuplicate.length; j++) {
      newState[replaceValueWhenDuplicate[j]?.name] =
        replaceValueWhenDuplicate[j]?.value;
    }

    newState["info"] =
      "This is order extension data, please save this for data to be stored in our system";

    newState["isDuplicate"] = true;

    return newState;
  },
};

export default commons;
