import styled, { css } from "styled-components/macro";

export const ButtonPrimary = styled.button`
  display: inline-block;

  background-color: #00bbb4;
  border: unset;
  border-radius: 4px;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  padding: 8px 12px;

  &:active {
    background-color: #063735;
  }

  &:disabled {
    background-color: rgba(0, 187, 180, 0.2);
    color: #fff;
  }

  &:focus-visible {
    background-color: #009c96;
    outline: 3px solid rgba(0, 187, 180, 0.2);
  }

  &:not(:disabled) {
    &:hover {
      background-color: #009c96;
    }
  }
`;

export const ButtonSecondary = styled.button`
  display: inline-block;

  background-color: #fff;
  border: 1px solid #00bbb4;
  border-radius: 4px;
  color: #00bbb4;
  font-size: 12px;
  font-weight: 500;
  padding: 8px 12px;

  &:active {
    background-color: #063735;
  }

  &:disabled {
    border: 1px solid rgba(0, 187, 180, 0.2);
    color: rgba(0, 187, 180, 0.2);
  }

  &:focus-visible {
    border: 1px solid #009c96;
    color: #009c96;
    outline: 3px solid rgba(0, 187, 180, 0.2);
  }

  &:hover {
    border: 1px solid #009c96;
    color: #009c96;
  }
`;

export const BorderBar = styled.hr<{ margin?: string }>`
  border-top: 1px solid #e7e7e7;
  margin: ${({ margin = "unset" }) => margin};
`;

export const VFormGroup = styled.div<{
  columns?: string;
  gap?: number;
  spacing?: {
    top?: number;
    right?: number;
    bottom?: number;
    left?: number;
  };
}>`
  display: grid;
  grid-template-columns: ${({ columns = "147px 1fr" }) => columns};
  grid-column-gap: ${({ gap = 8 }) => gap}px;

  ${({ spacing }) => {
    // Defined like this, because if we assign default value for prop, it's always return react-dom.development.js:11340 Uncaught TypeError: Cannot read properties of undefined (reading 'top')
    const top = spacing?.top ? spacing?.top : 0;
    const right = spacing?.right ? spacing?.right : 0;
    const bottom = spacing?.bottom ? spacing?.bottom : 0;
    const left = spacing?.left ? spacing?.left : 0;

    return css`
      margin: ${top * 4}px ${right * 4}px ${bottom * 4}px
        ${left * 4}px;
    `;
  }}
`;

export const LabelFormSRegular = styled.label<{ color?: string }>`
  color: ${({ color = "#222222" }) => color};
  font-size: 12px;
  font-weight: 400;
  margin-bottom: unset;
`;

export const LabelSRegular = styled.span<{ color?: string }>`
  color: ${({ color = "#222222" }) => color};
  font-size: 12px;
  font-weight: 400;
`;

export const LabelMBold = styled.span<{ color?: string }>`
  color: ${({ color = "#222222" }) => color};
  font-size: 14px;
  font-weight: 600;
`;

export const LabelMRegular = styled.span<{ color?: string }>`
  color: ${({ color = "#222222" }) => color};
  font-size: 14px;
  font-weight: 400;
`;

export const LabelLMedium = styled.b`
  color: ${({ color = "#222222" }) => color};
  font-size: 16px;
  font-weight: 500;
`;
