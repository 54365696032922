import { useState } from "react";
import { useParams, withRouter } from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import Form from "../../../../components/ubold/organisms/Form";

import CVAsyncSelect from "../../../../components/ubold/molecules/forms/CVAsyncSelect";
import CVTextField from "../../../../components/ubold/molecules/forms/CVTextField";

import definitions from "./definitions";

function DiscountViewPage() {
  let { id }: any = useParams();

  const initialState = {};

  const [state, setState] = useState(initialState);

  return (
    <Page
      title={"Detail of" + definitions.title}
      restAccessCode={definitions.restAccessCode}
    >
      <Form
        id={id}
        definitions={definitions}
        state={state}
        setState={setState}
        readOnly={true}
      >
        <CVAsyncSelect
          title="Landlord"
          name="landlord"
          overrideLayout="12"
          data="building/landlord?__type__=select_entries"
          isRequired
          readOnly
        />
        <CVTextField
          name="username"
          title="Username"
          password={100}
          overrideLayout="12"
          readOnly
        />
        <CVTextField
          name="email"
          title="Email"
          isRequired
          password={254}
          overrideLayout="12"
          readOnly
        />
        <CVTextField
          name="password"
          title="Password"
          type="password"
          autoComplete="new-password"
          isRequired
          password={50}
          overrideLayout="12"
          helperText="Your password can't be too similar to your other personal information.
                    <br/>Your password must contain at least 8 characters.
                    <br/>Your password can't be entirely numeric."
          readOnly
        />
        <CVTextField
          name="password_confirm"
          title="Confirm Password"
          type="password"
          autoComplete="new-password"
          overrideLayout="12"
          isRequired
          password={50}
          helperText="Enter the same password as before, for verification."
          readOnly
        />
      </Form>
    </Page>
  );
}

export default withRouter(DiscountViewPage);
