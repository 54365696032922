import { withRouter } from "react-router-dom";
import AccordionPane from "components/ubold/organisms/AccordionPane";
import CHSelect from "components/ubold/molecules/forms/CHSelect";
import CHPhoneNumber from "components/ubold/molecules/forms/CHPhoneNumber";
import CHTextField from "components/ubold/molecules/forms/CHTextField";
import { sectionProps } from "@types";
import useGlobalState from "state";

function EmergencyContact(props: sectionProps) {
  const { baseLink, accordionId, onSubmit, state, setState } = props;
  const [constants] = useGlobalState("constant_values");

  return (
    <AccordionPane
      id="emergencyContact"
      name="Emergency Contact"
      accordionId={accordionId}
      baseLink={baseLink}
      onSubmit={onSubmit}
      state={state}
      setState={setState}
    >
      <CHPhoneNumber
        title="Emergency Phone Number"
        countryCodeName="country_code"
        phoneNumberName="emergency_phone_number"
        isRequired
        readOnly
      />
      <CHSelect
        name="emergency_relation"
        title="Emergency Relations"
        data={constants?.["user_tenant_emergency_relation"]}
        isRequired
        readOnly
      />
      <CHTextField
        name="emergency_name"
        title="Name"
        isRequired
        readOnly
      />
    </AccordionPane>
  );
}

export default withRouter(EmergencyContact);
