import { withRouter } from "react-router-dom";

import CTextField from "../../atoms/forms/CTextField";
import CCheckBox from "../../atoms/forms/CCheckBox";

import useGlobalState from "../../../../state";

import utils from "../../../../utils";

function CHParkingCapacity(props) {
  const {
    carName,
    motorBikeName,
    // rukitaOptionName,
    enableOnlinePayment,
    title,
    isRequired = false,
    restAccessCode,
    state,
    setState,
    readOnly,
    disabled = false,
    name,
  } = props;

  const [user] = useGlobalState("user");
  const isSuperUser = user ? user.is_super_user : false;
  const [fieldAccesses] = useGlobalState("field_accesses");
  const [fieldAccessReversePolicy] = useGlobalState(
    "field_access_reverse_policy"
  );

  return (
    <div
      className="form-group row mb-3"
      style={{
        display:
          !isRequired &&
          restAccessCode &&
          utils.access.isFieldHidden(
            fieldAccesses,
            restAccessCode + "." + name
          )
            ? "none"
            : "flex",
      }}
    >
      <label
        className={
          "col-md-3 col-form-label" +
          (isRequired ? " font-weight-bold" : "")
        }
      >
        {title}
        {isRequired ? "*" : ""}{" "}
        {readOnly ??
        utils.access.isFieldReadOnly(
          fieldAccesses,
          restAccessCode + "." + name,
          fieldAccessReversePolicy,
          isSuperUser
        )
          ? "(read only)"
          : ""}
      </label>
      <div className="col-md-9">
        <div className="d-flex">
          <div className="flex-fill">
            <label className="col-form-label">Car</label>
            <CTextField
              type="number"
              name={carName}
              readOnly={
                readOnly ??
                (restAccessCode &&
                  utils.access.isFieldReadOnly(
                    fieldAccesses,
                    restAccessCode + "." + name,
                    fieldAccessReversePolicy,
                    isSuperUser
                  ))
              }
              state={state}
              setState={setState}
            />
          </div>
          <div className="flex-fill pl-4">
            <label className="col-form-label">Motorbike</label>
            <CTextField
              type="number"
              name={motorBikeName}
              readOnly={
                readOnly ??
                (restAccessCode &&
                  utils.access.isFieldReadOnly(
                    fieldAccesses,
                    restAccessCode + "." + name,
                    fieldAccessReversePolicy,
                    isSuperUser
                  ))
              }
              state={state}
              setState={setState}
            />
          </div>
        </div>
        <div className="d-flex mt-3">
          {/* Uncomment when its necessary */}
          {/* <div className="checkbox checkbox-success h-100 d-flex align-items-center px-1 mr-4">
            <CCheckBox
              name={rukitaOptionName}
              state={state}
              readOnly={
                readOnly ||
                (restAccessCode &&
                  utils.access.isFieldReadOnly(
                    fieldAccesses,
                    restAccessCode + "." + name
                  ))
              }
              setState={setState}
              disabled={disabled}
            />
            <label htmlFor={rukitaOptionName}>Rukita Options</label>
          </div> */}
          <div className="checkbox checkbox-success h-100 d-flex align-items-center px-1">
            <CCheckBox
              name={enableOnlinePayment}
              state={state}
              readOnly={
                readOnly ??
                (restAccessCode &&
                  utils.access.isFieldReadOnly(
                    fieldAccesses,
                    restAccessCode + "." + name,
                    fieldAccessReversePolicy,
                    isSuperUser
                  ))
              }
              setState={setState}
              disabled={disabled}
            />
            <label htmlFor={enableOnlinePayment}>
              Enable Online Payment
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(CHParkingCapacity);
