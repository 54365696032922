import { useState, useEffect } from "react";

import { Link, useParams, withRouter } from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import definitions from "./definitions";
import html2canvas from "html2canvas";
import RoomQR from "./RoomQR";

function RoomPrintQR() {
  let { id } = useParams();

  const initialState = {
    is_available: true,
    price: 0,
    electricity: 1,
    bathroom: 1,
    gender: 0,
    room_status: 10,
    t_room_service_schedule: [
      {
        laundry_schedule: [],
        cleaning_schedule: [],
      },
    ],
  };

  const [state, setState] = useState(initialState);

  // Get building data
  useEffect(() => {
    definitions.onInit(id, setState);
  }, []);

  const download4RCanvas = async () => {
    const element = document.getElementById("download-4r"),
      canvas = await html2canvas(element),
      data = canvas.toDataURL("image/jpg"),
      link = document.createElement("a");

    link.href = data;
    link.download = `QR-${state.building_name}-Room-${state.name}.png`;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const printUrl =
    "/print?building_name=" +
    state.building_name +
    "&room_variant=" +
    state.room_variant +
    "&name=" +
    state.name +
    "&building=" +
    state.building +
    "&external_id=" +
    state.external_id +
    "&type=ROOM";

  return (
    <Page
      title={"Room Print QR"}
      restAccessCode={definitions.restAccessCode}
    >
      <div style={styles.previewContainer}>
        <RoomQR roomData={state} type="PREVIEW" />
        <RoomQR roomData={state} type="DOWNLOAD" />

        <div style={styles.buttonContainer}>
          <div
            onClick={download4RCanvas}
            style={{ ...styles.button, marginRight: 12 }}
          >
            Download
          </div>
          <Link
            to={printUrl}
            target="_blank"
            rel="noopener noreferrer"
            style={styles.button}
          >
            Print
          </Link>
        </div>
      </div>
    </Page>
  );
}

export default withRouter(RoomPrintQR);

const styles = {
  previewContainer: {
    backgroundColor: "#FFF",
    padding: 20,
    margin: "20px 0",
    width: "fit-content",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  button: {
    cursor: "pointer",
    color: "#FFF",
    border: "1px solid rgb(26, 188, 156)",
    backgroundColor: "rgb(26, 188, 156)",
    width: "fit-content",
    padding: "4px 12px",
    borderRadius: 4,
    fontWeight: 500,
    marginTop: 24,
  },
};
