import { withRouter } from "react-router-dom";

import Page from "../../../components/ubold/organisms/Page";

import TableView from "../../../components/ubold/organisms/TableView";

import definitions from "./definitions";

function TeamMemberPage() {
    return (
        <Page title={definitions.title}>
            <TableView {...definitions.table} isTeamMenu={definitions.isTeamMenu} />
        </Page>
    );
}

export default withRouter(TeamMemberPage);