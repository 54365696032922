import React, { MouseEventHandler } from "react";

import styled from "styled-components";

const Failed: React.FC<{
  className?: string;
  messages?: React.ReactElement;
  onClose: MouseEventHandler<HTMLButtonElement>;
}> = ({ className, messages, onClose }) => {
  return (
    <FailedStyled className={className}>
      <img
        height={90}
        width={160}
        alt="Fail Illustration"
        src={
          process.env.PUBLIC_URL +
          "/assets/images/modal/export/Export_Fail.svg"
        }
      />

      <h5>
        Request can’t be processed, your email is yet registered
      </h5>

      {messages ? (
        messages
      ) : (
        <p>
          Please contact Product/Tech team to add your email to your
          account. Then, export request will be sent to your email.
        </p>
      )}

      <button onClick={onClose}>OK</button>
    </FailedStyled>
  );
};

const FailedStyled = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  button {
    height: 36px;
    width: 76px;

    background-color: #35b0a7;
    border: unset;
    border-radius: 2px;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    padding: 8px 28.5px;
  }

  h5,
  p {
    text-align: center;
  }

  h5 {
    font-size: 16px;
    font-weight: 500;
    margin-top: unset;
    margin-bottom: 16px;
  }

  img {
    margin-bottom: 30px;
  }

  p {
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 30px;
  }

  strong {
    color: #000;
    font-weight: 500;
  }
`;

export default Failed;
