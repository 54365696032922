// TENANT INVOICES
import Invoice from "pages/Finance/Invoice";
import InvoiceAddEdit from "pages/Finance/Invoice/AddEdit";
import InvoiceView from "pages/Finance/Invoice/ViewOnly";
import InvoiceHistory from "pages/Finance/Invoice/History";
// TENANT INVOICES SOP
import InvoiceSOP from "pages/Finance/TenantInvoiceSOP";
import InvoiceSOPAddEdit from "pages/Finance/TenantInvoiceSOP/AddEdit";
import InvoiceSOPView from "pages/Finance/TenantInvoiceSOP/ViewOnly";
import InvoiceSOPHistory from "pages/Finance/TenantInvoiceSOP/History";
// ONE TIME PURCHASE INVOICES
import OTPInvoices from "pages/OneTimePurchase/Finance/";
import OTPInvoiceView from "pages/OneTimePurchase/Finance/Detail";
// LANDLORD INVOICES
import LandlordInvoice from "pages/Finance/LandlordInvoice";
import LandlordInvoiceView from "pages/Finance/LandlordInvoice/Detail";
// LANDLORD INVOICES SOP
import LandlordInvoiceSOP from "pages/Finance/LandlordInvoiceNewSOP/LandlordInvoiceList";
import LandlordInvoiceSOPView from "pages/Finance/LandlordInvoiceNewSOP/ViewOnly/LandlordInvoiceView";
import LandlordInvoiceSOPAddEdit from "pages/Finance/LandlordInvoiceNewSOP/AddEdit/LandlordInvoiceView";
import LandlordInvoiceHistory from "pages/Finance/LandlordInvoiceNewSOP/History/LandlordInvoiceHistorySOP";
// OUTSTANDING INVOICE
import OutstandingInvoice from "pages/Finance/OutstandingInvoice/OutstandingInvoice";
import OutstandingInvoiceAddEditPage from "pages/Finance/OutstandingInvoice/AddEdit/OutstandingInvoiceAddEditPage";
import OutstandingInvoiceViewPage from "pages/Finance/OutstandingInvoice/ViewOnly/OutstandingInvoiceViewPage";
import OutstandingTenantInvoiceHistoryPage from "pages/Finance/OutstandingInvoice/History/OutstandingTenantInvoiceHistoryPage";

const FinanceRoutes = [
  // TENANT INVOICES
  {
    path: "/finance/tenant_invoice",
    component: Invoice,
  },
  {
    path: "/finance/tenant_invoice/(add|edit)/:id([0-9]+)?",
    component: InvoiceAddEdit,
  },
  {
    path: "/finance/tenant_invoice/view/:id([0-9]+)?",
    component: InvoiceView,
  },
  {
    path: "/finance/tenant_invoice/history/:id([0-9]+)?",
    component: InvoiceHistory,
  },
  // TENANT INVOICES SOP
  {
    path: "/finance/tenant_invoice_sop",
    component: InvoiceSOP,
  },
  {
    path: "/finance/tenant_invoice_sop/(add|edit)/:id([0-9]+)?",
    component: InvoiceSOPAddEdit,
  },
  {
    path: "/finance/tenant_invoice_sop/view/:id([0-9]+)?",
    component: InvoiceSOPView,
  },
  {
    path: "/finance/tenant_invoice_sop/history/:id([0-9]+)?",
    component: InvoiceSOPHistory,
  },
  // ONE TIME PURCHASE INVOICES
  {
    path: "/finance/onetimepurchase_invoice",
    component: OTPInvoices,
  },
  {
    path: "/finance/onetimepurchase_invoice/view/:id([0-9]+)?",
    component: OTPInvoiceView,
  },
  // LANDLORD INVOICES
  {
    path: "/finance/landlord_invoice",
    component: LandlordInvoice,
  },
  {
    path: "/finance/landlord_invoice/view/:id([0-9]+)?",
    component: LandlordInvoiceView,
  },
  // LANDLORD INVOICES SOP
  {
    path: "/finance/landlord_invoice_sop",
    component: LandlordInvoiceSOP,
  },
  {
    path: "/finance/landlord_invoice_sop/view/:id([0-9]+)?",
    component: LandlordInvoiceSOPView,
  },
  {
    path: "/finance/landlord_invoice_sop/(add|edit)/:id([0-9]+)?",
    component: LandlordInvoiceSOPAddEdit,
  },
  {
    path: "/finance/landlord_invoice_sop/history/:id([0-9]+)?",
    component: LandlordInvoiceHistory,
  },
  // OUTSTANDING INVOICES
  {
    path: "/finance/invoice_outstanding",
    component: OutstandingInvoice,
  },
  {
    path: "/finance/invoice_outstanding/(add|edit)/:id([0-9]+)?",
    component: OutstandingInvoiceAddEditPage,
  },
  {
    path: "/finance/invoice_outstanding/view/:id([0-9]+)?",
    component: OutstandingInvoiceViewPage,
  },
  {
    path: "/finance/invoice_outstanding/history/:id([0-9]+)?",
    component: OutstandingTenantInvoiceHistoryPage,
  },
];

export default FinanceRoutes;
