import configBuilder from "../../../components/ubold/ConfigBuilder";

import secondaryDataHandler from "./SecondaryDataHandler";

import utils from "../../../utils";

const otpPaymentMethod = [
  {
    label: "Cash",
    value: 1,
  },
  {
    label: "Transfer",
    value: 2,
  },
  {
    label: "Virtual Account",
    value: 3,
  },
];

const otpStatus = [
  {
    label: "Waiting for Payment",
    value: 1,
  },
  {
    label: "Proceed",
    value: 2,
  },
  {
    label: "Done",
    value: 3,
  },
  {
    label: "Cancel",
    value: 4,
  },
];

let definitions = {
  title: "One Time Purchase Invoices",
  frontendPath: "/finance/onetimepurchase_invoice",
  baseLink: "/one_time_purchase/invoice",
  restAccessCode: "one_time_purchase.invoice",

  __table__: {
    addButton: false,
    hideEditButton: true,
    hideRemoveButton: true,
    filterColumn: 3,
    hasSecondaryData: true,
    isAddButtonDisabled: true,
    isHideRemoveButton: true,
    secondaryDataItem: 2,
    secondaryDataHandler: secondaryDataHandler,
    filters: [
      {
        id: "tenant_name",
        name: "Tenant",
        type: "text",
      },
      {
        id: "number",
        name: "Invoice",
        type: "text",
      },
      {
        id: "otp_status",
        name: "Status",
        type: "select",
        data: otpStatus,
      },
      {
        id: "payment_method",
        name: "Payment Method",
        type: "select",
        data: otpPaymentMethod,
      },
    ],

    columns: [
      {
        id: "order_text",
        name: "Order",
        enableHyperlink: true,
        forceView: true,
      },
      {
        id: "number",
        name: "Number",
      },
      {
        id: "otp_status_text",
        name: "Status",
      },
      {
        id: "grand_total",
        name: "Grand Total",
        renderer: (value) => {
          return utils.formatter.currency(value);
        },
      },
      {
        id: "payment_method_text",
        name: "Payment Method",
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
