import styled, {
  css,
  FlattenSimpleInterpolation,
} from "styled-components";

export type FlagTheme =
  | "BLACK"
  | "BLUE"
  | "GREEN"
  | "GREEN_DARK"
  | "PURPLE"
  | "RED"
  | "GOLD"
  | "YELLOW";

const generateBorderAndColor = (
  color: string,
  theme: FlagTheme
): FlattenSimpleInterpolation => {
  switch (theme) {
    case "BLACK":
      return css`
        border: 1px solid ${color && !theme ? color : "#222222"};
        color: ${color && !theme ? color : "#222222"};
      `;

    case "BLUE":
      return css`
        border: 1px solid ${color && !theme ? color : "#2A7DE1"};
        color: ${color && !theme ? color : "#2A7DE1"};
      `;

    case "GREEN":
      return css`
        border: 1px solid ${color && !theme ? color : "#098908"};
        color: ${color && !theme ? color : "#098908"};
      `;

    case "GREEN_DARK":
      return css`
        border: 1px solid ${color && !theme ? color : "#065205"};
        color: ${color && !theme ? color : "#065205"};
      `;

    case "PURPLE":
      return css`
        border: 1px solid ${color && !theme ? color : "#7E57C5"};
        color: ${color && !theme ? color : "#7E57C5"};
      `;

    case "RED":
      return css`
        border: 1px solid ${color && !theme ? color : "#D00C1A"};
        color: ${color && !theme ? color : "#D00C1A"};
      `;

    case "GOLD":
      return css`
        border: 1px solid ${color && !theme ? color : "#CAA005"};

        color: ${color && !theme ? color : "#CAA005"};
        color: ${color && !theme ? color : "#D00C1A"};
      `;

    case "YELLOW":
      return css`
        border: 1px solid #caa005;
        color: #caa005;
      `;

    default:
      return css`
        border: 1px solid ${color && !theme ? color : "#222222"};
        color: ${color && !theme ? color : "#222222"};
      `;
  }
};

export const BulletSymbol = styled.span`
  height: 4px;
  width: 4px;

  display: inline-block;

  border-radius: 100%;
  margin-right: 4px;

  ${({ color, theme }) => {
    switch (theme) {
      case "BLACK":
        return css`
          background-color: ${color && !theme ? color : "#222222"};
        `;

      case "BLUE":
        return css`
          background-color: ${color && !theme ? color : "#2A7DE1"};
        `;

      case "GREEN":
        return css`
          background-color: ${color && !theme ? color : "#098908"};
        `;

      case "GREEN_DARK":
        return css`
          background-color: ${color && !theme ? color : "#065205"};
        `;

      case "PURPLE":
        return css`
          background-color: ${color && !theme ? color : "#7E57C5"};
        `;

      case "RED":
        return css`
          background-color: ${color && !theme ? color : "#D00C1A"};
        `;

      case "YELLOW":
        return css`
          background-color: ${color && !theme ? color : "#caa005"};
        `;

      case "GOLD":
        return css`
          background-color: ${color && !theme ? color : "#CAA005"};
        `;

      default:
        return css`
          background-color: ${color && !theme ? color : "#222222"};
        `;
    }
  }}
`;

export const FlagSingleModeBase = styled.div<{
  color?: string;
  theme?: FlagTheme;
}>`
  height: 18px;
  display: inline-flex;
  align-items: center;
  border-radius: 22px;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  padding: 0 8px;

  ${({ color, theme }) => generateBorderAndColor(color, theme)}
`;

export const FlagDualModeBase = styled.div<{
  color?: string;
  directon: "left" | "right";
  theme?: FlagTheme;
}>`
  height: 18px;
  display: inline-flex;
  align-items: center;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  padding: 0 8px;

  ${({ color, theme }) => generateBorderAndColor(color, theme)}

  ${({ directon }) => {
    if (directon === "left") {
      return css`
        border-radius: 22px 0 0 22px;
      `;
    } else {
      return css`
        border-radius: 0 22px 22px 0;
      `;
    }
  }}
`;
