// ORDER
// WILL BE DEPRECATED
import Order from "pages/Order/Order";
import OrderAddEdit from "pages/Order/Order/AddEdit";
import OrderView from "pages/Order/Order/ViewOnly";
import OrderHistory from "pages/Order/Order/History";
// ORDER SOP
import OrderSOP from "pages/Order/OrderNewSOP";
import OrderSOPAddEdit from "pages/Order/OrderNewSOP/AddEdit";
import OrderSOPView from "pages/Order/OrderNewSOP/ViewOnly";
import OrderSOPHistory from "pages/Order/OrderNewSOP/History";
// ADDON ORDERS
import AddonBuildingOrder from "pages/Order/AddonBuildingOrder";
import AddonBuildingOrderAddEdit from "pages/Order/AddonBuildingOrder/AddEdit";
import AddonBuildingOrderView from "pages/Order/AddonBuildingOrder/ViewOnly";
// ONE TIME PURCHASE ORDERS
import OneTimePurchaseOrder from "pages/Sales/OneTimePurchaseOrder";
import OneTimePurchaseOrderAddEdit from "pages/Sales/OneTimePurchaseOrder/AddEdit";
import OneTimePurchaseOrderView from "pages/Sales/OneTimePurchaseOrder/ViewOnly";

const SalesRoutes = [
  // ORDER
  // WILL BE DEPRECATED
  {
    path: "/sales/order",
    component: Order,
  },
  {
    path: "/sales/order/(add|edit)/:id([0-9]+)?",
    component: OrderAddEdit,
  },
  {
    path: "/sales/order/view/:id([0-9]+)?",
    component: OrderView,
  },
  {
    path: "/sales/order/history/:id([0-9]+)?",
    component: OrderHistory,
  },
  // ORDER SOP
  {
    path: "/sales/order_sop",
    component: OrderSOP,
  },
  {
    path: "/sales/order_sop/(add|edit)/:id([0-9]+)?",
    component: OrderSOPAddEdit,
  },
  {
    path: "/sales/order_sop/view/:id([0-9]+)?",
    component: OrderSOPView,
  },
  {
    path: "/sales/order_sop/history/:id([0-9]+)?",
    component: OrderSOPHistory,
  },
  // ADDON ORDERS
  {
    path: "/sales/addon_building_order",
    component: AddonBuildingOrder,
  },
  {
    path: "/sales/addon_building_order/(add|edit)/:id([0-9]+)?",
    component: AddonBuildingOrderAddEdit,
  },
  {
    path: "/sales/addon_building_order/view/:id([0-9]+)?",
    component: AddonBuildingOrderView,
  },
  // ONE TIME PURCHASE ORDERS
  {
    path: "/sales/onetimepurchase",
    component: OneTimePurchaseOrder,
  },
  {
    path: "/sales/onetimepurchase/(add|edit)/:id([0-9]+)?",
    component: OneTimePurchaseOrderAddEdit,
  },
  {
    path: "/sales/onetimepurchase/view/:id([0-9]+)?",
    component: OneTimePurchaseOrderView,
  },
];

export default SalesRoutes;
