import { withRouter } from "react-router";
import TabPane from "../../../../../../components/ubold/organisms/TabPane";
import TabPaneSection from "../../../../../../components/ubold/organisms/TabPaneSection";
import CHSelect from "../../../../../../components/ubold/molecules/forms/CHSelect";
import CHTextField from "../../../../../../components/ubold/molecules/forms/CHTextField";
import CHRichTextField from "../../../../../../components/ubold/molecules/forms/CHRichTextField";
import CHSimpleManyToMany from "../../../../../../components/ubold/molecules/forms/CHSimpleManyToMany";
import { genericTabProps } from "@types";
const Product = (props: genericTabProps<Object>) => {
  const { id, definitions, tab, state, setState } = props;

  return (
    <TabPane
      id={id}
      definitions={definitions}
      tab={tab}
      state={state}
      setState={setState}
      readOnly={true}
    >
      <TabPaneSection style={{ padding: "25px 16px 0px" }}>
        <CHTextField
          name="name"
          title="Name"
          isRequired
          maxLength={150}
          helperText="Required. 150 characters or fewer. Letters, space and -/' only."
          readOnly={true}
        />
        <CHTextField
          name="slug"
          title="Slug"
          isRequired
          maxLength={150}
          helperText="Required. 150 characters or fewer. Letters, space and -/' only."
          readOnly
        />
        <CHRichTextField name="desc" title="Description" readOnly />
        <CHTextField
          name="price"
          title="Price"
          type="number"
          isRequired
          readOnly
        />
        <CHTextField name="sla" title="Sla" type="number" readOnly />
        <CHSelect
          name="condition"
          title="Condition"
          data={[
            {
              label: "New",
              value: 1,
            },
            {
              label: "Second",
              value: 2,
            },
          ]}
          readOnly
        />
        <CHSelect
          name="status"
          title="Status"
          data={[
            {
              label: "Publish",
              value: 1,
            },
            {
              label: "Unpublish",
              value: 2,
            },
          ]}
          readOnly
        />
        <CHSimpleManyToMany
          name="buildings"
          title="Buildings"
          data="building/building?__type__=select_entries&limit=1000"
          itemsExtractor={(row: {
            building_name: string;
            id: string;
          }) => {
            return {
              label: row.building_name,
              value: row.id,
            };
          }}
          readOnly
        />
      </TabPaneSection>
    </TabPane>
  );
};

export default withRouter(Product);
