import { withRouter } from "react-router-dom";

import CSelect from "../../atoms/forms/CSelect";

import useGlobalState from "../../../../state";

import utils from "../../../../utils";

function CHSelect(props) {
  const {
    name,
    read_only_label = null,
    title,
    isRequired = false,
    restAccessCode,
    afterChange,
    state,
    readOnly,
    setState,
    data,
    helperText,
    dependsOn,
    dependsOnHandler,
    itemsExtractor,
    filterItems,
    filterItemsDependencies = [],
    isClearable = true,
  } = props;

  const [user] = useGlobalState("user");
  const isSuperUser = user ? user.is_super_user : false;
  const [fieldAccesses] = useGlobalState("field_accesses");
  const [fieldAccessReversePolicy] = useGlobalState(
    "field_access_reverse_policy"
  );

  return (
    <div
      className="form-group row mb-3"
      style={{
        display:
          !isRequired &&
          restAccessCode &&
          utils.access.isFieldHidden(
            fieldAccesses,
            restAccessCode + "." + name
          )
            ? "none"
            : "flex",
      }}
    >
      <label
        htmlFor="building_type"
        className={
          "col-md-3 col-form-label" +
          (isRequired ? " font-weight-bold" : "") +
          ((state ?? {})?.[name + "Error"] ?? false
            ? " text-danger"
            : "")
        }
      >
        {title}
        {isRequired ? "*" : ""}{" "}
        {readOnly ??
        utils.access.isFieldReadOnly(
          fieldAccesses,
          restAccessCode + "." + name,
          fieldAccessReversePolicy,
          isSuperUser
        )
          ? "(read only)"
          : ""}
      </label>
      {readOnly && read_only_label !== null ? (
        <div style={{ paddingTop: "7px", paddingLeft: "12px" }}>
          {state[read_only_label]}
        </div>
      ) : (
        <div className="col-md-9">
          <CSelect
            name={name}
            readOnly={
              readOnly ??
              (restAccessCode &&
                utils.access.isFieldReadOnly(
                  fieldAccesses,
                  restAccessCode + "." + name,
                  fieldAccessReversePolicy,
                  isSuperUser
                ))
            }
            state={state}
            setState={setState}
            data={data}
            afterChange={afterChange}
            dependsOn={dependsOn}
            dependsOnHandler={dependsOnHandler}
            itemsExtractor={itemsExtractor}
            filterItems={filterItems}
            filterItemsDependencies={filterItemsDependencies}
            isClearable={isClearable}
          />
          {helperText !== "" && helperText !== undefined ? (
            <span className="text-muted font-13 pt-1 mb-0">
              {helperText}
            </span>
          ) : (
            ""
          )}
          {helperText !== "" &&
          helperText !== undefined &&
          ((state ?? {})?.[name + "Error"] ?? false) ? (
            <br />
          ) : (
            <></>
          )}
          {(state ?? {})?.[name + "Error"] ?? false ? (
            <span
              className="text-danger"
              style={{ fontSize: "12px" }}
            >
              {(state ?? {})?.[name + "Error"] ?? false}
            </span>
          ) : (
            <></>
          )}
        </div>
      )}
    </div>
  );
}

export default withRouter(CHSelect);
