import React, { useEffect, useState } from "react";
import moment from "moment";

import { filterTypes } from "@types";

import Flag from "components/ubold/atoms/Flag/Flag";
import { FlagTheme } from "components/ubold/atoms/Flag/Flag.css";
import FilterPanelV2 from "components/ubold/molecules/tables/FilterPanelV2";
import ModalDetail, {
  DataModalDetailObjectType,
} from "components/ubold/molecules/modal/RoomAllotment/ModalDetail";
import { ModalDetailCSSDefault } from "components/ubold/molecules/modal/RoomAllotment/ModalDetail.css";
import RukiModal from "components/ubold/molecules/modal/RukiModal";

import DayOrderSchedule from "./DayOrderSchedule";
import {
  BgStripesBase,
  ButtonDateRangeNavigation,
  ColBookedBySales,
  ColDaysOfSelectedDateRange,
  ColItemDay,
  ColRoom,
  ColStatus,
  Container,
  CurrentDateInformation,
  CurrentSelectedDateRange,
  HeadColItemDay,
  IconDateRangeNavigation,
  LabelLink,
  LegendCategoryLabel,
  LegendsContainer,
  RefreshBtn,
  TableBase,
  TableBaseHeadLevel1,
  TableBaseHeadLevel2,
  TableBody,
  TableBodyRoomVariantItem,
  TableBodyRoomVariantItemBody,
  TableHead,
  TableHeadColBookedBySales,
  TableHeadColDaysOfSelectedDateRange,
  TableHeadColRoom,
  TableHeadColStatus,
  TableHeadRoomVariantItemHeader,
  TableInformation,
  TableRowRoomVariantItem,
  TableStickyInformation,
  TableWhiteContainer,
} from "./TableDashboardRoomAllotment.css";
import TextLegend from "components/ubold/molecules/TextLegend/TextLegend";

import configs from "../../../../configs";
import utils from "../../../../utils";

import {
  IRoom,
  IRoomAllotmentAPIResponse,
  IRowArranged,
  Schedule,
  TypeDataRoomAllotmentState,
} from "./Types";

import useGlobalState from "state";
import EmptyState from "components/ubold/molecules/EmptyState/EmptyState";
import Loader from "components/ubold/atoms/Loader";
import Tooltip from "components/ubold/atoms/tooltip/Tooltip";
import useElementSize from "utils/customHooks";
import { firstLetterUppercase } from "utils/generalUtils";

interface ITableDashboardRoomAllotmentProps {
  filters: filterTypes[];
  isAddButtonDisabled: boolean;
  filterValidators?: Function;
}

interface RoomAllotmentParams {
  building: number | null;
  buildings_room_status: (string | number)[];
  booked_by_sales: (boolean | string)[];
}

const TableDashboardRoomAllotment: React.FC<
  ITableDashboardRoomAllotmentProps
> = ({ filters, isAddButtonDisabled, filterValidators }) => {
  const [filterQueryParams, setFilterQueryParams] =
    useState<string>("");
  const [dataRoomAllotment, setDataRoomAllotment] = useState<{
    count: number;
    data: TypeDataRoomAllotmentState;
    error: boolean | string;
    loading: boolean;
    time: string;
  }>({
    count: null,
    data: [],
    error: null,
    loading: false,
    time: null,
  });

  const [fetchUtilities, setFetchUtilities] = useState<{
    queryParams: string;
    filteredParams: RoomAllotmentParams;
  }>({
    queryParams: "",
    filteredParams: {
      building: null,
      buildings_room_status: ["select_all"],
      booked_by_sales: ["select_all"],
    },
  });

  const [currentPeriod, setCurrentPeriod] = useState<{
    end: string;
    page: 1 | 2;
    start: string;
  }>({ end: null, page: 1, start: null });

  const [constantValues, setConstantValues] = useState<{
    statusMaintenance: Array<{ label: string; value: number }>;
    statusRoom: Array<{ label: string; value: number }>;
  }>(null);

  const [uiEmpty, setUIEmpty] = useState<{
    heading: string;
    content: string;
  }>({
    heading: "Filter First",
    content: "Please select filter first to see your preference data",
  });

  const [modalDetail, setModalDetail] = useState<boolean>(false);
  const [modalDetailData, setModalDetailData] =
    useState<DataModalDetailObjectType>(null);

  const [refDimensionColRoom, colRoomDimension] = useElementSize();
  const [refDimensionColStatus, colStatusDimension] =
    useElementSize();
  const [refDimensionColBookedBySales, colBookedBySalesDimension] =
    useElementSize();
  const [refDimensionColDays, colDaysDimension] = useElementSize();

  const [constants] = useGlobalState("constant_values");
  const addButton = !isAddButtonDisabled ? true : false;

  const timestampToday = moment().format(); // NOTE: Replace this data if we want to use timestamps from server

  const fetch = ({
    queryParams,
    filteredParams,
  }: {
    queryParams: string;
    filteredParams: RoomAllotmentParams;
  }) => {
    setDataRoomAllotment((prevState) => {
      return { ...prevState, loading: true };
    });

    utils.httpClient.get(
      configs.apiUrl + queryParams,
      (response: IRoomAllotmentAPIResponse) => {
        filterDataWithBookedBySales(
          response.data?.arranged,
          filteredParams,
          response.data.count
        );

        const findDataWithRoomAndScheduleAvailable =
          response.data.arranged
            .find((item) => item.rooms?.length > 0)
            .rooms.find((room) => room.schedules?.length === 60); // Find the first result that have rooms with schedules

        setCurrentPeriod({
          page: 1,
          end: findDataWithRoomAndScheduleAvailable?.schedules[29]
            ?.date,
          start:
            findDataWithRoomAndScheduleAvailable?.schedules[0]?.date,
        });
      },
      (error: any, message: string) => {}
    );
  };

  // const date30DaysFromToday = moment(
  //   data[currentPeriodIndex].period.start,
  //   "DD-MM-YYYY"
  // ) // const date30DaysFromToday = moment(timestampToday)
  //   .add(29, "days")
  //   .format(); // 29 because we want to include today in calculation.
  const textToday = moment(timestampToday).format("ddd, D MMM YYYY");

  const getMonthsOfCurrentPeriod = [
    ...new Set([
      moment(currentPeriod.start, "YYYY-MM-DD").format("M"),
      moment(currentPeriod.end).format("M"),
    ]),
  ]; // It will remove duplicate integer of month

  const renderCurrentPeriod = (): React.ReactElement => {
    if (getMonthsOfCurrentPeriod.length === 0) return null;
    if (getMonthsOfCurrentPeriod[0] === "Invalid date") return null;

    if (getMonthsOfCurrentPeriod.length > 1) {
      const formatStartPeriod =
        currentPeriod.start.split("-")[0] !==
        currentPeriod.end.split("-")[0]
          ? "MMMM YYYY"
          : "MMMM";

      return (
        <p>{`${moment(currentPeriod.start, "YYYY-MM-DD").format(
          formatStartPeriod
        )} - ${moment(currentPeriod.end, "YYYY-MM-DD").format(
          "MMMM YYYY"
        )}`}</p>
      );
    }

    return (
      <p>
        {moment(currentPeriod.start, "YYYY-MM-DD").format(
          "MMMM YYYY"
        )}
      </p>
    );
  };

  const generateDateRange = (input: {
    dateStart: string;
    dateEnd: string;
    dateFormat: string;
  }) => {
    let dayEnd = moment(input.dateEnd, input.dateFormat);
    let dayStart = moment(input.dateStart, input.dateFormat);
    const result: string[] = [];
    result.push(dayStart.format("DD-MM-YYYY"));

    while (dayEnd.isAfter(dayStart)) {
      dayStart.add(1, "day");
      result.push(dayStart.format("DD-MM-YYYY"));
    }
    return result;
  };

  const generateFilteredParams = () => {
    if (!filterQueryParams) return;
    const parsedParams = filterQueryParams
      .split("&")
      .map((param) => param.split("="));
    const tempFilteredParams = { ...fetchUtilities.filteredParams };
    for (const key of parsedParams) {
      const tempKey = key[0];
      const tempFilter = key[1];
      if (tempKey === "buildings_room_status") {
        tempFilteredParams[tempKey] = tempFilter
          .split(",")
          .includes("select_all")
          ? ["select_all"]
          : tempFilter.split(",");
      }
      if (tempKey === "booked_by_sales") {
        tempFilteredParams[tempKey] = tempFilter
          .split(",")
          .includes("select_all")
          ? ["select_all"]
          : tempFilter.split(",").map((filter) => filter === "true");
      }
      if (tempKey === "building") {
        tempFilteredParams[tempKey] = Number(tempFilter);
      }
    }
    const mainEndPoint =
      "u/dashboard/building/room/?page=1&limit=1000";
    const buildingQuery = tempFilteredParams.building
      ? `&building_id=${tempFilteredParams.building}`
      : "";
    const roomStatusQuery =
      tempFilteredParams.buildings_room_status[0] !== "select_all"
        ? `&room_status=${tempFilteredParams.buildings_room_status.join()}`
        : "";
    setFetchUtilities({
      queryParams: mainEndPoint + buildingQuery + roomStatusQuery,
      filteredParams: tempFilteredParams,
    });
    fetch({
      queryParams: mainEndPoint + buildingQuery + roomStatusQuery,
      filteredParams: tempFilteredParams,
    });
  };

  const filterDataWithBookedBySales = (
    data: TypeDataRoomAllotmentState,
    params: RoomAllotmentParams,
    count: number
  ) => {
    let tempDataRoomAllotment = [...data];
    if (
      params.booked_by_sales?.length &&
      params.booked_by_sales[0] !== "select_all"
    ) {
      const filteredData = tempDataRoomAllotment?.map(
        (data: IRowArranged) => {
          const tempRooms = data?.rooms.filter((room: IRoom) =>
            params.booked_by_sales.includes(room.booked_by_sales)
          );
          return tempRooms.length
            ? {
                ...data,
                rooms: tempRooms,
              }
            : null;
        }
      );
      tempDataRoomAllotment = filteredData.filter(
        (data: IRowArranged) => data
      );
    }

    if (count === 0) {
      setUIEmpty({
        heading: "Data not found",
        content:
          "Please select other filter to see your preference data",
      });
    }

    setDataRoomAllotment((prevState) => {
      return {
        ...prevState,
        count,
        data: tempDataRoomAllotment,
        loading: false,
        time: moment().format(),
      };
    });
  };

  const generateRoomAndMaintenanceStatusFlag = (
    roomStatus: number,
    maintenanceStatus: number
  ): React.ReactElement => {
    const roomStatusObject: { label: string; value: number } =
      constantValues.statusRoom?.length > 0 &&
      constantValues.statusRoom.find(
        (item) => item.value === roomStatus
      );

    const generateRoomStatusColor = (): FlagTheme => {
      switch (roomStatus) {
        case 6:
          return "GREEN_DARK";

        case 9:
          return "YELLOW";

        case 10:
          return "GREEN";

        case 11:
          return "BLUE";

        case 12:
          return "PURPLE";

        case 13:
          return "RED";

        default:
          return "BLACK";
      }
    };

    const generateRoomStatusLabel = (): string => {
      switch (roomStatus) {
        case 9:
          return "Occupied by RuOptions L.T.";

        default:
          return roomStatusObject?.label;
      }
    };

    if (!maintenanceStatus) {
      return (
        <Flag theme={generateRoomStatusColor()}>
          {generateRoomStatusLabel()}
        </Flag>
      );
    }

    const roomMaintenanceStatusObject: {
      label: string;
      value: number;
    } =
      constantValues.statusMaintenance?.length > 0 &&
      constantValues.statusMaintenance.find(
        (item) => item.value === maintenanceStatus
      );

    const generateRoomMaintenanceStatusLabel = (): string => {
      switch (roomMaintenanceStatusObject.value) {
        case 1:
          return "R&M";

        case 2:
          return " R&M by Owner";

        case 5:
          return "On Transform";

        default:
          return roomMaintenanceStatusObject?.label;
      }
    };

    return (
      <Flag
        dual={{
          left: {
            text: generateRoomStatusLabel(),
            theme: generateRoomStatusColor(),
          },
          right: {
            text: generateRoomMaintenanceStatusLabel(),
            theme: "BLACK",
          },
        }}
      />
    );
  };

  useEffect(() => {
    if (constants?.buildings_room_status?.length > 0) {
      setConstantValues((prevState) => {
        return {
          ...prevState,
          statusRoom: constants?.buildings_room_status,
        };
      });
    }
  }, [constants?.buildings_room_status]);

  useEffect(() => {
    setConstantValues((prevState) => {
      return {
        ...prevState,
        statusMaintenance:
          constants?.buildings_room_maintenance_status,
      };
    });
  }, [constants?.buildings_room_maintenance_status]);

  useEffect(() => {
    generateFilteredParams();
  }, [filterQueryParams]);

  const renderTableBody = () => {
    if (!dataRoomAllotment.data?.length) return null;

    const renderTextWithHoverTooltip = (
      text: string,
      maxLength: number,
      tooltip?: { zIndex?: number }
    ): string | React.ReactElement => {
      if (text?.length <= maxLength) {
        return text;
      }

      return (
        <Tooltip
          contentHover={text}
          contentMain={text}
          customCSS={{ width: "unset" }}
          trigger={"hover"}
          zIndex={tooltip && tooltip?.zIndex ? tooltip?.zIndex : 1}
        >
          <span
            style={{
              cursor: "pointer",
              display: "inline-block",
              overflowX: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              width: colRoomDimension.width - 8,
            }}
          >
            {text}
          </span>
        </Tooltip>
      );
    };

    return dataRoomAllotment?.data?.map((roomVariant, index) => {
      if (!roomVariant) return null;
      return (
        <TableBodyRoomVariantItem
          key={`room-variant=${roomVariant.room_type}-index=${index}`}
        >
          {
            <TableHeadRoomVariantItemHeader
              style={{
                borderBottom:
                  roomVariant?.rooms?.length > 0
                    ? "unset"
                    : "1px solid #E7E7E7",
              }}
            >
              <LabelLink
                href={`/building/room/room_variant/edit/${roomVariant?.room_variant_id}`}
                target="_blank"
                title="Click to see detail"
              >
                {roomVariant?.room_type}
              </LabelLink>
            </TableHeadRoomVariantItemHeader>
          }

          {roomVariant?.rooms?.length > 0 && (
            <TableBodyRoomVariantItemBody>
              {roomVariant?.rooms?.map((room, index) => {
                const currentRoomScheduleNotConfirmedCheckout: Schedule =
                  room.schedules.find((schedule) => {
                    if (!schedule.order || !schedule.order_end_date)
                      return null;

                    return (
                      schedule.date === schedule.order_end_date &&
                      !schedule.order_confirm_checkout
                    );
                  });

                const currentRoom1MonthSchedules =
                  currentPeriod.page === 1
                    ? room.schedules.slice(0, 30)
                    : room.schedules.slice(30, 60);

                const getBookedByUser = (label: string) => {
                  const splitStr = label?.split(" ");
                  const finalWord = firstLetterUppercase(
                    splitStr?.[2]
                  );

                  return finalWord;
                };

                return (
                  <TableRowRoomVariantItem
                    grid={{
                      templateColumns: `${colRoomDimension.width}px ${colStatusDimension.width}px ${colBookedBySalesDimension.width}px ${colDaysDimension.width}px`,
                    }}
                    key={`room-${room.room_variant_id}-${room.name}}-${room.id}-index${index}`}
                  >
                    <ColRoom>
                      <LabelLink
                        href={`/building/room/room/edit/${room.id}`}
                        target="_blank"
                        title="Click to see detail"
                      >
                        {renderTextWithHoverTooltip(room.name, 8, {
                          zIndex: 0,
                        })}
                      </LabelLink>
                    </ColRoom>

                    <ColStatus>
                      {generateRoomAndMaintenanceStatusFlag(
                        room.room_status,
                        room.maintenance_status
                      )}
                    </ColStatus>

                    <ColBookedBySales>
                      {room.booked_by_sales ? (
                        <React.Fragment>
                          <img
                            alt="Booked by Sales status"
                            height={12}
                            width={12}
                            src="/assets/icons/check-circle-fill.png"
                            style={{ marginRight: 8 }}
                          />
                          {renderTextWithHoverTooltip(
                            getBookedByUser(room.booked_remark),
                            18
                          ) ?? ""}
                        </React.Fragment>
                      ) : (
                        <img
                          alt="Booked by Sales status"
                          height={12}
                          width={12}
                          src="/assets/icons/x-circle-fill.png"
                          style={{ marginRight: 8 }}
                        />
                      )}
                    </ColBookedBySales>

                    <ColDaysOfSelectedDateRange>
                      {currentRoom1MonthSchedules.map(
                        (
                          currentOrderStatusItem,
                          indexOrderStatus
                        ) => {
                          if (
                            moment(
                              currentRoom1MonthSchedules[
                                indexOrderStatus
                              ].date,
                              "YYYY-MM-DD"
                            ).isAfter(
                              moment(
                                currentOrderStatusItem.order_end_date,
                                "YYYY-MM-DD"
                              )
                            )
                          )
                            return (
                              <ColItemDay
                                key={`order-status-${currentOrderStatusItem.date}`}
                                size="center"
                              />
                            );

                          return (
                            <ColItemDay
                              key={`order-status-${currentOrderStatusItem.date}`}
                              position={
                                currentOrderStatusItem.date ===
                                currentOrderStatusItem.order_start_date
                                  ? "right"
                                  : "left"
                              }
                              size="center"
                            >
                              <DayOrderSchedule
                                data={{
                                  currentOrderStatus:
                                    currentOrderStatusItem,
                                  roomDetail: {
                                    room: {
                                      id: room.id,
                                      name: room.name,
                                    },
                                    roomVariant: {
                                      id: room.room_variant_id,
                                      name: room.room_variant,
                                    },
                                  },
                                  scheduleNotConfirmedCheckout:
                                    currentRoomScheduleNotConfirmedCheckout,
                                }}
                                setModal={setModalDetail}
                                setModalData={setModalDetailData}
                              />
                            </ColItemDay>
                          );
                        }
                      )}
                    </ColDaysOfSelectedDateRange>
                  </TableRowRoomVariantItem>
                );
              })}
            </TableBodyRoomVariantItemBody>
          )}
        </TableBodyRoomVariantItem>
      );
    });
  };

  return (
    <Container>
      <FilterPanelV2
        addButton={addButton}
        defaultState={{
          building: { label: null, value: null },
          booked_by_sales: [
            { label: "Select All", value: "select_all" },
          ],
          buildings_room_status: [
            { label: "Select All", value: "select_all" },
          ],
        }}
        filters={filters}
        filterValidators={filterValidators}
        filterColumn={0}
        isTeamMenu={false}
        restAccessCode={""}
        setParams={setFilterQueryParams}
        rowsPerPage={0}
        ordering={""}
        selectedPage={0}
        totalRows={0}
        isFetching={false}
        dataTableList={dataRoomAllotment.data} // buat referensi si filter ngebaca data di table list-nya
      />

      <TableWhiteContainer>
        {dataRoomAllotment.loading ? (
          <Loader className="pb-0" />
        ) : (
          <React.Fragment />
        )}

        {dataRoomAllotment.count === 0 ||
        dataRoomAllotment.data.length === 0 ||
        !constantValues?.statusRoom ||
        !constantValues?.statusMaintenance ? (
          <React.Fragment>
            {!dataRoomAllotment.loading && (
              <EmptyState
                iconHeight={80}
                iconWidth={110}
                imageSource={"/assets/icons/empty-state-icon.png"}
                isDashboard
                textTitle={uiEmpty.heading}
                textDescription={uiEmpty.content}
              />
            )}
          </React.Fragment>
        ) : (
          <React.Fragment>
            {!dataRoomAllotment.loading && (
              <React.Fragment>
                <TableStickyInformation>
                  <TableInformation style={{ marginBottom: 16 }}>
                    <label>
                      Total {dataRoomAllotment?.count} Room(s)
                    </label>

                    <span style={{ margin: "0 4px" }}>&bull;</span>

                    <label style={{ marginRight: 4 }}>
                      Result Time:{" "}
                      {dataRoomAllotment.time &&
                        moment(dataRoomAllotment.time).format(
                          "ddd, DD MMM YYYY, HH:mm"
                        )}{" "}
                    </label>
                    <RefreshBtn
                      onClick={(e) => fetch(fetchUtilities)}
                    >
                      Refresh
                    </RefreshBtn>
                  </TableInformation>

                  <LegendsContainer style={{ marginBottom: 16 }}>
                    <div className="d-inline-flex">
                      <LegendCategoryLabel style={{ marginRight: 8 }}>
                        Order Status:
                      </LegendCategoryLabel>

                      <TextLegend
                        label="Waiting for Payment"
                        theme="RED"
                        style={{ marginRight: 8 }}
                      />
                      <TextLegend
                        label="Just Deposit"
                        theme="YELLOW"
                        style={{ marginRight: 8 }}
                      />
                      <TextLegend
                        label="Complete Payment"
                        theme="PURPLE"
                        style={{ marginRight: 8 }}
                      />
                      <TextLegend label="Ongoing" theme="BLUE" />
                    </div>

                    <div className="d-inline-flex">
                      <div className="d-flex">
                        <LegendCategoryLabel
                          style={{ marginRight: 8 }}
                        >
                          Subscription Type:
                        </LegendCategoryLabel>

                        <TextLegend
                          label="Open Subscription"
                          theme="GRAY"
                          style={{ marginRight: 8 }}
                        />

                        <TextLegend
                          label="Close Subscription"
                          stripes="#222"
                          theme="GRAY"
                          style={{ marginRight: 8 }}
                        />
                      </div>
                    </div>
                  </LegendsContainer>
                </TableStickyInformation>
                <TableBase
                  grid={{ templateColumns: { right: "1fr" } }}
                >
                  <TableHead>
                    <TableBaseHeadLevel1
                      grid={{
                        templateColumns: {
                          left: "1fr",
                          right: `${colDaysDimension.width}px`,
                        },
                      }}
                    >
                      <CurrentDateInformation>
                        <p>{"Today, " + textToday}</p>
                      </CurrentDateInformation>

                      <CurrentSelectedDateRange>
                        <ButtonDateRangeNavigation
                          disabled={currentPeriod.page === 1}
                          onClick={() => {
                            const findDataWithRoomAndScheduleAvailable =
                              dataRoomAllotment.data
                                .find(
                                  (item) => item.rooms?.length > 0
                                )
                                .rooms.find(
                                  (room) =>
                                    room.schedules?.length === 60
                                ); // Find the first result that have rooms with schedules

                            setCurrentPeriod({
                              end: findDataWithRoomAndScheduleAvailable
                                .schedules[29].date,
                              page: 1,
                              start:
                                findDataWithRoomAndScheduleAvailable
                                  .schedules[0].date,
                            });
                          }}
                        >
                          <IconDateRangeNavigation
                            alt="Previous Icon"
                            direction="LEFT"
                            disabled={currentPeriod.page === 1}
                            src="/assets/icons/triangle-left.svg"
                            height={10}
                            width={7}
                          />
                          <span>Prev</span>
                        </ButtonDateRangeNavigation>
                        {renderCurrentPeriod()}
                        <ButtonDateRangeNavigation
                          disabled={currentPeriod.page === 2}
                          onClick={() => {
                            const findDataWithRoomAndScheduleAvailable =
                              dataRoomAllotment.data
                                .find(
                                  (item) => item.rooms?.length > 0
                                )
                                .rooms.find(
                                  (room) =>
                                    room.schedules?.length === 60
                                ); // Find the first result that have rooms with schedules

                            setCurrentPeriod({
                              end: findDataWithRoomAndScheduleAvailable
                                .schedules[59].date,
                              page: 2,
                              start:
                                findDataWithRoomAndScheduleAvailable
                                  .schedules[30].date,
                            });
                          }}
                        >
                          <span>Next</span>
                          <IconDateRangeNavigation
                            alt="Next Icon"
                            direction="RIGHT"
                            disabled={currentPeriod.page === 2}
                            src="/assets/icons/triangle-left.svg"
                            height={10}
                            width={7}
                          />
                        </ButtonDateRangeNavigation>
                      </CurrentSelectedDateRange>
                    </TableBaseHeadLevel1>

                    <TableBaseHeadLevel2>
                      <TableHeadColRoom ref={refDimensionColRoom}>
                        <p>{"Room"}</p>
                      </TableHeadColRoom>

                      <TableHeadColStatus ref={refDimensionColStatus}>
                        <p>{"Room & Maintenance Status"}</p>
                      </TableHeadColStatus>

                      <TableHeadColBookedBySales
                        ref={refDimensionColBookedBySales}
                      >
                        <p>{"Booked by Sales"}</p>

                        {/* <img
                    alt="Sort Icon"
                    src="/assets/icons/icon-sort.svg"
                    height={12}
                    width={12}
                  /> */}
                      </TableHeadColBookedBySales>

                      <TableHeadColDaysOfSelectedDateRange
                        ref={refDimensionColDays}
                      >
                        {currentPeriod.end &&
                          currentPeriod.start &&
                          generateDateRange({
                            dateEnd: currentPeriod.end,
                            dateStart: currentPeriod.start,
                            dateFormat: "YYYY-MM-DD",
                          }).map((item) => (
                            <HeadColItemDay
                              key={`date-range-item-${item}`}
                            >
                              <p>
                                {
                                  moment(item, "DD-MM-YYYY").format(
                                    "dd"
                                  )[0]
                                }
                              </p>
                              <p>
                                {moment(item, "DD-MM-YYYY").format(
                                  "D"
                                )}
                              </p>
                            </HeadColItemDay>
                          ))}
                      </TableHeadColDaysOfSelectedDateRange>
                    </TableBaseHeadLevel2>
                  </TableHead>

                  <TableBody>{renderTableBody()}</TableBody>
                </TableBase>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </TableWhiteContainer>

      <RukiModal
        isOpen={modalDetail}
        onClose={() => setModalDetail(false)}
        modalSize={387}
        headerTitle="Detail"
        headerType="close"
        css={ModalDetailCSSDefault}
      >
        <ModalDetail
          data={modalDetailData}
          onClose={() => setModalDetail(false)}
        />
      </RukiModal>
    </Container>
  );
};

export const BgStripes: React.FC<
  {
    backgroundColor: string;
    bar: string;
    radiusLeft?: boolean;
    radiusRight?: boolean;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({ backgroundColor, bar, radiusLeft, radiusRight, ...props }) => {
  return (
    <BgStripesBase
      backgroundColor={backgroundColor}
      bar={bar}
      radiusLeft={radiusLeft}
      radiusRight={radiusRight}
      {...props}
    >
      <p className="bar"></p>
      <p className="bar"></p>
      <p className="bar"></p>
      <p className="bar"></p>
    </BgStripesBase>
  );
};

export default TableDashboardRoomAllotment;
