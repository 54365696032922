import utils from "../../../utils";

import configBuilder from '../../../components/ubold/ConfigBuilder';

let definitions = {
    title: 'Menu Positions',
    baseLink: '/crm/menu_position',
    restAccessCode: 'administrator.menu_position',
    __table__: {
        filterColumn: 3,
        filters: [
            {
                id: 'name',
                name: 'Name',
                type: 'text'
            },
            {
                id: 'constraint',
                name: 'Constraint',
                type: 'text'
            },
            {
                id: 'description',
                name: 'Description',
                type: 'text'
            }
        ],
        columns: [
            {
                id: 'name',
                name: 'Name'
            },
            {
                id: 'constraint',
                name: 'Constraint'
            },
            {
                id: 'description',
                name: 'Description'
            },
            {
                id: 'created_at',
                name: 'Created At',
                renderer: value => {
                    return utils.formatter.dateTime(value);
                }
            },
        ]
    }
}

export default configBuilder.buildTablePageConfig(definitions);