import AdminRoutes from "./AdminModule";
import AppRoutes from "./AppModule";
import AuthRoutes from "./AuthModule";
import BuildingRoutes from "./BuildingModule";
import ComEventRoutes from "./ComEventModule";
import CRMRoutes from "./CRMModule";
import FinanceRoutes from "./FinanceModule";
import HomeRoutes from "./HomeModule";
import LandlordRoutes from "./LandlordModule";
import LeadRoutes from "./LeadModule";
import MarketingRoutes from "./MarketingModule";
import OpsRoutes from "./OpsModule";
import PrintModule from "./PrintModule";
import RukikoinRoutes from "./RukikoinModule";
import SalesRoutes from "./SalesModule";
import TenantRoutes from "./TenantModule";
import WebsiteRoutes from "./WebsiteModule";

export default [
  ...AuthRoutes,
  ...HomeRoutes,
  ...BuildingRoutes,
  ...WebsiteRoutes,
  ...TenantRoutes,
  ...LandlordRoutes,
  ...SalesRoutes,
  ...OpsRoutes,
  ...FinanceRoutes,
  ...MarketingRoutes,
  ...ComEventRoutes,
  ...RukikoinRoutes,
  ...LeadRoutes,
  ...AdminRoutes,
  ...CRMRoutes,
  ...AppRoutes,
  ...PrintModule,
];
