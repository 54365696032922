import configBuilder from "../../../../components/ubold/ConfigBuilder";

let definitions = {
  title: "Master Category POI",
  frontendPath: "/building/poi/category_point_of_interest",
  baseLink: "/building/category-poi",
  restAccessCode: "building.category_poi",
  __table__: {
    filterColumn: 4,
    hideEditButton: true,
    filters: [
      {
        id: "name",
        name: "Name",
        type: "text",
      },
      {
        id: "name_en",
        name: "Name EN",
        type: "text",
      },
    ],
    columns: [
      {
        id: "name",
        name: "Name",
        enableHyperlink: true,
      },
      {
        id: "name_en",
        name: "Name EN",
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
