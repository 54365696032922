import { useEffect, useState } from "react";

import { withRouter } from "react-router-dom";

import Select from "react-select";

import configs from "../../../../configs";
import useGlobalState from "../../../../state";

import utils from "../../../../utils";

function CSelect(props) {
  const {
    name,
    state,
    setState,
    stateComponent,
    defaultValue = null,
    uniqueIdentifier,
    isUniqueOption = false,
    isClearable = true,
    data,
    onChange,
    afterChange,
    isMulti = false,
    isDisabled = false,
    readOnly = false,
    cacheKey = null,
    dependsOn = null,
    dependsOnHandler,
    itemsExtractor,
    filterItems,
    extraOptions = { atFirst: [], atLast: [] },
    filterItemsDependencies = [],
  } = props;

  const [options, setOptions] = useState([]);
  const [constants] = useGlobalState("constant_values");

  const valueFromId = (opts, id) =>
    (opts ?? []).find((o) => o?.value === id);

  const load = (dependsOn, callback) => {
    if (utils.commons.isArray(data)) {
      setOptions(data);
    } else if (utils.commons.isString(data)) {
      utils.httpClient.get(
        configs.apiUrl +
          utils.commons.stripMultipleSlashes(
            data.replace("?", "/?")
          ) +
          (dependsOn === null
            ? ""
            : "&" + dependsOn + "_id=" + state[dependsOn]),
        (data) => {
          const options = [];

          for (let i = 0; i < data.data.rows.length; i++) {
            if (itemsExtractor === undefined) {
              options[i] = {
                label: data.data.rows[i]["name"],
                value: data.data.rows[i]["id"],
              };

              options[i] = { ...options[i], ...data.data.rows[i] };
            } else {
              options[i] = itemsExtractor(data.data.rows[i], i);
            }
          }

          if (isUniqueOption) {
            const selectedValues = [];
            const optionsFiltered = [];

            if (stateComponent) {
              for (let i = 0; i < stateComponent.length; i++) {
                if (
                  stateComponent[i][
                    uniqueIdentifier ? uniqueIdentifier : name
                  ]
                ) {
                  selectedValues.push(
                    stateComponent[i][
                      uniqueIdentifier ? uniqueIdentifier : name
                    ]
                  );
                }
              }
            }

            for (let i = 0; i < options.length; i++) {
              if (
                !isDisabled &&
                !selectedValues.includes(options[i].value)
              ) {
                optionsFiltered.push(options[i]);
              } else if (isDisabled) {
                optionsFiltered.push(options[i]);
              } else {
                options[i].isSelected = true;
                optionsFiltered.push(options[i]);
              }
            }

            setOptions(optionsFiltered);

            if (utils.commons.isFunction(callback)) {
              callback(optionsFiltered);
            }
          } else {
            setOptions(options);

            if (utils.commons.isFunction(callback)) {
              callback(options);
            }
          }
        },
        (error, message) => {
          console.log(message);
        },
        false,
        dependsOn !== null && dependsOn !== undefined
          ? null
          : cacheKey
      );
    } else if (
      utils.commons.isObject(data) &&
      constants &&
      data.optionField &&
      constants[data.optionField]
    ) {
      if (utils.commons.isFunction(filterItems)) {
        const newOptions = filterItems(
          constants[data.optionField],
          state
        );
        setOptions(newOptions);
      } else {
        setOptions(constants[data.optionField]);
      }
    }
  };

  useEffect(() => {
    if (dependsOn === null || dependsOn === undefined) {
      load(null);
    }
  }, [data]);

  useEffect(() => {
    if (utils.commons.isFunction(setState)) {
      setState((prevState) => ({
        ...prevState,
        [name + "_object"]: valueFromId(options, state[name]),
      }));
    }
  }, [state?.[name]]);

  useEffect(() => {
    if (dependsOn && utils.commons.isString(data)) {
      if (
        state[dependsOn] !== undefined &&
        state[dependsOn] !== null
      ) {
        if (utils.commons.isFunction(dependsOnHandler)) {
          dependsOnHandler(data);
        } else {
          load(dependsOn, (optionsParam) => {
            if (
              state[name] &&
              !utils.commons.valueExists(
                optionsParam,
                "value",
                state[name]
              )
            ) {
              setState((prevState) => ({
                ...prevState,
                [name]: null,
              }));
            }
          });
        }
      } else {
        if (state[dependsOn] === null) {
          setState((prevState) => ({ ...prevState, [name]: null }));
        }
      }
    }
  }, [state?.[dependsOn]]);

  useEffect(() => {
    if (
      utils.commons.isObject(data) &&
      constants &&
      !!data.optionField &&
      constants[data.optionField]
    ) {
      if (utils.commons.isFunction(filterItems)) {
        const newOptions = filterItems(
          constants[data.optionField],
          state
        );
        setOptions(newOptions);
      } else {
        const appendAtFirst = !!extraOptions?.atFirst
          ? extraOptions?.atFirst
          : [];
        const appendAtLast = !!extraOptions?.atLast
          ? extraOptions?.atLast
          : [];

        setOptions([
          ...appendAtFirst,
          ...constants[data.optionField],
          ...appendAtLast,
        ]);
      }
    }
  }, [constants, ...filterItemsDependencies]);

  return (
    <Select
      styles={{
        menuPortal: (provided) => {
          return { ...provided, zIndex: 2000 };
        },
      }} // this is needed if react-select is used on modal component
      name={name}
      isClearable={isClearable}
      closeMenuOnSelect
      isMulti={isMulti}
      placeholder={defaultValue}
      menuPortalTarget={document.body}
      isDisabled={isDisabled || readOnly}
      isOptionDisabled={(option) => option.disabled}
      isOptionSelected={(option) => option.isSelected}
      hideSelectedOptions={true}
      value={
        state?.[name] === null || state?.[name] === undefined
          ? state?.[name]
          : valueFromId(options, state?.[name])
      }
      options={options}
      onChange={
        onChange
          ? onChange
          : (data) => {
              if (data) {
                if (utils.commons.isArray(data)) {
                  console.log(data);
                } else {
                  setState((prevState) => ({
                    ...prevState,
                    [name + "_label"]: data.label,
                    [name + "_object"]: data,
                    [name]: data.value,
                  }));
                }
              } else {
                setState((prevState) => ({
                  ...prevState,
                  [name + "_label"]: null,
                  [name + "_object"]: null,
                  [name]: null,
                }));
              }

              setTimeout(() => {
                if (utils.commons.isFunction(afterChange)) {
                  afterChange(data);
                }
              }, 250);
            }
      }
    />
  );
}

export default withRouter(CSelect);
