import {useParams, withRouter} from "react-router-dom";

import TabPaneSecondary from "../../../../../../components/ubold/organisms/TabPaneSecondary";

import COneOrManyToManyField from "../../../../../../components/ubold/molecules/forms/COneOrManyToManyField";

function RoleAccesses(props) {
    const {definitions, tab, state, setState} = props;

    let {id} = useParams();

    return (
        <TabPaneSecondary
            definitions={definitions}
            tab={tab}
            restAccessCodeNamespace='access'
            state={state}
            setState={setState}>
            <COneOrManyToManyField
                id={id}
                name={tab.id}
                restPath='team/role__access/'
                relationKey='role_id'
                columns={[
                    {
                        name: 'access',
                        type: 'async_select',
                        title: 'Access',
                        isRequired: true,
                        disabledAtEdit: true,
                        data: 'team/access/?__type__=select_entries',
                        itemsExtractor: row => {
                            return {
                                label: row.code,
                                value: row.id
                            }
                        }
                    },
                    {
                        name: 'note',
                        type: 'text',
                        title: 'Note',
                        maxLength: 255
                    },
                ]}
            />
        </TabPaneSecondary>
    );
}

export default withRouter(RoleAccesses);