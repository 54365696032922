import {useState} from "react";

import {useParams, withRouter} from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import Form from "../../../../components/ubold/organisms/Form";

import CVTextField from "../../../../components/ubold/molecules/forms/CVTextField";
import CVAsyncSelect from "../../../../components/ubold/molecules/forms/CVAsyncSelect";

import definitions from "./definitions";

function CAddEditPage() {
    let {id} = useParams();

    const initialState = {};

    const [state, setState] = useState(initialState);

    return (
        <Page title={(id === undefined ? 'Add New' : 'Edit') + ' ' +  definitions.title}
              restAccessCode={definitions.restAccessCode}>
            <Form
                id={id}
                definitions={definitions}
                state={state}
                setState={setState}>
                <CVAsyncSelect
                    name='utm_campaign'
                    title='UTM Campaign'
                    overrideLayout='12'
                    isRequired
                    data='marketing/utm_campaign?__type__=select_entries'
                    itemsExtractor= {row => {
                        return {
                            label: row.utm_campaign,
                            value: row.id
                        }
                    }}
                />
                <CVTextField
                    name='utm_content'
                    title='UTM Content'
                    overrideLayout='12'
                    maxLength={254}
                    isRequired
                />
                <CVTextField
                    name='code'
                    title='Code'
                    overrideLayout='12'
                    maxLength={2}
                    isRequired
                />
            </Form>
        </Page>
    );
}

export default withRouter(CAddEditPage);