import { withRouter } from "react-router-dom";

import AccordionPane from "../../../../../../../components/ubold/organisms/AccordionPane";

import CHTextField from "../../../../../../../components/ubold/molecules/forms/CHTextField";
import CHSelect from "../../../../../../../components/ubold/molecules/forms/CHSelect";
import CHDateTimeSplitPicker from "../../../../../../../components/ubold/molecules/forms/CHDateTimeSplitPicker";
import CHParkingCapacity from "../../../../../../../components/ubold/molecules/forms/CHParkingCapacity";

import utils from "../../../../../../../utils";
import CHDateTimePicker from "../../../../../../../components/ubold/molecules/forms/CHDateTimePicker";
import CHMultiCheckbox from "../../../../../../../components/ubold/molecules/forms/CHMultiCheckbox";
import { sectionProps } from "@types";
import CHCheckBox from "components/ubold/molecules/forms/CHCheckBox";

function OtherInfos(props: sectionProps) {
  const {
    baseLink,
    accordionId,
    onSubmit,
    state,
    setState,
    restAccessCode,
  } = props;

  return (
    <AccordionPane
      id="otherInfos"
      name="Other Infos"
      accordionId={accordionId}
      baseLink={baseLink}
      onSubmit={onSubmit}
      shown={!!utils.form.isFieldError(state, "property_type")}
      state={state}
      setState={setState}
    >
      <CHTextField
        name="building_floors"
        title="Total Floor"
        type="number"
        readOnly
      />
      <CHSelect
        name="building_status"
        title="Progress Status"
        data={{ optionField: "buildings_building_status" }}
        readOnly
      />
      <CHSelect
        name="property_type"
        title="Type of Property"
        isRequired
        data={{ optionField: "buildings_building_property_type" }}
        readOnly
      />
      <CHTextField
        name="total_buildup"
        title="Total Build Up"
        type="number"
        readOnly
      />
      <CHTextField
        name="rented_buildup"
        title="Rented Build Up"
        type="number"
        readOnly
      />
      <CHDateTimeSplitPicker
        name="last_maintenance"
        title="Last Maintenance"
        readOnly
        disabled
      />
      <CHDateTimeSplitPicker
        name="next_maintenance"
        title="Next Maintenance"
        readOnly
        disabled
      />
      <CHDateTimePicker
        name="year_build"
        title="Year Build"
        type="date"
        readOnly
      />
      <CHParkingCapacity
        carName="car_parking_capacity"
        motorBikeName="motorbike_parking_capacity"
        rukitaOptionName="rukita_option"
        enableOnlinePayment="enable_online_payment"
        title="Parking Capacity"
        readOnly
        disabled
      />
      <CHCheckBox
        name="allow_for_more_than_30_days"
        title="Allow 180 Days"
        readOnly
        disabled
      />
      <CHMultiCheckbox
        readOnly
        disabled
        name="shipping_method"
        title="Shipping Method"
        data={[
          {
            label: "Diantar ke Kamar",
            value: "1",
          },
          {
            label: "Diambil di Dropbox",
            value: "2",
          },
        ]}
        onChange={(values: string) => {
          setState((prevState: Object) => ({
            ...prevState,
            shipping_method: values,
          }));
        }}
      />
      <CHSelect
        name="minimum_deposit"
        title="Minimum Deposit"
        isRequired
        data={{ optionField: "buildings_building_minimum_deposit" }}
        restAccessCode={restAccessCode}
        readOnly
      />
    </AccordionPane>
  );
}

export default withRouter(OtherInfos);
