import {withRouter} from "react-router-dom";

import TabPane from "../../../../../../components/ubold/organisms/TabPane";
import TabPaneSection from "../../../../../../components/ubold/organisms/TabPaneSection";
import CHTextField from "../../../../../../components/ubold/molecules/forms/CHTextField";
import CHTextArea from "../../../../../../components/ubold/molecules/forms/CHTextArea";
import CHRichTextField from "../../../../../../components/ubold/molecules/forms/CHRichTextField";
import CHImageUpload from "../../../../../../components/ubold/molecules/forms/CHImageUpload";
import CHCheckBox from "../../../../../../components/ubold/molecules/forms/CHCheckBox";

function PlaceGroup(props) {
    const {id, definitions, tab, state, setState} = props;

    return (
        <TabPane
            id={id}
            definitions={definitions}
            tab={tab}
            state={state}
            setState={setState}>
            <TabPaneSection style={{padding: '25px 16px 0px'}}>
                <CHTextField
                    name='name'
                    title='Name'
                    maxLength={50}
                    isRequired
                />
                <CHTextField
                    name='slug'
                    title='Slug'
                    maxLength={50}
                    isRequired
                />
                <CHTextField
                    name='title_tag'
                    title='Title'
                    maxLength={160}
                />
                <CHTextField
                    name='title_tag_en'
                    title='Title in English'
                    maxLength={160}
                />
                <CHTextArea
                    name='meta_description'
                    title='Meta Description'
                />
                <CHTextArea
                    name='meta_description_en'
                    title='Meta Description in English'
                />
                <CHRichTextField
                    name='desc'
                    title='Description'
                />
                <CHRichTextField
                    name='desc_en'
                    title='Description in English'
                />
                <CHImageUpload
                    name='header_photo_original'
                    title='Photo Header'
                    isRequired
                />
                <CHCheckBox
                    name='published'
                    title='Published'
                />
                <CHTextField
                    name='order_no'
                    title='Sequence Number'
                    type='number'
                />
                <CHTextField
                    name='lat'
                    title='Latitude'
                    type='number'
                />
                <CHTextField
                    name='lon'
                    title='Longitude'
                    type='number'
                />
            </TabPaneSection>
        </TabPane>
    );
}

export default withRouter(PlaceGroup);