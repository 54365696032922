import { withRouter } from "react-router-dom";

import TabPane from "../../../../../../components/ubold/organisms/TabPane";
import TabPaneSection from "../../../../../../components/ubold/organisms/TabPaneSection";
import CHTextField from "../../../../../../components/ubold/molecules/forms/CHTextField";
import CHTextView from "../../../../../../components/ubold/molecules/forms/CHTextView";

import utils from "../../../../../../utils";
import CHSelect from "components/ubold/molecules/forms/CHSelect";
import COneOrManyToManyFieldV2 from "components/ubold/molecules/forms/COneOrManyToManyFieldV2";
import { useEffect } from "react";
import Swal from "sweetalert2";

const FirstTab = (props) => {
  const { id, definitions, tab, state, setState } = props;
  const handleOnClickDeleteCaseSubCaseRow = (index) => {
    const clonedCategories = [...state.t_categories];
    let deleteFlowExecuted = false;
    if (!id || clonedCategories[index].flag === "New") {
      clonedCategories.splice(index, 1);
      deleteFlowExecuted = true;
    } else if (!clonedCategories[index].is_active) {
      clonedCategories[index].flag = "Deleted";
      deleteFlowExecuted = true;
    }

    if (deleteFlowExecuted) {
      setState((prevState) => ({
        ...prevState,
        t_categories: clonedCategories,
      }));
      return;
    }

    Swal.fire({
      title: "Case and Sub-Case Category Deletion",
      text: "Please set it to Inactive first",
      icon: "warning",
    });
  };

  useEffect(() => {
    if (!id || !state?.t_categories?.length) return;
    const clonedCategories = [...state?.t_categories]?.map(
      (category) => ({
        ...category,
        flag: "Editing",
      })
    );
    setState((prevState) => ({
      ...prevState,
      t_categories: clonedCategories,
    }));
  }, [id]);

  return (
    <TabPane
      id={id}
      definitions={definitions}
      tab={tab}
      state={state}
      setState={setState}
    >
      <TabPaneSection style={{ padding: "25px 16px 0px" }}>
        <CHSelect
          name="request_type"
          title="Request Type"
          data={{
            optionField: "buildings_service_request_type",
          }}
          isRequired
          readOnly={id}
        />
        <CHTextField
          name="name"
          title="Name"
          type="text"
          maxLength={80}
          isRequired
        />
        <CHTextField
          name="name_en"
          title="Name EN"
          maxLength={80}
          type="text"
          isRequired
        />
        <CHTextField name="sla" title="SLA" type="number" />
        <CHTextView
          name="date_created"
          title="Date Created"
          renderer={(value) => {
            return utils.formatter.dateTime(value);
          }}
        />
        <CHTextView
          name="date_updated"
          title="Date Updated"
          renderer={(value) => {
            return utils.formatter.dateTime(value);
          }}
        />
      </TabPaneSection>
      {state?.request_type === 2 ? (
        <>
          <section
            style={{
              width: "100%",
              background: "#f2f2f2",
              height: 30,
              paddingRight: 24,
              paddingLeft: 29,
              alignItems: "center",
              fontSize: 16,
              fontWeight: 500,
              display: "flex",
            }}
          >
            Case and Sub-Case Category
          </section>
          <COneOrManyToManyFieldV2
            id={id}
            name="t_categories"
            saveOnlyFromMainForm
            mainTab
            isSequenceEnabled
            state={state}
            setState={setState}
            customHandleDeleteRow={handleOnClickDeleteCaseSubCaseRow}
            additionalStateInAddNewRow={{
              is_active: true,
              sequence: state?.t_categories?.length + 1 ?? 1,
            }}
            columns={[
              {
                name: "case_category",
                title: "Case Category",
                type: "async_select",
                isRequired: true,
                data: "building/case_category/?__type__=select_entries&ordering=-id",
                isClearable: false,
                isUniqueOption: true,
              },
              {
                name: "sub_case_category",
                title: "Sub Case Category",
                isRequired: true,
                type: "dual_list",
                width: 700,
                dependsOn: "case_category",
                data: "building/sub_case_category/?ordering=-id",
                hitApi: ({ index }) =>
                  !id ||
                  typeof state.t_categories[index].case_category ===
                    "number",
                sortByField: "sequence",
                itemsExtractor: (row, index) => ({
                  label: row.name,
                  value: row.id,
                  sequence: row.sequence,
                }),
              },
              {
                name: "is_active",
                title: "Active",
                type: "checkbox",
              },
            ]}
          />
        </>
      ) : (
        <></>
      )}
    </TabPane>
  );
};

export default withRouter(FirstTab);
