import { KeyboardEvent, useState, useEffect } from "react";

import {
  withRouter,
  useLocation,
  RouteComponentProps,
} from "react-router-dom";

import CSelect from "../../atoms/forms/CSelect";
import CAsyncSelect from "../../atoms/forms/CAsyncSelect";
import CDateTimePicker from "../../atoms/forms/CDateTimePicker";
import CTextField from "../../atoms/forms/CTextField";

import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

import utils from "../../../../utils";
import useGlobalState from "../../../../state";
import CButtons from "components/ubold/atoms/buttons/CButton";
import RukiModal from "../modal/RukiModal";
import { filterBtnRightProps, filterTypes } from "@types";
import styled, { css } from "styled-components";
import CBadge from "components/ubold/atoms/badges/CBadge";
import CCheckBoxMultiple from "components/ubold/atoms/forms/CCheckBoxMultiple";

import Success from "components/ubold/molecules/modal/Export/Success";
import Failed from "components/ubold/molecules/modal/Export/Failed";
import Waiting from "components/ubold/molecules/modal/Export/Waiting";
import configs from "../../../../configs";
import AppliedFilters from "../section/AppliedFilters";

type FilterPanelProps = {
  filters: filterTypes[];
  filterColumn: number;
  filterButtonsRight?: filterBtnRightProps;
  filterValidation?: Function;
  isTeamMenu: boolean;
  restAccessCode: string;
  fetch: (
    selectedPageParam: number,
    selectedRowPerPageParam: number,
    orderingParam?: string,
    paramsParam?: string
  ) => void;
  setParams: React.Dispatch<React.SetStateAction<string>>;
  rowsPerPage: number;
  ordering: string;
  selectedPage: number;
  addButton: boolean;
  isExportEnabled: boolean;
  totalRows: number;
  isFetching: boolean;
  setData?: React.Dispatch<React.SetStateAction<any>>;
  setFetched?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsEmptyData?: React.Dispatch<React.SetStateAction<boolean>>;
  exportURL?: string;
} & RouteComponentProps;

const stylingModal = css`
  .btn-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 12px;
  }

  .btn {
    border-radius: 4px;
    height: 36px;
    width: 100%;
    border: 1px solid #35b0a7;
    font-weight: 500;
  }

  .green {
    background: #35b0a7;
    color: #fff;
  }

  .green-outline {
    background: #fff;
    color: #35b0a7;
  }
`;

const FilterPanelNew: React.FC<FilterPanelProps> = (props) => {
  const {
    filters = [],
    filterButtonsRight = [],
    filterValidation = null,
    isTeamMenu = false,
    restAccessCode,
    fetch,
    setParams,
    rowsPerPage,
    ordering,
    selectedPage,
    addButton = true,
    isExportEnabled = false,
    setData,
    setFetched,
    setIsEmptyData,
    exportURL,
  } = props;

  const [isOpen, setOpen] = useState(false);
  const [isExportModalInfo, setExportModalInfo] = useState(false);
  const initialState: Record<
    string,
    string | string[] | number[] | { value: string; label: string }[]
  > = {};

  for (let i = 0; i < filters.length; i++) {
    initialState[filters[i].id] = null;
  }

  const [state, setState] = useState(initialState);
  const [lastAppliedFilters, setLastFilters] = useState(state);
  const [totalSelected, setTotalSelected] = useState(0);

  const [user] = useGlobalState("user");
  const [accesses] = useGlobalState("accesses");
  const [constants] = useGlobalState("constant_values");

  const sweetAlert = withReactContent(Swal);
  const location = useLocation();

  const buildParams = (
    stateParam?: Record<
      string,
      | string
      | string[]
      | number[]
      | { value: string; label: string }[]
    >
  ) => {
    const finalState = stateParam ?? state;
    const params = [];
    for (let key of Object.keys(finalState)) {
      if (finalState[key] !== null && finalState[key] !== undefined) {
        if (typeof finalState[key] === "string") {
          const stateString = finalState[key] as string;
          if (stateString.trim?.() !== "") {
            params.push(key + "=" + finalState[key]);
          }
        } else if (typeof finalState[key] === "object") {
          const stateObj = finalState[key] as any;

          if (stateObj?.length > 0) {
            let multipleParams = [];
            for (let i = 0; i < stateObj.length; i++) {
              multipleParams.push(stateObj[i].value ?? stateObj[i]);
            }
            params.push(`${key}=${multipleParams}`);
          } else {
            params.push(
              stateObj.value ? key + "=" + stateObj.value : ""
            );
          }
        } else {
          params.push(
            finalState[key] ? key + "=" + finalState[key] : ""
          );
        }
      }
    }
    return params;
  };

  const renderSelectedLabel = () => {
    const filterKeys = Object.keys(lastAppliedFilters);
    // for (let key of Object.keys(lastAppliedFilters)) {
    //   const stateObj = state[key] as any;
    //   if (typeof stateObj === "object" && stateObj?.length > 0) {
    //     /** do something here */
    //   }
    // }

    if (
      filterKeys.find((key) => {
        const stateObj = lastAppliedFilters[key] as any;
        return stateObj?.length > 0;
      })
    ) {
      return (
        <AppliedFilters
          isLoading={props.isFetching}
          totalRows={props.totalRows}
          reset={() => reset()}
          search={(
            passingState: Record<
              string,
              | string
              | string[]
              | number[]
              | { value: string; label: string }[]
            >
          ) => search(passingState)}
          filterKeys={filterKeys}
          lastAppliedFilters={lastAppliedFilters}
          setState={setState}
        />
      );
    }

    return <></>;
  };

  const refresh = (isReset = false, isRealRefresh = false) => {
    const params = buildParams();

    setParams(
      isReset ? "" : params.filter((param) => param !== "").join("&")
    );

    setData([]);
    setFetched(false);
    setIsEmptyData(true);

    if (isRealRefresh) {
      fetch(
        selectedPage,
        rowsPerPage,
        isReset ? "-id" : ordering,
        isReset
          ? ""
          : params.filter((param) => param !== "").join("&")
      );
    }
  };

  const findSelectedData = (state: any, id: string) => {
    let lengthPerID = null;

    if (!state[id]) return;
    lengthPerID = state[id].length;

    return lengthPerID;
  };

  const getTotalSelected = (state: any) => {
    let totalSelected: null | number = null;

    Object.keys(state).forEach((key) => {
      if (!state[key]) return;
      totalSelected += state[key].length;
    });

    return totalSelected;
  };

  const reset = () => {
    setState(initialState);
    setLastFilters(initialState);

    for (let i = 0; i < filters.length; i++) {
      if (
        !filters[i].type.startsWith("select") &&
        !filters[i].type.startsWith("async_select")
      ) {
        $("[name=" + filters[i].id + "]").val(null);
      } else if (
        filters[i].type.startsWith("select") ||
        filters[i].type.startsWith("async_select")
      ) {
        setState((prevState) => ({
          ...prevState,
          [filters[i].id]: null,
        }));
      }
    }

    refresh(true);
  };

  const search = (
    stateParam?: Record<
      string,
      | string
      | string[]
      | number[]
      | { value: string; label: string }[]
    >
  ) => {
    const finalState = stateParam ?? state;
    setLastFilters(finalState);
    const params = buildParams(finalState);
    if (!params.some((p) => p.length > 0)) {
      setData([]);
      setFetched(false);
      setIsEmptyData(true);
      return;
    }
    if (
      utils.commons.isFunction(filterValidation) &&
      filterValidation(params) !== true
    ) {
      let result = filterValidation(params);

      sweetAlert.fire("Warning!", result, "warning");
    } else {
      let isEmptyForm = true;

      for (let key of Object.keys(finalState)) {
        if (
          finalState[key] !== null &&
          finalState[key] !== undefined
        ) {
          isEmptyForm = false;

          break;
        }
      }

      if (!isEmptyForm) {
        const params = buildParams(finalState);

        setParams(params.filter((param) => param !== "").join("&"));

        fetch(
          1,
          rowsPerPage,
          ordering,
          params.filter((param) => param !== "").join("&")
        );
      } else {
        refresh(true);
      }
    }
  };

  const mandatoryGroup = filters
    .filter((f) => f.mandatoryField)
    .map((f) => f.id);

  const isOneOfMandatoryFieldFilled =
    mandatoryGroup.length > 0
      ? mandatoryGroup.some((field) => {
          return (state[field]?.length ?? 0) > 0;
        })
      : true;

  const renderFilters = filters.map((filter, i) => {
    const excludeThisField = mandatoryGroup.filter(
      (field) => field !== filter.id
    );
    if (mandatoryGroup.some((field) => field === filter.id)) {
      let isSkipRender = false;
      excludeThisField.forEach((field) => {
        if ((state[field]?.length ?? 0) > 0) {
          isSkipRender = true;
        }
      });
      if (isSkipRender) return <></>;
    }

    if (
      filter.type === "text" ||
      filter.type === "number" ||
      filter.type === "email"
    ) {
      return (
        <div
          key={"filter" + i}
          // className={
          //   "form-group col-md-" + filterColumnReal
          // }
          className="form-group"
        >
          <div style={{ height: "40px" }}>
            <label className="col-form-label">{filter.name}</label>
          </div>
          <div>
            <CTextField
              name={filter.id}
              type={filter.type}
              onKeyDown={(e: KeyboardEvent) => {
                if (e.key === "Enter") {
                  search();
                }
              }}
              state={state}
              setState={setState}
            />
          </div>
        </div>
      );
    } else if (filter.type === "datetime" || filter.type === "date") {
      return (
        <div
          key={"filter" + i}
          // className={
          //   "form-group col-md-" + filterColumnReal
          // }
          className="form-group"
        >
          <label
            className="col-form-label"
            style={{ marginBottom: "2px" }}
          >
            {filter.name}
          </label>
          <CDateTimePicker
            name={filter.id}
            type={filter.type}
            state={state}
            modeRange={filter.modeRange}
            setState={setState}
          />
        </div>
      );
    } else if (filter.type === "select") {
      return (
        <div
          key={"filter" + i}
          // className={
          //   "form-group col-md-" + filterColumnReal
          // }
          className="form-group"
        >
          <div style={{ height: "40px" }}>
            <label className="col-form-label">{filter.name}</label>
          </div>
          <CSelect
            name={filter.id}
            state={state}
            isMulti={filter.isMulti}
            setState={setState}
            data={filter.data}
            dependsOn={filter.dependsOn}
            cacheKey={
              filter.notCached === true ? null : "filter" + filter.id
            }
            itemsExtractor={filter.itemsExtractor}
          />
        </div>
      );
    } else if (filter.type === "async_select") {
      return (
        <div
          key={"filter" + i}
          // className={
          //   "form-group col-md-" + filterColumnReal
          // }
          className="form-group"
        >
          <div
            style={{
              height: "40px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <label className="col-form-label">{filter.name}</label>
            <div style={{ paddingLeft: 3 }}>
              <CBadge
                counter={findSelectedData(state, filter.id)}
                name={filter.id}
              />
            </div>
          </div>
          <CAsyncSelect
            name={filter.id}
            state={state}
            isMulti={filter.isMulti}
            setState={setState}
            data={filter.data}
            dependsOn={filter.dependsOn}
            cacheKey={
              filter.notCached === true ? null : "filter" + filter.id
            }
            itemsExtractor={filter.itemsExtractor}
            isUseNewFilter={true}
          />
        </div>
      );
    } else if (filter.type === "checkbox") {
      const constantField = filter?.constantField;
      return (
        <div key={"filter" + i} className="form-group">
          <div
            style={{
              height: "40px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <label className="col-form-label">{filter.name}</label>
            <div style={{ paddingLeft: 3 }}>
              <CBadge
                counter={findSelectedData(state, filter.id)}
                name={filter.id}
              />
            </div>
          </div>
          <CCheckBoxMultiple
            state={state}
            name={filter.id}
            data={
              constantField ? constants?.[constantField] : filter.data
            }
            setState={setState}
          />
        </div>
      );
    }
  });

  const exportData = () => {
    const params = buildParams();
    const finalParams = [...params, `ordering=${ordering}`];

    const sweetAlert = withReactContent(
      Swal.mixin({ showConfirmButton: false })
    );

    utils.httpClient.get(
      configs.apiUrl +
        exportURL +
        "?" +
        finalParams.filter((param) => param !== "").join("&"),
      (response: any) => {
        if (response.code === 200) {
          if (
            response.message ===
            "We are unable to export this data to Excel\nbecause the previous request is not completed yet.\nPlease try again in 5 minutes."
          ) {
            sweetAlert.fire({
              customClass: { htmlContainer: "p-0" },
              html: <Waiting onClose={() => sweetAlert.close()} />,
              padding: "28px 36px",
              width: 387,
            });
          } else {
            sweetAlert.fire({
              customClass: { htmlContainer: "p-0" },
              html: <Success onClose={() => sweetAlert.close()} />,
              padding: "28px 36px",
              width: 387,
            });
          }
        } else if (response.code === 400) {
          sweetAlert.fire({
            customClass: { htmlContainer: "p-0" },
            html: <Failed onClose={() => sweetAlert.close()} />,
            padding: "28px 36px",
            width: 387,
          });
        }
      },
      (error: any, message: string) => {}
    );
  };

  useEffect(() => {
    const result = getTotalSelected(lastAppliedFilters);
    setTotalSelected(result);
  }, [lastAppliedFilters]);

  return (
    <FilterPanelStyled className="row">
      {addButton ? (
        <div className="col-12 px-0 px-md-2">
          <a
            onClick={(e) => {
              e.preventDefault();
              if (
                accesses &&
                (accesses.includes(restAccessCode + ".create") ||
                  user["is_super_user"] ||
                  (isTeamMenu && user["has_team_management_access"]))
              ) {
                props.history.push(
                  utils.commons.stripMultipleSlashes(
                    location.pathname + "/add"
                  )
                );
              } else {
                sweetAlert.fire(
                  "Error!",
                  "You have no access to do this action.",
                  "error"
                );
              }
            }}
            className="btn btn-success waves-effect waves-light float-right"
            style={{ marginBottom: "10px" }}
          >
            <i className="fas fa-plus" /> Add
          </a>
        </div>
      ) : (
        <></>
      )}
      <div className="col-12 px-0 px-md-2">
        <div
          className="card-box"
          style={{
            padding: "1.5rem 1.5rem 0rem 0rem",
            marginBottom: "0px",
            backgroundColor: "#F4F5F7",
          }}
        >
          <div className="form-row align-items-center">
            <div className="form-group col-md-12">
              <FloatContainer>
                <CButtons
                  icon="/assets/images/icons/icon_refresh.svg"
                  title="Refresh"
                  onClick={() => refresh(false, true)}
                />
                {isExportEnabled && (
                  <ButtonWithBadgeContainer>
                    <CButtons
                      icon="/assets/images/icons/icon_download.svg"
                      title="Export"
                      onClick={() => exportData()}
                    />
                  </ButtonWithBadgeContainer>
                )}

                <ButtonWithBadgeContainer>
                  <BadgeContainer>
                    <CBadge counter={totalSelected} isTotal />
                  </BadgeContainer>
                  <CButtons
                    icon="/assets/images/icons/filter.svg"
                    title="Filter"
                    onClick={() => setOpen(true)}
                  />
                </ButtonWithBadgeContainer>
              </FloatContainer>

              <div className="float-right">
                {filterButtonsRight.map((value, index) => {
                  return (
                    <a
                      key={"filter-button-right-" + index}
                      onClick={(e) => value.onClick(e, state)}
                      className={
                        "btn btn-outline-success waves-effect waves-light " +
                        value["style"]
                      }
                      style={{ marginRight: "10px" }}
                    >
                      {value["name"]}
                    </a>
                  );
                })}

                <RukiModal
                  headerClass="d-none"
                  isOpen={isExportModalInfo}
                  onClose={() => setExportModalInfo(false)}
                  modalSize={387}
                  headerTitle=""
                  headerType="close"
                  css={stylingModal}
                >
                  <Success
                    onClose={() => setExportModalInfo(false)}
                  />
                </RukiModal>
                <RukiModal
                  isOpen={isOpen}
                  onClose={() => {
                    setState(lastAppliedFilters);
                    setOpen(false);
                  }}
                  modalSize={378}
                  headerTitle="Filter"
                  headerType="close"
                  css={stylingModal}
                >
                  {renderFilters}
                  <div className="btn-container d-grid w-100">
                    <button
                      onClick={() => {
                        reset();
                      }}
                      className="btn green-outline"
                    >
                      Reset
                    </button>
                    <button
                      onClick={() => {
                        setOpen(false);
                        search();
                      }}
                      className="btn green"
                      disabled={!isOneOfMandatoryFieldFilled}
                    >
                      Apply Filter
                    </button>
                  </div>
                </RukiModal>
              </div>
            </div>
          </div>
          <label>{renderSelectedLabel()}</label>
        </div>
      </div>
    </FilterPanelStyled>
  );
};

const FilterPanelStyled = styled.div``;

const ButtonWithBadgeContainer = styled.div`
  position: relative;
  padding-left: 16px;
`;

const BadgeContainer = styled.div`
  position: absolute;
  top: 5px;
  left: 25px;
`;

const FloatContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export default withRouter(FilterPanelNew);
