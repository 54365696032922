import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import FilterPanelV2 from "components/ubold/molecules/tables/FilterPanelV2";
import PaginationV2 from "components/ubold/molecules/tables/PaginationV2";
import TableComponent from "./TableComponent";

import {
  Container,
  LabelSeparator,
  TableStyled,
  TableInformation,
  RefreshBtn,
} from "./TableDashboardBuilding.styled";

import EmptyState from "components/ubold/molecules/EmptyState/EmptyState";
import Loader from "components/ubold/atoms/Loader";
import useGlobalState from "state";
import utils from "utils";
import configs from "configs";
import moment from "moment";
import RukiModal from "components/ubold/molecules/modal/RukiModal";
import ModalDetail from "components/ubold/molecules/modal/RoomAllotment/ModalDetail";
import { ModalDetailCSSDefault } from "components/ubold/molecules/modal/RoomAllotment/ModalDetail.css";

const TableDashboardBuilding = (props) => {
  const {
    actions = [],
    cellRenderer = null,
    columns = [],
    dateDependent,
    dateDependsOn,
    dateWithDefaultValue,
    editAsDetail = false,
    filterValidators,
    freezeColumns,
    freezeTableMinWidth,
    hasSecondaryData = false,
    hideColumnNumbering = false,
    hideEditButton = false,
    isAddButtonDisabled,
    isHideRemoveButton,
    isTeamMenu = false,
    path,
    removeIdentifier,
    restAccessCode,
    rowsPerPage = [50, 100, 150],
    secondaryDataHandler = null,
    secondaryDataItem = 5,
    secondaryDataRow = 1,
    secondaryDataRowHeight = 50,
    showDetailButton = false,
    useOldResetFunc,
  } = props;

  const [totalRows, setTotalRows] = useState(1);
  const [selectedPage, setSelectedPage] = useState(1);
  const [jumpToSelectedPage, setJumpToSelectedPage] = useState(1);
  const [isEmptyData, setIsEmptyData] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [user] = useGlobalState("user");
  const [fieldAccesses] = useGlobalState("field_accesses");
  const [accesses] = useGlobalState("accesses");
  const [ordering, setOrdering] = useState("-id");
  const [rowOffset, setRowOffset] = useState(0);
  const [selectedRowPerPage, setSelectedRowPerPage] = useState(
    rowsPerPage[2]
  );
  const [resultTime, setResultTime] = useState("");
  const [emptyStateText, setEmptyStateText] = useState({
    title: "Data not found",
    description:
      "Please select other filter to see your preference data",
  });

  const [params, setParams] = useState("");
  const [data, setData] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [isFetched, setFetched] = useState(false);
  const [modalDetail, setModalDetail] = useState(false);

  const sweetAlert = withReactContent(Swal);

  /** action button configuration */
  const addButton = !isAddButtonDisabled ? true : false;
  const hideRemoveButton = isHideRemoveButton
    ? isHideRemoveButton
    : false;

  const fetch = (
    selectedPageParam,
    selectedRowPerPageParam,
    orderingParam = null,
    paramsParam = null
  ) => {
    setData([]);
    setIsEmptyData(false);
    setIsFetching(true);
    setErrorMessage(null);

    setSelectedPage(selectedPageParam);
    setSelectedRowPerPage(selectedRowPerPageParam);

    if (orderingParam !== null) {
      setOrdering(orderingParam);
    }

    const usedParams = paramsParam === null ? params : paramsParam;

    utils.httpClient.get(
      configs.apiUrl +
        path +
        "?" +
        (usedParams !== "" ? usedParams + "&" : usedParams) +
        "ordering=" +
        (orderingParam !== null ? orderingParam : ordering) +
        "&page=" +
        selectedPageParam +
        "&limit=" +
        selectedRowPerPageParam,
      (response) => {
        setRowOffset(
          (selectedPageParam - 1) * selectedRowPerPageParam
        );
        setTotalRows(response.data.count);
        setData(response.data.rows);
        setResultTime(moment().format("ddd, MMM YYYY, HH:mm "));

        if (
          response.data.rows === null ||
          response.data.rows.length === 0
        ) {
          setIsEmptyData(true);
          setEmptyStateText({
            title: "Data not found",
            description:
              "Please select other filter to see your preference data",
          });
        } else {
          setIsEmptyData(false);
        }

        setIsFetching(false);
        setFetched(true);

        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      },
      (error, message) => {
        if (
          error.response &&
          (error.response.status === 401 ||
            error.response.status === 400)
        ) {
          const nextPath = window.location.pathname;

          utils.store.remove("t");

          props.history.push("/logout?next=" + nextPath);
        } else {
          setIsFetching(false);
          setData([]);

          let messageError =
            error.response &&
            error.response.data &&
            utils.commons.isString(error.response.data)
              ? error.response.data
                  .split("\n")[1]
                  .replace("['", "")
                  .replace("']", "")
              : message;

          setErrorMessage(
            messageError && messageError !== ""
              ? messageError
              : "Unknown"
          );
        }
      }
    );
  };

  const refreshTable = (e) => {
    e.preventDefault();
    fetch(selectedPage, selectedRowPerPage, null, null);
  };

  useEffect(() => {
    setEmptyStateText({
      title: "Filter First",
      description:
        "Please select filter first to see your preference data",
    });
  }, []);

  return (
    <Container>
      <FilterPanelV2
        addButton={addButton}
        dateDependsOn={dateDependsOn}
        dateDependent={dateDependent}
        dateWithDefaultValue={dateWithDefaultValue}
        filters={props.filters}
        filterValidators={filterValidators}
        fetch={fetch}
        setParams={setParams}
        ordering={ordering}
        useOldResetFunc={useOldResetFunc}
        selectedRowPerPage={selectedRowPerPage}
      />
      <TableStyled>
        {isFetching && <Loader />}
        {isEmptyData && !isFetching && (
          <EmptyState
            iconHeight={80}
            iconWidth={110}
            imageSource={"/assets/icons/empty-state-icon.png"}
            isDashboard
            textTitle={emptyStateText.title}
            textDescription={emptyStateText.description}
          />
        )}

        {!isEmptyData && !isFetching && (
          <>
            <TableInformation>
              <label style={{ marginRight: 4 }}>
                Total {totalRows} Room(s)
              </label>
              <LabelSeparator>.</LabelSeparator>
              <label style={{ marginRight: 4 }}>
                Result Time: {resultTime}
              </label>
              <LabelSeparator>.</LabelSeparator>
              <RefreshBtn onClick={(e) => refreshTable(e)}>
                Refresh
              </RefreshBtn>
            </TableInformation>
            <TableComponent
              idTable="primary"
              actions={actions}
              accesses={accesses}
              cellRenderer={cellRenderer}
              columns={columns}
              columnsHead={columns.slice(0, freezeColumns)}
              columnsContentEnd={freezeColumns}
              data={data}
              editAsDetail={editAsDetail}
              errorMessage={errorMessage}
              fieldAccesses={fieldAccesses}
              hasSecondaryData={hasSecondaryData}
              hideColumnNumbering={hideColumnNumbering}
              hideEditButton={hideEditButton}
              hideRemoveButton={hideRemoveButton}
              isEmptyData={isEmptyData}
              isTeamMenu={isTeamMenu}
              isFetching={isFetching}
              location={location}
              ordering={ordering}
              path={path}
              removeIdentifier={removeIdentifier}
              restAccessCode={restAccessCode}
              rowOffset={rowOffset}
              secondaryDataHandler={secondaryDataHandler}
              secondaryDataItem={secondaryDataItem}
              secondaryDataRow={secondaryDataRow}
              secondaryDataRowHeight={secondaryDataRowHeight}
              selectedPage={selectedPage}
              selectedRowPerPage={selectedRowPerPage}
              setData={setData}
              showDetailButton={showDetailButton}
              style={{
                backgroundColor: "#fff",
                minWidth: freezeTableMinWidth,
                zIndex: 2,
              }}
              sweetAlert={sweetAlert}
              user={user}
              isFetched={isFetched}
              fetch={fetch}
            />
            <PaginationV2
              fetch={fetch}
              rowsPerPage={rowsPerPage}
              jumpToSelectedPage={jumpToSelectedPage}
              selectedPage={selectedPage}
              selectedRowPerPage={selectedRowPerPage}
              setJumpToSelectedPage={setJumpToSelectedPage}
              setSelectedPage={setSelectedPage}
              setSelectedRowPerPage={setSelectedRowPerPage}
              totalRows={totalRows}
            />
          </>
        )}
      </TableStyled>

      <RukiModal
        isOpen={modalDetail}
        onClose={() => setModalDetail(false)}
        modalSize={387}
        headerTitle="Detail"
        headerType="close"
        css={ModalDetailCSSDefault}
      >
        <ModalDetail onClose={() => setModalDetail(false)} />
      </RukiModal>
    </Container>
  );
};

export default withRouter(TableDashboardBuilding);
