import { withRouter } from "react-router-dom";

import TabPane from "../../../../../../components/ubold/organisms/TabPane";
import TabPaneSection from "../../../../../../components/ubold/organisms/TabPaneSection";

import CHTextView from "../../../../../../components/ubold/molecules/forms/CHTextView";
import CHTextField from "../../../../../../components/ubold/molecules/forms/CHTextField";
import CHSelect from "../../../../../../components/ubold/molecules/forms/CHSelect";
import CHAsyncSelect from "../../../../../../components/ubold/molecules/forms/CHAsyncSelect";
import CHRichTextField from "../../../../../../components/ubold/molecules/forms/CHRichTextField";
import CHDateTimePicker from "../../../../../../components/ubold/molecules/forms/CHDateTimePicker";

import utils from "../../../../../../utils";
import { genericTabProps } from "@types";

function RoomVariant(props: genericTabProps<Object>) {
  const { id, definitions, tab, state, setState } = props;

  return (
    <TabPane
      id={id}
      definitions={definitions}
      tab={tab}
      state={state}
      setState={setState}
      readOnly
    >
      <TabPaneSection style={{ padding: "25px 16px 0px" }}>
        <CHTextView name="code" title="Code" readOnly />
        <CHTextField
          name="name"
          title="Name"
          isRequired
          maxLength={150}
          readOnly
        />
        <CHAsyncSelect
          name="landlord"
          title="Landlord"
          helperText="Mandatory only for DeCentralized building"
          data="building/landlord/?__type__=select_entries"
          readOnly
        />
        <CHAsyncSelect
          name="building"
          title="Building"
          isRequired
          data="building/building?__type__=select_entries"
          readOnly
          itemsExtractor={(item: {
            id: string;
            building_name: string;
            building_type: number;
          }) => {
            return {
              label:
                item.building_name +
                " - " +
                (item.building_type === 1
                  ? "Centralized"
                  : "Decentralized"),
              value: item.id,
            };
          }}
        />
        <CHAsyncSelect
          name="room_type"
          title="Room Type"
          isRequired
          data="building/room_type/?__type__=select_entries"
          readOnly
        />
        <CHSelect
          name="ac_type"
          title="AC Type"
          data={[
            {
              label: "Central",
              value: 1,
            },
            {
              label: "Other",
              value: 2,
            },
            {
              label: "None",
              value: 3,
            },
          ]}
          readOnly
        />
        <CHTextField
          name="area_size"
          title="Area Size"
          type="number"
          readOnly
        />
        <CHTextField
          name="total_bed"
          title="Total Bed"
          type="number"
          readOnly
        />
        <CHSelect
          name="laundry_type"
          title="Laundry"
          data={[
            {
              label: "In Unit",
              value: 1,
            },
            {
              label: "In Building",
              value: 2,
            },
            {
              label: "Other",
              value: 3,
            },
            {
              label: "None",
              value: 4,
            },
          ]}
          readOnly
        />
        <CHTextField
          name="max_occupancy"
          title="Max Occupancy"
          type="number"
          readOnly
        />
        <CHTextField
          name="base_price"
          title="Base Price"
          type="number"
          readOnly
        />
        <CHTextField
          name="price"
          title="Price"
          isRequired
          type="number"
          helperText="Price should be greater than Rp. 0"
          readOnly
        />
        <CHSelect
          name="electricity"
          title="Electricity"
          data={[
            {
              label: "Centralized",
              value: 1,
            },
            {
              label: "Token",
              value: 2,
            },
          ]}
          readOnly
        />
        <CHRichTextField
          name="remarks"
          title="Remarks"
          type="number"
          readOnly
        />
        <CHSelect
          name="bathroom"
          title="Bathroom"
          data={[
            {
              label: "Inside Room",
              value: 1,
            },
            {
              label: "Outside Room",
              value: 2,
            },
            {
              label: "Shared Bathroom",
              value: 3,
            },
          ]}
          readOnly
        />
        <CHSelect
          name="gender"
          title="Gender"
          data={[
            {
              label: "All Gender",
              value: 0,
            },
            {
              label: "Male",
              value: 1,
            },
            {
              label: "Female",
              value: 2,
            },
          ]}
          readOnly
        />
        <CHTextField
          name="video_url"
          title="Video URL"
          maxLength={254}
          readOnly
        />
        <CHSelect
          name="publish_status"
          title="Publish Status"
          data={[
            {
              label: "Not Publish",
              value: 0,
            },
            {
              label: "Publish",
              value: 1,
            },
            {
              label: "Terminated",
              value: 2,
            },
          ]}
          readOnly
        />
        <CHDateTimePicker
          name="terminated_date"
          title="Terminated Date"
          type="date"
          readOnly
        />
        <CHRichTextField
          name="terminated_reason"
          title="Terminated Reason"
          readOnly
        />
        <CHSelect
          name="is_renovated"
          title="Is Renovated"
          data={[
            {
              label: "Yes",
              value: true,
            },
            {
              label: "No",
              value: false,
            },
          ]}
          readOnly
        />
        <CHTextView
          name="date_created"
          title="Date Created"
          renderer={(value: string) => {
            return value
              ? utils.formatter.dateTime(
                  value,
                  "MMMM DD, YYYY, HH:mm"
                )
              : "-";
          }}
          readOnly
        />
        <CHTextView
          name="date_updated"
          title="Date Updated"
          renderer={(value: string) => {
            return value
              ? utils.formatter.dateTime(
                  value,
                  "MMMM DD, YYYY, HH:mm"
                )
              : "-";
          }}
          readOnly
        />
      </TabPaneSection>
    </TabPane>
  );
}

export default withRouter(RoomVariant);
