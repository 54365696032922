import configBuilder from "../../../components/ubold/ConfigBuilder";

let definitions = {
  title: "Discount Banners",
  frontendPath: "/marketing/promotion/discount/discount_banner",
  baseLink: "/building/discount_banner",
  restAccessCode: "building.discount_banner",
  __table__: {
    filterColumn: 4,
    hideEditButton: true,
    removeIdentifier: "title",
    filters: [
      {
        id: "title",
        name: "Title",
        type: "text",
      },
      {
        id: "title_en",
        name: "Title EN",
        type: "text",
      },
      {
        id: "description",
        name: "Description",
        type: "text",
      },
      {
        id: "description_en",
        name: "Description EN",
        type: "text",
      },
    ],
    columns: [
      {
        id: "title",
        name: "Title",
        enableHyperlink: true,
      },
      {
        id: "title_en",
        name: "Title EN",
      },
      {
        id: "description",
        name: "Description",
        wrap: true,
        isSafeContent: true,
      },
      {
        id: "description_en",
        name: "Description EN",
        wrap: true,
        isSafeContent: true,
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
