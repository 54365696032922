import configBuilder from "../../../components/ubold/ConfigBuilder";

import utils from "../../../utils";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import configs from "../../../configs";

let definitions = {
  title: "Addons",
  frontendPath: "/building/addon",
  baseLink: "/building/addon",
  restAccessCode: "building.addon",
  linkHistory: (id) => `${definitions.frontendPath}/history/${id}`,
  __table__: {
    filterColumn: 3,
    hideEditButton: true,
    actions: [
      {
        name: "Sync to Netsuite",
        icon: "mdi-axis-lock",
        action: (row) => {
          const sweetAlert = withReactContent(Swal);

          utils.httpClient.get(
            configs.apiUrl +
              "building/addon/sync_to_netsuite/?id=" +
              row.id,
            (response) => {
              sweetAlert.fire("", response.message, "info");
            },
            (error, message) => {
              sweetAlert.fire("", message, "error");
            }
          );
        },
      },
    ],
    filters: [
      {
        id: "name",
        name: "Name",
        type: "text",
      },
      {
        id: "type",
        name: "Addon Type",
        type: "select",
        data: {
          optionField: "buildings_addon_type",
        },
      },
      {
        id: "addon_category",
        name: "Addon Category",
        type: "async_select",
        data: "building/addon_category?__type__=select_entries",
        itemsExtractor: (item) => {
          return {
            label: item.name,
            value: item.id,
          };
        },
      },
      {
        id: "date_created",
        name: "Date Created",
        type: "date",
      },
    ],
    columns: [
      {
        id: "name",
        name: "Name",
        enableHyperlink: true,
      },
      {
        id: "type",
        name: "Addon Type",
        referStateFieldName: "buildings_addon_type",
        renderer: (value, _, constants) => {
          let label = "-";
          (constants ?? []).forEach((obj) => {
            if (obj.value === parseInt(value)) {
              label = obj.label;
            }
          });
          return label;
        },
      },

      {
        id: "description",
        name: "Description",
      },
      {
        id: "date_created",
        name: "Date Created",
        renderer: (value) => {
          return utils.formatter.dateTime(value);
        },
      },
      {
        id: "date_updated",
        name: "Date Updated",
        renderer: (value) => {
          return utils.formatter.dateTime(value);
        },
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
