import React, { useEffect } from "react";

import { isMobile } from "react-device-detect";

import { Link, withRouter } from "react-router-dom";
import useGlobalState from "../../../state";
import utils from "../../../utils";

function Form(props) {
  const {
    children,
    onSubmit,
    state,
    setState,
    id,
    definitions,
    readOnly = false,
    canAddAnother = true,
  } = props;

  const [user] = useGlobalState("user");
  const [accesses] = useGlobalState("accesses");

  let errorFields = [];

  if (state["hasErrors"]) {
    for (const [key] of Object.entries(state)) {
      if (
        state[key] !== null &&
        key.indexOf("Error") !== -1 &&
        key.indexOf("Tab") === -1 &&
        key !== "hasErrors"
      ) {
        errorFields.push(
          key.replace("Error", "").replaceAll("_", " ")
        );
      }
    }
  }

  const onSubmitBuiltIn = (e, buttonFlag) => {
    definitions.onSubmit(id, state, setState, e, props, buttonFlag);
  };

  useEffect(() => {
    definitions.onInit(id, setState, user, accesses);
  }, []);

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <div className="row">
              {state["hasErrors"] ? (
                <div
                  className="col-lg-12 alert alert-danger alert-dismissible fade show"
                  role="alert"
                >
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <div
                    style={{ marginBottom: "5px" }}
                    dangerouslySetInnerHTML={{
                      __html: state["non_field_errorsError"]
                        ? "<b>Error(s)</b>: " +
                          state["non_field_errorsError"]
                        : "There are <b>error(s)</b> in form inputs, please fix them first before continue!",
                    }}
                  />
                  <div
                    style={{}}
                    dangerouslySetInnerHTML={{
                      __html:
                        errorFields.length > 0
                          ? "<b>Error fields</b>: " +
                            utils.commons.toTitleCase(
                              errorFields.join(", ")
                            )
                          : "",
                    }}
                  />
                </div>
              ) : (
                <></>
              )}
              {state["errorMessage"] ? (
                <div
                  className="col-lg-12 alert alert-danger alert-dismissible fade show"
                  role="alert"
                >
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  {state["errorMessage"]}
                </div>
              ) : (
                <></>
              )}
              {utils.commons.isArray(children) ? (
                children.map((child) => {
                  return React.cloneElement(child, {
                    state: state,
                    setState: setState,
                    restAccessCode: definitions.restAccessCode,
                  });
                })
              ) : children ? (
                React.cloneElement(children, {
                  state: state,
                  setState: setState,
                  restAccessCode: definitions.restAccessCode,
                })
              ) : (
                <></>
              )}
            </div>
            <div
              className="row mt-3 pt-2"
              style={{ borderTop: "1px solid #efefef" }}
            >
              <div className="col-lg-12 p-0">
                <Link
                  to={
                    definitions.frontendPath
                      ? definitions.frontendPath
                      : definitions.baseLink
                  }
                  className="btn btn-danger waves-effect waves-light float-left"
                  style={{ marginBottom: isMobile ? "10px" : "0px" }}
                >
                  {!readOnly ? "Cancel" : "Back"}
                </Link>
                {!readOnly ? (
                  <div className="float-right">
                    {canAddAnother ? (
                      <a
                        onClick={(e) =>
                          onSubmit
                            ? // adding props history for refresh after submit when using custom onSubmit
                              onSubmit(
                                e,
                                "Add Another",
                                props.history
                              )
                            : onSubmitBuiltIn(e, "Add Another")
                        }
                        className="btn btn-outline-success mr-1"
                        style={{
                          marginBottom: isMobile ? "10px" : "0px",
                        }}
                      >
                        Save and add another
                      </a>
                    ) : (
                      <></>
                    )}
                    <a
                      onClick={(e) =>
                        onSubmit
                          ? // adding props history for refresh after submit when using custom onSubmit
                            onSubmit(
                              e,
                              "Continue Editing",
                              props.history
                            )
                          : onSubmitBuiltIn(e, "Continue Editing")
                      }
                      className="btn btn-outline-success mr-1"
                      style={{
                        marginBottom: isMobile ? "10px" : "0px",
                      }}
                    >
                      Save and continue editing
                    </a>
                    <a
                      onClick={(e) =>
                        onSubmit
                          ? // adding props history for refresh after submit when using custom onSubmit
                            onSubmit(e, "Add", props.history)
                          : onSubmitBuiltIn(e, "Add")
                      }
                      className="btn btn-success"
                      style={{
                        marginBottom: isMobile ? "10px" : "0px",
                      }}
                    >
                      Save
                    </a>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(Form);
