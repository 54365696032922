// USERS
import User from "pages/CRM/User";
import UserAddEdit from "pages/CRM/User/AddEdit";
// ROLES
import Role from "pages/CRM/Role";
import RoleAddEdit from "pages/CRM/Role/AddEdit";
// STRUCTURES
import Structure from "pages/CRM/Structure";
import StructureAddEdit from "pages/CRM/Structure/AddEdit";
// ACCESS LIST
import Access from "pages/CRM/Access";
import AccessAddEdit from "pages/CRM/Access/AddEdit";
// FIELD ACCESS LIST
import FieldAccess from "pages/CRM/FieldAccess";
import FieldAccessAddEdit from "pages/CRM/FieldAccess/AddEdit";
// MENUS
import Menu from "pages/CRM/Menu";
import MenuAddEdit from "pages/CRM/Menu/AddEdit";
// MENU POSITIONS
import MenuPosition from "pages/CRM/MenuPosition";
import MenuPositionAddEdit from "pages/CRM/MenuPosition/AddEdit";

const CRMRoutes = [
  // USERS
  {
    path: "/crm/user",
    component: User,
  },
  {
    path: "/crm/user/(add|edit)/:id([0-9]+)?",
    component: UserAddEdit,
  },
  // ROLES
  {
    path: "/crm/role",
    component: Role,
  },
  {
    path: "/crm/role/(add|edit)/:id([0-9]+)?",
    component: RoleAddEdit,
  },
  // STRUCTURES
  {
    path: "/crm/structure",
    component: Structure,
  },
  {
    path: "/crm/structure/(add|edit)/:id([0-9]+)?",
    component: StructureAddEdit,
  },
  // ACCESS LIST
  {
    path: "/crm/access",
    component: Access,
  },
  {
    path: "/crm/access/(add|edit)/:id([0-9]+)?",
    component: AccessAddEdit,
  },
  // FIELD ACCESS LIST
  {
    path: "/crm/field_access",
    component: FieldAccess,
  },
  {
    path: "/crm/field_access/(add|edit)/:id([0-9]+)?",
    component: FieldAccessAddEdit,
  },
  // MENUS
  {
    path: "/crm/menu",
    component: Menu,
  },
  {
    path: "/crm/menu/(add|edit)/:id([0-9]+)?",
    component: MenuAddEdit,
  },
  // MENU POSITIONS
  {
    path: "/crm/menu_position",
    component: MenuPosition,
  },
  {
    path: "/crm/menu_position/(add|edit)/:id([0-9]+)?",
    component: MenuPositionAddEdit,
  },
];

export default CRMRoutes;
