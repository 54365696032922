import React from "react";

interface IGeneralModalBodyTemplateProps {
  render: React.ReactElement;
}

const GeneralModalBodyTemplate: React.FC<
  IGeneralModalBodyTemplateProps
> = ({ render }) => {
  return <React.Fragment>{render}</React.Fragment>;
};

export default GeneralModalBodyTemplate;
