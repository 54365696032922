import configBuilder from "../../../../components/ubold/ConfigBuilder";

import pageDefinitions from "../definitions";

import utils from "../../../../utils";

const definitions = {
  ...pageDefinitions,
  tabs: [
    {
      id: "addon",
      name: "Addon",
    },
    {
      id: "t_addon_buildings",
      name: "Addon Buildings",
      alwaysEditable: true,
    },
  ],
  validators: {
    name: (value) => {
      return utils.validator.validateRequired(value);
    },
    type: (value) => {
      return utils.validator.validateRequired(value);
    },
    description: (value) => {
      return utils.validator.validateRequired(value);
    },
  },
};

export default configBuilder.buildAddEditPageConfig(definitions, [
  "image_1",
  "image_2",
]);
