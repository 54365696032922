import { withRouter } from "react-router-dom";

import TabPane from "../../../../../../components/ubold/organisms/TabPane";
import TabPaneSection from "../../../../../../components/ubold/organisms/TabPaneSection";

import CHTextField from "../../../../../../components/ubold/molecules/forms/CHTextField";
import CHRadio from "../../../../../../components/ubold/molecules/forms/CHRadio";
import CHSelect from "../../../../../../components/ubold/molecules/forms/CHSelect";
import CHAsyncSelect from "../../../../../../components/ubold/molecules/forms/CHAsyncSelect";
import CHTextArea from "../../../../../../components/ubold/molecules/forms/CHTextArea";
import CHRichTextField from "../../../../../../components/ubold/molecules/forms/CHRichTextField";
import CHCheckBox from "../../../../../../components/ubold/molecules/forms/CHCheckBox";
import CHImageUpload from "../../../../../../components/ubold/molecules/forms/CHImageUpload";
import CHDateTimePicker from "../../../../../../components/ubold/molecules/forms/CHDateTimePicker";
import CHTextView from "../../../../../../components/ubold/molecules/forms/CHTextView";
import CHImageView from "../../../../../../components/ubold/molecules/forms/CHImageView";
import CHSimpleManyToMany from "../../../../../../components/ubold/molecules/forms/CHSimpleManyToMany";

import OtherInfos from "./Sections/OtherInfos";
import Location from "./Sections/Location";
import GeneralServices from "./Sections/GeneralServices";
import Attributes from "./Sections/Attributes";
import { FormBuildingPropertyListProps } from "@types";
import CQRCodeAndURL from "components/ubold/atoms/buttons/CQRCodeAndURL";

function Building(props: FormBuildingPropertyListProps) {
  const { id, definitions, tab, state, setState } = props;
  return (
    <TabPane
      id={id}
      definitions={definitions}
      tab={tab}
      state={state}
      setState={setState}
      readOnly
    >
      <TabPaneSection style={{ padding: "25px 16px 0px" }}>
        <CHTextView name="code" title="Building Code" readOnly />
        {/* Start of QR Code and URL Copy Button Section */}
        <CQRCodeAndURL
          label="Building URL and QR Code"
          url={`/place/${state.slug}?utm_campaign=offline&utm_source=offline&utm_medium=offline&utm_content=offline`}
          id={id}
        />
        {/* End of QR Code and URL Copy Button Section */}
        <CHRadio
          name="building_type"
          title="Type"
          isRequired
          readOnly
          data={[
            {
              label: "Centralized",
              value: 1,
            },
            {
              label: "Decentralized",
              value: 2,
            },
          ]}
        />
        <CHAsyncSelect
          name="building_grade"
          title="Building Grade"
          data="building/grade?__type__=select_entries&limit=10"
          readOnly
        />
        <CHAsyncSelect
          name="landlord"
          title="Building Landlord"
          isRequired
          data="building/landlord?__type__=select_entries&limit=10"
          readOnly
        />
        <CHTextField
          name="email_fmr_1"
          title="Email for monthly report (1)"
          maxLength={254}
          readOnly
        />
        <CHTextField
          name="email_fmr_2"
          title="Email for monthly report (2)"
          maxLength={254}
          readOnly
        />
        <CHTextField
          name="building_name"
          title="Name"
          maxLength={64}
          isRequired
          readOnly
        />
        <CHTextField
          name="slug"
          title="Slug"
          maxLength={100}
          readOnly
        />
        <CHTextField
          name="title_tag"
          title="Title Tag"
          maxLength={160}
          readOnly
        />
        <CHTextField
          name="title_tag_en"
          title="Title Tag EN"
          maxLength={160}
          readOnly
        />
        <CHTextArea
          name="meta_description"
          title="Meta Description"
          readOnly
        />
        <CHTextArea
          name="meta_description_en"
          title="Meta Description EN"
          readOnly
        />
        <CHRichTextField name="desc" title="Description" readOnly />
        <CHRichTextField
          name="desc_en"
          title="Description EN"
          readOnly
        />
        <CHTextField
          name="start_from"
          title="Start From"
          type="number"
          inputGroups="Number"
          readOnly
        />
        <CHRichTextField name="notes" title="Notes" readOnly />
        <CHRichTextField name="rules" title="Rules" readOnly />
        <CHCheckBox
          name="active"
          title="Active Status"
          checkboxTitle="Active"
          readOnly
          disabled
        />
        <CHImageUpload
          name="photo_thumbnail_original"
          title="Building Thumbnail"
          readOnly
        />
        <CHImageView
          name="photo_thumbnail_original"
          title="Building Thumbnail Preview"
          readOnly
        />
        <CHTextField
          name="handyman_number"
          title="Handyman Number"
          maxLength={20}
          readOnly
        />
        <CHTextField
          name="it_support_number"
          title="IT Support Number"
          maxLength={20}
          readOnly
        />
        <CHTextField
          name="housekeeper_number"
          title="House Keeper Number"
          maxLength={20}
          readOnly
        />
        <CHSimpleManyToMany
          readOnly
          name="community_associate"
          title="Community Associates"
          data="crm/user?__type__=select_entries&limit=1000&role=Community Associate"
          itemsExtractor={(row: { username: string; id: string }) => {
            return {
              label: row.username,
              value: row.id,
            };
          }}
        />
        <CHSimpleManyToMany
          readOnly
          name="customer_service"
          title="Customer Services"
          data="crm/user?__type__=select_entries&limit=1000&group_name=Customer Service"
          itemsExtractor={(row: { username: string; id: string }) => {
            return {
              label: row.username,
              value: row.id,
            };
          }}
        />
        <CHSimpleManyToMany
          readOnly
          name="housekeeper"
          title="Operations Supervisor"
          data="crm/user?__type__=select_entries&limit=1000&role=Operations Supervisor"
          itemsExtractor={(row: { username: string; id: string }) => {
            return {
              label: row.username,
              value: row.id,
            };
          }}
        />
        <CHSelect
          readOnly
          name="release_status"
          title="Release Status"
          isRequired
          data={[
            {
              value: 1,
              label: "Hold",
            },
            {
              value: 2,
              label: "Release",
            },
          ]}
        />
        <CHSelect
          readOnly
          name="publish_status"
          title="Publish Status"
          isRequired
          data={[
            {
              value: 0,
              label: "Not Publish",
            },
            {
              value: 1,
              label: "Publish",
            },
            {
              value: 2,
              label: "Terminated",
            },
          ]}
        />
        <CHDateTimePicker
          readOnly
          name="terminated_date"
          title="Terminated Date"
          type="date"
        />
        <CHRichTextField
          readOnly
          name="terminated_reason"
          title="Terminated Reason"
        />
        <CHAsyncSelect
          readOnly
          name="discount_banner"
          title="Discount Banner"
          data="building/discount_banner?__type__=select_entries&limit=10"
          itemsExtractor={(row: { title: string; id: string }) => {
            return {
              label: row.title,
              value: row.id,
            };
          }}
        />
        <CHTextField
          readOnly
          name="video_url"
          title="Video URL"
          maxLength={50}
        />
        <CHCheckBox name="rukita_option" title="RuPartner" readOnly />
      </TabPaneSection>
      <TabPaneSection accordionId="formAccordion">
        <GeneralServices />
        <Attributes />
        <Location />
        <OtherInfos />
      </TabPaneSection>
    </TabPane>
  );
}

export default withRouter(Building);
