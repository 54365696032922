import { useState, useEffect } from "react";
import { useParams, withRouter } from "react-router-dom";

import TabPaneSecondary from "../../../../../../components/ubold/organisms/TabPaneSecondary";

import COneOrManyToManyField from "../../../../../../components/ubold/molecules/forms/COneOrManyToManyField";

import utils from "../../../../../../utils";

function OrderAddonBuildings(props: any) {
  const { definitions, tab, state, setState } = props;

  const [addonList, setAddonList] = useState([]);

  let { id }: any = useParams();

  const doneStatus = [4, 5, 6];

  useEffect(() => {
    if (!state["t_addon_buildings"]) return;
    setAddonList(state["t_addon_buildings"]);
  }, [state["t_addon_buildings"]]);

  return (
    <TabPaneSecondary
      definitions={definitions}
      tab={tab}
      restAccessCodeNamespace="addon_building"
      state={state}
      setState={setState}
      readOnly={true}
    >
      <COneOrManyToManyField
        id={id}
        name={tab.id}
        readOnly={id && doneStatus.includes(state["status"])}
        restPath="order/addon_building__order/"
        relationKey="order_id"
        saveOnlyFromMainForm={true}
        index={2}
        enableListener={true}
        columns={[
          {
            name: "addon_building",
            type: "async_select",
            disabledAtEdit: true,
            isUniqueOption: true,
            title: "Addon Building",
            isRequired: true,
            data:
              "building/addon__building?__type__=select_entries&limit=1000&building=" +
              state["building"],
            itemsExtractor: (row: any) => {
              return {
                label:
                  row.name +
                  (row["payment_plan"] && row["price"]
                    ? " (" +
                      (row["payment_plan"]
                        ? row["payment_plan"]
                        : "None") +
                      " month) - " +
                      utils.formatter.currency(row["price"])
                    : ""),
                value: row.id,
              };
            },
            readOnly: true,
          },
          {
            name: "notes",
            type: "custom",
            title: "Notes",
            addonList: addonList,
            readOnly: true,
          },
          {
            name: "number",
            type: "read_only",
            maxLength: 13,
            title: "Addon ID",
            readOnly: true,
          },
          {
            name: "status",
            type: "select",
            title: "Status",
            isRequired: true,
            data: [
              {
                label: "Menunggu konfirmasi Rukita",
                value: 1,
              },
              {
                label: "Menunggu pembayaran awal",
                value: 6,
              },
              {
                label: "Memproses pembayaran awal",
                value: 7,
              },
              {
                label: "Pembayaran awal ditolak",
                value: 8,
              },
              {
                label: "Pembayaran awal kurang",
                value: 9,
              },
              {
                label: "Stok barang habis",
                value: 10,
              },
              {
                label: "Pembayaran lewat batas",
                value: 11,
              },
              {
                label: "Menunggu aktivasi",
                value: 5,
              },
              {
                label: "Sedang Berjalan",
                value: 3,
              },
              {
                label: "Unsubscribe",
                value: 12,
              },
              {
                label: "Add-ons sudah di ambil kembali",
                value: 13,
              },
            ],
            readOnly: true,
          },
          {
            name: "start_date",
            type: "date",
            title: "Start Date",
            isRequired: true,
            readOnly: true,
          },
          {
            name: "end_date",
            type: "date",
            title: "End Date",
            isRequired: true,
            readOnly: true,
          },
        ]}
      />
    </TabPaneSecondary>
  );
}

export default withRouter(OrderAddonBuildings);
