import {withRouter} from "react-router-dom";

import Page from '../../../../components/ubold/organisms/Page';

function FinanceDashboardPage() {
    return (
        <Page title="Finance Dashboard">
            Dashboard Page
        </Page>
    );
}

export default withRouter(FinanceDashboardPage);