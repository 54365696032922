import configBuilder from "../../../components/ubold/ConfigBuilder";

let definitions = {
  title: "Languages",
  frontendPath: "/admin/configuration/language",
  baseLink: "/user/language",
  restAccessCode: "user.language",
  __table__: {
    hideEditButton: true,
    filterColumn: 1,
    filters: [
      {
        id: "name",
        name: "Name",
        type: "text",
      },
    ],
    columns: [
      {
        id: "name",
        name: "Name",
        enableHyperlink: true,
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
