import { ModalProps } from "@types";
import React from "react";
import WithPortal from "../../../global/WithPortal";
import ModalTemplate from "./ModalTemplate";

const RukiModal: React.FC<ModalProps> = ({
  isOpen,
  children,
  onClose,
  css,
  headerClass,
  headerTitle,
  headerType,
  modalSize = 387,
  modalFooter = null,
}) => {
  return isOpen ? (
    <WithPortal containerID="modal-root">
      <ModalTemplate
        css={css}
        isOpen={isOpen}
        onClose={() => onClose()}
        modalSize={modalSize}
        headerClass={headerClass}
        headerTitle={headerTitle}
        headerType={headerType}
        modalFooter={modalFooter}
      >
        {children}
      </ModalTemplate>
    </WithPortal>
  ) : null;
};

export default RukiModal;
