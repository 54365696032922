import { useParams, withRouter } from "react-router-dom";

import CRadio from "../../atoms/forms/CRadio";

import useGlobalState from "../../../../state";

import utils from "../../../../utils";

function CHRadio(props) {
  const {
    name,
    title,
    isRequired = false,
    readOnly,
    restAccessCode,
    state,
    setState,
    data,
  } = props;

  const [user] = useGlobalState("user");
  const isSuperUser = user ? user.is_super_user : false;
  const [fieldAccesses] = useGlobalState("field_accesses");
  const [fieldAccessReversePolicy] = useGlobalState(
    "field_access_reverse_policy"
  );
  let { id } = useParams();

  return (
    <div
      className="form-group row mb-3"
      style={{
        display:
          !isRequired &&
          restAccessCode &&
          utils.access.isFieldHidden(
            fieldAccesses,
            restAccessCode + "." + name
          )
            ? "none"
            : "flex",
      }}
    >
      <label
        className={
          "col-md-3 col-form-label" +
          (isRequired ? " font-weight-bold" : "") +
          (state[name + "Error"] ? " text-danger" : "")
        }
      >
        {title}
        {isRequired ? "*" : ""}{" "}
        {readOnly ??
        ((id || (!id && !isRequired)) &&
          utils.access.isFieldReadOnly(
            fieldAccesses,
            restAccessCode + "." + name,
            fieldAccessReversePolicy,
            isSuperUser
          ))
          ? "(read only)"
          : ""}
      </label>
      <div className="col-md-9">
        <CRadio
          name={name}
          data={data}
          readOnly={
            readOnly ??
            ((id || (!id && !isRequired)) &&
              restAccessCode &&
              utils.access.isFieldReadOnly(
                fieldAccesses,
                restAccessCode + "." + name,
                fieldAccessReversePolicy,
                isSuperUser
              ))
          }
          state={state}
          setState={setState}
        />{" "}
        &nbsp;
        {state[name + "Error"] ? (
          <span className="text-danger" style={{ fontSize: "12px" }}>
            {state[name + "Error"]}
          </span>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default withRouter(CHRadio);
