import {withRouter} from "react-router-dom";

import AccordionPane from "../../../../../../../components/ubold/organisms/AccordionPane";

import CHSimpleManyToMany from "../../../../../../../components/ubold/molecules/forms/CHSimpleManyToMany";
import utils from "../../../../../../../utils";

function Attributes(props) {
    const {baseLink, accordionId, onSubmit, state, setState} = props;

    return (
        <AccordionPane
            id='attributes'
            name='Building Attributes'
            accordionId={accordionId}
            baseLink={baseLink}
            shown={
                !!(
                    utils.form.isFieldError(state, 'place_groups')
                )
            }
            onSubmit={onSubmit}
            state={state}
            setState={setState}>
            <CHSimpleManyToMany
                name='staff'
                title='Staffs'
                data='building/staff?__type__=select_entries&limit=1000'
            />
            <CHSimpleManyToMany
                name='amenities'
                title='Amenities'
                data='building/amenity?__type__=select_entries&limit=1000'
            />
            <CHSimpleManyToMany
                name='place_groups'
                title='Place Groups'
                data='building/place_group?__type__=select_entries&limit=1000'
            />
        </AccordionPane>
    );
}

export default withRouter(Attributes);