import { useState } from "react";

import { withRouter, useLocation, Link } from "react-router-dom";

import CSelect from "../../atoms/forms/CSelect";
import CAsyncSelect from "../../atoms/forms/CAsyncSelect";
import CDateTimePicker from "../../atoms/forms/CDateTimePicker";
import CTextField from "../../atoms/forms/CTextField";

import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

import utils from "../../../../utils";
import useGlobalState from "../../../../state";

import styled, { css } from "styled-components";

const ButtonLink = styled.a`
  ${({ hasIcon }) => {
    if (hasIcon) {
      return css`
        &:hover {
          img {
            filter: brightness(0) saturate(100%) invert(89%)
              sepia(91%) saturate(4%) hue-rotate(159deg)
              brightness(110%) contrast(99%);
          }
        }
      `;
    }
  }}
`;

function FilterPanel(props) {
  const {
    filters = [],
    filterColumn = 6,
    filterButtonsRight = [],
    filterValidation = null,
    isTeamMenu = false,
    restAccessCode,
    fetch,
    setParams,
    rowsPerPage,
    ordering,
    selectedPage,
    addButton = true,
    hideFilterButton = false,
    excludeParams = null,
  } = props;

  const initialState = {};

  for (let i = 0; i < filters.length; i++) {
    initialState[filters[i].id] = null;
  }

  const [state, setState] = useState(initialState);
  const [user] = useGlobalState("user");
  const [accesses] = useGlobalState("accesses");

  const sweetAlert = withReactContent(Swal);
  const location = useLocation();

  const renderHelperText = (helperText) => {
    return (
      <div style={{ height: "10px" }}>
        {helperText ? (
          <span className="text-muted font-13 pt-1 mb-0">
            {helperText}
          </span>
        ) : null}
      </div>
    );
  };

  const buildParams = () => {
    const params = [];

    for (let key of Object.keys(state)) {
      if (excludeParams?.length && excludeParams?.includes(key))
        continue;
      if (state[key] !== null && state[key] !== undefined) {
        if (typeof state[key] === "string") {
          if (state[key].trim() !== "") {
            params.push(key + "=" + state[key]);
          }
        } else if (typeof state[key] === "object") {
          if (state[key]?.length > 0) {
            let multipleParams = [];
            for (let i = 0; i < state[key].length; i++) {
              multipleParams.push(state[key][i].value);
            }
            params.push(`${key}=${multipleParams}`);
          } else {
            params.push(key + "=" + state[key].value);
          }
        } else if (typeof state[key] === "number") {
          if (!isNaN(state[key])) {
            params.push(key + "=" + state[key]);
          }
        } else {
          params.push(key + "=" + state[key]);
        }
      }
    }

    return params;
  };

  const refresh = (isReset = false) => {
    const params = buildParams();

    setParams(isReset ? "" : params.join("&"));

    fetch(
      selectedPage,
      rowsPerPage,
      ordering ?? "-id",
      isReset ? "" : params.join("&")
    );
  };

  const reset = () => {
    setState(initialState);

    for (let i = 0; i < filters.length; i++) {
      if (
        !filters[i].type.startsWith("select") &&
        !filters[i].type.startsWith("async_select")
      ) {
        $("[name=" + filters[i].id + "]").val(null);
      } else if (
        filters[i].type.startsWith("select") ||
        filters[i].type.startsWith("async_select")
      ) {
        setState((prevState) => ({
          ...prevState,
          [filters[i].id]: null,
        }));
      }
    }

    refresh(true);
  };

  const search = () => {
    const params = buildParams();
    if (
      utils.commons.isFunction(filterValidation) &&
      filterValidation(params) !== true
    ) {
      let result = filterValidation(params);

      sweetAlert.fire("Warning!", result, "warning");
    } else {
      let isEmptyForm = true;

      for (let key of Object.keys(state)) {
        if (state[key] !== null && state[key] !== undefined) {
          isEmptyForm = false;

          break;
        }
      }

      if (!isEmptyForm) {
        const params = buildParams();

        setParams(params.join("&"));

        fetch(1, rowsPerPage, ordering, params.join("&"));
      } else {
        refresh(true);
      }
    }
  };

  return (
    <div className="row">
      {addButton ? (
        <div className="col-12 px-0 px-md-2">
          <Link
            onClick={(e) => {
              e.preventDefault();
              if (
                accesses &&
                (accesses.includes(restAccessCode + ".create") ||
                  user["is_super_user"] ||
                  (isTeamMenu && user["has_team_management_access"]))
              ) {
                props.history.push(
                  utils.commons.stripMultipleSlashes(
                    location.pathname + "/add"
                  )
                );
              } else {
                sweetAlert.fire(
                  "Error!",
                  "You have no access to do this action.",
                  "error"
                );
              }
            }}
            className="btn btn-success waves-effect waves-light float-right"
            style={{ marginBottom: "10px" }}
          >
            <i className="fas fa-plus" /> Add
          </Link>
        </div>
      ) : (
        <></>
      )}
      <div className="col-12 px-0 px-md-2">
        <div
          className="card-box"
          style={{
            padding: "1.5rem 1.5rem 0rem 1.5rem",
            marginBottom: "0px",
          }}
        >
          <div className="form-row align-items-center">
            {filters.map((filter, i) => {
              let filterColumnReal = 12 / filterColumn;

              if (
                filter.type === "text" ||
                filter.type === "number" ||
                filter.type === "email"
              ) {
                return (
                  <div
                    key={"filter" + i}
                    className={
                      "form-group col-md-" + filterColumnReal
                    }
                  >
                    <div style={{ height: "40px" }}>
                      <label className="col-form-label">
                        {filter.name}
                      </label>
                    </div>
                    <div>
                      <CTextField
                        name={filter.id}
                        type={filter.type}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            search();
                          }
                        }}
                        state={state}
                        setState={setState}
                        defaultValue={filter.placeholder}
                      />
                    </div>
                    {renderHelperText(filter.helperText)}
                  </div>
                );
              } else if (
                filter.type === "datetime" ||
                filter.type === "date"
              ) {
                return (
                  <div
                    key={"filter" + i}
                    className={
                      "form-group col-md-" + filterColumnReal
                    }
                  >
                    <label
                      className="col-form-label"
                      style={{ marginBottom: "2px" }}
                    >
                      {filter.name}
                    </label>
                    <CDateTimePicker
                      name={filter.id}
                      type={filter.type}
                      state={state}
                      modeRange={filter.modeRange}
                      setState={setState}
                    />
                    {renderHelperText(filter.helperText)}
                  </div>
                );
              } else if (filter.type === "select") {
                return (
                  <div
                    key={"filter" + i}
                    className={
                      "form-group col-md-" + filterColumnReal
                    }
                  >
                    <div style={{ height: "40px" }}>
                      <label className="col-form-label">
                        {filter.name}
                      </label>
                    </div>
                    <CSelect
                      name={filter.id}
                      state={state}
                      isMulti={filter.isMulti}
                      setState={setState}
                      data={filter.data}
                      dependsOn={filter.dependsOn}
                      cacheKey={
                        filter.notCached === true
                          ? null
                          : "filter" + filter.id
                      }
                      itemsExtractor={filter.itemsExtractor}
                    />
                    {renderHelperText(filter.helperText)}
                  </div>
                );
              } else if (filter.type === "async_select") {
                return (
                  <div
                    key={"filter" + i}
                    className={
                      "form-group col-md-" + filterColumnReal
                    }
                  >
                    <div style={{ height: "40px" }}>
                      <label className="col-form-label">
                        {filter.name}
                      </label>
                    </div>
                    <CAsyncSelect
                      name={filter.id}
                      state={state}
                      prefetch={false}
                      isMulti={filter.isMulti}
                      setState={setState}
                      data={filter.data}
                      dependsOn={filter.dependsOn}
                      dependsOnPrefix={filter.dependsOnPrefix}
                      requiredDependsOnBeforeLoad={
                        filter.requiredDependsOnBeforeLoad
                      }
                      cacheKey={
                        filter.notCached === true
                          ? null
                          : "filter" + filter.id
                      }
                      itemsExtractor={filter.itemsExtractor}
                      customAdditionalParams={
                        filter.customAdditionalParams
                      }
                    />
                    {renderHelperText(filter.helperText)}
                  </div>
                );
              }
            })}
            <div className="form-group col-md-12">
              <div className="float-left">
                <a
                  onClick={() => refresh()}
                  className="btn btn-outline-success waves-effect waves-light"
                  style={{ marginRight: "10px" }}
                >
                  Refresh
                </a>
              </div>
              <div className="float-right">
                {filterButtonsRight.map((value, index) => {
                  if (
                    !accesses?.includes(restAccessCode + ".export") &&
                    value?.useAccessList
                  ) {
                    return <></>;
                  }
                  return (
                    <ButtonLink
                      key={"filter-button-right-" + index}
                      onClick={(e) => value.onClick(e, state)}
                      className={
                        "btn btn-outline-success waves-effect waves-light " +
                        value["style"]
                      }
                      hasIcon={value["icon"]}
                      style={{ marginRight: "10px" }}
                    >
                      {value["icon"] && (
                        <img
                          alt={value["name"] + " Icon"}
                          src={value["icon"]}
                          style={{ marginRight: "10px " }}
                        />
                      )}
                      <span>{value["name"]}</span>
                    </ButtonLink>
                  );
                })}
                {!hideFilterButton && (
                  <>
                    {" "}
                    <a
                      onClick={() => reset()}
                      className="btn btn-outline-success waves-effect waves-light"
                      style={{ marginRight: "10px" }}
                    >
                      Reset
                    </a>
                    <a
                      onClick={() => search()}
                      className="btn btn-success waves-effect waves-light"
                    >
                      Search
                    </a>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(FilterPanel);
