import configBuilder from "../../../components/ubold/ConfigBuilder";

let definitions = {
  title: "Subdistricts",
  frontendPath: "/admin/location/subdistrict",
  baseLink: "/place/subdistrict",
  restAccessCode: "place.sub_district",
  __table__: {
    hideEditButton: true,
    filterColumn: 4,
    filters: [
      {
        id: "name",
        name: "Name",
        type: "text",
      },
      {
        id: "country",
        name: "Country",
        type: "async_select",
        data: "place/country?__type__=select_entries",
      },
      {
        id: "province",
        name: "Province",
        type: "async_select",
        dependsOn: "country",
        data: "place/province?__type__=select_entries",
      },
      {
        id: "city",
        name: "City",
        type: "async_select",
        dependsOn: "province",
        data: "place/city?__type__=select_entries",
      },
      {
        id: "district",
        name: "District",
        type: "async_select",
        dependsOn: "city",
        data: "place/district?__type__=select_entries",
      },
    ],
    columns: [
      {
        id: "name",
        name: "Name",
        enableHyperlink: true,
      },
      {
        id: "district_name",
        name: "District",
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
