import React, { useEffect, useState } from "react";

import { withRouter, RouteComponentProps } from "react-router-dom";

import Select, {
  components,
  MultiValueProps,
  MultiValueRemoveProps,
  OptionProps as SelectOptionProps,
} from "react-select";

import configs from "../../../../configs";
import useGlobalState from "../../../../state";

import utils from "../../../../utils";
import { OptionProps, SelectTypes } from "@types";
import styled, { css } from "styled-components/macro";
import CheckboxComponent from "../../atoms/buttons/Checkbox";

const CSelectV2: React.FC<SelectTypes & RouteComponentProps> = (
  props
) => {
  const {
    name,
    state,
    setState,
    stateComponent,
    defaultValue = null,
    uniqueIdentifier,
    isUniqueOption = false,
    data,
    onChange,
    afterChange,
    isClearable = false,
    isMulti = false,
    isDisabled = false,
    readOnly = false,
    cacheKey = null,
    dependsOn = null,
    dependsOnHandler,
    itemsExtractor,
    filterItems,
    hideSelectedOption = true,
    isCheckbox = false,
    extraOptions = {
      atFirst: [],
      atLast: [],
    },
    defaultState,
    resetIsTriggered,
    setResetIsTriggered,
    closeMenuOnSelect = true,
    minWidth = 120,
    customStyles = {
      menuPortal: {},
    },
    disableMultiValue = false,
    removeCloseIcon = false,
  } = props;

  const [options, setOptions] = useState<OptionProps[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [constants] = useGlobalState("constant_values");

  const valueFromId = (opts: any, id: any) => {
    if (utils.commons.isArray(id)) {
      const filteredResult = opts.filter((el: any) => {
        return id?.some((obj: any) => {
          if (typeof obj === "object") {
            return obj.value === el.value;
          }
          return (
            (typeof obj === "number" ? obj : Number(obj)) === el.value
          );
        });
      });
      return filteredResult;
    } else {
      return (opts ?? []).find((o: any) => o.value === id);
    }
  };

  const defaultHandleOnChange = (data: any) => {
    if (data) {
      if (utils.commons.isArray(data)) {
        const isSelectAll = (data ?? [])?.filter(
          (item: OptionProps) => item.value === "select_all"
        );
        const isRootStateSelectAll = (state[name] ?? []).filter(
          (item: OptionProps) => item.value === "select_all"
        );
        let selectedOptions: OptionProps[] | null = [];
        if (isSelectAll?.length && !isRootStateSelectAll?.length) {
          selectedOptions = options;
        } else if (
          isRootStateSelectAll?.length &&
          !isSelectAll.length
        ) {
          selectedOptions = null;
        } else if (
          !isRootStateSelectAll?.length &&
          !isSelectAll.length &&
          data.length === options.length - 1
        ) {
          selectedOptions = options;
        } else if (
          isRootStateSelectAll?.length &&
          isSelectAll.length &&
          data.length === options.length - 1
        ) {
          selectedOptions = data.filter(
            (item: OptionProps) => item.value !== "select_all"
          );
        } else {
          selectedOptions = data;
        }
        setState((prevState: any) => ({
          ...prevState,
          [name]: selectedOptions,
        }));
      } else {
        setState((prevState: any) => ({
          ...prevState,
          [name + "_label"]: data.label,
          [name + "_object"]: data,
          [name]: data.value,
        }));
      }
    } else {
      setState((prevState: any) => ({
        ...prevState,
        [name + "_label"]: null,
        [name + "_object"]: null,
        [name]: null,
      }));
    }
    setTimeout(() => {
      if (utils.commons.isFunction(afterChange)) {
        afterChange(data);
      }
    }, 250);
  };

  const load = (dependsOn: string, callback: any) => {
    if (utils.commons.isArray(data)) {
      setOptions(data);
    } else if (utils.commons.isString(data)) {
      utils.httpClient.get(
        configs.apiUrl +
          utils.commons.stripMultipleSlashes(
            data.replace("?", "/?")
          ) +
          (dependsOn === null
            ? ""
            : "&" + dependsOn + "_id=" + state[dependsOn]),
        (data: any) => {
          const options = [];

          for (let i = 0; i < data.data.rows.length; i++) {
            if (itemsExtractor === undefined) {
              options[i] = {
                label: data.data.rows[i]["name"],
                value: data.data.rows[i]["id"],
              };

              options[i] = { ...options[i], ...data.data.rows[i] };
            } else {
              options[i] = itemsExtractor(data.data.rows[i], i);
            }
          }

          if (isUniqueOption) {
            const selectedValues = [];
            const optionsFiltered = [];

            if (stateComponent) {
              for (let i = 0; i < stateComponent.length; i++) {
                if (
                  stateComponent[i][
                    uniqueIdentifier ? uniqueIdentifier : name
                  ]
                ) {
                  selectedValues.push(
                    stateComponent[i][
                      uniqueIdentifier ? uniqueIdentifier : name
                    ]
                  );
                }
              }
            }

            for (let i = 0; i < options.length; i++) {
              if (
                !isDisabled &&
                !selectedValues.includes(options[i].value)
              ) {
                optionsFiltered.push(options[i]);
              } else if (isDisabled) {
                optionsFiltered.push(options[i]);
              } else {
                options[i].isSelected = true;
                optionsFiltered.push(options[i]);
              }
            }

            setOptions(optionsFiltered);

            if (utils.commons.isFunction(callback)) {
              callback(optionsFiltered);
            }
          } else {
            setOptions(options);

            if (utils.commons.isFunction(callback)) {
              callback(options);
            }
          }
        },
        (error: any, message: string) => {
          console.log(message);
        },
        false,
        dependsOn !== null && dependsOn !== undefined
          ? null
          : cacheKey
      );
    } else if (
      utils.commons.isObject(data) &&
      !!constants &&
      data.optionField
    ) {
      if (utils.commons.isFunction(filterItems)) {
        const newOptions = filterItems(
          constants[data.optionField],
          state
        );
        setOptions(newOptions);
      } else {
        setOptions(constants[data.optionField]);
      }
    }
  };

  const defaultStateSetup = () => {
    if (utils.commons.isArray(defaultState[name])) {
      let tempDefaultValue: any = {};
      if (defaultState[name][0].value === "select_all") {
        tempDefaultValue = options;
      } else {
        tempDefaultValue = defaultState[name];
      }
      setState((prevState: any) => ({
        ...prevState,
        [name]: tempDefaultValue,
        [`${name}_object`]: tempDefaultValue,
      }));
    } else {
      setState((prevState: any) => ({
        ...prevState,
        [name + "_label"]: defaultState[name].label,
        [name + "_object"]: defaultState[name],
        [name]: defaultState[name].value,
      }));
    }
  };

  useEffect(() => {
    if (!resetIsTriggered?.[name] || !defaultState) return;
    defaultStateSetup();
    setResetIsTriggered((prevState: { [key: string]: boolean }) => ({
      ...prevState,
      [name]: false,
    }));
  }, [resetIsTriggered?.[name]]);

  useEffect(() => {
    // useEffect to handle if defaultState is present
    if (!defaultState || !options.length) return;
    setIsLoading(true);
    defaultStateSetup();
    const timeout = setTimeout(() => setIsLoading(false), 750);
    return () => {
      clearTimeout(timeout);
    };
  }, [options]);

  useEffect(() => {
    if (dependsOn === null || dependsOn === undefined) {
      load(null, null);
    }
  }, [data]);

  useEffect(() => {
    if (utils.commons.isFunction(setState)) {
      setState((prevState: any) => ({
        ...prevState,
        [name + "_object"]: valueFromId(options, state[name]),
      }));
    }
  }, [state[name]]);

  useEffect(() => {
    if (dependsOn && utils.commons.isString(data)) {
      if (
        state[dependsOn] !== undefined &&
        state[dependsOn] !== null
      ) {
        if (utils.commons.isFunction(dependsOnHandler)) {
          dependsOnHandler(data);
        } else {
          load(dependsOn, (optionsParam: any) => {
            if (
              state[name] &&
              !utils.commons.valueExists(
                optionsParam,
                "value",
                state[name]
              )
            ) {
              setState((prevState: any) => ({
                ...prevState,
                [name]: null,
              }));
            }
          });
        }
      } else {
        if (state[dependsOn] === null) {
          setState((prevState: any) => ({
            ...prevState,
            [name]: null,
          }));
        }
      }
    }
  }, [state[dependsOn]]);

  useEffect(() => {
    if (
      utils.commons.isObject(data) &&
      !!constants &&
      !!data.optionField
    ) {
      if (utils.commons.isFunction(filterItems)) {
        const newOptions = filterItems(constants[data.optionField]);
        setOptions(newOptions);
      } else {
        const appendAtFirst = !!extraOptions?.atFirst
          ? extraOptions?.atFirst
          : [];
        const appendAtLast = !!extraOptions?.atLast
          ? extraOptions?.atLast
          : [];

        setOptions([
          ...appendAtFirst,
          ...constants[data.optionField],
          ...appendAtLast,
        ]);
      }
    }
  }, [constants]);

  const Option = (props: SelectOptionProps) => {
    if (!isCheckbox) {
      return (
        <components.Option {...props}>
          {props.children}
        </components.Option>
      );
    }
    return (
      <components.Option {...props}>
        <OptionContainer>
          <CheckboxComponent isChecked={props.isSelected} />
          <div className="checkbox-label">{props.label}</div>
        </OptionContainer>
      </components.Option>
    );
  };

  const MultiValue = (props: MultiValueProps): any => {
    const { getValue, index, children } = props;
    if (disableMultiValue) {
      return (
        <components.MultiValue {...props} isDisabled={readOnly}>
          {children}
        </components.MultiValue>
      );
    }
    if (isLoading || !options.length) return !index && "...";
    if (getValue().length === options.length && options?.length > 0)
      return !index && "All";
    return !index && `${getValue().length} item(s) selected`;
  };

  const MultiValueRemove = (props: MultiValueRemoveProps) => {
    if (removeCloseIcon) return null;
    return (
      <components.MultiValueRemove {...props}>
        {props.children}
      </components.MultiValueRemove>
    );
  };

  return (
    <SelectStyled error={state[name + "Error"]} minWidth={minWidth}>
      <Select
        styles={{
          menuPortal: (provided) => {
            if (customStyles?.menuPortal) {
              return { ...provided, ...customStyles?.menuPortal };
            }

            return { ...provided, zIndex: 2000 };
          },
          indicatorSeparator: (provided: any) => {
            return { ...provided, display: "none" };
          },
          input: (provided) => ({
            ...provided,
            padding: 0,
          }),
          control: (provided, state) => ({
            ...provided,
            outline: "none",
            border: state.isFocused
              ? "1px solid #009C96"
              : "1px solid #cccccc",
            backgroundColor: "transparent",
            boxShadow: state.isFocused
              ? "0 0 0 4px rgba(0, 187, 180, 0.2)"
              : "none",
            "&:hover": {
              borderColor: "#009C96",
            },
          }),
          option: (provided, { isFocused, isSelected }) => {
            if (isSelected) {
              if (isCheckbox) {
                return {
                  ...provided,
                  backgroundColor: "#FFF",
                  color: "#222",
                  fontSize: 12,
                };
              } else {
                return {
                  ...provided,
                  backgroundColor: "#D9EFE5",
                  color: "#00BBB4",
                  fontWeight: 400,
                  fontSize: 12,
                };
              }
            }
            if (isFocused) {
              return {
                ...provided,
                backgroundColor: "#E7E7E7",
                color: "#222",
                fontSize: 12,
                fontWeight: 400,
              };
            }
            return {
              ...provided,
              color: "#222",
              fontWeight: 400,
              fontSize: 12,
              fontStyle: "normal",
            };
          },
          valueContainer: (provided, props) => {
            return {
              ...provided,
              "&:hover": {
                border: 0,
              },
            };
          },
        }} // this is needed if react-select is used on modal component
        name={name}
        components={{
          Option,
          MultiValue,
          MultiValueRemove,
        }}
        isClearable={isClearable}
        closeMenuOnSelect={closeMenuOnSelect}
        isMulti={isMulti}
        placeholder={defaultValue || "..."}
        menuPortalTarget={document.body}
        isDisabled={isDisabled || readOnly}
        isOptionDisabled={(option) => option.disabled}
        isOptionSelected={(option) => option.isSelected}
        hideSelectedOptions={hideSelectedOption}
        value={
          state[name] === null || state[name] === undefined
            ? state[name]
            : valueFromId(options, state[name])
        }
        options={options}
        onChange={
          onChange
            ? onChange
            : (data) => {
                defaultHandleOnChange(data);
              }
        }
      />
    </SelectStyled>
  );
};

export default withRouter(CSelectV2);

const SelectStyled = styled.div<{
  error?: string;
  minWidth?: number;
}>`
  min-width: ${({ minWidth }) => minWidth}px;
  height: 40px;
  border-radius: 4px;
  outline: none;
  ${({ error }) => {
    if (error) {
      return css`
        border: 1px solid #d00c1a;
      `;
    }
  }}
`;

const OptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .checkbox-label {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;
