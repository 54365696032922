import {withRouter} from "react-router-dom";

import TabPane from "../../../../../../components/ubold/organisms/TabPane";
import TabPaneSection from "../../../../../../components/ubold/organisms/TabPaneSection";
import CHImageUpload from "../../../../../../components/ubold/molecules/forms/CHImageUpload";
import CHTextField from "../../../../../../components/ubold/molecules/forms/CHTextField";
import CHRichTextField from "../../../../../../components/ubold/molecules/forms/CHRichTextField";
import CHDateTimePicker from "../../../../../../components/ubold/molecules/forms/CHDateTimePicker";
import CHSelect from "../../../../../../components/ubold/molecules/forms/CHSelect";
import CHCheckBox from "../../../../../../components/ubold/molecules/forms/CHCheckBox";
import CHTextView from "../../../../../../components/ubold/molecules/forms/CHTextView";

function FirstTab(props) {
    const {id, definitions, tab, state, setState} = props;

    return (
        <TabPane
            id={id}
            definitions={definitions}
            tab={tab}
            state={state}
            setState={setState}>
            <TabPaneSection style={{padding: '25px 16px 0px'}}>
                <CHImageUpload
                    name='banner_picture_original'
                    title='Banner (recommendation 966 x 420 px, max size 500kB)'
                    isRequired
                />
                <CHTextField
                    name='brand_name'
                    title='Brand Name'
                    maxLength={200}
                    isRequired
                />
                <CHRichTextField
                    name='brand_information'
                    title='Brand Information (max 119 char)'
                    isRequired
                />
                <CHImageUpload
                    name='brand_logo_original'
                    title='Brand Logo (recommendation 216 x 216 px, max size 500kB)'
                    isRequired
                />
                <CHTextField
                    name='headline_of_benefit'
                    title='Headline of Benefit'
                    maxLength={115}
                    isRequired
                />
                <CHRichTextField
                    name='detail_of_benefits'
                    title='Detail of Benefits'
                    isRequired
                />
                <CHRichTextField
                    name='how_to_redeem'
                    title='How to Redeem'
                    isRequired
                />
                <CHRichTextField
                    name='tnc'
                    title='TnC'
                    isRequired
                />
                <CHDateTimePicker
                    name='date_started'
                    title='Date Started'
                    isRequired
                />
                <CHDateTimePicker
                    name='date_ended'
                    title='Date Ended'
                    isRequired
                />
                <CHSelect
                    name='benefit_category'
                    title='Benefit Category'
                    isRequired
                    data={[
                        {
                            label: 'Food & Beverage',
                            value: 1
                        },
                        {
                            label: 'Entertainment',
                            value: 2
                        },
                        {
                            label: 'Health Beauty',
                            value: 3
                        },
                        {
                            label: 'Wellness',
                            value: 4
                        },
                        {
                            label: 'Lifestyle',
                            value: 5
                        },
                        {
                            label: 'Finance & Insurance',
                            value: 6
                        },
                    ]}
                />
                <CHCheckBox
                    name='published'
                    title='Published'
                />
                <CHCheckBox
                    name='eligible_to_all_unit'
                    title='Eligible to All Unit'
                />
                <CHTextField
                    name='coupon_code'
                    title='Coupon Code'
                    maxLength={200}
                />
                <CHTextField
                    name='title_pn'
                    title='Title Push Notification (max 50 char)'
                    maxLength={50}
                    isRequired
                />
                <CHTextField
                    name='content_pn'
                    title='Content Push Notification (max 150 char)'
                    maxLength={150}
                    isRequired
                />
                <CHImageUpload
                    name='pn_picture_original'
                    title='Push Notification (recommendation ratio image 2:1, max size 500kB)'
                />
                <CHDateTimePicker
                    name='date_published'
                    title='Date Published'
                />
                <CHCheckBox
                    name='pn_blast'
                    title='PN Blast'
                />
                <CHTextView
                    name='total_view'
                    title='Total View'
                />
            </TabPaneSection>
        </TabPane>
    );
}

export default withRouter(FirstTab);