import configBuilder from "components/ubold/ConfigBuilder";

let definitions = {
  title: "Monthly Report",
  frontendPath: "/landlord/monthly_report",
  baseLink: "/landlord/monthly_report",
  restAccessCode: "landlord.monthly_report",
  __table__: {
    filterColumn: 4,
    hideEditButton: true,
    filters: [
      {
        id: "search_keyword",
        name: "Search",
        type: "text",
        placeholder: "By Year or Sender",
      },
      {
        id: "building",
        name: "Building Name",
        type: "async_select",
        data: "building/building?__type__=select_entries&is_sop_filter=1",
        itemsExtractor: (item: any) => {
          return {
            label: item.building_name,
            value: item.id,
          };
        },
      },
      {
        id: "landlord",
        name: "Landlord",
        type: "async_select",
        data: "building/landlord?__type__=select_entries",
      },
    ],
    columns: [
      {
        id: "landlord",
        name: "Landlord",
        enableHyperlink: true,
      },
      {
        id: "email_subject",
        name: "Email Subject",
        maxWidthColumn: "auto",
      },
      {
        id: "date_created_last_updated",
        name: "Date Created & Last Updated",
      },
      {
        id: "sender",
        name: "Sender",
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
