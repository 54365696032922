import utils from "../../../utils";

import configBuilder from "../../../components/ubold/ConfigBuilder";

let definitions = {
  title: "Discounts",
  frontendPath: "/marketing/promotion/discount/discount",
  baseLink: "/finance/discount",
  restAccessCode: "finance.discount",
  __table__: {
    hideEditButton: true,
    filterColumn: 3,
    filters: [
      {
        id: "name",
        name: "Name",
        type: "text",
      },
      {
        id: "types",
        name: "Type",
        type: "select",
        data: {
          optionField: "finance_discount_category",
        },
      },
      {
        id: "discount_type",
        name: "Discount Type",
        type: "select",
        data: {
          optionField: "finance_discount_type",
        },
      },
      {
        id: "date_started",
        name: "Date Started",
        type: "datetime",
      },
      {
        id: "date_ended",
        name: "Date Ended",
        type: "datetime",
      },
    ],
    columns: [
      {
        id: "name",
        name: "Name",
        enableHyperlink: true,
      },
      {
        id: "types",
        name: "Type",
        referStateFieldName: "finance_discount_category",
        renderer: (value, _, constants) => {
          let label = "-";
          (constants ?? []).forEach((obj) => {
            if (obj.value === parseInt(value)) {
              label = obj.label;
            }
          });
          return label;
        },
      },
      {
        id: "discount_type",
        name: "Discount Type",
      },
      {
        id: "date_started",
        name: "Periode",
        renderer: (value, row) => {
          return utils.formatter.dateRange(value, row["date_ended"]);
        },
      },
      {
        id: "amount",
        name: "Amount",
        renderer: (value, row) => {
          if (row["discount_type"] === "flat") {
            return utils.formatter.currency(value);
          } else {
            return value + "%";
          }
        },
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
