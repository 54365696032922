import {useParams, withRouter} from "react-router-dom";

import TabPaneSecondary from "../../../../../../components/ubold/organisms/TabPaneSecondary";

import COneOrManyToManyField from "../../../../../../components/ubold/molecules/forms/COneOrManyToManyField";

function StructureUsers(props) {
    const {definitions, tab, state, setState} = props;

    let {id} = useParams();

    return (
        <TabPaneSecondary
            definitions={definitions}
            tab={tab}
            restAccessCodeNamespace='user'
            state={state}
            setState={setState}>
            <COneOrManyToManyField
                id={id}
                name={tab.id}
                restPath='crm/structure__user/'
                relationKey='structure_id'
                columns={[
                    {
                        name: 'user',
                        type: 'async_select',
                        title: 'User',
                        isRequired: true,
                        disabledAtEdit: true,
                        isUniqueOption: true,
                        data: 'crm/user/?__type__=select_entries',
                        itemsExtractor: value => {
                            return {
                                label: value.username,
                                value: value.id
                            }
                        }
                    },
                    {
                        name: 'note',
                        type: 'text',
                        title: 'Note',
                        maxLength: 255
                    },
                ]}
            />
        </TabPaneSecondary>
    );
}

export default withRouter(StructureUsers);