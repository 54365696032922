import utils from "../../../../utils";

import configBuilder from "../../../../components/ubold/ConfigBuilder";

import pageDefinitions from "../definitions";

const definitions = {
  ...pageDefinitions,
  linkHistory: (id) => `${definitions.frontendPath}/history/${id}`,
  tabs: [
    {
      id: "order",
      name: "Order",
    },
    {
      id: "t_discount_orders",
      name: "Additional Discounts",
      alwaysEditable: true,
      keyAdjuster: "checkout_date",
      keyToAdjust: ["end_date"],
      useCleanUpState: true,
    },
    {
      id: "t_addon_orders",
      name: "AddOn Orders",
      alwaysEditable: true,
      keyAdjuster: "checkout_date",
      keyToAdjust: ["end_date"],
      useCleanUpState: true,
    },
    /** Hide this as requested on RTD-4553 */
    // {
    //   id: "t_service_schedules",
    //   name: "Service Schedules",
    //   alwaysEditable: true,
    // },
    {
      id: "t_price_updates",
      name: "Price Update",
      alwaysEditable: true,
      useCleanUpState: true,
    },
  ],
  reloadAfterAdd: true,
  validators: {
    deposit_holder: (value) => {
      // we will make it required in the future
      return utils.validator.validateRequired(value);
    },
    tenant: (value) => {
      return utils.validator.validateRequired(value);
    },
    building: (value) => {
      return utils.validator.validateRequired(value);
    },
    room_variant: (value) => {
      return utils.validator.validateRequired(value);
    },
    room: (value, state) => {
      const roomValue = utils.validator.validateRequired(value);

      if (
        state["building_label"] &&
        state["building_label"].indexOf("Centralized") >= 0 &&
        roomValue !== true
      ) {
        return "Room is required if building is Centralized";
      } else {
        return true;
      }
    },
    tenant_category: (value) => {
      return utils.validator.validateRequired(value);
    },
    order_status: (value) => {
      return utils.validator.validateRequired(value);
    },
    reason_of_check_out: (value, state) => {
      if (state["checkout_date"] && state["order_status"] !== 5) {
        return utils.validator.validateRequired(value);
      }

      return true;
    },
    checkout_category: (value, state) => {
      if (state["checkout_date"] && state["order_status"] !== 5) {
        return utils.validator.validateRequired(value);
      }

      return true;
    },
    checkout_reason: (value, state) => {
      if (state["checkout_date"] && state["order_status"] !== 5) {
        return utils.validator.validateRequired(value);
      }

      return true;
    },
    status_of_done: (value) => {
      return utils.validator.validateRequired(value);
    },
    checkin_date: (value) => {
      return utils.validator.validateRequired(value);
    },
    checkout_date: (value, state) => {
      if (state["subscription_type"] === 2) {
        return utils.validator.validateRequired(value);
      }
      return true;
    },
    discount_notes: (value, state) => {
      const discountNotesValue =
        utils.validator.validateRequired(value);

      if (
        state["discount_amount"] > 0 &&
        discountNotesValue !== true
      ) {
        return "Discount Notes is Required";
      } else {
        return true;
      }
    },
    checkin_to_landlord: (value, state) => {
      if (state["tenant_category"] === 2) {
        return utils.validator.validateRequired(value);
      }
      return true;
    },
    price_from_landlord: (value, state) => {
      if (state["tenant_category"] === 2) {
        return utils.validator.validateRequired(value);
      }
      return true;
    },
    last_payment_to_landlord: (value, state) => {
      if (state["tenant_category"] === 2) {
        return utils.validator.validateRequired(value);
      }
      return true;
    },
    subscription_type: (value) => {
      return utils.validator.validateRequired(value);
    },
    // 29/09/2023: remove form validation monthly commitment
    // monthly_commitment: (value) => {
    //   if (value === 1) {
    //     return "Please fill monthly commitment with either 0 or more than 1";
    //   }
    //   return value >= 0;
    // },
    signed_by_1: (value, state) => {
      if (state["order_status"] !== 5) {
        if (state["source"] !== 3) {
          return utils.validator.validateRequired(value);
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    signed_date: (value, state) => {
      // if (state["order_status"] !== 5) {
      //   if (state["source"] !== 3) {
      //     return utils.validator.validateRequired(value);
      //   } else {
      //     return true;
      //   }
      // } else {
      //   return true;
      // }
      return true;
    },
  },
};

export default configBuilder.buildAddEditPageConfig(
  definitions,
  [],
  ["created_by", "date_created", "date_updated"]
);
