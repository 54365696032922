import { useState } from "react";

import { useParams, withRouter } from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import Form from "../../../../components/ubold/organisms/Form";

import CVTextField from "../../../../components/ubold/molecules/forms/CVTextField";
import CVSelect from "../../../../components/ubold/molecules/forms/CVSelect";
import CVAsyncSelect from "../../../../components/ubold/molecules/forms/CVAsyncSelect";

import definitions from "./definitions";
import CVRichTextField from "components/ubold/molecules/forms/CVRichTextField";
import CVCheckBox from "components/ubold/molecules/forms/CVCheckBox";
import CVImageView from "components/ubold/molecules/forms/CVImageView";

function DistrictViewPage() {
  let { id }: { id: string } = useParams();

  const initialState = {};

  const [state, setState] = useState(initialState);

  return (
    <Page
      title={"Detail of " + definitions.title}
      restAccessCode={definitions.restAccessCode}
    >
      <Form
        id={id}
        definitions={definitions}
        readOnly
        state={state}
        setState={setState}
      >
        <CVTextField
          name="name"
          title="Name"
          maxLength={50}
          isRequired
          readOnly
        />
        <CVAsyncSelect
          name="country"
          title="Country"
          data="place/country?__type__=select_entries"
          isRequired
          readOnly
        />
        <CVSelect
          name="province"
          title="Province"
          dependsOn="country"
          data="place/province?__type__=select_entries"
          isRequired
          readOnly
        />
        <CVSelect
          name="city"
          title="City"
          dependsOn="province"
          data="place/city?__type__=select_entries"
          isRequired
          readOnly
        />
        <CVTextField
          name="meta_title"
          title="Meta Title"
          maxLength={255}
          readOnly
        />
        <CVTextField
          name="meta_title_en"
          title="Meta Title EN"
          maxLength={255}
          readOnly
        />
        <CVRichTextField
          name="meta_description"
          title="Meta Description"
          readOnly
        />
        <CVRichTextField
          name="meta_description_en"
          title="Meta Description EN"
          readOnly
        />
        <CVRichTextField name="description" title="Description" />
        <CVRichTextField
          name="description_en"
          title="Description EN"
        />
        <CVCheckBox name="published" title="Published" readOnly />
        <CVTextField
          name="sequence_number"
          title="Sequence"
          readOnly
        />
        <CVImageView
          name="photo_header_original"
          title="Photo Header"
          readOnly
        />
      </Form>
    </Page>
  );
}

export default withRouter(DistrictViewPage);
