import { useParams, withRouter } from "react-router-dom";

import TabPaneSecondary from "../../../../../../components/ubold/organisms/TabPaneSecondary";

import COneOrManyToManyField from "../../../../../../components/ubold/molecules/forms/COneOrManyToManyField";

function UserRole(props: any) {
  const { definitions, tab, state, setState } = props;

  let { id }: { id: string } = useParams();

  return (
    <TabPaneSecondary
      definitions={definitions}
      tab={tab}
      restAccessCodeNamespace="role"
      state={state}
      setState={setState}
    >
      <COneOrManyToManyField
        id={id}
        name={tab.id}
        readOnly
        restPath="team/member_role/"
        relationKey="user_id"
        columns={[
          {
            name: "role",
            type: "async_select",
            title: "Role",
            isRequired: true,
            disabledAtEdit: true,
            data: "team/role?__type__=select_entries",
            isReadOnly: true,
          },
          {
            name: "note",
            type: "textarea",
            title: "Note",
            maxLength: 255,
            isReadOnly: true,
          },
        ]}
      />
    </TabPaneSecondary>
  );
}

export default withRouter(UserRole);
