import { withRouter } from "react-router-dom";

import AccordionPane from "../../../../../../../components/ubold/organisms/AccordionPane";

import CHSelect from "../../../../../../../components/ubold/molecules/forms/CHSelect";
import CHLatitudeLongitude from "../../../../../../../components/ubold/molecules/forms/CHLatitudeLongitude";
import CHTextField from "../../../../../../../components/ubold/molecules/forms/CHTextField";

import utils from "../../../../../../../utils";
import CHAsyncSelect from "../../../../../../../components/ubold/molecules/forms/CHAsyncSelect";
import { useEffect, useState } from "react";
import configs from "../../../../../../../configs";
import axios from "axios";
import httpClient from "utils/HttpClient";
import Select from "react-select";

function Location(props) {
  const [cityTags, setCityTags] = useState([]);
  const [selectedCityTags, setSelectedCityTags] = useState([]);
  const [districtTags, setDistrictTags] = useState([]);
  const [selectedDistrictTags, setSelectedDistrictTags] = useState(
    []
  );
  const [subDistrictTags, setSubDistrictTags] = useState([]);
  const [selectedSubDistrictTags, setSelectedSubDistrictTags] =
    useState([]);

  const { baseLink, accordionId, onSubmit, state, setState } = props;

  useEffect(() => {
    // Save State
    const taggingCity = selectedCityTags.map((item) => {
      return item.value;
    });
    const taggingDistrict = selectedDistrictTags.map((item) => {
      return item.value;
    });
    const taggingSubDistrict = selectedSubDistrictTags.map((item) => {
      return item.value;
    });
    setState((prevState) => ({
      ...prevState,
      tagging_city: taggingCity,
      tagging_district: taggingDistrict,
      tagging_subdistrict: taggingSubDistrict,
    }));
  }, [
    selectedCityTags,
    selectedDistrictTags,
    selectedSubDistrictTags,
  ]);

  useEffect(() => {
    // Read State
    if (state.original___tagging_city) {
      const cityIds = state.tagging_city.map((item) => item.value);
      const districtIds = state.tagging_district.map(
        (item) => item.value
      );
      getCityTagsOption(state.province).then(() => {
        setSelectedCityTags(state.original___tagging_city);
      });
      getDistrictTagsOption(cityIds).then(() => {
        setSelectedDistrictTags(state.original___tagging_district);
      });
      getSubDistrictTagsOption(districtIds).then(() => {
        setSelectedSubDistrictTags(
          state.original___tagging_subdistrict
        );
      });
    } else if (state.province) {
      getCityTagsOption(state.province);
    }
  }, [state.original___tagging_city, state.province]);

  const getCityTagsOption = async (id) => {
    return axios
      .get(
        configs.apiUrl +
          `place/city/?__type__=select_entries&only_with_buildings=true&province_id=${id}`,
        httpClient._getConfig()
      )
      .then(({ data }) => {
        const options = data.data.rows.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        });
        setCityTags(options);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDistrictTagsOption = async (ids) => {
    return axios
      .get(
        configs.apiUrl +
          `place/district/?__type__=select_entries&only_with_buildings=true&city_id=${ids}`,
        httpClient._getConfig()
      )
      .then(({ data }) => {
        const options = data.data.rows.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        });
        setDistrictTags(options);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSubDistrictTagsOption = async (ids) => {
    return axios
      .get(
        configs.apiUrl +
          `place/subdistrict/?__type__=select_entries&only_with_buildings=true&district_id=${ids}`,
        httpClient._getConfig()
      )
      .then(({ data }) => {
        const options = data.data.rows.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        });
        setSubDistrictTags(options);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <AccordionPane
      id="location"
      name="Location"
      accordionId={accordionId}
      baseLink={baseLink}
      onSubmit={onSubmit}
      shown={
        !!(
          utils.form.isFieldError(state, "country") ||
          utils.form.isFieldError(state, "province") ||
          utils.form.isFieldError(state, "city") ||
          utils.form.isFieldError(state, "district") ||
          utils.form.isFieldError(state, "subdistrict") ||
          utils.form.isFieldError(state, "lat") ||
          utils.form.isFieldError(state, "lon") ||
          utils.form.isFieldError(state, "address") ||
          utils.form.isFieldError(state, "postal_code") ||
          utils.form.isFieldError(state, "neighborhood")
        )
      }
      state={state}
      setState={setState}
    >
      <CHSelect
        name="country"
        title="Country"
        isRequired
        data="place/country?__type__=select_entries&limit=1000"
      />
      <CHAsyncSelect
        name="province"
        title="Province"
        isRequired
        data="place/province?__type__=select_entries&limit=10"
        dependsOn="country"
        dependsOnStartFirst={true}
      />
      <CHAsyncSelect
        name="city"
        title="City"
        isRequired
        data="place/city?__type__=select_entries&limit=10"
        dependsOn="province"
        afterChange={() => {
          getCityTagsOption(state.province);
        }}
      />
      <CHAsyncSelect
        name="district"
        title="District"
        isRequired
        data="place/district?__type__=select_entries&limit=10"
        dependsOn="city"
      />
      <CHAsyncSelect
        name="subdistrict"
        title="Sub District"
        isRequired
        data="place/subdistrict?__type__=select_entries&limit=10"
        dependsOn="district"
      />
      <CHLatitudeLongitude
        nameLatitude="lat"
        nameLongitude="lon"
        isRequired
        title="Coordinate"
      />
      <CHTextField
        name="address"
        isRequired
        title="Address"
        maxLength={100}
      />
      <CHTextField
        name="postal_code"
        title="Postal Code"
        isRequired
        type="number"
        maxLength={5}
      />
      <CHTextField
        name="neighborhood"
        title="Neighborhood"
        isRequired
        maxLength={120}
        helperText="Comma separated eg: `cawang, tebet`"
      />
      <div>
        <p
          style={{
            fontSize: "18px",
            fontWeight: "bold",
          }}
        >
          Building Location Tagging
        </p>
        <p>
          Here, you can link your building to many places like cities
          and areas. This helps people see your building when they
          look at nearby ones.
        </p>
        <div>
          <p>Steps:</p>
          <ol>
            <li>
              City Tags: First, fill in the &apos;City&apos; field
              above.
            </li>
            <li>
              District Tags: After adding a city tags, you can fill in
              the &apos;District Tags&apos;.
            </li>
            <li>
              Subdistrict Tags: Once you&apos;ve added a district, you
              can then add &apos;Subdistrict Tags&apos;.
            </li>
          </ol>
        </div>
        <hr />
        <div className="form-group row mb-3">
          <label
            htmlFor="building_type"
            className={"col-md-3 col-form-label"}
          >
            City Tags
          </label>
          <Select
            isDisabled={!state.province}
            value={selectedCityTags}
            isMulti
            name="cityTags"
            options={cityTags}
            className="basic-multi-select mx-2"
            classNamePrefix="select"
            styles={{
              container: (provided) => ({
                ...provided,
                flex: 1,
              }),
            }}
            onChange={(e) => {
              // merge ids to 1,2 string format
              const ids = e.map((item) => item.value).join(",");
              setSelectedCityTags(e);
              getDistrictTagsOption(ids);
            }}
          />
        </div>
        <div className="form-group row mb-3">
          <label
            htmlFor="building_type"
            className={"col-md-3 col-form-label"}
          >
            District Tags
          </label>
          <Select
            isDisabled={selectedCityTags.length === 0}
            value={selectedDistrictTags}
            isMulti
            name="districtTags"
            options={districtTags}
            className="basic-multi-select mx-2"
            classNamePrefix="select"
            styles={{
              container: (provided) => ({
                ...provided,
                flex: 1,
              }),
            }}
            onChange={(e) => {
              // merge ids to 1,2 string format
              const ids = e.map((item) => item.value).join(",");
              setSelectedDistrictTags(e);
              getSubDistrictTagsOption(ids);
            }}
          />
        </div>
        <div className="form-group row mb-3">
          <label
            htmlFor="building_type"
            className={"col-md-3 col-form-label"}
          >
            Subdistrict Tags
          </label>
          <Select
            isDisabled={selectedDistrictTags.length === 0}
            value={selectedSubDistrictTags}
            isMulti
            name="subDistrictTags"
            options={subDistrictTags}
            className="basic-multi-select mx-2"
            classNamePrefix="select"
            styles={{
              container: (provided) => ({
                ...provided,
                flex: 1,
              }),
            }}
            onChange={(e) => {
              setSelectedSubDistrictTags(e);
            }}
          />
        </div>
      </div>
    </AccordionPane>
  );
}

export default withRouter(Location);
