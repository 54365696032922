import utils from "../../../../utils";

import configBuilder from '../../../../components/ubold/ConfigBuilder';

import pageDefinitions from "../definitions";

const definitions = {...pageDefinitions,
    validators: {
        type: (value) => {
            return utils.validator.validateRequired(value);
        },
        code: (value) => {
            if(value && !/^[a-z._0-9]+$/.test(value)) {
                return 'Access code format is not correct.'
            } else {
                return utils.validator.validateRequired(value);
            }
        },
    },
}

export default configBuilder.buildAddEditPageConfig(definitions);