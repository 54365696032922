import { useState } from "react";
import { useParams, withRouter } from "react-router";

import Page from "../../../../components/ubold/organisms/Page";
import definitions from "./definitions";
import TabHeaders from "../../../../components/ubold/organisms/TabHeaders";
import TabContent from "../../../../components/ubold/organisms/TabContent";
import TabbedForm from "../../../../components/ubold/organisms/TabbedForm";

import Product from "./Tabs/Product";
import CategoryProducts from "./Tabs/CategoryProducts";
import PhotoProducts from "./Tabs/PhotoProducts";

function CAddEditPage() {
  let { id } = useParams();

  const initialState = {};

  const [state, setState] = useState(initialState);

  return (
    <Page
      title={`${id === undefined ? "Add New" : "Edit"} ${
        definitions.title
      }`}
      restAccessCode={definitions.restAccessCode}
    >
      <TabbedForm
        id={id}
        state={state}
        setState={setState}
        definitions={definitions}
      >
        <TabHeaders />
        <TabContent>
          <Product />
          <CategoryProducts />
          <PhotoProducts />
        </TabContent>
      </TabbedForm>
    </Page>
  );
}

export default withRouter(CAddEditPage);
