import { withRouter } from "react-router-dom";

import CTextField from "../../atoms/forms/CTextField";

import useGlobalState from "../../../../state";

import utils from "../../../../utils";
import styled from "styled-components";

function CVTextField(props) {
  const {
    name,
    title,
    type = "text",
    restAccessCode,
    autoComplete = "off",
    isRequired = false,
    maxLength = null,
    formColumn = 2,
    overrideLayout = null,
    helperText = null,
    state,
    setState,
    validate,
    readOnly,
    min,
    isParkingAddon = false,
    defaultValue = null,
    useCurrencyFormatHelper = false,
    uppercase = false,
  } = props;

  let formColumnReal = 12 / formColumn;

  const [user] = useGlobalState("user");
  const isSuperUser = user ? user.is_super_user : false;
  const [fieldAccesses] = useGlobalState("field_accesses");
  const [fieldAccessReversePolicy] = useGlobalState(
    "field_access_reverse_policy"
  );

  return (
    <div
      className={
        "col-lg-" + (overrideLayout ? overrideLayout : formColumnReal)
      }
      style={{
        display:
          !isRequired &&
          restAccessCode &&
          utils.access.isFieldHidden(
            fieldAccesses,
            restAccessCode + "." + name
          )
            ? "none"
            : "unset",
      }}
    >
      <div className="form-group mb-3">
        <label
          className={
            (isRequired ? " font-weight-bold" : "") +
            (state[name + "Error"] ? " text-danger" : "")
          }
        >
          {title}
          {isRequired ? "*" : ""}{" "}
          {readOnly ??
          utils.access.isFieldReadOnly(
            fieldAccesses,
            restAccessCode + "." + name,
            fieldAccessReversePolicy,
            isSuperUser
          )
            ? "(read only)"
            : ""}
        </label>
        &nbsp;
        <CTextField
          name={name}
          type={type}
          state={state}
          autoComplete={autoComplete}
          readOnly={
            readOnly ??
            (restAccessCode &&
              utils.access.isFieldReadOnly(
                fieldAccesses,
                restAccessCode + "." + name,
                fieldAccessReversePolicy,
                isSuperUser
              ))
          }
          maxLength={maxLength}
          setState={setState}
          validate={validate}
          min={min}
          isParkingAddon={isParkingAddon}
          defaultValue={defaultValue}
          uppercase={uppercase}
        />
        {helperText && helperText !== "" ? (
          <span
            className="text-muted font-13 pt-1 mb-0"
            dangerouslySetInnerHTML={{ __html: helperText }}
          />
        ) : (
          ""
        )}
        {helperText && helperText !== "" && state[name + "Error"] ? (
          <br />
        ) : (
          <></>
        )}
        <InformationContainer>
          {state[name + "Error"] ? (
            <span
              className="text-danger"
              style={{ fontSize: "12px" }}
            >
              {state[name + "Error"]}
            </span>
          ) : (
            <></>
          )}
          {useCurrencyFormatHelper ? (
            <span
              className="text-muted font-13 mb-0"
              dangerouslySetInnerHTML={{
                __html: utils.formatter.currency(state[name] ?? 0),
              }}
            />
          ) : (
            <></>
          )}
        </InformationContainer>
      </div>
    </div>
  );
}

export default withRouter(CVTextField);

const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
