import { useEffect, useRef, useState } from "react";
import { useLocation, useParams, withRouter } from "react-router-dom";

import { AsyncPaginate } from "react-select-async-paginate";
import {
  InputActionMeta,
  components,
  NoticeProps,
} from "react-select";

import axios from "axios";

import configs from "../../../../configs";
import httpClient from "../../../../utils/HttpClient";

import utils from "../../../../utils";
import styled from "styled-components";

function CAsyncSelectV2(props: any) {
  const {
    name,
    state,
    setState,
    stateComponent,
    inclusionKey = null,
    placeholder = null,
    isUniqueOption = false,
    uniqueIdentifier,
    data,
    afterChange,
    isMulti = false,
    dependsOn = null,
    onChange = null,
    isDisabled = false,
    readOnly = false,
    itemsExtractor,
    isUseNewFilter = false,
    hideSelectedOptions = false,
    resetIsTriggered,
    setResetIsTriggered = () => {},
    defaultState,
    listenParam,
    customStyles = {
      menuPortal: {},
    },
    onInputChange = null,
    isInputRequired = false,
    debounceTimeout = 500,
    customNoOptionMessage = "No Options",
  } = props;
  const { search } = useLocation();
  const urlQueryParams = new URLSearchParams(search);
  const hasSetInitialState = useRef(false);
  const [options, setOptions] = useState([]);
  const [key, setKey] = useState("");
  const [inputValueContainer, setInputValueContainer] =
    useState<string>("");

  const [isFirstRender, setIsFirstRender] = useState<boolean>(
    search.length === 0
  );

  const hasError = state[name + "Error"];

  const valueFromId = (opts: any, selectedValue: any) => {
    if (selectedValue?.length > 0) {
      const filteredResult = opts.filter((el: any) => {
        return selectedValue.some((obj: any) => {
          return obj.value === el.value;
        });
      });
      return filteredResult;
    } else {
      const result = opts.find((o: any) => o.value === selectedValue);
      return result;
    }
  };

  const getOptSelectedValue = () => {
    if (
      state[name] === null ||
      state[name] === undefined ||
      isMulti
    ) {
      return state[name];
    } else {
      return valueFromId(options, state[name]);
    }
  };

  const updateWithoutDupe = (state: any, selectedVal: any) => {
    if (state) {
      const originState = new Set(state.map((x: any) => x.value));

      const isNotDuplicate = selectedVal.some(
        (x: any) => !originState.has(x.value)
      );
      // this is remove option from multiple selected option
      if (originState.size - (selectedVal?.length ?? 0) === 1)
        return setState((prevState: any) => ({
          ...prevState,
          [name]: selectedVal,
        }));
      // end of scope
      if (!isNotDuplicate) return;
      setState((prevState: any) => ({
        ...prevState,
        [name]: selectedVal,
      }));
    } else {
      setState((prevState: any) => ({
        ...prevState,
        [name]: selectedVal,
      }));
    }
  };

  let { id }: { id: string } = useParams();
  const urlParams = new URLSearchParams(data);
  const limitParam = urlParams.has("limit") ? "" : "&limit=10";
  const getParamInclusion = () => {
    let inclusionParam = "";
    if (typeof listenParam === "string") {
      inclusionParam = urlQueryParams.get(listenParam)
        ? `&__inclusion__=${urlQueryParams.get(listenParam)}`
        : "";
    }
    if (isMulti) return inclusionParam;
    if (typeof state[name] === "number") {
      inclusionParam = `&__inclusion__=${state[name]}`;
      return inclusionParam;
    }
    if (state[name]?.length > 0) {
      let arrParam: any[] = [];
      state[name].forEach((element: any) => {
        arrParam.push(element.value);
      });

      inclusionParam = `&__inclusion__=${arrParam}`;
      return inclusionParam;
    }
    return inclusionParam;
  };

  const loadOptions = async (value: any, prevOptions: any) => {
    let loadOptionsResult: { options: any[]; hasMore: boolean } = {
      options: [],
      hasMore: false,
    };
    if (isInputRequired && !inputValueContainer) {
      return loadOptionsResult;
    }
    try {
      if (
        (value === "" || value.length >= 1) &&
        utils.commons.isString(data) &&
        (id === null ||
          id === undefined ||
          (id &&
            state["id"] !== null &&
            ((!stateComponent && state["id"] !== undefined) ||
              stateComponent)))
      ) {
        if (
          dependsOn === null ||
          (state[dependsOn] !== undefined &&
            state[dependsOn] !== null)
        ) {
          let response = await axios.get(
            configs.apiUrl +
              utils.commons.stripMultipleSlashes(
                data.replace("?", "/?")
              ) +
              (dependsOn === null ||
              state[dependsOn] === undefined ||
              state[dependsOn] === null
                ? ""
                : "&" + dependsOn + "_id=" + state[dependsOn]) +
              getParamInclusion() +
              (inclusionKey !== null
                ? "&__inclusion_key__=" + inclusionKey
                : "") +
              "&page=" +
              (Math.ceil(prevOptions.length / 10) + 1) +
              limitParam +
              (value !== "" ? "&keyword=" + value : ""),
            httpClient._getConfig()
          );

          let nextOptions = [];

          for (let i = 0; i < response.data.data.rows.length; i++) {
            // if state[name] is array means the select option or isMulti is true
            if (utils.commons.isArray(state[name]) && isMulti) {
              let shouldSkip = false;
              (state[name] ?? []).forEach((filter: any) => {
                if (response.data.data.rows[i].id === filter.value) {
                  shouldSkip = true;
                }
              });
              if (shouldSkip) {
                continue;
              }
            }
            if (itemsExtractor === undefined) {
              nextOptions[i] = {
                label: response.data.data.rows[i]["name"],
                value: response.data.data.rows[i]["id"],
              };
              nextOptions[i] = {
                ...nextOptions[i],
                ...response.data.data.rows[i],
              };
            } else {
              nextOptions[i] = itemsExtractor(
                response.data.data.rows[i],
                i
              );
            }
          }

          nextOptions = nextOptions.filter(Boolean);

          if (isUniqueOption) {
            const selectedValues = [];
            const optionsFiltered = [];

            if (stateComponent) {
              for (let i = 0; i < stateComponent.length; i++) {
                if (
                  stateComponent[i][
                    uniqueIdentifier ? uniqueIdentifier : name
                  ]
                ) {
                  selectedValues.push(
                    stateComponent[i][
                      uniqueIdentifier ? uniqueIdentifier : name
                    ]
                  );
                }
              }
            }

            for (let i = 0; i < nextOptions.length; i++) {
              if (
                !isDisabled &&
                !selectedValues.includes(nextOptions[i].value)
              ) {
                optionsFiltered.push(nextOptions[i]);
              } else if (isDisabled) {
                optionsFiltered.push(nextOptions[i]);
              } else {
                nextOptions[i].isSelected = true;
                optionsFiltered.push(nextOptions[i]);
              }
            }

            setOptions(optionsFiltered);
            loadOptionsResult = {
              options: optionsFiltered,
              hasMore: optionsFiltered.length >= 10,
            };
          } else {
            setOptions(nextOptions);
            loadOptionsResult = {
              options: nextOptions,
              hasMore: nextOptions.length >= 10,
            };
          }
        }
      }
    } catch (e) {
      console.error(e);
    }
    return loadOptionsResult;
  };

  const defaultStateSetup = () => {
    setState((prevState: any) => ({
      ...prevState,
      [name + "_label"]: defaultState[name].label,
      [name + "_object"]: defaultState[name],
      [name]: defaultState[name].value,
    }));
  };

  const AsyncSelectStyles = {
    menuPortal: (provided: any) => {
      return { ...provided, ...customStyles.menuPortal };
    },
    menuList: (provided: any) => {
      return { ...provided, maxHeight: 196 };
    },
    multiValue: (provided: any) => {
      return {
        ...provided,
        backgroundColor: "#fff",
        border: "1px solid #CCCCCC",
        borderRadius: "4px",
        maxWidth: "140px",
        margin: "0",
      };
    },
    indicatorSeparator: (provided: any) => {
      return { ...provided, display: "none" };
    },

    input: (provided: any) => {
      return {
        ...provided,
        gridArea: isMulti ? "unset" : provided.gridArea,
      };
    },
    option: (provided: any, state: any) => {
      return {
        ...provided,
        fontSize: 12,
        fontWeight: "400",
        color: state.isSelected ? "#00BBB4" : "#222222",
        backgroundColor: state.isFocused
          ? "#E7E7E7"
          : state?.isSelected
          ? "#D9EFE5"
          : "transparent",
      };
    },
    loadingIndicator: (provided: any) => {
      if (isUseNewFilter && isMulti) {
        return { ...provided, display: "none" };
      }
      return { ...provided };
    },
    control: (provided: any, state: any) => {
      if (hasError) {
        return {
          ...provided,
          border: "1px solid #d00c1a",
          "&:hover": {
            borderColor: "#d00c1a",
          },
        };
      }
      return {
        ...provided,
        outline: "none",
        border: state.isFocused
          ? "1px solid #009C96"
          : "1px solid #cccccc",
        backgroundColor: "transparent",
        boxShadow: state.isFocused
          ? "0 0 0 4px rgba(0, 187, 180, 0.2)"
          : "none",
        "&:hover": {
          borderColor: "#009C96",
        },
      };
    },
    multiValueRemove: (provided: any) => {
      if (isUseNewFilter) {
        return { ...provided, color: "#999999" };
      }
      return { ...provided };
    },
  };

  useEffect(() => {
    setKey((Math.random() + 1).toString(36).substring(7));
    if (!isMulti) {
      if (utils.commons.isFunction(setState)) {
        setState((prevState: any) => ({
          ...prevState,
          [name + "_object"]: valueFromId(options, state[name]),
        }));
      }
    }
  }, [state[name]]);

  useEffect(() => {
    if (dependsOn && utils.commons.isString(data)) {
      if (
        state[dependsOn] !== undefined &&
        state[dependsOn] !== null
      ) {
        setState((prevState: any) => ({
          ...prevState,
          [name]:
            state["original___" + dependsOn] === state[dependsOn] &&
            state["original___" + name] === state[name]
              ? state["original___" + name]
              : null,
        }));

        setKey((Math.random() + 1).toString(36).substring(7));
      } else {
        if (state[dependsOn] === null) {
          setState((prevState: any) => ({
            ...prevState,
            [name]: null,
          }));
        }
      }
    }
  }, [state[dependsOn]]);

  useEffect(() => {
    if (!resetIsTriggered?.[name] || !defaultState) return;
    defaultStateSetup();
    setResetIsTriggered((prevState: { [key: string]: boolean }) => ({
      ...prevState,
      [name]: false,
    }));
  }, [resetIsTriggered?.[name]]);

  useEffect(() => {
    if (
      listenParam &&
      !hasSetInitialState.current &&
      options.length > 0 &&
      search.length > 0
    ) {
      const initialStateFromQueryParam = options.find(
        (obj) =>
          Number(obj.value) ===
          Number(urlQueryParams.get(listenParam))
      );
      setState((prevState: any) => ({
        ...prevState,
        [name + "_label"]: initialStateFromQueryParam.label,
        [name + "_object"]: initialStateFromQueryParam,
        [name]: initialStateFromQueryParam.value,
      }));
      hasSetInitialState.current = true;
    }
  }, [listenParam, options, search]);

  return (
    <AsyncPaginatedStyled>
      <AsyncPaginate
        styles={AsyncSelectStyles}
        hideSelectedOptions={hideSelectedOptions}
        name={name}
        key={key}
        defaultOptions
        debounceTimeout={debounceTimeout}
        onInputChange={
          onInputChange
            ? onInputChange
            : (newValue: string, actionMeta: InputActionMeta) => {
                setInputValueContainer(newValue);
              }
        }
        loadOptions={!isFirstRender && loadOptions}
        placeholder={placeholder}
        isClearable={false}
        closeMenuOnSelect
        onMenuOpen={() => setIsFirstRender(false)}
        isMulti={isMulti}
        menuPortalTarget={document.body}
        isDisabled={isDisabled || readOnly}
        isOptionDisabled={(option) => option.disabled}
        isOptionSelected={(option) => option.isSelected}
        value={getOptSelectedValue()}
        onChange={
          onChange
            ? onChange
            : (data) => {
                if (data) {
                  if (utils.commons.isArray(data)) {
                    updateWithoutDupe(state[name], data);
                  } else {
                    setState((prevState: any) => ({
                      ...prevState,
                      [name + "_label"]: data.label,
                      [name + "_object"]: data,
                      [name]: data.value,
                    }));
                  }
                } else {
                  setState((prevState: any) => ({
                    ...prevState,
                    [name + "_label"]: null,
                    [name + "_object"]: null,
                    [name]: null,
                  }));
                }

                setTimeout(() => {
                  if (utils.commons.isFunction(afterChange)) {
                    afterChange(data);
                  }
                }, 250);
              }
        }
        components={{
          NoOptionsMessage: (props: NoticeProps<any>) => {
            if (isInputRequired && !inputValueContainer) {
              return (
                <components.NoOptionsMessage {...props}>
                  {customNoOptionMessage}
                </components.NoOptionsMessage>
              );
            }
            return <components.NoOptionsMessage {...props} />;
          },
        }}
      />
    </AsyncPaginatedStyled>
  );
}

export default withRouter(CAsyncSelectV2);

const AsyncPaginatedStyled = styled.div`
  border-radius: 4px;
  height: 38px;
  min-width: 240px;
  outline: none;
`;
