import configBuilder from "../../../components/ubold/ConfigBuilder";

import utils from "../../../utils";

let definitions = {
  title: "Rukikoins",
  frontendPath: "/ruki_koin/transaction",
  baseLink: "/user/ruki_koin",
  restAccessCode: "user.ruki_koin",
  __table__: {
    filterColumn: 4,
    addButton: false,
    hideRemoveButton: true,
    hideEditButton: true,
    editAsDetail: true,
    filters: [
      {
        id: "tenant",
        name: "Tenant",
        type: "async_select",
        data: "user/tenant?__type__=select_entries",
      },
      {
        id: "category",
        name: "Category",
        type: "select",
        data: {
          optionField: "ruki_koin_category",
        },
      },
      {
        id: "koin",
        name: "Koin",
        type: "number",
      },
      {
        id: "source",
        name: "Source",
        type: "select",
        data: {
          optionField: "ruki_koin_source",
        },
      },
      {
        id: "date_created",
        name: "Date Created",
        type: "date",
      },
    ],
    columns: [
      {
        id: "tenant_name",
        name: "Tenant",
        renderer: (value, row) => {
          return value + " - " + row["tenant_email"];
        },
        enableHyperlink: true,
      },
      {
        id: "category",
        name: "Category",
        referStateFieldName: "ruki_koin_category",
        renderer: (value, _, constants) => {
          let label = "-";
          (constants ?? []).forEach((obj) => {
            if (obj.value === parseInt(value)) {
              label = obj.label;
            }
          });
          return label;
        },
      },
      {
        id: "koin",
        name: "Koin",
        center: true,
      },
      {
        id: "source",
        name: "Source",
        referStateFieldName: "ruki_koin_source",
        renderer: (value, _, constants) => {
          let label = "-";
          (constants ?? []).forEach((obj) => {
            if (obj.value === parseInt(value)) {
              label = obj.label;
            }
          });
          return label;
        },
      },
      {
        id: "provider",
        name: "Provider",
        center: true,
      },
      {
        id: "date_created",
        name: "Date Created",
        renderer: (value) => {
          return utils.formatter.dateTime(value);
        },
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
