import configBuilder from "../../../../components/ubold/ConfigBuilder";

import pageDefinitions from "../definitions";

import utils from "../../../../utils";

const definitions = {
  ...pageDefinitions,
  validators: {
    name: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    country: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    province: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    city: (value: string) => {
      return utils.validator.validateRequired(value);
    },
  },
};

export default configBuilder.buildAddEditPageConfig(definitions);
