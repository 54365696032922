import { withRouter } from "react-router";
import DatePicker from "react-datepicker";
import styled from "styled-components";

import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

function CDateTimePickerV3(props) {
  const {
    disabled,
    name,
    onChange,
    state,
    minDate,
    maxDate,
    dateFormat = "MMM-yyyy",
    showMonthYearPicker = true,
    showFullMonthYearPicker = true,
    excludedDateRange = [],
  } = props;

  return (
    <Container>
      <DatePicker
        dateFormat={dateFormat}
        selected={state[name] ? moment(state[name]).toDate() : null}
        onChange={onChange}
        showMonthYearPicker={showMonthYearPicker}
        showFullMonthYearPicker={showFullMonthYearPicker}
        minDate={minDate}
        maxDate={maxDate}
        placeholderText="Choose month"
        disabled={disabled}
        excludeDateIntervals={excludedDateRange}
      />
    </Container>
  );
}

export default withRouter(CDateTimePickerV3);

const Container = styled.div`
  .react-datepicker__input-container input {
    display: block;
    width: 210px;
    margin-right: 13px;
    margin-left: 3px;
    height: 37px;
    border-radius: 4px;
    padding-left: 8px;
    border-color: #ced4da !important;

    :focus {
      outline: none;
    }
  }
`;
