import utils from "../../../../utils";

import configBuilder from '../../../../components/ubold/ConfigBuilder';

import pageDefinitions from "../definitions";

const definitions = {...pageDefinitions,
    validators: {
        name: (value) => {
            return utils.validator.validateRequired(value);
        },
        order_position: (value) => {
            return utils.validator.validateRequired(value);
        },
        menu_position: (value) => {
            return utils.validator.validateRequired(value);
        },
        type: (value) => {
            return utils.validator.validateRequired(value);
        },
        link: (value) => {
            return utils.validator.validateRequired(value);
        },
    },
}

export default configBuilder.buildAddEditPageConfig(definitions);