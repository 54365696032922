import configBuilder from "../../../../components/ubold/ConfigBuilder";

import pageDefinitions from "../definitions";

import utils from "../../../../utils";

const definitions = {
  ...pageDefinitions,
  tabs: [
    {
      id: "service",
      name: "Service",
    },
    {
      id: "t_request_logs",
      name: "RequestLog",
    },
  ],
  validators: {
    order_new: (value) => {
      return utils.validator.validateRequired(value);
    },
    tenant: (value) => {
      return utils.validator.validateRequired(value);
    },
    status: (value) => {
      return utils.validator.validateRequired(value);
    },
    building_service: (value) => {
      return utils.validator.validateRequired(value);
    },
    notes: (value) => {
      return utils.validator.validateRequired(value);
    },
    case_origin: (value) => {
      return utils.validator.validateRequired(value);
    },
  },
  /** Required if COneManyToMany is in the main tab and has image field */
  imgFieldToBeStriped: "photo_original",
};

export default configBuilder.buildAddEditPageConfig(definitions, [
  "service_images",
]);
