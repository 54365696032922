import { useParams, withRouter } from "react-router-dom";

import TabPaneSecondary from "../../../../../../components/ubold/organisms/TabPaneSecondary";

import COneOrManyToManyField from "../../../../../../components/ubold/molecules/forms/COneOrManyToManyField";
import { genericTabProps } from "@types";

function Building(props: genericTabProps<Object>) {
  const { definitions, tab, state, setState } = props;

  let { id } = useParams<{ id: string }>();

  return (
    <TabPaneSecondary
      definitions={definitions}
      restAccessCodeNamespace="building"
      tab={tab}
      state={state}
      setState={setState}
      readOny
    >
      <COneOrManyToManyField
        readOnly
        id={id}
        name={tab.id}
        restPath={"building/recommendation__building/"}
        relationKey="city_id"
        columns={[
          {
            name: "building",
            title: "Building",
            type: "async_select",
            isUniqueOption: true,
            disabledAtEdit: true,
            isRequired: true,
            data: "building/building?__type__=select_entries",
            itemsExtractor: (row: {
              id: string;
              building_name: string;
            }) => {
              return {
                label: row.building_name,
                value: row.id,
              };
            },
          },
          {
            name: "priority",
            title: "Priority",
            isRequired: true,
            type: "number",
            disabledAtEdit: true,
          },
        ]}
      />
    </TabPaneSecondary>
  );
}

export default withRouter(Building);
