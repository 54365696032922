import withReactContent from "sweetalert2-react-content";

import Swal from "sweetalert2";

import loading from "../assets/loading.gif";

import configs from "../configs";
import utils from "./index";
import moment from "moment";
import { isArray } from "lodash";

const form = {
  sortStateBeforeSend: (state, targetSort, sortedKey) => {
    if (!sortedKey) return state;
    const clonedState = { ...state };
    const stateWithoutFlagKey =
      utils.commons.filterStateObjByProperty(
        clonedState[targetSort],
        sortedKey,
        false
      );
    const stateWithFlagKey = utils.commons.filterStateObjByProperty(
      clonedState[targetSort],
      sortedKey,
      true
    );
    const mergedStateTarget = [
      ...stateWithoutFlagKey,
      ...stateWithFlagKey,
    ];

    state[targetSort] = mergedStateTarget;

    return state;
  },
  /** ValidateWithoutTab applicable when
   * manyToManyV2 is declared inside Form instead of TabPane component
   */
  validateWithoutTab: (name, state, setState, imageFieldName) => {
    if (!state[name]?.length) {
      setState((prevState) => ({
        ...prevState,
        [name + "ErrorMessage"]:
          "At Least 1 record is required before saving",
      }));
      return;
    }
    let dataCloned = JSON.parse(
      JSON.stringify(state[name] ? state[name] : [])
    );
    let columns = state[name + "Columns"];
    for (let i = 0; i < state[name].length; i++) {
      for (let j = 0; j < columns.length; j++) {
        if (
          columns[j].isRequired &&
          utils.form.isEmpty(state[name][i][columns[j].name])
        ) {
          setState((prevState) => ({
            ...prevState,
            [name + "ErrorMessage"]:
              "There are error(s) in form, please fix them first!",
          }));

          dataCloned[i][columns[j].name + "Error"] =
            "This field is required";
        } else {
          delete dataCloned[i][columns[j].name + "Error"];
        }
      }
    }
    let hasError = false;
    for (let i = 0; i < state[name].length; i++) {
      for (let j = 0; j < columns.length; j++) {
        if (dataCloned[i][columns[j].name + "Error"]) {
          hasError = true;

          break;
        }
      }
    }
    if (hasError) {
      setState((prevState) => ({
        ...prevState,
        [name]: dataCloned,
      }));

      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

      return false;
    } else {
      /** Clear image field if filetype is not base64
       * before saving
       */
      if (imageFieldName && state[name]?.length > 0) {
        for (let i = 0; i < state[name].length; i++) {
          if (!utils.form.isBase64(state[name][i][imageFieldName])) {
            delete state[name][i][imageFieldName];
          }
        }
      }
      setState((prevState) => ({
        ...prevState,
      }));

      return true;
    }
  },
  validateManyToManyV2: (state, setState) => {
    let arrPeriod = [];
    let hasError = false;

    if (state?.id) {
      state?.detail?.map((data) => {
        arrPeriod = Object.keys(data)[0];
        const periodDetail = state?.[arrPeriod];
        const periodColumns = state?.[`${arrPeriod}Columns`];
        if (periodColumns) {
          const result = utils.commons.checkRequiredField(
            periodDetail,
            periodColumns
          );
          if (result.length === 0) {
            hasError = true;
          } else {
            const hasErrorCheck = utils.commons.checkErrorField(
              result,
              periodColumns
            );
            hasError = hasErrorCheck;
          }
        }
      });
      if (hasError) {
        setState((prevState) => ({
          ...prevState,
          [`${arrPeriod}ErrorMessage`]:
            "There are error(s) in form, please fix them first!",
        }));
      }
    } else {
      const tInvoiceDetail = state?.t_invoice_details;
      const invoiceColumns = state?.t_invoice_detailsColumns;
      if (invoiceColumns) {
        const result = utils.commons.checkRequiredField(
          tInvoiceDetail,
          invoiceColumns
        );
        if (result.length === 0) {
          hasError = true;
        } else {
          const hasErrorCheck = utils.commons.checkErrorField(
            result,
            invoiceColumns
          );
          hasError = hasErrorCheck;
        }
      }
      if (hasError) {
        setState((prevState) => ({
          ...prevState,
          t_invoice_detailsErrorMessage:
            "There are error(s) in form, please fix them first!",
        }));
      }
    }

    return !hasError;
  },
  manipulateStateBeforeSave: (state) => {
    const t_invoice_details = state.t_invoice_details;

    if (state.id) {
      let arrPeriod = [];

      /** Change started_at and ended_at format */
      state.started_at = moment(state.started_at).format(
        "YYYY-MM-DD"
      );
      state.ended_at = moment(state.ended_at).format("YYYY-MM-DD");

      state.detail.map((data) => {
        arrPeriod = Object.keys(data)[0];

        const eachPeriodArray = state?.[arrPeriod];

        for (let k = 0; k < eachPeriodArray.length; k++) {
          eachPeriodArray[k].periode_start =
            eachPeriodArray[k].start_date;
          eachPeriodArray[k].periode_end =
            eachPeriodArray[k].end_date;
        }
        state.t_invoice_details = [
          ...state.t_invoice_details,
          ...eachPeriodArray,
        ];
        state.t_invoice_details.sort((a, b) => (b.id ? 1 : -1));
        delete state?.[arrPeriod];
      });
    } else {
      for (let i = 0; i < t_invoice_details.length; i++) {
        t_invoice_details[i].periode_start = state.started_at;
        t_invoice_details[i].periode_end = state.ended_at;
      }
    }

    /** Remove Unused Payload Key */
    for (let key of Object.keys(state)) {
      if (key.startsWith("original__")) {
        delete state[key];
      }
      if (key.includes("Columns")) {
        delete state[key];
      }
      if (key.includes("default")) {
        delete state[key];
      }
      if (key.includes("-subtotal")) {
        delete state[key];
      }
    }
    state.t_invoice_details.sort((a, b) => {
      return a.id - b.id;
    });

    delete state.detail;
    delete state.category;
    delete state.sub_category;
    delete state.start_date;
    delete state.end_date;
    delete state.order_label;
    delete state.order_object;
    delete state.t_midtrans_transactions;
    delete state.t_midtrans_transactionsColumns;
    delete state.t_payments;
    delete state.t_paymentsColumns;
    delete state?.t_midtrans_transactionsInfoMessage;
    delete state?.t_paymentsInfoMessage;

    return state;
  },
  cleanupState: (state, tabId, keyAdjuster, keyToAdjust = []) => {
    const tabState = state[tabId];
    // clean up local storage for extend order
    utils.store.remove("extendOrder");

    if (!tabState || tabState?.length === 0) return state;

    for (let i = 0; i < tabState.length; i++) {
      for (const key in tabState[i]) {
        let x = tabState[i][key];
        if (
          x instanceof Date &&
          keyToAdjust.length === 0 &&
          !keyAdjuster
        ) {
          tabState[i][key] = moment(x).format("YYYY-MM-DD");
        }
        if (
          keyToAdjust.includes(key) &&
          state[keyAdjuster] &&
          !moment(state[keyAdjuster]).isAfter(tabState[i][key])
        ) {
          tabState[i][key] = moment(state[keyAdjuster]).format(
            "YYYY-MM-DD"
          );
        }
      }
    }
    return state;
  },
  validate: (state, setState, validators) => {
    const errorMessage = {};

    let hasErrors = false;
    for (const [key, value] of Object.entries(validators)) {
      const result = value(state[key], state);

      if (result !== true) {
        errorMessage[key + "Error"] = result;

        hasErrors = true;
        errorMessage["hasErrors"] = true;
      } else {
        errorMessage[key + "Error"] = null;
      }
    }

    if (hasErrors) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    } else {
      errorMessage["hasErrors"] = false;
    }

    setState((prevState) => ({ ...prevState, ...errorMessage }));

    return !hasErrors;
  },
  validateFormOnly: (state, setState, validators) => {
    let validatorPassed = true;

    if (validators) {
      validatorPassed = utils.form.validate(
        state,
        setState,
        validators
      );
    }

    return validatorPassed;
  },

  validateBEOnly: (state, setState, validators) => {
    const errorMessage = {};
    const hasError = false;

    for (const [property] of Object.entries(validators)) {
      /** clean up the error field, since error message will be consumed from BE  */
      Object.keys(state).forEach((key) => {
        if (
          key.indexOf(property) !== -1 &&
          key.indexOf("Error") !== -1
        )
          errorMessage[key] = null;
      });
    }

    setState((prevState) => ({ ...prevState, ...errorMessage }));

    return !hasError;
  },
  stripErrorFields: (state, stripFields = [], contentType) => {
    let stateClone = JSON.parse(JSON.stringify(state));
    const xlsType = "application/vnd.ms-excel";
    const xlsxType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

    if (contentType === "multipart/form-data") {
      const formData = new FormData();

      Object.keys(state).forEach((key) => {
        if (key.indexOf("Error") !== -1) return;

        if (
          typeof state[key] === "object" &&
          (state[key]?.type !== xlsxType ||
            state[key]?.type !== xlsType)
        ) {
          if (state[key] !== null) {
            formData.append(key, JSON.stringify(state[key]));
          } else {
            formData.append(key, state[key]);
          }
        }

        if (
          typeof state[key] === "object" &&
          (state[key]?.type === xlsxType ||
            state[key]?.type === xlsType)
        ) {
          /** handle file type */
          formData.append(key, state[key]);
        } else {
          if (
            typeof state[key] === "string" ||
            typeof state[key] === "number" ||
            typeof state[key] === "boolean"
          ) {
            if (
              key === "image" ||
              key === "icon" ||
              key === "upload_voucher_code" ||
              key === "pn_picture_original"
            ) {
              if (!state[key].includes("https"))
                return formData.append(key, state[key]);
            } else {
              formData.append(key, state[key]);
            }
          }
        }
      });

      return formData;
    }

    for (let i = 0; i < stripFields.length; i++) {
      delete stateClone[stripFields[i]];
    }

    for (const [key] of Object.entries(stateClone)) {
      if (key.indexOf("Error") !== -1) {
        delete stateClone[key];
      }
    }

    return stateClone;
  },
  isEmpty: (value) => {
    return (
      value === null ||
      value === undefined ||
      value === "" ||
      (typeof value === "number" && isNaN(value))
    );
  },
  isFieldError: (state, key) => {
    return (
      state[key + "Error"] !== null &&
      state[key + "Error"] !== undefined &&
      state[key + "Error"] !== ""
    );
  },
  getBase64: (
    files,
    maxFile,
    name,
    state,
    setState,
    onLoadCallback,
    parentName = null
  ) => {
    if (maxFile === 1) {
      const reader = new FileReader();

      /**
       * New Feature to put filename on the end of base64 result
       * Separate by underscore
       * filetype : .pdf
       */
      const isPdf =
        files[0]?.type === "application/pdf" ? true : false;

      reader.readAsDataURL(files[0]);

      reader.onload = function () {
        const base64Result = isPdf
          ? `${reader.result}____________${files[0]?.name}`
          : reader.result;
        if (utils.commons.isFunction(onLoadCallback)) {
          onLoadCallback(base64Result);
        } else {
          if (parentName) {
            let cloned = JSON.parse(JSON.stringify(state));

            cloned[name] = base64Result;

            setState((prevState) => ({
              ...prevState,
              [parentName]: cloned,
            }));
          } else {
            setState((prevState) => ({
              ...prevState,
              [name]: base64Result,
            }));
          }
        }
      };

      reader.onerror = function (error) {
        alert(error);
      };
    } else {
      alert("not support multiple files.");
    }
  },
  isBase64: (str) => {
    return str && !str.startsWith("http");
  },
  loadFormData: (
    id,
    setState,
    path,
    type = "edit",
    beforeSetStateCallback = null,
    afterSetStateCallback = null
  ) => {
    if (id) {
      const sweetAlert = withReactContent(Swal);

      sweetAlert.fire({
        title: "Loading data",
        html: (
          <img
            style={{ width: "125px", height: "125px" }}
            alt="loading..."
            src={loading}
          />
        ),
        showConfirmButton: false,
        allowOutsideClick: false,
      });

      utils.httpClient.get(
        configs.apiUrl + path + id + "?__type__=" + type,
        (data) => {
          sweetAlert.close();

          if (utils.commons.isFunction(beforeSetStateCallback)) {
            data = beforeSetStateCallback(data);
          }

          for (const key in data) {
            data["original___" + key] = data[key];
          }

          setState((prevState) => ({ ...prevState, ...data }));

          if (utils.commons.isFunction(afterSetStateCallback)) {
            data = afterSetStateCallback(data);
          }
        },
        (error, message) => {
          sweetAlert.close();

          if (
            error.response &&
            error.response.data &&
            error.response.data.message ===
              "Sorry, you have no access to read detail data"
          ) {
            sweetAlert.fire("Access Denied", message, "error");

            window.history.back();
          } else {
            setState((prevState) => ({
              ...prevState,
              errorMessage: message,
            }));
          }
        }
      );
    }
  },
  saveOrEditFormData: (
    id = null,
    state,
    setState,
    e,
    props,
    buttonFlag,
    definitions,
    path,
    stripFields = [],
    imageFields = [],
    restAccessCode
  ) => {
    let contentType = props.isOptionalHeader;
    let thereIsTabError = false;
    let customErrorMessage = null;

    const paramRestAccessCode = `rest_access_code=${restAccessCode}`;
    const paramObjectId = `object_id=${id}`;

    if (definitions.tabs) {
      if (definitions?.validatorTabNotEmpty?.enabled) {
        if (
          state[definitions.validatorTabNotEmpty.tabId].length === 0
        ) {
          thereIsTabError = true;
          customErrorMessage = `Tab ${definitions.validatorTabNotEmpty.tabName} cannot be empty!`;
        } else {
          for (let i = 1; i < definitions.tabs.length; i++) {
            let tabResult = form.validateTabForm(
              definitions.tabs[i].id,
              state,
              setState
            );
            if (!tabResult && !thereIsTabError) {
              thereIsTabError = true;
            }
          }
        }
      } else {
        for (let i = 1; i < definitions.tabs.length; i++) {
          let tabResult = form.validateTabForm(
            definitions.tabs[i].id,
            definitions.tabs[i].useCleanUpState
              ? form.cleanupState(
                  state,
                  definitions.tabs[i].id,
                  definitions.tabs[i].keyAdjuster,
                  definitions.tabs[i].keyToAdjust
                )
              : state,
            setState
          );
          if (!tabResult && !thereIsTabError) {
            thereIsTabError = true;
          }
        }
      }
    }

    /** additionalValidatorWithoutTab applicable when
     * manyToManyV2 is declared inside Form instead of TabPane component
     */
    if (definitions.additionalValidatorWithoutTab?.length) {
      definitions.additionalValidatorWithoutTab.forEach((el) => {
        const isSectionExcluded = el.excluded
          ? state[el.excluded.field] === el.excluded.value
          : false;
        let checkResult = form.validateWithoutTab(
          el.id,
          state,
          setState,
          el.imageFieldName
        );
        if (!checkResult && !isSectionExcluded) {
          thereIsTabError = true;
        }
      });
    }

    let validatorPassed = true;
    let validatorBEPassed = true;
    let validatorManyToManyV2Passed = true;

    if (definitions.validators) {
      validatorPassed = utils.form.validate(
        state,
        setState,
        definitions.validators
      );
    }

    if (definitions.useBEValidation) {
      validatorBEPassed = utils.form.validateBEOnly(
        state,
        setState,
        definitions.validationFromBE
      );
    }

    if (definitions.validatorManyToManyV2) {
      validatorManyToManyV2Passed = utils.form.validateManyToManyV2(
        state,
        setState
      );
    }

    setState((prevState) => ({
      ...prevState,
      hasTabErrors: false,
    }));
    if (
      !thereIsTabError &&
      validatorPassed &&
      validatorBEPassed &&
      validatorManyToManyV2Passed
    ) {
      const newState = definitions.manipulateStateBeforeSave
        ? utils.form.manipulateStateBeforeSave(state)
        : definitions.sortStateBeforeSave
        ? utils.form.sortStateBeforeSend(
            state,
            definitions.targetSort,
            definitions.sortKey
          )
        : state;
      const stateStriped = utils.form.stripErrorFields(
        newState,
        stripFields,
        contentType
      );

      for (let i = 0; i < imageFields.length; i++) {
        /** If imageFields is inside Tab ManyToMany */
        if (
          isArray(stateStriped[imageFields[i]]) &&
          definitions.imgFieldToBeStriped
        ) {
          const arrImgStriped2D = stateStriped[imageFields[i]];
          if (!arrImgStriped2D.length) break;
          for (let j = 0; j < arrImgStriped2D.length; j++) {
            if (
              !utils.form.isBase64(
                arrImgStriped2D[j][definitions.imgFieldToBeStriped]
              )
            ) {
              /** only delete the image fields */
              delete arrImgStriped2D[j][
                definitions.imgFieldToBeStriped
              ];
            }
          }
        } else {
          if (!utils.form.isBase64(stateStriped[imageFields[i]])) {
            delete stateStriped[imageFields[i]];
          }
        }
      }

      const sweetAlert = withReactContent(Swal);

      sweetAlert.fire({
        title: "Saving data",
        html: (
          <img
            style={{ width: "125px", height: "125px" }}
            alt="loading..."
            src={loading}
          />
        ),
        showConfirmButton: false,
        allowOutsideClick: false,
      });

      if (id !== null) {
        if (contentType) {
          utils.httpClient.patchMultipart(
            configs.apiUrl +
              path +
              id +
              "/" +
              "?" +
              paramRestAccessCode +
              "&" +
              paramObjectId,
            stateStriped,
            contentType,
            () => {
              sweetAlert.close();
              sweetAlert
                .fire(
                  "Saved",
                  "Record has been updated successfully.",
                  "success"
                )
                .then(() => {
                  if (buttonFlag === "Add") {
                    props.history.push(
                      definitions.frontendPath
                        ? definitions.frontendPath
                        : definitions.baseLink
                    );
                  } else if (buttonFlag === "Add Another") {
                    location.href =
                      utils.commons.stripMultipleSlashes(
                        (definitions.frontendPath
                          ? definitions.frontendPath
                          : definitions.baseLink) + "/add"
                      );
                  } else {
                    location.href =
                      (definitions.frontendPath
                        ? definitions.frontendPath
                        : definitions.baseLink) +
                      "/edit/" +
                      id;
                  }
                });
            },
            (error, message) => {
              sweetAlert.close();

              if (utils.commons.isJSON(message)) {
                const messageObject = JSON.parse(message);

                for (const key of Object.keys(messageObject)) {
                  let message = [];
                  if (utils.commons.isArray(messageObject[key])) {
                    for (let keyItem in messageObject[key]) {
                      message.push(messageObject[key][keyItem]);
                    }
                  } else {
                    message.push(messageObject[key]);
                  }

                  setState((prevState) => ({
                    ...prevState,
                    [key + "Error"]: message.join(", "),
                    hasErrors: true,
                  }));

                  window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                  });
                }
              } else {
                let errorMessage = utils.httpClient
                  .getErrorMessage(error)
                  .split("\n")[0];

                sweetAlert.fire("Error", errorMessage, "error");
              }
            }
          );
        } else {
          utils.httpClient.patch(
            configs.apiUrl +
              path +
              id +
              "/" +
              "?" +
              paramRestAccessCode +
              "&" +
              paramObjectId,
            stateStriped,
            () => {
              sweetAlert.close();
              sweetAlert
                .fire(
                  "Saved",
                  "Record has been updated successfully.",
                  "success"
                )
                .then(() => {
                  if (buttonFlag === "Add") {
                    props.history.push(
                      definitions.frontendPath
                        ? definitions.frontendPath
                        : definitions.baseLink
                    );
                  } else if (buttonFlag === "Add Another") {
                    location.href =
                      utils.commons.stripMultipleSlashes(
                        (definitions.frontendPath
                          ? definitions.frontendPath
                          : definitions.baseLink) + "/add"
                      );
                  } else {
                    location.href =
                      (definitions.frontendPath
                        ? definitions.frontendPath
                        : definitions.baseLink) +
                      "/edit/" +
                      id;
                  }
                });
            },
            (error, message) => {
              sweetAlert.close();

              if (utils.commons.isJSON(message)) {
                const messageObject = JSON.parse(message);

                for (const key of Object.keys(messageObject)) {
                  let message = [];
                  if (utils.commons.isArray(messageObject[key])) {
                    for (let keyItem in messageObject[key]) {
                      message.push(messageObject[key][keyItem]);
                    }
                  } else {
                    message.push(messageObject[key]);
                  }

                  setState((prevState) => ({
                    ...prevState,
                    [key + "Error"]: message.join(", "),
                    hasErrors: true,
                  }));

                  window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                  });
                }
              } else {
                let errorMessage = utils.httpClient
                  .getErrorMessage(error)
                  .split("\n")[0];

                sweetAlert.fire("Error", errorMessage, "error");
              }
            }
          );
        }
      } else {
        utils.httpClient.post(
          configs.apiUrl + path + "?" + paramRestAccessCode,
          stateStriped,
          (data) => {
            sweetAlert
              .fire(
                "Saved",
                "Record has been added successfully.",
                "success"
              )
              .then(() => {
                if (buttonFlag === "Add") {
                  props.history.push(
                    definitions.frontendPath
                      ? definitions.frontendPath
                      : definitions.baseLink
                  );
                } else if (buttonFlag === "Add Another") {
                  location.href = utils.commons.stripMultipleSlashes(
                    (definitions.frontendPath
                      ? definitions.frontendPath
                      : definitions.baseLink) + "/add"
                  );
                } else if (buttonFlag === "Continue Editing") {
                  props.history.replace(
                    utils.commons.stripMultipleSlashes(
                      (definitions.frontendPath
                        ? definitions.frontendPath
                        : definitions.baseLink) +
                        "/edit/" +
                        data.id
                    )
                  );
                  setState(data);
                  if (definitions.reloadAfterAdd) {
                    location.reload();
                  }
                }
              });
          },
          (error, message) => {
            sweetAlert.close();

            if (utils.commons.isJSON(message)) {
              const messageObject = JSON.parse(message);

              for (const key of Object.keys(messageObject)) {
                let message = [];
                if (utils.commons.isArray(messageObject[key])) {
                  for (let keyItem in messageObject[key]) {
                    message.push(messageObject[key][keyItem]);
                  }
                } else {
                  message.push(messageObject[key]);
                }

                setState((prevState) => ({
                  ...prevState,
                  [key + "Error"]: message.join(", "),
                  hasErrors: true,
                }));

                window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: "smooth",
                });
              }
            } else {
              let errorMessage = utils.httpClient
                .getErrorMessage(error)
                .split("\n")[0];

              sweetAlert.fire("Error", errorMessage, "error");
            }

            if (
              error.response &&
              error.response.data &&
              error.response.data.message ===
                "Sorry, you have no access to create this data"
            ) {
              window.history.back();
            }
          }
        );
      }
    } else {
      if (thereIsTabError) {
        setState((prevState) => ({
          ...prevState,
          hasTabErrors: true,
          customErrorMessage: customErrorMessage,
        }));
      }
    }
  },
  showRequestError: (sweetAlert, error) => {
    sweetAlert.close();

    sweetAlert.fire(
      "Error",
      utils.httpClient.getErrorMessage(error),
      "error"
    );
  },
  validateTabForm: (name, state, setState) => {
    if (state[name]) {
      let dataCloned = JSON.parse(
        JSON.stringify(state[name] ? state[name] : [])
      );

      let columns = state[name + "Columns"];

      for (let i = 0; i < state[name]?.length; i++) {
        for (let j = 0; j < columns?.length; j++) {
          if (
            columns[j].isRequired &&
            utils.form.isEmpty(state[name][i][columns[j].name])
          ) {
            setState((prevState) => ({
              ...prevState,
              [name + "ErrorMessage"]:
                "There are error(s) in form, please fix them first!",
            }));

            dataCloned[i][columns[j].name + "Error"] =
              "This field is required";
          } else {
            delete dataCloned[i][columns[j].name + "Error"];
          }
        }
      }

      let hasError = false;
      for (let i = 0; i < state[name]?.length; i++) {
        for (let j = 0; j < columns?.length; j++) {
          if (dataCloned[i][columns[j].name + "Error"]) {
            hasError = true;

            break;
          }
        }
      }

      if (hasError) {
        setState((prevState) => ({
          ...prevState,
          [name]: dataCloned,
          [name + "TabError"]: true,
        }));

        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

        return false;
      } else {
        setState((prevState) => ({
          ...prevState,
          [name + "TabError"]: false,
        }));

        return true;
      }
    } else {
      return true;
    }
  },
  checkPermission(
    id,
    restAccessCode,
    accesses,
    user,
    callback,
    isTeamMenu = false
  ) {
    if (accesses) {
      const sweetAlert = withReactContent(Swal);

      if (
        id === undefined &&
        !accesses.includes(restAccessCode + ".create") &&
        user &&
        !user.is_super_user &&
        isTeamMenu &&
        user &&
        !user.has_team_management_access
      ) {
        sweetAlert
          .fire("Error", "You have no access to this action", "error")
          .then(() => {
            window.history.back();
          });
      } else if (
        id &&
        !accesses.includes(restAccessCode + ".update") &&
        user &&
        !user.is_super_user &&
        isTeamMenu &&
        user &&
        !user.has_team_management_access
      ) {
        sweetAlert
          .fire("Error", "You have no access to this action", "error")
          .then(() => {
            window.history.back();
          });
      } else {
        callback();
      }
    } else {
      callback();
    }
  },
};

export default form;
