import {useParams, withRouter} from "react-router-dom";

import TabPaneSecondary from "../../../../../../components/ubold/organisms/TabPaneSecondary";

import COneOrManyToManyField from "../../../../../../components/ubold/molecules/forms/COneOrManyToManyField";

function SecondTab(props) {
    const {definitions, tab, state, setState} = props;

    let {id} = useParams();

    return (
        <TabPaneSecondary
            definitions={definitions}
            restAccessCodeNamespace='like'
            tab={tab}
            state={state}
            setState={setState}>
            <COneOrManyToManyField
                id={id}
                name={tab.id}
                restPath='event/event_comment_like/'
                relationKey='event_comment_id'
                columns={[
                    {
                        name: 'tenant',
                        title: 'Tenant',
                        type: 'async_select',
                        data: 'user/tenant?__type__=select_entries',
                        isUniqueOption: true,
                        disabledAtEdit: true,
                        isRequired: true
                    },
                ]}
            />
        </TabPaneSecondary>
    );
}

export default withRouter(SecondTab);