import { useEffect, useState } from "react";

import { withRouter } from "react-router-dom";

import { useDropzone } from "react-dropzone";

import utils from "../../../../utils";

import Alert, {
  AlertModalDefaultLayout,
} from "components/ubold/molecules/modal/Alert";
import RukiModal from "components/ubold/molecules/modal/RukiModal";

function CImageUpload(props) {
  const {
    name,
    maxFile = 1,
    isHorizontalPreview = false,
    readOnly = false,
    onChange,
    state,
    setState,
    isPdf = false,
    allowedImageExtension = ["jpg", "jpeg", "png"],
    maxSize,
    alertAllowedExtension = ".jpg .jpeg or .png",
    alertFileSize = "2MB",
  } = props;

  const [files, setFiles] = useState([]);
  const [fileLabel, setFileLabel] = useState([]);
  const [modalInvalidExtension, setModalInvalidExtension] =
    useState(false);

  const strAllowedExtension = allowedImageExtension
    .map((ext) => {
      return `image/${ext}`;
    })
    .toString();

  const { getRootProps, getInputProps } = useDropzone({
    disabled: readOnly || (isPdf && state["id"]),
    accept: isPdf ? "application/pdf" : strAllowedExtension,
    maxFiles: maxFile,
    maxSize: maxSize ?? 2000000,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles[0]?.type === "application/pdf") {
        setFileLabel(acceptedFiles[0]?.name);
      }

      if (acceptedFiles?.length === 0) {
        // If the selected file extension is not allowed extension type, it will return []
        setModalInvalidExtension(true);
        return;
      }

      if (onChange) {
        onChange(acceptedFiles);
        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
      } else {
        if (maxFile === 1) {
          utils.form.getBase64(
            acceptedFiles,
            maxFile,
            name,
            state,
            setState
          );
        } else {
          for (let i = 0; i < maxFile; i++) {
            if (acceptedFiles[i]) {
            }
          }

          setState((prevState) => ({
            ...prevState,
            [name]: acceptedFiles,
          }));
        }

        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
      }
    },
  });

  useEffect(() => {
    if (state[name]) {
      setFiles(state[name]);
    }
  }, [state[name]]);

  const thumbsContainer = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  };

  const thumb = {
    display: "inline-flex",
    borderRadius: 2,
    border: "1px solid #eaeaea",
    marginBottom: 8,
    marginRight: 8,
    width: 80,
    height: 80,
    padding: 4,
    boxSizing: "border-box",
  };

  const thumbHorizontal = {
    display: "inline-flex",
    borderRadius: 2,
    marginBottom: 8,
    marginRight: 8,
    marginLeft: 5,
    marginTop: 4,
    width: 30,
    height: 30,
    padding: 4,
    boxSizing: "border-box",
  };

  const thumbInner = {
    display: "flex",
    minWidth: 0,
    overflow: "hidden",
  };

  const img = {
    display: "block",
    width: "auto",
    height: "100%",
  };

  const thumbs =
    files !== undefined && utils.commons.isArray(files) ? (
      files.map((file) => {
        return utils.commons.isString(file) ? (
          <div style={isHorizontalPreview ? thumbHorizontal : thumb}>
            <div style={thumbInner}>
              <img alt="image" src={file} style={img} />
            </div>
          </div>
        ) : (
          <div
            style={isHorizontalPreview ? thumbHorizontal : thumb}
            key={file.name}
          >
            <div style={thumbInner}>
              <img alt="image" src={file.preview} style={img} />
            </div>
          </div>
        );
      })
    ) : files !== undefined && utils.commons.isString(files) ? (
      <div style={isHorizontalPreview ? thumbHorizontal : thumb}>
        <div style={thumbInner}>
          <img alt="image" src={files} style={img} />
        </div>
      </div>
    ) : files !== undefined ? (
      <div style={isHorizontalPreview ? thumbHorizontal : thumb}>
        <div style={thumbInner}>
          <img alt="image" src={files.preview} style={img} />
        </div>
      </div>
    ) : (
      []
    );

  const renderPreview = () => {
    return !isHorizontalPreview ? (
      <aside style={thumbsContainer}>{thumbs}</aside>
    ) : (
      <div className="float-left">{thumbs}</div>
    );
  };

  const renderFileLabel = () => {
    return (
      <div>
        <label>
          {" "}
          {state["file"] && state["flag"] !== "New" ? (
            <a href={state["file"]} target="_blank" rel="noreferrer">
              {state["file"]}
            </a>
          ) : (
            fileLabel
          )}{" "}
        </label>
      </div>
    );
  };

  return (
    <section>
      {!isPdf ? renderPreview() : renderFileLabel()}

      {!readOnly ? (
        <div
          {...getRootProps({ className: "dropzone" })}
          style={{
            minHeight: "0px",
            padding: "6px",
            backgroundColor:
              isPdf && state["id"] ? "#f2f2f2" : "#ffffff",
          }}
        >
          <input {...getInputProps()} />
          <p className="mb-0">
            Drag &apos;n&apos; drop{" "}
            {maxFile === 1 ? "a file" : "some files"} here, or click
            to select {maxFile === 1 ? "a file" : "some files"}
          </p>
        </div>
      ) : null}

      <RukiModal
        headerClass="d-none"
        isOpen={modalInvalidExtension}
        onClose={() => setModalInvalidExtension(false)}
        modalSize={387}
        headerTitle=""
        headerType="close"
        css={AlertModalDefaultLayout}
      >
        <Alert
          textHeading="Invalid file ext or File size limit exceeded "
          textMessages={`Please select a file with these extension: ${alertAllowedExtension} and size below ${alertFileSize}!`}
          onClose={() => setModalInvalidExtension(false)}
        />
      </RukiModal>
    </section>
  );
}

export default withRouter(CImageUpload);
