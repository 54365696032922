import {useState} from "react";

import {useParams, withRouter} from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import Form from "../../../../components/ubold/organisms/Form";

import CVTextField from "../../../../components/ubold/molecules/forms/CVTextField";
import CVSwitch from "../../../../components/ubold/molecules/forms/CVSwitch";

import definitions from "./definitions";

function PaymentPlanAddEditPage() {
    let { id } = useParams();

    const initialState = {
        full_paid: false,
        discount_amount: 0
    };

    const [state, setState] = useState(initialState);

    return (
        <Page title={(id === undefined ? 'Add New' : 'Edit') + ' ' +  definitions.title}
              restAccessCode={definitions.restAccessCode}>
            <Form
                id={id}
                definitions={definitions}
                state={state}
                setState={setState}>
                <CVTextField
                    title='Plan'
                    name='plan'
                    type='number'
                    isRequired
                />
                <CVTextField
                    title='Discount in %'
                    name='discount_amount'
                    type='number'
                    isRequired
                />
                <CVSwitch
                    title='Full Paid'
                    name='full_paid' />
            </Form>
        </Page>
    );
}

export default withRouter(PaymentPlanAddEditPage);