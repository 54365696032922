import { withRouter } from "react-router-dom";

import AccordionPane from "../../../../../../../components/ubold/organisms/AccordionPane";
import CHSelect from "../../../../../../../components/ubold/molecules/forms/CHSelect";
import CHAsyncSelect from "../../../../../../../components/ubold/molecules/forms/CHAsyncSelect";
import useGlobalState from "state";
import CHTextField from "components/ubold/molecules/forms/CHTextField";

function Detail(props) {
  const { baseLink, accordionId, onSubmit, state, setState } = props;
  const [constants] = useGlobalState("constant_values");

  return (
    <AccordionPane
      id="detail"
      name="Detail"
      accordionId={accordionId}
      baseLink={baseLink}
      onSubmit={onSubmit}
      state={state}
      setState={setState}
    >
      <CHAsyncSelect
        name="job_group"
        title="Job/Employment Category"
        data="user/job_group?__type__=select_entries"
      />
      <CHTextField
        name="organization_name"
        title="Job/Employment/School"
      />
      {/* <CHAsyncSelect
        name="organization"
        title="Job/Employment/School"
        data="user/organization?__type__=select_entries"
      /> */}
      <CHSelect
        name="marital_status"
        title="Marital Status"
        data={constants?.["user_tenant_marital_status"]}
      />
      <CHSelect
        name="religion"
        title="Religion"
        data={constants?.["user_tenant_religion"]}
      />

      {/* 
      will move to another section or removed
      <CHImageUpload name="photo_original" title="Photo" />
      <CHImageUpload name="id_card_photo_original" title="ID Card" />
      <CHImageUpload
        name="payment_receipt_original"
        title="Payment Receipt"
      />
      <CHSelect
        name="source"
        title="Source"
        data={[
          {
            label: "Ads",
            value: 1,
          },
          {
            label: "Google",
            value: 2,
          },
          {
            label: "Mamikos",
            value: 3,
          },
          {
            label: "Instagram",
            value: 4,
          },
          {
            label: "Website",
            value: 5,
          },
          {
            label: "OLX",
            value: 6,
          },
          {
            label: "Friends",
            value: 7,
          },
          {
            label: "Visit",
            value: 8,
          },
          {
            label: "Rukita Website",
            value: 9,
          },
        ]}
      />
      <CHTextField name="name" title="Name" maxLength={100} />
      <CHAsyncSelect
        name="job"
        title="Job"
        dependsOn="job_group"
        data="user/occupation?__type__=select_entries"
      />
      <CHSelect
        name="income_level"
        title="Income Level"
        data={[
          {
            label: "< 15",
            value: 1,
          },
          {
            label: "15 < 25",
            value: 2,
          },
          {
            label: "25 < 50",
            value: 3,
          },
          {
            label: "> 50",
            value: 4,
          },
        ]}
      />
      <CHSelect
        name="race"
        title="Race"
        data={[
          {
            label: "Malay",
            value: 1,
          },
          {
            label: "Indian",
            value: 2,
          },
          {
            label: "Chinese",
            value: 3,
          },
          {
            label: "Arab",
            value: 4,
          },
          {
            label: "Javanese",
            value: 5,
          },
          {
            label: "Other",
            value: 6,
          },
        ]}
      />
      <CHTextArea name="notes" title="Notes" />
      <CHSimpleManyToMany
        name="languages"
        title="Languages"
        data="user/language?__type__=select_entries"
      />
      <CHCheckBox name="first_login" title="First Login" />
      <CHCheckBox name="is_dummy" title="Is dummy" /> */}
    </AccordionPane>
  );
}

export default withRouter(Detail);
