import configBuilder from "../../../components/ubold/ConfigBuilder";
import utils from "utils";

let definitions = {
  title: "Homepage Header",
  frontendPath: "/marketing/homepage/homepage_header",
  baseLink: "/homepage-header",
  restAccessCode: "homepage.homepage_header",
  __table__: {
    removeIdentifierNo: true,
    filterColumn: 4,
    filters: [
      {
        id: "url",
        name: "URL",
        type: "text",
      },
      {
        id: "order",
        name: "Order Number",
        type: "number",
      },
      {
        id: "published",
        name: "Published",
        type: "select",
        data: [
          {
            label: "Yes",
            value: true,
          },
          {
            label: "No",
            value: false,
          },
        ],
      },
      {
        id: "created",
        name: "Date Created",
        type: "date",
      },
      {
        id: "updated",
        name: "Date Updated",
        type: "date",
      },
    ],
    columns: [
      {
        id: "mobile_screen_original",
        name: "Banner Mobile Screen",
        isSafeContent: true,
        canDoOrdering: false,
        center: true,
        renderer: (value: string) => {
          if (value !== "" && value !== "-") {
            return (
              "<img alt='image' src='" + value + "' width='50px' />"
            );
          } else {
            return value;
          }
        },
      },
      {
        id: "tablet_screen_original",
        name: "Banner Tablet Screen",
        isSafeContent: true,
        canDoOrdering: false,
        center: true,
        renderer: (value: string) => {
          if (value !== "" && value !== "-") {
            return (
              "<img alt='image' src='" + value + "' width='50px' />"
            );
          } else {
            return value;
          }
        },
      },
      {
        id: "desktop_screen_original",
        name: "Banner Desktop Screen",
        isSafeContent: true,
        canDoOrdering: false,
        center: true,
        renderer: (value: string) => {
          if (value !== "" && value !== "-") {
            return (
              "<img alt='image' src='" + value + "' width='50px' />"
            );
          } else {
            return value;
          }
        },
      },
      {
        id: "url",
        name: "URL",
        center: true,
        enableHyperlink: true,
      },
      {
        id: "order",
        name: "Order",
        center: true,
      },
      {
        id: "published",
        name: "Published Flag",
        center: true,
        renderer: (value: string) => {
          if (value === "true") {
            return '<span style="color: rgb(26, 188, 156);"> ✔ </span>';
          } else {
            return '<span style="color: red;">✘</span>';
          }
        },
      },
      {
        id: "date_created",
        name: "Data Created",
        renderer: (value: string) => {
          return utils.formatter.dateTime(value);
        },
      },
      {
        id: "date_updated",
        name: "Data Updated",
        renderer: (value: string) => {
          return utils.formatter.dateTime(value);
        },
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
