import configBuilder from "../../../components/ubold/ConfigBuilder";
import utils from "../../../utils";

let definitions = {
  title: "Properties",
  frontendPath: "/building/facility/property",
  baseLink: "/building/property",
  restAccessCode: "building.property",
  __table__: {
    filterColumn: 3,
    hideEditButton: true,
    filters: [
      {
        id: "name",
        name: "Name",
        type: "text",
      },
      {
        id: "name_en",
        name: "Name EN",
        type: "text",
      },
      {
        id: "building_priority",
        name: "Building Priority",
        type: "number",
      },
      {
        id: "room_priority",
        name: "Room Priority",
        type: "number",
      },
      {
        id: "date_created",
        name: "Date Created",
        type: "date",
      },
    ],
    columns: [
      {
        id: "name",
        name: "Name",
        enableHyperlink: true,
      },
      {
        id: "name_en",
        name: "Name EN",
      },
      {
        id: "building_priority",
        name: "Building Priority",
      },
      {
        id: "room_priority",
        name: "Room Priority",
      },
      {
        id: "date_updated",
        name: "Date Updated",
        renderer: (value) => {
          return utils.formatter.dateTime(value);
        },
      },
      {
        id: "date_created",
        name: "Date Created",
        renderer: (value) => {
          return utils.formatter.dateTime(value);
        },
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
