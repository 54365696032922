import { useState, useEffect } from "react";

import { useParams, withRouter } from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import TabHeaders from "../../../../components/ubold/organisms/TabHeaders";
import TabContent from "../../../../components/ubold/organisms/TabContent";
import TabbedForm from "../../../../components/ubold/organisms/TabbedForm";

import Order from "./Tabs/Order";
import OrderAdditionalDiscounts from "./Tabs/OrderAdditionalDiscounts";
import OrderAddon from "./Tabs/OrderAddon";
/** Hide this as requested on RTD-4553 */
// import OrderServiceSchedules from "./Tabs/OrderServiceSchedules";
import OrderUpdatePrice from "./Tabs/OrderUpdatePrice";
import definitions from "./definitions";
import moment from "moment";

function OrderAddEditPage() {
  let { id } = useParams();

  const initialState = {
    payment_plan_discount: 0,
    price: 0,
    discount_amount: 0,
    deposit: 0,
    status_of_done: 0,
    tenant_category: 1,
    order_status: 9,
    source: 1,
    promoDateRanges: {
      availableRange: [],
      selectedDate: [],
    },
    discountDateRanges: {
      availableRange: [],
      selectedDate: [],
    },
    is_migration_data: false,
    subscription_type: 1,
    monthly_commitment: 0,
    exclude_deposit: false,
    split_deposit_and_rent: false,
    t_discount_orders: [],
    t_addon_orders: [],
    t_price_updates: [],
    t_discount_ordersInfoMessage:
      "Before adding additional discount data, please fill tenant, building and checkin date",
  };

  const [state, setState] = useState(initialState);
  const [triggerOnce, setTriggerOnce] = useState(false);
  const [triggerOnceTwo, setTriggerOnceTwo] = useState(false);

  useEffect(() => {
    if (id) return;
    /**
     * prerequisities
     * new order creation, initialState is set to prevent blank page
     *
     * originally for case extend order
     * since Link (react-router-dom) does not have capability to persist state
     * in different page then I came up with this idea instead
     * TODO: need to check whether this function need adjustment after node ver upgrade
     */
    if (localStorage.getItem("extendOrder")) {
      const extendOrderState = {
        ...JSON.parse(localStorage.getItem("extendOrder")),
        deposit_rules: 1,
        deposit_rules_object: {
          label: "No Deposit",
          value: 1,
        },
        deposit_rule_name: "No Deposit",
      };
      setState(extendOrderState ?? initialState);
    } else {
      setState(initialState);
    }

    return () => localStorage.removeItem("extendOrder");
  }, []);

  useEffect(() => {
    if (
      parseInt(state?.["discount_amount"]) === 0 ||
      state?.["discount_amount"] === ""
    ) {
      setState((prevState) => ({
        ...prevState,
        discount_notes: "",
      }));
    }
  }, [state?.["discount_amount"], state?.["order_status"]]);

  useEffect(() => {
    if (!(state?.t_discount_orders ?? []).length) return;
    const tempPromoDateRanges = { ...state?.promoDateRanges };
    const tempDiscountDateRanges = { ...state?.discountDateRanges };
    const tempPromoSelectedDate =
      tempPromoDateRanges?.selectedDate ?? [];
    const tempDiscountSelectedDate =
      tempDiscountDateRanges?.selectedDate ?? [];
    for (const [
      index,
      discountOrPromo,
    ] of state?.t_discount_orders.entries()) {
      if (!discountOrPromo.start_date) continue;
      const isCategoryPromo = discountOrPromo.category === 2;
      if (isCategoryPromo) {
        tempPromoSelectedDate[index] = {
          from: discountOrPromo.start_date,
          to: discountOrPromo.end_date ?? "",
        };
      } else {
        tempDiscountSelectedDate[index] = {
          from: discountOrPromo.start_date,
          to: discountOrPromo.end_date ?? "",
        };
      }
    }
    tempPromoDateRanges.selectedDate = tempPromoSelectedDate;
    tempDiscountDateRanges.selectedDate = tempDiscountSelectedDate;
    setState((prevState) => ({
      ...prevState,
      promoDateRanges: tempPromoDateRanges,
      discountDateRanges: tempDiscountDateRanges,
    }));
  }, [state?.t_discount_orders]);

  useEffect(() => {
    if (!id || !state?.t_discount_orders?.length || triggerOnceTwo)
      return;
    const tempDiscountOrder = [...state?.t_discount_orders];
    setTriggerOnceTwo(true);
    setState((prevState) => ({
      ...prevState,
      original___t_discount_orders: tempDiscountOrder,
    }));
  }, [state?.t_discount_orders]);

  useEffect(() => {
    if (
      !id ||
      triggerOnce ||
      !state?.original___building ||
      !state?.original___checkin_date ||
      state?.original___monthly_commitment === undefined ||
      state?.original___monthly_commitment === null ||
      !state?.t_discount_orders?.length ||
      !state?.original___tenant
    )
      return;
    const tempAdditionalDiscounts = [...state.t_discount_orders]?.map(
      (tempState) => ({
        ...tempState,
        monthly_commitment: state?.original___monthly_commitment,
        building: state?.original___building,
        selected_date: moment().format("Y-M-D"),
        tenant: state?.original___tenant,
      })
    );
    setTriggerOnce(true);
    setState((prevState) => ({
      ...prevState,
      t_discount_orders: tempAdditionalDiscounts,
    }));
  }, [
    id,
    state?.original___building,
    state?.original___checkin_date,
    state?.original___monthly_commitment,
    state?.t_discount_orders,
    state?.original___tenant,
  ]);

  return (
    <Page
      title={
        (id === undefined ? "Add New" : "Edit") +
        " " +
        definitions.title
      }
      restAccessCode={definitions.restAccessCode}
    >
      <TabbedForm
        id={id}
        state={state}
        setState={setState}
        definitions={definitions}
      >
        <TabHeaders />
        <TabContent>
          <Order />
          <OrderAdditionalDiscounts />
          <OrderAddon />
          {/* 
           Hide this as requested on RTD-4553
          <OrderServiceSchedules /> */}
          <OrderUpdatePrice />
        </TabContent>
      </TabbedForm>
    </Page>
  );
}

export default withRouter(OrderAddEditPage);
