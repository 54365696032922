import { withRouter } from "react-router-dom";

import AccordionPane from "../../../../../../../components/ubold/organisms/AccordionPane";
import CHSelect from "components/ubold/molecules/forms/CHSelect";
import CHPhoneNumber from "components/ubold/molecules/forms/CHPhoneNumber";
import CHTextField from "components/ubold/molecules/forms/CHTextField";

function EmergencyContact(props) {
  const { baseLink, accordionId, onSubmit, state, setState } = props;

  return (
    <AccordionPane
      id="emergencyContact"
      name="Emergency Contact"
      accordionId={accordionId}
      baseLink={baseLink}
      onSubmit={onSubmit}
      state={state}
      setState={setState}
    >
      <CHPhoneNumber
        title="Emergency Phone Number"
        countryCodeName="other_country_code"
        phoneNumberName="emergency_phone_number"
      />
      <CHSelect
        name="emergency_relation"
        title="Emergency Relations"
        data={{
          optionField: "user_tenant_emergency_relation",
        }}
        filterItems={(options) => {
          const newOptions = options?.filter(
            (option) => option.label !== null
          );
          return newOptions;
        }}
      />
      <CHTextField name="emergency_name" title="Name" />
    </AccordionPane>
  );
}

export default withRouter(EmergencyContact);
