import utils from "../../../utils";
import configBuilder from "../../../components/ubold/ConfigBuilder";

let definitions = {
  title: "Landlord invoices",
  frontendPath: "/finance/landlord_invoice_sop",
  baseLink: "finances_new/invoice-landlord/",
  restAccessCode: "finance_new.invoice_landlord",
  __table__: {
    isAddButtonDisabled: true,
    addButton: false,
    filterColumn: 4,
    excludeParams: ["building_object", "building_label"],
    filters: [
      {
        id: "invoice",
        name: "Invoice Number",
        type: "async_select",
        data: "finances_new/invoice-landlord?__type__=select_entries",
        itemsExtractor: (item: any) => {
          return {
            label: item.number,
            value: item.id,
          };
        },
      },
      {
        id: "order_id",
        name: "Order ID",
        type: "async_select",
        data: "order_new/order?__type__=select_entries",
        itemsExtractor: (item: any) => {
          return {
            label: item.number,
            value: item.id,
          };
        },
      },
      {
        id: "building",
        name: "Building Name",
        type: "async_select",
        data: "building/building?__type__=select_entries",
        itemsExtractor: (item: any) => {
          return {
            label: item.building_name,
            value: item.id,
          };
        },
      },
      {
        id: "tenant_name",
        name: "Tenant Name",
        type: "async_select",
        data: "user/tenant?__type__=select_entries",
        itemsExtractor: (item: any) => {
          return {
            label: item.name,
            value: item.id,
          };
        },
      },
      {
        id: "landlord_name",
        name: "Landlord Name",
        type: "async_select",
        data: "building/landlord?__type__=select_entries",
        itemsExtractor: (item: any) => {
          return {
            label: item.name,
            value: item.id,
          };
        },
      },
      {
        id: "start_date",
        name: "Started At",
        type: "date",
      },
      {
        id: "end_date",
        name: "Ended At",
        type: "date",
      },
    ],
    columns: [
      {
        id: "number",
        name: "Invoice Number",
        enableHyperlink: true,
      },
      {
        id: "order_number",
        name: "Order ID",
        renderer: (_: any, value: any) =>
          `<a class='link-rukita' style='color: #35b0a7;' href='/sales/order_sop/edit/${value.order}'>${value.order_number}</a>`,
      },
      {
        id: "tenant_name",
        name: "Tenant Name",
        renderer: (_: any, value: any) =>
          `<a class='link-rukita' style='color: #35b0a7;' href='/tenant/tenant/edit/${value.tenant}'>${value.tenant_name}</a>`,
      },
      {
        id: "building_name",
        name: "Building Name",
        renderer: (_: any, value: any) =>
          `<a class='link-rukita' style='color: #35b0a7;' href='/building/property_list/edit/${value.building}'>${value.building_name}</a>`,
      },
      {
        id: "landlord_name",
        name: "Landlord Name",
      },
      {
        id: "release_status",
        name: "Release Status",
        canDoOrdering: false,
        referStateFieldName: "finance_invoice_release_status",
        renderer: (value: any, _: any, constants: any) => {
          let label = "-";
          (constants ?? []).forEach((obj: any) => {
            if (obj.value === parseInt(value)) {
              label = obj.label;
            }
          });
          return label;
        },
      },
      {
        id: "started_at",
        name: "Start At",
        renderer: (value: any) => {
          return utils.formatter.date(value);
        },
      },
      {
        id: "ended_at",
        name: "Ended At",
        renderer: (value: any) => {
          return utils.formatter.date(value);
        },
      },
      {
        id: "date_created",
        name: "Created At",
        renderer: (value: any) => {
          return utils.formatter.dateTime(value);
        },
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
