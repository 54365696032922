import { useState, useEffect } from "react";
import { useParams, withRouter } from "react-router-dom";

import TabPaneSecondary from "../../../../../../components/ubold/organisms/TabPaneSecondary";

import COneOrManyToManyField from "../../../../../../components/ubold/molecules/forms/COneOrManyToManyField";

import utils from "../../../../../../utils";
import moment from "moment";

function OrderAddons(props) {
  const { definitions, tab, state, setState } = props;
  const today = moment();

  const [addonList, setAddonList] = useState([]);
  const [dynamicDisabled, setDynamicDisabled] = useState(false);

  let { id } = useParams();

  /** order status = expire, cancel, done */
  const doneStatus = [5, 6, 10];

  const buildingParam = state["building"]
    ? `&building=${state["building"]}`
    : `&building=0`;

  useEffect(() => {
    if (!state[tab.id]) return;
    setAddonList(state[tab.id]);
  }, [state[tab.id]]);

  const generateRowLabel = (rowName, rowState) => {
    return {
      label:
        rowName +
        (rowState["price"]
          ? " (" +
            (rowState["payment_plan"]
              ? rowState["payment_plan"]
              : "1") +
            " month) - " +
            utils.formatter.currency(rowState["price"])
          : ""),
      value: rowState.id,
    };
  };

  const getMinDateCondition = () => {
    // set default min date as today
    let minDate = today.format("YYYY-MM-DD");

    // order update then min date H+1 from addon added date
    if (id) {
      minDate = today.add(1, "d").format("YYYY-MM-DD");
    }
    // order creation than min date = checkin date
    if (!id && state?.subscription_type === 1) {
      minDate = moment(state?.checkin_date).format("YYYY-MM-DD");
    }
    // subs closed upfront case
    if (
      state?.subscription_type === 2 &&
      state?.monthly_commitment >= 0 &&
      state?.order_status !== 3
    ) {
      minDate = moment(state?.checkin_date).format("YYYY-MM-DD");
    } else if (
      state?.subscription_type === 2 &&
      state?.monthly_commitment >= 0 &&
      state?.order_status === 3
    ) {
      minDate = moment().add(1, "day").format("YYYY-MM-DD");
    }
    return minDate;
  };

  const updateColumnRequired = (name, isRequired) => {
    const columns = state[tab.id + "Columns"] ?? [];
    if (columns.length > 0) {
      columns.forEach((obj) => {
        if (obj.name === name) {
          obj.isRequired = isRequired;
        }
      });
    }
  };

  const getRequiredCondition = (name) => {
    let isRequired = false;
    if (state?.checkout_date || state?.subscription_type === 2) {
      isRequired = true;
      updateColumnRequired(name, isRequired);
    } else {
      updateColumnRequired(name, isRequired);
    }
    return isRequired;
  };

  const getRequireField = (name) => {
    let requireField = "";

    if (state?.subscription_type !== 2) return requireField;
    return (requireField = name);
  };
  useEffect(() => {
    if (id || state?.subscription_type === 1) return;
    let infoMessage = "";
    if (
      state?.monthly_commitment > 1 &&
      !state?.checkin_date &&
      !state?.checkout_date
    ) {
      setDynamicDisabled(true);
      infoMessage =
        "Make sure both Check-In and Check-Out Date are filled before using this tab!";
    }

    if (state?.monthly_commitment === 0 && !state?.checkin_date) {
      setDynamicDisabled(true);
      infoMessage =
        "Make sure Check-In Date is filled before using this tab!";
    }

    if (!infoMessage) {
      setDynamicDisabled(false);
    } else {
      setState((prevState) => ({
        ...prevState,
        [tab.id + "InfoMessage"]: infoMessage,
      }));
    }
  }, [
    id,
    state?.subscription_type,
    state?.checkin_Date,
    state?.checkout_date,
    state?.monthly_commitment,
  ]);

  return (
    <TabPaneSecondary
      definitions={definitions}
      tab={tab}
      restAccessCodeNamespace="addon_order"
      state={state}
      setState={setState}
    >
      <COneOrManyToManyField
        id={id}
        name={tab.id}
        readOnly={
          (id &&
            doneStatus.includes(state?.original___order_status)) ||
          dynamicDisabled
        }
        restPath="order_new/addon_order/"
        relationKey="order_id"
        saveOnlyFromMainForm={true}
        index={2}
        infoMessageStandAlone={!id}
        enableListener={true}
        columns={[
          {
            name: "addon_building",
            type: "async_select",
            disabledAtEdit: true,
            isUniqueOption: false,
            title: "AddOn Building",
            isRequired: true,
            requireField: "building",
            data: `building/addon__building?__type__=select_entries&limit=1000${buildingParam}`,
            itemsExtractor: (row) => {
              if (row.name) {
                return generateRowLabel(row.name, row);
              } else if (row.addon__name) {
                return generateRowLabel(row.addon__name, row);
              }
            },
          },
          {
            name: "number",
            type: "read_only",
            maxLength: 13,
            title: "Addon ID",
          },
          {
            name: "note",
            type: "custom",
            title: "Notes",
            addonList: addonList,
            isReadOnly: doneStatus.includes(
              state?.original___order_status
            ),
          },
          {
            name: "status",
            type: "select",
            title: "Status",
            isRequired: true,
            data: {
              optionField: "order_new_addon_order_addon_statuses",
            },
            isReadOnly: doneStatus.includes(
              state?.original___order_status
            ),
          },
          {
            name: "start_date",
            type: "date",
            title: "Start Date",
            isRequired: true,
            minDate: getMinDateCondition(),
            adjustMinDate: true,
            isReadOnly: doneStatus.includes(
              state?.original___order_status
            ),
            maxDate: state?.checkout_date
              ? moment(state?.checkout_date).format("YYYY-MM-DD")
              : null,
            isClearable: false,
          },
          {
            name: "end_date",
            type: "date",
            title: "End Date",
            isRequired: getRequiredCondition("end_date"),
            dynamicMinDate: true,
            dynamicReadOnly:
              state?.checkout_date &&
              state?.subscription_type === 2 &&
              !doneStatus.includes(state?.original___order_status)
                ? true
                : false,
            isReadOnly: doneStatus.includes(
              state?.original___order_status
            ),
            requireField: getRequireField("Start Date"),
            maxDate: state?.checkout_date
              ? moment(state?.checkout_date).format("YYYY-MM-DD")
              : null,
            dataList: addonList,
            isClearable: false,
          },
          {
            name: "date_created",
            title: "Date Created",
            type: "date",
            isReadOnly: true,
          },
          {
            name: "is_after_order_creation",
            type: "checkbox",
            title: "Flag",
            helperText: "For Devs Purpose",
            readOnly: true,
            dynamicValue: true,
            dataList: addonList,
            hideColumnByCSS: true,
          },
        ]}
      />
    </TabPaneSecondary>
  );
}

export default withRouter(OrderAddons);
