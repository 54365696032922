import configBuilder from "../../../components/ubold/ConfigBuilder";
import utils from "../../../utils";

let definitions = {
  title: "Staffs",
  frontendPath: "/building/staff",
  baseLink: "/building/staff",
  restAccessCode: "building.staff",
  __table__: {
    filterColumn: 4,
    hideEditButton: true,
    filters: [
      {
        id: "name",
        name: "Name",
        type: "text",
      },
      {
        id: "country_code",
        name: "Country Code",
        type: "number",
      },
      {
        id: "phone_number",
        name: "Phone Number",
        type: "number",
      },
      {
        id: "email",
        name: "Email",
        type: "text",
      },
      {
        id: "roles",
        name: "Role",
        type: "select",
        data: {
          optionField: "buildings_staff_role",
        },
      },
      {
        id: "date_created",
        name: "Date Created",
        type: "date",
      },
    ],
    columns: [
      {
        id: "name",
        name: "Name",
        enableHyperlink: true,
      },
      {
        id: "country_code",
        name: "Country Code",
      },
      {
        id: "phone_number",
        name: "Phone Number",
      },
      {
        id: "email",
        name: "Email",
      },
      {
        id: "roles",
        name: "Role",
        renderer: (value) => {
          if (parseInt(value) === 1) {
            return "Manager";
          } else if (parseInt(value) === 2) {
            return "Maid";
          } else if (parseInt(value) === 3) {
            return "Security";
          } else if (parseInt(value) === 4) {
            return "Building Manager";
          } else if (parseInt(value) === 5) {
            return "Community Associate";
          } else {
            return "-                            ";
          }
        },
      },
      {
        id: "photo_original",
        name: "Photo",
        isSafeContent: true,
        canDoOrdering: false,
        center: true,
        renderer: (value) => {
          if (value && value !== "-" && value !== "") {
            return (
              "<img alt='image' src='" + value + "' width='50px' />"
            );
          } else {
            return value;
          }
        },
      },
      {
        id: "date_created",
        name: "Date Created",
        renderer: (value) => {
          return utils.formatter.dateTime(value);
        },
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
