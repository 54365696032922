import React from "react";
import moment from "moment";

import CHTextFieldV2 from "../../forms/CHTextFieldV2";

import TabPaneSection from "../../../../ubold/organisms/TabPaneSection";

import {
  CustomFormGroup,
  ModalPreviewOrderSubmitStyled,
} from "./ModalPreviewOrderSubmit.css";
import AccordionItem from "./AccordionItem";

import { GridItemSingleRow } from "./AccordionItem.css";
import {
  LabelMBold,
  LabelMRegular,
} from "components/ubold/atoms/General.css";

import {
  IPreviewOrder_DataObject,
  IPreviewOrder_InvoiceDetail,
  IPreviewOrder_PeriodObject,
} from "@types";
import utils from "utils";

export type DataModalDetailObjectType = {
  date: string;
  hrefId: {
    order: number;
    tenant: number;
  };
  render: {
    orderStatus:
      | "Waiting confirm"
      | "Processing"
      | "Waiting for Payment"
      | "Deposit received"
      | "Deposit and payment received"
      | "Rejected"
      | "Ongoing"
      | "Cancel"
      | "Done";
    orderDate: string;
    orderId: string;
    salesName: string;
    confirmCheckout: boolean;
    roomVariant: string;
    room: string;
    tenantName: string;
  };
};

const ModalPreviewOrderSubmit: React.FC<{
  onClose: () => void;
  data: IPreviewOrder_DataObject;
  customCheckInDateTitle?: string;
}> = ({ data, customCheckInDateTitle = "Check-in Date" }) => {
  const calculateTotalPriceOfPeriod = ({
    invoiceDetail,
    textPeriod,
  }: {
    invoiceDetail: IPreviewOrder_InvoiceDetail;
    textPeriod: string;
  }): number => {
    const listDeduction = invoiceDetail[textPeriod].filter(
      (item: IPreviewOrder_PeriodObject) => item?.category === 1
    );

    const totalDeduction: number = listDeduction.reduce(
      (previous: number, current: IPreviewOrder_PeriodObject) => {
        return previous + current?.price;
      },
      0
    );

    const reducedBasedPrice: number =
      invoiceDetail[textPeriod].find(
        (item: IPreviewOrder_PeriodObject) => item.category === 4
      )?.price - totalDeduction;

    const listRestObjectsToCalculate = invoiceDetail[
      textPeriod
    ].filter(
      (item: IPreviewOrder_PeriodObject) =>
        item?.category !== 4 && item?.category !== 1
    );

    const accumulatedTotal: number =
      listRestObjectsToCalculate.reduce(
        (previous: number, current: IPreviewOrder_PeriodObject) => {
          return previous + current?.price;
        },
        reducedBasedPrice
      );

    return accumulatedTotal;
  };

  if (!data) return null;

  return (
    <ModalPreviewOrderSubmitStyled>
      <CustomFormGroup>
        <CHTextFieldV2
          readOnly
          title="Building Name"
          value={data.building}
        />
      </CustomFormGroup>
      <CustomFormGroup>
        <CHTextFieldV2
          readOnly
          title="Room Name"
          value={data.room_name}
        />
      </CustomFormGroup>
      <CustomFormGroup>
        <CHTextFieldV2
          readOnly
          title="Tenant Name"
          value={data.tenant}
        />
      </CustomFormGroup>
      <CustomFormGroup>
        <CHTextFieldV2
          readOnly
          title="Subscription Type"
          value={data.subscription_type_name}
        />
      </CustomFormGroup>
      <CustomFormGroup>
        <CHTextFieldV2
          readOnly
          title="Monthly Commitment"
          value={
            data?.monthly_commitment ? data.monthly_commitment : "-"
          }
        />
      </CustomFormGroup>
      <CustomFormGroup>
        <CHTextFieldV2
          readOnly
          title={customCheckInDateTitle}
          value={moment(data.started_at, "YYYY-MM-DD").format(
            "DD-MM-YYYY"
          )}
        />
      </CustomFormGroup>
      <CustomFormGroup>
        <CHTextFieldV2
          readOnly
          title="End Date"
          value={moment(data.ended_at, "YYYY-MM-DD").format(
            "DD-MM-YYYY"
          )}
        />
      </CustomFormGroup>

      <div style={{ width: "100%" }}>
        <div className="row">
          {data?.invoice_details?.length > 0 ? (
            <TabPaneSection accordionId="formAccordion">
              {data?.invoice_details?.map((item, index) => {
                const textPeriod = Object?.keys(item)[0];

                return (
                  <AccordionItem
                    className={
                      index !== data.invoice_details.length - 1
                        ? "mb-3"
                        : "mb-1"
                    }
                    data={item[textPeriod]}
                    key={index}
                    name={textPeriod}
                    shown={index === 0}
                    total={utils.formatter
                      .currency(
                        calculateTotalPriceOfPeriod({
                          invoiceDetail: item,
                          textPeriod,
                        })
                      )
                      ?.replace("Rp", "")
                      ?.replace(/\s/g, "")}
                  />
                );
              })}
            </TabPaneSection>
          ) : (
            <div />
          )}

          <div className="col-12">
            {data?.referral?.referral ? (
              <GridItemSingleRow border={{ top: true }}>
                <div>
                  <LabelMBold>Referral</LabelMBold>
                </div>

                <div>
                  <LabelMBold>
                    {data.referral.referral ?? ""}
                  </LabelMBold>
                </div>

                <div className="table-border-r-unset"></div>

                <div>
                  <LabelMRegular className="justify-self-start">
                    (-) Rp
                  </LabelMRegular>
                  <LabelMRegular>
                    {utils.formatter
                      .currency(data.referral.amount)
                      .replace("Rp", "")
                      .replace(/\s/g, "")}
                  </LabelMRegular>
                </div>

                <div className="table-border-r-unset">
                  <LabelMRegular>{""}</LabelMRegular>
                </div>

                <div>
                  <LabelMRegular>{""}</LabelMRegular>
                </div>
              </GridItemSingleRow>
            ) : (
              <></>
            )}
            <GridItemSingleRow
              border={{ top: !data?.referral?.referral }}
            >
              <div>
                <LabelMBold>Grand Total</LabelMBold>
              </div>

              <div className="table-border-r-unset"></div>

              <div className="table-border-r-unset"></div>

              <div>
                <LabelMRegular className="justify-self-start">
                  Rp
                </LabelMRegular>
                <LabelMRegular>
                  {utils.formatter
                    .currency(data.grand_total)
                    .replace("Rp", "")
                    .replace(/\s/g, "")}
                </LabelMRegular>
              </div>

              <div className="table-border-r-unset">
                <LabelMRegular>{""}</LabelMRegular>
              </div>

              <div>
                <LabelMRegular>{""}</LabelMRegular>
              </div>
            </GridItemSingleRow>
          </div>
        </div>
      </div>
    </ModalPreviewOrderSubmitStyled>
  );
};

export default ModalPreviewOrderSubmit;
