import configBuilder from '../../../../components/ubold/ConfigBuilder';

import pageDefinitions from "../definitions";

import utils from "../../../../utils";

const definitions = {...pageDefinitions,
    validators: {
        building_aggrement: value => {
            return utils.validator.validateRequired(value);
        },
        month: value => {
            return utils.validator.validateRequired(value);
        },
        escalating_amount: value => {
            return utils.validator.validateRequired(value);
        },
    },
}

export default configBuilder.buildAddEditPageConfig(definitions);