import { useParams, withRouter } from "react-router-dom";

import TabPaneSecondary from "../../../../../../components/ubold/organisms/TabPaneSecondary";

import COneOrManyToManyField from "../../../../../../components/ubold/molecules/forms/COneOrManyToManyField";

function RoomVariantRoom(props) {
  const { definitions, tab, state, setState } = props;

  let { id } = useParams();

  return (
    <TabPaneSecondary
      definitions={definitions}
      restAccessCodeNamespace="room"
      tab={tab}
      state={state}
      setState={setState}
    >
      <COneOrManyToManyField
        id={id}
        name={tab.id}
        restPath="building/room/"
        relationKey="room_variant_id"
        readOnly
        columns={[
          {
            name: "name",
            type: "text",
            title: "Name",
            maxLength: 100,
            isRequired: true,
            disabledAtEdit: true,
          },
          {
            name: "room_type",
            type: "async_select",
            title: "Room Type",
            data: "building/room_type?__type__=select_entries",
            disabledAtEdit: true,
          },
          {
            name: "price",
            type: "number",
            default: 0,
            title: "Price",
            disabledAtEdit: true,
          },
          {
            name: "room_status",
            type: "select",
            title: "Room Status",
            isRequired: true,
            default: "None",
            disabledAtEdit: true,
            data: [
              {
                label: "None",
                value: 0,
              },
              {
                label: "Repair and Maintenance",
                value: 1,
              },
              {
                label: "On Transformation",
                value: 2,
              },
              {
                label: "Temporary Occupied",
                value: 3,
              },
              {
                label: "Not For Sale",
                value: 4,
              },
              {
                label: "Demolish",
                value: 5,
              },
              {
                label: "Vacant - Dirty",
                value: 6,
              },
              {
                label: "Repair and Maintenance  by Owner",
                value: 7,
              },
              {
                label: "Booked",
                value: 8,
              },
            ],
          },
          {
            name: "room_status_notes",
            type: "textarea",
            title: "Room Status Notes",
            disabledAtEdit: true,
          },
          {
            name: "is_available",
            type: "select",
            title: "Is Available",
            width: 150,
            isRequired: true,
            default: "Yes",
            disabledAtEdit: true,
            helperText:
              "Note: This value might be overridden by backend rules.",
            data: [
              {
                label: "Yes",
                value: true,
              },
              {
                label: "No",
                value: false,
              },
            ],
          },
        ]}
      />
    </TabPaneSecondary>
  );
}

export default withRouter(RoomVariantRoom);
