import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import styled from "styled-components/macro";

export const ModalStyled = styled(Modal)`
  margin: 80px 16px 0;

  .modal-content {
    border-radius: 8px;
  }

  .canvas {
    /* padding: 16px; */
  }

  @media only screen and (min-width: 576px) {
    margin: 80px auto 0;

    .canvas {
      padding: 14px 24px 24px 24px;
    }
  }

  @media only screen and (min-width: 992px) {
    margin: 120px auto 0;
  }
`;

export const ModalBodyStyled = styled(ModalBody)`
  font-size: 16px;
  font-weight: 300;
  padding: 16px 0 40px;
`;
export const ModalFooterStyled = styled(ModalFooter)`
  flex-wrap: nowrap;
  border-top: 1px solid #e7e7e7;
`;
export const ModalHeaderStyled = styled(ModalHeader)`
  height: 52px;
  border-bottom: 1px solid #e7e7e7;

  align-items: center;

  .modal-title {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      background-color: unset;
      border: unset;
      padding: unset;
    }

    span {
      font-size: 14px;
    }
  }
`;

export const RuButton = styled.button`
  display: flex;
  justify-content: center;
  white-space: nowrap;
  max-height: 48px;

  background-color: #35b0a7;
  border: unset;
  border-radius: 4px;
  color: #ffffff;
  margin: unset;

  &:active,
  &:focus {
    outline: unset;
  }

  &:disabled {
    background-color: #eeeeee;
  }
`;

export const RuButtonWhite = styled.button`
  display: flex;
  justify-content: center;
  white-space: nowrap;
  max-height: 48px;

  background-color: #ffffff;
  border: 1px solid #35b0a7;
  border-radius: 4px;
  color: #35b0a7;
  margin: unset;

  &:active,
  &:focus {
    outline: unset;
  }
`;
