import { withRouter } from "react-router-dom";

import TabPane from "../../../../../../components/ubold/organisms/TabPane";
import TabPaneSection from "../../../../../../components/ubold/organisms/TabPaneSection";

import CHTextField from "../../../../../../components/ubold/molecules/forms/CHTextField";
import CHAsyncSelect from "../../../../../../components/ubold/molecules/forms/CHAsyncSelect";
import { genericTabProps } from "@types";

function RecommendationCity(props: genericTabProps<Object>) {
  const { id, definitions, tab, state, setState } = props;

  return (
    <TabPane
      id={id}
      definitions={definitions}
      tab={tab}
      state={state}
      setState={setState}
      readOnly
    >
      <TabPaneSection style={{ padding: "25px 16px 0px" }}>
        <CHAsyncSelect
          name="city"
          title="City"
          isRequired
          data="place/city?__type__=select_entries"
          readOnly
        />
        <CHTextField
          name="priority"
          title="Priority"
          isRequired
          type="number"
          readOnly
        />
      </TabPaneSection>
    </TabPane>
  );
}

export default withRouter(RecommendationCity);
