import { useParams, withRouter } from "react-router-dom";

import TabPaneSecondary from "../../../../../../components/ubold/organisms/TabPaneSecondary";

import COneOrManyToManyField from "../../../../../../components/ubold/molecules/forms/COneOrManyToManyField";

import utils from "../../../../../../utils";

function TabInvoiceDetails(props) {
  const { definitions, tab, state, setState } = props;

  let { id } = useParams();

  return (
    <TabPaneSecondary
      definitions={definitions}
      tab={tab}
      restAccessCodeNamespace="detail"
      state={state}
      setState={setState}
    >
      <COneOrManyToManyField
        id={id}
        name={tab.id}
        restPath="one_time_purchase/invoice_detail/"
        relationKey="invoice_id"
        readOnly
        columns={[
          {
            name: "product_text",
            type: "read_only",
            title: "Product",
          },
          {
            name: "product_name",
            type: "read_only",
            title: "Product Name",
          },
          {
            name: "qty",
            type: "read_only",
            title: "Quantity",
          },
          {
            name: "date_created",
            type: "read_only",
            title: "Date Created",
            renderer: (value) => {
              return utils.formatter.dateTime(value);
            },
          },
          {
            name: "date_updated",
            type: "read_only",
            title: "Date Updated",
            renderer: (value) => {
              return utils.formatter.dateTime(value);
            },
          },
        ]}
      />
    </TabPaneSecondary>
  );
}

export default withRouter(TabInvoiceDetails);
