import configBuilder from "../../../components/ubold/ConfigBuilder";
import utils from "../../../utils";

let definitions = {
  title: "UTM Mediums",
  frontendPath: "/admin/utm/utm_medium",
  baseLink: "/marketing/utm_medium",
  restAccessCode: "marketing.utm_medium",
  __table__: {
    hideEditButton: true,
    filterColumn: 4,
    removeIdentifier: "utm_medium",
    filters: [
      {
        id: "utm_medium",
        name: "UTM Medium",
        type: "text",
      },
      {
        id: "utm_source",
        name: "UTM Source",
        type: "async_select",
        data: "marketing/utm_source?__type__=select_entries",
        itemsExtractor: (row) => {
          return {
            label: row.utm_source,
            value: row.id,
          };
        },
      },
      {
        id: "code",
        name: "Code",
        type: "text",
      },
      {
        id: "date_created",
        name: "Date Created",
        type: "date",
      },
    ],
    columns: [
      {
        id: "utm_medium",
        name: "UTM Medium",
        enableHyperlink: true,
      },
      {
        id: "utm_source_name",
        name: "UTM Source",
      },
      {
        id: "code",
        name: "Code",
      },
      {
        id: "date_created",
        name: "Date Created",
        renderer: (value) => {
          return utils.formatter.dateTime(value);
        },
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
