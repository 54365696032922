import configBuilder from "../../../components/ubold/ConfigBuilder";

import utils from "../../../utils";

let definitions = {
  title: "Addon Building Order",
  frontendPath: "/sales/addon_building_order",
  baseLink: "/order/addon_building__order",
  restAccessCode: "order.addon_building_order",
  __table__: {
    filterColumn: 4,
    removeIdentifier: "number",
    hideEditButton: true,
    filters: [
      {
        id: "number",
        name: "Number",
        type: "text",
      },
      {
        id: "order",
        name: "Order",
        type: "async_select",
        data: "order/order?__type__=select_entries",
        itemsExtractor: (row) => {
          return {
            label: row.number,
            value: row.id,
          };
        },
      },
      {
        id: "building",
        name: "Building",
        type: "async_select",
        data: "building/building?__type__=select_entries",
        itemsExtractor: (row) => {
          return {
            label: row.building_name,
            value: row.id,
          };
        },
      },
      {
        id: "tenant",
        name: "Tenant",
        type: "async_select",
        data: "user/tenant?__type__=select_entries",
      },
      {
        id: "addon",
        name: "Addon",
        type: "async_select",
        data: "building/addon?__type__=select_entries",
      },
      {
        id: "status",
        name: "Status",
        type: "select",
        data: {
          optionField: "order_addon_building_order_status",
        },
      },
      {
        id: "date_created",
        name: "Created Date",
        type: "date",
      },
      {
        id: "start_date",
        name: "Start Date",
        type: "date",
      },
      {
        id: "end_date",
        name: "End Date",
        type: "date",
      },
    ],
    columns: [
      {
        id: "number",
        name: "Number",
        enableHyperlink: true,
      },
      {
        id: "order_number",
        name: "Order Number",
      },
      {
        id: "building_name",
        name: "Building Name",
      },
      {
        id: "tenant_name",
        name: "Tenant Name",
      },
      {
        id: "addon_name",
        name: "Addon Name",
      },
      {
        id: "status",
        name: "Status",
        referStateFieldName: "order_addon_building_order_status",
        renderer: (value, _, constants) => {
          let label = "---";
          (constants ?? []).forEach((obj) => {
            if (obj.value === parseInt(value)) {
              label = obj.label;
            }
          });
          return label;
        },
      },
      {
        id: "date_created",
        name: "Created Date",
        renderer: (value) => {
          return utils.formatter.dateTime(value);
        },
      },
      {
        id: "start_date",
        name: "Start Date",
        renderer: (value) => {
          return utils.formatter.dateTime(value);
        },
      },
      {
        id: "end_date",
        name: "End Date",
        renderer: (value) => {
          return utils.formatter.dateTime(value);
        },
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
