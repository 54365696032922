import { isMobile } from "react-device-detect";

function SecondaryDataHandler(data) {
  return (
    <div
      className={
        isMobile
          ? "secondary-data-panel-mobile"
          : "secondary-data-panel"
      }
    >
      <div className="row mb-2">
        <div className="col mw-200">
          <div>
            <b>Landlord</b>: <br />
            {data.landlord_name}
          </div>
        </div>
        <div className="col mw-200">
          <div>
            <b>Place Groups</b>: <br />
            {data.place_groups}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SecondaryDataHandler;
