import configBuilder from "../../../components/ubold/ConfigBuilder";

import utils from "../../../utils";

let definitions = {
  title: "Event Comments",
  frontendPath: "/community_event/event_comment",
  baseLink: "/event/event_comment",
  restAccessCode: "event.event_comment",
  __table__: {
    filterColumn: 4,
    removeIdentifier: "comment",
    hideEditButton: true,
    filters: [
      {
        id: "comment",
        name: "Comment",
        type: "text",
      },
      {
        id: "event",
        name: "Event",
        type: "async_select",
        data: "event/event?__type__=select_entries",
        itemsExtractor: (value) => {
          return {
            label: value.event_name,
            value: value.id,
          };
        },
      },
      {
        id: "tenant",
        name: "Tenant",
        type: "async_select",
        data: "user/tenant?__type__=select_entries",
      },
      {
        id: "date_created",
        name: "Date Created",
        type: "date",
      },
    ],
    columns: [
      {
        id: "comment",
        name: "Comment",
        enableHyperlink: true,
      },
      {
        id: "event_name",
        name: "Event",
      },
      {
        id: "tenant_name",
        name: "Tenant",
      },
      {
        id: "date_created",
        name: "Date Created",
        renderer: (value) => {
          return utils.formatter.dateTime(value);
        },
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
