import { useParams, withRouter } from "react-router-dom";

import TabPaneSecondary from "../../../../../../components/ubold/organisms/TabPaneSecondary";

import COneOrManyToManyField from "../../../../../../components/ubold/molecules/forms/COneOrManyToManyField";
import utils from "../../../../../../utils";

function OrderAdditionalDiscounts(props: any) {
  const { definitions, tab, state, setState } = props;

  let { id }: any = useParams();

  const doneStatus = [4, 5, 6];

  return (
    <TabPaneSecondary
      definitions={definitions}
      tab={tab}
      restAccessCodeNamespace="additional_discount"
      state={state}
      setState={setState}
      readOnly={true}
    >
      <COneOrManyToManyField
        id={id}
        name={tab.id}
        readOnly={id && doneStatus.includes(state["status"])}
        restPath="order/additional_discount/"
        relationKey="order_id"
        saveOnlyFromMainForm={true}
        index={1}
        columns={[
          {
            name: "discount_master",
            type: "async_select",
            title: "Discount Master",
            disabledAtEdit: true,
            isRequired: true,
            isUniqueOption: true,
            width: 200,
            data: "finance/discount?__type__=select_entries",
            itemsExtractor: (row: any) => {
              return {
                label:
                  row["name"] +
                  (row["amount"]
                    ? " (" +
                      utils.formatter.currency(row["amount"]) +
                      ")"
                    : ""),
                value: row["id"],
              };
            },
            readOnly: true,
          },
          {
            name: "date_started",
            type: "datetime",
            isRequired: true,
            title: "Date Started",
            readOnly: true,
          },
          {
            name: "date_ended",
            type: "datetime",
            isRequired: true,
            title: "Date Ended",
            readOnly: true,
          },
        ]}
      />
    </TabPaneSecondary>
  );
}

export default withRouter(OrderAdditionalDiscounts);
