import {withRouter} from "react-router-dom";

import Page from '../../components/ubold/organisms/Page';

function HomePage() {
    return (
        <Page title="Home">
            Home Page
        </Page>
    );
}

export default withRouter(HomePage);