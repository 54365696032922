import { withRouter } from "react-router";
import TabPane from "components/ubold/organisms/TabPane";
import TabPaneSection from "components/ubold/organisms/TabPaneSection";
import CHTextView from "components/ubold/molecules/forms/CHTextView";
import CHTextField from "components/ubold/molecules/forms/CHTextField";
import CHAsyncSelect from "components/ubold/molecules/forms/CHAsyncSelect";
import CHSelect from "components/ubold/molecules/forms/CHSelect";
import HorizontalWrapper from "components/ubold/atoms/display/HorizontalWrapper";
import CSelectV3 from "components/ubold/atoms/forms/CSelectV3";
import { useState, useEffect } from "react";

const MonthlyReportTab = (props: any) => {
  const { id, definitions, tab, state, setState } = props;
  const [selectedBuilding, setSelectedBuilding] = useState(null);
  const [selectedRoomVariant, setSelectedRoomVariant] =
    useState(null);
  const monthData = [
    {
      label: "Januari",
      value: 1,
    },
    {
      label: "Februari",
      value: 2,
    },
    {
      label: "Maret",
      value: 3,
    },
    {
      label: "April",
      value: 4,
    },
    {
      label: "Mei",
      value: 5,
    },
    {
      label: "Juni",
      value: 6,
    },
    {
      label: "Juli",
      value: 7,
    },
    {
      label: "Agustus",
      value: 8,
    },
    {
      label: "September",
      value: 9,
    },
    {
      label: "October",
      value: 10,
    },
    {
      label: "November",
      value: 11,
    },
    {
      label: "Desember",
      value: 12,
    },
  ];

  useEffect(() => {
    if (!id) return;
    setSelectedBuilding({
      value: state?.original___building,
      label: state?.original___building_name,
    });
    if (state?.original___building_type === 2) {
      setSelectedRoomVariant({
        value: state?.original___room_variant,
        label: state?.original___room_variant_name,
      });
    }
  }, [id, state?.building]);

  return (
    <TabPane
      id={id}
      definitions={definitions}
      tab={tab}
      state={state}
      setState={setState}
      readOnly
    >
      <TabPaneSection style={{ padding: "25px 16px 0px" }}>
        <HorizontalWrapper
          title="Building"
          errorMessage={state?.buildingError ?? ""}
          isRequired
          isReadOnly
        >
          <CSelectV3 value={selectedBuilding} readOnly />
        </HorizontalWrapper>
        {state?.building_type === 2 ? (
          <HorizontalWrapper
            title="Room Variants"
            errorMessage={state?.room_variantError ?? ""}
            isRequired
          >
            <CSelectV3 value={selectedRoomVariant} readOnly />
          </HorizontalWrapper>
        ) : (
          <></>
        )}
        <CHSelect
          name="month"
          title="Month"
          readOnly={id && true}
          data={monthData}
          isRequired
        />
        <CHTextField
          name="year"
          title="Year"
          type="number"
          min={0}
          readOnly={id && true}
          isRequired
        />
        <CHTextView
          name="email_fmr_1"
          title="Additional email from Building(1)"
        />
        <CHTextView
          name="email_fmr_2"
          title="Additional email from Building(2)"
        />
      </TabPaneSection>
    </TabPane>
  );
};

export default withRouter(MonthlyReportTab);
