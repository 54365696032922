import {
  ModalNames,
  ModalTree,
  ModalTreeProviderShape,
} from "@types";
import React, { useState } from "react";
import { ModalCollectionContext } from "utils/context";

const ModalsCollectionProvider: React.FC = ({ children }) => {
  const [modalTree, setModalTree] = useState<ModalTree>({
    [ModalNames.PROMPT_DISCARD_CHANGES]: {
      isShow: false,
    },
  });

  const modalTreeInstance: ModalTreeProviderShape = {
    modalTree,
    setModalTree,
  };

  return (
    <ModalCollectionContext.Provider value={modalTreeInstance}>
      {children}
    </ModalCollectionContext.Provider>
  );
};

export default ModalsCollectionProvider;
