import { withRouter } from "react-router-dom";

import TabPane from "../../../../../../components/ubold/organisms/TabPane";
import TabPaneSection from "../../../../../../components/ubold/organisms/TabPaneSection";

import CHTextField from "../../../../../../components/ubold/molecules/forms/CHTextField";
import CHTextView from "../../../../../../components/ubold/molecules/forms/CHTextView";
import CHSelect from "../../../../../../components/ubold/molecules/forms/CHSelect";
import CHRichTextField from "../../../../../../components/ubold/molecules/forms/CHRichTextField";
import CHDateTimePicker from "../../../../../../components/ubold/molecules/forms/CHDateTimePicker";
import CHCheckBox from "../../../../../../components/ubold/molecules/forms/CHCheckBox";
import CHFileUpload from "../../../../../../components/ubold/molecules/forms/CHFileUpload";
import CHImageView from "components/ubold/molecules/forms/CHImageView";

function VoucherManagement(props: any) {
  const { id, definitions, tab, state, setState } = props;

  const isFieldDisabled = state?.history_has_published;

  return (
    <TabPane
      id={id}
      definitions={definitions}
      tab={tab}
      state={state}
      setState={setState}
      isOptionalHeader={"multipart/form-data"}
      readOnly
    >
      <TabPaneSection style={{ padding: "25px 16px 0px" }}>
        <CHTextField
          title="Voucher Headline"
          name="name"
          isRequired
          maxLength={50}
          readOnly
        />
        <CHImageView title="Banner (max size 500kB)" name="image" />
        <CHSelect
          title="Voucher Source"
          name="category"
          isRequired
          data={[
            {
              label: "Rukita Voucher",
              value: 1,
            },
            {
              label: "E-Wallet",
              value: 2,
            },
            {
              label: "Rukita Partner",
              value: 3,
            },
          ]}
          readOnly
        />
        <CHSelect
          title="Category"
          name="voucher_category"
          isRequired
          data={[
            { value: 1, label: "Food & Beverage" },
            { value: 2, label: "Entertainment" },
            { value: 3, label: "Health Beauty" },
            { value: 4, label: "Wellness" },
            { value: 5, label: "Lifestyle" },
            { value: 6, label: "Finance & Insurance" },
            { value: 7, label: "Tagihan Bulanan" },
            { value: 8, label: "E-Wallet" },
          ]}
          readOnly
        />
        <CHSelect
          title="E-Wallet Selection"
          name="external_provider"
          data={[
            {
              label: "Gopay",
              value: 1,
            },
            {
              label: "OVO",
              value: 2,
            },
          ]}
          readOnly
        />
        <CHTextField
          title="Amount"
          name="amount"
          type="number"
          min={0}
          isRequired
          readOnly
        />
        <CHTextField
          title="Price (in RukiKoin)"
          name="price"
          type="number"
          min={0}
          isRequired
          readOnly
        />
        <CHTextField
          title="Quota"
          name="quota"
          type="number"
          min={0}
          isRequired
          readOnly
        />
        <CHRichTextField
          title="Vouchers Detail"
          name="vouchers_detail"
          isRequired
          readOnly
        />
        <CHRichTextField
          title="How to use"
          name="htu"
          isRequired
          readOnly
        />
        <CHRichTextField
          title="Term & Condition"
          name="tnc"
          isRequired
          readOnly
        />
        <CHTextField
          title="Expiry time voucher Rukita and E-Wallet(in days)"
          name="expiry_time"
          type="number"
          min={0}
          readOnly
        />
        <CHDateTimePicker
          title="Date Started"
          name="date_started"
          isRequired
          readOnly
        />
        <CHDateTimePicker
          title="Date Ended"
          name="date_ended"
          isRequired
          readOnly
        />
        <CHDateTimePicker
          title="Date Published"
          name="date_published"
          isRequired
          readOnly
        />
        <CHTextField
          title="Partner Name"
          name="brand_name"
          maxLength={50}
          readOnly
        />
        <CHRichTextField
          title="Partner's Information(max 119 char)"
          name="brand_information"
          readOnly
        />
        <CHImageView title="Icon (max size 250kB)" name="icon" />
        <CHTextField
          title="Title Push Notification(Max 50 Char)"
          name="title_pn"
          maxLength={50}
          readOnly
        />
        <CHTextField
          title="Content Push Notification(Max 150 Char)"
          name="content_pn"
          maxLength={150}
          readOnly
        />
        <CHImageView
          title="Push Notification (recommendation ratio image 2:1, max size 500kB): (max size 250kB)"
          name="pn_picture_original"
        />
        <CHTextField
          title="Maximum Redem per user"
          name="multiple_use"
          type="number"
          min={0}
          readOnly
        />
        <CHTextField
          title="Partner's URL"
          name="partner_url"
          readOnly
        />
        <CHCheckBox
          checkboxTitle="Featured Voucher"
          name="featured_voucher"
          readOnly
        />
        <CHTextField
          title="Order No"
          name="order_no"
          type="number"
          min={0}
          readOnly
        />
        <CHCheckBox
          checkboxTitle="Eligible to All Users Sign In"
          name="eligible_to_all_users_sign_in"
          readOnly
        />
        <CHCheckBox
          checkboxTitle="Published"
          name="published"
          readOnly
        />
        <CHFileUpload
          title="Upload Unique Code Voucher :"
          name="upload_voucher_code"
          readOnly
          enableDownload
        />
        <CHTextView
          title="Remaining Quota"
          name="remaining_quota"
          readOnly
        />
      </TabPaneSection>
    </TabPane>
  );
}

export default withRouter(VoucherManagement);
