import utils from "../../utils";

let configBuilder = {
  buildTablePageConfig: (config) => {
    return {
      ...config,
      get initPath() {
        return this.baseLink
          ? this.baseLink.replace(/^\//, "") + "/"
          : "";
      },
      get onSubmitPath() {
        return this.initPath;
      },
      get table() {
        return {
          ...this.__table__,
          path: this.initPath ? this.initPath.replace(/\/$/, "") : "",
          restAccessCode: this.restAccessCode,
        };
      },
    };
  },
  buildAddEditPageConfig: (
    config,
    imageFields = [],
    stripFields = [],
    type = "edit",
    beforeSetStateCallback = null,
    afterSetStateCallback = null
  ) => {
    return {
      ...config,
      get onInit() {
        return (id, setState, user, accesses) => {
          utils.form.checkPermission(
            id,
            this.restAccessCode,
            accesses,
            user,
            () => {
              utils.form.loadFormData(
                id,
                setState,
                this.initPath,
                type,
                beforeSetStateCallback,
                afterSetStateCallback
              );
            },
            this.isTeamMenu
          );
        };
      },
      get onSubmit() {
        return (id = null, state, setState, e, props, buttonFlag) => {
          utils.form.saveOrEditFormData(
            id,
            state,
            setState,
            e,
            props,
            buttonFlag,
            this,
            this.onSubmitPath,
            stripFields,
            imageFields,
            this.restAccessCode
          );
        };
      },
    };
  },
};

export default configBuilder;
