import React from "react";
import Page from "../../../../components/ubold/organisms/Page";
import TableHistory from "../../../../components/ubold/organisms/TableHistory/TableHistory";

import definitions from "./definitions";

const TenantInvoiceSOPHistoryPage: React.FC = (props) => {
  return (
    <Page title={"Tenant Invoices SOP History"}>
      <TableHistory {...definitions} />
    </Page>
  );
};

export default TenantInvoiceSOPHistoryPage;
