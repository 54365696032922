import React from "react";
import useModal from "./useModal";

import {
  ModalBodyStyled,
  ModalFooterStyled,
  ModalHeaderStyled,
  ModalStyled,
} from "./RuGeneralModal.styled";

import {
  ButtonPrimary,
  ButtonSecondary,
} from "components/ubold/atoms/General.css";

interface IRuGeneralModal {
  className?: string;
  canvasClassName?: string;
}

const RuGeneralModal: React.FC<IRuGeneralModal> = ({
  className = "",
  canvasClassName = "",
}) => {
  let {
    ModalContent,
    handleModal,
    modal,
    modalOptions = { btnLeft: null, btnRight: null, title: null },
  } = useModal();

  const { btnLeft, btnRight, title, closeBtnID } = modalOptions;

  return (
    <ModalStyled
      className={className}
      isOpen={modal}
      toggle={handleModal}
    >
      <div className={`canvas`} style={{ padding: 0 }}>
        <ModalHeaderStyled>
          <span>{title}</span>
          <button
            id={closeBtnID ?? ""}
            onClick={(e) => handleModal("CLOSE")}
            className="position-relative"
          >
            <img
              height={12}
              width={12}
              src="/assets/icons/icon-close.svg"
            />
            {closeBtnID && (
              <span
                className="overlay-navigation"
                id={closeBtnID ?? ""}
              />
            )}
          </button>
        </ModalHeaderStyled>

        <ModalBodyStyled
          style={{
            paddingTop: 16,
            paddingRight: 18,
            paddingBottom: 12,
            paddingLeft: 18,
          }}
        >
          {ModalContent}
        </ModalBodyStyled>

        <ModalFooterStyled className="d-flex" style={{ padding: 16 }}>
          {!!btnLeft && (
            <ButtonSecondary
              // className="px-8 py-3 mr-4"
              onClick={btnLeft.onClick}
              id={btnLeft.htmlID ?? ""}
            >
              {btnLeft.text}
            </ButtonSecondary>
          )}

          {!!btnRight && (
            <ButtonPrimary
              // className="px-8 py-3"
              onClick={btnRight.onClick}
              id={btnRight.htmlID ?? ""}
            >
              {btnRight.text}
            </ButtonPrimary>
          )}
        </ModalFooterStyled>
      </div>
    </ModalStyled>
  );
};

export default RuGeneralModal;
