import secondaryDataHandler from "./SecondaryDataHandler";

import utils from "../../../utils";

import configBuilder from "../../../components/ubold/ConfigBuilder";

import configs from "../../../configs";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

let definitions = {
  title: "Tenant Invoices SOP",
  frontendPath: "/finance/tenant_invoice_sop",
  baseLink: "/finances_new/invoice",
  restAccessCode: "finance_new.invoice",
  reloadAfterAdd: true,
  __table__: {
    filterColumn: 3,
    isHideRemoveButton: true,
    hideRemoveButton: true,
    isAddButtonDisabled: true,
    removeIdentifier: "number",
    hasSecondaryData: true,
    secondaryDataRow: 3,
    secondaryDataItem: 13,
    secondaryDataHandler: secondaryDataHandler,
    hideEditButton: true,
    presetParams: "source=1",
    filterButtonsRight: [
      {
        name: "Export",
        onClick: (e, state) => {
          const fieldMaps = {
            date: "date",
            invoice: "number",
            order: "order",
            tenant: "tenant",
            building: "building",
            payment_method: "selected_payment_method",
            building_type: "building_type",
            midtrans_number: "midtrans",
            invoice_status: "invoice_status",
            release_status: "release_status",
          };

          const params = [];

          for (let key of Object.keys(state)) {
            if (
              state[key] !== null &&
              state[key] !== undefined &&
              fieldMaps[key]
            ) {
              if (typeof state[key] === "string") {
                if (state[key].trim() !== "") {
                  params.push(fieldMaps[key] + "=" + state[key]);
                }
              } else if (typeof state[key] === "object") {
                params.push(fieldMaps[key] + "=" + state[key].value);
              } else {
                params.push(fieldMaps[key] + "=" + state[key]);
              }
            }
          }

          const sweetAlert = withReactContent(Swal);

          utils.httpClient.get(
            configs.apiUrl +
              "finances_new/invoice/export/?" +
              params.join("&"),
            (response) => {
              sweetAlert.fire("", response.message, "info");
            },
            (error, message) => {
              sweetAlert.fire("", message, "error");
            }
          );
        },
      },
    ],
    filters: [
      {
        id: "invoice",
        name: "Invoice ID",
        type: "async_select",
        data: "finances_new/invoice?__type__=select_entries",
        customAdditionalParams: "&source=1",
        itemsExtractor: (item) => {
          return {
            label: item.number,
            value: item.id,
          };
        },
      },
      {
        id: "order",
        name: "Order ID",
        type: "async_select",
        data: "order_new/order?__type__=select_entries",
        itemsExtractor: (item) => {
          return {
            label: item.number,
            value: item.id,
          };
        },
      },
      {
        id: "tenant",
        name: "Tenant Name & Email",
        type: "async_select",
        data: "user/tenant?__type__=select_entries",
        itemsExtractor: (item) => {
          const label = `${item.name} - ${item.email}`;
          return {
            label,
            value: item.id,
          };
        },
      },
      {
        id: "building",
        name: "Building Name",
        type: "async_select",
        data: "building/building?__type__=select_entries",
        itemsExtractor: (item) => {
          return {
            label: item.building_name,
            value: item.id,
          };
        },
      },
      {
        id: "room_number",
        name: "Room Number",
        type: "async_select",
        data: "building/room?__type__=select_entries",
        dependsOnPrefix: "",
        dependsOn: "building",
        requiredDependsOnBeforeLoad: true,
        helperText:
          "Please select building name to enable this filter",
      },
      {
        id: "invoice_status",
        name: "Invoice Status",
        type: "select",
        data: {
          optionField: "finance_new_invoice_status",
        },
      },
      {
        id: "invoice_type",
        name: "Invoice Type",
        type: "select",
        data: {
          optionField: "finance_new_invoice_type",
        },
      },
      {
        id: "date",
        name: "Started Date (Export Only)",
        type: "date",
        modeRange: true,
      },
    ],
    columns: [
      {
        id: "number",
        name: "Invoice ID",
        enableHyperlink: true,
      },
      {
        id: "order_number",
        name: "Order ID",
        renderer: (_, value) =>
          `<a class='link-rukita' target='_blank' rel='noopener' style='color: #35b0a7;' href='/sales/order_sop/edit/${value.order}'>${value.order_number}</a>`,
      },
      {
        id: "tenant_name",
        name: "Tenant Name",
      },
      {
        id: "building_name",
        name: "Building Name",
      },
      {
        id: "invoice_type",
        name: "Invoice Type",
        canDoOrdering: false,
        referStateFieldName: "finance_new_invoice_type",
        renderer: (value, _, constants) => {
          let label = "-";
          (constants ?? []).forEach((obj) => {
            if (obj.value === parseInt(value)) {
              label = obj.label;
            }
          });
          return label;
        },
      },
      {
        id: "invoice_status",
        name: "Invoice Status",
        canDoOrdering: false,
        referStateFieldName: "finance_new_invoice_status",
        renderer: (value, _, constants) => {
          let label = "-";
          (constants ?? []).forEach((obj) => {
            if (obj.value === parseInt(value)) {
              label = obj.label;
            }
          });
          return label;
        },
      },
      {
        id: "grand_total",
        name: "Invoice Grand Total",
        renderer: (value) => {
          return utils.formatter.currency(value);
        },
      },
      {
        id: "release_status",
        name: "Release Status",
        canDoOrdering: false,
        referStateFieldName: "finance_new_invoice_release_status",
        renderer: (value, _, constants) => {
          let label = "-";
          (constants ?? []).forEach((obj) => {
            if (obj.value === parseInt(value)) {
              label = obj.label;
            }
          });
          return label;
        },
      },
      {
        id: "started_at",
        name: "Started Date",
        renderer: (value) => {
          return utils.formatter.date(value);
        },
      },
      {
        id: "ended_at",
        name: "Ended Date",
        renderer: (value) => {
          return utils.formatter.date(value);
        },
      },
      {
        id: "date_created",
        name: "Created Date",
        renderer: (value) => {
          return utils.formatter.dateTime(value);
        },
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
