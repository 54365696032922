import configBuilder from "../../../components/ubold/ConfigBuilder";

import utils from "../../../utils";

let definitions = {
  title: "Discount Category",
  frontendPath: "/marketing/promotion/discount/discount_category",
  baseLink: "/finance/discount_category",
  restAccessCode: "finance.discount_category",
  __table__: {
    filterColumn: 2,
    filters: [
      {
        id: "name",
        name: "Category Name",
        type: "text",
      },
      {
        id: "date_created",
        name: "Created Date",
        type: "date",
      },
    ],
    columns: [
      {
        id: "name",
        name: "Category Name",
        enableHyperlink: true,
      },
      {
        id: "date_created",
        name: "Created Date",
        renderer: (data) => {
          return utils.formatter.dateTime(data);
        },
      },
    ],
    hideEditButton: true,
  },
};

export default configBuilder.buildTablePageConfig(definitions);
