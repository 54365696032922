import React, { useState } from "react";
import styled from "styled-components/macro";

import { IPasswordFieldProps } from "@types";

// import utils from "../../../../utils";

const CPasswordField: React.FC<IPasswordFieldProps> = ({
  name = "password",
  onChange,
  readOnly,
  rules,
  setState,
  state,
  validate,
  ...props
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const hasError = state?.[name + "Error"];

  return (
    <>
      <FieldContainer>
        <Input
          autoComplete="off"
          disabled={readOnly}
          hasError={hasError}
          onChange={
            onChange
              ? onChange
              : (e) => {
                  const value = e.target.value;

                  setState((prevState: any) => ({
                    ...prevState,
                    [name]: value === "" ? null : value,
                  }));

                  // if (
                  //   value !== "" &&
                  //   utils.commons.isFunction(validate)
                  // ) {
                  //   // validate(value, setErrorMessage);
                  // } else {
                  //   // if (
                  //   //   setErrorMessage &&
                  //   //   utils.commons.isFunction(setErrorMessage)
                  //   // ) {
                  //   //   setErrorMessage(null);
                  //   // }
                  // }
                }
          }
          type={isPasswordVisible ? "text" : "password"}
          value={state?.[name] === null ? "" : state?.[name]}
          {...props}
        />

        <InputIconContainerLeft>
          <IconStyled
            height={16}
            width={16}
            src="/assets/icons/key-fill.svg"
          />
        </InputIconContainerLeft>

        <InputIconContainerRight>
          {hasError && (
            <IconStyled
              height={16}
              width={16}
              src="/assets/icons/exclamation-mark.svg"
              style={{ marginRight: 8 }}
            />
          )}

          <ButtonTogglePasswordVisibility
            onClick={(e) => {
              e.preventDefault();
              setIsPasswordVisible((prevState) => !prevState);
            }}
            tabIndex={-1}
            type="button"
          >
            <IconStyled
              src={
                isPasswordVisible
                  ? "/assets/icons/eye-slash-fill.svg"
                  : "/assets/icons/eye-fill.svg"
              }
              height={16}
              width={16}
            />
          </ButtonTogglePasswordVisibility>
        </InputIconContainerRight>

        {rules?.length > 0 &&
          rules?.map((rule) => (
            <React.Fragment key={"rule-" + rule.text}>
              {rule.visible && (
                <FieldInfo hasError={rule?.error}>
                  {rule?.text}
                </FieldInfo>
              )}
            </React.Fragment>
          ))}

        {typeof state?.[name + "Error"] === "string"
          ? state?.[name + "Error"] && (
              <FieldInfo hasError={state?.[name + "Error"]}>
                {state?.[name + "Error"]}
              </FieldInfo>
            )
          : state?.[name + "Error"]?.length > 0 &&
            state?.[name + "Error"]?.map((error: string) => (
              <FieldInfo hasError={error} key={error}>
                {error}
              </FieldInfo>
            ))}
      </FieldContainer>
    </>
  );
};

const ButtonTogglePasswordVisibility = styled.button`
  display: inline-flex;

  background-color: unset;
  border: unset;
  padding: unset;
  outline: unset;

  &:active,
  &:focus {
    outline: unset;
  }
`;

export default CPasswordField;

const FieldContainer = styled.div`
  position: relative;
  width: 100%;
`;

const FieldInfo = styled.div<{
  hasError?: boolean | string;
}>`
  font-size: 10px;
  color: ${({ hasError }) => (hasError ? "#D00C1A" : "#999999")};
  margin-top: 4px;
  font-weight: 400;
`;

const Input = styled.input<{
  hasError?: string;
}>`
  color: #222222;
  height: 34px;
  font-size: 12px;
  width: 100%;
  border: 1px solid
    ${({ hasError }) => (hasError ? "#D00C1A" : "#CCCCCC")};
  border-radius: 4px;
  outline: none;
  padding: 8px 12px 8px 34.5px;
  box-sizing: border-box;
  transition: 0.3s;

  &:focus {
    border: 1px solid #009c96;
    box-shadow: 0 0 4px 0px #00bbb4;
  }

  &:hover {
    border-color: #009c96;
  }

  &:disabled {
    background: #fafafa;
  }
`;

const IconStyled = styled.img`
  background-color: transparent;
  border: none;
`;

const InputIconContainerLeft = styled.div`
  position: absolute;
  top: 9px;
  left: 12px;

  display: inline-flex;
`;

const InputIconContainerRight = styled.div`
  position: absolute;
  top: 9px;
  right: 12px;

  display: inline-flex;
`;
