import {useParams, withRouter} from "react-router-dom";

import TabPaneSecondary from "../../../../../../components/ubold/organisms/TabPaneSecondary";

import COneOrManyToManyField from "../../../../../../components/ubold/molecules/forms/COneOrManyToManyField";

function InvoiceMidtransTransactions(props) {
    const {definitions, tab, state, setState} = props;

    let {id} = useParams();

    return (
        <TabPaneSecondary
            definitions={definitions}
            tab={tab}
            restAccessCodeNamespace='midtrans_transaction'
            state={state}
            setState={setState}>
            <COneOrManyToManyField
                id={id}
                name={tab.id}
                restPath='payment_gateway/midtrans_transaction/'
                relationKey='invoice_id'
                readOnly
                columns={[
                    {
                        name: 'midtrans_number',
                        type: 'read_only',
                        title: 'Midtrans Number',
                    },
                    {
                        name: 'payment_type',
                        type: 'read_only',
                        title: 'Payment Type',
                    },
                    {
                        name: 'status',
                        type: 'read_only',
                        title: 'Status',
                        renderer: value => {
                            if(value === 1) {
                                return "Waiting"
                            } else if(value === 2) {
                                return "Pending"
                            } else if(value === 3) {
                                return "Cancel"
                            } else if(value === 4) {
                                return "Fail"
                            } else if(value === 5) {
                                return "Paid"
                            } else if(value === 6) {
                                return "Expired"
                            } else if(value === 13) {
                                return "Others"
                            }
                        }
                    },
                    {
                        name: 'status_timestamp',
                        type: 'read_only',
                        title: 'Status Timestamp',
                    },
                    {
                        name: 'token',
                        type: 'read_only',
                        title: 'Token',
                    },
                    {
                        name: 'bank',
                        type: 'read_only',
                        title: 'Bank',
                    },
                ]}
            />
        </TabPaneSecondary>
    );
}

export default withRouter(InvoiceMidtransTransactions);