import configBuilder from "../../../../components/ubold/ConfigBuilder";

import pageDefinitions from "../definitions";

const definitions = {
  ...pageDefinitions,
  tabs: [
    {
      id: "view_payment",
      name: "View Payment",
    },
  ],
};

export default configBuilder.buildAddEditPageConfig(
  definitions,
  [],
  [],
  "detail"
);
