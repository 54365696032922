import React, { MouseEventHandler } from "react";

import useGlobalState from "state";

import styled from "styled-components";

const Waiting: React.FC<{
  className?: string;
  messages?: React.ReactElement;
  onClose: MouseEventHandler<HTMLButtonElement>;
}> = ({ className, messages, onClose }) => {
  const [user] = useGlobalState("user");

  return (
    <WaitingStyled className={className}>
      <img
        height={90}
        width={160}
        alt="Waiting Illustration"
        src={
          process.env.PUBLIC_URL +
          "/assets/images/modal/export/Export_Waiting.svg"
        }
      />

      <h5>Your Export Process is in a Line</h5>

      {messages ? (
        messages
      ) : (
        <p>
          {
            "Looks like you've requested an export in a few times. Your export request is still in line, and will be sent to your email "
          }
          {user && user.email && <strong>{user.email}</strong>} after
          the process is complete.
        </p>
      )}

      <button onClick={onClose}>OK</button>
    </WaitingStyled>
  );
};

const WaitingStyled = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  button {
    height: 36px;
    width: 76px;

    background-color: #35b0a7;
    border: unset;
    border-radius: 2px;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    padding: 8px 28.5px;
  }

  h5,
  p {
    text-align: center;
  }

  h5 {
    font-size: 16px;
    font-weight: 500;
    margin-top: unset;
    margin-bottom: 16px;
  }

  img {
    margin-bottom: 30px;
  }

  p {
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 30px;
  }

  strong {
    color: #000;
    font-weight: 500;
  }
`;

export default Waiting;
