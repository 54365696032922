import utils from "../../../../utils";

import configBuilder from '../../../../components/ubold/ConfigBuilder';

import pageDefinitions from "../definitions";

const definitions = {...pageDefinitions,
    tabs: [
        {
            id: 'structure',
            name: 'Structure'
        },
        {
            id: 't_roles',
            name: 'Roles'
        },
        {
            id: 't_users',
            name: 'Users'
        },
    ],
    validators: {
        name: (value) => {
            return utils.validator.validateRequired(value);
        },
        can_have_team: (value) => {
            return utils.validator.validateRequired(value);
        },
    },
}

export default configBuilder.buildAddEditPageConfig(definitions);