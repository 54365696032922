import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import Page from "components/ubold/organisms/Page";
import styled from "styled-components/macro";
import useGlobalState from "state";

import definitions from "./definitions";

import UserInfoBoard from "components/specific/profileSetting/UserInfoBoard";

import EditProfileSection from "./Section/EditProfileSection";
import ChangePasswordSection from "./Section/ChangePasswordSection";
import VerticalTabsWithPanel from "components/ubold/organisms/VerticalTabsWithPanel/VerticalTabsWithPanel";
import useModal from "components/global/Modal/useModal";
import GeneralModalBodyTemplate from "components/global/Modal/GeneralModalBodyTemplate";

const ProfilePage: React.FC = (props: any) => {
  const { handleModal } = useModal();

  const [user] = useGlobalState("user");
  const { state }: { state: any } = useLocation();
  const history = useHistory();

  const [target, setTarget] = useState<string>(state.target);
  const [isFormProfileDirty, setIsFormProfileDirty] =
    useState<boolean>(false);
  const [isFormPasswordDirty, setIsFormPasswordDirty] =
    useState<boolean>(false);

  const renderUserInfo = () => {
    if (!user) return "Default User";
    if (user.first_name && user.last_name) {
      return `${user.first_name} ${user.last_name}`;
    } else {
      return user.username;
    }
  };

  window.addEventListener("beforeunload", (e: any) => {
    if (!isFormProfileDirty) return;

    e.preventDefault();
    e.returnValue = "";
    return;
  });

  const logout = () => {
    localStorage.clear();
    if (isFormProfileDirty || isFormPasswordDirty) {
      handleModal(
        "OPEN",
        <GeneralModalBodyTemplate
          render={
            <p>
              You have unsaved changes. Are you sure you want to
              leave? Unsaved changes may be lost.
            </p>
          }
        />,
        {
          btnLeft: {
            onClick: () => {
              handleModal("CLOSE");
              props.history.push("/logout");
            },
            text: "Yes, Leave This Page",
          },
          btnRight: {
            onClick: () => handleModal("CLOSE"),
            text: "No, Stay on This Page",
          },
          title: "Unsaved Changes",
        }
      );
    } else {
      props.history.push("/logout");
    }
  };

  const handleBack = () => {
    if (isFormProfileDirty || isFormPasswordDirty) {
      handleModal(
        "OPEN",
        <GeneralModalBodyTemplate
          render={
            <p>
              You have unsaved changes. Are you sure you want to
              leave? Unsaved changes may be lost.
            </p>
          }
        />,
        {
          btnLeft: {
            onClick: () => {
              handleModal("CLOSE");
              props.history.push("/u/home");
            },
            text: "Yes, Leave This Page",
          },
          btnRight: {
            onClick: () => handleModal("CLOSE"),
            text: "No, Stay on This Page",
          },
          title: "Unsaved Changes",
        }
      );
    } else {
      props.history.push("/u/home");
    }
  };

  const panels: Array<{ id: string; element: React.ReactElement }> = [
    {
      id: "edit_profile",
      element: (
        <EditProfileSection
          key="panel-edit_profile"
          title="Edit Profile"
          setIsFormProfileDirty={setIsFormProfileDirty}
        />
      ),
    },
    {
      id: "change_password",
      element: (
        <ChangePasswordSection
          key="panel-change_password"
          setIsFormPasswordDirty={setIsFormPasswordDirty}
        />
      ),
    },
  ];

  const handleTargetPage = (id: string) => {
    setTarget(id);
    if (id === "edit_profile") {
      setIsFormProfileDirty(false);
    } else {
      setIsFormPasswordDirty(false);
    }
    history.push({
      pathname: "/profile_settings",
      state: {
        target: id,
      },
    });
  };

  useEffect(() => {
    setTarget(state.target);
  }, [state]);
  return (
    <Page container="xl" isProfilePage>
      <ProfileContainer>
        <BackBtnStyled
          src="/assets/icons/fi_arrow-left.svg"
          onClick={handleBack}
        />
        {definitions.title}

        <VerticalTabsWithPanel
          addOnsMenus={
            <BtnLogout onClick={logout}>
              <img
                src="/assets/icons/box-arrow-right.svg"
                alt="ic-logout"
                style={{ marginRight: 16 }}
              />
              Logout
            </BtnLogout>
          }
          className="pt-3"
          menus={definitions.sections}
          onClickTab={(id) => handleTargetPage(id)}
          panels={panels}
          section={{
            head: (
              <UserInfoBoard
                username={user?.username?.charAt(0).toUpperCase()}
                title={renderUserInfo()}
              />
            ),
          }}
          state={target}
          isFormProfileDirty={isFormProfileDirty}
          isFormPasswordDirty={isFormPasswordDirty}
        />
      </ProfileContainer>
    </Page>
  );
};

export default ProfilePage;

const BtnLogout = styled.div`
  display: flex;
  flex-direction: row;
  padding: 12px;
  min-height: 44px;
  border-radius: 4px;
  transition: 0.2s;

  :hover {
    background: #fafafa;
    font-weight: 500;
  }
`;

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px 80px 64px 0;
  font-family: "Rubik";
  font-weight: 500;
  font-size: 16px;
  color: #222222;
`;

const BackBtnStyled = styled.img`
  display: block;
  position: absolute;
  left: 70px;
  cursor: pointer;
`;
