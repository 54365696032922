import { useState } from "react";

import { useParams, withRouter } from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import Form from "../../../../components/ubold/organisms/Form";

import CVTextField from "../../../../components/ubold/molecules/forms/CVTextField";
import CVTextArea from "../../../../components/ubold/molecules/forms/CVTextArea";
import CVAsyncSelect from "../../../../components/ubold/molecules/forms/CVAsyncSelect";

import definitions from "./definitions";

function ProvinceViewPage() {
  let { id }: { id: string } = useParams();

  const initialState = {};

  const [state, setState] = useState(initialState);

  return (
    <Page
      title={"Detail of " + definitions.title}
      restAccessCode={definitions.restAccessCode}
    >
      <Form
        id={id}
        definitions={definitions}
        readOnly
        state={state}
        setState={setState}
      >
        <CVTextField
          name="name"
          title="Name"
          maxLength={50}
          isRequired
          readOnly
        />
        <CVAsyncSelect
          name="country"
          title="Country"
          data="place/country?__type__=select_entries"
          isRequired
          readOnly
        />
        <CVTextField
          name="meta_title"
          title="Meta Title"
          maxLength={255}
          readOnly
        />
        <CVTextField
          name="meta_title_en"
          title="Meta Title EN"
          maxLength={255}
          readOnly
        />
        <CVTextArea
          name="meta_description"
          title="Meta Description"
          readOnly
        />
        <CVTextArea
          name="meta_description_en"
          title="Meta Description EN"
          readOnly
        />
      </Form>
    </Page>
  );
}

export default withRouter(ProvinceViewPage);
