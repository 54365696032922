import utils from "../../../utils";

import configBuilder from "../../../components/ubold/ConfigBuilder";

let definitions = {
  title: "Rooms",
  frontendPath: "/building/room/room",
  baseLink: "/building/room",
  restAccessCode: "building.room",
  __table__: {
    filterColumn: 3,
    hideEditButton: true,
    filters: [
      {
        id: "name",
        name: "Name",
        type: "text",
      },
      {
        id: "building",
        name: "Building",
        type: "async_select",
        data: "building/building?__type__=select_entries",
        itemsExtractor: (item) => {
          return {
            label: item.building_name,
            value: item.id,
          };
        },
      },
      {
        id: "room_variant",
        name: "Room Variant",
        type: "async_select",
        data: "building/room_variant?__type__=select_entries",
        dependsOn: "building",
      },
    ],
    columns: [
      {
        id: "name",
        name: "Name",
        enableHyperlink: true,
      },
      {
        id: "room_variant_name",
        name: "Room Variant",
      },
      {
        id: "building_name",
        name: "Building",
      },
      {
        id: "room_type_name",
        name: "Room Type",
      },
      {
        id: "price",
        name: "Price",
        renderer: (value) => {
          return utils.formatter.currency(value);
        },
      },
      {
        id: "room_status",
        name: "Room Status",
        referStateFieldName: "buildings_room_status",
        renderer: (value, _, constants) => {
          let label = "-";
          (constants ?? []).forEach((obj) => {
            if (obj.value === parseInt(value)) {
              label = obj.label;
            }
          });
          return label;
        },
        canDoOrdering: false,
      },
      {
        id: "room_status_notes",
        name: "Room Status Notes",
      },
      {
        id: "floor",
        name: "Floor",
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
