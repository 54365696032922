import { useState } from "react";
import { useParams, withRouter } from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import Form from "../../../../components/ubold/organisms/Form";

import CVTextField from "../../../../components/ubold/molecules/forms/CVTextField";
import CVSwitch from "../../../../components/ubold/molecules/forms/CVSwitch";

import definitions from "./definitions";
import CVImageView from "components/ubold/molecules/forms/CVImageView";

function PromotionHightlightsViewPage() {
  let { id }: { id: string } = useParams();

  const initialState = {};

  const [state, setState] = useState(initialState);

  return (
    <Page
      title={
        (id === undefined ? "Add New" : "Edit") +
        " " +
        definitions.title
      }
      restAccessCode={definitions.restAccessCode}
    >
      <Form
        id={id}
        definitions={definitions}
        readOnly
        state={state}
        setState={setState}
      >
        <CVImageView
          title="Mobile screen (450x253px, 150dpi, max size 500kb)"
          name="banner_picture_original"
        />
        <CVTextField
          title="URL"
          name="url"
          maxLength={2000}
          readOnly
        />
        <CVTextField
          title="Order No"
          name="order_no"
          type="number"
          readOnly
        />
        <CVSwitch title="Published" name="published" readOnly />
      </Form>
    </Page>
  );
}

export default withRouter(PromotionHightlightsViewPage);
