import { useState, useEffect } from "react";
import { useParams, withRouter } from "react-router-dom";

import TabPaneSecondary from "../../../../../../components/ubold/organisms/TabPaneSecondary";

import COneOrManyToManyField from "../../../../../../components/ubold/molecules/forms/COneOrManyToManyField";

import utils from "../../../../../../utils";

function OrderAddons(props: any) {
  const { definitions, tab, state, setState } = props;

  const [addonList, setAddonList] = useState([]);

  let { id } = useParams<{ id: string }>();

  const doneStatus = [4, 5, 6];

  const buildingParam = state["building"]
    ? `&building=${state["building"]}`
    : `&building=0`;

  useEffect(() => {
    if (!state["t_addon_orders"]) return;
    setAddonList(state["t_addon_orders"]);
  }, [state["t_addon_orders"]]);

  return (
    <TabPaneSecondary
      definitions={definitions}
      tab={tab}
      restAccessCodeNamespace="addon_order"
      state={state}
      setState={setState}
      readOnly={true}
    >
      <COneOrManyToManyField
        id={id}
        name={tab.id}
        readOnly={id && doneStatus.includes(state["status"])}
        restPath="order_new/addon_order/"
        relationKey="order_id"
        saveOnlyFromMainForm={true}
        index={2}
        enableListener={true}
        columns={[
          {
            name: "addon_building",
            type: "async_select",
            disabledAtEdit: true,
            isUniqueOption: true,
            title: "Addon Building",
            isRequired: true,
            data: `building/addon__building?__type__=select_entries&limit=1000${buildingParam}`,
            itemsExtractor: (row: any) => {
              return {
                label:
                  row.name +
                  (row["payment_plan"] && row["price"]
                    ? " (" +
                      (row["payment_plan"]
                        ? row["payment_plan"]
                        : "None") +
                      " month) - " +
                      utils.formatter.currency(row["price"])
                    : ""),
                value: row.id,
              };
            },
            readOnly: true,
          },
          {
            name: "number",
            type: "read_only",
            maxLength: 13,
            title: "Addon ID",
            readOnly: true,
          },
          {
            name: "notes",
            type: "custom",
            title: "Notes",
            addonList: addonList,
            readOnly: true,
          },
          {
            name: "status",
            type: "select",
            title: "Status",
            isRequired: true,
            data: {
              optionField: "order_new_addon_order_addon_statuses",
            },
            readOnly: true,
          },
          {
            name: "start_date",
            type: "date",
            title: "Start Date",
            isRequired: true,
            readOnly: true,
          },
          {
            name: "end_date",
            type: "date",
            title: "End Date",
            readOnly: true,
          },
        ]}
      />
    </TabPaneSecondary>
  );
}

export default withRouter(OrderAddons);
