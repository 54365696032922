import { useState } from "react";

import { useParams, withRouter } from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import Form from "../../../../components/ubold/organisms/Form";

import definitions from "./definitions";
import CVTextField from "../../../../components/ubold/molecules/forms/CVTextField";
import CVSelect from "../../../../components/ubold/molecules/forms/CVSelect";
import CVImageView from "components/ubold/molecules/forms/CVImageView";

function CAddEditPage() {
  let { id } = useParams<{ id: string }>();

  const initialState = {
    roles: 2,
  };

  const [state, setState] = useState(initialState);

  return (
    <Page
      title={
        (id === undefined ? "Add New" : "Edit") +
        " " +
        definitions.title
      }
      restAccessCode={definitions.restAccessCode}
    >
      <Form
        id={id}
        definitions={definitions}
        state={state}
        setState={setState}
        readOnly
      >
        <CVTextField
          name="name"
          title="Name"
          maxLength={80}
          isRequired
          readOnly
        />
        <CVTextField
          name="country_code"
          title="Country Code"
          maxLength={2}
          type="number"
          readOnly
        />
        <CVTextField
          name="phone_number"
          title="Phone Number"
          maxLength={16}
          type="number"
          readOnly
        />
        <CVTextField
          name="email"
          title="Email"
          maxLength={254}
          readOnly
        />
        <CVSelect
          name="roles"
          title="Role"
          isRequired
          data={[
            {
              label: "Manager",
              value: 1,
            },
            {
              label: "Maid",
              value: 2,
            },
            {
              label: "Security",
              value: 3,
            },
            {
              label: "Building Manager",
              value: 4,
            },
            {
              label: "Community Associate",
              value: 5,
            },
          ]}
          readOnly
        />
        <CVImageView name="photo_original" title="Photo" />
      </Form>
    </Page>
  );
}

export default withRouter(CAddEditPage);
