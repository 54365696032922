import { useState } from "react";

import { useParams, withRouter } from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import Form from "../../../../components/ubold/organisms/Form";

import definitions from "./definitions";
import CVSelect from "../../../../components/ubold/molecules/forms/CVSelect";
import CVTextView from "../../../../components/ubold/molecules/forms/CVTextView";
import CVMultiCheckBox from "../../../../components/ubold/molecules/forms/CVMultiCheckbox";
import utils from "../../../../utils";

function CAddEditPage() {
  let { id } = useParams();

  const initialState = {};

  const [state, setState] = useState(initialState);

  return (
    <Page
      title={
        (id === undefined ? "Add New" : "Edit") +
        " " +
        definitions.title
      }
      restAccessCode={definitions.restAccessCode}
    >
      <Form
        id={id}
        definitions={definitions}
        canAddAnother={false}
        state={state}
        setState={setState}
      >
        <CVTextView
          name="name"
          title="Name"
          boldTitle
          overrideLayout="12"
        />
        <CVTextView
          name="email"
          title="Email"
          boldTitle
          overrideLayout="12"
        />
        <CVTextView
          name="role"
          title="Role"
          boldTitle
          overrideLayout="12"
        />
        <CVTextView
          name="company_name"
          title="Company Name"
          boldTitle
          overrideLayout="12"
        />
        <CVTextView
          name="comment"
          title="Comment"
          boldTitle
          overrideLayout="12"
        />
        <CVTextView
          name="phone"
          title="Phone"
          boldTitle
          overrideLayout="12"
        />
        <CVMultiCheckBox
          name="occupancy_types"
          title="Selected Needs"
          disabled
          boldTitle
          data={[
            {
              label: "Hunian Karyawan",
              value: "1",
            },
            {
              label: "Custom Coliving",
              value: "2",
            },
            {
              label: "Akomondasi Perjalanan Bisnis",
              value: "3",
            },
            {
              label: "Others",
              value: "4",
            },
          ]}
        />
        <CVTextView
          name="occupancy_source"
          title="Others Preference"
          overrideLayout="12"
          boldTitle
        />
        <CVTextView
          name="location"
          title="Location"
          overrideLayout="12"
          boldTitle
        />
        <CVTextView
          name="approx_number_of_room"
          title="Approx. Number of Rooms"
          overrideLayout="12"
          boldTitle
        />
        <CVTextView
          name="approx_check_in_date"
          title="Approx. Check-in Date"
          overrideLayout="12"
          boldTitle
          renderer={(value) => {
            return utils.formatter.date(value);
          }}
        />
        <CVSelect
          name="source"
          title="Source"
          data={[
            {
              label: "Whole form",
              value: 1,
            },
            {
              label: "WhatsApp form",
              value: 2,
            },
          ]}
          readOnly
        />
        <CVSelect
          name="remarks"
          title="Remarks"
          overrideLayout="12"
          data={[
            {
              label: "Contacted",
              value: 1,
            },
          ]}
        />
        <CVTextView
          name="date_created"
          title="Date Created"
          boldTitle
          overrideLayout="12"
          renderer={(value) => {
            return utils.formatter.dateTime(value);
          }}
        />
        <CVTextView
          name="date_updated"
          title="Date Updated"
          boldTitle
          overrideLayout="12"
          renderer={(value) => {
            return utils.formatter.dateTime(value);
          }}
        />
      </Form>
    </Page>
  );
}

export default withRouter(CAddEditPage);
