import Login from "pages/Login";
import Logout from "pages/Logout";

const AuthRoutes = [
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/logout",
    component: Logout,
  },
];

export default AuthRoutes;
