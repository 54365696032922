import React, { useState } from "react";
import { ToastContext } from "utils/context";
import ToastItem from "./Toast";
import { ToastShape, ToastType as toastTypes } from "@types";
import styled from "styled-components";

const WithToast: React.FC = ({ children }) => {
  const [fullWidth, setFullWidth] = useState<boolean>(false);
  const [list, setList] = useState<ToastShape[]>([]);
  const deleteToast = (id: number) => {
    const listItemIndex = list.findIndex((e) => e.id === id);
    list.splice(listItemIndex, 1);
    setList([...list]);
  };

  const dispatch = ({
    description,
    dTime,
    type = "success",
    variant = "DEFAULT",
  }: {
    description: string;
    dTime?: number;
    type?: toastTypes;
    variant?: "DEFAULT" | "FULL_WIDTH";
  }) => {
    const id = Math.floor(Math.random() * 1001 + 1);

    const toastProperties: ToastShape = {
      id,
      description,
      dismissTime: dTime ?? 5000,
      type,
      variant,
    };

    if (variant === "FULL_WIDTH") {
      setFullWidth(true);
    } else {
      setFullWidth(false);
    }

    setList([toastProperties]);
  };

  return (
    <ToastContext.Provider value={{ dispatch } as any}>
      <ToastContainerStyled className="fixed left-4 bottom-4 z-10 w-full">
        <div className="container-xl">
          <div className="row">
            {fullWidth ? (
              <div className="col-12 mx-auto">
                {list.map((t) => (
                  <ToastItem
                    key={t.id}
                    data={t}
                    deleteToast={deleteToast}
                  />
                ))}
              </div>
            ) : (
              <div className="col-12 col-sm-6 col-xl-5 mx-auto">
                {list.map((t) => (
                  <ToastItem
                    key={t.id}
                    data={t}
                    deleteToast={deleteToast}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </ToastContainerStyled>
      {children}
    </ToastContext.Provider>
  );
};

const ToastContainerStyled = styled.div`
  position: fixed;
  top: 0;
  z-index: 2000;
  width: 100%;
`;

export default WithToast;
