import { withRouter } from "react-router-dom";

import TabPane from "../../../../../../components/ubold/organisms/TabPane";
import TabPaneSection from "../../../../../../components/ubold/organisms/TabPaneSection";
import CHTextField from "../../../../../../components/ubold/molecules/forms/CHTextField";
import CHTextView from "../../../../../../components/ubold/molecules/forms/CHTextView";
import CHTextArea from "../../../../../../components/ubold/molecules/forms/CHTextArea";
import CHSelect from "../../../../../../components/ubold/molecules/forms/CHSelect";
import CHCheckBox from "../../../../../../components/ubold/molecules/forms/CHCheckBox";
import CHImageUpload from "../../../../../../components/ubold/molecules/forms/CHImageUpload";
import CHRichTextField from "../../../../../../components/ubold/molecules/forms/CHRichTextField";
import CHAsyncSelect from "../../../../../../components/ubold/molecules/forms/CHAsyncSelect";

import utils from "../../../../../../utils";

function Addon(props) {
  const { id, definitions, tab, state, setState } = props;

  return (
    <TabPane
      id={id}
      definitions={definitions}
      tab={tab}
      state={state}
      setState={setState}
    >
      <TabPaneSection style={{ padding: "25px 16px 0px" }}>
        <CHTextView name="id" title="ID" />
        <CHTextField
          name="name"
          title="Name"
          maxLength={50}
          isRequired
        />
        <CHRichTextField name="facility" title="Facility" />
        <CHSelect
          name="purchase_type"
          title="Purchase Type"
          isRequired
          data={{
            optionField: "buildings_addon_purchase_type",
          }}
        />
        <CHSelect
          name="type"
          title="Addon Type"
          isRequired
          data={[
            {
              label: "Premium",
              value: 1,
            },
            {
              label: "Personal",
              value: 2,
            },
            {
              label: "Rukita Mods",
              value: 3,
            },
          ]}
        />
        <CHAsyncSelect
          name="addon_category"
          title="Add-on Category"
          data="building/addon_category?__type__=select_entries&published=true&limit=10"
          isRequired
        />
        <CHTextArea name="note_help_text" title="Note Help Text" />
        <CHTextArea
          name="description"
          title="Description"
          isRequired
        />
        <CHCheckBox name="notes_requires" title="Note Requires" />
        <CHImageUpload name="image_1" title="Image 1" />
        <CHImageUpload name="image_2" title="Image 2" />
        <CHTextView
          name="date_created"
          title="Date Created"
          renderer={(value) => {
            return utils.formatter.dateTime(value);
          }}
        />
        <CHTextView
          name="date_updated"
          title="Date Updated"
          renderer={(value) => {
            return utils.formatter.dateTime(value);
          }}
        />
      </TabPaneSection>
    </TabPane>
  );
}

export default withRouter(Addon);
