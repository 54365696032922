import {withRouter} from "react-router-dom";

import TabPane from "../../../../../../components/ubold/organisms/TabPane";
import TabPaneSection from "../../../../../../components/ubold/organisms/TabPaneSection";
import CHAsyncSelect from "../../../../../../components/ubold/molecules/forms/CHAsyncSelect";
import CHTextField from "../../../../../../components/ubold/molecules/forms/CHTextField";
import CHCheckBox from "../../../../../../components/ubold/molecules/forms/CHCheckBox";
import CHTextView from "../../../../../../components/ubold/molecules/forms/CHTextView";

import utils from "../../../../../../utils";

function FirstTab(props) {
    const {id, definitions, tab, state, setState} = props;

    return (
        <TabPane
            id={id}
            definitions={definitions}
            tab={tab}
            state={state}
            setState={setState}>
            <TabPaneSection style={{padding: '25px 16px 0px'}}>
                <CHAsyncSelect
                    name='event'
                    title='Event'
                    data='event/event?__type__=select_entries'
                    isRequired
                    itemsExtractor={value => {
                        return {
                            label: value.event_name,
                            value: value.id
                        }
                    }}
                />
                <CHAsyncSelect
                    name='tenant'
                    title='Tenant'
                    isRequired
                    data='user/tenant?__type__=select_entries'
                />
                <CHTextField
                    name='comment'
                    title='Comment'
                    maxLength={150}
                    isRequired
                />
                <CHAsyncSelect
                    name='parent'
                    title='Parent Comment'
                    data='event/event_comment?__type__=select_entries'
                    itemsExtractor={value => {
                        return {
                            label: value.comment,
                            value: value.id
                        }
                    }}
                />
                <CHTextField
                    name='total_likes'
                    title='Total Likes'
                    type='number'
                />
                <CHCheckBox
                    name='pinned'
                    title='Pinned'
                />
                <CHTextView
                    name='date_created'
                    title='Date Created'
                    renderer={value => {
                        return utils.formatter.dateTime(value);
                    }}
                />
                <CHTextView
                    name='date_update'
                    title='Date Updated'
                    renderer={value => {
                        return utils.formatter.dateTime(value);
                    }}
                />
            </TabPaneSection>
        </TabPane>
    );
}

export default withRouter(FirstTab);