import configBuilder from "../../../components/ubold/ConfigBuilder";

let definitions = {
  title: "Addon Category",
  frontendPath: "/building/addon_category",
  baseLink: "/building/addon_category",
  restAccessCode: "building.addon_category",
  __table__: {
    filterColumn: 4,
    hideEditButton: true,
    filters: [
      {
        id: "name",
        name: "Add Ons Name",
        type: "text",
      },
      {
        id: "published",
        name: "By Published",
        type: "select",
        data: [
          {
            label: "Yes",
            value: true,
          },
          {
            label: "No",
            value: false,
          },
        ],
      },
    ],
    columns: [
      {
        id: "name",
        name: "Name",
        enableHyperlink: true,
      },
      {
        id: "published",
        name: "Published",
        center: true,
        renderer: (value: string) => {
          if (value === "true") {
            return '<span style="color: rgb(26, 188, 156);"> ✔ </span>';
          } else {
            return '<span style="color: red;">✘</span>';
          }
        },
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
