import {useState} from "react";

import {useParams, withRouter} from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import TabHeaders from "../../../../components/ubold/organisms/TabHeaders";
import TabContent from "../../../../components/ubold/organisms/TabContent";
import TabbedForm from "../../../../components/ubold/organisms/TabbedForm";

import User from "./Tabs/User";
import UserRole from "./Tabs/UserRole";
import UserStructure from "./Tabs/UserStructure";

import definitions from "./definitions";

function UserAddEditPage() {
    let { id } = useParams();

    const initialState = {
        is_available: true,
        price: 0,
        electricity: 1,
        bathroom: 1,
        gender: 0,
        room_status: 0
    };

    const [state, setState] = useState(initialState);

    return (
        <Page title={(id === undefined ? 'Add New' : 'Edit') + ' ' +  definitions.title}
              restAccessCode={definitions.restAccessCode}>
            <TabbedForm
                id={id}
                state={state}
                setState={setState}
                definitions={definitions}>
                <TabHeaders />
                <TabContent>
                    <User />
                    <UserRole />
                    <UserStructure />
                </TabContent>
            </TabbedForm>
        </Page>
    );
}

export default withRouter(UserAddEditPage);