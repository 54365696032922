import { useState } from "react";

import { useParams, withRouter } from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import Form from "../../../../components/ubold/organisms/Form";

import definitions from "./definitions";
import CVTextArea from "../../../../components/ubold/molecules/forms/CVTextArea";
import CVSelect from "../../../../components/ubold/molecules/forms/CVSelect";
import CVTextField from "../../../../components/ubold/molecules/forms/CVTextField";
import CVTextView from "../../../../components/ubold/molecules/forms/CVTextView";

import utils from "../../../../utils";

function InterestViewPage() {
  let { id }: { id: string } = useParams();

  const initialState = {};

  const [state, setState] = useState(initialState);

  return (
    <Page
      title={"Detail of " + definitions.title}
      restAccessCode={definitions.restAccessCode}
    >
      <Form
        id={id}
        definitions={definitions}
        canAddAnother={false}
        readOnly
        state={state}
        setState={setState}
      >
        <CVTextArea
          name="comment"
          title="Comment"
          overrideLayout="12"
          readOnly
        />
        <CVSelect
          name="status"
          title="Status"
          overrideLayout="12"
          readOnly
          data={[
            {
              label: "New",
              value: 1,
            },
            {
              label: "Contacted",
              value: 2,
            },
            {
              label: "Visit",
              value: 3,
            },
            {
              label: "Deal",
              value: 4,
            },
          ]}
        />
        <CVTextField
          name="referral_code"
          title="Referral Code"
          overrideLayout="12"
          readOnly
          maxLength={254}
        />
        <CVTextView
          name="id"
          title="ID"
          boldTitle
          overrideLayout="12"
          readOnly
        />
        <CVTextView
          name="airtable_id"
          title="Airtable ID"
          boldTitle
          overrideLayout="12"
          readOnly
        />
        <CVTextView
          name="name"
          title="Name"
          boldTitle
          overrideLayout="12"
          readOnly
        />
        <CVTextView
          name="email"
          title="Email"
          boldTitle
          overrideLayout="12"
          readOnly
        />
        <CVTextView
          name="phone"
          title="Phone"
          boldTitle
          overrideLayout="12"
          readOnly
        />
        <CVTextView
          name="contact_preference"
          title="Contact Preference"
          boldTitle
          overrideLayout="12"
          readOnly
        />
        <CVTextView
          name="contact_time"
          title="Contact Time"
          boldTitle
          overrideLayout="12"
          readOnly
          renderer={(value: string) => {
            return utils.formatter.dateTime(value);
          }}
        />
        <CVTextView
          name="visit_time"
          title="Visit Time"
          boldTitle
          overrideLayout="12"
          readOnly
          renderer={(value: string) => {
            return utils.formatter.dateTime(value);
          }}
        />
        <CVTextView
          name="enquiry_type"
          title="Enquiry Type"
          boldTitle
          overrideLayout="12"
          readOnly
        />
        <CVTextView
          name="enquiry"
          title="Enquiry"
          boldTitle
          overrideLayout="12"
          readOnly
        />
        <CVTextView
          name="comment"
          title="Comment"
          boldTitle
          overrideLayout="12"
          readOnly
        />
        <CVTextView
          name="preferred_location"
          title="Preferred Location"
          boldTitle
          overrideLayout="12"
          readOnly
        />
        <CVTextView
          name="preferred_building"
          title="Preferred Building"
          boldTitle
          overrideLayout="12"
          readOnly
        />
        <CVTextView
          name="source"
          title="Source"
          boldTitle
          overrideLayout="12"
          readOnly
        />
        <CVTextView
          name="collection_id"
          title="Collection ID"
          boldTitle
          overrideLayout="12"
          readOnly
        />
        <CVTextView
          name="room_variant"
          title="Room Variant"
          boldTitle
          overrideLayout="12"
          readOnly
        />
        <CVTextView
          name="budget"
          title="Budget"
          boldTitle
          overrideLayout="12"
          readOnly
        />
        <CVTextView
          name="gender"
          title="Gender"
          boldTitle
          overrideLayout="12"
          readOnly
        />
        <CVTextView
          name="marital_status"
          title="Marital Status"
          boldTitle
          overrideLayout="12"
          readOnly
        />
        <CVTextView
          name="movein_date"
          title="Move in Date"
          boldTitle
          overrideLayout="12"
          readOnly
          renderer={(value: string) => {
            return utils.formatter.dateTime(value);
          }}
        />
        <CVTextView
          name="province"
          title="Province"
          boldTitle
          overrideLayout="12"
          readOnly
        />
        <CVTextView
          name="city"
          title="City"
          boldTitle
          overrideLayout="12"
          readOnly
        />
        <CVTextView
          name="district"
          title="District"
          boldTitle
          overrideLayout="12"
          readOnly
        />
        <CVTextView
          name="sub_district"
          title="Sub District"
          boldTitle
          overrideLayout="12"
          readOnly
        />
        <CVTextView
          name="remarks"
          title="Remarks"
          boldTitle
          overrideLayout="12"
          readOnly
        />
        <CVTextView
          name="lead_type"
          title="Lead Type"
          boldTitle
          overrideLayout="12"
          readOnly
        />
        <CVTextView name="unit_type" title="Unit Type" boldTitle />
        <CVTextView
          name="status"
          title="Status"
          boldTitle
          overrideLayout="12"
          readOnly
        />
        <CVTextView
          name="utm_source"
          title="UTM Source"
          boldTitle
          overrideLayout="12"
          readOnly
        />
        <CVTextView
          name="utm_medium"
          title="UTM Medium"
          boldTitle
          overrideLayout="12"
          readOnly
        />
        <CVTextView
          name="utm_campaign"
          title="UTM Campaign"
          boldTitle
          overrideLayout="12"
          readOnly
        />
        <CVTextView
          name="utm_content"
          title="UTM Content"
          boldTitle
          overrideLayout="12"
          readOnly
        />
        <CVTextView
          name="playing_video"
          title="Referral Code"
          boldTitle
          overrideLayout="12"
          readOnly
        />
        <CVTextView
          name="tenant_name"
          title="Tenant Name"
          boldTitle
          overrideLayout="12"
          readOnly
        />
        <CVTextView
          name="Room"
          title="Room"
          boldTitle
          overrideLayout="12"
          readOnly
        />
        <CVTextView
          name="room_variant"
          title="Room Variant (FK)"
          boldTitle
          overrideLayout="12"
          readOnly
        />
        <CVTextView
          name="room_type"
          title="Room Type"
          boldTitle
          overrideLayout="12"
          readOnly
        />
        <CVTextView
          name="building"
          title="Building"
          boldTitle
          overrideLayout="12"
          readOnly
        />
        <CVTextView
          name="date_created"
          title="Date Created"
          boldTitle
          overrideLayout="12"
          readOnly
          renderer={(value: string) => {
            return utils.formatter.dateTime(value);
          }}
        />
        <CVTextView
          name="date_updated"
          title="Date Updated"
          boldTitle
          overrideLayout="12"
          readOnly
          renderer={(value: string) => {
            return utils.formatter.dateTime(value);
          }}
        />
        <CVTextView
          name="is_deleted"
          title="Is Deleted"
          boldTitle
          overrideLayout="12"
          readOnly
          isSafeContent
          renderer={(value: string) => {
            if (value === "true") {
              return '<span style="color: rgb(26, 188, 156);"> ✔ </span>';
            } else {
              return '<span style="color: red;">✘</span>';
            }
          }}
        />
        <CVTextView
          name="date_deleted"
          title="Date Deleted"
          boldTitle
          overrideLayout="12"
          readOnly
          renderer={(value: string) => {
            return utils.formatter.dateTime(value);
          }}
        />
        <CVTextView
          name="deleted_by"
          title="Deleted By"
          boldTitle
          overrideLayout="12"
          readOnly
        />
      </Form>
    </Page>
  );
}

export default withRouter(InterestViewPage);
