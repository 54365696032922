import { useCallback, useEffect, useState } from "react";
import { useParams, withRouter } from "react-router-dom";

import { AsyncPaginate } from "react-select-async-paginate";

import axios from "axios";

import configs from "../../../../configs";
import httpClient from "../../../../utils/HttpClient";

import utils from "../../../../utils";
import { escapeRegExp } from "utils/generalUtils";

function CAsyncSelect(props) {
  const {
    name,
    read_only_label = null,
    prefetch = true,
    state,
    setState,
    stateComponent,
    inclusionKey = null,
    defaultValue = null,
    isUniqueOption = false,
    uniqueIdentifier,
    data,
    afterChange,
    isMulti = false,
    dependsOn = null,
    onChange = null,
    isDisabled = false,
    readOnly = false,
    itemsExtractor,
    isUseNewFilter = false,
    dependsOnPrefix = "_id",
    isClearable = true,
    onInputChange = null,
    isInputRequired = false,
    debounceTimeout = 500,
    requiredDependsOnBeforeLoad = false,
    customAdditionalParams = "",

    // isOptionValid is a function that receives an option and returns a boolean
    // It will be used to filter out options that are not valid
    isOptionValid = () => true,
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [key, setKey] = useState("");
  const [inputValueContainer, setInputValueContainer] = useState("");

  const valueFromId = (opts, selectedValue) => {
    if (
      typeof selectedValue === "object" &&
      selectedValue?.length > 0
    ) {
      const filteredResult = opts.filter((el) => {
        return selectedValue.some((obj) => {
          return obj.value === el.value;
        });
      });
      return filteredResult;
    } else {
      const result = opts.find((o) => o.value === selectedValue);

      return result;
    }
  };

  const getOptSelectedValue = useCallback(() => {
    if (
      state[name] === null ||
      state[name] === undefined ||
      isMulti
    ) {
      return state[name];
    } else {
      return valueFromId(options, state[name]);
    }
  }, [state[name], options]);

  const updateWithoutDupe = (state, selectedVal) => {
    if (state) {
      const originState = new Set(state.map((x) => x.value));

      const isNotDuplicate = selectedVal.some(
        (x) => !originState.has(x.value)
      );
      // this is remove option from multiple selected option
      if (originState.size - (selectedVal?.length ?? 0) === 1)
        return setState((prevState) => ({
          ...prevState,
          [name]: selectedVal,
        }));
      // end of scope
      if (!isNotDuplicate) return;
      setState((prevState) => ({
        ...prevState,
        [name]: selectedVal,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        [name]: selectedVal,
      }));
    }
  };

  let { id } = useParams();
  const urlParams = new URLSearchParams(data);
  const limitParam = urlParams.has("limit") ? "" : "&limit=10";

  const getParamInclusion = () => {
    let inclusionParam = "";
    if (isMulti) return inclusionParam;
    if (typeof state[name] === "number") {
      inclusionParam = `&__inclusion__=${state[name]}`;
      return inclusionParam;
    }
    if (typeof state[name] === "object" && state[name]?.length > 0) {
      let arrParam = [];
      (state[name] ?? []).forEach((element) => {
        arrParam.push(element.value);
      });

      inclusionParam = `&__inclusion__=${arrParam}`;
      return inclusionParam;
    }
    return inclusionParam;
  };

  const generateDependsOnParam = () => {
    if (!dependsOn) return "";
    if (
      utils.commons.isArray(dependsOn) &&
      utils.commons.isArray(dependsOnPrefix)
    ) {
      let queryResult = "";
      dependsOn.forEach((string, index) => {
        queryResult +=
          "&" + string + dependsOnPrefix[index] + "=" + state[string];
      });
      return queryResult;
    }
    return "&" + dependsOn + dependsOnPrefix + "=" + state[dependsOn];
  };

  const checkDependsOnState = () => {
    if (utils.commons.isString(dependsOn)) {
      return (
        state[dependsOn] !== undefined && state[dependsOn] !== null
      );
    } else if (utils.commons.isArray(dependsOn)) {
      return dependsOn.every(
        (item) => state[item] !== undefined && state[item] !== null
      );
    } else return dependsOn === null;
  };

  const loadOptions = async (value, prevOptions) => {
    let loadOptionsResult = {
      options: [],
      hasMore: false,
    };

    if (!isOpen && prefetch === false && options.length === 0) {
      return {
        options: [],
        hasMore: true,
      };
    }

    if (
      (requiredDependsOnBeforeLoad && !state[dependsOn]) ||
      (isInputRequired && !inputValueContainer)
    ) {
      return loadOptionsResult;
    }
    try {
      if (
        (value === "" || value.length >= 1) &&
        utils.commons.isString(data) &&
        (id === null ||
          id === undefined ||
          (id &&
            state["id"] !== null &&
            ((!stateComponent && state["id"] !== undefined) ||
              stateComponent)))
      ) {
        if (checkDependsOnState()) {
          let response = await axios.get(
            configs.apiUrl +
              utils.commons.stripMultipleSlashes(
                data.replace("?", "/?")
              ) +
              generateDependsOnParam() +
              getParamInclusion() +
              (inclusionKey !== null
                ? "&__inclusion_key__=" + inclusionKey
                : "") +
              "&page=" +
              (Math.ceil(prevOptions.length / 10) + 1) +
              limitParam +
              (customAdditionalParams ? customAdditionalParams : "") +
              (value !== "" ? "&keyword=" + value : ""),
            httpClient._getConfig()
          );

          let nextOptions = [];

          for (let i = 0; i < response.data.data.rows.length; i++) {
            // if state[name] is array means the select option or isMulti is true
            if (utils.commons.isArray(state[name]) && isMulti) {
              let shouldSkip = false;
              (state[name] ?? []).forEach((filter) => {
                if (response.data.data.rows[i].id === filter.value) {
                  shouldSkip = true;
                }
              });
              if (shouldSkip) {
                continue;
              }
            }
            if (itemsExtractor === undefined) {
              nextOptions[i] = {
                label: response.data.data.rows[i]["name"],
                value: response.data.data.rows[i]["id"],
              };
              nextOptions[i] = {
                ...nextOptions[i],
                ...response.data.data.rows[i],
              };
            } else {
              nextOptions[i] = itemsExtractor(
                response.data.data.rows[i],
                i
              );
            }
          }

          // nextOptions = nextOptions.filter(Boolean);

          if (isUniqueOption) {
            const selectedValues = [];
            const optionsFiltered = [];

            if (stateComponent) {
              for (let i = 0; i < stateComponent.length; i++) {
                if (
                  stateComponent[i][
                    uniqueIdentifier ? uniqueIdentifier : name
                  ]
                ) {
                  selectedValues.push(
                    stateComponent[i][
                      uniqueIdentifier ? uniqueIdentifier : name
                    ]
                  );
                }
              }
            }

            for (let i = 0; i < nextOptions.length; i++) {
              if (
                !isDisabled &&
                !selectedValues.includes(nextOptions[i].value)
              ) {
                optionsFiltered.push(nextOptions[i]);
              } else if (isDisabled) {
                optionsFiltered.push(nextOptions[i]);
              } else {
                nextOptions[i].isSelected = true;
                optionsFiltered.push(nextOptions[i]);
              }
            }

            setOptions(optionsFiltered);
            loadOptionsResult = {
              options: optionsFiltered,
              hasMore: optionsFiltered.length >= 10,
            };
          } else {
            setOptions(nextOptions);
            loadOptionsResult = {
              options: nextOptions,
              hasMore: nextOptions.length >= 10,
            };
          }
        }
      }
    } catch (e) {
      console.error(e);
    }

    // filter out options
    loadOptionsResult.options =
      loadOptionsResult.options.filter(isOptionValid);
    return loadOptionsResult;
  };

  const AsyncSelectStyles = {
    menuPortal: (provided) => {
      if (isUseNewFilter) {
        return { ...provided, zIndex: 2000 };
      }
      return { ...provided };
    },
    menuList: (provided) => {
      if (isUseNewFilter) {
        return { ...provided, maxHeight: "calc(37px * 5)" }; // shows 5 items
      }
      return { ...provided };
    },
    multiValue: (provided) => {
      if (isUseNewFilter) {
        return {
          ...provided,
          backgroundColor: "#fff",
          border: "1px solid #CCCCCC",
          borderRadius: "4px",
          maxWidth: "140px",
          margin: "0",
        };
      }
      return { ...provided };
    },
    clearIndicator: (provided) => {
      if (isUseNewFilter && isMulti) {
        return { ...provided, display: "none" };
      }
      return { ...provided };
    },
    indicatorSeparator: (provided) => {
      if (isUseNewFilter && isMulti) {
        return { ...provided, display: "none" };
      }
      return { ...provided };
    },
    valueContainer: (provided) => {
      if (isUseNewFilter) {
        if (isMulti) {
          return {
            ...provided,
            rowGap: "8px",
            columnGap: "8px",
            padding:
              state[name]?.length > 0
                ? "9px 0px 9px 12px"
                : "3px 0px 4px 12px",
          };
        }
        return {
          ...provided,
          padding:
            state[name]?.length > 0
              ? "9px 0px 9px 12px"
              : "3px 0px 4px 12px",
        };
      }
      return { ...provided };
    },
    input: (provided) => {
      if (isUseNewFilter) {
        return {
          ...provided,
          gridArea: isMulti ? "unset" : provided.gridArea,
        };
      }
      return { ...provided };
    },
    option: (provided, state) => {
      if (isUseNewFilter) {
        if (state.isSelected) {
          return {
            ...provided,
            backgroundColor: "#fff",
            fontWeight: "500",
            color: "#222",
          };
        }
        if (state.isFocused) {
          return {
            ...provided,
            backgroundColor: "#D9EFE5",
            fontWeight: "500",
            color: "#35B0A7",
          };
        }
      }
      return { ...provided };
    },
    loadingIndicator: (provided) => {
      if (isUseNewFilter && isMulti) {
        return { ...provided, display: "none" };
      }
      return { ...provided };
    },
    control: (provided) => {
      if (isUseNewFilter) {
        return { ...provided, minHeight: "36px" };
      }
      return { ...provided };
    },
    multiValueRemove: (provided, state) => {
      if (isUseNewFilter) {
        return { ...provided, color: "#999999" };
      }
      return { ...provided };
    },
  };

  useEffect(() => {
    setKey((Math.random() + 1).toString(36).substring(7));
    if (!isMulti) {
      if (utils.commons.isFunction(setState)) {
        setState((prevState) => ({
          ...prevState,
          [name + "_object"]: valueFromId(options, state[name]),
        }));
      }
    }
  }, [state[name]]);

  useEffect(() => {
    if (
      utils.commons.isString(dependsOn) &&
      utils.commons.isString(data)
    ) {
      if (
        state[dependsOn] !== undefined &&
        state[dependsOn] !== null
      ) {
        setState((prevState) => ({
          ...prevState,
          [name]:
            state["original___" + dependsOn] === state[dependsOn] &&
            state["original___" + name] === state[name]
              ? state["original___" + name]
              : null,
        }));
      } else {
        if (state[dependsOn] === null) {
          setState((prevState) => ({ ...prevState, [name]: null }));
        }
      }
      setKey((Math.random() + 1).toString(36).substring(7));
    }
  }, [state[dependsOn], data]);

  useEffect(() => {
    if (
      utils.commons.isArray(dependsOn) &&
      utils.commons.isString(data)
    ) {
      if (
        dependsOn.every(
          (item) => state[item] !== undefined && state[item] !== null
        )
      ) {
        setKey((Math.random() + 1).toString(36).substring(7));
      }
    }
  }, [state]);

  const filterOption = (option, inputValue) =>
    (
      option.label
        .toString()
        .toLowerCase()
        .match(escapeRegExp(inputValue.toLowerCase())) || []
    ).length > 0;

  return readOnly && read_only_label !== null ? (
    <div style={{ paddingTop: "7px" }}>{state[read_only_label]}</div>
  ) : (
    <AsyncPaginate
      styles={AsyncSelectStyles} // this is needed if react-select is used on modal component
      // menuIsOpen={true}
      debounceTimeout={debounceTimeout}
      hideSelectedOptions
      name={name}
      key={key}
      cacheOptions
      defaultOptions
      onInputChange={
        onInputChange
          ? onInputChange
          : (newValue) => {
              setInputValueContainer(newValue);
            }
      }
      loadOptions={loadOptions}
      placeholder={defaultValue}
      isClearable={isClearable}
      closeMenuOnSelect
      isMulti={isMulti}
      menuPortalTarget={document.body}
      isDisabled={
        isDisabled ||
        readOnly ||
        (requiredDependsOnBeforeLoad && !state[dependsOn])
      }
      isOptionDisabled={(option) => option.disabled}
      isOptionSelected={(option) => option.isSelected}
      value={getOptSelectedValue()}
      filterOption={filterOption}
      onMenuOpen={() => {
        setIsOpen(true);
      }}
      onMenuClose={() => {
        setIsOpen(false);
      }}
      onChange={
        onChange
          ? onChange
          : (data) => {
              if (data) {
                if (utils.commons.isArray(data)) {
                  updateWithoutDupe(state[name], data);
                } else {
                  setState((prevState) => ({
                    ...prevState,
                    [name + "_label"]: data.label,
                    [name + "_object"]: data,
                    [name]: data.value,
                  }));
                }
              } else {
                setState((prevState) => ({
                  ...prevState,
                  [name + "_label"]: null,
                  [name + "_object"]: null,
                  [name]: null,
                }));
              }

              setTimeout(() => {
                if (utils.commons.isFunction(afterChange)) {
                  afterChange(data);
                }
              }, 250);
            }
      }
    />
  );
}

export default withRouter(CAsyncSelect);
