import { withRouter } from "react-router-dom";

import TabPane from "../../../../../../components/ubold/organisms/TabPane";
import TabPaneSection from "../../../../../../components/ubold/organisms/TabPaneSection";
import CHTextField from "../../../../../../components/ubold/molecules/forms/CHTextField";
import CHSelect from "../../../../../../components/ubold/molecules/forms/CHSelect";
import CHTextArea from "../../../../../../components/ubold/molecules/forms/CHTextArea";
import CHRichTextField from "../../../../../../components/ubold/molecules/forms/CHRichTextField";
import CHDateTimePicker from "../../../../../../components/ubold/molecules/forms/CHDateTimePicker";
import CHCheckBox from "../../../../../../components/ubold/molecules/forms/CHCheckBox";
import CHTextView from "../../../../../../components/ubold/molecules/forms/CHTextView";

import utils from "../../../../../../utils";
import CHImageView from "components/ubold/molecules/forms/CHImageView";

function FirstTab(props: any) {
  const { id, definitions, tab, state, setState } = props;

  return (
    <TabPane
      id={id}
      definitions={definitions}
      tab={tab}
      readOnly
      state={state}
      setState={setState}
    >
      <TabPaneSection style={{ padding: "25px 16px 0px" }}>
        <CHTextField
          name="event_name"
          title="Name"
          maxLength={80}
          isRequired
          readOnly
        />
        <CHSelect
          name="event_type"
          title="Event Type"
          isRequired
          data={[
            {
              label: "Internal",
              value: 1,
            },
            {
              label: "External",
              value: 2,
            },
          ]}
          readOnly
        />
        <CHTextField
          name="link_external"
          title="Link External"
          maxLength={300}
          readOnly
        />
        <CHTextField
          name="host"
          title="Host"
          maxLength={160}
          isRequired
          readOnly
        />
        <CHImageView name="host_pic_original" title="Host Pic" />
        <CHTextField
          name="slug"
          title="Slug"
          maxLength={100}
          readOnly
        />
        <CHTextField
          name="title_tag"
          title="Title Tag"
          maxLength={160}
          readOnly
        />
        <CHTextArea
          name="meta_description"
          title="Meta Description"
          readOnly
        />
        <CHRichTextField name="desc" title="Description" readOnly />
        <CHImageView name="photo_original" title="Photo" isRequired />
        <CHTextField
          name="address"
          title="Address"
          maxLength={120}
          readOnly
        />
        <CHTextField
          name="location"
          title="Location"
          maxLength={120}
          readOnly
        />
        <CHTextField
          name="lon"
          title="Longitude"
          type="number"
          readOnly
        />
        <CHTextField
          name="lat"
          title="Latitude"
          type="number"
          readOnly
        />
        <CHDateTimePicker
          name="date_started"
          title="Date Started"
          type="date"
          isRequired
          readOnly
        />
        <CHDateTimePicker
          name="date_ended"
          title="Date Ended"
          type="date"
          isRequired
          readOnly
        />
        <CHDateTimePicker
          name="time_started"
          title="Time Started"
          type="time"
          isRequired
          readOnly
        />
        <CHDateTimePicker
          name="time_ended"
          title="Time Ended"
          type="time"
          readOnly
        />
        <CHCheckBox name="published" title="Published" readOnly />
        <CHCheckBox name="cancelled" title="Cancelled" readOnly />
        <CHTextField
          name="url"
          title="Registration link"
          maxLength={250}
          readOnly
        />
        <CHDateTimePicker
          name="latest_cancellation_date"
          title="Latest Cancellation Date"
          isRequired
          readOnly
        />
        <CHDateTimePicker
          name="latest_registration_date"
          title="Latest Registration Date"
          isRequired
          readOnly
        />
        <CHTextField
          name="quota"
          title="Quota"
          type="number"
          readOnly
        />
        <CHSelect
          name="event_mode"
          title="Event Mode"
          data={[
            {
              label: "Offline",
              value: 0,
            },
            {
              label: "Online",
              value: 1,
            },
          ]}
          readOnly
        />
        <CHTextField
          name="link"
          title="Event Link"
          maxLength={250}
          readOnly
        />
        <CHDateTimePicker
          name="link_publish_date"
          title="link Publish Date"
          readOnly
        />
        <CHTextView
          name="date_created"
          title="Date Created"
          readOnly
          renderer={(value: string) => {
            return utils.formatter.dateTime(value);
          }}
        />
        <CHTextView
          name="date_updated"
          title="Date Updated"
          readOnly
          renderer={(value: string) => {
            return utils.formatter.dateTime(value);
          }}
        />
      </TabPaneSection>
    </TabPane>
  );
}

export default withRouter(FirstTab);
