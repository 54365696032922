import { withRouter } from "react-router-dom";

import AccordionPane from "../../../../../../../components/ubold/organisms/AccordionPane";
import CHSelect from "components/ubold/molecules/forms/CHSelect";
import CHTextField from "components/ubold/molecules/forms/CHTextField";
import CHCheckBox from "components/ubold/molecules/forms/CHCheckBox";
import CHTextView from "components/ubold/molecules/forms/CHTextView";
import utils from "utils";
import useGlobalState from "state";

function OtherInfo(props) {
  const { baseLink, accordionId, onSubmit, state, setState } = props;
  const [constants] = useGlobalState("constant_values");

  return (
    <AccordionPane
      id="otherInfo"
      name="Other Info"
      accordionId={accordionId}
      baseLink={baseLink}
      onSubmit={onSubmit}
      state={state}
      setState={setState}
    >
      <CHTextField
        name="quota_laundry"
        title="Laundry Quota"
        type="number"
      />
      <CHTextField
        name="tenant_deposit"
        title="Tenant Deposit"
        type="number"
      />
      <CHCheckBox name="is_dummy" title="Dummy Account" />
      <CHSelect
        name="verified_email"
        title="Email Verified"
        data={[
          { label: "Yes", value: true },
          { label: "No", value: false },
        ]}
      />
      <CHSelect
        name="verified_phone"
        title="Phone Number Verified"
        data={[
          { label: "Yes", value: true },
          { label: "No", value: false },
        ]}
      />
      <CHCheckBox name="first_login" title="First Login" readOnly />
      <CHCheckBox name="is_active" title="Is Active" />

      <CHCheckBox
        name="on_leave_free_of_charge"
        title="On Leave Free of Charge"
      />
      <CHSelect
        name="source_register"
        title="Source of Registration"
        data={constants?.["user_tenant_source_register"]}
        readOnly
      />
      <CHTextView
        name="date_created"
        title="Date Joined/Created"
        renderer={(value) => {
          return utils.formatter.dateTime(value);
        }}
      />
      <CHTextView
        name="date_updated"
        title="Date Updated"
        renderer={(value) => {
          return utils.formatter.dateTime(value);
        }}
      />
      <CHTextView
        name="last_login"
        title="Last Login"
        renderer={(value) => {
          return utils.formatter.dateTime(value);
        }}
      />
    </AccordionPane>
  );
}

export default withRouter(OtherInfo);
