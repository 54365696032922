import configBuilder from "../../../../components/ubold/ConfigBuilder";

import pageDefinitions from "../definitions";

const definitions = {
  ...pageDefinitions,
};

export default configBuilder.buildAddEditPageConfig(definitions, [
  "mobile_screen_original",
  "tablet_screen_original",
  "desktop_screen_original",
]);
