import styled from "styled-components/macro";

const TableComponentContainer = styled.div`
  overflow-y: scroll;
  max-height: 85vh;
  width: auto;
  display: flex;
  flex-grow: 1;
  z-index: 1 !important;

  .table {
    table-layout: fixed;
    white-space: nowrap;
    width: auto;
    flex-grow: 1;

    &:first-child {
      background-color: #fff;
    }

    td {
      padding: 12px 8px;
      border-left: 1px solid #e7e7e7 !important;
      border-right: 1px solid #e7e7e7 !important;

      a {
        &.link-rukita {
          font-weight: 500;
        }
      }
    }

    .thead-light {
      th {
        position: sticky;
        top: 0;
        background-color: #d9efe5;
        color: #222222;
        font-weight: 500;
        padding: 12px 8px;
        box-shadow: inset 0 -1px 1px #00bbb4;
      }
    }
  }
`;
export default TableComponentContainer;

export const TitleHeadStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;
