import { withRouter } from "react-router-dom";

import Page from "../../../../../components/ubold/organisms/Page";
// import TableView from "../../../../../components/ubold/organisms/TableView";

import TableViewColFreeze from "../../../../../components/ubold/organisms/TableViewColFreeze/TableViewColFreeze";

import definitions from "./definitions";
import PageRoomCC from "./Page.css";

function CPage() {
  return (
    <PageRoomCC>
      <Page title={definitions.title}>
        <TableViewColFreeze {...definitions.table} />
      </Page>
    </PageRoomCC>
  );
}

export default withRouter(CPage);
