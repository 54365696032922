import configBuilder from "../../../components/ubold/ConfigBuilder";
import utils from "../../../utils";

let definitions = {
  title: "Landlord Lead Buildings",
  frontendPath: "/lead/landlord/building",
  baseLink: "/user/landlord_lead_building",
  restAccessCode: "user.landlord_lead_building",
  __table__: {
    filterColumn: 4,
    hideEditButton: true,
    removeIdentifier: "landlord_lead_name",
    filters: [
      {
        id: "landlord_lead",
        name: "Landlord",
        type: "async_select",
        data: "user/landlord_lead?__type__=select_entries",
      },
      {
        id: "building_name",
        name: "Building Name",
        type: "text",
      },
      {
        id: "building_type",
        name: "Building Type",
        type: "text",
      },
      {
        id: "need_response",
        name: "Need Response",
        type: "select",
        data: [
          {
            label: "Yes",
            value: true,
          },
          {
            label: "No",
            value: false,
          },
        ],
      },
      {
        id: "qualified",
        name: "Qualified",
        type: "select",
        data: [
          {
            label: "Yes",
            value: true,
          },
          {
            label: "No",
            value: false,
          },
        ],
      },
      {
        id: "date_created",
        name: "Date Created",
        type: "date",
      },
      {
        id: "date_updated",
        name: "Date Updated",
        type: "date",
      },
    ],
    columns: [
      {
        id: "landlord_lead_name",
        name: "Landlord Name",
        enableHyperlink: true,
      },
      {
        id: "building_name",
        name: "Building Name",
      },
      {
        id: "building_type",
        name: "Building Type",
        center: true,
      },
      {
        id: "need_response",
        name: "Need Response",
        isSafeContent: true,
        center: true,
        renderer: (value) => {
          if (value === "true") {
            return '<span style="color: rgb(26, 188, 156);"> ✔ </span>';
          } else {
            return '<span style="color: red;">✘</span>';
          }
        },
      },
      {
        id: "qualified",
        name: "Qualified",
        isSafeContent: true,
        center: true,
        renderer: (value) => {
          if (value === "true") {
            return '<span style="color: rgb(26, 188, 156);"> ✔ </span>';
          } else {
            return '<span style="color: red;">✘</span>';
          }
        },
      },
      {
        id: "date_created",
        name: "Date Created",
        renderer: (value) => {
          return utils.formatter.dateTime(value);
        },
      },
      {
        id: "date_updated",
        name: "Date Updated",
        renderer: (value) => {
          return utils.formatter.dateTime(value);
        },
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
