import {withRouter} from "react-router-dom";

import TabPane from "../../../../../../components/ubold/organisms/TabPane";
import TabPaneSection from "../../../../../../components/ubold/organisms/TabPaneSection";
import CHSelect from "../../../../../../components/ubold/molecules/forms/CHSelect";
import CHTextField from "../../../../../../components/ubold/molecules/forms/CHTextField";

function Facility(props) {
    const {id, definitions, tab, state, setState} = props;

    return (
        <TabPane
            id={id}
            definitions={definitions}
            tab={tab}
            state={state}
            setState={setState}>
            <TabPaneSection style={{padding: '25px 16px 0px'}}>
                <CHTextField
                    name='name'
                    title='Name'
                    maxLength={100}
                    isRequired
                />
                <CHTextField
                    name='name_en'
                    title='Name EN'
                    maxLength={100}
                />
                <CHTextField
                    name='priority'
                    title='Priority'
                    type='number'
                />
                <CHSelect
                    name='category'
                    title='Category'
                    data={[
                        {
                            label: 'General Facility',
                            value: 1
                        },
                        {
                            label: 'Sharing Area',
                            value: 2
                        },
                        {
                            label: 'Room Facility',
                            value: 4
                        },
                        {
                            label: 'Room Features',
                            value: 5
                        },
                    ]}
                />
            </TabPaneSection>
        </TabPane>
    );
}

export default withRouter(Facility);