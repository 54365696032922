import React from "react";

import { withRouter } from "react-router-dom";

import utils from "../../../utils";

function TabPaneSection(props) {
  const {
    children,
    cancelLink,
    tab,
    restAccessCode,
    accordionId,
    multipleColumn = false,
    state,
    setState,
  } = props;

  let clonedChildren = [];
  if (utils.commons.isArray(children)) {
    for (let i = 0; i < children.length; i++) {
      clonedChildren[i] = React.cloneElement(children[i], {
        key: i,
        state: state,
        setState: setState,
        restAccessCode: restAccessCode,
        cancelLink: cancelLink,
        accordionId: accordionId,
        tab: tab,
      });
    }
  } else if (children) {
    clonedChildren[0] = React.cloneElement(children, {
      key: "0",
      state: state,
      setState: setState,
      restAccessCode: restAccessCode,
      cancelLink: cancelLink,
      accordionId: accordionId,
      tab: tab,
    });
  }

  return (
    <div
      style={{ ...props.style, width: "100%" }}
      id="tabPaneSectElement"
    >
      <div className="col-12">
        {multipleColumn ? (
          <div className="row">
            {accordionId !== null ? (
              <div
                className="accordion custom-accordion"
                id={accordionId}
              >
                {clonedChildren}
              </div>
            ) : (
              clonedChildren
            )}
          </div>
        ) : accordionId !== null ? (
          <div
            className="accordion custom-accordion"
            id={accordionId}
          >
            {clonedChildren}
          </div>
        ) : (
          clonedChildren
        )}
      </div>
    </div>
  );
}

export default withRouter(TabPaneSection);
