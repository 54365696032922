import { withRouter } from "react-router-dom";

import AccordionPane from "../../../../../../../components/ubold/organisms/AccordionPane";

import CHRadio from "../../../../../../../components/ubold/molecules/forms/CHRadio";
import CHSelect from "../../../../../../../components/ubold/molecules/forms/CHSelect";
import CHRichTextField from "../../../../../../../components/ubold/molecules/forms/CHRichTextField";
import CHTextView from "../../../../../../../components/ubold/molecules/forms/CHTextView";
import CHMultiCheckbox from "../../../../../../../components/ubold/molecules/forms/CHMultiCheckbox";
import { sectionProps } from "@types";
import CHCheckBox from "components/ubold/molecules/forms/CHCheckBox";

function GeneralServices(props: sectionProps) {
  const { baseLink, accordionId, onSubmit, state, setState } = props;

  const generalServiceOptions = [
    {
      value: 1,
      label: "None",
    },
    {
      value: 2,
      label: "In House",
    },
    {
      value: 3,
      label: "Vendor",
    },
  ];

  return (
    <AccordionPane
      id="generalServices"
      name="General Services"
      accordionId={accordionId}
      baseLink={baseLink}
      onSubmit={onSubmit}
      state={state}
      setState={setState}
    >
      <CHRadio
        readOnly
        name="internet_status"
        title="Status of Internet"
        data={[
          {
            label: "Connected",
            value: 1,
          },
          {
            label: "Pending Application",
            value: 2,
          },
          {
            label: "Pending Installation",
            value: 3,
          },
        ]}
      />
      <CHTextView
        readOnly
        name="internet_speed"
        title="Internet Speed"
        renderer={(value: string) => {
          return (value ? value : "None") + " MB/s";
        }}
      />
      <CHSelect
        readOnly
        name="laundry"
        title="Laundry"
        data={generalServiceOptions}
      />
      <CHTextView
        readOnly
        name="laundry_quota"
        title="Laundry Quota"
      />
      <CHMultiCheckbox
        readOnly
        disabled
        name="laundry_schedule"
        title="Laundry Schedule"
        data={[
          {
            label: "Senin",
            value: "1",
          },
          {
            label: "Selasa",
            value: "2",
          },
          {
            label: "Rabu",
            value: "3",
          },
          {
            label: "Kamis",
            value: "4",
          },
          {
            label: "Jumat",
            value: "5",
          },
          {
            label: "Sabtu",
            value: "6",
          },
          {
            label: "Minggu",
            value: "7",
          },
        ]}
        onChange={(values: Object) => {
          setState((prevState: Object) => ({
            ...prevState,
            laundry_schedule: values,
          }));
        }}
      />
      <CHCheckBox
        name="apply_laundry_to_all_room"
        title="Apply Laundry Schedule to All Room"
        readOnly
      />
      <CHRichTextField
        readOnly
        name="laundry_remark"
        title="Laundry Remark"
      />
      <CHSelect
        readOnly
        name="room_cleaning"
        title="Room Cleaning"
        data={generalServiceOptions}
      />
      <CHTextView
        readOnly
        name="room_cleaning_quota"
        title="Room Cleaning Quota"
      />
      <CHMultiCheckbox
        readOnly
        disabled
        name="cleaning_schedule"
        title="Cleaning Schedule"
        data={[
          {
            label: "Senin",
            value: "1",
          },
          {
            label: "Selasa",
            value: "2",
          },
          {
            label: "Rabu",
            value: "3",
          },
          {
            label: "Kamis",
            value: "4",
          },
          {
            label: "Jumat",
            value: "5",
          },
          {
            label: "Sabtu",
            value: "6",
          },
          {
            label: "Minggu",
            value: "7",
          },
        ]}
        onChange={(values: Object) => {
          setState((prevState: Object) => ({
            ...prevState,
            cleaning_schedule: values,
          }));
        }}
      />
      <CHCheckBox
        name="apply_cleaning_to_all_room"
        title="Apply Cleaning Schedule to All Room"
        readOnly
      />
      <CHRichTextField
        readOnly
        name="room_cleaning_remark"
        title="Room Cleaning Remark"
      />
      <CHSelect
        readOnly
        name="parking_area"
        title="Parking Area"
        data={generalServiceOptions}
      />
      <CHRichTextField
        readOnly
        name="parking_area_remark"
        title="Parking Area Remark"
      />
      <CHSelect
        readOnly
        name="security"
        title="Security"
        data={generalServiceOptions}
      />
      <CHRichTextField
        readOnly
        name="security_remark"
        title="Security Remark"
      />
      <CHTextView
        readOnly
        name="sla"
        title="SLA Room Maintenance"
        renderer={(value: string) => {
          return (value ? value : "0") + " days";
        }}
      />
    </AccordionPane>
  );
}

export default withRouter(GeneralServices);
