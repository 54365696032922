import { useState } from "react";
import { useParams, withRouter } from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import Form from "../../../../components/ubold/organisms/Form";

import CVTextField from "../../../../components/ubold/molecules/forms/CVTextField";
import CVSelect from "../../../../components/ubold/molecules/forms/CVSelect";
import CVAsyncSelect from "../../../../components/ubold/molecules/forms/CVAsyncSelect";
import CVImageUpload from "../../../../components/ubold/molecules/forms/CVImageUpload";

import definitions from "./definitions";

function DiscountAddEditPage() {
  let { id } = useParams();

  const initialState = {
    status: 0,
  };

  const [state, setState] = useState(initialState);

  return (
    <Page
      title={
        (id === undefined ? "Add New" : "Edit") +
        " " +
        definitions.title
      }
      restAccessCode={definitions.restAccessCode}
    >
      <Form
        id={id}
        definitions={definitions}
        state={state}
        setState={setState}
      >
        <CVTextField
          title="Name"
          name="name"
          maxLength={100}
          isRequired
        />
        <CVTextField
          title="Code"
          name="code"
          maxLength={100}
          isRequired
        />
        <CVSelect
          title="Status"
          name="status"
          data={[
            {
              label: "PUBLISH",
              value: 1,
            },
            {
              label: "UNPUBLISH",
              value: 0,
            },
          ]}
        />
        <CVTextField
          title="Sequence"
          name="sequence"
          type="number"
          min={1}
        />
        <CVAsyncSelect
          name="category_payment"
          title="Category Payment"
          data="payment_gateway/category_payment?__type__=select_entries"
          itemExtractor={(row) => {
            return {
              label: row.name,
              value: row.id,
            };
          }}
        />
        <CVImageUpload name="image_original" title="Image" />
      </Form>
    </Page>
  );
}

export default withRouter(DiscountAddEditPage);
