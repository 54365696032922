import {useParams, withRouter} from "react-router-dom";

import TabPaneSecondary from "../../../../../../components/ubold/organisms/TabPaneSecondary";

import COneOrManyToManyField from "../../../../../../components/ubold/molecules/forms/COneOrManyToManyField";

function Building(props) {
    const {definitions, tab, state, setState} = props;

    let {id} = useParams();

    return (
        <TabPaneSecondary
            definitions={definitions}
            restAccessCodeNamespace='building'
            tab={tab}
            state={state}
            setState={setState}>
            <COneOrManyToManyField
                id={id}
                name={tab.id}
                readOnly
                restPath={'building/building?building_grade_id=' + id}
                relationKey='building_grade_id'
                columns={[
                    {
                        name: 'building_name',
                        title: 'Building',
                        type: 'read_only'
                    }
                ]}
            />
        </TabPaneSecondary>
    );
}

export default withRouter(Building);