import { withRouter } from "react-router-dom";

import AccordionPane from "../../../../../../../components/ubold/organisms/AccordionPane";

import CHTextView from "../../../../../../../components/ubold/molecules/forms/CHTextView";

import utils from "../../../../../../../utils";

function OtherInfos(props: any) {
  const { baseLink, accordionId, onSubmit, state, setState } = props;

  return (
    <AccordionPane
      id="information"
      name="Information"
      accordionId={accordionId}
      baseLink={baseLink}
      onSubmit={onSubmit}
      state={state}
      setState={setState}
    >
      <CHTextView
        name="date_created"
        title="Date Created"
        renderer={(value: string) => {
          return utils.formatter.dateTime(value);
        }}
        readOnly
      />
      <CHTextView
        name="date_updated"
        title="Date Updated"
        renderer={(value: string) => {
          return utils.formatter.dateTime(value);
        }}
        readOnly
      />
      <CHTextView name="created_by" title="Created By" readOnly />
    </AccordionPane>
  );
}

export default withRouter(OtherInfos);
