import React from "react";
import { withRouter } from "react-router-dom";

import CTextField from "../../atoms/forms/CTextField";

import useGlobalState from "../../../../state";

import utils from "../../../../utils";

function CHTextField(props) {
  const {
    children,
    name,
    title,
    type = "text",
    isRequired = false,
    autoComplete = "off",
    restAccessCode,
    maxLength = null,
    readOnly,
    state,
    setState,
    helperText = "",
    validate,
    inputGroups = null,
    min,
    defaultValue = null,
    useCurrencyFormatHelper = false,
  } = props;

  const [user] = useGlobalState("user");
  const isSuperUser = user ? user.is_super_user : false;
  const [fieldAccesses] = useGlobalState("field_accesses");
  const [fieldAccessReversePolicy] = useGlobalState(
    "field_access_reverse_policy"
  );

  const input = (
    <CTextField
      name={name}
      type={type}
      readOnly={
        readOnly ??
        (restAccessCode &&
          utils.access.isFieldReadOnly(
            fieldAccesses,
            restAccessCode + "." + name,
            fieldAccessReversePolicy,
            isSuperUser
          ))
      }
      state={state}
      autoComplete={autoComplete}
      maxLength={maxLength}
      setState={setState}
      validate={validate}
      min={min}
      defaultValue={defaultValue}
    />
  );

  return (
    <div
      className="form-group row mb-3"
      style={{
        display:
          !isRequired &&
          restAccessCode &&
          utils.access.isFieldHidden(
            fieldAccesses,
            restAccessCode + "." + name
          )
            ? "none"
            : "flex",
      }}
    >
      <label
        htmlFor="name"
        className={
          "col-md-3 col-form-label" +
          (isRequired ? " font-weight-bold" : "") +
          (state[name + "Error"] ? " text-danger" : "")
        }
      >
        {title}
        {isRequired ? "*" : ""}{" "}
        {readOnly ??
        utils.access.isFieldReadOnly(
          fieldAccesses,
          restAccessCode + "." + name,
          fieldAccessReversePolicy,
          isSuperUser
        )
          ? "(read only)"
          : ""}
      </label>
      <div className={children ? "col-md-6" : "col-md-9"}>
        {inputGroups !== null ? (
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">{inputGroups}</span>
            </div>
            {input}
          </div>
        ) : (
          input
        )}
        {helperText ? (
          <span
            className="text-muted font-13 pt-1 mb-0"
            dangerouslySetInnerHTML={{ __html: helperText }}
          />
        ) : (
          ""
        )}
        {helperText && state[name + "Error"] ? <br /> : <></>}
        {state[name + "Error"] ? (
          <span className="text-danger" style={{ fontSize: "12px" }}>
            {state[name + "Error"]}
          </span>
        ) : (
          <></>
        )}
        {useCurrencyFormatHelper ? (
          <span
            className="text-muted font-13 pt-1 mb-0"
            dangerouslySetInnerHTML={{
              __html: utils.formatter.currency(state[name] ?? 0),
            }}
          />
        ) : (
          <></>
        )}
      </div>

      {children ? (
        <React.Fragment>
          <div className="col-md-3">{children}</div>
        </React.Fragment>
      ) : (
        <React.Fragment />
      )}
    </div>
  );
}

export default withRouter(CHTextField);
