import configBuilder from "components/ubold/ConfigBuilder";

import pageDefinitions from "../definitions";
import utils from "utils";

const definitions = {
  ...pageDefinitions,
  linkHistory: (id: string) =>
    `${definitions.frontendPath}/history/${id}`,
  validators: {
    name: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    start_date: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    end_date: (value: string, state: any) => {
      if (value !== null && value < state.start_date) {
        return "End Date must be greater than Start date";
      } else {
        return utils.validator.validateRequired(value);
      }
    },
    category: (value: number) => {
      return utils.validator.validateRequired(value);
    },
    percentage: (value: number, state: { one_time: boolean }) => {
      if ((value <= 0 || value > 100) && !state.one_time) {
        return "Percentage should be between 1 and 100";
      }
      return utils.validator.validateRequired(value);
    },
    maximum_amount: (value: number) => {
      return utils.validator.validateRequired(value);
    },
    tenant_category: (value: number) => {
      return utils.validator.validateRequired(value);
    },
    tenant_type: (value: number) => {
      return utils.validator.validateRequired(value);
    },
    building_eligibility: (value: number[], state: any) => {
      if (!state["all_building"] && value?.length === 0) {
        return "This field is required";
      } else {
        return true;
      }
    },
    budget_period: (value: number) => {
      return utils.validator.validateRequired(value);
    },
    minimum_monthly_commitment: (
      value: number,
      state: { show: boolean }
    ) => {
      if (state.show && value > 1) {
        return "Inputted value cannot greater than 1";
      }
      return true;
    },
    show: (value: boolean, state: { category: number }) => {
      if (state.category === 2 && value) {
        return "Only Discount category that can be shown on Website/App ";
      }
      return true;
    },
    adjustment_remarks: (
      value: string,
      state: { id: string | undefined; adjustment_order: boolean }
    ) => {
      if (state.id || !state.adjustment_order) return true;
      return utils.validator.validateRequired(value);
    },
    voucher_name: (value: string, state: { category: number }) => {
      if (state?.category >= 3) {
        return utils.validator.validateRequired(value);
      }
      return true;
    },
    voucher_code: (value: string, state: { category: number }) => {
      if (state?.category >= 3) {
        return utils.validator.validateRequired(value);
      }
      return true;
    },
    voucher_tnc_url: (value: string, state: { category: number }) => {
      if (state?.category >= 3) {
        return utils.validator.validateRequired(value);
      }
      return true;
    },
    sort_priority: (value: string, state: { category: number }) => {
      if (state?.category >= 3) {
        return utils.validator.validateRequired(value);
      }
      return true;
    },
    is_visible: (value: string, state: { category: number }) => {
      return true;
    },
    voucher_duration_day: (
      value: string,
      state: { category: number }
    ) => {
      if (state?.category >= 3) {
        return utils.validator.validateRequired(value);
      }
      return true;
    },
  },
};

export default configBuilder.buildAddEditPageConfig(definitions);
