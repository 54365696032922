import "./App.css";
import { Switch, Route, BrowserRouter } from "react-router-dom";

import ContextProviderWrapper from "components/global/ContextProviderWrapper";
import WithRefreshToken from "components/global/WithRefreshToken";

import routes from "routes";

function App() {
  return (
    <WithRefreshToken>
      <ContextProviderWrapper>
        <BrowserRouter>
          <Switch>
            {routes.map((route) => (
              <Route key={route.path} path={route.path} exact>
                <route.component />
              </Route>
            ))}
          </Switch>
        </BrowserRouter>
      </ContextProviderWrapper>
    </WithRefreshToken>
  );
}

export default App;

// REFACTOR NOTES
//
// BELOW IS LIST OF ROUTES THAT UNUSED IN THE DASHBOARD
// PLEASE CHECK IT OUT AND REMOVE IT IF IT IS NOT NEEDED
// ----------------------------------------------------------------
// import PlacePage from "./pages/Building/Place";
// import PlaceAddEditPage from "./pages/Building/Place/AddEdit";

// import PaymentPage from "./pages/Finance/Payment";
// import PaymentAddEditPage from "./pages/Finance/Payment/AddEdit";

// import HistoryPage from "./pages/History/History";
// import HistoryAddEditPage from "./pages/History/History/AddEdit";
// import ModelListPage from "./pages/History/ModelList";
// import ModelListAddEditPage from "./pages/History/ModelList/AddEdit";

// import UserDiscountPage from "./pages/User/Discount";
// import UserDiscountAddEditPage from "./pages/User/Discount/AddEdit";

// <Route
//   exact
//   path="/u/dashboard/sales/room_allotment"
//   component="{SalesRoomAllotmentPage}"
// />

// <Route exact path="/website/place/place" component="{PlacePage}" />
// <Route
//   exact
//   path="/website/place/place/(add|edit)/:id([0-9]+)?"
//   component="{PlaceAddEditPage}"
// />

// <Route exact path="/history/history" component="{HistoryPage}" />
// <Route
//   exact
//   path="/history/history/(add|edit)/:id([0-9]+)?"
//   component="{HistoryAddEditPage}"
// />
// <Route exact path="/history/model_list" component="{ModelListPage}" />
// <Route
//   exact
//   path="/history/model_list/(add|edit)/:id([0-9]+)?"
//   component="{ModelListAddEditPage}"
// />

// <Route exact path="/finance/payment" component="{PaymentPage}" />
// <Route
//   exact
//   path="/finance/payment/(add|edit)/:id([0-9]+)?"
//   component="{PaymentAddEditPage}"
// />

// <Route
//   exact
//   path="/tenant/tenant/history/:id([0-9]+)?"
//   component="{UserTenantHistory}"
// />

// <Route exact path="/user/discount" component="{UserDiscountPage}" />
// <Route
//   exact
//   path="/user/discount/(add|edit)/:id([0-9]+)?"
//   component="{UserDiscountAddEditPage}"
// />
