import utils from "../../../utils";

import configBuilder from "../../../components/ubold/ConfigBuilder";

import secondaryDataHandler from "./SecondaryDataHandler";

let definitions = {
  title: "Orders SOP",
  frontendPath: "/sales/order_sop",
  baseLink: "/order_new/order",
  restAccessCode: "order_new.order",
  __table__: {
    filterColumn: 3,
    removeIdentifier: "number",
    hasSecondaryData: true,
    secondaryDataRow: 2,
    secondaryDataRowHeight: "auto",
    secondaryDataItem: 13,
    selectedInitialRowsPerPage: 4,
    secondaryDataHandler: secondaryDataHandler,
    hideEditButton: true,
    isHideRemoveButton: true,
    filters: [
      {
        id: "order",
        name: "Order Id",
        type: "async_select",
        data: "order_new/order?__type__=select_entries",
        itemsExtractor: (item) => {
          return {
            label: item.number,
            value: item.id,
          };
        },
      },
      {
        id: "tenant",
        name: "Tenant Name & Email",
        type: "async_select",
        data: "user/tenant?__type__=select_entries",
        itemsExtractor: (item) => {
          const label = `${item.name} - ${item.email}`;
          return {
            label,
            value: item.id,
          };
        },
      },
      {
        id: "order_status",
        name: "Order Status",
        type: "select",
        data: {
          optionField: "order_new_statuses",
        },
      },
      {
        id: "subscription_type",
        name: "Subscription Type",
        type: "select",
        data: {
          optionField: "order_new_subscription_type",
        },
      },
      {
        id: "building",
        name: "Building Name",
        type: "async_select",
        data: "building/building?__type__=select_entries&is_sop_filter=1",
        itemsExtractor: (item) => {
          return {
            label: item.building_name,
            value: item.id,
          };
        },
      },
      {
        id: "room_number",
        name: "Room Number",
        type: "async_select",
        data: "building/room?__type__=select_entries",
        dependsOnPrefix: "",
        dependsOn: "building",
        requiredDependsOnBeforeLoad: true,
        helperText:
          "Please select building name to enable this filter",
      },
      {
        id: "status_of_done",
        name: "Status of Done",
        type: "select",
        data: {
          optionField: "order_new_status_of_done",
        },
      },
    ],
    columns: [
      {
        id: "number",
        name: "Order Number",
        enableHyperlink: true,
      },
      {
        id: "tenant_name",
        name: "Tenant",
      },
      {
        id: "building_name",
        name: "Building | Room",
        maxWidthColumn: "auto",
        renderer: (value, state) => {
          return `${value} | ${state["room_name"]}`;
        },
      },
      {
        id: "price",
        name: "Price",
        renderer: (value) => {
          return utils.formatter.currency(value);
        },
      },
      {
        id: "subscription_type",
        name: "Subscription Type",
        referStateFieldName: "order_new_subscription_type",
        renderer: (value, _, constants) => {
          let label = "-";
          (constants ?? []).forEach((obj) => {
            if (obj.value === parseInt(value)) {
              label = obj.label;
            }
          });
          return label;
        },
      },
      {
        id: "order_status",
        name: "Order Status",
        referStateFieldName: "order_new_statuses",
        renderer: (value, _, constants) => {
          let label = "-";
          (constants ?? []).forEach((obj) => {
            if (obj.value === parseInt(value)) {
              label = obj.label;
            }
          });
          return label;
        },
      },
      {
        id: "status_of_done",
        name: "Status of Done",
        referStateFieldName: "order_new_status_of_done",
        renderer: (value, _, constants) => {
          let label = "-";
          (constants ?? []).forEach((obj) => {
            if (obj.value === parseInt(value)) {
              label = obj.label;
            }
          });
          return label;
        },
      },
      {
        id: "date_created",
        name: "Date Created",
        renderer: (value) => {
          return utils.formatter.date(value);
        },
      },
      {
        id: "checkin_date",
        name: "Check-In Date",
        renderer: (value) => {
          return utils.formatter.date(value);
        },
      },
      {
        id: "checkout_date",
        name: "Check-Out Date",
        renderer: (value) => {
          return utils.formatter.date(value);
        },
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
