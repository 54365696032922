import configBuilder from "../../../components/ubold/ConfigBuilder";

import secondaryDataHandler from "./SecondaryDataHandler";

let definitions = {
  title: "Carts",
  frontendPath: "/admin/logs/onetimepurchase/cart",
  baseLink: "/one_time_purchase/cart",
  restAccessCode: "one_time_purchase.cart",
  __table__: {
    addButton: false,
    hideEditButton: true,
    hideRemoveButton: true,
    showDetailButton: true,
    filterColumn: 3,
    hasSecondaryData: true,
    isAddButtonDisabled: true,
    isHideRemoveButton: true,
    secondaryDataItem: 2,
    secondaryDataHandler: secondaryDataHandler,
    filters: [
      {
        id: "tenant_name",
        name: "Tenant",
        type: "text",
      },
      {
        id: "product_name",
        name: "Product",
        type: "text",
      },
      {
        id: "status",
        name: "Status",
        type: "select",
        data: [
          {
            label: "In Cart",
            value: 1,
          },
          {
            label: "Finish",
            value: 2,
          },
        ],
      },
    ],
    columns: [
      {
        id: "tenant_text",
        name: "Tenant",
        enableHyperlink: true,
      },
      {
        id: "product_text",
        name: "Product",
      },
      {
        id: "qty",
        name: "Quantity",
      },
      {
        id: "status_text",
        name: "Status",
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
