import { useState } from "react";
import { useParams, withRouter } from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import Form from "../../../../components/ubold/organisms/Form";

import CVTextField from "../../../../components/ubold/molecules/forms/CVTextField";
import CVSelect from "../../../../components/ubold/molecules/forms/CVSelect";
import CVAsyncSelect from "../../../../components/ubold/molecules/forms/CVAsyncSelect";
import CVRichTextField from "../../../../components/ubold/molecules/forms/CVRichTextField";
import CVDateTimePicker from "../../../../components/ubold/molecules/forms/CVDateTimePicker";
import CVSwitch from "../../../../components/ubold/molecules/forms/CVSwitch";

import definitions from "./definitions";

function DiscountViewPage() {
  let { id }: { id: string } = useParams();

  const initialState = {
    discount_type: "flat",
    show_discount_period: true,
  };

  const [state, setState] = useState(initialState);

  return (
    <Page
      title={`Detail of ${definitions.title}`}
      restAccessCode={definitions.restAccessCode}
    >
      <Form
        id={id}
        definitions={definitions}
        state={state}
        setState={setState}
        readOnly={true}
      >
        <CVTextField
          title="Name"
          name="name"
          maxLength={200}
          isRequired
          readOnly={true}
        />
        <CVAsyncSelect
          title="Category"
          name="category"
          data="finance/discount_category?__type__=select_entries"
          readOnly={true}
        />
        <CVSelect
          title="Type"
          name="types"
          data={[
            {
              label: "Employee",
              value: 1,
            },
            {
              label: "Referal",
              value: 2,
            },
          ]}
          readOnly={true}
        />
        <CVSelect
          title="Discount Type"
          name="discount_type"
          isRequired
          data={[
            {
              label: "Percent",
              value: "percent",
            },
            {
              label: "Flat",
              value: "flat",
            },
          ]}
          readOnly={true}
        />
        <CVTextField
          title="Amount"
          name="amount"
          type="number"
          readOnly={true}
        />
        <CVDateTimePicker
          title="Date Started"
          name="date_started"
          type="datetime"
          isRequired
          readOnly={true}
        />
        <CVDateTimePicker
          title="Date Ended"
          name="date_ended"
          type="datetime"
          isRequired
          readOnly={true}
        />
        <CVTextField
          title="Period (in Month)"
          name="valid_period"
          type="number"
          readOnly={true}
        />
        <CVSwitch
          title="Is Stackable"
          name="is_stackable"
          readOnly={true}
        />
        <CVSwitch
          title="Show discount end date in the website"
          name="show_discount_period"
          readOnly={true}
        />
        <CVRichTextField
          title="Description"
          name="description"
          readOnly={true}
        />
      </Form>
    </Page>
  );
}

export default withRouter(DiscountViewPage);
