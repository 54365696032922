import { useParams, withRouter } from "react-router";
import { useEffect, useState } from "react";
import TabPaneSecondary from "components/ubold/organisms/TabPaneSecondary";

import COneOrManyToManyField from "components/ubold/molecules/forms/COneOrManyToManyField";

function PriceUpdate(props: any) {
  const { definitions, tab, state, setState } = props;

  let { id }: { id: string } = useParams();
  const [dataList, setDataList] = useState([]);

  useEffect(() => {
    if (!state[tab.id]) return;
    setDataList(state[tab.id]);
  }, [state[tab.id]]);

  return (
    <TabPaneSecondary
      definitions={definitions}
      tab={tab}
      restAccessCodeNamespace="price_update"
      state={state}
      setState={setState}
      readOnly={true}
    >
      <COneOrManyToManyField
        id={id}
        name={tab.id}
        readOnly={true}
        restPath="order_new/price_update/"
        relationKey="order_id"
        saveOnlyFromMainForm={true}
        index={4}
        columns={[
          {
            name: "price",
            type: "number",
            title: "Price",
            readOnly: true,
          },
          {
            name: "period",
            type: "monthpicker",
            title: "Period",
            isRequired: true,
            dynamicReadOnly: true,
          },
          {
            name: "date_created",
            type: "date",
            title: "Created Date",
            readOnly: true,
          },
          {
            name: "status",
            type: "select",
            title: "Status",
            readOnly: true,
            default: 1,
            data: [
              {
                label: "Waiting",
                value: 1,
              },
              {
                label: "Applied",
                value: 2,
              },
              {
                label: "Cancel",
                value: 3,
              },
            ],
          },
          {
            name: "is_cancelled",
            type: "checkbox",
            dynamicReadOnly: true,
            title: "Cancel",
            default: false,
            dataList: dataList,
            readOnly: true,
          },
        ]}
      />
    </TabPaneSecondary>
  );
}

export default withRouter(PriceUpdate);
