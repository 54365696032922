import configBuilder from "../../../../components/ubold/ConfigBuilder";

import pageDefinitions from "../definitions";

const definitions = {
  ...pageDefinitions,
  linkHistory: (id: string) =>
    `${definitions.frontendPath}/history/${id}`,
  tabs: [
    {
      id: "invoice",
      name: "Invoice",
    },
    {
      id: "t_payments",
      name: "Payments",
    },
    {
      id: "t_midtrans_transactions",
      name: "Midtrans Transactions",
    },
  ],
  validators: {
    discount_amount: (value: string, state: any) => {
      if (state["total_amount"] - parseInt(value) < 0) {
        return "Discount can't be greater than Amount";
      } else {
        return true;
      }
    },
  },
};

export default configBuilder.buildAddEditPageConfig(definitions);
