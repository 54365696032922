import { isMobile } from "react-device-detect";
import { otpPaymentMethod } from "./definitions";
import moment from "moment";

function SecondaryDataHandler(data) {
  const dateCreated = moment(data.date_created).format(
    "DD MMM YYYY HH:mm:ss"
  );
  const dateUpdated = moment(data.date_updated).format(
    "DD MMM YYYY HH:mm:ss"
  );

  const usedOTPPaymentMethod = otpPaymentMethod.find(
    (paymentMethod) => paymentMethod.value === data.otp_payment_method
  );

  return (
    <div
      className={
        isMobile
          ? "secondary-data-panel-mobile"
          : "secondary-data-panel"
      }
    >
      <div className="row mb-2">
        <div className="col mw-200">
          {usedOTPPaymentMethod && (
            <div>
              <b>Payment Method</b>: <br />
              {usedOTPPaymentMethod.label}
            </div>
          )}
        </div>

        <div className="col mw-200">
          {data.bank_name && (
            <div>
              <b>Bank Name</b>: <br />
              {data.bank_name.toUpperCase()}
            </div>
          )}
        </div>

        <div className="col mw-200">
          {data.bank_account_number && (
            <div>
              <b>Bank Account Number</b>: <br />
              {data.bank_account_number}
            </div>
          )}
        </div>

        <div className="col mw-200">
          {data.date_created && (
            <div>
              <b>Date Created</b>: <br />
              {dateCreated}
            </div>
          )}
        </div>

        <div className="col mw-200">
          {data.date_updated && (
            <div>
              <b>Date Updated</b>: <br />
              {dateUpdated}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SecondaryDataHandler;
