import { useParams, withRouter } from "react-router-dom";

import TabPaneSecondary from "../../../../../../components/ubold/organisms/TabPaneSecondary";

import COneOrManyToManyField from "../../../../../../components/ubold/molecules/forms/COneOrManyToManyField";

function RoomVariant(props) {
  const { definitions, tab, state, setState } = props;

  let { id } = useParams();

  return (
    <TabPaneSecondary
      definitions={definitions}
      tab={tab}
      restAccessCodeNamespace="room_variant"
      state={state}
      setState={setState}
    >
      <COneOrManyToManyField
        id={id}
        name={tab.id}
        restPath="building/room_variant/"
        additionalDataFilter="room_total_info=1"
        relationKey="building_id"
        readOnly
        columns={[
          {
            name: "name",
            type: "text",
            title: "Name",
            maxLength: 150,
            isRequired: true,
            disabledAtEdit: true,
          },
          {
            name: "room_type",
            type: "async_select",
            title: "Room Type",
            isRequired: true,
            data: "building/room_type?__type__=select_entries",
            disabledAtEdit: true,
          },
          {
            name: "price",
            type: "number",
            isRequired: true,
            title: "Price",
            disabledAtEdit: true,
          },
          {
            name: "landlord",
            type: "async_select",
            title: "Landlord",
            data: "building/landlord?__type__=select_entries",
            disabledAtEdit: true,
          },
          {
            name: "discount",
            type: "async_select",
            title: "Discount",
            data: "finance/discount?__type__=select_entries",
            disabledAtEdit: true,
          },
          {
            name: "max_occupancy",
            type: "number",
            isRequired: true,
            title: "Max Occupancy",
            disabledAtEdit: true,
          },
          {
            name: "available_room",
            type: "read_only",
            center: true,
            title: "Available Room",
            disabledAtEdit: true,
          },
          {
            name: "total_room",
            type: "read_only",
            center: true,
            title: "Total Room",
            disabledAtEdit: true,
          },
          {
            name: "is_renovated",
            type: "select",
            title: "Is Renovated",
            width: 150,
            disabledAtEdit: true,
            data: [
              {
                label: "Yes",
                value: true,
              },
              {
                label: "No",
                value: false,
              },
            ],
          },
        ]}
      />
    </TabPaneSecondary>
  );
}

export default withRouter(RoomVariant);
