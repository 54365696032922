import utils from "../../../utils";

import configBuilder from '../../../components/ubold/ConfigBuilder';

let definitions = {
    title: 'Structures',
    baseLink: '/crm/structure',
    restAccessCode: 'administrator.structure',
    __table__: {
        filterColumn: 3,
        filters: [
            {
                id: 'name',
                name: 'Structure Name',
                type: 'text'
            },
            {
                id: 'parent_structure',
                name: 'Parent Structure',
                type: 'async_select',
                data: 'crm/structure?__type__=select_entries'
            },
            {
                id: 'can_have_team',
                name: 'Can Have Team?',
                type: 'select',
                data: [
                    {
                        label: 'Yes',
                        value: true
                    },
                    {
                        label: 'No',
                        value: false
                    }
                ]
            },
        ],
        columns: [
            {
                id: 'name',
                name: 'Name'
            },
            {
                id: 'parent_name',
                sortId: 'parent',
                name: 'Parent Structure'
            },
            {
                id: 'can_have_team',
                name: 'Can Have Team?',
                isSafeContent: true,
                center: true,
                renderer: (value) => {
                    if(value === 'true') {
                        return '<span style="color: rgb(26, 188, 156);"> ✔ </span>';
                    } else {
                        return '<span style="color: red;">✘</span>';
                    }
                }
            },
            {
                id: 'created_at',
                name: 'Created At',
                renderer: (value) => {
                    return utils.formatter.dateTime(value);
                }
            }
        ]
    }
}

export default configBuilder.buildTablePageConfig(definitions);