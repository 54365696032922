import {useState} from "react";

import {useParams, withRouter} from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import Form from "../../../../components/ubold/organisms/Form";

import CVSimpleManyToMany from "../../../../components/ubold/molecules/forms/CVSimpleManyToMany";

import definitions from "./definitions";
import CVTextView from "../../../../components/ubold/molecules/forms/CVTextView";
import utils from "../../../../utils";

function CAddEditPage() {
    let {id} = useParams();

    const initialState = {};

    const [state, setState] = useState(initialState);

    return (
        <Page title={(id === undefined ? 'Add New' : 'Edit') + ' ' +  definitions.title}
              restAccessCode={definitions.restAccessCode}>
            <Form
                id={id}
                definitions={definitions}
                state={state}
                setState={setState}>
                <CVSimpleManyToMany
                    name='popular_landmark'
                    title='Popular Landmarks'
                    overrideLayout='12'
                    data='building/place_group?__type__=select_entries'
                />
                <CVTextView
                    name='date_created'
                    title='Date Created'
                    renderer={value => {
                        return utils.formatter.dateTime(value);
                    }}
                />
                <CVTextView
                    name='date_updated'
                    title='Date Updated'
                    renderer={value => {
                        return utils.formatter.dateTime(value);
                    }}
                />
            </Form>
        </Page>
    );
}

export default withRouter(CAddEditPage);