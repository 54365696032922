import utils from "../../../utils";

import configBuilder from "../../../components/ubold/ConfigBuilder";

let definitions = {
  title: "Team Members",
  frontendPath: "/admin/people/team_member",
  baseLink: "/team/member",
  restAccessCode: "team.member",
  isTeamMenu: true,
  __table__: {
    removeIdentifier: "username",
    hideEditButton: true,
    hideRemoveButton: true,
    addButton: false,
    filterColumn: 2,
    filters: [
      {
        id: "username",
        name: "Username",
        type: "text",
      },
      {
        id: "email",
        name: "Email",
        type: "email",
      },
    ],
    columns: [
      {
        id: "first_name",
        name: "Name",
        renderer: (value, row) => {
          return (
            (value ? value : "") +
            " " +
            (row["last_name"] ? row["last_name"] : "")
          );
        },
        enableHyperlink: true,
      },
      {
        id: "username",
        name: "Username",
      },
      {
        id: "email",
        name: "Email",
      },
      {
        id: "position",
        name: "Position",
        canDoOrdering: false,
      },
      {
        id: "date_joined",
        name: "Join At",
        renderer: (value) => {
          return utils.formatter.dateTime(value);
        },
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
