import { withRouter } from "react-router-dom";

import useGlobalState from "../../../../state";

import utils from "../../../../utils";

function CHImageView(props) {
  const {
    name,
    title,
    isRequired = false,
    restAccessCode,
    state,
    helperText = "",
    imgWidth,
  } = props;

  const [fieldAccesses] = useGlobalState("field_accesses");

  return (
    <div
      className="form-group row mb-3"
      style={{
        display:
          !isRequired &&
          restAccessCode &&
          utils.access.isFieldHidden(
            fieldAccesses,
            restAccessCode + "." + name
          )
            ? "none"
            : "flex",
      }}
    >
      <label
        htmlFor="name"
        className={
          "col-md-3 col-form-label" +
          (isRequired ? " font-weight-bold" : "")
        }
      >
        {title}
      </label>
      <div className="col-md-9" style={{ paddingTop: "7px" }}>
        {state[name] === null ||
        state[name] === undefined ||
        !utils.commons.isString(state[name]) ? (
          "---"
        ) : (
          <img
            alt="image"
            src={state[name]}
            style={{ width: imgWidth ?? "200px" }}
          />
        )}
        {helperText !== "" ? (
          <p className="text-muted font-13 pt-1 mb-0">{helperText}</p>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default withRouter(CHImageView);
