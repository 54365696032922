import {useState} from "react";

import {useParams, withRouter} from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import TabHeaders from "../../../../components/ubold/organisms/TabHeaders";
import TabContent from "../../../../components/ubold/organisms/TabContent";
import TabbedForm from "../../../../components/ubold/organisms/TabbedForm";

import Role from "./Tabs/Role";
import RoleAccesses from "./Tabs/RoleAccesses";
import RoleAccessFields from "./Tabs/RoleAccessFields";
import RoleUsers from "./Tabs/RoleUsers";

import definitions from "./definitions";

function TeamMemberRoleAddEditPage() {
    let { id } = useParams();

    const initialState = {
        is_personal_role: false
    };

    const [state, setState] = useState(initialState);

    return (
        <Page title={(id === undefined ? 'Add New' : 'Edit') + ' ' +  definitions.title}
              restAccessCode={definitions.restAccessCode}>
            <TabbedForm
                id={id}
                state={state}
                setState={setState}
                definitions={definitions}>
                <TabHeaders />
                <TabContent>
                    <Role />
                    <RoleAccesses />
                    <RoleAccessFields />
                    <RoleUsers />
                </TabContent>
            </TabbedForm>
        </Page>
    );
}

export default withRouter(TeamMemberRoleAddEditPage);