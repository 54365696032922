import { withRouter } from "react-router-dom";

import Flatpickr from "react-flatpickr";

import moment from "moment";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";

require("flatpickr/dist/plugins/monthSelect/style.css");
require("flatpickr/dist/flatpickr.css");

function CDateTimePicker(props) {
  const {
    name,
    type = "datetime",
    onChange,
    modeRange = false,
    readOnly,
    isDisabled = false,
    onClear = null,
    state,
    setState,
    disableMobile = false,
    minDate = null,
    maxDate = null,
    monthSelect = false,
    disabledDate = [],
    isClearable = true,
    enableTime = type !== "date",
    dateFormat = "Y-m-d H:i",
    defaultDate = "",
  } = props;

  let flatPickers = null;

  return (
    <div className="input-group flatpickr">
      <Flatpickr
        id={name}
        name={name}
        value={state[name] || defaultDate}
        disabled={readOnly || isDisabled}
        placeholder={
          type === "date" && !monthSelect
            ? "Y-m-d"
            : type === "time"
            ? "H:i K"
            : type === "date" && monthSelect
            ? "Y-m"
            : dateFormat
        }
        ref={(fp) => {
          flatPickers = fp;
        }}
        style={{
          border: "1px solid #ced4da",
          width: "100%",
          padding: "8px 8px 7px 8px",
        }}
        options={{
          dateFormat:
            type === "date"
              ? "Y-m-d"
              : type === "time"
              ? "H:i"
              : dateFormat,
          enableTime: enableTime,
          noCalendar: type === "time",
          mode: modeRange ? "range" : "single",
          disableMobile: disableMobile,
          minDate: minDate,
          maxDate: maxDate,
          plugins: monthSelect && [
            new monthSelectPlugin({
              dateFormat: "Y-m",
              altFormat: "M Y",
            }),
          ],
          disable: disabledDate,
        }}
        onChange={
          onChange
            ? onChange
            : (date) => {
                let result = moment(date[0].toISOString()).format(
                  "YYYY-MM-DD HH:mm"
                );

                if (type === "date") {
                  if (modeRange) {
                    result =
                      moment(date[0].toISOString()).format(
                        "YYYY-MM-DD"
                      ) +
                      (date[1]
                        ? " to " +
                          moment(date[1].toISOString()).format(
                            "YYYY-MM-DD"
                          )
                        : "");
                  } else {
                    result = moment(date[0].toISOString()).format(
                      "YYYY-MM-DD"
                    );
                  }
                } else if (type === "time") {
                  if (modeRange) {
                    result =
                      moment(date[0].toISOString()).format(
                        "HH:mm:ss"
                      ) +
                      (date[1]
                        ? " to " +
                          moment(date[1].toISOString()).format(
                            "HH:mm:ss"
                          )
                        : "");
                  } else {
                    result = moment(date[0].toISOString()).format(
                      "HH:mm:ss"
                    );
                  }
                }

                setState((prevState) => ({
                  ...prevState,
                  [name]: result,
                }));
              }
        }
      />
      <div
        className="input-group-append"
        style={{ position: "absolute", right: "0px", zIndex: "0" }}
      >
        {isClearable &&
        state[name] !== null &&
        state[name] !== undefined &&
        !readOnly ? (
          <button
            className="btn waves-light"
            type="button"
            onClick={
              onClear
                ? onClear
                : () => {
                    if (flatPickers) {
                      setState((prevState) => ({
                        ...prevState,
                        [name]: null,
                      }));
                    }
                  }
            }
          >
            <i className="fas fa-times" />
          </button>
        ) : (
          <></>
        )}
        <button
          className="btn btn-dark waves-effect waves-light"
          type="button"
          onClick={() => {
            if (flatPickers) {
              flatPickers.flatpickr.open();
            }
          }}
        >
          <i
            className={
              "fas" +
              (type === "time" ? " fa-clock" : " fa-calendar-alt")
            }
          />
        </button>
      </div>
    </div>
  );
}

export default withRouter(CDateTimePicker);
