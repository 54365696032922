import configBuilder from "../../../components/ubold/ConfigBuilder";

let definitions = {
  title: "One Time Purchase",
  frontendPath: "/building/onetimepurchase/product",
  baseLink: "/one_time_purchase/product",
  restAccessCode: "one_time_purchase.product",
  __table__: {
    hideEditButton: true,
    filterColumn: 4,
    removeIdentifier: "name",
    filters: [
      {
        id: "name",
        name: "Product Name",
        type: "text",
      },
    ],
    columns: [
      {
        id: "name",
        name: "Name",
        canDoOrdering: false,
        enableHyperlink: true,
      },
      {
        id: "condition",
        name: "Condition",
        renderer: (value) => {
          if (value === "1") {
            return "New";
          } else if (value === "2") {
            return "Second";
          }
        },
      },
      {
        id: "price",
        name: "Price",
        canDoOrdering: false,
      },
      {
        id: "status",
        name: "Status",
        renderer: (value) => {
          if (value === "1") {
            return "Publish";
          } else if (value === "2") {
            return "Unpublish";
          }
        },
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
