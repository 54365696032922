import configBuilder from "../../../../components/ubold/ConfigBuilder";

import pageDefinitions from "../definitions";
import utils from "../../../../utils";

const definitions = {
  ...pageDefinitions,
  validators: {
    mobile_screen_original: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    tablet_screen_original: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    desktop_screen_original: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    url: (value: string) => {
      if (value && !value.startsWith("http"))
        return "Please input URL start with http:// or https:// (case sensitive)";
      return utils.validator.validateRequired(value);
    },
    order: (value: string) => {
      return utils.validator.validateRequired(value);
    },
  },
};

export default configBuilder.buildAddEditPageConfig(definitions, [
  "mobile_screen_original",
  "tablet_screen_original",
  "desktop_screen_original",
]);
