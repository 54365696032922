import { useEffect, useState } from "react";

import { useParams, withRouter } from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import TabbedForm from "../../../../components/ubold/organisms/TabbedForm";
import TabHeaders from "../../../../components/ubold/organisms/TabHeaders";
import TabContent from "../../../../components/ubold/organisms/TabContent";

import Invoice from "./Tabs/InvoiceGeneral";
import InvoicePayments from "./Tabs/InvoicePayments";
import InvoiceMidtransTransactions from "./Tabs/InvoiceMidtransTransactions";
import moment from "moment";

import definitions from "./definitions";

function InvoiceAddEditPage() {
  let { id } = useParams();

  const initialState = !id
    ? {
        release_status: 2,
        invoice_status: 1,
        invoice_type: 4,
        category: 2,
        sub_category: 5,
        is_manual: true,
        started_at: moment().format("YYYY-MM-DD"),
        ended_at: moment(moment())
          .endOf("month")
          .format("YYYY-MM-DD"),
        t_invoice_details: [],
        first_invoice: false,
      }
    : {};

  const [state, setState] = useState(initialState);
  const [onlyOnce, setOnlyOnce] = useState(false);

  useEffect(() => {
    if (onlyOnce || !state?.payment) return;
    const tempState = { ...state };
    const tempPayment = { ...state?.payment };
    const paymentKeys = Object.keys(tempPayment);
    paymentKeys.forEach((key) => {
      tempState[key] = tempPayment[key];
    });
    setState(tempState);
    setOnlyOnce(true);
  }, [state]);

  useEffect(() => {
    if (!state?.payment_link?.url) return;
    setState((prevState) => ({
      ...prevState,
      payment_link_url: state?.payment_link?.url,
    }));
  }, [state?.payment_link]);

  return (
    <Page
      title={
        (id === undefined ? "Add New" : "Edit") +
        " " +
        definitions.title
      }
      restAccessCode={definitions.restAccessCode}
    >
      <TabbedForm
        id={id}
        state={state}
        setState={setState}
        definitions={definitions}
      >
        <TabHeaders />
        <TabContent>
          <Invoice />
          <InvoicePayments />
          <InvoiceMidtransTransactions />
        </TabContent>
      </TabbedForm>
    </Page>
  );
}

export default withRouter(InvoiceAddEditPage);
