import {useState} from "react";

import {useParams, withRouter} from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";
import Form from "../../../../components/ubold/organisms/Form";

import CVTextField from "../../../../components/ubold/molecules/forms/CVTextField";
import CVTextArea from "../../../../components/ubold/molecules/forms/CVTextArea";
import CVSelect from "../../../../components/ubold/molecules/forms/CVSelect";
import CVAsyncSelect from "../../../../components/ubold/molecules/forms/CVAsyncSelect";

import definitions from "./definitions";

function AccessAddEditPage() {
    let { id } = useParams();

    const initialState = {};

    const [state, setState] = useState(initialState);

    return (
        <Page title={(id === undefined ? 'Add New' : 'Edit') + ' ' +  definitions.title}
              restAccessCode={definitions.restAccessCode}>
            <Form
                id={id}
                definitions={definitions}
                state={state}
                setState={setState}>
                <CVTextField
                    title='Code'
                    name='code'
                    isRequired
                    maxLength={150}
                    helperText='Required. 150 characters or fewer. Letters, digits and ./_ only.'
                />
                <CVSelect
                    title='Type'
                    name='type'
                    data={[
                        {
                            label: 'Menu',
                            value: 0
                        },
                        {
                            label: 'Feature',
                            value: 1
                        },
                    ]}
                    isRequired
                />
                <CVAsyncSelect
                    title='Menu'
                    name='menu'
                    data='crm/menu?__type__=select_entries'
                />
                <CVTextField
                    title='Name'
                    name='name'
                    maxLength={75}
                />
                <CVTextField
                    title='Descriptive Name'
                    name='descriptive_name'
                    maxLength={125}
                />
                <CVTextArea
                    title='Description'
                    name='description'
                    maxLength={255}
                />
            </Form>
        </Page>
    );
}

export default withRouter(AccessAddEditPage);