import utils from "../../../../utils";

import configBuilder from "../../../../components/ubold/ConfigBuilder";

import pageDefinitions from "../definitions";

const doneStatus = [4, 5, 6];

const definitions = {
  ...pageDefinitions,
  linkHistory: (id) => `${definitions.frontendPath}/history/${id}`,
  tabs: [
    {
      id: "order",
      name: "Order",
    },
    {
      id: "t_additional_discounts",
      name: "Additional Discounts",
      alwaysEditable: true,
    },
    {
      id: "t_addon_buildings",
      name: "Addon Buildings",
      alwaysEditable: true,
    },
    {
      id: "t_service_schedules",
      name: "Service Schedules",
      alwaysEditable: true,
    },
  ],
  validators: {
    tenant: (value) => {
      return utils.validator.validateRequired(value);
    },
    building: (value) => {
      return utils.validator.validateRequired(value);
    },
    room_variant: (value) => {
      return utils.validator.validateRequired(value);
    },
    room: (value, state) => {
      const roomValue = utils.validator.validateRequired(value);

      if (
        state["building_label"] &&
        state["building_label"].indexOf("Centralized") >= 0 &&
        roomValue !== true
      ) {
        return "Room is required if building is Centralized";
      } else {
        return true;
      }
    },
    payment_plan: (value) => {
      return utils.validator.validateRequired(value);
    },
    discount_amount: (value) => {
      return utils.validator.validateRequired(value);
    },
    tenant_category: (value) => {
      return utils.validator.validateRequired(value);
    },
    status: (value) => {
      return utils.validator.validateRequired(value);
    },
    date_started: (value) => {
      return utils.validator.validateRequired(value);
    },
    reason_of_check_out: (value, state) => {
      if (state["date_checkout"]) {
        return utils.validator.validateRequired(value);
      }

      return true;
    },
    checkout_category: (value, state) => {
      if (state["date_checkout"]) {
        return utils.validator.validateRequired(value);
      }

      return true;
    },
    checkout_reason: (value, state) => {
      if (state["date_checkout"]) {
        return utils.validator.validateRequired(value);
      }

      return true;
    },
    status_of_done: (value) => {
      return utils.validator.validateRequired(value);
    },
    deposit: (value) => {
      if (parseInt(value) < 0) {
        return "Deposit should be greater than 0";
      } else {
        return true;
      }
    },
    deposit_notes: (value, state) => {
      const depositNotesValue =
        utils.validator.validateRequired(value);

      if (
        !doneStatus.includes(state["status"]) &&
        state["deposit"] > 0 &&
        depositNotesValue !== true
      ) {
        return "Deposit Notes is Required";
      } else {
        return true;
      }
    },
    discount_notes: (value, state) => {
      const discountNotesValue =
        utils.validator.validateRequired(value);

      if (
        state["discount_amount"] > 0 &&
        discountNotesValue !== true
      ) {
        return "Discount Notes is Required";
      } else {
        return true;
      }
    },
  },
};

export default configBuilder.buildAddEditPageConfig(
  definitions,
  [],
  ["created_by", "date_created", "date_updated"]
);
