import React from "react";
import {
  BulletSymbol,
  FlagDualModeBase,
  FlagSingleModeBase,
  FlagTheme,
} from "./Flag.css";

const Flag: React.FC<{
  color?: string;
  dual?: {
    left: {
      color?: string;
      text: string;
      theme?: FlagTheme;
    };
    right: {
      color?: string;
      text: string;
      theme?: FlagTheme;
    };
  };
  theme?: FlagTheme;
}> = ({ color, dual, children, theme }) => {
  if (dual) {
    return (
      <React.Fragment>
        <FlagDualModeBase
          color={dual.left.color}
          directon="left"
          theme={dual.left.theme}
        >
          <BulletSymbol
            color={dual.left.color}
            theme={dual.left.theme}
          />
          <span>{dual.left.text}</span>
        </FlagDualModeBase>
        <FlagDualModeBase
          color={dual.right.color}
          directon="right"
          theme={dual.right.theme}
        >
          <span>{dual.right.text}</span>
        </FlagDualModeBase>
      </React.Fragment>
    );
  }

  return (
    <FlagSingleModeBase color={color} theme={theme}>
      <BulletSymbol color={color} theme={theme} />
      <span>{children}</span>
    </FlagSingleModeBase>
  );
};

export default Flag;
