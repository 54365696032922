import configBuilder from "components/ubold/ConfigBuilder";

import pageDefinitons from "../definitions";
import utils from "utils";

const definitions = {
  ...pageDefinitons,
  validators: {
    title: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    messages: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    sanity_id: (value: string) => {
      return utils.validator.validateRequired(value);
    },
    notif_type: (value: number) => {
      return utils.validator.validateRequired(value);
    },
    target: (value: number) => {
      return utils.validator.validateRequired(value);
    },
  },
};

export default configBuilder.buildAddEditPageConfig(definitions);
