import { useEffect, useState } from "react";

import { useLocation, withRouter } from "react-router-dom";

import logoDark from "../../assets/logo-dark.png";

import services from "../../services";
import utils from "../../utils";
import configs from "../../configs";

function LoginPage(props) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const location = useLocation();

  const login = () => {
    setLoginError(false);
    setErrorMessage(null);
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isEmail = regex.test(username);
    if (username === "") {
      setLoginError(true);
      setErrorMessage("Please fill username input.");
    }
    // Username must not in email format
    else if (isEmail) {
      setLoginError(true);
      setErrorMessage(
        "Please use username instead of email address."
      );
    } else if (password === "") {
      setLoginError(true);
      setErrorMessage("Please fill password input");
    } else {
      window.localStorage.clear();
      services.auth.login(
        {
          username: username,
          password: password,
        },
        (data) => {
          utils.store.set(configs.t_key, data.access);
          utils.store.set(configs.r_key, data.refresh);
          utils.store.set("userRole", data?.roles);
          utils.store.set("supervisor", data?.structures_parent);

          let urlSearch = new URLSearchParams(location.search);

          let topAccessibleMenu = utils.commons.getTopAccessibleMenu(
            data["menu_accesses"]
          );

          utils.store.set("topAccessibleMenu", topAccessibleMenu);

          const next = urlSearch.get("next");
          if (next) {
            const hasAccess = utils.commons.hasAccess(
              next,
              data["menu_accesses"]
            );

            window.location.href = hasAccess
              ? next
              : topAccessibleMenu;
          } else {
            window.location.href = topAccessibleMenu || "/u/home";
          }
        },
        (error, message) => {
          setLoginError(true);

          if (
            error.response &&
            (error.response.status === 401 ||
              error.response.status === 400)
          ) {
            setErrorMessage(null);
          } else {
            setErrorMessage(message);
          }
        }
      );
    }
  };

  useEffect(() => {
    const t = utils.store.get("t");

    if (t) {
      let topAccessibleMenu = utils.store.get("topAccessibleMenu");

      props.history.push(topAccessibleMenu || "/u/home");
    }
  }, []);

  return (
    <div className="account-pages mt-5 mb-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 col-lg-6 col-xl-5">
            <div className="card bg-pattern">
              <div className="card-body p-4">
                <div className="text-center w-75 m-auto">
                  <div className="auth-logo">
                    <a className="logo logo-dark text-center">
                      <span className="logo-lg">
                        <img src={logoDark} alt="Logo" height="42" />
                      </span>
                    </a>
                  </div>
                  <p
                    className="text-muted mb-4 mt-3"
                    style={{ color: "#222222" }}
                  >
                    Masukkan username dan password untuk masuk
                    Internal Tools New Dashboard
                  </p>
                </div>
                {loginError ? (
                  <div className="alert alert-block alert-danger">
                    <ul className="m-0">
                      <li>
                        {errorMessage !== null && errorMessage !== ""
                          ? errorMessage
                          : "Please enter a correct username and password. Note that both fields may be case-sensitive."}
                      </li>
                    </ul>
                  </div>
                ) : (
                  <></>
                )}
                <div className="form-group mb-3">
                  <label htmlFor="username">Username</label>
                  <input
                    className="form-control"
                    type="text"
                    id="username"
                    required
                    placeholder="Enter your username"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();

                        return login();
                      }
                    }}
                    onChange={(e) => {
                      setLoginError(false);
                      setUsername(e.target.value);
                    }}
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="password">Password</label>
                  <div className="input-group input-group-merge">
                    <input
                      type="password"
                      id="password"
                      className="form-control"
                      required
                      placeholder="Enter your password"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();

                          return login();
                        }
                      }}
                      onChange={(e) => {
                        setLoginError(false);
                        setPassword(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="form-group mb-0 text-center">
                  <button
                    className="btn btn-primary btn-block"
                    type="submit"
                    style={{
                      backgroundColor: "#35B0A7",
                      borderColor: "#35B0A7",
                    }}
                    onClick={(e) => login(e)}
                  >
                    Log In
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(LoginPage);
