import { useParams, withRouter } from "react-router";

import TabPaneSecondary from "components/ubold/organisms/TabPaneSecondary";
import COneOrManyToManyField from "components/ubold/molecules/forms/COneOrManyToManyField";

function ReportFileTab(props: any) {
  const { definitions, tab, state, setState } = props;
  let { id }: { id: string } = useParams();

  return (
    <TabPaneSecondary
      definitions={definitions}
      restAccessCodeNamespace="report_file"
      tab={tab}
      state={state}
      setState={setState}
    >
      <COneOrManyToManyField
        id={id}
        name={tab.id}
        restPath="landlord/report_file/"
        relationKey="periode"
        photoFields={["file"]}
        saveOnlyFromMainForm={true}
        dependsTabName={tab.id}
        columns={[
          {
            name: "file",
            type: "photo_upload",
            title: "File",
            isPdf: true,
            tabId: tab.id,
            dependKey: "attachment_url",
            alertFileSize: "20MB",
            alertAllowedExtension: ".pdf",
            maxSize: 20000000,
            /** New Additional Config */
            useURL: true,
            uploadPath: "landlord/report_file",
            contentType: "multipart/form-data",
            restPath: "upload",
          },
          {
            name: "attachment_url",
            type: "text",
            title: "Attachment URL",
            tabId: tab.id,
            dependKey: "file",
          },
          {
            name: "category",
            type: "select",
            title: "Category",
            isRequired: true,
            data: [
              {
                label: "Revenue",
                value: 1,
              },
              {
                label: "Service",
                value: 2,
              },
              {
                label: "Housekeeping",
                value: 3,
              },
            ],
          },
          {
            name: "invalid",
            title: "Invalid",
            type: "checkbox",
          },
        ]}
      />
    </TabPaneSecondary>
  );
}

export default withRouter(ReportFileTab);
