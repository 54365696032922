import {useParams, withRouter} from "react-router-dom";

import TabPaneSecondary from "../../../../../../components/ubold/organisms/TabPaneSecondary";

import COneOrManyToManyField from "../../../../../../components/ubold/molecules/forms/COneOrManyToManyField";

function OrderServiceSchedules(props) {
    const {definitions, tab, state, setState} = props;

    let {id} = useParams();

    return (
        <TabPaneSecondary
            definitions={definitions}
            tab={tab}
            restAccessCodeNamespace='service_schedule'
            state={state}
            setState={setState}>
            <COneOrManyToManyField
                id={id}
                name={tab.id}
                restPath='order/service_schedule/'
                relationKey='order_id'
                saveOnlyFromMainForm={true}
                index={3}
                maxRow={1}
                columns={[
                    {
                        name: 'laundry',
                        type: 'multi_checkbox',
                        data: [
                            {
                                label: 'Senin',
                                value: "1",
                            },
                            {
                                label: 'Selasa',
                                value: "2",
                            },
                            {
                                label: 'Rabu',
                                value: "3",
                            },
                            {
                                label: 'Kamis',
                                value: "4",
                            },
                            {
                                label: 'Jumat',
                                value: "5",
                            },
                            {
                                label: 'Sabtu',
                                value: "6",
                            },
                            {
                                label: 'Minggu',
                                value: "7",
                            },
                        ],
                        title: 'Laundry'
                    },
                    {
                        name: 'room_cleaning',
                        type: 'multi_checkbox',
                        data: [
                            {
                                label: 'Senin',
                                value: "1",
                            },
                            {
                                label: 'Selasa',
                                value: "2",
                            },
                            {
                                label: 'Rabu',
                                value: "3",
                            },
                            {
                                label: 'Kamis',
                                value: "4",
                            },
                            {
                                label: 'Jumat',
                                value: "5",
                            },
                            {
                                label: 'Sabtu',
                                value: "6",
                            },
                            {
                                label: 'Minggu',
                                value: "7",
                            },
                        ],
                        title: 'Room Cleaning'
                    },
                    {
                        name: 'laundry_default',
                        type: 'checkbox',
                        center: true,
                        title: 'Laundry Default',
                    },
                    {
                        name: 'room_cleaning_default',
                        type: 'checkbox',
                        center: true,
                        title: 'Room Cleaning Default',
                    },
                ]}
            />
        </TabPaneSecondary>
    );
}

export default withRouter(OrderServiceSchedules);