import {useParams, withRouter} from "react-router-dom";

import TabPaneSecondary from "../../../../../../components/ubold/organisms/TabPaneSecondary";

import COneOrManyToManyField from "../../../../../../components/ubold/molecules/forms/COneOrManyToManyField";
import {useEffect, useState} from "react";

function TiringEscalation(props) {
    const {definitions, tab, state, setState} = props;

    let {id} = useParams();

    const [months, setMonths] = useState([]);

    useEffect(() => {
        const monthsGenerated = [];

        for(let i = 1; i <= 61; i++) {
            monthsGenerated.push({
                label: i + ' month' + (i === 1 ? '' : 's'),
                value: i
            })
        }

        setMonths(monthsGenerated);
    }, []);

    return (
        <TabPaneSecondary
            definitions={definitions}
            restAccessCodeNamespace='tiring_escalation'
            tab={tab}
            state={state}
            setState={setState}>
            <COneOrManyToManyField
                id={id}
                name={tab.id}
                restPath='building/tiring_escalation/'
                relationKey='building_aggrement_id'
                columns={[
                    {
                        name: 'month',
                        title: 'Month',
                        type: 'select',
                        isRequired: true,
                        default: '1 month',
                        data: months
                    },
                    {
                        name: 'escalating_amount',
                        title: 'Escalation Amount (in Month)',
                        isRequired: true,
                        default: 0,
                        type: 'number'
                    }
                ]}
            />
        </TabPaneSecondary>
    );
}

export default withRouter(TiringEscalation);