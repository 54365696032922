// LANDLORDS
import Landlord from "pages/Landlord/UserLandlord";
import LandlordAddEdit from "pages/Landlord/UserLandlord/AddEdit";
import LandlordView from "pages/Landlord/UserLandlord/ViewDetail";
// BUILDING LANDLORDS
import BuildingLandlord from "pages/Building/Landlord";
import BuildingLandlordAddEdit from "pages/Building/Landlord/AddEdit";
import BuildingLandlordView from "pages/Building/Landlord/ViewOnly";
// MONTHLY REPORTS
import LandlordMonthlyReport from "pages/Landlord/MonthlyReport";
import LandlordMonthlyReportAddEdit from "pages/Landlord/MonthlyReport/AddEdit";
import LandlordMonthlyReportView from "pages/Landlord/MonthlyReport/ViewOnly";

const LandlordRoutes = [
  // LANDLORDS
  {
    path: "/landlord/landlord",
    component: Landlord,
  },
  {
    path: "/landlord/landlord/(add|edit)/:id([0-9]+)?",
    component: LandlordAddEdit,
  },
  {
    path: "/landlord/landlord/view/:id([0-9]+)?",
    component: LandlordView,
  },
  // BUILDING LANDLORDS
  {
    path: "/landlord/building_landlord",
    component: BuildingLandlord,
  },
  {
    path: "/landlord/building_landlord/(add|edit)/:id([0-9]+)?",
    component: BuildingLandlordAddEdit,
  },
  {
    path: "/landlord/building_landlord/view/:id([0-9]+)?",
    component: BuildingLandlordView,
  },
  // MONTHLY REPORTS
  {
    path: "/landlord/monthly_report",
    component: LandlordMonthlyReport,
  },
  {
    path: "/landlord/monthly_report/(add|edit)/:id([0-9]+)?",
    component: LandlordMonthlyReportAddEdit,
  },
  {
    path: "/landlord/monthly_report/view/:id([0-9]+)?",
    component: LandlordMonthlyReportView,
  },
];

export default LandlordRoutes;
