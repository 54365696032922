import { useEffect, useState } from "react";

import { useParams, withRouter } from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import TabbedForm from "../../../../components/ubold/organisms/TabbedForm";
import TabHeaders from "../../../../components/ubold/organisms/TabHeaders";
import TabContent from "../../../../components/ubold/organisms/TabContent";

import TabInvoice from "./Tabs/Invoice";
import TabInvoiceDetails from "./Tabs/InvoiceDetails";
import TabPayments from "./Tabs/Payments";

import definitions from "./definitions";

function OTPFinanceDetailPage() {
  let { id } = useParams();

  const initialState = {};

  const [state, setState] = useState(initialState);

  useEffect(() => {
    definitions.onInit(id, setState);
  }, []);

  return (
    <Page
      title={"Detail " + definitions.title}
      restAccessCode={definitions.restAccessCode}
    >
      <TabbedForm
        id={id}
        state={state}
        setState={setState}
        definitions={definitions}
      >
        <TabHeaders />
        <TabContent>
          <TabInvoice />
          <TabInvoiceDetails />
          <TabPayments />
        </TabContent>
      </TabbedForm>
    </Page>
  );
}

export default withRouter(OTPFinanceDetailPage);
