import configBuilder from '../../../../components/ubold/ConfigBuilder';

import pageDefinitions from "../definitions";
import utils from "../../../../utils";

const definitions = {...pageDefinitions,
    validators: {
        collection_type: value => {
            return utils.validator.validateRequired(value)
        },
        name: value => {
            return utils.validator.validateRequired(value)
        },
        slug: value => {
            return utils.validator.validateRequired(value)
        },
        title_tag: value => {
            return utils.validator.validateRequired(value)
        },
    },
}

export default configBuilder.buildAddEditPageConfig(definitions, [
    'banner_photo_original',
    'banner_photo_small_original'
], [
    'header_photo_original'
]);