import { useEffect, useState } from "react";

import { Link, useLocation, withRouter } from "react-router-dom";

import { isMobile } from "react-device-detect";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import Pagination from "../molecules/tables/Pagination";
import FilterPanel from "components/ubold/molecules/tables/FilterPanel";
import FilterPanelNew from "components/ubold/molecules/tables/FilterPanelNew";

import configs from "../../../configs";
import utils from "../../../utils";
import useGlobalState from "../../../state";

function TableView(props) {
  const {
    path,
    rowsPerPage = [10, 20, 30, 40, 50],
    filterButtonsRight = [],
    isTeamMenu = false,
    selectedInitialRowsPerPage,
    restAccessCode,
    hasSecondaryData = false,
    secondaryDataRow = 1,
    secondaryDataItem = 5,
    secondaryDataRowHeight = 50,
    secondaryDataHandler = null,
    removeIdentifier,
    removeIdentifierNo,
    editAsDetail = false,
    columns = [],
    actions = [],
    cellRenderer = null,
    defaultOrdering = null,
    filterColumn = 2,
    isAddButtonDisabled,
    hideEditButton = false,
    isHideRemoveButton,
    showDetailButton = false,
    isUseNewFilter = false,
    isExportEnabled = false,
    ignoreOrdering = false,
    hideFilterButton = false,
    excludeParams = null,
    presetParams = null, // presetParams defined in definitions if specific query params required outside of filter
  } = props;

  const findFiftyRows = rowsPerPage.findIndex(
    (limit) => limit === 50
  );
  const [totalRows, setTotalRows] = useState(1);
  const [data, setData] = useState([]);
  const [rowOffset, setRowOffset] = useState(0);
  const [ordering, setOrdering] = useState("-id");
  const [params, setParams] = useState("");

  /** action button configuration */
  const addButton = !isAddButtonDisabled ? true : false;
  const hideRemoveButton = isHideRemoveButton
    ? isHideRemoveButton
    : false;

  const [isFetching, setIsFetching] = useState(true);
  const [isEmptyData, setIsEmptyData] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const [selectedPage, setSelectedPage] = useState(1);
  const [selectedRowPerPage, setSelectedRowPerPage] = useState(
    selectedInitialRowsPerPage
      ? rowsPerPage[selectedInitialRowsPerPage]
      : rowsPerPage[0]
  );

  const [user] = useGlobalState("user");
  const [fieldAccesses] = useGlobalState("field_accesses");
  const [accesses] = useGlobalState("accesses");
  const [constants] = useGlobalState("constant_values");

  const sweetAlert = withReactContent(Swal);

  const location = useLocation();

  const paramRestAccessCode = `rest_access_code=${restAccessCode}`;

  const fetch = (
    selectedPageParam,
    selectedRowPerPageParam,
    orderingParam = null,
    paramsParam = null
  ) => {
    setData([]);
    setIsFetching(true);
    setErrorMessage(null);

    setSelectedPage(selectedPageParam);
    setSelectedRowPerPage(selectedRowPerPageParam);

    if (orderingParam !== null) {
      setOrdering(orderingParam);
    }

    const usedParams = paramsParam === null ? params : paramsParam;
    const orderParam = ignoreOrdering
      ? ""
      : `ordering=${
          orderingParam !== null ? orderingParam : ordering
        }`;

    utils.httpClient.get(
      `${configs.apiUrl}${path}?${
        usedParams !== "" ? `${usedParams}&` : usedParams
      }${orderParam}${
        !presetParams ? "" : `&${presetParams}`
      }&page=${selectedPageParam}&limit=${selectedRowPerPageParam}`,
      (response) => {
        setRowOffset(
          (selectedPageParam - 1) * selectedRowPerPageParam
        );
        setTotalRows(response.data.count);
        setData(response.data.rows);

        if (
          response.data.rows === null ||
          response.data.rows.length === 0
        ) {
          setIsEmptyData(true);
        } else {
          setIsEmptyData(false);
        }

        setIsFetching(false);

        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      },
      (error, message) => {
        if (
          error.response &&
          (error.response.status === 401 ||
            error.response.status === 400)
        ) {
          const nextPath = window.location.pathname;

          utils.store.remove("t");

          props.history.push("/logout?next=" + nextPath);
        } else {
          setIsFetching(false);
          setData([]);

          let messageError =
            error.response &&
            error.response.data &&
            utils.commons.isString(error.response.data)
              ? error.response.data
                  .split("\n")[1]
                  .replace("['", "")
                  .replace("']", "")
              : message;

          setErrorMessage(
            messageError && messageError !== ""
              ? messageError
              : "Unknown"
          );
        }
      }
    );
  };

  const fetchSecondaryData = (id, index) => {
    setData(() => {
      setData(
        [...data].map((object, i) => {
          if (i === index) {
            return {
              ...object,
              secondaryPanelVisible: true,
            };
          } else {
            return object;
          }
        })
      );
    });

    utils.httpClient.get(
      configs.apiUrl +
        utils.commons.stripMultipleSlashes(path + "/" + id) +
        "?__type__=secondary_data",
      (response) => {
        setData(() => {
          setData(
            [...data].map((object, i) => {
              if (i === index) {
                return {
                  ...object,
                  secondaryPanelVisible: true,
                  secondaryPanelData: response,
                  secondaryPanelError: null,
                };
              } else {
                return object;
              }
            })
          );
        });
      },
      (error, message) => {
        setData(() => {
          setData(
            [...data].map((object, i) => {
              if (i === index) {
                return {
                  ...object,
                  secondaryPanelVisible: true,
                  secondaryPanelData: null,
                  secondaryPanelError: message,
                };
              } else {
                return object;
              }
            })
          );
        });
      }
    );
  };

  const removeRecord = (id, identifier = null) => {
    sweetAlert
      .fire({
        title: "Confirmation",
        html:
          "Are you sure to delete <strong>" +
          (identifier === null ? id : identifier) +
          "</strong>?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      })
      .then((result) => {
        if (result.isConfirmed) {
          utils.httpClient.delete(
            configs.apiUrl +
              (path + "/" + id + "/").replace(/\/+/g, "/") +
              "?" +
              paramRestAccessCode +
              "&object_id=" +
              id,
            null,
            () => {
              sweetAlert.fire(
                "Deleted!",
                "Record has been deleted.",
                "success"
              );

              fetch(selectedPage, selectedRowPerPage);
            },
            (error, message) => {
              const errorCode = error?.response?.status;
              let messageError = "";

              if (!errorCode === 400) {
                messageError = error?.response?.data
                  ? error?.response?.data
                      .split("\n")[1]
                      .replace("['", "")
                      .replace("']", "")
                  : message;
              } else {
                messageError =
                  error?.response?.data?.error_message ?? message;
              }

              sweetAlert.fire("Error", messageError, "error");
            }
          );
        }
      });
  };

  useEffect(() => {
    fetch(
      1,
      selectedInitialRowsPerPage
        ? rowsPerPage[selectedInitialRowsPerPage]
        : rowsPerPage[findFiftyRows > -1 ? findFiftyRows : 0],
      defaultOrdering
    );
  }, []);

  return (
    <div>
      {isUseNewFilter ? (
        <FilterPanelNew
          filters={props.filters}
          filterValidation={props.filterValidation}
          filterColumn={filterColumn}
          selectedPage={selectedPage}
          rowsPerPage={selectedRowPerPage}
          ordering={ordering}
          setParams={setParams}
          params={params}
          filterButtonsRight={filterButtonsRight}
          fetch={fetch}
          isTeamMenu={isTeamMenu}
          restAccessCode={restAccessCode}
          addButton={addButton}
          isExportEnabled={isExportEnabled}
          totalRows={totalRows}
          isFetching={isFetching}
        />
      ) : (
        <FilterPanel
          filters={props.filters}
          filterValidation={props.filterValidation}
          filterColumn={filterColumn}
          selectedPage={selectedPage}
          rowsPerPage={selectedRowPerPage}
          ordering={ordering}
          setParams={setParams}
          params={params}
          filterButtonsRight={filterButtonsRight}
          fetch={fetch}
          isTeamMenu={isTeamMenu}
          restAccessCode={restAccessCode}
          addButton={addButton}
          hideFilterButton={hideFilterButton}
          excludeParams={excludeParams}
        />
      )}
      <div className="row">
        <div className="col-12 px-0 px-md-2">
          <div className="card">
            <div className="card-body p-0">
              <div className="table-responsive">
                <table className="table table-centered table-nowrap mb-0">
                  <thead className="thead-light">
                    <tr>
                      {!hideEditButton ||
                      !hideRemoveButton ||
                      showDetailButton ||
                      (actions && actions.length > 0) ? (
                        <th
                          className="border-0"
                          style={{
                            width: "80px",
                            textAlign: "center",
                            paddingLeft: "18px",
                          }}
                        >
                          Action
                        </th>
                      ) : (
                        <></>
                      )}
                      <th
                        className="border-0"
                        style={{ width: "20px" }}
                      >
                        No
                      </th>
                      {columns.map((value, index) => {
                        let orderingHeader = <></>;

                        let canDoOrdering =
                          value["canDoOrdering"] === undefined
                            ? true
                            : value["canDoOrdering"];

                        let orderingParam =
                          value["sortId"] !== undefined
                            ? value["sortId"]
                            : value["id"];

                        if (
                          canDoOrdering &&
                          ordering === orderingParam
                        ) {
                          orderingHeader = (
                            <span>
                              <i className="fas fa-arrow-down" />
                            </span>
                          );
                        } else if (
                          canDoOrdering &&
                          ordering === "-" + orderingParam
                        ) {
                          orderingHeader = (
                            <span>
                              <i className="fas fa-arrow-up" />
                            </span>
                          );
                        }

                        let isValueHidden =
                          utils.access.isFieldHidden(
                            fieldAccesses,
                            restAccessCode + ".table." + value.id
                          );

                        return !isValueHidden ? (
                          <th
                            style={{
                              cursor: canDoOrdering
                                ? "pointer"
                                : "unset",
                              textAlign: value.center
                                ? "center"
                                : "left",
                            }}
                            onClick={() => {
                              if (
                                canDoOrdering &&
                                ordering !== orderingParam
                              ) {
                                fetch(
                                  selectedPage,
                                  selectedRowPerPage,
                                  orderingParam
                                );
                              } else if (canDoOrdering) {
                                fetch(
                                  selectedPage,
                                  selectedRowPerPage,
                                  "-" + orderingParam
                                );
                              }
                            }}
                            className="border-0"
                            key={"columns" + index}
                          >
                            {orderingHeader} {value["name"]}
                          </th>
                        ) : (
                          <></>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {isFetching ? (
                      <tr>
                        <td
                          colSpan={columns.length + 2}
                          style={{
                            padding: "5px 0px 0px 0px",
                          }}
                        >
                          <div
                            className="spinner-border m-2"
                            role="status"
                          >
                            <span className="sr-only">
                              Loading...
                            </span>
                          </div>
                        </td>
                      </tr>
                    ) : !isFetching &&
                      (isEmptyData ||
                        (errorMessage !== "" &&
                          errorMessage !== null)) ? (
                      <td
                        colSpan={columns.length + 2}
                        style={{
                          padding: "25px 0px 10px 15px",
                        }}
                      >
                        {errorMessage !== "" &&
                        errorMessage !== null ? (
                          <span
                            style={{ textDecoration: "underline" }}
                          >
                            {errorMessage}
                          </span>
                        ) : isEmptyData ? (
                          <span style={{ padding: "0px" }}>
                            There is no data
                          </span>
                        ) : (
                          ""
                        )}
                      </td>
                    ) : (
                      <></>
                    )}
                    {data &&
                      data.map((value, index) => {
                        const columnEntries = [];

                        for (let i = 0; i < columns.length; i++) {
                          let valueAltered = "???";

                          if (value[columns[i].id] !== undefined) {
                            valueAltered =
                              value[columns[i].id] !== "" &&
                              value[columns[i].id] !== null
                                ? String(value[columns[i].id])
                                : "-";
                          }

                          if (
                            columns[i].renderer &&
                            utils.commons.isFunction(
                              columns[i].renderer
                            )
                          ) {
                            valueAltered = columns[i].renderer(
                              valueAltered,
                              value,
                              constants?.[
                                columns[i].referStateFieldName
                              ]
                            );
                          }

                          /** new feature moved action button into title field */
                          if (
                            columns[i].enableHyperlink &&
                            accesses &&
                            user
                          ) {
                            if (
                              (accesses.includes(
                                restAccessCode + ".update"
                              ) ||
                                user["is_super_user"] ||
                                (isTeamMenu &&
                                  user[
                                    "has_team_management_access"
                                  ])) &&
                              !columns[i].forceView
                            ) {
                              valueAltered = `<a class='link-rukita' title='Click to see detail' style='color: #35b0a7;' href='${
                                location.pathname
                              }/edit/${
                                value["id"]
                              }' target='_blank'>${
                                value[columns[i].id]
                              }</a>`;
                            } else {
                              valueAltered = `<a class='link-rukita' title='Click to see detail' style='color: #35b0a7;' href='${
                                location.pathname
                              }/view/${
                                value["id"]
                              }' target='_blank'>${
                                value[columns[i].id]
                              }</a>`;
                            }
                          }

                          let isValueHidden =
                            utils.access.isFieldHidden(
                              fieldAccesses,
                              restAccessCode +
                                ".table." +
                                columns[i].id
                            );

                          if (isValueHidden) {
                            valueAltered = "-hidden-";
                          }

                          if (
                            !isValueHidden &&
                            columns[i].isSafeContent
                          ) {
                            columnEntries[i] = (
                              <td
                                key={"cell" + index + "_" + i}
                                className="border-0"
                                style={{
                                  textAlign: columns[i].center
                                    ? "center"
                                    : "left",
                                  whiteSpace: columns[i].wrap
                                    ? "pre-wrap"
                                    : "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  maxWidth:
                                    columns[i].maxWidthColumn ??
                                    "unset",
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: utils.commons.isFunction(
                                    cellRenderer
                                  )
                                    ? cellRenderer(
                                        index,
                                        i,
                                        valueAltered,
                                        value,
                                        props
                                      )
                                    : valueAltered,
                                }}
                              />
                            );
                          } else if (!isValueHidden) {
                            columnEntries[i] = (
                              <td
                                key={"cell" + index + "_" + i}
                                className="border-0"
                                style={{
                                  textAlign: columns[i].center
                                    ? "center"
                                    : "left",
                                  whiteSpace: columns[i].wrap
                                    ? "pre-wrap"
                                    : "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  maxWidth:
                                    columns[i].maxWidthColumn ??
                                    "unset",
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: utils.commons.isFunction(
                                    cellRenderer
                                  )
                                    ? cellRenderer(
                                        index,
                                        i,
                                        valueAltered,
                                        value,
                                        props
                                      )
                                    : valueAltered,
                                }}
                              ></td>
                            );
                          }
                        }

                        const actionEntries = [];

                        for (let i = 0; i < actions.length; i++) {
                          if (actions[i].type === "link") {
                            actionEntries[i] = (
                              <Link
                                key={"action" + index}
                                to={actions[i].path.replace(
                                  "{ID}",
                                  value.id
                                )}
                                className="dropdown-item"
                                style={{ cursor: "pointer" }}
                              >
                                {actions[i].icon &&
                                actions[i].icon !== "" ? (
                                  <i
                                    className={
                                      "mdi " +
                                      actions[i].icon +
                                      " mr-2 text-muted vertical-middle"
                                    }
                                  />
                                ) : (
                                  ""
                                )}
                                {actions[i].name}
                              </Link>
                            );
                          } else {
                            actionEntries[i] = (
                              <a
                                key={"action" + index}
                                onClick={() =>
                                  actions[i].action(value, props)
                                }
                                className="dropdown-item"
                                style={{ cursor: "pointer" }}
                              >
                                {actions[i].icon &&
                                actions[i].icon !== "" ? (
                                  <i
                                    className={
                                      "mdi " +
                                      actions[i].icon +
                                      " mr-2 text-muted vertical-middle"
                                    }
                                  />
                                ) : (
                                  ""
                                )}
                                {actions[i].name}
                              </a>
                            );
                          }
                        }

                        return (
                          <>
                            <tr
                              title={
                                hasSecondaryData
                                  ? "Click to " +
                                    (hasSecondaryData &&
                                    value["secondaryPanelVisible"]
                                      ? "close "
                                      : "open ") +
                                    "detail data."
                                  : "Row data."
                              }
                              style={{
                                cursor: hasSecondaryData
                                  ? "pointer"
                                  : "unset",
                              }}
                              onClick={(e) => {
                                if (
                                  !e.target.id.startsWith(
                                    "rowAction"
                                  ) &&
                                  e.target.id !==
                                    "rowActionIcon" + index
                                ) {
                                  if (
                                    value["secondaryPanelVisible"]
                                  ) {
                                    setData(() => {
                                      setData(
                                        [...data].map((object, i) => {
                                          if (i === index) {
                                            return {
                                              ...object,
                                              secondaryPanelVisible: false,
                                            };
                                          } else {
                                            return object;
                                          }
                                        })
                                      );
                                    });
                                  } else {
                                    if (value["secondaryPanelData"]) {
                                      setData(
                                        [...data].map((object, i) => {
                                          if (i === index) {
                                            return {
                                              ...object,
                                              secondaryPanelVisible: true,
                                            };
                                          } else {
                                            return object;
                                          }
                                        })
                                      );
                                    } else {
                                      fetchSecondaryData(
                                        value.id,
                                        index
                                      );
                                    }
                                  }
                                }
                              }}
                            >
                              {!hideEditButton ||
                              !hideRemoveButton ||
                              showDetailButton ||
                              (actions && actions.length > 0) ? (
                                <td
                                  className="border-0"
                                  style={{ textAlign: "center" }}
                                >
                                  <div className="btn-group dropright">
                                    <a
                                      data-toggle="dropdown"
                                      aria-expanded="true"
                                      id={"rowAction" + index}
                                      className="table-action-btn dropdown-toggle arrow-none btn btn-light btn-xs dropdown-table"
                                    >
                                      <i
                                        id={"rowActionIcon" + index}
                                        className="mdi mdi-dots-horizontal"
                                      />
                                    </a>
                                    <div className="dropdown-menu">
                                      {!hideEditButton ? (
                                        <Link
                                          id={"rowActionEdit" + index}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            if (
                                              accesses.includes(
                                                restAccessCode +
                                                  ".update"
                                              ) ||
                                              user["is_super_user"] ||
                                              (isTeamMenu &&
                                                user[
                                                  "has_team_management_access"
                                                ])
                                            ) {
                                              props.history.push(
                                                utils.commons.stripMultipleSlashes(
                                                  location.pathname +
                                                    "/edit/"
                                                ) + value.id
                                              );
                                            } else {
                                              sweetAlert.fire(
                                                "Error!",
                                                "You have no access to do this action.",
                                                "error"
                                              );
                                            }
                                          }}
                                          className="dropdown-item"
                                        >
                                          <i
                                            className={
                                              "mdi mr-2 text-muted vertical-middle" +
                                              (editAsDetail
                                                ? " mdi-eye"
                                                : " mdi-pencil")
                                            }
                                          />
                                          {!editAsDetail
                                            ? "Edit"
                                            : "Detail"}
                                        </Link>
                                      ) : (
                                        <></>
                                      )}
                                      {!hideRemoveButton ? (
                                        <a
                                          id={
                                            "rowActionRemove" + index
                                          }
                                          onClick={() => {
                                            if (
                                              accesses.includes(
                                                restAccessCode +
                                                  ".destroy"
                                              ) ||
                                              user["is_super_user"] ||
                                              (isTeamMenu &&
                                                user[
                                                  "has_team_management_access"
                                                ])
                                            ) {
                                              removeRecord(
                                                value.id,
                                                utils.commons.isFunction(
                                                  removeIdentifier
                                                )
                                                  ? removeIdentifier(
                                                      value
                                                    )
                                                  : removeIdentifier
                                                  ? value[
                                                      removeIdentifier
                                                    ]
                                                  : value.name
                                                  ? value.name
                                                  : removeIdentifierNo
                                                  ? `No ${index + 1}`
                                                  : value.id
                                              );
                                            } else {
                                              sweetAlert.fire(
                                                "Error!",
                                                "You have no access to do this action.",
                                                "error"
                                              );
                                            }
                                          }}
                                          className="dropdown-item"
                                          style={{
                                            cursor: "pointer",
                                          }}
                                        >
                                          <i className="mdi mdi-delete mr-2 text-muted vertical-middle" />
                                          Remove
                                        </a>
                                      ) : (
                                        <></>
                                      )}
                                      {showDetailButton ? (
                                        <Link
                                          id={
                                            "rowActionDetail" + index
                                          }
                                          to={
                                            location.pathname +
                                            "/" +
                                            value.id
                                          }
                                          className="dropdown-item"
                                        >
                                          <i className="mdi mdi-eye mr-2 text-muted vertical-middle" />
                                          Detail
                                        </Link>
                                      ) : (
                                        <></>
                                      )}
                                      {actionEntries}
                                    </div>
                                  </div>
                                </td>
                              ) : (
                                <></>
                              )}
                              <td
                                className="border-0"
                                style={{ textAlign: "center" }}
                              >
                                {rowOffset + index + 1}.{" "}
                              </td>
                              {columnEntries}
                            </tr>
                            {hasSecondaryData &&
                            value["secondaryPanelVisible"] ? (
                              <tr>
                                <td
                                  className="border-0"
                                  style={{
                                    padding: "10px 5px 10px 75px",
                                    backgroundColor: "#efe",
                                  }}
                                  colSpan={columns.length + 2}
                                >
                                  <div
                                    style={{
                                      padding: "0px 10px",
                                      height: () => {
                                        if (isMobile) {
                                          if (
                                            value[
                                              "secondaryPanelData"
                                            ]
                                          ) {
                                            return (
                                              secondaryDataItem *
                                                secondaryDataRowHeight +
                                              10 +
                                              "px"
                                            );
                                          } else {
                                            return "25px";
                                          }
                                        } else {
                                          if (
                                            value[
                                              "secondaryPanelData"
                                            ]
                                          ) {
                                            if (
                                              typeof secondaryDataRowHeight ===
                                              "number"
                                            )
                                              return (
                                                secondaryDataRow *
                                                  secondaryDataRowHeight +
                                                "px"
                                              );

                                            return secondaryDataRowHeight;
                                          } else {
                                            return "25px";
                                          }
                                        }
                                      },
                                    }}
                                  >
                                    {value["secondaryPanelError"] ||
                                    value["secondaryPanelData"] ? (
                                      <div>
                                        {value["secondaryPanelError"]
                                          ? value[
                                              "secondaryPanelError"
                                            ]
                                          : utils.commons.isFunction(
                                              secondaryDataHandler
                                            )
                                          ? secondaryDataHandler(
                                              utils.access.doAccessProtectionInSecondaryData(
                                                restAccessCode,
                                                fieldAccesses,
                                                value[
                                                  "secondaryPanelData"
                                                ]
                                              )
                                            )
                                          : "Handler not defined"}
                                      </div>
                                    ) : (
                                      <div
                                        className="spinner-border m-1"
                                        style={{
                                          width: "15px",
                                          height: "15px",
                                        }}
                                        role="status"
                                      >
                                        <span className="sr-only">
                                          Loading...
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            ) : (
                              <></>
                            )}
                            <tr>
                              <td
                                className="border-0"
                                colSpan={100}
                                style={{ padding: "0px" }}
                              >
                                <div
                                  style={{
                                    width: "100%",
                                    height: "2px",
                                    backgroundColor: "#f5f6f8",
                                  }}
                                >
                                  {" "}
                                </div>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              <Pagination
                totalRows={totalRows}
                selectedInitialRowsPerPage={
                  selectedInitialRowsPerPage
                }
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
                rowsPerPage={rowsPerPage}
                fetch={fetch}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(TableView);
