import { withRouter } from "react-router-dom";

import TabPane from "../../../../../../components/ubold/organisms/TabPane";
import TabPaneSection from "../../../../../../components/ubold/organisms/TabPaneSection";

import CHSelect from "components/ubold/molecules/forms/CHSelect";
import CHAsyncSelect from "components/ubold/molecules/forms/CHAsyncSelect";
import CHTextView from "components/ubold/molecules/forms/CHTextView";
import CHCheckBox from "components/ubold/molecules/forms/CHCheckBox";
import InvoiceTenantDetail from "../InvoiceDetails/index";
import CHMultiRowView from "components/ubold/molecules/forms/CHMultiRowView";
import CHSingleRow from "components/ubold/molecules/forms/CHSingleRow";
import utils from "../../../../../../utils";
import CHHiddenTextView from "components/ubold/molecules/forms/CHHiddenTextView";
import configs from "../../../../../../configs";

function Invoice(props: any) {
  const { id, definitions, tab, state, setState } = props;
  const tempDetail = state?.detail ? [...state?.detail] : [];
  if (tempDetail.length > 0) {
    tempDetail.forEach((detail) => {
      const period = Object.keys(detail)[0];
      const periodData = detail[period];
      state[`${period}-default`] = periodData;
    });
  }

  const generalKeys = state?.detail?.map(
    (tempDetail: any) => Object.keys(tempDetail)[0]
  );

  const renderPaymentLink = () => {
    if (
      state?.invoice_status !== 1 ||
      ![2, 4].includes(state?.invoice_type) ||
      state?.original___release_status !== 3 ||
      state?.payment_link?.status === 3 ||
      !state?.payment_link_url
    )
      return <></>;
    return (
      <CHHiddenTextView
        name="payment_link_url"
        title="Payment Link"
        toastMessage="Payment link copied to clipboard"
        allowedToShowHiddenText
        buttonTitle="Click to show"
        handleOnClick={() => {
          setTimeout(() => {
            const copiedText = document.getElementById(
              "CTextField-payment_link_url"
            ) as HTMLInputElement;
            copiedText.select();
            copiedText.setSelectionRange(0, 99999);
            navigator.clipboard.writeText(copiedText.value);
            utils.httpClient.get(
              configs.apiUrl +
                `finances_new/view-payment-link/${id}/`,
              () => {},
              () => {}
            );
          }, 1000);
        }}
      />
    );
  };

  return (
    <TabPane
      id={id}
      definitions={definitions}
      tab={tab}
      hideAddAnother
      state={state}
      setState={setState}
      readOnly={true}
    >
      <TabPaneSection style={{ padding: "25px 16px 0px" }}>
        <CHTextView title="Invoice Number" name="number" />
        <CHSelect
          name="invoice_type"
          title="Invoice Type"
          readOnly
          data={{ optionField: "finance_new_invoice_type" }}
        />

        <CHAsyncSelect
          name="order"
          title="Order ID"
          data="order_new/order?__type__=select_entries&with_related=1"
          isRequired
          itemsExtractor={(row: any) => {
            // set selected order from endpoint response
            if (state?.order === row.id) {
              setState((prev: any) => ({
                ...prev,
                is_migration_data: row.is_migration_data,
              }));
            }

            return {
              label: row.number,
              value: row.id,
              building_name: row.building__building_name,
              tenant_name: row.tenant__name,
              room_name: row.room__name,
              is_migration_data: row.is_migration_data,
            };
          }}
          afterChange={(value: any) => {
            setState((prevState: any) => ({
              ...prevState,
              building_name: value?.building_name,
              tenant_name: value?.tenant_name,
              room_name: value?.room_name,
              is_migration_data: value?.is_migration_data,
            }));
          }}
          readOnly
        />
        <CHTextView
          readOnly
          title="Started At"
          name="started_at"
          isRequired
          renderer={(value: any) => {
            return utils.formatter.date(value);
          }}
        />
        <CHTextView
          readOnly
          title="Ended At"
          name="ended_at"
          renderer={(value: any) => {
            return utils.formatter.date(value);
          }}
        />
        <CHTextView
          title="Building Name"
          name="building_name"
          readOnly
        />
        <CHTextView title="Room Name" name="room_name" readOnly />
        <CHTextView title="Tenant Name" name="tenant_name" readOnly />
        <CHTextView
          title="Grand Total"
          name="grand_total"
          renderer={(value: number) => {
            return utils.formatter.currency(value);
          }}
        />
        <CHSelect
          title="Release Status"
          name="release_status"
          data={{
            optionField: "finance_new_invoice_release_status",
          }}
          readOnly
        />
        <CHSelect
          title="Invoice Status"
          name="invoice_status"
          readOnly
          data={{
            optionField: "finance_new_invoice_status",
          }}
        />
        <CHCheckBox
          title="First Invoice"
          name="first_invoice"
          readOnly
        />
        {renderPaymentLink()}
      </TabPaneSection>
      <TabPaneSection accordionId="formAccordion">
        {generalKeys?.map((key: string, index: number) => {
          return (
            <InvoiceTenantDetail
              key={`${key}-${index}`}
              generalKey={key}
              accordionIndex={index}
            />
          );
        })}
      </TabPaneSection>
      {id &&
      state?.referal &&
      Object.keys(state?.referal).length > 0 ? (
        <CHMultiRowView
          data={state?.referal}
          title="Referral"
          isDeduction
        />
      ) : (
        <></>
      )}
      {id && state?.vouchers?.length > 0 ? (
        <CHMultiRowView
          data={state?.vouchers}
          title="Voucher"
          multi={true}
          isDeduction
        />
      ) : (
        <></>
      )}
      <CHSingleRow
        title="Grand Total"
        data={state?.grand_total}
        moreLeft
        titleWidth="57%"
      />
    </TabPane>
  );
}

export default withRouter(Invoice);
