import configBuilder from "../../../components/ubold/ConfigBuilder";

let definitions = {
  title: "Collections",
  frontendPath: "/website/building_collection/collection",
  baseLink: "/building/collection",
  restAccessCode: "building.collection",
  __table__: {
    filterColumn: 3,
    hideEditButton: true,
    filters: [
      {
        id: "name",
        name: "Name",
        type: "text",
      },
      {
        id: "slug",
        name: "Slug",
        type: "text",
      },
      {
        id: "published",
        name: "Is Published?",
        type: "select",
        data: [
          {
            label: "Yes",
            value: true,
          },
          {
            label: "No",
            value: false,
          },
        ],
      },
    ],
    columns: [
      {
        id: "name",
        name: "Name",
        enableHyperlink: true,
      },
      {
        id: "slug",
        name: "Slug",
      },
      {
        id: "published",
        name: "Is Published?",
        isSafeContent: true,
        renderer: (value) => {
          if (value === "true") {
            return '<span style="color: rgb(26, 188, 156);"> ✔ </span>';
          } else {
            return '<span style="color: red;">✘</span>';
          }
        },
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
