import { useState } from "react";

import { useParams, withRouter } from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import TabHeaders from "../../../../components/ubold/organisms/TabHeaders";
import TabContent from "../../../../components/ubold/organisms/TabContent";
import TabbedForm from "../../../../components/ubold/organisms/TabbedForm";

import Order from "./Tabs/Order";
import OrderDetails from "./Tabs/OrderDetails";
import HistoryStatusOrders from "./Tabs/HistoryStatusOrders";

import definitions from "./definitions";

function SalesOTPViewPage() {
  let { id }: any = useParams();

  const initialState = {};

  const [state, setState] = useState(initialState);

  return (
    <Page
      title={"Detail of" + " " + definitions.title}
      restAccessCode={definitions.restAccessCode}
    >
      <TabbedForm
        id={id}
        state={state}
        setState={setState}
        definitions={definitions}
      >
        <TabHeaders />
        <TabContent>
          <Order />
          <OrderDetails />
          <HistoryStatusOrders />
        </TabContent>
      </TabbedForm>
    </Page>
  );
}

export default withRouter(SalesOTPViewPage);
