import { RouteComponentProps } from "react-router-dom";
import React, { HTMLAttributes, ReactNode } from "react";
import { CSSProp } from "styled-components";
import { InputActionMeta } from "react-select";

export type IDataAuditTrailObject = {
  _index: string;
  _type: string;
  _id: string;
  _score: number | null;
  _source: {
    user: number;
    username: string;
    rest_access_code: string;
    action: "POST" | "PATCH" | "DELETE" | "GET";
    url: string;
    model_name: string;
    object: number | string;
    data_diff: {
      added?: string[] | [];
      changed?: string[] | [];
      deleted?: string[] | [];
    };
    old_value: string;
    new_value: string;
    date_created: string;
    menu: number;
    menu_name: string;
  };
  sort?: number[] | [];
};

export interface ICRMAuditTrailResponse {
  code: number;
  message: string;
  data: Array<IDataAuditTrailObject>;
}

export type OptionProps = {
  label: string;
  value: any;
};

export interface CustomWindowEventMap extends WindowEventMap {
  openmodal: MouseEvent;
  closmodal: MouseEvent;
}

export type fadeTypeProp = "in" | "out" | null;

export type filterBtnRightProps = {
  name: string;
  onClick: Function;
  style: string;
}[];

export type filterTypes = {
  id: string;
  name: string;
  type: string;
  append?: {
    atFirst: Array<{ label: string; value: number }>;
    atLast: Array<{ label: string; value: number }>;
  };
  mandatoryField?: boolean;
  data?: {
    label: string;
    value: number;
  }[];
  modeRange?: boolean;
  isMulti?: boolean;
  dependsOn?: string;
  notCached?: boolean;
  itemsExtractor?: (input: string) => {
    label: string;
    value: number;
  };
  isRequired?: boolean;
  hideSelectedOptions?: boolean;
  conditionalRenderIdentifier?: string | null;
  isCheckbox?: boolean;
  isClearable?: boolean;
  closeMenuOnSelect?: boolean;
  constantField?: string;
  listenParam?: string[];
  placeholder?: string | null;
  showClearDate?: boolean;
  minWidth?: number;
  isInputRequired?: boolean;
  onInputChange?: (
    newValue: string,
    actionMeta: InputActionMeta
  ) => void;
  customNoOptionMessage?: string;
};

export type ModalProps = {
  onClose: Function;
  isOpen: boolean;
  modalClass?: string;
  modalSize: number;
  headerType?: "close";
  headerTitle?: string;
  headerClass?: string;
  // setDeclineOpen?: Function;
  // setAcceptOpen?: Function;
  changeType?: number;
  css?: CSSProp;
  modalFooter?: JSX.Element[] | JSX.Element;
};

export type buildingPropertyListShape = {
  building_type: number;
  active: boolean;
  release_status: number;
  publish_status: number;
  internet_status: number;
  laundry: number;
  room_cleaning: number;
  parking_area: number;
  security: number;
  building_status: number;
  property_type: number;
  enable_online_payment: boolean;
  country: number;
  slug: string;
};

export type FormBuildingPropertyListProps = {
  id?: string;
  definitions?: Object;
  tab?: {
    id: string;
  };
  state?: buildingPropertyListShape;
  setState?: React.Dispatch<
    React.SetStateAction<buildingPropertyListShape>
  >;
} & RouteComponentProps;

export type sectionProps = {
  baseLink?: string;
  accordionId?: string;
  onSubmit?: Function;
  state?: { [key: string]: any };
  setState?: React.Dispatch<
    React.SetStateAction<{ [key: string]: any }>
  >;
  restAccessCode?: string;
} & RouteComponentProps;

export type genericTabProps<T> = {
  id?: string;
  definitions?: any;
  tab?: {
    id: string;
  };
  state?: T;
  setState?: React.Dispatch<React.SetStateAction<T>>;
} & RouteComponentProps;

export type ToastType = "success" | "warning" | "danger" | "info";

export type ToastShape = {
  id: number;
  description: string | number | ReactNode;
  dismissTime: number;
  type: ToastType;
  variant: "DEFAULT" | "FULL_WIDTH";
};

export type TextFieldType = {
  autoComplete?: string;
  defaultValue?: string | null;
  isCheckingValue?: boolean;
  isParkingAddon?: boolean;
  isValueAvailable?: boolean;
  maxLength?: number | null;
  min?: number;
  name?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  checkValueAvailability?: (name: string, value: string) => void;
  placeholder?: string;
  readOnly?: boolean;
  setErrorMessage?: any;
  setState?: any;
  state?: any;
  type?: string;
  useValueChecking?: boolean;
  validate?: any;
  valueIsValid?: boolean;
  value?: any;
};

export type UserRoleList = {
  created_at: Date | string | null;
  deleted_at: Date | string | null;
  deleted_by: string | number | null;
  id: number;
  is_deleted: boolean;
  is_personal_role: boolean;
  name: string;
  owner: number;
  update_by: string | number | null;
  updated_at: Date | string | null;
};

export type UserData = {
  email: string;
  first_name: string;
  has_team_management_access?: boolean;
  id: number;
  is_super_user?: boolean;
  last_name: string;
  username: string;
};

export interface IPasswordFieldProps
  extends HTMLAttributes<HTMLInputElement> {
  autoComplete?: string;
  defaultValue?: string | null;
  name?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  readOnly?: boolean;
  rules?: Array<{ error: boolean; text: string; visible: boolean }>;
  setState?: any;
  state?: any;
  type?: string;
  validate?: any;
}

export enum ModalNames {
  PROMPT_DISCARD_CHANGES = "prompt-discard-changes",
}

export type ModalTree = Record<
  ModalNames,
  {
    isShow: boolean;
    props?: Record<string, any>;
  }
>;

export type ModalTreeProviderShape = {
  modalTree: ModalTree;
  setModalTree: React.Dispatch<React.SetStateAction<ModalTree>>;
};

export type FilterPanelProps = {
  dateDependsOn?: string;
  dateDependent?: string;
  dateWithDefaultValue?: string;
  filters: filterTypes[];
  filterColumn: number;
  filterButtonsRight?: filterBtnRightProps;
  filterValidation?: Function;
  filterValidators?: any;
  isTeamMenu: boolean;
  restAccessCode: string;
  fetch?: (
    selectedPageParam: number,
    selectedRowPerPageParam: number,
    orderingParam?: string,
    paramsParam?: string
  ) => void;
  defaultState?: object;
  setParams: React.Dispatch<React.SetStateAction<string>>;
  rowsPerPage: number;
  ordering: string;
  selectedPage: number;
  addButton: boolean;
  isExportEnabled?: boolean;
  totalRows: number;
  isFetching: boolean;
  setData?: React.Dispatch<React.SetStateAction<any>>;
  setFetched?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsEmptyData?: React.Dispatch<React.SetStateAction<boolean>>;
  exportURL?: string;
  useOldResetFunc?: boolean;
  dataTableList?: any[];
  selectedRowPerPage?: number;
} & RouteComponentProps;

export interface IEmptyStateProps {
  textDescription?: string;
  textTitle?: string;
  imageSource?: string;
  isDashboard?: boolean;
  iconHeight?: number;
  iconWidth?: number;
}
export type SelectTypes = {
  afterChange?: any;
  cacheKey?: string | null;
  data?: any;
  defaultValue?: string | number | null;
  dependsOn?: string;
  dependsOnHandler?: any;
  extraOptions?: {
    atFirst?: Array<{ label: string; value: number }>;
    atLast: Array<{ label: string; value: number }>;
  };

  filterItems?: any;
  hideSelectedOption?: boolean;
  isDisabled?: boolean;
  isMulti?: boolean;
  isUniqueOption?: boolean;
  itemsExtractor?: any;
  name?: string;
  onChange?: () => void;
  readOnly?: boolean;
  setState?: Function;
  state?: any;
  stateComponent?: any;
  uniqueIdentifier?: any;
  isCheckbox?: boolean;
  isClearable?: boolean;
  defaultState?: any;
  resetIsTriggered?: { [key: string]: boolean };
  setResetIsTriggered?: React.Dispatch<React.SetStateAction<any>>;
  closeMenuOnSelect?: boolean;
  minWidth?: number;
  customStyles?: { [key: string]: object };
  disableMultiValue?: boolean;
  removeCloseIcon?: boolean;
};

export type RukiUIButtonVariant =
  | "PRIMARY"
  | "OUTLINED"
  | "TEXT"
  | "DANGER_PRIMARY"
  | "DANGER_OUTLINED"
  | "DANGER_TEXT";

export interface IDataOrderDetailObject {
  id: number;
  t_additional_discounts: any[];
  t_addon_buildings: any[];
  t_service_schedules: any[];
  date_ended: string;
  updated_by: string;
  number: string;
  full_paid: boolean;
  agreed_price: number;
  deposit: number;
  deposit_notes: string;
  price: number;
  status: number;
  date_started: string;
  date_checkout: string;
  reason_of_check_out: string;
  date_created: string;
  date_updated: string;
  discount_type: any;
  discount_notes: string;
  discount_amount: number;
  tenant_category: number;
  status_of_done: number;
  on_leave_paying: boolean;
  duplicated_order: boolean;
  signed_date: string;
  transfer_from: any;
  referral_code: any;
  is_deleted: boolean;
  date_deleted: any;
  deleted_by: any;
  rumanage_remarks: any;
  tenant: number;
  building: number;
  room_variant: number;
  room: number;
  plan: any;
  room_plan: any;
  payment_plan: number;
  discount: any;
  checkout_category: any;
  checkout_reason: any;
  signed_by_1: string;
  signed_by_2: string;
  signed_by_3: string;
  created_by: number;
  addon_building_order: any[];
}

export interface IDataTenantDetailObject {
  id: number;
  photo_original: any;
  id_card_photo_original: any;
  payment_receipt_original: any;
  last_login: string;
  created_by: any;
  updated_by: any;
  postal_code: any;
  source: any;
  source_register: string;
  country_code: string;
  phone_number: string;
  emergency_phone_number: any;
  emergency_relation: any;
  emergency_name: any;
  identity_id: any;
  identity_type: any;
  passport_id: any;
  organisation: any;
  job_title: any;
  other_contact_name: any;
  other_country_code: any;
  other_phone_number: any;
  marital_status: any;
  gender: number;
  income_level: any;
  religion: any;
  race: any;
  notes: string;
  name: string;
  username: string;
  dob: any;
  nickname: any;
  email: string;
  is_active: boolean;
  date_created: string;
  date_updated: string;
  first_login: boolean;
  on_leave_free_of_charge: boolean;
  otp: any;
  date_valid_otp: any;
  rukikoin: number;
  referral_code: string;
  quota_laundry: any;
  verified_email: boolean;
  verified_phone: boolean;
  is_deleted: boolean;
  date_deleted: any;
  deleted_by: any;
  latest_password_update: any;
  password_created_at: string;
  tenant_deposit: any;
  code: string;
  is_dummy: boolean;
  source_feature: any;
  reason_notes: any;
  address: any;
  country: any;
  province: any;
  city: any;
  district: any;
  subdistrict: any;
  job_group: number;
  job: any;
  organization: any;
  languages: any[];
  city_name: string;
}
export interface Addon {
  addon_building: number;
  start_date: string;
  end_date: string;
  status: number;
}

export interface Discount {
  discount: number;
  start_date: string;
  end_date: string;
  one_time: boolean;
}

export type PreviewOrderPayload = {
  tenant: number;
  tenant_category: number;
  building: number;
  room_variant: number;
  room: number;
  deposit_rules: number;
  subscription_type: number;
  checkin_date: string;
  checkout_date?: string;
  monthly_commitment: number;
  checkin_to_landlord?: string;
  price_from_landlord?: string;
  last_payment_to_landlord?: string;
  order_status: number;
  order_type: number;
  source: number;
  addons?: Addon[];
  discounts?: Discount[];
  referral_code?: string;
};

export interface IPreviewOrder_Response {
  status: number;
  message: string;
  data: IPreviewOrder_DataObject;
}

export interface IPreviewOrder_DataObject {
  invoice_details: IPreviewOrder_InvoiceDetail[];
  invoice_type: number;
  started_at: string;
  ended_at: string;
  building: string;
  room_name: string;
  tenant: string;
  grand_total: number;
  subscription_type: number;
  subscription_type_name: string;
  monthly_commitment: number;
  referral: {
    amount: number;
    referral: string | null;
  };
}

export interface IPreviewOrder_InvoiceDetail {
  [period: string]: IPreviewOrder_PeriodObject[];
}

export interface IPreviewOrder_PeriodObject {
  category: number;
  sub_category: any;
  item: string;
  item_id: number;
  price: number;
  start_date: string;
  end_date: string;
  period_start: string;
  period_end: string;
}

export interface GenerateBackdateInvoiceResponse {
  invoice_id: number;
  invoice_number: string;
}

export enum FieldOrderToBeRemoved {
  number = "number",
  depositForfeited = "deposit_forfeited",
  extendHoldInvoice = "extend_and_hold_invoice",
  splitDepositAndRent = "split_deposit_and_rent",
  checkoutDate = "checkout_date",
  shortStay = "short_stay",
  reasonCheckout = "reason_of_check_out",
  checkoutCategory = "checkout_category",
  checkoutReason = "checkout_reason",
  cancellationRemarks = "cancellation_remarks",
  estimateCheckoutDate = "estimated_checkout_date",
  checkinTime = "checkin_time",
  checkoutTime = "checkout_time",
  confirmCheckout = "confirm_checkout",
  noShow = "no_show",
  referralCode = "referral_code",
  rumanageRemarks = "rumanage_remarks",
  backdateCheckout = "backdate_checkout",
  isMigrationData = "is_migration_data",
  tDiscountOrders = "t_discount_orders",
  tAddonOrders = "t_addon_orders",
  tServiceSchedules = "t_service_schedules",
  tPriceUpdates = "t_price_updates",
  tDiscountOrdersInfoMsg = "t_discount_ordersInfoMessage",
  tAddonOrdersInfoMsg = "t_addon_ordersInfoMessage",
}

export enum FieldOrderToBeModified {
  subscriptionType = "subscription_type",
  statusOfDone = "status_of_done",
  orderStatus = "order_status",
  sourceOfOrder = "source",
  extendFromOrderId = "extend_from_order_id",
  extendFromOrderNumber = "extend_from_order_number",
  canExtendOrder = "can_extend_order",
  checkinDate = "checkin_date",
  orderType = "order_type",
  excludeDeposit = "exclude_deposit",
  dateCreated = "date_created",
  dateUpdated = "date_updated",
  createdBy = "created_by",
  lastPaymentToLandlord = "last_payment_to_landlord",
}

export interface WrapperProps {
  title: string;
  isRequired?: boolean;
  isReadOnly?: boolean;
  formColumn?: number;
  errorMessage?: string;
  helperMessage?: string;
  children?: ReactNode;
}

export interface BuildingRoom {
  id: number;
  name: string;
  price: number;
  is_available: boolean;
  room_status: number;
  room_status_notes: string;
  floor: number | null;
  maintenance_status: number;
  maintenance_date_ended: string | null;
  external_id: string;
  room_variant: number;
  room_variant_name: string;
  building_name: string;
  room_type_name: string;
  room_type: number;
}

export enum MediaType {
  Image = "IMAGE",
  Marzipano360 = "MARZIPANO_360",
  HLS = "HLS_M3U8",
}

export interface BuildingPhoto {
  id: number;
  building: number;
  created_by: string;
  date_created: string;
  date_deleted: string | null;
  date_updated: string;
  deleted_by: string | null;
  image_description: string | null;
  image_orignal: string;
  is_deleted: boolean;
  media_type: MediaType;
  order_no: number | null;
  thumbnail: string;
  updated_by: string;
}
