import { useState } from "react";
import { useParams, withRouter } from "react-router-dom";

import Page from "../../../../../components/ubold/organisms/Page";
import Form from "../../../../../components/ubold/organisms/Form";
import CVTextField from "../../../../../components/ubold/molecules/forms/CVTextField";
import definitions from "./definitions";

function CAddEditPage() {
  let { id }: any = useParams();

  const initialState = {};
  const [state, setState] = useState(initialState);

  return (
    <Page
      title={
        (id === undefined ? "Add New" : "Edit") +
        " " +
        definitions.title
      }
      restAccessCode={definitions.restAccessCode}
    >
      <Form
        id={id}
        definitions={definitions}
        state={state}
        setState={setState}
      >
        <CVTextField name="name" title="Name ID" isRequired />
        <CVTextField name="name_en" title="Name EN" isRequired />
      </Form>
    </Page>
  );
}

export default withRouter(CAddEditPage);
