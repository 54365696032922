import React, { HTMLAttributes } from "react";

import NavItem from "./NavItem";

import {
  DefaultNavMenuContainer,
  Divider,
  LeftPanelContainer,
  NavigationMenus,
  RightPanelContainer,
} from "./VerticalTabsWithPanel.css";
import useModal from "components/global/Modal/useModal";
import GeneralModalBodyTemplate from "components/global/Modal/GeneralModalBodyTemplate";

interface IVerticalTabsWithPanelProps
  extends HTMLAttributes<HTMLDivElement> {
  addOnsMenus?: React.ReactElement;
  menus: Array<{
    id: string;
    icon: {
      active: string;
      main: string;
    };
    name: string;
  }>;
  onClickTab?: (id: string) => void;
  panels: Array<{ id: string; element: React.ReactElement }>;
  section?: {
    head?: React.ReactElement;
  };
  state: string;
  isFormProfileDirty?: boolean;
  isFormPasswordDirty?: boolean;
}

const VerticalTabsWithPanel: React.FC<
  IVerticalTabsWithPanelProps
> = ({
  addOnsMenus,
  menus,
  onClickTab,
  panels,
  section,
  state,
  isFormProfileDirty,
  isFormPasswordDirty,
  ...props
}) => {
  const { handleModal } = useModal();

  const handleNavigation = (id: string, menu: string) => {
    if (
      state !== menu &&
      (isFormProfileDirty || isFormPasswordDirty)
    ) {
      handleModal(
        "OPEN",
        <GeneralModalBodyTemplate
          render={
            <p>
              You have unsaved changes. Are you sure you want to
              leave? Unsaved changes may be lost.
            </p>
          }
        />,
        {
          btnLeft: {
            onClick: () => {
              handleModal("CLOSE");
              onClickTab(id);
            },
            text: "Yes, Leave This Page",
          },
          btnRight: {
            onClick: () => handleModal("CLOSE"),
            text: "No, Stay on This Page",
          },
          title: "Unsaved Changes",
        }
      );
    } else {
      onClickTab(id);
    }
  };

  return (
    <Divider {...props}>
      <LeftPanelContainer>
        <DefaultNavMenuContainer>
          {section?.head}

          <NavigationMenus>
            {menus.map((menu) => (
              <NavItem
                key={menu.id}
                id={menu.id}
                imgSrc={menu.icon.main}
                imgSrcActive={menu.icon.active}
                title={menu.name}
                isActive={state === menu.id}
                onClick={(id) => handleNavigation(id, menu.id)}
              />
            ))}

            {addOnsMenus}
          </NavigationMenus>
        </DefaultNavMenuContainer>
      </LeftPanelContainer>

      <RightPanelContainer>
        {panels.map((panel) => state === panel.id && panel.element)}
      </RightPanelContainer>
    </Divider>
  );
};

export default VerticalTabsWithPanel;
