import { withRouter } from "react-router-dom";

import TabPane from "../../../../../../components/ubold/organisms/TabPane";
import TabPaneSection from "../../../../../../components/ubold/organisms/TabPaneSection";

import CHSelect from "../../../../../../components/ubold/molecules/forms/CHSelect";
import CHTextView from "../../../../../../components/ubold/molecules/forms/CHTextView";

import utils from "../../../../../../utils";

function Building(props) {
  const { id, definitions, tab, state, setState } = props;

  return (
    <TabPane
      id={id}
      definitions={definitions}
      isHideAddAnother={true}
      removedDuplicateFields={["number"]}
      tab={tab}
      state={state}
      setState={setState}
    >
      <TabPaneSection style={{ padding: "25px 16px 0px" }}>
        <CHTextView name="number" title="Order ID" />
        <CHTextView name="tenant_text" title="Tenant" />
        <CHSelect
          name="otp_status"
          title="Status"
          isRequired
          data={{ optionField: "otp_order_status" }}
          afterChange={() => {
            setState((prevState) => ({
              ...prevState,
              statusEdited: true,
            }));
          }}
        />
        <CHTextView name="courier_text" title="Courier" />
        <CHTextView
          name="date_created"
          title="Date Created"
          renderer={(value) => {
            return utils.formatter.dateTime(value);
          }}
        />
        <CHTextView
          name="date_updated"
          title="Date Updated"
          renderer={(value) => {
            return utils.formatter.dateTime(value);
          }}
        />
      </TabPaneSection>
    </TabPane>
  );
}

export default withRouter(Building);
