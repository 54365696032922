import { useEffect, useState } from "react";

import { useLocation, withRouter } from "react-router-dom";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import Pagination from "../../molecules/tables/Pagination";
import FilterPanel from "../../molecules/tables/FilterPanel";
import FilterPanelNew from "components/ubold/molecules/tables/FilterPanelNew";

import configs from "../../../../configs";
import utils from "../../../../utils";
import useGlobalState from "../../../../state";
import BaseTableResponsive from "./BaseTableResponsive";

function TableView(props) {
  const {
    path,
    rowsPerPage = [10, 20, 30, 40, 50],
    filterButtonsRight = [],
    isTeamMenu = false,
    selectedInitialRowsPerPage,
    restAccessCode,
    hasSecondaryData = false,
    secondaryDataRow = 1,
    secondaryDataItem = 5,
    secondaryDataRowHeight = 50,
    secondaryDataHandler = null,
    removeIdentifier,
    editAsDetail = false,
    columns = [],
    actions = [],
    cellRenderer = null,
    defaultOrdering = null,
    filterColumn = 2,
    isAddButtonDisabled,
    hideEditButton = false,
    hideColumnNumbering = false,
    isHideRemoveButton,
    showDetailButton = false,
    freezeColumns,
    freezeTableMinWidth,
    isUseNewFilter = false,
    isExportEnabled = false,
    exportEndpointURL,
  } = props;

  const findFiftyRows = rowsPerPage.findIndex(
    (limit) => limit === 50
  );
  const [totalRows, setTotalRows] = useState(1);
  const [data, setData] = useState([]);
  const [rowOffset, setRowOffset] = useState(0);
  const [ordering, setOrdering] = useState("-id");
  const [params, setParams] = useState("");
  const [isFetched, setFetched] = useState(false);

  /** action button configuration */
  const addButton = !isAddButtonDisabled ? true : false;
  const hideRemoveButton = isHideRemoveButton
    ? isHideRemoveButton
    : false;

  const [isFetching, setIsFetching] = useState(
    props.isDisableInitialLoad ? false : true
  );
  const [isEmptyData, setIsEmptyData] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const [selectedPage, setSelectedPage] = useState(1);
  const [selectedRowPerPage, setSelectedRowPerPage] = useState(
    selectedInitialRowsPerPage
      ? rowsPerPage[selectedInitialRowsPerPage]
      : rowsPerPage[0]
  );

  const [user] = useGlobalState("user");
  const [fieldAccesses] = useGlobalState("field_accesses");
  const [accesses] = useGlobalState("accesses");

  const sweetAlert = withReactContent(Swal);

  const location = useLocation();

  const fetch = (
    selectedPageParam,
    selectedRowPerPageParam,
    orderingParam = null,
    paramsParam = null
  ) => {
    setData([]);
    setIsFetching(true);
    setErrorMessage(null);

    setSelectedPage(selectedPageParam);
    setSelectedRowPerPage(selectedRowPerPageParam);

    if (orderingParam !== null) {
      setOrdering(orderingParam);
    }

    const usedParams = paramsParam === null ? params : paramsParam;

    utils.httpClient.get(
      configs.apiUrl +
        path +
        "?" +
        (usedParams !== "" ? usedParams + "&" : usedParams) +
        "ordering=" +
        (orderingParam !== null ? orderingParam : ordering) +
        "&page=" +
        selectedPageParam +
        "&limit=" +
        selectedRowPerPageParam,
      (response) => {
        setRowOffset(
          (selectedPageParam - 1) * selectedRowPerPageParam
        );
        setTotalRows(response.data.count);
        setData(response.data.rows);

        if (
          response.data.rows === null ||
          response.data.rows.length === 0
        ) {
          setIsEmptyData(true);
        } else {
          setIsEmptyData(false);
        }

        setIsFetching(false);
        setFetched(true);

        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      },
      (error, message) => {
        if (
          error.response &&
          (error.response.status === 401 ||
            error.response.status === 400)
        ) {
          const nextPath = window.location.pathname;

          utils.store.remove("t");

          props.history.push("/logout?next=" + nextPath);
        } else {
          setIsFetching(false);
          setData([]);

          let messageError =
            error.response &&
            error.response.data &&
            utils.commons.isString(error.response.data)
              ? error.response.data
                  .split("\n")[1]
                  .replace("['", "")
                  .replace("']", "")
              : message;

          setErrorMessage(
            messageError && messageError !== ""
              ? messageError
              : "Unknown"
          );
        }
      }
    );
  };

  useEffect(() => {
    if (props.isDisableInitialLoad) {
      setSelectedRowPerPage(
        rowsPerPage[findFiftyRows > -1 ? findFiftyRows : 0]
      );
    } else {
      fetch(
        1,
        selectedInitialRowsPerPage
          ? rowsPerPage[selectedInitialRowsPerPage]
          : rowsPerPage[findFiftyRows > -1 ? findFiftyRows : 0],
        defaultOrdering
      );
    }
  }, []);

  return (
    <div>
      {isUseNewFilter ? (
        <FilterPanelNew
          filters={props.filters}
          filterValidation={props.filterValidation}
          filterColumn={filterColumn}
          selectedPage={selectedPage}
          rowsPerPage={selectedRowPerPage}
          ordering={ordering}
          setParams={setParams}
          params={params}
          filterButtonsRight={filterButtonsRight}
          fetch={fetch}
          isTeamMenu={isTeamMenu}
          restAccessCode={restAccessCode}
          addButton={addButton}
          isExportEnabled={isExportEnabled}
          totalRows={totalRows}
          isFetching={isFetching}
          setData={setData}
          setFetched={setFetched}
          setIsEmptyData={setIsEmptyData}
          exportURL={exportEndpointURL}
        />
      ) : (
        <FilterPanel
          filters={props.filters}
          filterValidation={props.filterValidation}
          filterColumn={filterColumn}
          selectedPage={selectedPage}
          rowsPerPage={selectedRowPerPage}
          ordering={ordering}
          setParams={setParams}
          params={params}
          filterButtonsRight={filterButtonsRight}
          fetch={fetch}
          isTeamMenu={isTeamMenu}
          restAccessCode={restAccessCode}
          addButton={addButton}
        />
      )}

      <div className="row">
        <div className="col-12 px-0 px-md-2">
          <div className="card">
            <div className="card-body p-0">
              <div
                className="d-flex"
                style={{
                  maxHeight: "65vh",
                  overflow: "auto",
                  width: "100%",
                }}
              >
                <BaseTableResponsive
                  idTable="primary"
                  actions={actions}
                  accesses={accesses}
                  cellRenderer={cellRenderer}
                  columns={columns}
                  columnsHead={columns.slice(0, freezeColumns)}
                  columnsContentEnd={freezeColumns}
                  data={data}
                  editAsDetail={editAsDetail}
                  errorMessage={errorMessage}
                  fieldAccesses={fieldAccesses}
                  hasSecondaryData={hasSecondaryData}
                  hideColumnNumbering={hideColumnNumbering}
                  hideEditButton={hideEditButton}
                  hideRemoveButton={hideRemoveButton}
                  isEmptyData={isEmptyData}
                  isTeamMenu={isTeamMenu}
                  isFetching={isFetching}
                  location={location}
                  ordering={ordering}
                  path={path}
                  removeIdentifier={removeIdentifier}
                  restAccessCode={restAccessCode}
                  rowOffset={rowOffset}
                  secondaryDataHandler={secondaryDataHandler}
                  secondaryDataItem={secondaryDataItem}
                  secondaryDataRow={secondaryDataRow}
                  secondaryDataRowHeight={secondaryDataRowHeight}
                  selectedPage={selectedPage}
                  selectedRowPerPage={selectedRowPerPage}
                  setData={setData}
                  showDetailButton={showDetailButton}
                  style={{
                    backgroundColor: "#fff",
                    minWidth: freezeTableMinWidth,
                    zIndex: 2,
                  }}
                  sweetAlert={sweetAlert}
                  user={user}
                  isFetched={isFetched}
                  fetch={fetch}
                />

                <BaseTableResponsive
                  idTable="secondary"
                  actions={actions}
                  accesses={accesses}
                  cellRenderer={cellRenderer}
                  columns={columns}
                  columnsHead={columns.slice(freezeColumns)}
                  columnsContentStart={freezeColumns}
                  columnsContentEnd={columns.length}
                  data={data}
                  editAsDetail={editAsDetail}
                  errorMessage={errorMessage}
                  fieldAccesses={fieldAccesses}
                  hasSecondaryData={hasSecondaryData}
                  hideColumnNumbering={true}
                  hideEditButton={hideEditButton}
                  hideRemoveButton={hideRemoveButton}
                  isEmptyData={isEmptyData}
                  isTeamMenu={isTeamMenu}
                  isFetching={isFetching}
                  location={location}
                  ordering={ordering}
                  path={path}
                  removeIdentifier={removeIdentifier}
                  restAccessCode={restAccessCode}
                  rowOffset={rowOffset}
                  secondaryDataHandler={secondaryDataHandler}
                  secondaryDataItem={secondaryDataItem}
                  secondaryDataRow={secondaryDataRow}
                  secondaryDataRowHeight={secondaryDataRowHeight}
                  selectedPage={selectedPage}
                  selectedRowPerPage={selectedRowPerPage}
                  setData={setData}
                  showDetailButton={showDetailButton}
                  showLoadingSpinner={false}
                  style={{ zIndex: 0 }}
                  sweetAlert={sweetAlert}
                  user={user}
                  isFetched={isFetched}
                  fetch={fetch}
                />
              </div>

              <Pagination
                totalRows={totalRows}
                selectedInitialRowsPerPage={
                  selectedInitialRowsPerPage
                }
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
                rowsPerPage={rowsPerPage}
                fetch={fetch}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(TableView);
