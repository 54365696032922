import {useParams, withRouter} from "react-router-dom";

import TabPaneSecondary from "../../../../../../components/ubold/organisms/TabPaneSecondary";

import COneOrManyToManyField from "../../../../../../components/ubold/molecules/forms/COneOrManyToManyField";

import utils from "../../../../../../utils";

function RequestLog(props) {
    const {definitions, tab, state, setState} = props;

    let {id} = useParams();

    return (
        <TabPaneSecondary
            definitions={definitions}
            restAccessCodeNamespace='request_log'
            tab={tab}
            state={state}
            setState={setState}>
            <COneOrManyToManyField
                id={id}
                name={tab.id}
                readOnly
                restPath='order/service_request_log'
                relationKey='service_request_id'
                columns={[
                    {
                        name: 'date_change',
                        title: 'Date Change',
                        type: 'read_only',
                        renderer: value => {
                            return utils.formatter.dateTime(value)
                        }
                    },
                    {
                        name: 'user',
                        title: 'User',
                        type: 'read_only',
                    },
                    {
                        name: 'role',
                        title: 'Role',
                        type: 'read_only',
                    },
                    {
                        name: 'status_from',
                        title: 'Status From',
                        type: 'read_only',
                    },
                    {
                        name: 'status_to',
                        title: 'Status To',
                        type: 'read_only',
                    },
                    {
                        name: 'notes',
                        title: 'Notes',
                        type: 'read_only',
                    },
                ]}
            />
        </TabPaneSecondary>
    );
}

export default withRouter(RequestLog);