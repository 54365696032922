import React from "react";
import Page from "../../../../components/ubold/organisms/Page";
import TableHistory from "../../../../components/ubold/organisms/TableHistory/TableHistory";

import definitions from "./definitions";

const RoomTypeHistory: React.FC = (props) => {
  return (
    <Page title={"Room Type History"}>
      <TableHistory {...definitions} />
    </Page>
  );
};

export default RoomTypeHistory;
