import { useParams, withRouter } from "react-router-dom";

import TabPaneSecondary from "../../../../../../components/ubold/organisms/TabPaneSecondary";

import COneOrManyToManyField from "../../../../../../components/ubold/molecules/forms/COneOrManyToManyField";
import { genericTabProps } from "@types";

function Photo(props: genericTabProps<Object>) {
  const { definitions, tab, state, setState } = props;

  let { id } = useParams<{ id: string }>();

  return (
    <TabPaneSecondary
      definitions={definitions}
      restAccessCodeNamespace="photo"
      tab={tab}
      state={state}
      setState={setState}
      readOnly
    >
      <COneOrManyToManyField
        readOnly
        id={id}
        name={tab.id}
        restPath="building/place_group_photo/"
        relationKey="place_group_id"
        photoFields={["photo_original"]}
        columns={[
          {
            name: "name",
            title: "Name",
            type: "text",
            maxLength: 30,
            disabledAtEdit: true,
          },
          {
            name: "photo_original",
            title: "Photo",
            type: "photo_upload",
            isRequired: true,
            disabledAtEdit: true,
          },
        ]}
      />
    </TabPaneSecondary>
  );
}

export default withRouter(Photo);
