import configBuilder from "../../../../components/ubold/ConfigBuilder";

import pageDefinitions from "../definitions";

import utils from "../../../../utils";

const definitions = {
  ...pageDefinitions,
  tabs: [
    {
      id: "facility",
      name: "Facility",
    },
    {
      id: "t_properties",
      name: "Properties",
    },
  ],
  validators: {
    name: (value: string) => {
      return utils.validator.validateRequired(value);
    },
  },
};

export default configBuilder.buildAddEditPageConfig(definitions);
