import { useState } from "react";

import { useParams, withRouter } from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";
import Form from "../../../../components/ubold/organisms/Form";

import CVTextField from "../../../../components/ubold/molecules/forms/CVTextField";
import CVTextArea from "../../../../components/ubold/molecules/forms/CVTextArea";
import CVAsyncSelect from "../../../../components/ubold/molecules/forms/CVAsyncSelect";

import definitions from "./definitions";
import CVCheckBox from "components/ubold/molecules/forms/CVCheckBox";

function FieldAccessAddEditPage() {
  let { id } = useParams();

  const initialState = {};

  const [state, setState] = useState(initialState);

  return (
    <Page
      title={
        (id === undefined ? "Add New" : "Edit") +
        " " +
        definitions.title
      }
      restAccessCode={definitions.restAccessCode}
    >
      <Form
        id={id}
        definitions={definitions}
        state={state}
        setState={setState}
      >
        <CVTextField
          title="Code"
          name="code"
          isRequired
          maxLength={150}
          helperText="Required. 150 characters or fewer. Letters, digits and ./_ only."
        />
        <CVAsyncSelect
          title="Menu"
          name="menu"
          data="crm/menu?__type__=select_entries"
          isRequired
        />
        <CVTextArea
          title="Description"
          name="description"
          maxLength={255}
        />
        <CVCheckBox
          title="Is Reverse Policy?"
          name="is_reverse_policy"
        />
      </Form>
    </Page>
  );
}

export default withRouter(FieldAccessAddEditPage);
