import configBuilder from "../../../components/ubold/ConfigBuilder";

let definitions = {
  title: "Countries",
  frontendPath: "/admin/location/country",
  baseLink: "/place/country",
  restAccessCode: "place.country",
  __table__: {
    hideEditButton: true,
    filterColumn: 1,
    filters: [
      {
        id: "name",
        name: "Name",
        type: "text",
      },
    ],
    columns: [
      {
        id: "name",
        name: "Name",
        enableHyperlink: true,
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
