import configBuilder from "../../../components/ubold/ConfigBuilder";
import utils from "../../../utils";

let definitions = {
  title: "Popular Locations",
  frontendPath: "/website/popular_location",
  baseLink: "/building/popular_location",
  restAccessCode: "building.popular_location",
  __table__: {
    filterColumn: 2,
    addButton: false,
    hideRemoveButton: true,
    filters: [
      {
        id: "date_updated",
        name: "Date Updated",
        type: "date",
      },
      {
        id: "date_created",
        name: "Date Created",
        type: "date",
      },
    ],
    columns: [
      {
        id: "id",
        canDoOrdering: false,
        name: "Popular Location",
        enableHyperlink: true,
        // renderer: () => {
        //     return 'Popular Locations';
        // }
      },
      {
        id: "date_updated",
        name: "Date Updated",
        renderer: (value) => {
          return utils.formatter.dateTime(value);
        },
      },
      {
        id: "date_created",
        name: "Date Created",
        renderer: (value) => {
          return utils.formatter.dateTime(value);
        },
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
