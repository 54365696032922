import { useEffect, useState } from "react";

import { withRouter } from "react-router-dom";

function CFileUpload(props) {
  const {
    name,
    state,
    setState,
    enableDownload,
    onChange,
    readOnly,
  } = props;

  const [files, setFiles] = useState([]);

  const handleOnChangeFile = (event) => {
    const acceptedFile = event.target.files[0];
    setFiles(acceptedFile);

    setState((prevState) => ({
      ...prevState,
      [name]: acceptedFile,
    }));
  };

  useEffect(() => {
    if (state[name]) {
      setFiles(state[name]);
    }
  }, [state[name]]);

  const isDisabled = state?.history_has_published ? true : false;

  return (
    <div>
      <input
        type="file"
        id="fileSelect"
        accept=".xlsx, .xls, .csv, .pdf"
        onChange={onChange ? onChange : handleOnChangeFile}
        disabled={readOnly || isDisabled}
      />
      <div>
        <label>
          Currently:{" "}
          {state[name] && typeof state[name] === "string" ? (
            <a href={state[name]}>{state[name]}</a>
          ) : files?.name ? (
            files.name
          ) : (
            ""
          )}
        </label>
      </div>
      <div>
        {enableDownload && (
          <a href="https://rukita.s3.ap-southeast-1.amazonaws.com/templates/rukita_template_voucher_code.xlsx">
            Download Template File Excel Here!
          </a>
        )}
      </div>
    </div>
  );
}

export default withRouter(CFileUpload);
