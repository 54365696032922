import React from "react";
import styled from "styled-components/macro";

interface UserInfoBoardProps {
  username: string;
  title: string;
}

const UserPanel = (props: UserInfoBoardProps) => {
  const { username, title } = props;
  return (
    <Container>
      <IconContainer>
        <Icon>{username ?? "U"}</Icon>
      </IconContainer>
      <TitleContainer>{title}</TitleContainer>
    </Container>
  );
};

export default UserPanel;

const Container = styled.div`
  display: grid;
  grid-gap: 8px;
  grid-template-rows: 1fr auto;
  border-bottom: 1px solid #e7e7e7;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Icon = styled.div`
  background: #d9efe5;
  border-radius: 50%;
  color: #222222;
  font-size: 24px;
  font-weight: 300;
  width: 56px;
  height: 56px;
  line-height: 2.1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TitleContainer = styled.div`
  text-align: center;
  margin-bottom: 16px;
`;
