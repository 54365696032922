import { useParams, withRouter } from "react-router-dom";

import TabPaneSecondary from "../../../../../../components/ubold/organisms/TabPaneSecondary";

import COneOrManyToManyField from "../../../../../../components/ubold/molecules/forms/COneOrManyToManyField";

function SecondTab(props: any) {
  const { definitions, tab, state, setState } = props;

  let { id }: { id: string } = useParams();

  return (
    <TabPaneSecondary
      definitions={definitions}
      restAccessCodeNamespace="attendance"
      tab={tab}
      state={state}
      setState={setState}
    >
      <COneOrManyToManyField
        id={id}
        name={tab.id}
        readOnly
        restPath="event/event_attendance/"
        relationKey="event_id"
        columns={[
          {
            name: "tenant",
            title: "Tenant",
            type: "async_select",
            isUniqueOption: true,
            disabledAtEdit: true,
            data: "user/tenant?__type__=select_entries",
            isRequired: true,
            isReadOnly: true,
          },
          {
            name: "signature_id",
            title: "Signature ID",
            maxLength: 254,
            type: "text",
            isReadOnly: true,
          },
          {
            name: "status",
            title: "Status",
            type: "select",
            data: [
              {
                label: "Register",
                value: 1,
              },
              {
                label: "Waiting List",
                value: 2,
              },
              {
                label: "Cancel",
                value: 3,
              },
            ],
            isReadOnly: true,
          },
        ]}
      />
    </TabPaneSecondary>
  );
}

export default withRouter(SecondTab);
