import { useState, useEffect } from "react";
import { useParams, withRouter } from "react-router-dom";

import TabPaneSecondary from "../../../../../../components/ubold/organisms/TabPaneSecondary";

import COneOrManyToManyField from "../../../../../../components/ubold/molecules/forms/COneOrManyToManyField";

import utils from "../../../../../../utils";

function OrderAddonBuildings(props) {
  const { definitions, tab, state, setState } = props;

  const [addonList, setAddonList] = useState([]);

  let { id } = useParams();

  const doneStatus = [4, 5, 6];

  const buildingParam = state["building"]
    ? `&building=${state["building"]}`
    : `&building=0`;

  useEffect(() => {
    if (!state["t_addon_buildings"]) return;
    setAddonList(state["t_addon_buildings"]);
  }, [state["t_addon_buildings"]]);

  const generateRowLabel = (rowName, rowState) => {
    return {
      label:
        rowName +
        (rowState["price"]
          ? " (" +
            (rowState["payment_plan"]
              ? rowState["payment_plan"]
              : "1") +
            " month) - " +
            utils.formatter.currency(rowState["price"])
          : ""),
      value: rowState.id,
    };
  };

  return (
    <TabPaneSecondary
      definitions={definitions}
      tab={tab}
      restAccessCodeNamespace="addon_building"
      state={state}
      setState={setState}
    >
      <COneOrManyToManyField
        id={id}
        name={tab.id}
        readOnly={id && doneStatus.includes(state["status"])}
        restPath="order/addon_building__order/"
        relationKey="order_id"
        saveOnlyFromMainForm={true}
        index={2}
        enableListener={true}
        columns={[
          {
            name: "addon_building",
            type: "async_select",
            disabledAtEdit: true,
            isUniqueOption: true,
            title: "Addon Building",
            isRequired: true,
            requireField: "building",
            data: `building/addon__building?__type__=select_entries&limit=1000${buildingParam}`,
            itemsExtractor: (row) => {
              if (row.name) {
                return generateRowLabel(row.name, row);
              } else if (row.addon__name) {
                return generateRowLabel(row.addon__name, row);
              }
            },
          },
          {
            name: "notes",
            type: "custom",
            title: "Notes",
            addonList: addonList,
          },
          {
            name: "number",
            type: "read_only",
            maxLength: 13,
            title: "Addon ID",
          },
          {
            name: "status",
            type: "select",
            title: "Status",
            isRequired: true,
            data: {
              optionField: "order_addon_building_order_status",
            },
          },
          {
            name: "start_date",
            type: "date",
            title: "Start Date",
            isRequired: true,
          },
          {
            name: "end_date",
            type: "date",
            title: "End Date",
          },
        ]}
      />
    </TabPaneSecondary>
  );
}

export default withRouter(OrderAddonBuildings);
