import { useState } from "react";

import { useParams, withRouter } from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import TabHeaders from "../../../../components/ubold/organisms/TabHeaders";
import TabContent from "../../../../components/ubold/organisms/TabContent";
import TabbedForm from "../../../../components/ubold/organisms/TabbedForm";

import RoomPhotos from "./Tabs/RoomPhotos";
import RoomFacilities from "./Tabs/RoomFacilities";
import RoomFeatures from "./Tabs/RoomFeatures";
import Room from "./Tabs/Room";

import definitions from "./definitions";
import RoomServiceSchedule from "./Tabs/RoomServiceSchedule";

function RoomAddEditPage() {
  let { id } = useParams<{ id: string }>();

  const initialState: any = {
    is_available: true,
    price: 0,
    electricity: 1,
    bathroom: 1,
    gender: 0,
    room_status: 10,
    t_room_service_schedule: [
      {
        laundry_schedule: [],
        cleaning_schedule: [],
      },
    ],
  };

  const [state, setState] = useState(initialState);

  return (
    <Page
      title={
        (id === undefined ? "Add New" : "Edit") +
        " " +
        definitions.title
      }
      restAccessCode={definitions.restAccessCode}
    >
      <TabbedForm
        id={id}
        state={state}
        setState={setState}
        definitions={definitions}
      >
        <TabHeaders />
        <TabContent>
          <Room />
          <RoomPhotos />
          <RoomFacilities />
          <RoomFeatures />
          <RoomServiceSchedule />
        </TabContent>
      </TabbedForm>
    </Page>
  );
}

export default withRouter(RoomAddEditPage);
