import { withRouter } from "react-router-dom";

import TabPane from "../../../../../../components/ubold/organisms/TabPane";
import TabPaneSection from "../../../../../../components/ubold/organisms/TabPaneSection";

import CHTextField from "../../../../../../components/ubold/molecules/forms/CHTextField";
import CHSelect from "../../../../../../components/ubold/molecules/forms/CHSelect";
import CHAsyncSelect from "../../../../../../components/ubold/molecules/forms/CHAsyncSelect";
import CHRichTextField from "../../../../../../components/ubold/molecules/forms/CHRichTextField";
import CHCheckBox from "../../../../../../components/ubold/molecules/forms/CHCheckBox";
import CHDateTimePicker from "../../../../../../components/ubold/molecules/forms/CHDateTimePicker";
import CHTextView from "../../../../../../components/ubold/molecules/forms/CHTextView";

import Information from "./Sections/Information";
import Rent from "./Sections/Rent";

import utils from "../../../../../../utils";
import CHTextArea from "../../../../../../components/ubold/molecules/forms/CHTextArea";

function Order(props: any) {
  const { id, definitions, tab, state, setState } = props;

  const doneStatus = [4, 5, 6];

  return (
    <TabPane
      id={id}
      definitions={definitions}
      isAbleToDuplicate
      removedDuplicateFields={["number"]}
      tab={tab}
      state={state}
      setState={setState}
      readOnly={true}
    >
      <TabPaneSection style={{ padding: "25px 16px 0px" }}>
        <CHTextView name="number" title="Order ID" readOnly />
        <CHAsyncSelect
          name="tenant"
          title="Tenant"
          isRequired
          readOnly
          data="user/tenant?__type__=select_entries&limit=10"
          itemsExtractor={(row: {
            name: string;
            email: string;
            id: number;
            username: string;
            country_code: number;
            phone_number: number;
          }) => {
            return {
              label:
                row.name +
                " - " +
                row.email +
                " - " +
                row.country_code +
                row.phone_number,
              value: row.id,
            };
          }}
        />
        <CHAsyncSelect
          name="building"
          title="Building"
          isRequired
          readOnly
          data="building/building?__type__=select_entries&limit=10"
          itemsExtractor={(row: any) => {
            return {
              label:
                row.building_name +
                " - " +
                (row.building_type === 1
                  ? "Centralized"
                  : "Decentralized"),
              value: row.id,
            };
          }}
        />
        <CHAsyncSelect
          name="room_variant"
          title="Room Variant"
          dependsOn="building"
          isRequired
          readOnly
          data="building/room_variant?__type__=select_entries&limit=10"
          itemsExtractor={(row: any) => {
            return {
              label:
                row.name +
                " - " +
                utils.formatter.currency(row.price),
              value: row.id,
            };
          }}
        />
        <CHAsyncSelect
          name="room"
          title="Room"
          dependsOn="room_variant"
          readOnly
          data="building/room?__type__=select_entries&limit=10"
          itemsExtractor={(row: any) => {
            return {
              label:
                row.name +
                " (" +
                row["availability"] +
                ")" +
                " - " +
                utils.formatter.currency(row.price),
              value: row.id,
            };
          }}
        />
        <CHAsyncSelect
          name="payment_plan"
          title="Payment Plan"
          isRequired
          readOnly
          data="finance/payment_plan?__type__=select_entries&limit=10"
          itemsExtractor={(row: any) => {
            return {
              label:
                row.plan + " Month(s) - " + row.discount_amount + "%",
              value: row.id,
            };
          }}
        />
        <CHTextView
          name="payment_plan_discount"
          title="Payment Plan Discount"
          renderer={(value: string) => {
            return value + "%";
          }}
          readOnly
        />
        <CHCheckBox name="full_paid" title="Is Full Paid?" disabled />
        <CHTextField
          name="deposit"
          title="Deposit"
          type="number"
          readOnly
        />
        {id && !doneStatus.includes(state["status"]) ? (
          <CHRichTextField
            name="deposit_notes"
            title="Deposit Notes"
            readOnly
          />
        ) : (
          <CHTextView
            name="deposit_notes"
            title="Deposit Notes"
            isSafeContent
            readOnly
          />
        )}
        <CHTextView
          name="price"
          title="Price"
          readOnly
          renderer={(value: number) => {
            return utils.formatter.currency(value);
          }}
        />
        <CHSelect
          name="discount_type"
          title="Discount Type"
          readOnly
          data={[
            {
              label: "Discount Existing Tenant",
              value: 1,
            },
            {
              label: "Discount Negotiation",
              value: 2,
            },
          ]}
        />
        <CHTextField
          name="discount_amount"
          title="Discount Amount"
          readOnly
          type="number"
          isRequired
        />
        {id && !doneStatus.includes(state["status"]) ? (
          <CHRichTextField
            name="discount_notes"
            title="Discount Notes"
            readOnly
          />
        ) : (
          <CHTextView
            name="discount_notes"
            title="Discount Notes"
            isSafeContent
            readOnly
          />
        )}
        <CHSelect
          name="tenant_category"
          title="Tenant Category"
          readOnly
          isRequired
          data={[
            {
              label: "Rukita Tenant",
              value: 1,
            },
            {
              label: "Legacy Tenant",
              value: 2,
            },
            {
              label: "Rukita Staff - Pay",
              value: 3,
            },
            {
              label: "Rukita Staff - Free of Charge",
              value: 4,
            },
          ]}
        />
        <CHTextView
          name="transfer_from"
          title="Transfer From"
          readOnly
        />
        <CHCheckBox
          name="duplicated_order"
          title="Is Duplicate Order?"
          disabled
        />
        <CHAsyncSelect
          name="signed_by_1"
          title="Signed By 1"
          readOnly
          data="crm/user?__type__=select_entries&limit=10"
          itemsExtractor={(value: any) => {
            return {
              label: value.username,
              value: value.id,
            };
          }}
        />
        <CHAsyncSelect
          name="signed_by_2"
          title="Signed By 2"
          readOnly
          data="crm/user?__type__=select_entries&limit=10"
          itemsExtractor={(value: any) => {
            return {
              label: value.username,
              value: value.id,
            };
          }}
        />
        <CHAsyncSelect
          name="signed_by_3"
          title="Signed By 3"
          readOnly
          data="crm/user?__type__=select_entries&limit=10"
          itemsExtractor={(value: any) => {
            return {
              label: value.username,
              value: value.id,
            };
          }}
        />
        <CHDateTimePicker
          name="signed_date"
          title="Signed Date"
          type="date"
          readOnly
        />
        <CHTextField
          name="referral_code"
          title="Referral Code"
          maxLength={8}
          readOnly
        />
        <CHTextArea
          name="rumanage_remarks"
          title="RuManage Remarks"
          maxLength={150}
          withCharCounter
          readOnly
        />
      </TabPaneSection>
      <TabPaneSection accordionId="formAccordion">
        <Rent />
        <Information />
      </TabPaneSection>
    </TabPane>
  );
}

export default withRouter(Order);
