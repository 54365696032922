import { useState } from "react";

import { useParams, withRouter } from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";
import Form from "../../../../components/ubold/organisms/Form";

import CVTextField from "../../../../components/ubold/molecules/forms/CVTextField";
import CVTextArea from "../../../../components/ubold/molecules/forms/CVTextArea";
import CVRichTextField from "../../../../components/ubold/molecules/forms/CVRichTextField";
import CVSelect from "../../../../components/ubold/molecules/forms/CVSelect";
import CVAsyncSelect from "../../../../components/ubold/molecules/forms/CVAsyncSelect";

import definitions from "./definitions";
import CVSwitch from "../../../../components/ubold/molecules/forms/CVSwitch";

function MenuAddEditPage() {
  let { id } = useParams();

  const initialState = {
    is_active: true,
    type: 2,
  };

  const [state, setState] = useState(initialState);

  return (
    <Page
      title={
        (id === undefined ? "Add New" : "Edit") +
        " " +
        definitions.title
      }
      restAccessCode={definitions.restAccessCode}
    >
      <Form
        id={id}
        definitions={definitions}
        state={state}
        setState={setState}
      >
        <CVTextField
          title="Menu Name"
          name="name"
          isRequired
          maxLength={50}
        />
        <CVTextField
          title="Order Position"
          name="order_position"
          type="number"
          isRequired
        />
        <CVAsyncSelect
          title="Menu Position"
          name="menu_position"
          isRequired
          data="crm/menu_position?__type__=select_entries"
        />
        <CVTextField
          title="Path"
          name="link"
          maxLength={75}
          isRequired
        />
        <CVSelect
          title="Menu Type"
          name="type"
          isRequired
          data={[
            {
              label: "None",
              value: 0,
            },
            {
              label: "Menu",
              value: 2,
            },
          ]}
        />
        <CVAsyncSelect
          title="Parent Menu"
          name="parent"
          data="crm/menu?__type__=select_entries&__menu_list__=with_parent"
        />
        <CVTextField title="Icon" name="icon" maxLength={125} />
        <CVTextField
          title="Short Description"
          name="short_description"
          maxLength={75}
        />
        <CVSwitch title="Is Audited?" name="is_audited" />
        <CVSwitch title="Is Exact Link?" name="is_exact_link" />
        <CVSwitch
          title="Is Administrator Menu?"
          name="is_administrator_menu"
        />
        <CVSwitch title="Is Active?" name="is_active" />
        <CVTextArea
          title="Description"
          name="description"
          maxLength={255}
        />
        <CVRichTextField
          title="Documentation"
          name="documentation"
          overrideLayout={12}
        />
      </Form>
    </Page>
  );
}

export default withRouter(MenuAddEditPage);
