import { useParams, withRouter } from "react-router-dom";
import COneOrManyToManyField from "../../../../../../components/ubold/molecules/forms/COneOrManyToManyField";
import utils from "../../../../../../utils";
import AccordionPane from "components/ubold/organisms/AccordionPane";
import { useEffect } from "react";

const LandlordInvoiceDetails = (props: any) => {
  const {
    definitions,
    tab,
    state,
    setState,
    accordionId,
    baseLink,
    onSubmit,
    generalKey,
    accordionIndex,
  } = props;

  let { id }: any = useParams();

  useEffect(() => {
    if (
      !state?.[generalKey]?.length &&
      state?.[`${generalKey}-default`]?.length
    ) {
      setState((prevState: any) => ({
        ...prevState,
        [generalKey]: state?.[`${generalKey}-default`],
      }));
    }
  }, [state?.[generalKey]]);

  return (
    <AccordionPane
      id={generalKey}
      name={generalKey}
      accordionId={accordionId}
      baseLink={baseLink}
      onSubmit={onSubmit}
      definitions={definitions}
      tab={tab}
      restAccessCodeNamespace="detail"
      state={state}
      setState={setState}
      shown={accordionIndex === 0}
    >
      <COneOrManyToManyField
        id={id}
        name={generalKey}
        restPath="finance/invoice_detail/"
        relationKey="invoice_id"
        readOnly
        columns={[
          {
            name: "category_name",
            type: "read_only",
            title: "Category",
            isReadOnly: true,
          },
          {
            name: "amount",
            type: "read_only",
            title: "Amount",
            renderer: (value: any) => {
              return utils.formatter.currency(value);
            },
            isReadOnly: true,
          },
          {
            name: "description",
            type: "textarea",
            title: "Description",
            isReadOnly: true,
          },
          {
            name: "start_date",
            type: "date",
            title: "Start Date",
            isReadOnly: true,
          },
          {
            name: "end_date",
            type: "date",
            title: "End Date",
            isReadOnly: true,
          },
        ]}
      />
    </AccordionPane>
  );
};

export default withRouter(LandlordInvoiceDetails);
