import { useState, useEffect } from "react";

import { useParams, withRouter } from "react-router-dom";

import Page from "../../../../components/ubold/organisms/Page";

import TabbedForm from "../../../../components/ubold/organisms/TabbedForm";
import TabHeaders from "../../../../components/ubold/organisms/TabHeaders";
import TabContent from "../../../../components/ubold/organisms/TabContent";

import LandlordInvoiceGeneral from "./Tabs/LandlordInvoiceGeneral/LandlordInvoiceGeneral";

import definitions from "./definitions";

function InvoiceViewpage() {
  const { id }: any = useParams();
  const [state, setState] = useState<any>({});

  useEffect(() => {
    if (!state || !state.detail?.length) return;
    const tempDetail = [...state?.detail];
    tempDetail.forEach((detail: any) => {
      const period = Object.keys(detail)[0];
      const periodData = detail[period];
      setState((prevState: any) => ({
        ...prevState,
        [`${period}-default`]: periodData,
      }));
    });
  }, [state?.detail]);

  return (
    <Page
      title={`View ${definitions.title}`}
      restAccessCode={definitions.restAccessCode}
    >
      <TabbedForm
        id={id}
        state={state}
        setState={setState}
        definitions={definitions}
      >
        <TabHeaders />
        <TabContent>
          <LandlordInvoiceGeneral />
        </TabContent>
      </TabbedForm>
    </Page>
  );
}

export default withRouter(InvoiceViewpage);
