import configBuilder from "../../../components/ubold/ConfigBuilder";

import utils from "../../../utils";

let definitions = {
  title: "Tiring Escalations",
  frontendPath: "/building/agreement/tiring_escalation",
  baseLink: "/building/tiring_escalation",
  restAccessCode: "building.tiring_escalation",
  __table__: {
    filterColumn: 3,
    removeIdentifier: "building_name",
    hideEditButton: true,
    filters: [
      {
        id: "building",
        name: "Building",
        type: "async_select",
        data: "building/building?__type__=select_entries",
        itemsExtractor: (value) => {
          return {
            label: value.building_name,
            value: value.id,
          };
        },
      },
      {
        id: "month",
        name: "Month(s)",
        type: "number",
      },
      {
        id: "escalating_amount",
        name: "Escalating Amount",
        type: "number",
      },
      {
        id: "date_created",
        name: "Date Created",
        type: "date",
      },
    ],
    columns: [
      {
        id: "building_name",
        name: "Building",
        enableHyperlink: true,
      },
      {
        id: "month",
        name: "Month(s)",
        renderer: (value) => {
          return (
            value + " Month" + (parseInt(value) === 1 ? "" : "s")
          );
        },
      },
      {
        id: "escalating_amount",
        name: "Escalating Amount",
        renderer: (value) => {
          return utils.formatter.currency(value);
        },
      },
      {
        id: "date_created",
        name: "Date Created",
        renderer: (value) => {
          return utils.formatter.dateTime(value);
        },
      },
    ],
  },
};

export default configBuilder.buildTablePageConfig(definitions);
