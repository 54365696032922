import { withRouter } from "react-router-dom";

import CDualListBox from "../../atoms/forms/CDualListBox";
import CDualistBoxV2 from "components/ubold/atoms/forms/CDualistBoxV2";

import useGlobalState from "../../../../state";

import utils from "../../../../utils";

function CVSimpleManyToMany(props) {
  const {
    name,
    title,
    isRequired = false,
    overrideLayout,
    helperText,
    formColumn = 2,
    restAccessCode,
    state,
    setState,
    data,
    dependsOn,
    dependsOnHandler,
    itemsExtractor,
    readOnly,
    customWarning = null,
    dataID,
    arrDataIdList = [],
    apiUrl,
    useV2,
  } = props;

  let formColumnReal = 12 / formColumn;

  const [user] = useGlobalState("user");
  const isSuperUser = user ? user.is_super_user : false;
  const [fieldAccesses] = useGlobalState("field_accesses");
  const [fieldAccessReversePolicy] = useGlobalState(
    "field_access_reverse_policy"
  );

  return (
    <div
      className={
        "col-lg-" + (overrideLayout ? overrideLayout : formColumnReal)
      }
      style={{
        display:
          !isRequired &&
          restAccessCode &&
          utils.access.isFieldHidden(
            fieldAccesses,
            restAccessCode + "." + name
          )
            ? "none"
            : "unset",
      }}
    >
      <div className="form-group mb-3">
        <label
          className={
            (isRequired ? " font-weight-bold" : "") +
            (state[name + "Error"] ? " text-danger" : "")
          }
        >
          {title}
          {isRequired ? "*" : ""}{" "}
          {readOnly ??
          utils.access.isFieldReadOnly(
            fieldAccesses,
            restAccessCode + "." + name,
            fieldAccessReversePolicy,
            isSuperUser
          )
            ? "(read only)"
            : ""}
        </label>
        &nbsp;
        {!useV2 ? (
          <CDualListBox
            name={name}
            readOnly={
              readOnly ??
              (restAccessCode &&
                utils.access.isFieldReadOnly(
                  fieldAccesses,
                  restAccessCode + "." + name,
                  fieldAccessReversePolicy,
                  isSuperUser
                ))
            }
            state={state}
            setState={setState}
            data={data}
            dependsOn={dependsOn}
            dependsOnHandler={dependsOnHandler}
            itemsExtractor={itemsExtractor}
          />
        ) : (
          <CDualistBoxV2
            name={name}
            readOnly={
              readOnly ??
              (restAccessCode &&
                utils.access.isFieldReadOnly(
                  fieldAccesses,
                  restAccessCode + "." + name,
                  fieldAccessReversePolicy,
                  isSuperUser
                ))
            }
            state={state}
            setState={setState}
            data={data}
            dependsOn={dependsOn}
            dependsOnHandler={dependsOnHandler}
            itemsExtractor={itemsExtractor}
            dataID={dataID}
            arrDataIdList={arrDataIdList}
            apiUrl={apiUrl}
          />
        )}
        {helperText && helperText !== "" ? (
          <span
            className="text-muted font-13 pt-1 mb-0"
            dangerouslySetInnerHTML={{ __html: helperText }}
          />
        ) : (
          ""
        )}
        {helperText && helperText !== "" && state[name + "Error"] ? (
          <br />
        ) : (
          <></>
        )}
        {state[name + "Error"] || customWarning ? (
          <span
            className={!customWarning ? "text-danger" : "text-muted"}
            style={{ fontSize: "12px" }}
          >
            {state[name + "Error"] || customWarning}
          </span>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default withRouter(CVSimpleManyToMany);
