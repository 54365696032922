// TRANSACTIONS
import RukiKoin from "pages/RukiKoin/RukiKoin";
import RukiKoinAddEdit from "pages/RukiKoin/RukiKoin/AddEdit";
import RukiKoinView from "pages/RukiKoin/RukiKoin/ViewOnly";

const RukikoinRoutes = [
  // TRANSACTIONS
  {
    path: "/ruki_koin/transaction",
    component: RukiKoin,
  },
  {
    path: "/ruki_koin/transaction/(add|edit)/:id([0-9]+)?",
    component: RukiKoinAddEdit,
  },
  {
    path: "/ruki_koin/transaction/view/:id([0-9]+)?",
    component: RukiKoinView,
  },
];

export default RukikoinRoutes;
